import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  layoutViewMode: "grid",
  currentPosition: null,
  requestPDFSettings: {
    groupBy: "category",
    order: "desc",
    orderBy: "date",
  },
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setLayoutViewMode(state, { payload }) {
      state.layoutViewMode = payload || "grid";
    },
    toggleLayoutViewMode(state, { payload }) {
      state.layoutViewMode = state.layoutViewMode === "grid" ? "line" : "grid";
    },
    setCurrentPosition(state, { payload }) {
      state.currentPosition = payload;
    },
    resetSettings(state) {
      state.currentPosition = initialState.currentPosition;
      state.layoutViewMode = initialState.layoutViewMode;
    },
    setRequestPDFSetting(state, { payload }) {
      state.requestPDFSettings[payload?.setting] = payload?.value;
    },
  },
});

export const {
  toggleLayoutViewMode,
  setCurrentPosition,
  setLayoutViewMode,
  resetSettings,
  setRequestPDFSetting,
} = settingsSlice.actions;

export default settingsSlice.reducer;

export const selectRequestPDFSettings = (state) =>
  state.settings.requestPDFSettings;
export const selectLayoutViewMode = (state) => state.settings.layoutViewMode;

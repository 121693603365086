import {
  Close,
  DragHandleOutlined,
  GpsFixedOutlined,
  LocationOnOutlined,
} from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
} from "@mui/material";
import { Autocomplete } from "@react-google-maps/api";
import React, { memo, useState } from "react";
import "../../../../../main.css";

function RouteInputBox(props) {
  const {
    value,
    innerRef,
    isDestination,
    provided,
    isOrigin,
    enableRemove,
    loadingCurrentPos,
    onClickFromCurrentPosition = () => {},
    onPlaceChanged = () => {},
    onRemove = () => {},
    calculate = () => {},
  } = props;

  const [hovered, setHovered] = useState(false);

  const [inputRef, setInputRef] = useState(null);

  return (
    <Box
      width={"100%"}
      component={Paper}
      elevation={1}
      boxShadow={0}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      ref={innerRef}
      position="relative"
      display={"flex"}
      alignItems="center"
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      {hovered && (
        <DragHandleOutlined
          color="action"
          sx={{ position: "absolute", left: -8, fontSize: "1rem" }}
        />
      )}
      <Box
        component={"div"}
        display={"flex"}
        justifyContent="center"
        alignItems={"center"}
        width={enableRemove ? 56 : 49}
        mr={0.5}
      >
        {isDestination ? (
          <LocationOnOutlined color="error" sx={{ fontSize: "1.2rem" }} />
        ) : (
          <Box
            border={2}
            borderRadius={100}
            zIndex={100}
            bgcolor={"background.default"}
            borderColor={(t) =>
              isOrigin ? "primary.main" : t.palette.grey[400]
            }
            width={11}
            height={11}
          />
        )}
      </Box>
      <Autocomplete
        onLoad={(node) => node && setInputRef(node)}
        onPlaceChanged={() => {
          if (inputRef !== null) {
            const value =
              inputRef.getPlace().formatted_address ||
              inputRef.getPlace().vicinity;
            value && onPlaceChanged(value);
            calculate();
          }
        }}
        className="fullWidth"
      >
        <TextField
          autoFocus={false}
          id={`route_${value}`}
          hiddenLabel
          onFocus={(event) => {
            event.target.select();
          }}
          InputProps={{
            endAdornment: isOrigin && (
              <InputAdornment position="end">
                {loadingCurrentPos ? (
                  <CircularProgress size={20} sx={{ mr: 1 }} />
                ) : (
                  <Tooltip placement="top" title={"Seu local"}>
                    <IconButton
                      onClick={onClickFromCurrentPosition}
                      size="small"
                      color="primary"
                    >
                      <GpsFixedOutlined fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              fontSize: ".95rem",
              "& fieldset": {
                border: 1,
                borderRadius: 1.1,
                borderColor: (t) =>
                  t.palette.mode === "light"
                    ? "rgb(0,0,0,.42)"
                    : "rgb(255,255,255,.42)",
              },
              "&.Mui-focused fieldset": {
                boxShadow: 5,
              },
            },
          }}
          size="small"
          placeholder={
            isOrigin
              ? "De"
              : isDestination
              ? "Escolha o destino"
              : "Passando por"
          }
          fullWidth
          value={value || ""}
          onChange={(e) => onPlaceChanged(e.target.value)}
        />
      </Autocomplete>
      {enableRemove && (
        <Tooltip
          placement="right"
          enterDelay={1000}
          title={`Remover ${value ? value?.slice(0, 25) + "..." : ""}`}
        >
          <IconButton
            onClick={onRemove}
            sx={{ visibility: hovered ? "visible" : "hidden" }}
            size="small"
          >
            <Close fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      {provided.placeholder}
    </Box>
  );
}

export default memo(RouteInputBox);

import { ArrowDropDown } from "@mui/icons-material";
import {
  Box,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import { curr, revertCurr } from "../../../../utils/more/currency_country";
import { CurrencyInputMask } from "../../../../utils/more/mask_functions";
import RefundableToggle from "../RefundableToggle";

const SelectCurrencyMenu = ({ currency, onChange }) => {
  const [menu, setMenu] = useState(null);

  const handleClickMenuItem = (v) => {
    onChange(v);
    setMenu(null);
  };

  return (
    <>
      <Box
        onClick={(e) => setMenu(e.target)}
        borderRadius={1}
        p={0.5}
        pt={0}
        sx={{ cursor: "pointer", ":hover": { bgcolor: "action.hover" } }}
        width={100}
        display={"flex"}
        alignItems={"center"}
      >
        <Typography flex={1} noWrap fontSize="1.9rem" variant="h5">
          {curr(currency)}
        </Typography>
        <ArrowDropDown />
      </Box>
      <Menu open={Boolean(menu)} anchorEl={menu} onClose={() => setMenu(null)}>
        <MenuItem
          selected={revertCurr(currency) === "BRL"}
          onClick={() => handleClickMenuItem("BRL")}
          value="BRL"
        >
          BRL (R$)
        </MenuItem>
        <MenuItem
          selected={revertCurr(currency) === "EUR"}
          onClick={() => handleClickMenuItem("EUR")}
          value="EUR"
          disabled
        >
          EUR (€) - Em breve
        </MenuItem>
        <MenuItem
          selected={revertCurr(currency) === "USD"}
          onClick={() => handleClickMenuItem("USD")}
          value="USD"
          disabled
        >
          USD ($) - Em breve
        </MenuItem>
      </Menu>
    </>
  );
};

function AmounInput({
  readOnly,
  onChange,
  refundableReadOnly,
  refundable,
  onChangeRefundable,
  onChangeCurrency,
  value = "",
  innherRef,
  currency,
  sx,
}) {
  return (
    <>
      <Box sx={sx} pr={0.5} display={"flex"} gap={1} alignItems="center">
        <SelectCurrencyMenu currency={currency} onChange={onChangeCurrency} />
        <TextField
          name="amount"
          hiddenLabel
          required
          inputRef={innherRef}
          autoFocus
          fullWidth
          readOnly={readOnly}
          variant={"standard"}
          size="small"
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          placeholder="0,00"
          InputProps={{
            sx: {
              fontSize: "2rem",
              fontWeight: "500",
              borderRadius: 1,
            },
            disableUnderline: false,
            inputComponent: CurrencyInputMask,
            endAdornment: <InputAdornment position={"end"}></InputAdornment>,
          }}
        />
        <RefundableToggle
          readOnly={readOnly || refundableReadOnly}
          refundable={refundable}
          onChange={onChangeRefundable}
        />
      </Box>
    </>
  );
}

export default memo(AmounInput);

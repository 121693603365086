import {
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { RESET_STATE } from "@redux-offline/redux-offline/lib/constants";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthAPIGateway } from "../../api/httpClient";
import { ADMIN_BASE_URL, AUTH_BASE_URL } from "../../api/urls";
import NotepayLogo from "../../components/NotepayLogo";
import {
  selectAccountStatus,
  selectIsMaster,
  selectUserBaseInfo,
} from "../../store/features/accountSlice";
import {
  closeSnackbar,
  openSnackbar,
} from "../../store/features/base/snackbarBaseSlice";
import { getAccountStatusData } from "../../utils/accountStatus";
import { getProfilePicture } from "../../utils/functions/profile";
import { formatDate } from "../../utils/more/date_functions";

const errors = {
  F2: "A gestão de despesas e relatórios está inativada",
  F3: "Lamentamos informar que o acesso à sua conta foi revogado.",
  F4: "Sua conta está com o acesso expirado",
  F5: "Você não tem permissão para acessar o painel de despesas",
};

const ModalInactiveAccount = ({ accountError }) => {
  const errorCode = accountError?.code;

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const user = useSelector(selectUserBaseInfo);
  const accountStatus = useSelector(selectAccountStatus);
  const isMaster = useSelector(selectIsMaster);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(closeSnackbar());
    setTimeout(() => {
      setOpen(true);
    }, 500);
  }, []);

  const handleLogout = async () => {
    setLoading(true);
    try {
      await AuthAPIGateway.logout();
      setLoading(false);
      dispatch({
        type: RESET_STATE,
      });
      window.location.replace(
        `${AUTH_BASE_URL}?step=0&email=${window.localStorage.getItem(
          "email"
        )}&redirect=${window.location.origin}`
      );
    } catch (error) {
      setLoading(false);
      dispatch(openSnackbar({ message: "Erro ao sair da conta", error: true }));
    }
  };

  return (
    <>
      <NotepayLogo
        disableTheme
        style={{
          width: "150px",
          position: "fixed",
          left: 40,
          top: 40,
          zIndex: 10000000,
        }}
      />
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        scroll="body"
        PaperProps={{
          sx: {
            bgcolor: "#FFF",
            color: "#272727",
          },
        }}
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: "rgba(255,255,255,.8)",
            },
          },
        }}
      >
        <Backdrop
          sx={{
            zIndex: (t) => t.zIndex.modal + 100,
            backgroundColor: "rgba(255,255,255,.8)",
          }}
          open={loading}
        >
          <CircularProgress />
        </Backdrop>
        <DialogContent>
          <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
            <Avatar
              sx={{ mb: 1 }}
              src={getProfilePicture(user?.userId, nanoid())}
              alt={user?.name}
            />
            <Typography textAlign={"center"} variant="h6">
              Olá, {user?.name}.
            </Typography>
            <Typography
              mb={2}
              textAlign={"center"}
              variant="body2"
              color={"text.secondary"}
            >
              {user?.email}
            </Typography>
          </Box>
          <Typography
            gutterBottom
            textAlign={"center"}
            fontWeight={"600"}
            variant="h5"
          >
            {errors[errorCode] || "Seu acesso está inválido"}
          </Typography>
          {isMaster ? (
            <>
              <Typography
                textAlign={"center"}
                color={"text.secondary"}
                variant="body1"
              >
                {getAccountStatusData(accountStatus).description}
              </Typography>
            </>
          ) : (
            <Typography
              textAlign={"center"}
              color={"text.secondary"}
              variant="body1"
            >
              Contate um proprietário da sua empresa para saber mais informações
              sobre o status da conta.
            </Typography>
          )}
          {errorCode === "F4" && accountError?.valid_to && (
            <Typography
              mt={2}
              textAlign={"center"}
              variant="body2"
              fontWeight={"600"}
              color={"text.secondary"}
            >
              Acesso expirado em {formatDate(accountError?.valid_to)}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          {isMaster && (
            <Button
              onClick={() => {
                window.location.replace(`${ADMIN_BASE_URL}/billing`);
              }}
              variant="outlined"
            >
              Configurações da conta
            </Button>
          )}
          <Button onClick={handleLogout} variant="contained" disableElevation>
            Voltar para login
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalInactiveAccount;

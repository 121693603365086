import { MoreVert } from "@mui/icons-material";
import { Box, CardActionArea, IconButton, Paper } from "@mui/material";
import React, { memo } from "react";
import ContextMenuWrapper from "./components/ContextMenuWrapper";

function Base({ sx, onClick, children, requestId, innerRef, settings }) {
  const renderComponent = ({ opened, setMenu }) => {
    return (
      <Box
        onContextMenu={(e) => {
          e.preventDefault();
          setMenu(e.target);
        }}
        onClick={onClick}
        id={`r-${requestId}`}
        component={Paper}
        borderRadius={2.5}
        width="100%"
        position="relative"
        height={135}
        ref={innerRef}
        overflow={"hidden"}
        elevation={0}
        sx={{
          border: 1,
          cursor: "pointer",
          borderColor: (t) =>
            t.palette.mode === "light"
              ? "rgba(0,0,0,.26)"
              : "rgba(255,255,255,.26)",
          boxShadow: 0,
          transition: "none",

          ":hover": {
            boxShadow: 5,
          },
          ...sx,
        }}
      >
        <CardActionArea
          sx={{
            p: 2,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          {children}
        </CardActionArea>
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            setMenu(e.target);
          }}
          style={{ position: "absolute", bottom: 10, right: 5 }}
        >
          <MoreVert />
        </IconButton>
      </Box>
    );
  };

  return (
    <ContextMenuWrapper
      requestId={requestId}
      settings={settings}
      renderComponent={renderComponent}
    />
  );
}

export default memo(Base);

import {
  AssignmentOutlined,
  CurrencyExchangeOutlined,
  NotificationsOutlined,
  PaidOutlined,
} from "@mui/icons-material";
import { Avatar, ListItemAvatar, useTheme } from "@mui/material";
import React, { memo, useMemo } from "react";
import { getProfilePicture } from "../../../../../../utils/functions/profile";

const CustomAvatar = ({ variant, payload = {} }) => {
  const { palette } = useTheme();

  const color = useMemo(() => {
    switch (variant) {
      case "advance-rejected":
        return palette.error.main;
      case "advance-approved":
        return palette.success.main;
      case "request-rejected":
        return palette.error.main;
      case "request-approved":
        return palette.success.main;
      case "report-finished":
        return palette.primary.main;
      case "advance-paid":
        return palette.primary.main;
      default:
        break;
    }
  }, [variant]);

  const bgcolor = useMemo(() => {
    return `${color}20`;
  }, [color]);

  const Icon = useMemo(() => {
    switch (variant) {
      case "advance-rejected":
        return PaidOutlined;
      case "advance-approved":
        return PaidOutlined;
      case "request-rejected":
        return AssignmentOutlined;
      case "request-approved":
        return AssignmentOutlined;
      case "report-finished":
        return CurrencyExchangeOutlined;
      default:
        if (variant.includes("request")) {
          return AssignmentOutlined;
        } else if (variant.includes("advance")) {
          return PaidOutlined;
        }
        break;
    }
  }, [variant]);

  return (
    <ListItemAvatar sx={{ mt: 0.5 }}>
      <Avatar
        src={payload?.user_id && getProfilePicture(payload?.user_id)}
        sx={{
          bgcolor,
          position: "relative",

          width: 38,
          height: 38,
        }}
      >
        {Icon ? <Icon sx={{ color }} /> : <NotificationsOutlined />}
      </Avatar>
    </ListItemAvatar>
  );
};

export default memo(CustomAvatar);

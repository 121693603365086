import { Box } from "@mui/material";
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { useDispatch } from "react-redux";
import LoadingSection from "../../../../../components/loading/LoadingSection";
import ModalExpenseView from "../../../../../modals/expense-view/expense/ModalExpenseView";
import ModalManualRouteView from "../../../../../modals/expense-view/manual-route/ModalManualRouteView";
import ModalMapRouteView from "../../../../../modals/expense-view/map-route/ModalMapRouteView";
import { selectionReducer } from "../../../../../reducers/selectionReducer";
import { openElement } from "../../../../../store/features/portalSlice";
import ExpensesRenderer from "../../../../expenses/components/ExpensesRenderer";
import NoExpenses from "./NoExpenses";
import SelectionBar from "./SelectionBar";

function ExpensesContent({
  loading,
  expenses = [],
  isEditable,
  rejectedExpenses,
  status,
  totalAmount = 0,
  totalRefundable = 0,
}) {
  const dispatch = useDispatch();

  const [selected, dispatchSelected] = useReducer(selectionReducer, []);

  const expensesIds = useMemo(() => expenses.map((exp) => exp.id), [expenses]);

  const empty = useMemo(() => !Boolean(expenses.length), [expenses]);

  const expensesIdsNotRejected = useMemo(
    () =>
      expensesIds.filter((expenseId) =>
        status === "R" ? !rejectedExpenses.includes(expenseId) : true
      ),
    [rejectedExpenses, expensesIds, status]
  );

  const expensesSettings = useMemo(() => {
    return {
      edit: isEditable,
      remove: isEditable,
      addToRequest: false,
      select: isEditable,
      removeFromRequest: isEditable,
    };
  }, [isEditable]);

  useEffect(() => {
    if (!isEditable) {
      handleClearSelection();
    }
  }, [isEditable]);

  useEffect(() => {
    handleClearSelection();
  }, [expensesIds]);

  const handleExpenseClick = useCallback((expenseId, isRoute, routeMode) => {
    const viewName = isRoute
      ? routeMode === "map"
        ? "routeView"
        : "manualRouteView"
      : "expenseView";

    dispatch(
      openElement({
        name: viewName,
        util: {
          expenseId,
        },
      })
    );
  }, []);

  const handleSelect = useCallback((expenseId) => {
    dispatchSelected({ type: "toggle", payload: expenseId });
  }, []);

  const handleSelectAllClick = useCallback(
    (event) => {
      if (event.target.checked) {
        dispatchSelected({
          type: "select-all",
          payload: [...expensesIds],
        });
      } else {
        dispatchSelected({ type: "clear" });
      }
    },
    [expensesIds]
  );

  const handleClearSelection = useCallback(() => {
    dispatchSelected({ type: "clear" });
  }, []);

  const isSelected = useCallback(
    (expenseId) => selected.includes(expenseId),
    [selected]
  );

  const isRejected = useCallback(
    (expenseId) => rejectedExpenses?.includes(expenseId),
    [rejectedExpenses]
  );

  return (
    <>
      <SelectionBar
        selected={selected}
        count={expensesIds.length}
        onSelectAllClick={handleSelectAllClick}
        onClearSelection={handleClearSelection}
      />
      <>
        {loading && empty ? (
          <LoadingSection h={"70%"} />
        ) : empty ? (
          <NoExpenses status={status} />
        ) : (
          <>
            <Box color={"text.primary"} id="expenses" position={"relative"}>
              <ExpensesRenderer
                enableOtherSection={
                  status === "R" && Boolean(rejectedExpenses?.length)
                }
                sectionTitle={`Rejeitadas (${rejectedExpenses.length})`}
                sectionDescription="Reabra o relatório para editar as despesas e enviá-las novamente"
                sectionExpensesIds={rejectedExpenses}
                expensesIds={expensesIdsNotRejected}
                expenseSettings={expensesSettings}
                onExpenseClick={handleExpenseClick}
                onExpenseSelect={handleSelect}
                isSelected={isSelected}
                isRejected={isRejected}
                totalAmount={totalAmount}
                totalRefundable={totalRefundable}
              />
            </Box>
            <ModalExpenseView settings={expensesSettings} />
            <ModalMapRouteView settings={expensesSettings} />
            <ModalManualRouteView settings={expensesSettings} />
          </>
        )}
      </>
    </>
  );
}

export default memo(ExpensesContent);

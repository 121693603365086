import {
  CurrencyExchangeOutlined,
  NotesOutlined,
  PersonPinCircleOutlined,
  PostAddOutlined,
  TodayOutlined,
} from "@mui/icons-material";
import { Box, Divider, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { selectAccountCurrency } from "../../../../../store/features/accountSlice";
import { selectRoutePolicieById } from "../../../../../store/features/configs/routePoliciesSlice";
import { formatDate, formatMoment } from "../../../../../utils/more/date_functions";
import { CurrencyFormat } from "../../../../../utils/more/mask_functions";
import Baseline from "../../../expense/contents/view/components/Baseline";
import RouteLine from "./components/RouteLine";

function ViewContent({ expense }) {
  const accountCurrency = useSelector((state) => selectAccountCurrency(state, true));
  const {
    to,
    from,
    rate,
    amount,
    waypoints,
    distance,
    refundable,
    project_name,
    date,
    notes,
    created_at,
    route_policy_name,
    route_policy_id,
  } = expense;

  const routePolicy = useSelector((state) =>
    selectRoutePolicieById(state, route_policy_id)
  );

  return (
    <Box px={2} display={"flex"} flexDirection={"column"} gap={3}>
      <Box display={"flex"} alignItems={"flex-start"}>
        <Box>
          <Typography fontWeight={"500"} fontSize={"1.8rem"} variant="h4">
            {accountCurrency} <CurrencyFormat value={amount || 0} />
          </Typography>
        </Box>
        <Box flex={1} />
        <Box mt={1} ml={1} display={"flex"} alignItems={"center"}>
          <CurrencyExchangeOutlined
            fontSize="small"
            color={refundable ? "primary" : "disabled"}
            sx={{ mr: 1 }}
          />
          <Typography variant="body2">
            {refundable ? "Reembolsável" : "Não reembolsável"}
          </Typography>
        </Box>
      </Box>
      <Box
        position={"relative"}
        display={"flex"}
        flexDirection={"column"}
        gap={1.4}
      >
        <Divider
          orientation="vertical"
          sx={{
            position: "absolute",
            bgcolor: grey[400],
            left: 9.5,
          }}
        />
        <RouteLine value={from} />
        {(waypoints || []).map((wp, idx) => (
          <RouteLine key={wp + idx.toString()} value={wp} />
        ))}
        <RouteLine isDestination value={to} />
      </Box>
      <Typography
        fontWeight={"500"}
        variant="body1"
        fontSize={"1.2rem"}
        mt={-1}
      >
        {distance} km
      </Typography>
      <Divider sx={{ mx: -4 }} />
      <Box display={"flex"} flexDirection={"column"} gap={3} mx={-1}>
        <Baseline
          Icon={TodayOutlined}
          label={"Data do percurso"}
          value={formatDate(date)}
        />
        <Baseline
          Icon={PostAddOutlined}
          value={project_name}
          label={"Projeto"}
          emptyLabel="Sem projeto"
        />
        <Baseline
          Icon={PersonPinCircleOutlined}
          value={
            <Typography component={"span"}>
              {route_policy_name || routePolicy?.name || ""} - {accountCurrency}{" "}
              <CurrencyFormat value={rate} /> /km
            </Typography>
          }
          label={"Política de percurso"}
          emptyLabel="Sem política aplicada"
        />
        <Baseline
          Icon={NotesOutlined}
          value={notes}
          label={"Descrição"}
          emptyLabel="Sem descrição"
        />
      </Box>
      <Divider sx={{ mx: -4 }} />
      <Typography variant="body2" color={"text.secondary"}>
        Criado em {" "}
        <Typography
          fontWeight={"500"}
          color={"text.primary"}
          component={"span"}
          variant="body2"
        >
          {formatMoment(created_at || new Date())}
        </Typography>
      </Typography>
    </Box>
  );
}

export default memo(ViewContent);

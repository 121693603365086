import React, { memo } from "react";

const SingleExpensesSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="280.974"
      height="250.706"
      viewBox="0 0 1105.398 700.724"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Retângulo_1487"
            data-name="Retângulo 1487"
            width="46.854"
            height="48.492"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <rect
            id="Retângulo_1488"
            data-name="Retângulo 1488"
            width="47.086"
            height="48.344"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-3">
          <rect
            id="Retângulo_1489"
            data-name="Retângulo 1489"
            width="48.155"
            height="56.058"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-4">
          <rect
            id="Retângulo_1490"
            data-name="Retângulo 1490"
            width="254.366"
            height="353.265"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-5">
          <rect
            id="Retângulo_1494"
            data-name="Retângulo 1494"
            width="269.836"
            height="61.38"
            fill="none"
          />
        </clipPath>
      </defs>
      <g
        id="Grupo_858"
        data-name="Grupo 858"
        transform="translate(-177.504 -503.531)"
      >
        <path
          id="Caminho_1680"
          data-name="Caminho 1680"
          d="M224.952,203.669c37.477,36.686,43.77,93.443,35.867,143.155-.207,1.128-1.952.824-1.952-.236,0-11.32.342-22.533.313-33.713-.207-.516-.376-.993-.583-1.5-9.782-7.113-18.775-15.146-24.345-26.157-6.26-12.307-8.713-26.191-13.507-39.048-.438-1.229.872-.867,1.73-.236,22.8,16.813,30.06,34.9,36.6,59.08a201.393,201.393,0,0,0-3.966-37.645c-4.925-22.943-14.664-45.678-31.145-62.7-.612-.651.342-1.643.988-.993"
          transform="translate(842.671 776.982)"
          fill="#263238"
        />
        <path
          id="Caminho_1681"
          data-name="Caminho 1681"
          d="M247.033,231.259c-6.448.217-10.486,10.52-15.512,13.527-2.655,1.6-5.248,1.5-7.113-1.118-4.978-6.949,1.85-15.522,8.274-20.317-4.592,1.889-8.4,5.209-13.358,6.149-3,.549-5.33-.559-6.028-3.609C211.112,216.417,223,212.2,231.15,212c-3.956-2.747-15.281-10.216-9.267-13.888,6.1-3.686,19.391,6.992,23.728,10.51,6.375,5.171,13.975,12.9,14.259,20.982.376,9.44-7.874,3.633-12.838,1.653"
          transform="translate(813.55 753.667)"
          fill="#027BC3"
        />
        <g
          id="Grupo_764"
          data-name="Grupo 764"
          transform="translate(1026.58 951.02)"
          opacity="0.1"
        >
          <g id="Grupo_763" data-name="Grupo 763">
            <g id="Grupo_762" data-name="Grupo 762" clipPath="url(#clip-path)">
              <path
                id="Caminho_1682"
                data-name="Caminho 1682"
                d="M247.033,231.259c-6.448.217-10.486,10.52-15.512,13.527-2.655,1.6-5.248,1.5-7.113-1.118-4.978-6.949,1.85-15.522,8.274-20.317-4.592,1.889-8.4,5.209-13.358,6.149-3,.549-5.33-.559-6.028-3.609C211.112,216.417,223,212.2,231.15,212c-3.956-2.747-15.281-10.216-9.267-13.888,6.1-3.686,19.391,6.992,23.728,10.51,6.375,5.171,13.975,12.9,14.259,20.982.376,9.44-7.874,3.633-12.838,1.653"
                transform="translate(-213.03 -197.354)"
              />
            </g>
          </g>
        </g>
        <path
          id="Caminho_1683"
          data-name="Caminho 1683"
          d="M241.6,225.969c-8.24-3.749-24.075,1.648-25.424-1.475-2.728-6.346,9.927-10.7,16.755-11.546-6.289-1.706-18.341-4.419-17.974-10.486.453-7.407,18.49-1.036,27.617,5.961,11.5,8.819,11.421,17.324,9.522,19.03-2.607,2.337-9.137-.867-10.5-1.484"
          transform="translate(820.851 760.547)"
          fill="#027BC3"
        />
        <path
          id="Caminho_1684"
          data-name="Caminho 1684"
          d="M273.362,202.9c-38.84,35.236-47.288,91.709-41.284,141.69.159,1.132,1.913.892,1.957-.169.429-11.305.516-22.528.973-33.7.227-.5.414-.973.636-1.479,10.043-6.737,19.333-14.423,25.323-25.213,6.722-12.062,9.705-25.839,14.982-38.5.487-1.214-.834-.9-1.725-.3-23.41,15.931-31.356,33.732-38.821,57.639a201.589,201.589,0,0,1,5.4-37.462c5.792-22.74,16.394-45.091,33.511-61.48.636-.626-.279-1.653-.954-1.026"
          transform="translate(879.609 774.116)"
          fill="#263238"
        />
        <path
          id="Caminho_1685"
          data-name="Caminho 1685"
          d="M250.838,229.882c6.433.463,10.081,10.915,14.987,14.11,2.593,1.7,5.185,1.7,7.151-.848,5.238-6.756-1.258-15.579-7.5-20.615,4.52,2.062,8.2,5.522,13.112,6.65,2.978.665,5.349-.357,6.163-3.378,2.544-9.387-9.17-14.047-17.309-14.558,4.053-2.593,15.656-9.628,9.782-13.527-5.951-3.918-19.642,6.25-24.109,9.6-6.563,4.925-14.452,12.356-15.045,20.427-.733,9.421,7.734,3.927,12.765,2.14"
          transform="translate(909.005 751.68)"
          fill="#027BC3"
        />
        <g
          id="Grupo_767"
          data-name="Grupo 767"
          transform="translate(1147.029 948.509)"
          opacity="0.1"
        >
          <g id="Grupo_766" data-name="Grupo 766">
            <g
              id="Grupo_765"
              data-name="Grupo 765"
              clipPath="url(#clip-path-2)"
            >
              <path
                id="Caminho_1686"
                data-name="Caminho 1686"
                d="M250.838,229.882c6.433.463,10.081,10.915,14.987,14.11,2.593,1.7,5.185,1.7,7.151-.848,5.238-6.756-1.258-15.579-7.5-20.615,4.52,2.062,8.2,5.522,13.112,6.65,2.978.665,5.349-.357,6.163-3.378,2.544-9.387-9.17-14.047-17.309-14.558,4.053-2.593,15.656-9.628,9.782-13.527-5.951-3.918-19.642,6.25-24.109,9.6-6.563,4.925-14.452,12.356-15.045,20.427-.733,9.421,7.734,3.927,12.765,2.14"
                transform="translate(-238.024 -196.829)"
              />
            </g>
          </g>
        </g>
        <path
          id="Caminho_1687"
          data-name="Caminho 1687"
          d="M249.312,224.592c8.38-3.431,24,2.559,25.463-.506,2.968-6.24-9.512-11.074-16.3-12.177,6.347-1.465,18.495-3.72,18.355-9.792-.169-7.421-18.432-1.74-27.82,4.906-11.826,8.375-12.076,16.876-10.24,18.654,2.516,2.433,9.161-.516,10.544-1.084"
          transform="translate(908.608 758.484)"
          fill="#027BC3"
        />
        <path
          id="Caminho_1688"
          data-name="Caminho 1688"
          d="M255.2,326.568c-2.867,18.572-6.424,37.771-12.062,55.711a1.386,1.386,0,0,1-2.713-.549c2.554-17.165,7.2-34.017,9.754-51.186,2.593-17.367,3.98-34.98,2.983-52.55-1.634-28.933-9.493-59.706-28.369-82.345-.414-.535.467-1.291.891-.8,37.795,44.069,31.786,98.1,31.752,102.941,5.349-18.244,10.862-39.154,31.1-57.735.872-.8,1.552-.419,1.407.944-3.619,33.838-13.026,51.5-32.643,69.595-.559,5.363-1.248,10.7-2.1,15.97"
          transform="translate(858.048 743.508)"
          fill="#263238"
        />
        <path
          id="Caminho_1689"
          data-name="Caminho 1689"
          d="M250.383,233.248c-6.8-3.021-16.177,5.638-22.9,6.226-3.561.323-6.2-1.079-6.819-4.737-1.667-9.72,9.734-15.175,18.813-16.919-5.72-.352-11.344,1.176-16.953-.342-3.388-.94-5.248-3.267-4.438-6.78,2.506-10.929,16.953-9.32,25.511-5.421-2.718-4.843-10.708-18.293-2.617-19.078,8.182-.752,16.6,17.016,19.329,22.851,4.009,8.573,8,20.413,4.231,28.947-4.366,9.985-10-.2-14.153-4.747"
          transform="translate(832.116 710.977)"
          fill="#027BC3"
        />
        <g
          id="Grupo_770"
          data-name="Grupo 770"
          transform="translate(1050.01 897.154)"
          opacity="0.1"
        >
          <g id="Grupo_769" data-name="Grupo 769">
            <g
              id="Grupo_768"
              data-name="Grupo 768"
              clipPath="url(#clip-path-3)"
            >
              <path
                id="Caminho_1690"
                data-name="Caminho 1690"
                d="M250.383,233.248c-6.8-3.021-16.177,5.638-22.9,6.226-3.561.323-6.2-1.079-6.819-4.737-1.667-9.72,9.734-15.175,18.813-16.919-5.72-.352-11.344,1.176-16.953-.342-3.388-.94-5.248-3.267-4.438-6.78,2.506-10.929,16.953-9.32,25.511-5.421-2.718-4.843-10.708-18.293-2.617-19.078,8.182-.752,16.6,17.016,19.329,22.851,4.009,8.573,8,20.413,4.231,28.947-4.366,9.985-10-.2-14.153-4.747"
                transform="translate(-217.894 -186.177)"
              />
            </g>
          </g>
        </g>
        <path
          id="Caminho_1691"
          data-name="Caminho 1691"
          d="M245.19,227.969c-6.665-8.038-25.81-10.414-25.637-14.327.366-7.961,15.686-6.11,23.2-3.547-5.672-4.939-16.808-13.821-13.372-19.926,4.2-7.46,19.7,8.226,25.651,20.085,7.493,14.939,3.132,23.724.3,24.538-3.884,1.108-9.045-5.5-10.144-6.824"
          transform="translate(838.447 718.845)"
          fill="#027BC3"
        />
        <path
          id="Caminho_1692"
          data-name="Caminho 1692"
          d="M303.351,224.286,320.02,351.644H218.587c2.091-11.286,20.967-127.5,20.967-127.5Z"
          transform="translate(834.762 855.973)"
          fill="#027BC3"
        />
        <path
          id="Caminho_1693"
          data-name="Caminho 1693"
          d="M36.835,251.463c354.093-1.508,751.305-1.518,1105.4,0-354.093,1.518-751.305,1.508-1105.4,0"
          transform="translate(140.669 955.98)"
          fill="#263238"
        />
        <path
          id="Caminho_1694"
          data-name="Caminho 1694"
          d="M128.807,269.629C154.014,326.256,252.638,508.623,313.5,519.547c39.934,7.171,199.507-14.1,225.717-52.969C550.639,449.635,526.183,335.263,505,340.607S356.037,367.53,340.106,363.516C324.266,359.526,201.1,227.7,155.744,195.5c-78.216-55.519-40.513,43.63-26.938,74.129"
          transform="translate(411.445 683.265)"
          fill="#eb9481"
        />
        <path
          id="Caminho_1695"
          data-name="Caminho 1695"
          d="M467.349,341.932l9.619,172.816s-126.067,27.714-181.09,8.7c-66.814-23.078-164.57-229.977-177.957-263.989-21.969-55.827-15.883-89.636,15.257-81.367,12.1,3.108,28.012,12.64,47.514,29.824C201.1,225.807,318.3,341.937,355.29,363.878c20.688,12.274,112.059-21.945,112.059-21.945"
          transform="translate(400.343 675.396)"
          fill="#027BC3"
        />
        <path
          id="Caminho_1696"
          data-name="Caminho 1696"
          d="M181,368.634c-2.183-25.5-7.325-130.154-6.905-152.841.029-1.576,1.031-1.937,1.214-.3,8.39,76.245,5.831,127.9,6.693,153.019.024.626-.945.771-1,.12"
          transform="translate(664.76 818.869)"
          fill="#263238"
        />
        <g
          id="Grupo_773"
          data-name="Grupo 773"
          transform="translate(505.175 852.257)"
          opacity="0.1"
        >
          <g id="Grupo_772" data-name="Grupo 772">
            <g
              id="Grupo_771"
              data-name="Grupo 771"
              clipPath="url(#clip-path-4)"
            >
              <path
                id="Caminho_1697"
                data-name="Caminho 1697"
                d="M117.906,259.435c13.363,34.017,111.177,240.95,177.953,264,16.456,5.691,39.37,7.18,63.34,6.544L129.028,177.224c-6.616-1.022-11.951.1-15.811,3.2-12.409,9.739-11.209,38.657,4.689,79.006"
                transform="translate(-104.832 -176.858)"
              />
            </g>
          </g>
        </g>
        <path
          id="Caminho_1698"
          data-name="Caminho 1698"
          d="M59.968,533.164l346.118-.814L386.959,413.342s27.371-33.217,22.191-69.252C403.474,304.6,332.284,204.534,326.289,198.9c-24.085-22.615-49.606-26.677-74.11-18.514h-.058C138.955,251.523,59.968,533.164,59.968,533.164"
          transform="translate(229.012 674.048)"
          fill="#027BC3"
        />
        <path
          id="Caminho_1699"
          data-name="Caminho 1699"
          d="M123.7,191.294c6.689,11.132,14.076,21.887,21.078,32.865-2.819-7.773-5.595-15.56-8.7-23.227-.063-.154.154-.284.236-.13a160.419,160.419,0,0,1,11.98,28.962A219.974,219.974,0,0,1,162.285,255.6c4.809,10.775,8.452,22.051,9.382,33.872.93,11.8-1.1,23.714-4.915,34.865-4.424,12.934-10.871,25.015-17.536,36.9-.882,1.576-3.07.12-2.337-1.446,9.821-20.933,20.664-42.956,19.68-66.694-.974-23.444-11.9-45.1-22.62-65.45-6.453-12.259-13.025-24.576-21.01-35.93-.323-.458.477-.925.776-.429"
          transform="translate(469.165 729.719)"
          fill="#263238"
        />
        <path
          id="Caminho_1700"
          data-name="Caminho 1700"
          d="M151.224,245.221l-6.939,39.346H362.437c11.826,0,23.121-9.6,25.208-21.43l3.156-17.917Z"
          transform="translate(551.01 936.475)"
          fill="#dadada"
        />
        <path
          id="Caminho_1701"
          data-name="Caminho 1701"
          d="M140.886,263.138l3.166-17.917H186.9l-3.156,17.917c-2.087,11.826-13.377,21.43-25.208,21.43a17.8,17.8,0,0,1-14.057-6.269,18.511,18.511,0,0,1-3.595-15.16"
          transform="translate(536.758 936.475)"
          fill="#eaeaea"
        />
        <path
          id="Caminho_1702"
          data-name="Caminho 1702"
          d="M205.133,203.993l-56.4,319.86H345.454c11.826,0,23.121-9.6,25.208-21.43l56.4-319.859H230.34a25.425,25.425,0,0,0-16.269,6.269,26.252,26.252,0,0,0-8.939,15.16"
          transform="translate(567.993 697.19)"
          fill="#dadada"
        />
        <path
          id="Caminho_1703"
          data-name="Caminho 1703"
          d="M174.695,182.564,163.3,247.176H402.88l7.614-43.182c2.087-11.826-5.821-21.43-17.647-21.43Z"
          transform="translate(623.638 697.194)"
          fill="#eaeaea"
        />
        <path
          id="Caminho_1704"
          data-name="Caminho 1704"
          d="M166.47,203.993l-7.614,43.187H201.71l7.614-43.187c2.087-11.826-5.821-21.43-17.647-21.43a25.425,25.425,0,0,0-16.269,6.269,26.252,26.252,0,0,0-8.939,15.16"
          transform="translate(606.656 697.19)"
          fill="#dadada"
        />
        <path
          id="Caminho_1705"
          data-name="Caminho 1705"
          d="M300.269,208.232c8.009,22.827,31.111,52.574,31.352,70.549.222,16.235-13.686,20.451-13.686,20.451s16.77,10.717,14.3,27.068c-1.267,8.414-15.825,13.777-15.825,13.777s13.064,9.17,9.975,23.579C323.92,375.13,309.7,378.787,309.7,378.787s8.433,14.64,2.173,25.526c-12.693,22.08-112.888,11.435-124.443-27.121-5.388-17.97,18.948-14.042,18.948-14.042s-33.626-14.1-31.964-34.812,32.976-13.7,32.976-13.7-32.465-13.951-28.571-35.25c4.053-22.177,34.166-9.8,34.166-9.8s-20.673-17.469-13.956-33.091c11.083-25.8,49.707,12.225,72.284,9.344l16.008-35.019Z"
          transform="translate(665.858 795.218)"
          fill="#eb9481"
        />
        <path
          id="Caminho_1706"
          data-name="Caminho 1706"
          d="M230.241,279.472c-5.691-22.273,23.656-45.91,21.271-67.995-3.224-29.872-39.129-4.742-50.945,36.889l-3.489,33.93Z"
          transform="translate(752.618 757.676)"
          fill="#eb9481"
        />
        <path
          id="Caminho_1707"
          data-name="Caminho 1707"
          d="M283.676,258.585a.266.266,0,0,1,.043.53c-33.809,4.525-72.346-.863-102.561-17.126-.993-.53-.559-2.371.646-1.995,34.354,10.809,65.7,17.661,101.872,18.591"
          transform="translate(689.728 916.321)"
          fill="#263238"
        />
        <path
          id="Caminho_1708"
          data-name="Caminho 1708"
          d="M292.44,256.981c.385.01.376.66-.039.713-34.865,4.477-78.1-2.785-110.724-25.632-1.118-.781-1.335-2.607.014-1.8,22.846,13.546,78.182,26.046,110.748,26.721"
          transform="translate(690.298 878.574)"
          fill="#263238"
        />
        <path
          id="Caminho_1709"
          data-name="Caminho 1709"
          d="M182.719,220.548c32.128,20.7,67.657,29.01,105.38,32.306.492.043.381.737-.039.81-18.895,3.417-42.72-1.19-60.954-6.342-17.469-4.935-32.234-12.9-45.11-25.651-.5-.491.034-1.566.723-1.123"
          transform="translate(694.273 841.851)"
          fill="#263238"
        />
        <path
          id="Caminho_1710"
          data-name="Caminho 1710"
          d="M103.462,255.524c.467,6.086,22.625,48.526,49.664,51.4,8.809.92,15.984-46.926,15.984-46.926l1.34-3.412,18.683-47.76L136.77,162.6l-7.633-6.231s-4.25,13.4-9.378,30.142c-.241.781-.482,1.557-.732,2.39-.313.94-.622,1.817-.872,2.713-.8,2.491-1.6,5.036-2.352,7.595-.573,1.937-1.147,3.826-1.677,5.884-.588,2.058-1.113,4.057-1.7,6.115-5.344,20.37-9.127,41.741-8.963,44.319"
          transform="translate(395.092 597.143)"
          fill="#eb9481"
        />
        <path
          id="Caminho_1711"
          data-name="Caminho 1711"
          d="M106.84,186.512c8.105,41.553,37.082,62.448,50.69,70.076l18.683-47.76-52.362-46.237-7.638-6.226s-4.25,13.4-9.373,30.147"
          transform="translate(408.012 597.143)"
          fill="#263238"
        />
        <path
          id="Caminho_1712"
          data-name="Caminho 1712"
          d="M265.343,252.6c-11.859,89.222-66.636,92.2-85.873,89.617-17.464-2.337-76.625-12.524-74.052-102.5S153.227,127.062,193.445,130.3s83.757,33.082,71.9,122.3"
          transform="translate(402.272 496.48)"
          fill="#eb9481"
        />
        <path
          id="Caminho_1713"
          data-name="Caminho 1713"
          d="M144.892,162.3a59.228,59.228,0,0,1-5.7-2.477c-2.178-.974-4.014-1.528-5.335-3.638a3.2,3.2,0,0,1,.79-3.826c2.409-1.846,5.373-1.311,8.043-.246a11.667,11.667,0,0,1,6.342,5.508c1.465,2.641-1.475,5.556-4.139,4.679"
          transform="translate(509.891 577.202)"
          fill="#263238"
        />
        <path
          id="Caminho_1714"
          data-name="Caminho 1714"
          d="M125.847,157.021a58.16,58.16,0,0,0,6.216-.039c2.385-.039,4.289.173,6.332-1.243a3.2,3.2,0,0,0,.781-3.831c-1.489-2.641-4.424-3.32-7.3-3.388a11.642,11.642,0,0,0-8,2.573c-2.385,1.846-.829,5.686,1.966,5.927"
          transform="translate(468.613 567.164)"
          fill="#263238"
        />
        <path
          id="Caminho_1715"
          data-name="Caminho 1715"
          d="M132.844,154.406c0-.236-.52.2-.559.448-.9,6.067-2.949,12.862-8.766,13.878-.178.034-.217.337-.01.347,6.828.265,9.455-8.785,9.334-14.674"
          transform="translate(471.137 589.402)"
          fill="#263238"
        />
        <path
          id="Caminho_1716"
          data-name="Caminho 1716"
          d="M132.237,153.019c-9.565-2.491-13.84,16.828-4.978,19.131,8.722,2.27,13-17.044,4.978-19.131"
          transform="translate(467.495 583.521)"
          fill="#263238"
        />
        <path
          id="Caminho_1717"
          data-name="Caminho 1717"
          d="M134.273,155.551c.1-.217.4.385.337.631-1.557,5.932-2.347,12.987,2.6,16.206.154.1.067.4-.125.323-6.38-2.443-5.243-11.792-2.814-17.16"
          transform="translate(505.417 593.856)"
          fill="#263238"
        />
        <path
          id="Caminho_1718"
          data-name="Caminho 1718"
          d="M140.586,154.518c9.773,1.475,6.106,20.914-2.949,19.55-8.91-1.344-5.243-20.784,2.949-19.55"
          transform="translate(505.87 589.873)"
          fill="#263238"
        />
        <path
          id="Caminho_1719"
          data-name="Caminho 1719"
          d="M123.864,165.2c1.041,1.769,1.942,4.057,4.043,4.814,2.014.728,4.679.448,6.761.227a.213.213,0,0,1,.174.371,8.254,8.254,0,0,1-8.375,1.19c-2.554-1.19-3.253-3.86-3.243-6.486,0-.366.492-.371.641-.116"
          transform="translate(470.576 630.2)"
          fill="#263238"
        />
        <path
          id="Caminho_1720"
          data-name="Caminho 1720"
          d="M147.146,208.38s-.636,9.219-1.518,13.445c-.082.4-1.142.371-2.6.154-.072.01-.116.029-.164-.029-7.706-.4-14.683-4.539-17.946-11.58a.445.445,0,0,1,.752-.453c4.718,5.253,10.65,7.594,17.247,9.088.545-1.108.559-15.825,1.238-15.734,2.352.284,6.578,2.8,9.141,4.178,1.879-19.165.67-38.474,2.824-57.528a.589.589,0,0,1,1.161-.043c2.7,20.148,1.026,42.252-.4,62.092-.154,2.472-8.192-2.516-9.734-3.59"
          transform="translate(476.906 570.571)"
          fill="#263238"
        />
        <path
          id="Caminho_1721"
          data-name="Caminho 1721"
          d="M116.286,258.089c23.285,4.756,30.272-32.065,30.272-32.065s26.928,2.761,37.192-39.568c0,0,7.705,28.258,42.546,40.5,7.378,2.593-14.24-22.047-14.24-22.047S237.173,238.457,295.809,239c4.149.039,12-61.643-28.432-90.658s-87.453-24.928-120.386-2.279c0,0-31.732,11.165-41.39,41.264s1.634,68.91,10.684,70.761"
          transform="translate(389.173 488.147)"
          fill="#263238"
        />
        <path
          id="Caminho_1722"
          data-name="Caminho 1722"
          d="M138.212,207.906a29.86,29.86,0,1,1,37.9-28.06v0a29.849,29.849,0,0,1-37.9,28.056m15.006-53.673a25.614,25.614,0,0,0-6.361-.944,25.85,25.85,0,1,0,6.361.944"
          transform="translate(444.475 570.125)"
          fill="#eaeaea"
        />
        <path
          id="Caminho_1723"
          data-name="Caminho 1723"
          d="M147.583,210.178c-11.006-3.074-17.84-17.136-15.421-32.532A35.632,35.632,0,0,1,141.636,158c5.05-5.074,11.132-7.349,17.122-6.409,6.009.935,11.079,4.973,14.336,11.349a35.654,35.654,0,0,1,2.993,21.6C173.528,200.8,161.6,212.5,149.5,210.6a16.9,16.9,0,0,1-1.913-.419m12.038-54.314a12.858,12.858,0,0,0-1.479-.323c-4.694-.737-9.551,1.137-13.681,5.277a31.731,31.731,0,0,0-8.356,17.444c-2.212,14.1,4.038,26.832,14.009,28.388,9.932,1.561,19.815-8.635,22.032-22.721a31.691,31.691,0,0,0-2.607-19.174c-2.376-4.65-5.855-7.754-9.917-8.891"
          transform="translate(502.858 578.132)"
          fill="#eaeaea"
        />
        <path
          id="Caminho_1724"
          data-name="Caminho 1724"
          d="M130.1,161.626l-2.062-3.421c5.065-3.055,16.206-6.481,22.2,2.361l-3.3,2.236c-5.359-7.927-16.365-1.451-16.832-1.176"
          transform="translate(488.953 591.602)"
          fill="#eaeaea"
        />
        <path
          id="Caminho_1725"
          data-name="Caminho 1725"
          d="M103.491,167.129l-3.219-2.361c1.1-1.5,11.021-14.664,20.051-14.712,8.438-.019,60.525,13.233,62.737,13.8l-.988,3.87c-14.929-3.817-55.21-13.715-61.725-13.676-5.966.029-14.187,9.45-16.857,13.078"
          transform="translate(382.929 573.049)"
          fill="#eaeaea"
        />
        <path
          id="Caminho_1726"
          data-name="Caminho 1726"
          d="M135.98,176.757s-13.083-31.689-27.048-27.612-8.375,47.032,5.026,55.716,21.685-3.561,21.685-3.561Z"
          transform="translate(384.977 568.187)"
          fill="#eb9481"
        />
        <path
          id="Caminho_1727"
          data-name="Caminho 1727"
          d="M103.091,151.412a.206.206,0,0,0-.164.371c9.32,6.428,12.606,17.126,14.192,27.815-2.2-4.467-5.889-7.469-11.773-4.968-.308.13-.149.6.154.607,4.395.034,7.262,1.436,9.349,5.426,1.479,2.824,2.183,6.139,3.012,9.2.275,1.022,2.038.9,1.923-.231-.01-.082-.024-.169-.034-.255,3.161-13.006-2.93-33.54-16.659-37.968"
          transform="translate(392.725 578.189)"
          fill="#263238"
        />
        <path
          id="Caminho_1728"
          data-name="Caminho 1728"
          d="M179.464,182.154l-12.587,41.115L113.19,177.162,100.564,194.5s55.128,75.821,55.123,72.187l13.474-35.669,25.68,21.834-7.012-59.7Z"
          transform="translate(384.044 676.564)"
          fill="#eaeaea"
        />
        <path
          id="Caminho_1729"
          data-name="Caminho 1729"
          d="M193.947,210.313c-1.489-9.816-2.713-19.748-4.655-29.487-.067-.342-.626-.453-.679-.034-1.166,9.7,4.727,47.216,5.45,56.27-.034-.034-.077-.067-.111-.1-1.075-.949-23.709-19.117-23.892-18.779-3.089,5.739-12.992,31.723-13.618,34.065-8.722-12.047-45.423-59.34-55.49-70.578a.345.345,0,0,0-.564.4c7.893,12.833,55.422,75.315,56,75.093.434.279,12.765-31.732,14.057-37.693,3.913,4.048,26.962,24.76,26.909,23.285a311.768,311.768,0,0,0-3.407-32.441"
          transform="translate(383.141 689.391)"
          fill="#263238"
        />
        <path
          id="Caminho_1730"
          data-name="Caminho 1730"
          d="M271.17,240.21c-20.866,89.805-79.931,190.3-117.321,219.829-22.76,17.974-95.641,18.134-98.913-29.01S194.79,236.061,215.849,207.3,290,159.18,271.17,240.21"
          transform="translate(209.583 696.666)"
          fill="#eb9481"
        />
        <path
          id="Caminho_1731"
          data-name="Caminho 1731"
          d="M271.17,240.21c-20.866,89.805-79.931,190.3-117.321,219.829-22.76,17.974-95.641,18.134-98.913-29.01S194.79,236.061,215.849,207.3,290,159.18,271.17,240.21"
          transform="translate(209.583 696.666)"
          fill="#027BC3"
        />
        <path
          id="Caminho_1732"
          data-name="Caminho 1732"
          d="M62.807,432.683a96.382,96.382,0,0,0,29.656.178,80.356,80.356,0,0,0,27.347-9.782c17.844-10.264,29.786-27.632,40.407-44.753q17.456-28.14,32.711-57.572c10.187-19.622,19.526-39.664,28.562-59.836,4.554-10.158,8.717-20.48,13.035-30.74,4.289-10.178,8.452-20.4,12.221-30.788.949-2.607,1.85-5.238,2.747-7.864.082-.246.467-.14.386.111-3.373,10.741-6.587,21.569-10.221,32.219-.472,1.388-.988,2.752-1.465,4.135,3.773-8.028,7.638-16.008,11.667-23.911.241-.472.892-.048.689.414-3.585,8.2-7.339,16.322-11.19,24.4-1.894,3.971-3.821,7.927-5.739,11.888q-2.754,7.416-5.672,14.77-12.57,31.472-27.964,61.629-15.353,30.157-33.024,59.065c-10.843,17.734-22.49,35.968-40.031,47.8-16.384,11.05-37.881,15.7-57.306,11.551a53.836,53.836,0,0,1-7.074-2.019.467.467,0,0,1,.26-.9"
          transform="translate(237.613 730.885)"
          fill="#263238"
        />
        <path
          id="Caminho_1733"
          data-name="Caminho 1733"
          d="M69.576,268.016c14.876-36.209,39-85.849,55.254-93.357,17.054-7.879,78.134-33.178,94.224-34.479s73.165,15.4,83.459,25.521c8.236,8.1-6.028,27.4-38.561,24.629-30.417-2.588-37.563-4.7-46.314-3.33-8.934,1.4-34.956,22.413-32.658,35.284,1.359,7.585,19.039,20.639,32.744,18.254s22.008-15.806,38.985-23.646,41.351-.063,41.057,6.983-9.267,8.057-27.781,27.492c-11.9,12.5-20.49,37.3-45.428,57.648C212.028,319.245,187.2,348.66,187.2,348.66Z"
          transform="translate(265.704 535.059)"
          fill="#eb9481"
        />
        <path
          id="Caminho_1734"
          data-name="Caminho 1734"
          d="M81.041,183.333c16.755-15.666,56.005-41.587,89.246-45.4,22.466-2.578,84.013,49.808,78.5,62.732s-17.55,9.874-34.8,3.171c-6.284-2.438-37.409-23.584-45.1-17.893-11.064,8.178-30.836,40.768-30.836,40.768Z"
          transform="translate(309.488 526.386)"
          fill="#eb9481"
        />
        <path
          id="Caminho_1735"
          data-name="Caminho 1735"
          d="M147.439,181.584c-4.988-16.64-22.119-29.241-39.568-40.2-.275-.169-.554.241-.284.41,4.443,2.795,31.757,25.029,39.582,39.968.092.169.318,0,.27-.174"
          transform="translate(410.445 539.763)"
          fill="#263238"
        />
        <path
          id="Caminho_1736"
          data-name="Caminho 1736"
          d="M81.041,183.326c16.755-15.666,56.005-41.587,89.246-45.4,22.466-2.578,66.853,55.61,61.335,68.53s-23.063,9.546-40.44-2.009c-5.614-3.735-22.548-23.622-30.243-17.936-11.059,8.178-38.156,28.576-38.156,28.576Z"
          transform="translate(309.488 526.393)"
          fill="#eb9481"
        />
        <path
          id="Caminho_1737"
          data-name="Caminho 1737"
          d="M93.424,203.289c2.679-2.462,3.6-3.33,6.327-5.749,2.708-2.4,5.465-4.771,8.342-6.973,2.487-1.9,4.988-4.072,8.023-5.031a13.976,13.976,0,0,1,8.872.516c6.457,2.318,11.353,8.057,16.138,12.727,5.344,5.209,16.842,14.394,27.073,15.9a20.266,20.266,0,0,0,8.409-.106c1.8-.5,4.207-1.07,5.677-2.544,6.2-6.216,2.959-15.97-1.036-23.309-7.436-13.681-19.2-25.246-30.923-35.274-2.877-2.462-5.816-4.853-8.756-7.243-3.349-2.723-6.872-5.417-11.19-6.563-6.905-1.831-20.051,2.279-24.119,3.5-.308.092-.4-.4-.1-.487,4.939-1.561,17.7-7.474,28.716-4.033,4.033,1.258,7.31,4.337,10.611,6.9,3.46,2.679,6.833,5.479,10.139,8.346a162.474,162.474,0,0,1,18.037,18.066c5.175,6.168,10.582,13.209,13.17,20.929,2.246,6.689,1.258,15.136-3.913,20.225-4.356,4.289-11.185,3.836-14.269,3.45a33.8,33.8,0,0,1-8.785-2.347c-2.983-1.219-13.425-7.4-17.7-10.524-5.431-3.966-9.835-9.45-15.584-12.939a12.05,12.05,0,0,0-9.185-1.735c-2.949.757-5.778,2.824-8.332,4.419-5.773,3.614-9.58,5.947-14.712,10.467-1.243,1.094-2.062.443-.94-.588"
          transform="translate(354.748 525.329)"
          fill="#263238"
        />
        <path
          id="Caminho_1738"
          data-name="Caminho 1738"
          d="M78.33,219.331c6.679-7.783,23.535-42.628,33.525-52.068s52.41-40.079,67.1-34.494c18.461,7.012-10.158,36.161-23.748,47.707s-18.076,35.944-18.076,35.944Z"
          transform="translate(299.135 504.454)"
          fill="#eb9481"
        />
        <path
          id="Caminho_1739"
          data-name="Caminho 1739"
          d="M128.292,138.082c-4.684,3.556-9.093,7.7-13.4,11.7-4.082,3.8-8.611,7.431-12.1,11.8a50.913,50.913,0,0,0-7.739,14.7c-.93,2.578-1.691,5.19-2.39,7.845-.766,2.891-1.258,5.879-2.067,8.751-.087.3.371.472.467.164a128.08,128.08,0,0,1,6.115-15.228,69.4,69.4,0,0,1,8.8-14.462c7-8.809,15.792-16.013,22.871-24.755.289-.357-.231-.766-.564-.516"
          transform="translate(345.946 527.028)"
          fill="#263238"
        />
        <path
          id="Caminho_1740"
          data-name="Caminho 1740"
          d="M99.137,183.063c7.262.545,12.539-2.284,17.285-5.845,4.491-3.373,8.77-7.036,13.291-10.37,4.559-3.359,9.088-6.573,14.423-8.515,5.845-2.13,12.341-2.7,18.5-3.079,6.033-.376,12.24-.029,17.806,2.6,4.559,2.144,9.325,6.857,6.867,12.4a22.091,22.091,0,0,1-5.1,6.51,56.207,56.207,0,0,1-6.322,5.142c-4.482,3.224-9.233,6.091-13.406,9.739-5.571,4.877-9.339,11.508-13.454,17.623-1.518,2.26-11.242,17.681-14.953,22.895a90.46,90.46,0,0,1-10.428,12.462,126.189,126.189,0,0,1-11.961,9.744,75.716,75.716,0,0,0-12.491,11.19c-.169.2-.448-.077-.332-.28,4.78-8.635,13.276-14.428,20.991-20.133,10.019-7.411,32.229-45.649,36.248-50.839,6.693-8.645,16.866-12.785,24.514-20.307,1.754-1.73,4.438-4.207,4.385-6.91-.048-2.655-2.458-4.708-4.573-5.831-5.262-2.785-11.7-2.607-17.435-2.043-5.927.578-12.081,1.012-17.767,2.94a58.125,58.125,0,0,0-14.187,7.407c-5.007,3.465-9.831,7.079-15.078,10.163-4.963,2.92-10.64,4.944-16.967,3.8-.289-.053-.159-.477.145-.453"
          transform="translate(377.424 592.414)"
          fill="#263238"
        />
        <path
          id="Caminho_1741"
          data-name="Caminho 1741"
          d="M125.539,149.3c-4.019-1.966-8-4.086-12.057-5.956-.106-.048-.058-.217.053-.174,4.829,1.913,9.8,3.5,14.649,5.378,3.831,1.479,7.811,3.648,9.416,7.667,1.508,3.768.2,8-2.255,11.05-2.766,3.421-6.751,5.233-10.573,7.18a.183.183,0,0,1-.174-.323c5.826-3.378,14.886-10.182,10.134-17.921-2.067-3.368-5.763-5.224-9.194-6.9"
          transform="translate(433.15 546.72)"
          fill="#263238"
        />
        <path
          id="Caminho_1742"
          data-name="Caminho 1742"
          d="M112.04,163.12c4.588.135,9.638.039,13.7-2.385a9.709,9.709,0,0,0,4.978-7.4c.12-.978.226-2.5-.434-3.306-.2-.246.251-.448.313-.169a16.065,16.065,0,0,0,.742,1.788,6.92,6.92,0,0,1,.342,1.6,8.224,8.224,0,0,1-.265,3.195,10.447,10.447,0,0,1-4.053,5.609c-4.564,3.272-10.24,2.978-15.387,1.451a.194.194,0,0,1,.067-.376"
          transform="translate(427.122 571.777)"
          fill="#263238"
        />
        <path
          id="Caminho_1743"
          data-name="Caminho 1743"
          d="M248.825,249.715c-9.966,36.021-85.815,228.064-107,255.156s-72.279,32.952-86.572-16.037S115.457,205.7,131.205,169.071c8.77-20.394,121.224,67.614,117.62,80.644"
          transform="translate(204.075 634.009)"
          fill="#eb9481"
        />
        <path
          id="Caminho_1744"
          data-name="Caminho 1744"
          d="M266.745,256.835,117.879,170.509S54.241,388.921,53.1,456.275c-.824,48.348,57.1,84.456,102.888,19.117S266.745,256.835,266.745,256.835"
          transform="translate(202.746 651.157)"
          fill="#027BC3"
        />
        <path
          id="Caminho_1745"
          data-name="Caminho 1745"
          d="M198.318,256.646C175.77,244.546,85.3,191.678,66.526,178.932c-1.3-.887-1.055-1.923.419-1.181,68.448,34.624,110.314,64.993,131.826,77.989.535.323.125,1.214-.453.906"
          transform="translate(250.823 677.845)"
          fill="#263238"
        />
        <path
          id="Caminho_1746"
          data-name="Caminho 1746"
          d="M90.713,340.208c15.719-29.521,30.817-59.359,44.387-89.93q5.7-12.83,11.146-25.762c3.484-8.3,7.05-16.6,10.168-25.049-3.118-2.178-16.8-11.117-18.278-12.5a.611.611,0,0,1,.631-.988c1.807.684,17.69,9.888,21.059,12.394.472.352-6.293,16.8-9.373,24.663s-6.25,15.685-9.527,23.468q-9.729,23.116-20.61,45.722Q108.634,316.6,95.724,340.281c-7.411,13.536-25.251,37.934-5.012-.072"
          transform="translate(310.736 710.124)"
          fill="#263238"
        />
        <path
          id="Caminho_1747"
          data-name="Caminho 1747"
          d="M208.1,296.636s29.193,3.956,30.277-16.167-28.369-19.324-28.369-19.324Z"
          transform="translate(794.729 997.268)"
          fill="#dd6a57"
        />
        <rect
          id="Retângulo_1491"
          data-name="Retângulo 1491"
          width="35.542"
          height="207.166"
          transform="translate(797.126 1282.848) rotate(-86.922)"
          fill="#027BC3"
        />
        <rect
          id="Retângulo_1492"
          data-name="Retângulo 1492"
          width="41.498"
          height="8.233"
          transform="matrix(0.054, -0.999, 0.999, 0.054, 996.723, 1296.561)"
          fill="#37474f"
        />
        <rect
          id="Retângulo_1493"
          data-name="Retângulo 1493"
          width="41.5"
          height="8.235"
          transform="matrix(0.054, -0.999, 0.999, 0.054, 987.634, 1296.074)"
          fill="#37474f"
        />
        <path
          id="Caminho_1748"
          data-name="Caminho 1748"
          d="M193.49,258.844l-26.253,11.98-8.669,3.923,8.2,4.9,24.817,14.727,5.344-3.315s-4.886-3.711-4.964-3.792l5.349-3.311s-4.891-3.711-4.968-3.792l5.344-3.238s-4.886-3.788-4.959-3.87l5.339-3.233s-4.886-3.788-4.959-3.87l5.344-3.238s-4.891-3.788-4.963-3.87"
          transform="translate(605.552 988.5)"
          fill="#eaeaea"
        />
        <path
          id="Caminho_1749"
          data-name="Caminho 1749"
          d="M167.236,261.331l-8.669,3.918,8.2,4.906Z"
          transform="translate(605.553 997.996)"
          fill="#37474f"
        />
        <path
          id="Caminho_1750"
          data-name="Caminho 1750"
          d="M324.048,258.008l24.861,50.314a5.53,5.53,0,0,1-4.959,7.98l-258.765,0a5.53,5.53,0,0,1-4.862-8.158l27.14-50.256a5.525,5.525,0,0,1,4.862-2.9l206.765-.063a5.521,5.521,0,0,1,4.959,3.079"
          transform="translate(304.178 973.549)"
          fill="#027BC3"
        />
        <path
          id="Caminho_1751"
          data-name="Caminho 1751"
          d="M329.2,258.931l26.032,52.69a5.536,5.536,0,0,1,.573,2.448v3.585a5.531,5.531,0,0,1-5.532,5.527H84.4a5.53,5.53,0,0,1-5.527-5.527V314.18a5.507,5.507,0,0,1,.665-2.626l28.48-52.743a5.525,5.525,0,0,1,4.862-2.9l211.362-.058a5.521,5.521,0,0,1,4.959,3.079"
          transform="translate(301.22 977.074)"
          fill="#027BC3"
        />
        <g
          id="Grupo_776"
          data-name="Grupo 776"
          transform="translate(383.828 1228.478)"
          opacity="0.1"
        >
          <g id="Grupo_775" data-name="Grupo 775">
            <g
              id="Grupo_774"
              data-name="Grupo 774"
              clipPath="url(#clip-path-5)"
            >
              <path
                id="Caminho_1752"
                data-name="Caminho 1752"
                d="M324.048,258.008l24.861,50.314a5.53,5.53,0,0,1-4.959,7.98l-258.765,0a5.53,5.53,0,0,1-4.862-8.158l27.14-50.256a5.525,5.525,0,0,1,4.862-2.9l206.765-.063a5.521,5.521,0,0,1,4.959,3.079"
                transform="translate(-79.651 -254.929)"
              />
            </g>
          </g>
        </g>
        <path
          id="Caminho_1753"
          data-name="Caminho 1753"
          d="M277.911,259.9l11.435,41.924a5.534,5.534,0,0,1-5.354,6.987L88.528,308.2a5.526,5.526,0,0,1-5.036-7.773l18.365-41.312a5.537,5.537,0,0,1,5.055-3.287H272.576a5.534,5.534,0,0,1,5.335,4.077"
          transform="translate(317.002 976.967)"
          fill="#fff"
        />
        <path
          id="Caminho_1754"
          data-name="Caminho 1754"
          d="M140.68,263.643c0,2.66-3.238,4.819-7.228,4.819s-7.228-2.159-7.228-4.819,3.238-4.819,7.228-4.819,7.228,2.159,7.228,4.819"
          transform="translate(482.033 988.424)"
          fill="#fff"
        />
        <path
          id="Caminho_1755"
          data-name="Caminho 1755"
          d="M302.051,182.846a78.194,78.194,0,0,1-124.824,62.8l-38.32,9.782,15.951-35.684a78.222,78.222,0,1,1,147.193-36.9"
          transform="translate(530.472 399.537)"
          fill="#fff"
        />
        <path
          id="Caminho_1756"
          data-name="Caminho 1756"
          d="M303.338,183.344c.294,64.111-73.8,101.611-125.272,63.4l.636.125-38.281,9.951-1.85.482.781-1.759,15.8-35.751c-24.432-40.874.371-101.192,50.806-112.854,48.584-12.794,98.176,26.287,97.385,76.4m0,0c.342-49.76-48.97-88.282-97.082-75.194-49.933,12.968-71.937,67.6-49.114,112.541l-16.1,35.612-1.075-1.277,38.363-9.614c.424-.135.337-.087.636.125,50.762,38.277,124.2,1.2,124.376-62.193"
          transform="translate(529.185 399.04)"
          fill="#263238"
        />
        <path
          id="Caminho_1757"
          data-name="Caminho 1757"
          d="M177.832,190.664l-29.1-29.458,9.778-9.657,17,17.2,35.318-55.369,11.589,7.392Z"
          transform="translate(567.987 433)"
          fill="#027BC3"
        />
      </g>
    </svg>
  );
};

export default memo(SingleExpensesSVG);

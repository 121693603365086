import { TodayOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import React, { memo } from "react";
import Input from "../../../../components/inputs/DateInput";
import SelectorBox from "../SelectorBox";

function DateInput({
  disableDefault,
  size,
  variant,
  value = new Date(),
  onChange = () => {},
  disabledByRoutePolicy,
  error,
}) {
  return (
    <SelectorBox disabled={disableDefault} Icon={TodayOutlined}>
      <Tooltip
        arrow
        title="Não é permitido alterar a data da despesa, pois você não está mais vinculado(a) à política de percurso selecionada. Escolha outra política para editar."
        disableHoverListener={!disabledByRoutePolicy}
        disableFocusListener={!disabledByRoutePolicy}
      >
        <span style={{ width: "100%" }}>
          <Input
            disableHelperText
            value={value}
            disabled={disabledByRoutePolicy}
            disableOpenPicker={disabledByRoutePolicy}
            onChange={onChange}
            size={size || "small"}
            variant="outlined"
            label={"Data da despesa"}
          />
        </span>
      </Tooltip>
    </SelectorBox>
  );
}

export default memo(DateInput);

import { FormControlLabel, Switch, Tooltip, Typography } from "@mui/material";
import React from "react";

function RefundableToggle({ readOnly, refundable, onChange }) {
  return (
    <FormControlLabel
      control={
        <Tooltip
          disableHoverListener={!readOnly}
          disableFocusListener={!readOnly}
          title={
            <Typography fontSize={".8rem"} fontWeight={"500"}>
              A forma de pagamento selecionada {refundable ? "é" : "não é"}{" "}
              reembolsável
            </Typography>
          }
        >
          <Switch
            sx={{ opacity: readOnly ? 0.5 : 1 }}
            readOnly={Boolean(readOnly)}
            disableRipple={readOnly}
            checked={Boolean(refundable)}
            onClick={(event) => !readOnly && onChange(event)}
          />
        </Tooltip>
      }
      labelPlacement="start"
      label={
        <Typography
          fontWeight={"500"}
          noWrap
          variant="inherit"
          fontSize={".9rem"}
        >
          Reembolsável
        </Typography>
      }
    />
  );
}

export default RefundableToggle;

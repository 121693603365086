import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  DialogContent,
  Drawer,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { memo, useCallback, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ExpenseCardItem from "../../../../../../components/expenses/card/ExpenseCardItem";
import { selectionReducer } from "../../../../../../reducers/selectionReducer";
import { openSnackbar } from "../../../../../../store/features/base/snackbarBaseSlice";
import {
  expensesOfflineActions,
  selectSingleExpensesIds,
} from "../../../../../../store/features/expensesSlice";
import { isSelected } from "../../../../../../utils/functions/onSelect";
import NoExpensesContent from "../../../../../expenses/NoExpensesContent";

const Header = memo(
  ({ selectedNum, onClose, count, onSelectAllClick, onAdd }) => {
    return (
      <Box
        borderBottom={1}
        borderColor={"divider"}
        py={1}
        px={2}
        display={"flex"}
        alignItems={"center"}
      >
        <IconButton size="large" onClick={onClose} sx={{ mr: 1 }}>
          <Close />
        </IconButton>
        <Tooltip enterDelay={2000} title="Marcar/Desmarcar todas">
          <Checkbox
            sx={{ mr: 1 }}
            color="primary"
            indeterminate={selectedNum > 0 && selectedNum < count}
            checked={count > 0 && selectedNum === count}
            onChange={onSelectAllClick}
          />
        </Tooltip>
        {selectedNum > 0 ? (
          <Typography fontSize={"1.2rem"} color={"primary"} fontWeight={"500"}>
            {selectedNum} selecionada(s)
          </Typography>
        ) : (
          <Typography fontSize={"1.2rem"} fontWeight={"500"}>
            Selecione as despesas...
          </Typography>
        )}
        <Box flex={1} />
        <Button
          disabled={selectedNum === 0}
          onClick={onAdd}
          variant="contained"
          disableElevation
        >
          Adicionar ao relatório
        </Button>
      </Box>
    );
  }
);

const SelectFromSingleExpenses = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { requestId } = useParams();
  const expensesIds = useSelector(selectSingleExpensesIds);

  const [selected, dispatchSelected] = useReducer(selectionReducer, []);

  useEffect(() => {
    if (!open) {
      dispatchSelected({ type: "clear" });
    }
  }, [open]);

  const handleSelect = useCallback((expenseId) => {
    dispatchSelected({ type: "toggle", payload: expenseId });
  }, []);

  const handleSelectAllClick = useCallback(
    (event) => {
      if (event.target.checked) {
        dispatchSelected({
          type: "select-all",
          payload: [...expensesIds],
        });
        return;
      }
      dispatchSelected({ type: "clear" });
    },
    [expensesIds]
  );

  const handleAdd = useCallback(async () => {
    dispatch(expensesOfflineActions.addToRequest(selected, requestId));
    dispatch(
      openSnackbar({
        message: "Despesas adicionadas",
      })
    );
    onClose();
  }, [selected, requestId]);

  return (
    <Drawer
      open={open}
      data-disableselect={true}
      anchor="bottom"
      onClose={onClose}
      PaperProps={{
        sx: {
          height: "98vh",
          width: "100vw",
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
        },
      }}
    >
      <Header
        onClose={onClose}
        selectedNum={selected.length}
        count={expensesIds.length}
        onSelectAllClick={handleSelectAllClick}
        onAdd={handleAdd}
      />
      <DialogContent sx={{ p: 0 }}>
        {!Boolean(expensesIds.length) && (
          <NoExpensesContent
            title="Nenhuma despesa avulsa encontrada"
            subtitle="Crie despesas antes de adicionar a um relatório"
            disableCreation
          />
        )}
        <Box
          p={3}
          pb={10}
          sx={{
            display: "grid",
            gridGap: "18px",
            gridTemplateColumns: "repeat(auto-fill, minmax(350px,1fr))",
            gridAutoRows: "auto",
          }}
        >
          {expensesIds.map((expenseId, idx) => (
            <ExpenseCardItem
              key={expenseId}
              expenseId={expenseId}
              selected={isSelected(expenseId, selected)}
              onChangeSelected={handleSelect}
              onClick={handleSelect}
              disableActions
              settings={{
                select: true,
                remove: false,
              }}
            />
          ))}
        </Box>
      </DialogContent>
    </Drawer>
  );
};

export default SelectFromSingleExpenses;

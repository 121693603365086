import { ThemeProvider, useMediaQuery, useTheme } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { LoadScript } from "@react-google-maps/api";
import { ptBR } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//STYLES
import "cropperjs/dist/cropper.css";
import "react-medium-image-zoom/dist/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./App.css";
//

import { useThemeMode } from "./contexts/useThemeMode";

import { RESET_STATE } from "@redux-offline/redux-offline/lib/constants";
import { BrowserRouter } from "react-router-dom";
import { refreshToken } from "./api/auth";
import { HttpClient } from "./api/httpClient";
import { synchronize } from "./api/synchronizer";
import updateReceiptsAccessToken from "./api/updateReceiptsAccessToken";
import WebSockets from "./api/websockets";
import SnackbarBase from "./components/snackbar/SnackbarBase";
import ModalErrorBase from "./modals/error/ModalErrorBase";
import ModalInactiveAccount from "./modals/inactive-account/ModalInactiveAccount";
import SynchronizingLoading from "./pages/SynchronizingLoading";
import Router from "./router";
import { startScanExpenses } from "./scanner_expenses";
import {
  selectAccessExpiresAt,
  selectAccountError,
  selectAccountStatus,
  selectIsApprover,
  selectIsSyncedAll,
  selectReceiptsAccessToken,
  toggleCheckingAccess,
} from "./store/features/accountSlice";
import { fetchApprovalRequests } from "./store/features/approvalRequestsSlice";
import { setLayoutViewMode } from "./store/features/base/settingsSlice";
import { syncExpenses } from "./store/features/expensesSlice";
import { fetchUnredNotificationsTotal } from "./store/features/notificationsSlice";
import { fetchRequests } from "./store/features/requestsSlice";
import { store } from "./store/store";
import theme from "./theme";
import { accountStatusIsError } from "./utils/accountStatus";

//APIs google maps
const librariesGoogleMapsApi = ["places"];

//Tempo de duração do access token
const accessTokenRefreshTime = 420000; //7 minutos

function App(props) {
  const dispatch = useDispatch();

  //Tema
  const { mode } = useThemeMode();

  //Pegar se ja teve a primeira sincronização
  const synced = useSelector(selectIsSyncedAll);

  //Pegar se é aprovador
  const isApprover = useSelector(selectIsApprover);

  //Pegar dados de status da conta
  const accountError = useSelector(selectAccountError);
  const accountStatus = useSelector(selectAccountStatus);

  //Utilizados para estado de verificando o acesso inicial
  const checkingAccess = useSelector((state) => state.account.checkingAccess);
  const setCheckingAccess = (bool) => dispatch(toggleCheckingAccess(bool));

  const accessExpiresAt = useSelector(selectAccessExpiresAt);

  const receiptsAccess = useSelector(selectReceiptsAccessToken);

  //Estado para logado ou não
  const [isAuth, setIsAuth] = useState(false);

  //Verificar se o acesso ainda está válido
  const checkAcessIsValid = (expiresAt) => {
    if (!expiresAt) {
      return {
        isExpired: true,
        remainingDurationsMs: 0,
      };
    }
    const currentDate = new Date().getTime();
    const expiresAtTime = new Date(expiresAt).getTime();

    const isExpired =
      currentDate > expiresAtTime ||
      Math.floor((expiresAtTime - currentDate) / 60000) <= 2;

    return {
      isExpired,
      remainingDurationsMs: isExpired ? 0 : expiresAtTime - currentDate,
    };
  };

  //Verificar se é uma conta diferente entrando...
  const checkIsNewAccount = (userId) => {
    const prevUserId = window.localStorage.getItem("userId");
    const isNewAccount = prevUserId && prevUserId !== userId;
    if (isNewAccount) {
      dispatch({
        type: RESET_STATE,
      });
      dispatch({
        type: "RESET_STORE",
      });
    }
    window.localStorage.setItem("userId", userId);
  };

  let intervalRefreshToken;

  const startInitialDispatchers = () => {
    dispatch(fetchUnredNotificationsTotal());
    dispatch(
      fetchRequests({
        skip: 0,
        limit: 50,
        resync: true,
      })
    );
    dispatch(syncExpenses());
    if (isApprover) {
      dispatch(fetchApprovalRequests());
    }
  };

  function removeLoadingApplication() {
    let initialLoading = document.getElementById("load_application");
    if (initialLoading?.parentNode) {
      initialLoading.parentNode.removeChild(initialLoading);
    }
  }

  const startInitialConfiguration = async () => {
    //Fazer refresh token
    const { isAuth: ok, userId, persistLoading } = await refreshToken();

    //Configurar dados para caso autenticado
    if (ok) {
      checkIsNewAccount(userId);
      setIsAuth(true);

      //Se estiver online e sincronizado
      if (navigator.onLine && synced) {
        startInitialDispatchers();

        //Començar intervalo de atualização de token
        intervalRefreshToken = setInterval(() => {
          refreshToken();
        }, accessTokenRefreshTime);
      }
    }
    if (!persistLoading) {
      setCheckingAccess(false);
      // Removendo load_application
      removeLoadingApplication();
    }
  };

  //Zendesk webchat settings
  useEffect(() => {
    if (window.zE) {
      window.zE("messenger", "hide");
      window.zE("messenger:on", "close", function () {
        setTimeout(() => {
          window.zE("messenger", "hide");
        }, 200);
      });
    }
  }, []);

  //INITIAL EFFECT
  useEffect(() => {
    HttpClient.setup();
    HttpClient.setAuthFailedEventListener();
    startInitialConfiguration();

    return () => {
      clearInterval(intervalRefreshToken);
    };
  }, []);

  //ALL SUCCESS EFFECT
  useEffect(() => {
    if (synced && isAuth && !checkingAccess) {
      //Ligar WS
      WebSockets.start();

      //Chamar sync
      (async () => {
        await synchronize();
        await startScanExpenses(null);
      })();
    }
  }, [synced, isAuth, checkingAccess]);

  async function onlineHandler() {
    let okAuth = false;
    if (!Boolean(accessExpiresAt)) {
      const { isAuth } = await refreshToken();
      okAuth = isAuth;
    } else {
      const { isExpired } = checkAcessIsValid(accessExpiresAt);
      if (isExpired) {
        const { isAuth } = await refreshToken();
        okAuth = isAuth;
      } else {
        okAuth = true;
      }
    }
    if (okAuth) {
      WebSockets.start();
      if (!intervalRefreshToken) {
        intervalRefreshToken = setInterval(() => {
          refreshToken();
        }, accessTokenRefreshTime);
      }
      await synchronize();
      startScanExpenses(null);
    }
  }

  useEffect(() => {
    //lidar com o status da conexão
    function startOnlineHandler() {
      onlineHandler();
    }

    function offlineHandler() {
      WebSockets.setConnectionIsOnline(false);
      clearInterval(intervalRefreshToken);
    }

    function onVisibilityChange() {
      const state = store.getState();
      const accessExpiresAt = state.account.accessExpiresAt;
      const { isExpired } = checkAcessIsValid(accessExpiresAt);
      if (
        document.visibilityState === "visible" &&
        !state.account.accountError
      ) {
        if (isExpired) {
          refreshToken();
        }
      }
    }

    document.addEventListener("visibilitychange", onVisibilityChange);
    window.addEventListener("online", startOnlineHandler);
    window.addEventListener("offline", offlineHandler);
    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
      window.removeEventListener("online", startOnlineHandler);
      window.removeEventListener("offline", offlineHandler);
      clearInterval(intervalRefreshToken);
    };
  }, []);

  //Garantir os tokens atualizados de acesso para comprovantes
  useEffect(() => {
    if (
      isAuth &&
      !checkingAccess &&
      (!receiptsAccess?.token || !receiptsAccess?.expiresAt)
    ) {
      (async () => {
        const { isAuth, accessToken } = await refreshToken();
        if (isAuth) {
          await updateReceiptsAccessToken(accessToken);
        }
      })();
    }
  }, [receiptsAccess, checkingAccess, isAuth]);

  //theme
  useEffect(() => {
    if (mode === "dark") {
      document.body.style.backgroundColor = "#202124";
    } else {
      document.body.style.backgroundColor = "#FFF";
    }
  }, [mode]);

  const breakpoints = useTheme().breakpoints;
  const isSmall = useMediaQuery(breakpoints.down("md"));

  useEffect(() => {
    if (isSmall) {
      dispatch(setLayoutViewMode("grid"));
    }
  }, [isSmall]);

  return (
    <div
      onDragOver={(e) => e.preventDefault()}
      onDragLeave={(e) => e.preventDefault()}
      onDrop={(e) => e.preventDefault()}
      className="App"
      data-disableselect={true}
    >
      <ThemeProvider theme={theme(mode)}>
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          libraries={librariesGoogleMapsApi}
        >
          <LocalizationProvider
            adapterLocale={ptBR}
            dateAdapter={AdapterDateFns}
          >
            <BrowserRouter>
              {!checkingAccess &&
                (accountStatusIsError(accountStatus) ||
                Boolean(accountError) ? (
                  <ModalInactiveAccount accountError={accountError} />
                ) : !synced ? (
                  <SynchronizingLoading />
                ) : (
                  <Router />
                ))}
            </BrowserRouter>
          </LocalizationProvider>
        </LoadScript>
        <ModalErrorBase />
        <SnackbarBase />
      </ThemeProvider>
    </div>
  );
}

export default App;

import React, { memo } from "react";
import PrimaryLogo from "../assets/logo/logo-primary.svg";
import WhiteLogo from "../assets/logo/logo-white.svg";
import { useThemeMode } from "../contexts/useThemeMode";

const NotepayLogo = ({ style, disableTheme }) => {
  const { mode } = useThemeMode();

  return (
    <img
      style={{ width: "100px", ...style }}
      src={
        disableTheme ? PrimaryLogo : mode === "light" ? PrimaryLogo : WhiteLogo
      }
    />
  );
};

export default memo(NotepayLogo);

import { Timeline as TimelineMUI } from "@mui/lab";
import { Typography } from "@mui/material";
import React, { useMemo } from "react";
import EventItem from "./EventItem";

export default function Timeline({ events = [], thirdPerson = false }) {
  const orderedEvents = useMemo(() => {
    const mutableEvents = [...events]; // Cria uma cópia mutável do array events
    return mutableEvents.sort((a, b) => {
      return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
    });
  }, [events]);
  return (
    <TimelineMUI sx={{ p: 0, mt: 0.5 }} position="left">
      <Typography mb={2} variant="body2" color={"text.secondary"}>
        A linha do tempo começa aqui.
      </Typography>
      {orderedEvents?.map((event, idx) => {
        return (
          <EventItem
            thirdPerson={thirdPerson}
            key={idx.toString()}
            event={event}
            type={event.type}
          />
        );
      })}
      <Typography mt={2} variant="body2" color={"text.secondary"}>
        Fim.
      </Typography>
    </TimelineMUI>
  );
}

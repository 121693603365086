import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React, { memo } from "react";

function NavigateCarousel({ total, currentIndex, onNext, onPrev }) {
  return (
    <Box
      bgcolor={"elevation1.main"}
      sx={{
        display: "flex",
        position: "sticky",
        boxShadow: 3,
        top: 0,
        zIndex: 101,
        mb: 2,
        alignItems: "center",
        alignSelf: "center",
        borderRadius: 3,
        px: 2,
        pr: 1,
        py: 0.5,
      }}
    >
      <Typography variant="body2" fontWeight={"600"}>
        {total === 1
          ? "1 comprovante"
          : ` Comprovantes (${currentIndex + 1}/${total})`}
      </Typography>
      <Box flex={1} />
      {total > 1 && (
        <>
          <IconButton sx={{ ml: 1 }} size="small" onClick={onPrev}>
            <ChevronLeft />
          </IconButton>
          <IconButton size="small" onClick={onNext}>
            <ChevronRight />
          </IconButton>
        </>
      )}
    </Box>
  );
}

export default memo(NavigateCarousel);

import { ThumbDownAltOutlined } from "@mui/icons-material";
import { ToggleButton, Tooltip } from "@mui/material";
import React, { memo } from "react";

const ToggleRejection = ({ selected, onChange, color }) => {
  return (
    <Tooltip title="Marcar/Desmarcar como rejeitada">
      <ToggleButton
        value="check"
        size="small"
        color={color}
        sx={{ border: "none", borderRadius: 100, mr: 1 }}
        selected={selected}
        onChange={(e, v) => {
          e.stopPropagation();
          onChange();
        }}
      >
        <ThumbDownAltOutlined />
      </ToggleButton>
    </Tooltip>
  );
};

export default memo(ToggleRejection);

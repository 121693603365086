import { CloudDoneOutlined, RefreshOutlined } from "@mui/icons-material";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { synchronize } from "../../../api/synchronizer";
import WSMessenger from "../../../api/websockets/messenger";
import { selectIsSyncing } from "../../../store/features/accountSlice";

const SyncButton = memo(({ visible, onSync }) => {
  return (
    <>
      <Tooltip title={`Sincronizar`}>
        <span>
          <IconButton
            disabled={!visible}
            style={{
              transform: `scale(${visible ? 1 : 0.2})`,
              opacity: visible ? 1 : 0,
              transition: ".2s ease",
            }}
            onClick={onSync}
          >
            <RefreshOutlined />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
});

const FinishSyncIcon = memo(({ visible }) => {
  return (
    <CloudDoneOutlined
      sx={{
        position: "absolute",
        transform: `scale(${visible ? 1 : 0.2})`,
        opacity: visible ? 1 : 0,
        transition: ".2s ease",
      }}
      color="success"
      fontSize="medium"
    />
  );
});

const SyncingIndicator = memo(({ loading }) => {
  return (
    <CircularProgress
      color="primary"
      size={20}
      sx={{
        position: "absolute",
        transform: `scale(${loading ? 1 : 0.2})`,
        opacity: loading ? 1 : 0,
        transition: ".2s ease",
      }}
    />
  );
});

const ServerSyncButton = () => {
  const [mounted, setMounted] = useState(false);
  const syncing = useSelector((state) => selectIsSyncing(state));
  const [loading, setLoading] = useState(syncing || false);
  const [completed, setCompleted] = useState(false);

  const offlineQueueBusy = useSelector((state) => {
    return Boolean(state.offline.outbox?.length);
  });

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  let timeout;

  useEffect(() => {
    if (!mounted || completed) return;
    if (syncing || offlineQueueBusy) {
      setLoading(true);
      setCompleted(false);
      clearTimeout(timeout);
    } else {
      setLoading(false);
      setCompleted(true);
      timeout = setTimeout(() => {
        setCompleted(false);
      }, 2000);
    }
  }, [syncing, offlineQueueBusy, mounted]);

  const handleSync = useCallback(async () => {
    if (completed || loading) return;
    clearInterval(timeout);
    await synchronize();
    WSMessenger.sendMessage();
  }, [timeout, completed, loading]);

  return (
    <Box
      display={"flex"}
      position={"relative"}
      justifyContent={"center"}
      alignItems={"center"}
      // border={1}
      // borderColor={"divider"}
      overflow={"hidden"}
      borderRadius={100}
      mr={{ xs: -1, md: 0 }}
    >
      <SyncingIndicator loading={loading} />
      <FinishSyncIcon visible={completed && !loading} />
      <SyncButton onSync={handleSync} visible={!completed && !loading} />
      {/* <MoreButton visible={!completed && !loading} /> */}
    </Box>
  );
};

export default ServerSyncButton;

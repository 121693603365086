import { SearchOutlined } from "@mui/icons-material";
import React, { memo } from "react";
import Input from "../../inputs/text-input";

const SearchRequests = ({ value, onChange }) => {
  return (
    <Input
      autoFocus
      inputSx={{
        borderRadius: 100,
        height: 40,
        px: 2,
      }}
      LeftIcon={SearchOutlined}
      value={value}
      variant={"standard"}
      placeholder={"Pesquisar relatório"}
      onChange={onChange}
    />
  );
};

export default memo(SearchRequests);

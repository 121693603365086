import { AssignmentTurnedInOutlined } from "@mui/icons-material";
import { TableCell } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NoDataContent from "../../../../components/form/NoDataContent";
import { selectionReducer } from "../../../../reducers/selectionReducer";
import CustomTableContainer from "../../components/CustomTableContainer";
import TableItem from "./components/table-item/TableItem";

const Requests = ({ requestsIds = [] }) => {
  const navigate = useNavigate();

  const { state, pathname, search } = useLocation();

  const handleClickItem = useCallback((requestId) => {
    navigate(requestId, {
      state: {
        origin: pathname + search,
        lastVisitedRequestId: requestId,
      },
    });
  }, []);

  const [selected, dispatchSelection] = useReducer(selectionReducer, []);

  const selectionMode = useMemo(
    () => Boolean(selected.length),
    [Boolean(selected.length)]
  );

  const handleChangeSelected = useCallback((requestId) => {
    dispatchSelection({ type: "toggle", payload: requestId });
  }, []);

  const handleClearSelection = useCallback(() => {
    dispatchSelection({ type: "clear" });
  }, []);

  const handleSelectAllClick = useCallback(
    (event) => {
      if (event.target.checked) {
        dispatchSelection({ type: "select-all", payload: [...requestsIds] });
        return;
      }
      dispatchSelection({ type: "clear" });
    },
    [requestsIds]
  );

  useEffect(() => {
    if (state && state?.lastVisitedRequestId) {
      const element = document.getElementById(
        `r-${state?.lastVisitedRequestId}`
      );
      if (element) {
        element?.scrollIntoView({
          behavior: "instant",
          block: "end",
        });
      }
    }
  }, [state?.lastVisitedRequestId]);

  const isSelected = (id) => selected.includes(id);
  const scrollIntoView = (id) => Boolean(state?.lastVisitedRequestId === id);

  const renderItem = useCallback((requestId) => {
    return (
      <TableItem
        key={requestId}
        requestId={requestId}
        onClick={handleClickItem}
        onChangeSelected={handleChangeSelected}
        selected={isSelected(requestId)}
        scrollIntoView={scrollIntoView(requestId)}
      />
    );
  }, [selected]);

  return (
    <CustomTableContainer
      data={requestsIds}
      selectionMode={selectionMode}
      selected={selected}
      isAdvance={false}
      onSelectAll={handleSelectAllClick}
      onClearSelection={handleClearSelection}
      renderItem={renderItem}
      emptyComponent={
        <NoDataContent
          height={400}
          iconSize="5rem"
          Icon={AssignmentTurnedInOutlined}
          title={"Nenhum relatório para aprovar"}
          subtitle={
            "Aqui você encontrará relatórios de despesas pendentes de aprovação"
          }
        />
      }
      columns={
        <>
          <TableCell>Prestador</TableCell>
          <TableCell>Título</TableCell>
          <TableCell>Total</TableCell>
          <TableCell>Etapa</TableCell>
          <TableCell align="right"></TableCell>
        </>
      }
    />
  );
};

export default Requests;

import { Box, Paper, Typography } from "@mui/material";
import React from "react";

const PaperSection = ({
  children,
  title,
  rightContent,
  leftContent,
  sx,
  Icon,
}) => {
  return (
    <Paper
      sx={{
        borderRadius: 3,
        overflow: "hidden",
        borderColor: (t) =>
          t.palette.mode === "light"
            ? "rgba(0,0,0,.2)"
            : "rgba(255,255,255,.2)",
        ...sx,
      }}
      variant="outlined"
    >
      <Box
        p={2}
        py={2}
        pb={0.5}
        mb={0.3}
        display={"flex"}
        alignItems={"center"}
        minHeight={55}
      >
        {Icon && <Icon color="action" sx={{ mr: 1.5 }} />}
        <Typography fontSize={"1.2rem"} variant="h5" fontWeight={"600"} mr={2}>
          {title}
        </Typography>
        {leftContent}
        <Box flex={1} />
        {rightContent}
      </Box>
      <Box p={2} pt={0}>
        {children}
      </Box>
    </Paper>
  );
};

export default PaperSection;

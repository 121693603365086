import { Box, Skeleton, Typography } from "@mui/material";
import React, { memo } from "react";
import HelpIconButton from "../../../../../components/buttons/HelpIconButton";

const ResumeItem = ({ children, title, loading, helperText }) => {
  return (
    <Box flex={1}>
      <Typography
        component={"div"}
        display={"flex"}
        alignItems={"center"}
        fontWeight={"500"}
        noWrap
      >
        {title}
        {helperText && (
          <HelpIconButton size="small" title={title}>
            {helperText}
          </HelpIconButton>
        )}
      </Typography>
      {loading ? (
        <Skeleton variant="text" width={160} height={33} />
      ) : (
        <Typography variant="h5" fontWeight={"600"}>
          {children || "--"}
        </Typography>
      )}
    </Box>
  );
};

export default memo(ResumeItem);

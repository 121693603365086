import React, { memo } from "react";

const NoRequestsSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="290.385"
      height="250.098"
      viewBox="0 0 652.385 300.098"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Retângulo_1471"
            data-name="Retângulo 1471"
            width="96.433"
            height="124.253"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <rect
            id="Retângulo_1474"
            data-name="Retângulo 1474"
            width="47.57"
            height="62.219"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-3">
          <rect
            id="Retângulo_1458"
            data-name="Retângulo 1458"
            width="96.065"
            height="91.528"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-4">
          <rect
            id="Retângulo_1470"
            data-name="Retângulo 1470"
            width="111.012"
            height="32.592"
            fill="none"
          />
        </clipPath>
      </defs>
      <g
        id="Grupo_862"
        data-name="Grupo 862"
        transform="translate(-10667.91 -13665.404)"
      >
        <g
          id="Grupo_859"
          data-name="Grupo 859"
          transform="translate(10486.387 13159.826)"
        >
          <path
            id="Caminho_1503"
            data-name="Caminho 1503"
            d="M66.954,291.282c208.979-10.9,443.409-11,652.385,0-208.979,11-443.406,10.9-652.385,0"
            transform="translate(114.569 479.299)"
            fill="#263238"
          />
          <path
            id="Caminho_1553"
            data-name="Caminho 1553"
            d="M160.173,184.952c18.4,17.444,31.054,62.826,21.727,91.469-11.108,34.123-41.546,51.539-66.516,43.46-22.671-7.334-32.645-30.246-24.322-47.28,8.039-16.454,23.384-22.484,26.231-37.121,1.361-7-.984-18.637-.409-29.2,1.049-19.222,28.958-34.909,43.289-21.326"
            transform="translate(150.846 325.737)"
            fill="#263238"
          />
          <path
            id="Caminho_1554"
            data-name="Caminho 1554"
            d="M88.1,265.485a36.491,36.491,0,0,1,4.468-10.091,45.023,45.023,0,0,1,6.707-8.326c2.565-2.473,5.067-5.029,7.494-7.7a46.9,46.9,0,0,0,6.5-8.884,32.74,32.74,0,0,0,2.332-5.2,24.244,24.244,0,0,0,1.009-5.769,106.406,106.406,0,0,0-.268-11.566,49.656,49.656,0,0,1,.561-11.314c.634-3.709,2.6-7.136,4.406-10.335-2.052,3.009-4.172,6.428-5.029,10.156a49.086,49.086,0,0,0-.952,11.493c.022,3.85.339,7.686.062,11.417a22.619,22.619,0,0,1-1,5.4,30.969,30.969,0,0,1-2.275,4.9c-3.454,6.377-8.67,11.327-13.548,16.533a45.634,45.634,0,0,0-6.55,8.773A34.972,34.972,0,0,0,88.1,265.485"
            transform="translate(150.761 336.79)"
            fill="#263238"
          />
          <path
            id="Caminho_1555"
            data-name="Caminho 1555"
            d="M221.337,429.474c-3.793-1.391-89.482-69.872-91.1-74.549-.621-1.77,8.624-14.833,20.244-30.3,2.508-3.34,5.121-6.773,7.792-10.246,17.316-22.562,36.218-46.1,36.218-46.1l57.452,45.642-37.8,44.843-8.291,9.85s21.079,40.9,22.3,46.215c1.2,5.306-3.028,16.031-6.821,14.64"
            transform="translate(222.806 477.078)"
            fill="#ffbe9d"
          />
          <path
            id="Caminho_1556"
            data-name="Caminho 1556"
            d="M221.337,400.379c-3.793-1.391-89.482-69.872-91.1-74.549-.748-2.161,13.054-20.993,28.036-40.543l55.872,44.387-8.291,9.85s21.079,40.9,22.3,46.215c1.2,5.306-3.028,16.031-6.821,14.64"
            transform="translate(222.806 506.173)"
            fill="#fff"
          />
          <path
            id="Caminho_1557"
            data-name="Caminho 1557"
            d="M221.337,393.912c-3.793-1.391-89.482-69.872-91.1-74.549-.621-1.77,8.624-14.833,20.244-30.3l55.373,43.991s21.079,40.9,22.3,46.215c1.2,5.306-3.028,16.031-6.821,14.64"
            transform="translate(222.806 512.64)"
            fill="#027BC3"
          />
          <path
            id="Caminho_1558"
            data-name="Caminho 1558"
            d="M219.253,369.592c-14.082-11.832-71.995-58.228-86.814-68.853-.122-.087-.184-.008-.073.089,13.7,12.035,71.99,57.957,86.7,69,.309.233.485.014.187-.236"
            transform="translate(226.4 532.551)"
            fill="#263238"
          />
          <path
            id="Caminho_1560"
            data-name="Caminho 1560"
            d="M173.3,311.657c-5.685-4.674-13.973-7.974-21.3-6.125-.285.07-.179.472.062.521a101.805,101.805,0,0,1,20.773,6.39.463.463,0,0,0,.466-.786"
            transform="translate(259.794 539.923)"
            fill="#263238"
          />
          <path
            id="Caminho_1561"
            data-name="Caminho 1561"
            d="M174.423,313.6c-5.685-4.674-13.973-7.974-21.3-6.125-.282.07-.176.472.065.521a101.8,101.8,0,0,1,20.773,6.39.463.463,0,0,0,.466-.786"
            transform="translate(261.722 543.255)"
            fill="#263238"
          />
          <path
            id="Caminho_1562"
            data-name="Caminho 1562"
            d="M175.55,315.55c-5.685-4.674-13.973-7.974-21.3-6.125-.285.07-.179.474.062.521a101.813,101.813,0,0,1,20.773,6.39.462.462,0,0,0,.466-.786"
            transform="translate(263.651 546.585)"
            fill="#263238"
          />
          <path
            id="Caminho_1563"
            data-name="Caminho 1563"
            d="M176.677,317.5c-5.685-4.674-13.973-7.971-21.3-6.125-.285.07-.179.475.062.521a101.8,101.8,0,0,1,20.773,6.39.463.463,0,0,0,.466-.786"
            transform="translate(265.58 549.916)"
            fill="#263238"
          />
          <path
            id="Caminho_1564"
            data-name="Caminho 1564"
            d="M144.279,297.659c-6.466-5.135-14.459,4.9-7.982,10.048s14.459-4.9,7.982-10.048"
            transform="translate(229.122 524.912)"
            fill="#fff"
          />
          <path
            id="Caminho_1565"
            data-name="Caminho 1565"
            d="M183.826,306.6c-.981-4.739-6.534-5.311-10.33-4.238a38.618,38.618,0,0,0-16.975,10.525.194.194,0,0,0,.049.309.9.9,0,0,0,.662,1.291c6.358.439,13.1,1.7,19.4.174,3.679-.889,8.123-3.579,7.2-8.06m-16.82,6.583c-3.131-.141-6.236-.434-9.372-.458a115.574,115.574,0,0,1,10.048-5.929,41.689,41.689,0,0,1,5.2-2.2c2.768-.9,12.249-1.2,7.822,5.517-2.44,3.7-9.964,3.24-13.7,3.072"
            transform="translate(267.737 534.605)"
            fill="#263238"
          />
          <path
            id="Caminho_1566"
            data-name="Caminho 1566"
            d="M152.571,310.292c4.389,4.769,10.693,7.472,16.221,10.639a.9.9,0,0,0,1.269-.7.193.193,0,0,0,.211-.228,38.624,38.624,0,0,0-8.258-18.186c-2.559-3-7.491-5.612-10.942-2.218-3.264,3.21-1.065,7.914,1.5,10.7m-.857-6.046c.052-8.041,7.77-2.524,9.57-.236a41.827,41.827,0,0,1,3.1,4.717,115.931,115.931,0,0,1,5.056,10.517c-2.622-1.724-5.365-3.207-8.047-4.831-3.2-1.936-9.709-5.734-9.679-10.167"
            transform="translate(255.704 527.917)"
            fill="#263238"
          />
          <path
            id="Caminho_1567"
            data-name="Caminho 1567"
            d="M357.03,285.775c-3.937,29.85-77.5,116.016-86,125.9-1.253,1.459-1.936,2.25-1.936,2.25l-60.847-46.76.7-.905L262.532,296.1l-159.826-.941,60.681-47.185s4.923,2.839,15.155,1.345c35.272-5.162,114.8-16.6,133.552-15.839,24.192,1,48.546,24.908,44.935,52.293"
            transform="translate(175.747 417.465)"
            fill="#37474f"
          />
          <path
            id="Caminho_1568"
            data-name="Caminho 1568"
            d="M182.571,246.955a71.927,71.927,0,0,0-5.528,7.084c-1.892,2.483-3.817,4.942-5.71,7.429l-.054.073c.336-.727.664-1.459,1.011-2.18,1.2-2.473,2.15-5.026,3.562-7.391a.13.13,0,0,0-.222-.136c-1.45,2.348-3.134,4.576-4.376,7.046-.586,1.166-1.139,2.34-1.654,3.535-.285.653-.572,1.309-.827,1.976a9.466,9.466,0,0,0-.347.954c-.849,1.155-1.686,2.313-2.5,3.492-1.8,2.614-3.394,5.374-5.222,7.968-.1.138.122.268.222.136,1.849-2.437,3.923-4.709,5.8-7.13,1.911-2.462,3.725-4.994,5.517-7.545,1.749-2.489,3.459-5,5.205-7.494a74.586,74.586,0,0,0,5.271-7.7c.049-.095-.07-.206-.152-.122"
            transform="translate(274.952 440.53)"
            fill="#263238"
          />
          <path
            id="Caminho_1569"
            data-name="Caminho 1569"
            d="M119.524,235.34c8.651,16.584,41.768,69.669,60.3,71.821,12.165,1.413,59.833-7.979,67-20.176,3.121-5.317-6.385-39.309-12.667-37.3s-44.36,10.9-49.235,9.985c-4.845-.908-44.414-38.311-58.675-47.158-24.6-15.261-11.387,13.9-6.726,22.831"
            transform="translate(191.287 374.207)"
            fill="#ffbe9d"
          />
          <path
            id="Caminho_1570"
            data-name="Caminho 1570"
            d="M222.029,250.432l6.13,51.873s-37.455,10.706-54.383,6.011c-20.556-5.7-53.871-66.193-58.539-76.186-7.662-16.405-6.461-26.7,3.074-24.794,3.7.71,8.673,3.283,14.868,8.093,6.485,5.008,43.956,37.794,55.508,43.712,6.461,3.308,33.342-8.708,33.342-8.708"
            transform="translate(188.613 372.397)"
            fill="#455a64"
          />
          <path
            id="Caminho_1571"
            data-name="Caminho 1571"
            d="M222.014,250.433l6.141,51.881s-6.211,1.776-14.722,3.565c-12.39,2.592-29.63,5.219-39.659,2.432-20.551-5.7-53.884-66.188-58.542-76.181-5.544-11.867-6.442-20.545-2.9-23.712,1.337-1.215,3.34-1.605,5.973-1.087,3.7.724,8.673,3.289,14.873,8.093,6.477,5.008,43.937,37.8,55.495,43.712,6.463,3.324,33.337-8.7,33.337-8.7"
            transform="translate(188.616 372.396)"
            fill="#027BC3"
          />
          <path
            id="Caminho_1572"
            data-name="Caminho 1572"
            d="M153.319,271.388c-1.136-7.637-4.642-39.065-4.94-45.908-.022-.474.274-.6.361-.111,3.953,22.809,4.148,38.415,4.88,45.962.016.19-.271.252-.3.057"
            transform="translate(253.9 403.11)"
            fill="#263238"
          />
          <path
            id="Caminho_1573"
            data-name="Caminho 1573"
            d="M115.231,232.122c4.661,9.993,37.994,70.5,58.537,76.192,5.064,1.4,11.994,1.426,19.2.784L117.042,207.149a5.933,5.933,0,0,0-4.7,1.263c-3.554,3.167-2.654,11.853,2.89,23.709"
            transform="translate(188.615 372.398)"
            fill="#263238"
          />
          <path
            id="Caminho_1574"
            data-name="Caminho 1574"
            d="M91.028,334.562l97.184-24.829-9.587-35.091s7.692-11.007,4.422-21.5c-2.917-9.354-25.078-37.181-29.554-40.887-7.678-6.36-21.4-8.486-28.92.377C93.59,249.169,91.028,334.562,91.028,334.562"
            transform="translate(155.764 371.732)"
            fill="#027BC3"
          />
          <g
            id="Grupo_754"
            data-name="Grupo 754"
            transform="translate(246.923 579.138)"
            opacity="0.1"
          >
            <g id="Grupo_753" data-name="Grupo 753">
              <g
                id="Grupo_752"
                data-name="Grupo 752"
                clipPath="url(#clip-path)"
              >
                <path
                  id="Caminho_1575"
                  data-name="Caminho 1575"
                  d="M148.076,208.524l-25.821,6.461c.729-.952,1.472-1.873,2.231-2.779.179-.209.358-.4.534-.6l18.512-4.636a24.544,24.544,0,0,1,4.544,1.551m6.087,3.991-36.476,9.158c-.163.277-.339.55-.485.824l37.528-9.419c-.2-.209-.388-.4-.567-.564m4.7,5.2-45.3,11.36c-.5,1-1,2.033-1.488,3.069l48.663-12.2c-.645-.792-1.277-1.534-1.876-2.231m5.574,6.819-55.107,13.83c-.111.258-.209.518-.323.775l55.9-14.006c-.165-.211-.325-.4-.472-.6m4.124,5.349-61.942,15.54q-.537,1.476-1.017,2.955l64.653-16.218c-.55-.775-1.133-1.535-1.694-2.277m5.089,6.946-69.97,17.56a5.68,5.68,0,0,0-.228.743l70.6-17.707c-.13-.193-.277-.388-.4-.6m3.679,5.444-75.636,18.984c-.247.952-.5,1.922-.759,2.874L178.817,244.6c-.469-.743-.952-1.518-1.488-2.326m4.235,7.174-82.056,20.58c-.065.244-.108.5-.176.746l82.539-20.713a4.61,4.61,0,0,0-.306-.613m1.99,5.864L98.022,276.764c-.192.954-.388,1.906-.58,2.844l86.318-21.649a16.916,16.916,0,0,0-.206-2.649m-87.2,30.1c-.046.244-.092.5-.127.743l86.706-21.744c.065-.26.133-.5.179-.759Zm-1.131,6.656c-.144.954-.287,1.892-.42,2.811l84.011-21.082a34.887,34.887,0,0,0,1.762-3.134Zm84.64-13.005L93.982,300.611c-.033.241-.06.483-.095.727l86.161-21.616Zm1.632,5.962L93.158,307.186c-.111.954-.225,1.873-.323,2.779l89.341-22.424Zm2.1,7.708L92.239,315.634c-.016.244-.033.485-.065.729l91.6-22.974Zm1.632,5.962L91.672,322.146c-.081.968-.144,1.89-.209,2.746l94.446-23.685Zm2.1,7.689L91.125,330.515c-.033.241-.033.483-.049.71l96.436-24.186Z"
                  transform="translate(-91.077 -206.973)"
                />
              </g>
            </g>
          </g>
          <path
            id="Caminho_1576"
            data-name="Caminho 1576"
            d="M119.013,214.1c2.437,3.039,5.081,5.943,7.61,8.928-1.125-2.185-2.237-4.376-3.446-6.518-.024-.043.038-.089.068-.046a45.7,45.7,0,0,1,4.65,8.085,63.706,63.706,0,0,1,5.176,7.106,29.07,29.07,0,0,1,4,9.614,24.309,24.309,0,0,1-.434,10.386,64.779,64.779,0,0,1-4.3,11.406c-.228.491-.954.138-.775-.344,2.4-6.466,5.086-13.287,4.029-20.212-1.041-6.84-5.132-12.818-9.118-18.425a94.8,94.8,0,0,0-7.683-9.828c-.117-.125.119-.287.228-.152"
            transform="translate(203.207 384.277)"
            fill="#263238"
          />
          <path
            id="Caminho_1577"
            data-name="Caminho 1577"
            d="M133.95,230.081a95.489,95.489,0,0,0-12.881,5.829c-2.044,1.15-4.021,2.389-5.984,3.674-.976.64-1.968,1.244-2.971,1.846a15.108,15.108,0,0,1-3.294,1.586.085.085,0,0,0,.046.163,25.885,25.885,0,0,0,3.327-.96,24.815,24.815,0,0,0,3.026-1.456c2.085-1.147,4.113-2.389,6.187-3.557,4.181-2.359,8.359-4.715,12.7-6.767.2-.092.051-.437-.157-.358"
            transform="translate(186.106 411.688)"
            fill="#263238"
          />
          <path
            id="Caminho_1578"
            data-name="Caminho 1578"
            d="M136.712,230.45a103.655,103.655,0,0,0-11.4,2.475c-1.876.529-3.706,1.16-5.531,1.844q-1.305.488-2.595,1.019c-.949.388-1.844.9-2.8,1.277-.1.041-.057.2.046.163.908-.325,1.873-.5,2.8-.773s1.857-.556,2.787-.83c1.863-.548,3.731-1.076,5.59-1.64,3.717-1.133,7.431-2.223,11.186-3.229.2-.054.111-.339-.087-.306"
            transform="translate(195.639 412.333)"
            fill="#263238"
          />
          <path
            id="Caminho_1579"
            data-name="Caminho 1579"
            d="M107.447,228.315c.583,1.746,10.186,12.542,18.311,11.4,2.646-.377,1.234-14.911,1.234-14.911l.141-1.1,1.963-15.353-18.723-9.687L107.68,197.4s-.26,4.235-.529,9.513c-.016.244-.03.488-.041.751-.022.3-.049.58-.057.859-.052.786-.1,1.591-.13,2.394-.024.61-.054,1.2-.06,1.846s-.027,1.269-.049,1.914a99.956,99.956,0,0,0,.632,13.632"
            transform="translate(182.767 355.793)"
            fill="#ffbe9d"
          />
          <path
            id="Caminho_1580"
            data-name="Caminho 1580"
            d="M106.934,206.918a31.038,31.038,0,0,0,19.984,16.79l1.96-15.351-18.721-9.69-2.692-1.263s-.26,4.235-.531,9.513"
            transform="translate(182.982 355.793)"
            fill="#263238"
          />
          <path
            id="Caminho_1581"
            data-name="Caminho 1581"
            d="M153.647,208.456c6.935,26.263-8.012,33.383-13.675,34.871-5.143,1.353-22.825,5.3-32.436-20.1s.407-36.932,12-40.646,27.174-.385,34.109,25.878"
            transform="translate(177.416 328.263)"
            fill="#ffbe9d"
          />
          <path
            id="Caminho_1582"
            data-name="Caminho 1582"
            d="M122.976,192.518a17.312,17.312,0,0,1-1.876-.038,3.037,3.037,0,0,1-1.906-.4.97.97,0,0,1-.22-1.16,2.4,2.4,0,0,1,2.218-.99,3.511,3.511,0,0,1,2.4.808,1.031,1.031,0,0,1-.618,1.781"
            transform="translate(203.408 343)"
            fill="#263238"
          />
          <path
            id="Caminho_1583"
            data-name="Caminho 1583"
            d="M114.381,193.97a17.9,17.9,0,0,0,1.73-.724,3.05,3.05,0,0,0,1.627-1.074.975.975,0,0,0-.222-1.16,2.4,2.4,0,0,0-2.426-.108,3.532,3.532,0,0,0-1.939,1.638,1.032,1.032,0,0,0,1.231,1.429"
            transform="translate(193.407 344.199)"
            fill="#263238"
          />
          <path
            id="Caminho_1584"
            data-name="Caminho 1584"
            d="M115.956,193.488c-.027-.065-.122.117-.106.19.447,1.795.656,3.929-.851,4.88-.046.03-.022.119.035.1,1.936-.71,1.632-3.538.922-5.167"
            transform="translate(196.742 349.066)"
            fill="#263238"
          />
          <path
            id="Caminho_1585"
            data-name="Caminho 1585"
            d="M115.719,192.919c-2.955.4-1.93,6.284.808,5.91,2.695-.366,1.67-6.249-.808-5.91"
            transform="translate(194.959 348.092)"
            fill="#263238"
          />
          <path
            id="Caminho_1586"
            data-name="Caminho 1586"
            d="M116.383,193.132c-.455.423-.857,1.1-1.464,1.266-.7.2-1.353-.325-1.925-.835-.06-.054-.1.005-.089.084.179,1.131.816,2.21,2.052,2.169,1.128-.038,1.746-1.174,1.83-2.356.016-.22-.22-.5-.4-.328"
            transform="translate(193.194 348.391)"
            fill="#263238"
          />
          <path
            id="Caminho_1587"
            data-name="Caminho 1587"
            d="M119.252,192.555c0-.073.157.062.168.138.244,1.833.835,3.893,2.586,4.224.054.011.065.1,0,.1-2.063.052-2.82-2.689-2.757-4.465"
            transform="translate(204.054 347.458)"
            fill="#263238"
          />
          <path
            id="Caminho_1588"
            data-name="Caminho 1588"
            d="M120.563,191.777c2.9-.71,4.107,5.135,1.423,5.794-2.643.648-3.853-5.2-1.423-5.794"
            transform="translate(204.055 346.06)"
            fill="#263238"
          />
          <path
            id="Caminho_1589"
            data-name="Caminho 1589"
            d="M120.37,192.384c.526.214,1.1.683,1.659.6.648-.089.99-.819,1.274-1.51.03-.073.084-.033.1.043.263,1.112.133,2.353-.9,2.79-.938.4-1.854-.412-2.351-1.472-.095-.2,0-.542.211-.455"
            transform="translate(205.548 345.585)"
            fill="#263238"
          />
          <path
            id="Caminho_1590"
            data-name="Caminho 1590"
            d="M116.65,199.206c.493.374,1.009.908,1.681.878a4.843,4.843,0,0,0,1.914-.713.064.064,0,0,1,.089.084,2.487,2.487,0,0,1-2.2,1.293,1.963,1.963,0,0,1-1.651-1.437c-.043-.1.095-.16.165-.106"
            transform="translate(199.31 358.839)"
            fill="#263238"
          />
          <path
            id="Caminho_1591"
            data-name="Caminho 1591"
            d="M122.689,207.337s.881,2.646,1.12,3.926c.022.122-.277.236-.708.342-.019.011-.03.022-.049.011a6.2,6.2,0,0,1-6.339-1.171c-.111-.108.027-.271.157-.214a9.337,9.337,0,0,0,5.859.558c.024-.371-1.662-4.482-1.461-4.533a11.293,11.293,0,0,1,3.031.114c-1.676-5.563-4.229-10.812-5.815-16.378a.176.176,0,0,1,.317-.144c3.069,5.311,5.138,11.672,7.016,17.373.241.71-2.576.239-3.129.117"
            transform="translate(199.647 342.718)"
            fill="#263238"
          />
          <path
            id="Caminho_1592"
            data-name="Caminho 1592"
            d="M109.249,225.874a11.227,11.227,0,0,0,5.243-9.776,29.332,29.332,0,0,0,10.815-10.864c-.122.577-.987,4.75-1.494,6.865,16.823-5.108,20.689-18.84,20.689-18.84,6.184,4.945,7.689,8.38,8.692,10.064,0,0-1.038-10.552-7.236-17.01-7.586-7.908-16.454-5.271-16.454-5.271a24.425,24.425,0,0,0-22.9,9.915c-9.817,13.388,2.649,34.917,2.649,34.917"
            transform="translate(175.963 327.071)"
            fill="#263238"
          />
          <path
            id="Caminho_1593"
            data-name="Caminho 1593"
            d="M120.623,201.613a31.885,31.885,0,0,1-8.453,4.208.02.02,0,0,1-.014-.038,27.081,27.081,0,0,0,11.769-9.015,25.626,25.626,0,0,0,3.134-5.59,17.76,17.76,0,0,0,1.562-6.8.134.134,0,0,1,.268-.005,20.786,20.786,0,0,1-.618,6.919,18.937,18.937,0,0,1-3.188,6.108,21.788,21.788,0,0,1-4.46,4.216"
            transform="translate(191.896 333.271)"
            fill="#263238"
          />
          <path
            id="Caminho_1594"
            data-name="Caminho 1594"
            d="M114.211,200.4s-7.29-7.342-10.72-4.6,3.064,14.087,7.8,14.974a4.592,4.592,0,0,0,5.642-3.484Z"
            transform="translate(175.447 351.999)"
            fill="#ffbe9d"
          />
          <path
            id="Caminho_1595"
            data-name="Caminho 1595"
            d="M103.635,196.607c-.073.005-.068.108,0,.122,3.34.724,5.485,3.332,7.155,6.133-1.128-.992-2.5-1.407-3.858-.035-.07.073.03.187.114.155a2.824,2.824,0,0,1,3.232.439,13.658,13.658,0,0,1,1.9,2.223c.2.255.672.019.51-.285l-.038-.068c-.61-3.994-4.669-9.023-9.009-8.684"
            transform="translate(177.245 354.4)"
            fill="#263238"
          />
          <path
            id="Caminho_1596"
            data-name="Caminho 1596"
            d="M119.22,223.186c-14.507,22.787-31.178,59.155-31.637,84.865-.222,12.558,21.491,23.967,29.394,20.16,18.593-8.958,44.466-29.549,54.646-43.121,3.6-4.791-34.871-37.013-37.688-31.262-2.749,5.612-4.574,9.123-7.407,11.341-1.377,1.076,6.8-15.844,10.907-39.523,5.615-32.339-12.959-10.717-18.216-2.459"
            transform="translate(149.866 374.659)"
            fill="#ffbe9d"
          />
          <path
            id="Caminho_1597"
            data-name="Caminho 1597"
            d="M117.712,212.437s-6.03-7.458-.61-11.156,10.164,4.636,10.164,4.636Z"
            transform="translate(196.513 360.805)"
            fill="#ffc4c0"
          />
          <rect
            id="Retângulo_1472"
            data-name="Retângulo 1472"
            width="11.569"
            height="67.433"
            transform="matrix(0.826, -0.564, 0.564, 0.826, 314.012, 572.927)"
            fill="#027BC3"
          />
          <g
            id="Grupo_757"
            data-name="Grupo 757"
            transform="translate(314.01 566.407)"
            opacity="0.2"
          >
            <g id="Grupo_756" data-name="Grupo 756">
              <g
                id="Grupo_755"
                data-name="Grupo 755"
                clipPath="url(#clip-path-2)"
              >
                <rect
                  id="Retângulo_1473"
                  data-name="Retângulo 1473"
                  width="11.569"
                  height="67.433"
                  transform="matrix(0.826, -0.564, 0.564, 0.826, 0.002, 6.521)"
                />
              </g>
            </g>
          </g>
          <rect
            id="Retângulo_1475"
            data-name="Retângulo 1475"
            width="13.509"
            height="2.682"
            transform="translate(313.005 573.177) rotate(-34.318)"
            fill="#37474f"
          />
          <rect
            id="Retângulo_1476"
            data-name="Retângulo 1476"
            width="13.509"
            height="2.682"
            transform="translate(314.675 575.624) rotate(-34.318)"
            fill="#37474f"
          />
          <path
            id="Caminho_1598"
            data-name="Caminho 1598"
            d="M139.4,223.278l1.1,9.329.371,3.074-2.749-1.453-8.285-4.422.019-2.047s1.857.735,1.892.74l.022-2.047s1.857.735,1.892.743v-2.033s1.879.721,1.914.727l0-2.033s1.879.721,1.914.729v-2.033s1.879.718,1.914.727"
            transform="translate(222.178 398.822)"
            fill="#fff"
          />
          <path
            id="Caminho_1599"
            data-name="Caminho 1599"
            d="M135.272,226.26l.371,3.075-2.746-1.453Z"
            transform="translate(227.409 405.168)"
            fill="#37474f"
          />
          <path
            id="Caminho_1600"
            data-name="Caminho 1600"
            d="M163.4,209.4c3.435,4.745-8.985,12.219-8.985,12.219a10.816,10.816,0,0,1,7.006,2.229c3.4,2.877.38,9.467.38,9.467a13.34,13.34,0,0,1,7.014,3.527c2.974,3.08.878,10.164.878,10.164A11.682,11.682,0,0,1,173.9,254c1.093,7.426-8.215,14.261-13.141,19.629-5.279,5.75-15.627,11.24-19.648,15.418l-36.883-30.395s11.8-29.983,22.167-38.062c5.75-4.482,31.753-18.436,37-11.184"
            transform="translate(178.351 372.811)"
            fill="#ffbe9d"
          />
          <path
            id="Caminho_1601"
            data-name="Caminho 1601"
            d="M135.941,212.6c-7.518,2.646-14.391,7.084-20.974,11.444a.136.136,0,0,0,.152.225c6.5-4.408,14.331-7.187,20.99-11.33a.192.192,0,0,0-.168-.339"
            transform="translate(196.627 381.772)"
            fill="#263238"
          />
          <path
            id="Caminho_1602"
            data-name="Caminho 1602"
            d="M136.716,216.807a20.743,20.743,0,0,0-4.587,2.912q-2.326,1.712-4.593,3.5-4.543,3.583-8.9,7.385c-.127.111.014.336.155.225,3.023-2.372,6.078-4.7,9.2-6.943q2.338-1.688,4.709-3.332a24.687,24.687,0,0,0,4.313-3.31.275.275,0,0,0-.293-.439"
            transform="translate(202.915 388.963)"
            fill="#263238"
          />
          <path
            id="Caminho_1603"
            data-name="Caminho 1603"
            d="M138.691,222.017c-1.486.507-2.8,1.86-3.991,2.841q-2.033,1.676-4.088,3.327c-2.738,2.2-5.477,4.4-8.291,6.507-.141.106.011.331.155.225,2.868-2.123,5.767-4.208,8.616-6.358q2.1-1.582,4.181-3.188c1.182-.911,2.76-1.914,3.535-3.221.043-.073-.043-.157-.117-.133"
            transform="translate(209.216 397.9)"
            fill="#263238"
          />
          <path
            id="Caminho_1604"
            data-name="Caminho 1604"
            d="M111.662,217.517c1.64-1.979,4.379-5.062,11.015-7.293.184-.062.184.043.011.133a51.639,51.639,0,0,0-10.959,7.225.047.047,0,0,1-.068-.065"
            transform="translate(191.051 377.696)"
            fill="#263238"
          />
          <path
            id="Caminho_1605"
            data-name="Caminho 1605"
            d="M174.781,293.03l-40.117-39.5-6.125,11.094s9.147-24.693,11.474-38.179,1.93-29.27-15.153-9.08c-16.736,19.781-39.16,76.826-38.016,91.6s19.957,23.736,29.422,22.039,58.515-37.983,58.515-37.983"
            transform="translate(148.533 373.489)"
            fill="#027BC3"
          />
          <path
            id="Caminho_1606"
            data-name="Caminho 1606"
            d="M135.814,261.956c-5.6-5.314-27.738-27.9-32.192-33.1-.309-.361-.155-.643.214-.309,17.175,15.519,26.952,27.689,32.179,33.182.133.136-.057.363-.2.228"
            transform="translate(177.03 408.825)"
            fill="#263238"
          />
          <path
            id="Caminho_1607"
            data-name="Caminho 1607"
            d="M91.631,268.9c2.164-3.75,4.685-7.345,7.087-10.972s4.815-7.271,7.366-10.826,5.273-7.03,7.974-10.506c1.35-1.741,3.98-5.143,5.338-6.876,1.339-1.708,1.494-1.312.211.561-1.231,1.8-3.733,5.268-4.956,7.073-2.41,3.552-4.766,7.136-7.26,10.641s-5.089,6.976-7.651,10.452c-2.6,3.53-5.083,7.155-7.843,10.587-.114.141-.361.027-.266-.136"
            transform="translate(156.76 409.233)"
            fill="#263238"
          />
          <path
            id="Caminho_1608"
            data-name="Caminho 1608"
            d="M105.964,232.032c1.4-4.08,3.074-8.131,4.156-12.306a.135.135,0,0,1,.26.068c-.808,2.755-1.445,5.552-2.155,8.334.068-.114.136-.228.2-.339.637-1.074,1.244-2.161,1.838-3.256a.134.134,0,0,1,.233.13c-.556,1.122-1.1,2.245-1.608,3.389a14.913,14.913,0,0,1-1.372,2.654c-.171.6-.333,1.209-.523,1.806a91.516,91.516,0,0,1-4.617,12,.236.236,0,0,1-.431-.173c1.182-4.135,2.624-8.242,4.018-12.3"
            transform="translate(174.433 393.823)"
            fill="#263238"
          />
          <path
            id="Caminho_1609"
            data-name="Caminho 1609"
            d="M108.343,224.571c.13-.214,1.12.822,1.589,1.366.076.089-.033.133-.149.046-.325-.241-.689-.388-.992-.648-.941,1.876-6.149,10.717-6.52,11.1-.165.174-.323.022-.247-.217.312-.976,5.162-9.725,6.32-11.65"
            transform="translate(174.545 402.229)"
            fill="#263238"
          />
          <path
            id="Caminho_1610"
            data-name="Caminho 1610"
            d="M102.559,272.714c11.574-8.451,23.373-16.633,35.435-24.371,3.3-2.117,6.431-4.4,9.673-6.61-.355-.239-3.619-3.7-3.72-3.82a.068.068,0,0,1,.095-.1c.366.306,4.533,3.682,4.823,4.069.174.23-12.095,8.616-17.9,12.718-5.851,4.126-11.886,8-17.907,11.87q-5.136,3.3-10.365,6.458c-.141.087-.26-.122-.13-.217"
            transform="translate(175.4 424.911)"
            fill="#263238"
          />
          <path
            id="Caminho_1611"
            data-name="Caminho 1611"
            d="M189.263,294.812l-60.513,5.227L150.3,219.968l60.51-5.224Z"
            transform="translate(220.311 385.461)"
            fill="#eaeaea"
          />
          <path
            id="Caminho_1612"
            data-name="Caminho 1612"
            d="M190.79,294.68l-60.513,5.227,21.551-80.072,60.511-5.224Z"
            transform="translate(222.926 385.236)"
            fill="#027BC3"
          />
          <path
            id="Caminho_1613"
            data-name="Caminho 1613"
            d="M143.33,218.153a5.384,5.384,0,0,1-2.321,3.384c-.55.361-.77-.382-.225-.724a4.025,4.025,0,0,0,1.586-2.145,4.306,4.306,0,0,0,.1-.442,2.868,2.868,0,0,0-.035-1.483.95.95,0,0,0-.385-.5.127.127,0,0,1-.043-.033c-.03-.014-.062-.011-.076-.027a.832.832,0,0,1-.114-.008.742.742,0,0,0-.423.073,1.715,1.715,0,0,0-.656.507,3.612,3.612,0,0,0-.534.859c-.089.146-.149.306-.228.469l-.027.106a.577.577,0,0,0-.06.16c-.011.038-.038.073-.03.108l-.859.073a6.276,6.276,0,0,1,.691-1.564,3.3,3.3,0,0,1,2.2-1.678,1.333,1.333,0,0,1,1.461,1.12,3.543,3.543,0,0,1-.019,1.749"
            transform="translate(237.852 386.372)"
            fill="#455a64"
          />
          <path
            id="Caminho_1614"
            data-name="Caminho 1614"
            d="M145.077,218a5.383,5.383,0,0,1-2.321,3.384c-.55.361-.77-.382-.225-.724a4.026,4.026,0,0,0,1.586-2.145,4.319,4.319,0,0,0,.1-.442,2.868,2.868,0,0,0-.035-1.483.95.95,0,0,0-.385-.5.186.186,0,0,1-.043-.033c-.03-.014-.062-.011-.076-.027a.822.822,0,0,1-.114-.008.741.741,0,0,0-.423.073,1.734,1.734,0,0,0-.656.5,3.629,3.629,0,0,0-.534.862,5.041,5.041,0,0,0-.228.469l-.027.106a.581.581,0,0,0-.06.16c-.011.035-.038.073-.03.106l-.859.076a6.28,6.28,0,0,1,.691-1.564,3.3,3.3,0,0,1,2.2-1.678,1.334,1.334,0,0,1,1.461,1.12,3.542,3.542,0,0,1-.019,1.749"
            transform="translate(240.842 386.114)"
            fill="#455a64"
          />
          <path
            id="Caminho_1615"
            data-name="Caminho 1615"
            d="M146.824,217.851a5.384,5.384,0,0,1-2.321,3.384c-.55.361-.77-.382-.225-.724a4.025,4.025,0,0,0,1.586-2.145,4.311,4.311,0,0,0,.1-.442,2.88,2.88,0,0,0-.035-1.486.954.954,0,0,0-.385-.5.186.186,0,0,1-.043-.032c-.03-.014-.062-.011-.076-.027a.829.829,0,0,1-.114-.008.742.742,0,0,0-.423.073,1.715,1.715,0,0,0-.656.507,3.651,3.651,0,0,0-.534.857c-.089.146-.149.309-.228.472l-.027.106a.578.578,0,0,0-.06.16c-.011.035-.038.073-.03.106l-.859.076a6.277,6.277,0,0,1,.691-1.564,3.3,3.3,0,0,1,2.2-1.678,1.334,1.334,0,0,1,1.461,1.12,3.543,3.543,0,0,1-.019,1.749"
            transform="translate(243.831 385.855)"
            fill="#455a64"
          />
          <path
            id="Caminho_1616"
            data-name="Caminho 1616"
            d="M148.571,217.7a5.384,5.384,0,0,1-2.321,3.383c-.55.361-.77-.382-.225-.724a4.025,4.025,0,0,0,1.586-2.145,4.3,4.3,0,0,0,.1-.442,2.868,2.868,0,0,0-.035-1.483.949.949,0,0,0-.385-.5.149.149,0,0,1-.043-.03c-.03-.016-.062-.014-.076-.03a.978.978,0,0,1-.114-.005.717.717,0,0,0-.423.07,1.714,1.714,0,0,0-.656.507,3.61,3.61,0,0,0-.534.859,5.025,5.025,0,0,0-.228.469l-.027.106a.579.579,0,0,0-.06.16c-.011.035-.038.073-.03.108l-.859.073a6.277,6.277,0,0,1,.691-1.564,3.3,3.3,0,0,1,2.2-1.678,1.334,1.334,0,0,1,1.461,1.12,3.543,3.543,0,0,1-.019,1.749"
            transform="translate(246.82 385.597)"
            fill="#455a64"
          />
          <path
            id="Caminho_1617"
            data-name="Caminho 1617"
            d="M150.318,217.549A5.384,5.384,0,0,1,148,220.933c-.55.361-.77-.382-.225-.724a4.026,4.026,0,0,0,1.586-2.145,4.335,4.335,0,0,0,.1-.442,2.869,2.869,0,0,0-.035-1.483.95.95,0,0,0-.385-.5.185.185,0,0,1-.043-.032c-.03-.014-.062-.011-.076-.027a.792.792,0,0,1-.114-.008.741.741,0,0,0-.423.073,1.733,1.733,0,0,0-.656.5,3.683,3.683,0,0,0-.534.862,5.01,5.01,0,0,0-.228.469l-.027.106a.58.58,0,0,0-.06.16c-.011.035-.038.073-.03.108l-.859.073a6.276,6.276,0,0,1,.691-1.564,3.3,3.3,0,0,1,2.2-1.678,1.334,1.334,0,0,1,1.461,1.12,3.543,3.543,0,0,1-.019,1.749"
            transform="translate(249.81 385.339)"
            fill="#455a64"
          />
          <path
            id="Caminho_1618"
            data-name="Caminho 1618"
            d="M152.065,217.4a5.384,5.384,0,0,1-2.321,3.384c-.55.361-.77-.382-.225-.724a4.026,4.026,0,0,0,1.586-2.145,4.311,4.311,0,0,0,.1-.442,2.868,2.868,0,0,0-.035-1.483.95.95,0,0,0-.385-.5.247.247,0,0,1-.043-.03c-.03-.016-.062-.014-.076-.03a.794.794,0,0,1-.114-.008.742.742,0,0,0-.423.073,1.715,1.715,0,0,0-.656.507,3.65,3.65,0,0,0-.534.857c-.089.146-.149.309-.228.472l-.027.106a.578.578,0,0,0-.06.16c-.011.035-.038.073-.03.108l-.859.073a6.275,6.275,0,0,1,.691-1.564,3.3,3.3,0,0,1,2.2-1.678,1.334,1.334,0,0,1,1.461,1.12,3.543,3.543,0,0,1-.019,1.749"
            transform="translate(252.799 385.08)"
            fill="#455a64"
          />
          <path
            id="Caminho_1619"
            data-name="Caminho 1619"
            d="M153.812,217.247a5.4,5.4,0,0,1-2.321,3.386c-.55.358-.77-.385-.225-.727a4.026,4.026,0,0,0,1.586-2.145,4.319,4.319,0,0,0,.1-.442,2.868,2.868,0,0,0-.035-1.483.95.95,0,0,0-.385-.5.149.149,0,0,1-.043-.03c-.03-.016-.062-.014-.076-.03a.978.978,0,0,1-.114-.005.717.717,0,0,0-.423.07,1.714,1.714,0,0,0-.656.507,3.615,3.615,0,0,0-.534.859c-.089.146-.149.306-.228.469l-.027.106a.581.581,0,0,0-.06.16c-.011.038-.038.073-.03.108l-.859.073a6.278,6.278,0,0,1,.691-1.564,3.3,3.3,0,0,1,2.2-1.678,1.334,1.334,0,0,1,1.461,1.12,3.542,3.542,0,0,1-.019,1.749"
            transform="translate(255.789 384.822)"
            fill="#455a64"
          />
          <path
            id="Caminho_1620"
            data-name="Caminho 1620"
            d="M155.559,217.1a5.384,5.384,0,0,1-2.321,3.384c-.55.358-.77-.385-.225-.724a4.035,4.035,0,0,0,1.586-2.145,3.749,3.749,0,0,0,.1-.445,2.871,2.871,0,0,0-.035-1.483.954.954,0,0,0-.385-.5.339.339,0,0,1-.043-.033c-.03-.016-.062-.011-.076-.027-.033,0-.114-.011-.114-.011a.767.767,0,0,0-.423.076,1.663,1.663,0,0,0-.656.507,3.558,3.558,0,0,0-.534.859,5.019,5.019,0,0,0-.228.469l-.027.1a.627.627,0,0,0-.06.163c-.011.038-.038.073-.03.106l-.859.076a6.235,6.235,0,0,1,.691-1.564,3.3,3.3,0,0,1,2.2-1.678,1.33,1.33,0,0,1,1.461,1.117,3.554,3.554,0,0,1-.019,1.751"
            transform="translate(258.778 384.565)"
            fill="#455a64"
          />
          <path
            id="Caminho_1621"
            data-name="Caminho 1621"
            d="M157.306,216.946a5.4,5.4,0,0,1-2.321,3.386c-.55.358-.77-.385-.225-.727a4.025,4.025,0,0,0,1.586-2.144,4.3,4.3,0,0,0,.1-.442,2.867,2.867,0,0,0-.035-1.483.95.95,0,0,0-.385-.5.147.147,0,0,1-.043-.03c-.03-.016-.062-.014-.076-.03a.786.786,0,0,1-.114-.008.741.741,0,0,0-.423.073,1.714,1.714,0,0,0-.656.507,3.611,3.611,0,0,0-.534.859c-.089.146-.149.306-.228.469l-.027.106a.579.579,0,0,0-.06.16c-.011.035-.038.073-.03.108l-.859.073a6.277,6.277,0,0,1,.691-1.564,3.3,3.3,0,0,1,2.2-1.678,1.334,1.334,0,0,1,1.461,1.12,3.543,3.543,0,0,1-.019,1.749"
            transform="translate(261.767 384.307)"
            fill="#455a64"
          />
          <path
            id="Caminho_1622"
            data-name="Caminho 1622"
            d="M159.053,216.8a5.384,5.384,0,0,1-2.321,3.384c-.55.361-.77-.382-.225-.724a4.026,4.026,0,0,0,1.586-2.145,4.335,4.335,0,0,0,.1-.442,2.88,2.88,0,0,0-.035-1.486.954.954,0,0,0-.385-.5.184.184,0,0,1-.043-.033c-.03-.014-.062-.011-.076-.027a.833.833,0,0,1-.114-.008.741.741,0,0,0-.423.073,1.715,1.715,0,0,0-.656.507,3.651,3.651,0,0,0-.534.857c-.089.146-.149.309-.228.472l-.027.106a.58.58,0,0,0-.06.16c-.011.035-.038.073-.03.106l-.859.076a6.277,6.277,0,0,1,.691-1.564,3.3,3.3,0,0,1,2.2-1.678,1.334,1.334,0,0,1,1.461,1.12,3.543,3.543,0,0,1-.019,1.749"
            transform="translate(264.757 384.048)"
            fill="#455a64"
          />
          <path
            id="Caminho_1623"
            data-name="Caminho 1623"
            d="M160.8,216.644a5.384,5.384,0,0,1-2.321,3.384c-.55.361-.77-.382-.225-.724a4.025,4.025,0,0,0,1.586-2.145,4.311,4.311,0,0,0,.1-.442,2.868,2.868,0,0,0-.035-1.483.95.95,0,0,0-.385-.5.248.248,0,0,1-.043-.03c-.03-.016-.062-.014-.076-.03a.791.791,0,0,1-.114-.008.742.742,0,0,0-.423.073,1.715,1.715,0,0,0-.656.507,3.612,3.612,0,0,0-.534.859c-.089.146-.149.306-.228.469l-.027.106a.578.578,0,0,0-.06.16c-.011.035-.038.073-.03.108l-.859.073a6.277,6.277,0,0,1,.691-1.564,3.3,3.3,0,0,1,2.2-1.678,1.334,1.334,0,0,1,1.461,1.12,3.543,3.543,0,0,1-.019,1.749"
            transform="translate(267.746 383.79)"
            fill="#455a64"
          />
          <path
            id="Caminho_1624"
            data-name="Caminho 1624"
            d="M162.547,216.493a5.384,5.384,0,0,1-2.321,3.384c-.55.361-.77-.382-.225-.724a4.007,4.007,0,0,0,1.583-2.145,3.716,3.716,0,0,0,.1-.442,2.879,2.879,0,0,0-.035-1.486.954.954,0,0,0-.385-.5.185.185,0,0,1-.043-.033c-.03-.014-.062-.011-.076-.027a.834.834,0,0,1-.117-.008.736.736,0,0,0-.42.073,1.733,1.733,0,0,0-.656.5,3.725,3.725,0,0,0-.534.859c-.089.146-.149.309-.228.472l-.027.106a.579.579,0,0,0-.06.16c-.011.035-.038.073-.03.106l-.859.076a6.276,6.276,0,0,1,.691-1.564,3.3,3.3,0,0,1,2.2-1.678,1.334,1.334,0,0,1,1.461,1.12,3.543,3.543,0,0,1-.019,1.749"
            transform="translate(270.736 383.532)"
            fill="#455a64"
          />
          <path
            id="Caminho_1625"
            data-name="Caminho 1625"
            d="M181.458,221.7c3.1,6.61,10.934,14.841,11.552,20.233a6.224,6.224,0,0,1-3.489,6.556s5.36,2.708,5.116,7.692c-.127,2.565-4.335,4.617-4.335,4.617s4.2,2.359,3.709,6.778c-.39,3.519-4.549,5.048-4.549,5.048s2.977,4.14,1.429,7.6c-3.142,7.014-33.551,6.857-38.189-4.37-2.161-5.232,5.262-4.791,5.262-4.791s-10.522-3.215-10.649-9.484,9.484-5.113,9.484-5.113-10.17-3.2-9.646-9.717c.545-6.783,9.961-3.977,9.961-3.977s-6.737-4.62-5.195-9.516c2.548-8.082,15.3,2.166,21.988.615l3.747-11Z"
            transform="translate(251.767 397.369)"
            fill="#ffbe9d"
          />
          <path
            id="Caminho_1626"
            data-name="Caminho 1626"
            d="M168.253,240.827c-2.383-6.515,5.71-14.5,4.327-21.06-1.876-8.871-11.894-.239-14.179,12.62l-.019,10.294Z"
            transform="translate(271.018 387.852)"
            fill="#ffbe9d"
          />
          <path
            id="Caminho_1627"
            data-name="Caminho 1627"
            d="M182.385,242.188a.08.08,0,0,1,.03.157c-10.015,2.386-21.749,1.936-31.314-2.031-.315-.13-.239-.7.133-.621,10.641,2.2,20.263,3.31,31.151,2.494"
            transform="translate(258.223 428.143)"
            fill="#263238"
          />
          <path
            id="Caminho_1628"
            data-name="Caminho 1628"
            d="M184.782,239.119c.119-.008.136.187.011.214-10.332,2.4-23.536,1.534-34.022-4.338-.361-.2-.483-.743-.049-.542,7.269,3.375,24.262,5.452,34.06,4.666"
            transform="translate(257.436 419.113)"
            fill="#263238"
          />
          <path
            id="Caminho_1629"
            data-name="Caminho 1629"
            d="M150.745,229.241c10.275,5.241,21.2,6.659,32.621,6.507.149,0,.136.209.014.244-5.569,1.6-12.864.938-18.5-.057A30.439,30.439,0,0,1,150.56,229.6c-.165-.136-.035-.472.184-.361"
            transform="translate(257.506 410.232)"
            fill="#263238"
          />
          <path
            id="Caminho_1630"
            data-name="Caminho 1630"
            d="M347.544,311.593c-1.4,3.79-70.16,89.257-74.842,90.859-1.773.616-14.8-8.67-30.232-20.342-3.332-2.519-6.756-5.14-10.218-7.824-22.508-17.389-45.984-36.365-45.984-36.365l45.83-57.306,44.721,37.943,9.823,8.323s40.96-20.949,46.285-22.156c5.308-1.187,16.02,3.077,14.619,6.867"
            transform="translate(318.736 498.179)"
            fill="#ffbe9d"
          />
          <path
            id="Caminho_1631"
            data-name="Caminho 1631"
            d="M318.521,296.5c-1.4,3.79-70.16,89.257-74.842,90.859-2.164.74-20.952-13.122-40.451-28.166L247.8,303.468l9.823,8.323s40.96-20.949,46.285-22.156c5.308-1.187,16.02,3.077,14.619,6.867"
            transform="translate(347.759 513.269)"
            fill="#fff"
          />
          <path
            id="Caminho_1632"
            data-name="Caminho 1632"
            d="M312.072,296.5c-1.4,3.79-70.16,89.257-74.842,90.859-1.773.616-14.8-8.67-30.232-20.342l44.17-55.229s40.96-20.949,46.285-22.156c5.308-1.187,16.02,3.077,14.619,6.867"
            transform="translate(354.208 513.269)"
            fill="#027BC3"
          />
          <path
            id="Caminho_1633"
            data-name="Caminho 1633"
            d="M287.786,291.574c-11.878,14.044-58.458,71.808-69.132,86.595-.087.119-.008.182.089.07,12.078-13.662,58.19-71.8,69.278-86.478.233-.309.014-.483-.236-.187"
            transform="translate(374.089 516.671)"
            fill="#263238"
          />
          <path
            id="Caminho_1634"
            data-name="Caminho 1634"
            d="M239.825,290.65a14.78,14.78,0,0,0-3.4,11.384c.512,3.346,2.023,7.068,5.075,9.356.087.065.214-.089.138-.173-4.948-5.328-5.772-14.388-1.638-20.423a.115.115,0,0,0-.179-.144"
            transform="translate(404.34 515.277)"
            fill="#263238"
          />
          <path
            id="Caminho_1635"
            data-name="Caminho 1635"
            d="M229.7,296.16c-4.693,5.672-8.017,13.946-6.2,21.283.07.285.474.182.523-.06a101.669,101.669,0,0,1,6.458-20.754.463.463,0,0,0-.786-.469"
            transform="translate(381.578 524.489)"
            fill="#263238"
          />
          <path
            id="Caminho_1636"
            data-name="Caminho 1636"
            d="M231.645,295.039c-4.693,5.672-8.017,13.946-6.195,21.283.07.285.474.179.523-.06a101.677,101.677,0,0,1,6.458-20.754.463.463,0,0,0-.786-.469"
            transform="translate(384.915 522.57)"
            fill="#263238"
          />
          <path
            id="Caminho_1637"
            data-name="Caminho 1637"
            d="M233.6,293.918c-4.693,5.672-8.017,13.946-6.2,21.283.07.285.474.179.523-.06a101.669,101.669,0,0,1,6.458-20.754.463.463,0,0,0-.786-.469"
            transform="translate(388.252 520.652)"
            fill="#263238"
          />
          <path
            id="Caminho_1638"
            data-name="Caminho 1638"
            d="M235.545,292.8c-4.693,5.669-8.017,13.946-6.2,21.283.07.285.474.179.523-.06a101.84,101.84,0,0,1,6.458-20.754.463.463,0,0,0-.786-.469"
            transform="translate(391.588 518.734)"
            fill="#263238"
          />
          <path
            id="Caminho_1639"
            data-name="Caminho 1639"
            d="M215.6,319.538c-5.157,6.447,4.856,14.475,10.023,8.014s-4.858-14.475-10.023-8.014"
            transform="translate(366.472 550.705)"
            fill="#fff"
          />
          <path
            id="Caminho_1640"
            data-name="Caminho 1640"
            d="M224.637,289.3c-4.742.965-5.33,6.515-4.27,10.313a38.6,38.6,0,0,0,10.471,17.01.2.2,0,0,0,.309-.049.9.9,0,0,0,1.291-.659c.461-6.355,1.741-13.09.239-19.4-.878-3.682-3.552-8.131-8.039-7.217m6.529,16.839c-.152,3.131-.453,6.236-.488,9.37a115.158,115.158,0,0,1-5.9-10.067,41.654,41.654,0,0,1-2.185-5.205c-.892-2.771-1.155-12.254,5.542-7.805,3.693,2.454,3.207,9.977,3.028,13.708"
            transform="translate(376.317 512.839)"
            fill="#263238"
          />
          <path
            id="Caminho_1641"
            data-name="Caminho 1641"
            d="M228.26,316.343c4.782-4.376,7.5-10.668,10.69-16.186a.9.9,0,0,0-.7-1.271.193.193,0,0,0-.228-.211,38.619,38.619,0,0,0-18.214,8.2c-3.009,2.551-5.634,7.475-2.253,10.934,3.2,3.275,7.911,1.093,10.7-1.464m-6.049.838c-8.041-.079-2.5-7.778-.206-9.57a41.716,41.716,0,0,1,4.728-3.083,116.307,116.307,0,0,1,10.533-5.024c-1.732,2.616-3.224,5.355-4.856,8.031-1.947,3.188-5.767,9.69-10.2,9.646"
            transform="translate(369.56 529.078)"
            fill="#263238"
          />
          <path
            id="Caminho_1642"
            data-name="Caminho 1642"
            d="M437.818,389.75,390.126,454.1,229.194,342.426l-15.519-10.774s-4.392,2.955-11.007,6.613c-9.188,5.081-22.652,11.512-34.562,13.266-19.979,2.955-50.108,2.638-66.944-19.878-14.185-18.962-9.711-43.7-9.711-43.7l21.936-9.419s51.913-43.254,85.54-44.555c14.282-.569,35.08,10.256,54.34,21.565C272.51,266.836,437.818,389.75,437.818,389.75"
            transform="translate(155.342 418.341)"
            fill="#37474f"
          />
          <path
            id="Caminho_1643"
            data-name="Caminho 1643"
            d="M166.538,291.268s-4.392,2.958-11.007,6.615c-11.693-14-36.452-39.423-37.2-40.326Z"
            transform="translate(202.479 458.723)"
            fill="#263238"
          />
          <path
            id="Caminho_1644"
            data-name="Caminho 1644"
            d="M127.746,245.066c-3.1,2.155-6.106,4.444-9.166,6.656-3.012,2.177-6.119,4.219-8.982,6.594-2.912,2.416-5.669,5-8.413,7.6-1.323,1.255-2.635,2.521-3.915,3.823a35.946,35.946,0,0,0-3.739,4.056c-.1.138.1.3.23.22a43.1,43.1,0,0,0,4.159-3.386c1.453-1.22,2.887-2.462,4.335-3.693,2.79-2.372,5.634-4.677,8.418-7.054,2.765-2.364,5.327-4.951,8.066-7.342,2.933-2.562,6.024-4.948,9.069-7.372.057-.046,0-.144-.062-.1"
            transform="translate(160.003 437.33)"
            fill="#263238"
          />
          <path
            id="Caminho_1645"
            data-name="Caminho 1645"
            d="M242.191,290.6c-3.1,3.34-5.962,6.935-8.846,10.462s-5.775,7.1-8.635,10.663c-5.721,7.147-11.487,14.258-17.11,21.483-3.088,3.966-6.135,7.965-9.007,12.095-.127.184.165.39.312.228,6.154-6.764,11.78-13.984,17.487-21.123s11.417-14.323,17.026-21.559c3.069-3.961,6.233-7.9,9.055-12.043.114-.165-.149-.35-.282-.206"
            transform="translate(339.772 515.179)"
            fill="#263238"
          />
          <path
            id="Caminho_1646"
            data-name="Caminho 1646"
            d="M216.4,325.792c-8.006-6.309-26.212-18.631-34.513-24.55-8.261-5.889-16.514-11.794-24.9-17.506-2.408-1.64-4.834-3.245-7.261-4.858s-4.94-3.1-7.312-4.766c-.138-.095-.26.133-.125.231,2.177,1.559,4.235,3.31,6.374,4.932,2.044,1.548,4.072,3.112,6.133,4.639,4.018,2.977,8.079,5.9,12.135,8.828q12.33,8.914,24.872,17.552c4.75,3.259,19.382,12.894,24.235,15.99.29.184.634-.274.361-.491"
            transform="translate(243.386 487.011)"
            fill="#263238"
          />
          <path
            id="Caminho_1647"
            data-name="Caminho 1647"
            d="M270.061,323.332c-7.4-6.133-24.268-17.921-32.022-23.59-7.846-5.734-15.649-11.522-23.525-17.216q-11.72-8.471-23.438-16.942c-7.575-5.477-15.112-11.04-23.113-15.887-4.376-2.652-8.866-5.111-13.48-7.326a88.27,88.27,0,0,0-13.935-5.693.131.131,0,0,0-.095.241c3.942,2.2,8.085,4.056,12.073,6.181q5.917,3.148,11.653,6.626c7.941,4.815,15.437,10.283,22.972,15.7,15.643,11.251,31.257,22.549,47.012,33.643,8.817,6.209,26.39,19.038,35.546,24.742.287.179.615-.26.352-.48"
            transform="translate(240.229 422.987)"
            fill="#263238"
          />
        </g>
        <path
          id="Caminho_2020"
          data-name="Caminho 2020"
          d="M314.086,323.039H222.558s-4.537-9.35-4.537-18.215V231.511h91.53v74.261c0,7.424,4.535,17.267,4.535,17.267"
          transform="translate(10795.255 13474.044)"
          fill="#027BC3"
        />
        <g
          id="Grupo_860"
          data-name="Grupo 860"
          transform="translate(11013.275 13705.555)"
          opacity="0.8"
        >
          <g id="Grupo_747" data-name="Grupo 747">
            <g
              id="Grupo_746"
              data-name="Grupo 746"
              clipPath="url(#clip-path-3)"
            >
              <path
                id="Caminho_1524"
                data-name="Caminho 1524"
                d="M314.086,323.039H222.558s-4.537-9.35-4.537-18.215V231.511h91.53v74.261c0,7.424,4.535,17.267,4.535,17.267"
                transform="translate(-218.021 -231.511)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
        <rect
          id="Retângulo_1584"
          data-name="Retângulo 1584"
          width="91.526"
          height="0.274"
          transform="translate(11013.277 13711.794)"
          fill="#dadada"
        />
        <rect
          id="Retângulo_1585"
          data-name="Retângulo 1585"
          width="91.526"
          height="0.274"
          transform="translate(11013.277 13718.366)"
          fill="#dadada"
        />
        <rect
          id="Retângulo_1586"
          data-name="Retângulo 1586"
          width="91.526"
          height="0.274"
          transform="translate(11013.277 13724.95)"
          fill="#dadada"
        />
        <rect
          id="Retângulo_1587"
          data-name="Retângulo 1587"
          width="91.526"
          height="0.274"
          transform="translate(11013.277 13731.521)"
          fill="#dadada"
        />
        <rect
          id="Retângulo_1588"
          data-name="Retângulo 1588"
          width="91.526"
          height="0.274"
          transform="translate(11013.277 13738.104)"
          fill="#dadada"
        />
        <rect
          id="Retângulo_1589"
          data-name="Retângulo 1589"
          width="91.526"
          height="0.274"
          transform="translate(11013.277 13744.677)"
          fill="#dadada"
        />
        <rect
          id="Retângulo_1590"
          data-name="Retângulo 1590"
          width="91.526"
          height="0.274"
          transform="translate(11013.277 13751.249)"
          fill="#dadada"
        />
        <rect
          id="Retângulo_1591"
          data-name="Retângulo 1591"
          width="91.526"
          height="0.274"
          transform="translate(11013.277 13757.833)"
          fill="#dadada"
        />
        <rect
          id="Retângulo_1592"
          data-name="Retângulo 1592"
          width="91.526"
          height="0.274"
          transform="translate(11013.277 13764.404)"
          fill="#dadada"
        />
        <rect
          id="Retângulo_1593"
          data-name="Retângulo 1593"
          width="91.526"
          height="0.274"
          transform="translate(11013.277 13770.986)"
          fill="#dadada"
        />
        <rect
          id="Retângulo_1594"
          data-name="Retângulo 1594"
          width="91.526"
          height="0.274"
          transform="translate(11013.277 13777.56)"
          fill="#dadada"
        />
        <path
          id="Caminho_2021"
          data-name="Caminho 2021"
          d="M309.794,274.467H218.321a2.379,2.379,0,0,1-.042-.274H309.75c.011.088.033.186.044.274"
          transform="translate(10795.472 13509.949)"
          fill="#dadada"
        />
        <path
          id="Caminho_2022"
          data-name="Caminho 2022"
          d="M310.791,278.036H219.242a2,2,0,0,1-.088-.274H310.7a1.993,1.993,0,0,0,.088.274"
          transform="translate(10796.208 13512.951)"
          fill="#dadada"
        />
        <path
          id="Caminho_2023"
          data-name="Caminho 2023"
          d="M225.707,323.036h-.285c-.724-1.976-1.449-4.06-2.084-6.089-.022-.1-.055-.186-.077-.274a53.44,53.44,0,0,1-1.6-6.3,2.334,2.334,0,0,1-.055-.274,16.974,16.974,0,0,1-.285-2.863V231.51h.274v75.726a16.968,16.968,0,0,0,.285,2.863c.011.088.033.177.044.274a55.913,55.913,0,0,0,1.613,6.3c.033.088.055.186.088.274.635,2.04,1.361,4.124,2.084,6.089"
          transform="translate(10798.027 13474.043)"
          fill="#dadada"
        />
        <path
          id="Caminho_2024"
          data-name="Caminho 2024"
          d="M229.281,323.036H229c-.724-1.976-1.447-4.06-2.084-6.089-.022-.1-.055-.186-.077-.274a53.441,53.441,0,0,1-1.6-6.3,2.34,2.34,0,0,1-.055-.274,16.974,16.974,0,0,1-.285-2.863V231.51h.274v75.726a16.974,16.974,0,0,0,.285,2.863c.011.088.033.177.044.274a55.907,55.907,0,0,0,1.613,6.3c.033.088.055.186.088.274.635,2.04,1.361,4.124,2.084,6.089"
          transform="translate(10801.035 13474.043)"
          fill="#dadada"
        />
        <path
          id="Caminho_2025"
          data-name="Caminho 2025"
          d="M232.851,323.036h-.285c-.724-1.976-1.449-4.06-2.084-6.089-.022-.1-.055-.186-.077-.274a53.437,53.437,0,0,1-1.6-6.3,2.331,2.331,0,0,1-.055-.274,16.974,16.974,0,0,1-.285-2.863V231.51h.274v75.726a16.97,16.97,0,0,0,.285,2.863c.011.088.033.177.044.274a55.924,55.924,0,0,0,1.613,6.3c.033.088.055.186.088.274.635,2.04,1.361,4.124,2.084,6.089"
          transform="translate(10804.038 13474.043)"
          fill="#dadada"
        />
        <path
          id="Caminho_2026"
          data-name="Caminho 2026"
          d="M236.427,323.036h-.285c-.724-1.976-1.447-4.06-2.084-6.089-.033-.1-.055-.186-.088-.274a55.7,55.7,0,0,1-1.6-6.3c-.011-.087-.033-.186-.044-.274a16.969,16.969,0,0,1-.285-2.863V231.51h.274v75.726a16.974,16.974,0,0,0,.285,2.863c.011.088.033.177.044.274a55.9,55.9,0,0,0,1.613,6.3c.033.088.055.186.088.274.635,2.04,1.361,4.124,2.084,6.089"
          transform="translate(10807.046 13474.043)"
          fill="#dadada"
        />
        <path
          id="Caminho_2027"
          data-name="Caminho 2027"
          d="M240,323.036h-.285c-.724-1.976-1.449-4.06-2.084-6.089-.022-.1-.055-.186-.077-.274a53.452,53.452,0,0,1-1.6-6.3,2.34,2.34,0,0,1-.055-.274,16.974,16.974,0,0,1-.285-2.863V231.51h.274v75.726a16.974,16.974,0,0,0,.285,2.863c.011.088.033.177.044.274a55.913,55.913,0,0,0,1.613,6.3c.033.088.055.186.088.274.635,2.04,1.361,4.124,2.084,6.089"
          transform="translate(10810.048 13474.043)"
          fill="#dadada"
        />
        <path
          id="Caminho_2028"
          data-name="Caminho 2028"
          d="M243.571,323.036h-.285c-.724-1.976-1.449-4.06-2.084-6.089-.022-.1-.055-.186-.077-.274a53.437,53.437,0,0,1-1.6-6.3,2.318,2.318,0,0,1-.055-.274,16.97,16.97,0,0,1-.285-2.863V231.51h.274v75.726a16.973,16.973,0,0,0,.285,2.863c.011.088.033.177.044.274a55.917,55.917,0,0,0,1.613,6.3c.033.088.055.186.088.274.635,2.04,1.361,4.124,2.084,6.089"
          transform="translate(10813.057 13474.043)"
          fill="#dadada"
        />
        <path
          id="Caminho_2029"
          data-name="Caminho 2029"
          d="M247.14,323.036h-.285c-.724-1.976-1.447-4.06-2.084-6.089-.033-.1-.055-.186-.088-.274a55.724,55.724,0,0,1-1.6-6.3c-.011-.087-.033-.186-.044-.274a16.971,16.971,0,0,1-.285-2.863V231.51h.274v75.726a16.974,16.974,0,0,0,.285,2.863c.011.088.033.177.044.274a55.92,55.92,0,0,0,1.613,6.3c.033.088.055.186.088.274.635,2.04,1.361,4.124,2.084,6.089"
          transform="translate(10816.059 13474.043)"
          fill="#dadada"
        />
        <path
          id="Caminho_2030"
          data-name="Caminho 2030"
          d="M250.716,323.036h-.285c-.724-1.976-1.449-4.06-2.084-6.089-.033-.1-.055-.186-.088-.274a55.7,55.7,0,0,1-1.6-6.3c-.011-.087-.033-.186-.044-.274a16.969,16.969,0,0,1-.285-2.863V231.51h.274v75.726a16.974,16.974,0,0,0,.285,2.863c.011.088.033.177.044.274a55.907,55.907,0,0,0,1.613,6.3c.033.088.055.186.088.274.635,2.04,1.361,4.124,2.084,6.089"
          transform="translate(10819.066 13474.043)"
          fill="#dadada"
        />
        <path
          id="Caminho_2031"
          data-name="Caminho 2031"
          d="M254.285,323.036H254c-.724-1.976-1.449-4.06-2.084-6.089-.022-.1-.055-.186-.077-.274a53.434,53.434,0,0,1-1.6-6.3,2.34,2.34,0,0,1-.055-.274,16.971,16.971,0,0,1-.285-2.863V231.51h.274v75.726a16.974,16.974,0,0,0,.285,2.863c.011.088.033.177.044.274a55.9,55.9,0,0,0,1.613,6.3c.033.088.055.186.088.274.635,2.04,1.361,4.124,2.084,6.089"
          transform="translate(10822.069 13474.043)"
          fill="#dadada"
        />
        <path
          id="Caminho_2032"
          data-name="Caminho 2032"
          d="M257.861,323.036h-.285c-.724-1.976-1.449-4.06-2.084-6.089-.022-.1-.055-.186-.077-.274a53.458,53.458,0,0,1-1.6-6.3,2.327,2.327,0,0,1-.055-.274,16.968,16.968,0,0,1-.285-2.863V231.51h.274v75.726a16.968,16.968,0,0,0,.285,2.863c.011.088.033.177.044.274a55.914,55.914,0,0,0,1.613,6.3c.033.088.055.186.088.274.635,2.04,1.361,4.124,2.084,6.089"
          transform="translate(10825.077 13474.043)"
          fill="#dadada"
        />
        <path
          id="Caminho_2033"
          data-name="Caminho 2033"
          d="M261.43,323.036h-.285c-.724-1.976-1.449-4.06-2.084-6.089-.033-.1-.055-.186-.088-.274a55.718,55.718,0,0,1-1.6-6.3c-.011-.087-.033-.186-.044-.274a16.972,16.972,0,0,1-.285-2.863V231.51h.274v75.726a16.968,16.968,0,0,0,.285,2.863c.011.088.033.177.044.274a55.92,55.92,0,0,0,1.613,6.3c.033.088.055.186.088.274.635,2.04,1.361,4.124,2.084,6.089"
          transform="translate(10828.08 13474.043)"
          fill="#dadada"
        />
        <path
          id="Caminho_2034"
          data-name="Caminho 2034"
          d="M265.005,323.036h-.285c-.724-1.976-1.447-4.06-2.084-6.089-.033-.1-.055-.186-.088-.274a55.711,55.711,0,0,1-1.6-6.3c-.011-.087-.033-.186-.044-.274a16.98,16.98,0,0,1-.285-2.863V231.51h.274v75.726a16.971,16.971,0,0,0,.285,2.863c.011.088.033.177.044.274a55.92,55.92,0,0,0,1.613,6.3c.033.088.055.186.088.274.635,2.04,1.361,4.124,2.084,6.089"
          transform="translate(10831.087 13474.043)"
          fill="#dadada"
        />
        <path
          id="Caminho_2035"
          data-name="Caminho 2035"
          d="M268.575,323.036h-.285c-.724-1.976-1.447-4.06-2.084-6.089-.022-.1-.055-.186-.077-.274a53.446,53.446,0,0,1-1.6-6.3,2.34,2.34,0,0,1-.055-.274,16.974,16.974,0,0,1-.285-2.863V231.51h.274v75.726a16.968,16.968,0,0,0,.285,2.863c.011.088.033.177.044.274a55.92,55.92,0,0,0,1.613,6.3c.033.088.055.186.088.274.635,2.04,1.361,4.124,2.084,6.089"
          transform="translate(10834.09 13474.043)"
          fill="#dadada"
        />
        <path
          id="Caminho_2036"
          data-name="Caminho 2036"
          d="M225.495,241.553c5.411-.226,10.823-.331,16.236-.42s10.823-.083,16.234-.129c5.413.044,10.825.039,16.236.127l8.118.153c2.707.09,5.411.144,8.118.269-2.707.125-5.411.179-8.118.269l-8.118.153c-5.411.088-10.823.083-16.236.127-5.411-.046-10.823-.039-16.234-.129s-10.825-.193-16.236-.42"
          transform="translate(10801.542 13482.029)"
          fill="#263238"
        />
        <path
          id="Caminho_2037"
          data-name="Caminho 2037"
          d="M225.495,245.058c5.411-.227,10.823-.331,16.236-.42s10.823-.083,16.234-.129c5.413.044,10.825.039,16.236.127l8.118.153c2.707.09,5.411.144,8.118.269-2.707.125-5.411.179-8.118.269l-8.118.153c-5.411.088-10.823.083-16.236.127-5.411-.046-10.823-.039-16.234-.129s-10.825-.193-16.236-.42"
          transform="translate(10801.542 13484.978)"
          fill="#263238"
        />
        <path
          id="Caminho_2038"
          data-name="Caminho 2038"
          d="M225.495,248.563c3.868-.226,7.737-.331,11.607-.42s7.737-.114,11.605-.129c3.868.013,7.739.028,11.607.127l5.8.153c1.933.09,3.868.144,5.8.269-1.935.125-3.87.179-5.8.269l-5.8.153c-3.868.1-7.739.114-11.607.127-3.868-.015-7.737-.028-11.605-.129s-7.739-.193-11.607-.42"
          transform="translate(10801.542 13487.927)"
          fill="#263238"
        />
        <path
          id="Caminho_2039"
          data-name="Caminho 2039"
          d="M225.495,255.573c2.349-.227,4.7-.331,7.046-.42s4.7-.114,7.045-.129c2.349.013,4.7.028,7.046.127l3.522.153c1.175.09,2.349.144,3.524.269-1.175.125-2.349.179-3.524.269l-3.522.153c-2.349.1-4.7.114-7.046.127-2.348-.015-4.7-.028-7.045-.129s-4.7-.193-7.046-.42"
          transform="translate(10801.542 13493.824)"
          fill="#263238"
        />
        <path
          id="Caminho_2040"
          data-name="Caminho 2040"
          d="M225.495,259.078c5.411-.226,10.823-.331,16.236-.42s10.823-.083,16.234-.129c5.413.044,10.825.039,16.236.127l8.118.153c2.707.09,5.411.144,8.118.269-2.707.125-5.411.179-8.118.269l-8.118.153c-5.411.088-10.823.083-16.236.127-5.411-.046-10.823-.039-16.234-.129s-10.825-.193-16.236-.42"
          transform="translate(10801.542 13496.771)"
          fill="#263238"
        />
        <path
          id="Caminho_2041"
          data-name="Caminho 2041"
          d="M225.495,262.583c4.594-.226,9.188-.331,13.78-.42s9.188-.083,13.782-.129c4.592.044,9.186.039,13.78.127l6.89.153c2.3.09,4.594.144,6.89.269-2.3.125-4.594.179-6.89.269l-6.89.153c-4.594.088-9.188.083-13.78.127-4.594-.046-9.188-.039-13.782-.129s-9.186-.193-13.78-.42"
          transform="translate(10801.542 13499.721)"
          fill="#263238"
        />
        <path
          id="Caminho_2042"
          data-name="Caminho 2042"
          d="M225.495,266.088c3.793-.226,7.586-.331,11.377-.42s7.588-.114,11.381-.129c3.793.013,7.584.028,11.377.127l5.689.153c1.9.09,3.793.144,5.689.269-1.9.125-3.793.179-5.689.269l-5.689.153c-3.793.1-7.584.114-11.377.127-3.793-.015-7.586-.028-11.381-.129s-7.584-.193-11.377-.42"
          transform="translate(10801.542 13502.67)"
          fill="#263238"
        />
        <g
          id="Grupo_861"
          data-name="Grupo 861"
          transform="translate(11004.524 13694.964)"
          opacity="0.5"
        >
          <g id="Grupo_750" data-name="Grupo 750">
            <g
              id="Grupo_749"
              data-name="Grupo 749"
              clipPath="url(#clip-path-4)"
            >
              <path
                id="Caminho_1547"
                data-name="Caminho 1547"
                d="M247.478,227.031l-3.994,2.6L248,227.9l1.7,2.843-1.825,1.589,2.326-.749.654,1.092-2.478,1.8,2.826-1.217.49.819-33.45,19.988-1.467-2.458,2.615-1.729-3,1.079-.626-1.046,1.966-1.4-2.3.849-.3-.508,3.13-2.535-3.918,1.217-1.064-1.78,33.45-19.988Z"
                transform="translate(-213.269 -225.759)"
                fill="#fff"
              />
              <path
                id="Caminho_1548"
                data-name="Caminho 1548"
                d="M288.813,251.911l-3.692-2.891,3.038,3.66-2.138,2.517-2.07-1.182,1.438,1.926-.83.98L282.1,255.2l2.016,2.245-.619.727-29.213-24.805,1.854-2.184,2.44,1.885-1.95-2.462.8-.937,1.926,1.392-1.515-1.876.385-.453,3.368,2.112-2.373-3.283,1.342-1.58,29.213,24.805Z"
                transform="translate(-178.76 -225.576)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
        <path
          id="Caminho_2043"
          data-name="Caminho 2043"
          d="M370.4,227.854h-90.61l-20.269-13.908,20.269-13.906H370.4Z"
          transform="translate(10900.467 13502.128)"
          fill="#027BC3"
        />
        <path
          id="Caminho_2044"
          data-name="Caminho 2044"
          d="M377.565,214.938h-90.61L266.686,201.03l20.269-13.906h90.61Z"
          transform="translate(10912.731 13480.026)"
          fill="#455a64"
        />
        <path
          id="Caminho_2045"
          data-name="Caminho 2045"
          d="M327.419,240.867c-15.8,21.4-32.279,46.239-36.292,73.079-.049.407.6.54.7.136a159.313,159.313,0,0,1,6.987-21.489c10.389-2.86,20.589-5.208,26.044-15.521,5.563-10.6,4.858-24.419,3.8-35.966-.076-.618-.914-.651-1.236-.239"
          transform="translate(10954.55 13571.521)"
          fill="#027BC3"
        />
        <path
          id="Caminho_2046"
          data-name="Caminho 2046"
          d="M294.036,288.135c7.1-15.931,16.213-31.927,27.8-45.027.076-.087.2.041.127.127-7.586,9.332-13.347,19.493-18.975,29.831,5.233-5.314,10.967-10.072,15.923-15.7.095-.111.249.049.157.157-4.921,5.908-10.3,12.325-16.706,16.7q-2.582,4.758-5.214,9.535c.431-.241.876-.455,1.318-.675.079-.038.146.079.068.119-.488.247-.973.507-1.478.721-.832,1.5-1.657,3.012-2.513,4.514-.19.336-.653.041-.5-.3"
          transform="translate(10959.482 13575.775)"
          fill="#263238"
        />
        <path
          id="Caminho_2047"
          data-name="Caminho 2047"
          d="M296.02,262.775a93.226,93.226,0,0,0,12.13-8.1c.1-.084.255.06.149.149a64.043,64.043,0,0,1-12.105,8.247c-.2.1-.369-.187-.173-.3"
          transform="translate(10962.779 13595.577)"
          fill="#263238"
        />
        <path
          id="Caminho_2048"
          data-name="Caminho 2048"
          d="M291.169,335.692c.033.325.586.32.578-.027.081-8.838.008-17.717.141-26.6,2.264-4.568,7.125-7.1,10.5-10.761a46.942,46.942,0,0,0,6.3-8.776,63.055,63.055,0,0,0,7.315-24.381c.933-9.454-2.654-40.651-6.64-39.57-2.069.564-8.754,16.015-10.53,21.123a147.584,147.584,0,0,0-6.29,25.9c-3.229,20.8-2.961,42.159-1.38,63.089"
          transform="translate(10952.679 13545.784)"
          fill="#027BC3"
        />
        <path
          id="Caminho_2049"
          data-name="Caminho 2049"
          d="M290.478,294.119l.041-.035c.483-11.184,3-22.476,5.363-33.323,2.54-11.685,5.509-22.687,11.522-33.109.111-.2.4-.024.3.173a122.941,122.941,0,0,0-9.256,25.414c.4-.632.892-1.228,1.31-1.822.165-.236.553-.016.39.225a16.567,16.567,0,0,1-1.879,2.654.182.182,0,0,1-.079.035c-2.071,8.353-3.557,16.9-4.829,25.417.095-.108.187-.225.282-.328.065-.07.176.019.13.1-.157.282-.333.564-.5.843-.643,4.327-1.236,8.643-1.833,12.924,7.7-6.583,14.28-14.342,17.989-23.9.016-.043.089-.024.073.022-3.636,9.915-9.364,18.731-18.184,24.747-.163,1.152-.323,2.307-.485,3.454-.035.239-.415.174-.415-.057.005-1.017.054-2.039.092-3.061a.237.237,0,0,1-.027-.371"
          transform="translate(10953.308 13549.229)"
          fill="#263238"
        />
        <path
          id="Caminho_2050"
          data-name="Caminho 2050"
          d="M291.784,263.227c5.886-8.784,11.384-17.316,15.69-27.025.049-.108.236-.03.192.081-3.63,9.646-8.711,19.423-15.586,27.171-.141.157-.415-.049-.3-.228"
          transform="translate(10955.628 13563.918)"
          fill="#263238"
        />
        <path
          id="Caminho_2051"
          data-name="Caminho 2051"
          d="M294.671,243.552c2.437-4.313,5.01-8.513,7.3-12.911.065-.122.233-.016.182.106a67.249,67.249,0,0,1-7.109,13.024c-.163.222-.518.03-.377-.22"
          transform="translate(10960.563 13554.399)"
          fill="#263238"
        />
        <path
          id="Caminho_2052"
          data-name="Caminho 2052"
          d="M294.323,236.061a.038.038,0,0,1,0,.076.038.038,0,0,1,0-.076"
          transform="translate(10959.96 13563.766)"
          fill="#263238"
        />
        <path
          id="Caminho_2053"
          data-name="Caminho 2053"
          d="M303.823,301.7a90.033,90.033,0,0,1-2.066,17.414c.092.629.182,1.258.274,1.906a82.97,82.97,0,0,0,3.115-19.409.6.6,0,0,0,.027-.6l0-.019a128.408,128.408,0,0,0-1.949-26.3c-2.736-16.243-8.716-31.832-20.046-44.008a.808.808,0,0,0-1.25.26c-4.571,11.623-2.977,25.49.916,37.086a67.81,67.81,0,0,0,8.985,17.617c3.885,5.463,8.841,10.086,12,16.053"
          transform="translate(10934.044 13554.219)"
          fill="#027BC3"
        />
        <path
          id="Caminho_2054"
          data-name="Caminho 2054"
          d="M281.409,232.634c10.734,17.425,18.206,38.084,19.119,58.664.016.366-.6.461-.651.089-.255-1.849-.537-3.682-.832-5.509l-.019-.005c-4.444-4.254-9-8.744-12.181-14.06-.057-.092.081-.2.155-.117,3.863,4.416,7.467,8.939,11.829,12.878q-1.147-6.828-2.755-13.442c-.287-.328-.567-.667-.849-1-.187-.22.119-.537.317-.32.084.1.171.193.258.287a155.47,155.47,0,0,0-5.612-17.823c-2.733-3.259-5.788-6.076-7.781-10a.082.082,0,0,1,.141-.084c1.6,2.248,3.153,4.506,4.967,6.591.724.83,1.518,1.605,2.267,2.416a137.411,137.411,0,0,0-8.79-18.322.241.241,0,0,1,.417-.241"
          transform="translate(10937.152 13557.706)"
          fill="#263238"
        />
        <path
          id="Caminho_2055"
          data-name="Caminho 2055"
          d="M282.085,240.527c3.47,4.669,7.426,9.126,10.649,13.954.095.138-.108.279-.222.171-4.145-3.888-7.654-9.2-10.66-13.99-.095-.149.133-.271.233-.135"
          transform="translate(10938.645 13571.308)"
          fill="#263238"
        />
        <path
          id="Caminho_2056"
          data-name="Caminho 2056"
          d="M286.154,246.057l.149.149a.112.112,0,0,1-.16.157l-.149-.146a.113.113,0,0,1,.16-.16"
          transform="translate(10945.713 13580.813)"
          fill="#263238"
        />
        <path
          id="Caminho_2057"
          data-name="Caminho 2057"
          d="M274.922,245.58c.862,8.966,5.58,19.672,11.33,26.58a28.192,28.192,0,0,0,9.185,7.168c3.98,1.968,8.147,4.238,12.263,5.853a80.368,80.368,0,0,1,4.341,15.418c.133-.447.266-.916.4-1.364-2.643-22.7-17.33-42.1-36.115-54.549a.911.911,0,0,0-1.4.892"
          transform="translate(10926.815 13578.257)"
          fill="#027BC3"
        />
        <path
          id="Caminho_2058"
          data-name="Caminho 2058"
          d="M277.342,247.563c-.133-.117.046-.355.19-.247a76.868,76.868,0,0,1,23.94,29.5c.136.312-.29.583-.466.274-.873-1.532-1.765-3.02-2.67-4.482l-.014,0c-4.286-.808-8.348-3.825-11.531-6.691-.087-.076.041-.2.127-.127a26.3,26.3,0,0,0,5.425,3.812c1.749.887,3.63,1.448,5.449,2.158-1.589-2.521-3.245-4.94-4.975-7.288a50,50,0,0,1-12.886-7.244c-.122-.089-.016-.29.119-.206a128.868,128.868,0,0,0,11.978,6.4,107.335,107.335,0,0,0-8.456-9.858c-1.456-.77-2.933-1.543-4.346-2.367a.12.12,0,0,1,.092-.22,15.459,15.459,0,0,1,3.335,1.64c-1.694-1.716-3.44-3.408-5.311-5.059"
          transform="translate(10930.886 13582.979)"
          fill="#263238"
        />
        <path
          id="Caminho_2059"
          data-name="Caminho 2059"
          d="M277.573,250.293a5.718,5.718,0,0,1,.724.515c.068.062-.019.149-.092.119a5.179,5.179,0,0,1-.754-.428c-.13-.081-.008-.29.122-.206"
          transform="translate(10931.057 13588.086)"
          fill="#263238"
        />
        <path
          id="Caminho_2060"
          data-name="Caminho 2060"
          d="M332.062,319.454h-51.48l4.156-46.879.914-10.34H327l.911,10.34Z"
          transform="translate(10936.511 13608.554)"
          fill="#455a64"
        />
        <path
          id="Caminho_2061"
          data-name="Caminho 2061"
          d="M325.283,272.574H282.116l.914-10.338h41.343Z"
          transform="translate(10939.134 13608.556)"
          fill="#263238"
        />
        <rect
          id="Retângulo_1595"
          data-name="Retângulo 1595"
          width="46.269"
          height="10.232"
          transform="translate(11219.699 13863.561)"
          fill="#455a64"
        />
      </g>
    </svg>
  );
};

export default memo(NoRequestsSVG);

import {
  AssignmentOutlined,
  AssignmentRounded,
  CheckCircleRounded,
  HomeOutlined,
  HomeRounded,
  ReceiptLongOutlined,
  ReceiptLongRounded,
  SettingsOutlined,
  TaskAltOutlined
} from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import React, { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ADMIN_BASE_URL } from "../../api/urls";
import {
  selectIsAdmin,
  selectIsApprover,
} from "../../store/features/accountSlice";
import { selectSingleExpensesNum } from "../../store/features/expensesSlice";
import { closeElement } from "../../store/features/portalSlice";
import CreationButton from "./components/CreationButton";
import HelpButton from "./components/HelpButton";
import SidebarButtonItem from "./components/SidebarButtonItem";
import ToggleThemeButton from "./components/ToggleThemeButton";

const SingleExpensesButton = () => {
  const expensesNum = useSelector(selectSingleExpensesNum);
  return (
    <SidebarButtonItem
      Icon={ReceiptLongOutlined}
      path={"/expenses"}
      title={"Despesas avulsas"}
      tour_id={"single_expenses"}
      badge={expensesNum}
      ActiveIcon={ReceiptLongRounded}
    />
  );
};

function Sidebar(props) {
  const dispatch = useDispatch();

  const isApprover = useSelector(selectIsApprover);
  const { pathname } = useLocation();

  const isAdm = useSelector(selectIsAdmin);

  const navigate = useNavigate();

  const visitedRequest = useMemo(() => {
    const regex = /\/approval-requests\/(\w+)/;
    const match = pathname.match(regex);
    const id = match && match[1];
    return id;
  }, [pathname]);

  const handleRequestClick = useCallback(() => {
    navigate("/approval-requests", {
      state: {
        visitedRequest,
      },
    });
  }, [visitedRequest]);

  useEffect(() => {
    const appbar = document.getElementById("appbar_main");
    if (!appbar) return;
    appbar.style.boxShadow = "none";

    dispatch(closeElement("expenseView"));
    dispatch(closeElement("routeView"));
    dispatch(closeElement("manualRouteView"));
  }, [pathname]);

  return (
    <Box
      pt={2}
      position={"fixed"}
      left={0}
      width={{ xs: 0, md: "6.7em" }}
      overflow={"hidden"}
      height={"calc(100%)"}
      display={"flex"}
      flexDirection={"column"}
      bgcolor={"elevation1.main"}
      boxShadow={pathname.includes("approval-requests") ? 2 : 0}
      zIndex={(z) => z.zIndex.appBar + 40}
      sx={{
        transition: "box-shadow .15s ease",
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
      }}
    >
      <CreationButton />
      <SidebarButtonItem
        Icon={HomeOutlined}
        ActiveIcon={HomeRounded}
        path={"/home"}
        title={"Início"}
      />
      <SingleExpensesButton />
      <SidebarButtonItem
        Icon={AssignmentOutlined}
        ActiveIcon={AssignmentRounded}
        onClick={handleRequestClick}
        disableLink
        tour_id={"requests"}
        path={"/approval-requests"}
        title={"Relatórios de despesas"}
      />
      {isApprover && (
        <SidebarButtonItem
          Icon={TaskAltOutlined}
          ActiveIcon={CheckCircleRounded}
          path={"/approver"}
          tour_id={"approver"}
          title={"Para aprovar"}
        />
      )}
      <Box flex={1} />
      <HelpButton />
      {isAdm && (
        <Tooltip placement="right" title="Acessar painel do administrador">
          <IconButton
            tour_id={"admin"}
            LinkComponent={"a"}
            href={ADMIN_BASE_URL}
            target="_blank"
            sx={{ alignSelf: "center", mb: 1 }}
          >
            <SettingsOutlined />
          </IconButton>
        </Tooltip>
      )}
      <ToggleThemeButton />
      {/* <SettingsButton /> */}
    </Box>
  );
}

export default memo(Sidebar);

import { ArrowForward } from "@mui/icons-material";
import {
  Box,
  Button,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Tour from "reactour";
import "../App.css";
import {
  selectIsAdmin,
  selectIsApprover
} from "../store/features/accountSlice";
import theme from "../theme";

const innerTheme = createTheme({
  ...theme("light"),
});

const StepContent = memo(({ headerTitle = "", title = "", subtitle = "" }) => (
  <Box>
    {headerTitle && (
      <Typography
        gutterBottom
        color={"text.secondary"}
        fontWeight={"600"}
        fontSize={"1.2rem"}
      >
        {headerTitle}
      </Typography>
    )}
    {title && (
      <Typography gutterBottom fontWeight={"500"} fontSize={"1.4rem"}>
        {title}
      </Typography>
    )}
    {subtitle && (
      <Typography color={"text.secondary"} fontSize={"1.1rem"}>
        {subtitle}
      </Typography>
    )}
  </Box>
));

const steps = (isApprover, isAdm) => {
  let defaultSteps = [
    {
      selector: `[tour_id="creation_button"]`,
      content: (
        <StepContent
          headerTitle="Boas vindas à Notepay"
          title="Aqui você cria despesas, percursos, relatórios e mais"
        />
      ),
    },
    {
      selector: `[tour_id="single_expenses"]`,
      content: (
        <StepContent
          title="Suas despesas avulsas"
          subtitle="Prepare as despesas antes de colocá-las em um relatório"
        />
      ),
    },
    {
      selector: `[tour_id="requests"]`,
      content: (
        <StepContent
          title="Seus relatórios de despesas"
          subtitle="Crie relatórios, adicione despesas e acompanhe as aprovações"
        />
      ),
    },
  ];

  if (isApprover) {
    defaultSteps.push({
      selector: `[tour_id="approver"]`,
      content: (
        <StepContent
          title="Aprove relatórios de despesas e adiantamentos"
          subtitle="Aqui, você acompanha relatórios e adiantamentos que aguardam sua aprovação"
        />
      ),
    });
  }
  if (isAdm) {
    defaultSteps.push({
      selector: `[tour_id="admin"]`,
      content: (
        <StepContent
          title="Painel de administração"
          subtitle="Gerencie sua equipe, regras, políticas e muito mais"
        />
      ),
    });
  }

  return defaultSteps;
};

const CustomTour = () => {
  const visited = window.localStorage.getItem("visited");

  const isApprover = useSelector(selectIsApprover);
  const isAdm = useSelector(selectIsAdmin);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!visited) {
      setTimeout(() => {
        setOpen(true);
      }, 1000);
    }
  }, []);

  const closeTour = () => setOpen(false);

  const onBeforeClose = () => {
    window.localStorage.setItem("visited", "true");
  };

  return (
    <ThemeProvider theme={innerTheme}>
      <Tour
        steps={steps(isApprover, isAdm)}
        isOpen={open}
        onRequestClose={closeTour}
        showNumber={false}
        disableDotsNavigation
        showNavigation={false}
        prevButton={<></>}
        rounded={20}
        showCloseButton={true}
        disableInteraction
        lastStepNextButton={
          <Button sx={{ ml: "60%" }} variant="outlined" size="large">
            Entendido
          </Button>
        }
        nextButton={
          <Button
            endIcon={<ArrowForward />}
            sx={{ ml: "60%" }}
            variant="outlined"
            size="large"
          >
            Próximo
          </Button>
        }
        className="tour-mask"
        onBeforeClose={onBeforeClose}
      />
    </ThemeProvider>
  );
};

export default memo(CustomTour);

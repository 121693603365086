import { Box, Paper, useScrollTrigger } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import ViewerHeader from "./ViewerHeader";
import ExpenseByType from "./contents/ExpenseByType";
import ManualRoute from "./contents/ManualRoute";
import MapRoute from "./contents/MapRoute";
import WarningsList from "./contents/WarningsList";

export default ({
  open,
  onClose,
  expense = {},
  onNextExpense,
  onPrevExpense,
  activeIndex,
  totalExpenses,
  toggleRejectExpense,
  isRejected,
  userId,
}) => {
  const [content, setContent] = useState(undefined);

  const scrolled = useScrollTrigger({
    disableHysteresis: true,
    target: content,
    threshold: 0,
  });

  const handleToggleRejectExpense = useCallback(() => {
    toggleRejectExpense(expense.id);
  }, [expense.id]);

  const handleKeydown = useCallback((e) => {
    if (e.keyCode === 27) {
      onClose();
    }
  }, []);

  useEffect(() => {
    if (open) {
      window.addEventListener("keydown", handleKeydown);
    } else {
      return () => {
        window.removeEventListener("keydown", handleKeydown);
      };
    }
  }, [open]);

  const requiredReceipt = useMemo(
    () => !Boolean(expense?.receipts?.length) && expense?.required_receipt,
    [expense]
  );

  return (
    <Box
      elevation={0}
      borderRadius={3}
      component={Paper}
      zIndex={200}
      display={"flex"}
      flexDirection={"column"}
      boxShadow={5}
      position={"fixed"}
      right={15}
      top={69}
      width={{ xs: "25rem", xl: "34em" }}
      height={"calc(100% - 90px)"}
      sx={{
        outline: (t) =>
          isRejected ? `2px solid ${t.palette.error.main}` : "none",
        transform: open ? "translateY(0)" : "translateY(100vh)",
        transition: "transform .15s ease-in-out",
      }}
    >
      <ViewerHeader
        activeIndex={activeIndex + 1}
        total={totalExpenses}
        rejected={isRejected}
        toggleRejectExpense={handleToggleRejectExpense}
        scrolled={scrolled}
        onClose={onClose}
        onPrev={onPrevExpense}
        onNext={onNextExpense}
      />
      <Box
        ref={(node) => node && setContent(node)}
        flex={1}
        flexBasis={0}
        overflow={"scroll"}
        sx={{
          overscrollBehavior: "contain",
        }}
        p={2}
        pt={1}
        pb={10}
      >
        {(requiredReceipt || Boolean(expense?.warnings?.length)) && (
          <WarningsList
            requiredReceipt={requiredReceipt}
            warnings={expense?.warnings || []}
          />
        )}
        {expense.is_route ? (
          expense.route_mode === "map" ? (
            <MapRoute data={expense} />
          ) : (
            expense.route_mode === "manual" && (
              <ManualRoute data={expense} userId={userId} />
            )
          )
        ) : (
          <ExpenseByType data={expense} userId={userId} />
        )}
      </Box>
    </Box>
  );
};

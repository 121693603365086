import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Input from "../../../../components/inputs/text-input";
import CustomFieldsForm from "../../../../modals/new-request/components/CustomFieldsForm";
import { openSnackbar } from "../../../../store/features/base/snackbarBaseSlice";
import {
  selectCustomFieldsEntities,
  selectCustomFieldsIds,
} from "../../../../store/features/configs/customFieldsSlice";
import { requestsOfflineActions } from "../../../../store/features/requestsSlice";

function EditModal({
  open,
  onClose,
  initialTitle,
  initialObs,
  initialCustomFields = {},
}) {
  const { requestId } = useParams();
  const dispatch = useDispatch();
  const [title, setTitle] = useState(initialTitle || "");
  const [obs, setObs] = useState(initialObs || "");

  const customFieldsIds = useSelector(selectCustomFieldsIds);
  const customFieldsEntities = useSelector(selectCustomFieldsEntities);

  const initialCustomFieldsValues = useMemo(() => {
    let values = {};
    customFieldsIds?.forEach((fieldId) => {
      values[fieldId] = initialCustomFields[fieldId]?.value;
    });
    return values;
  }, [customFieldsIds, initialCustomFields]);

  const [customFieldsValues, setCustomFieldsValues] = useState(
    customFieldsIds?.length && initialCustomFields
      ? initialCustomFieldsValues
      : null
  );

  const requiredCustomFieldsIsOk = useMemo(() => {
    if (!customFieldsValues) return true;
    return customFieldsIds
      ?.filter((fieldId) => customFieldsEntities[fieldId]?.isRequired)
      ?.every((fieldId) => Boolean(customFieldsValues[fieldId]));
  }, [customFieldsIds, customFieldsEntities, customFieldsValues]);

  const isOk = useMemo(
    () => requiredCustomFieldsIsOk && [title.trim()].every(Boolean),
    [title, requiredCustomFieldsIsOk]
  );

  const handleChangeCustomFieldValue = useCallback((fieldId, value) => {
    setCustomFieldsValues((prev) => ({ ...prev, [fieldId]: value }));
  }, []);

  useEffect(() => {
    if (open) {
      setTitle(initialTitle);
      setObs(initialObs);
      setCustomFieldsValues(
        customFieldsIds?.length && initialCustomFields
          ? initialCustomFieldsValues
          : null
      );
    }
  }, [open]);

  const handleSave = async () => {
    if (!isOk) return;
    dispatch(
      requestsOfflineActions.updateRequest(requestId, {
        title,
        obs,
        custom_fields: customFieldsValues,
      })
    );
    dispatch(
      openSnackbar({
        message: "Relatório salvo",
      })
    );
    onClose();
  };

  return (
    <Dialog scroll="body" open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle fontSize={"1.1rem"}>
        Editar relatório
        <Box flex={1} />
        <IconButton onClick={onClose} sx={{ m: -1 }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ pt: 0 }}>
        <Input
          value={title}
          onChange={setTitle}
          autoFocus
          required
          label={"Título"}
          margin={"normal"}
          variant={"filled"}
        />
        <Input
          value={obs}
          onChange={setObs}
          label={"Descrição"}
          multiline
          margin={"dense"}
          variant={"filled"}
        />
        <CustomFieldsForm
          values={customFieldsValues}
          onChangeValue={handleChangeCustomFieldValue}
          variant="filled"
        />
      </DialogContent>
      <DialogActions sx={{ height: 70 }}>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          disabled={!isOk}
          variant="contained"
          onClick={handleSave}
          disableElevation
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(EditModal);

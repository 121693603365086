import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  tableCellClasses,
} from "@mui/material";
import React, { memo, useCallback } from "react";

import { visuallyHidden } from "@mui/utils";

const headCells = [
  {
    id: "total_receipts",
    sorteable: true,
    numeric: false,
    align: "center",
    label: "Anexos",
    width: 100,
  },
  {
    id: "date",
    sorteable: true,
    numeric: false,
    label: "Data da despesa",
    align: "left",
    width: 180,
  },
  {
    id: "type_id",
    sorteable: false,
    label: "Detalhes",
    align: "left",
    width: "auto",
  },
  {
    id: "payment_type",
    sorteable: false,
    numeric: false,
    label: "Pagamento",
    tooltip: "Forma de pagamento",
    align: "left",
    width: "auto",
  },
  {
    id: "project_id",
    sorteable: false,
    numeric: false,
    label: "Projeto",
    align: "left",
    width: "auto",
  },
  {
    id: "refundable",
    align: "left",
    sorteable: true,
    numeric: false,
    label: "Reemb.",
    tooltip: "Reembolsável",
    width: 100,
  },
  {
    id: "amount",
    sorteable: true,
    numeric: true,
    align: "right",
    label: "Valor",
    width: 100,
  },
];

const ExpensesTableHeader = (props) => {
  const {
    expenseSettings = {},
    renderCustomAction,
    order,
    orderBy,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const renderCells = useCallback(
    (headCell) => {
      return (
        <TableCell
          key={headCell.id}
          align={headCell.align}
          width={headCell.width}
          sortDirection={orderBy === headCell.id ? order : false}
        >
          <Tooltip title={headCell.tooltip || headCell.label}>
            <span>
              <TableSortLabel
                disabled={!headCell.sorteable}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography variant="inherit" noWrap>
                  {headCell.label}
                </Typography>
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </span>
          </Tooltip>
        </TableCell>
      );
    },
    [orderBy, order]
  );

  return (
    <TableHead>
      <TableRow
        sx={{
          [`& .${tableCellClasses.root}`]: {
            color: "text.secondary",
            fontSize: ".875rem",
            fontWeight: "500",
            py: 1,
          },
        }}
      >
        {(expenseSettings.select || Boolean(renderCustomAction)) && (
          <TableCell width={70} align="center" padding="normal"></TableCell>
        )}
        {headCells.map(renderCells)}
        <TableCell width={120} padding="normal" align="right"></TableCell>
      </TableRow>
    </TableHead>
  );
};

export default memo(ExpensesTableHeader);

import { Box } from "@mui/material";
import React, { memo, useState } from "react";

function LeftContent({ isRoute, route, receipts }) {
  const [hovered, setHovered] = useState(false);
  return (
    <Box
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 83,
        height: "100%",
        borderRadius: 0,
        position: "relative",
      }}
    >
      {isRoute ? route : receipts(hovered)}
    </Box>
  );
}

export default memo(LeftContent);

import { w3cwebsocket } from "websocket";
import { WSS_URL } from "../urls";
import messageCapture from "./messageCapture";

class WebSockets {
  static _accessToken = "";
  static _isOnline = true;
  static _client = null;

  static setAccessToken(token) {
    this._accessToken = token;
  }

  static setConnectionIsOnline(bool) {
    if (!bool) {
      this._client?.close();
    }
    this._isOnline = !!bool;
  }

  static sendMessage(message) {
    this._client?.send(message);
  }

  static start() {
    this._client = new w3cwebsocket(
      `${WSS_URL}?accessToken=${this._accessToken}`
    );
    this._client.onopen = () => {
      console.log("WebSocket client connected");
    };
    this._client.onmessage = (message) => {
      const dataFromServer = message.data;
      console.log("Mensagem do WSS: ", dataFromServer);
      messageCapture(dataFromServer);
    };
    this._client.onclose = (event) => {
      console.log("WebSocket client disconnected", event);
      setTimeout(() => {
        console.log("Tentando reconexão WS");
        if (this._isOnline) {
          this.start();
        }
      }, 5000);
    };
  }
}

export default WebSockets;

import { AddOutlined } from "@mui/icons-material";
import React, { useState } from "react";
import MenuCreation from "../../../../../components/sidebar/components/MenuCreation";
import ActionButton from "./ActionButton";

const CreateAction = () => {
  const [menu, setMenu] = useState(null);
  return (
    <>
      <ActionButton
        onClick={(e) => setMenu(e.target)}
        startIcon={<AddOutlined />}
      >
        Criar
      </ActionButton>
      <MenuCreation
        anchorEl={menu}
        onClose={() => setMenu(null)}
        hiddenScanner
      />
    </>
  );
};

export default CreateAction;

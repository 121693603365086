import { Button } from "@mui/material";
import React from "react";

const ActionButton = ({ children, onClick, startIcon }) => {
  return (
    <Button
      onClick={onClick}
      startIcon={startIcon}
      variant="outlined"
      sx={{
        borderRadius: 100,
        boxShadow: 2,
        bgcolor: "background.default",
        ":hover": {
          bgcolor: "background.default",
          boxShadow: 3,
        },
      }}
    >
      {children}
    </Button>
  );
};

export default ActionButton;

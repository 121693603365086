import { Box, useMediaQuery, useScrollTrigger, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorBoundary from "../../errors/Boundary";
import { setBreadcrumbs } from "../../store/features/base/breadcrumbsSlice";
import { selectLayoutViewMode } from "../../store/features/base/settingsSlice";
import { selectRequestsVisitedStatus } from "../../store/features/requestsSlice";
import StatusNavigation from "./components/StatusNavigation";
import FetcherRenderer from "./components/list-renderers/FetcherRenderer";

function RequestsMain(props) {
  const dispatch = useDispatch();
  const visitedStatus = useSelector(selectRequestsVisitedStatus);

  const scrolled = useScrollTrigger({
    disableHysteresis: true,
    target: undefined,
    threshold: 0,
  });

  useEffect(() => {
    const appbar = document.getElementById("appbar_main");
    if (!appbar) return;
    if (scrolled) {
      appbar.style.boxShadow = "0 4px 3px -2px rgba(0,0,0,.3)";
    } else {
      appbar.style.boxShadow = "none";
    }
  }, [scrolled]);

  useEffect(() => {
    dispatch(setBreadcrumbs(["Relatórios de despesas"]));
  }, []);

  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  const layoutViewMode = useSelector(selectLayoutViewMode);

  return (
    <ErrorBoundary>
      <StatusNavigation selectedStatus={visitedStatus} />
      <Box
        mt={1}
        pl={{ md: layoutViewMode === "grid" ? "15em" : "15.5em", xs: 0 }}
        id="requests"
        position={"relative"}
      >
        {/* <Toolbar /> */}
        <FetcherRenderer status={isSmall ? "all" : visitedStatus} />
      </Box>
    </ErrorBoundary>
  );
}

export default RequestsMain;

import { Box, DialogContent, Typography } from "@mui/material";
import React, { useCallback } from "react";
import formatAmount from "../../../../utils/formatAmount";
import DateInput from "../../components/inputs/DateInput";
import DistanceInput from "../../components/inputs/DistanceInput";
import ObsInput from "../../components/inputs/ObsInput";
import PreRegisteredSnippet from "../../components/inputs/PreRegisteredSnippet";
import ProjectInput from "../../components/inputs/ProjectInput";
import RequestInput from "../../components/inputs/RequestInput";
import RoutePoliciesInput from "../../components/inputs/RoutePoliciesInput";
import ModalReceipts from "../../new-expense/components/ModalReceipts";

const ManualModeContent = ({
  values,
  canEditDistance,
  rate,
  receipts,
  dateInvalid,
  currency,
  amount,
  onChangeValue,
  onDropReceipts,
  onCropReceipt,
  onRemoveReceipt,
}) => {
  return (
    <DialogContent
      sx={{ p: 0, display: "flex", alignItems: "flex-start", flexWrap: "wrap" }}
    >
      <Box
        height={"100%"}
        flex={1}
        display={"flex"}
        flexDirection={"column"}
        borderRight={1}
        borderColor="divider"
      >
        <Box
          p={2}
          pr={2.5}
          pt={3}
          pb={6}
          flex={1}
          display="flex"
          flexDirection={"column"}
          flexBasis={1}
          className="hover-scrollbar"
          id="new_manual_route_fields_content"
          overflow="auto"
          gap={2.9}
        >
          <PreRegisteredSnippet
            value={values.snippet_id}
            onChange={useCallback(
              (v) => onChangeValue("snippet_id", v),
              [values.snippet_id]
            )}
          />
          <DistanceInput
            disabled={!canEditDistance}
            value={values.distance}
            onChange={useCallback(
              (v) => onChangeValue("distance", v),
              [values.distance]
            )}
          />
          <RoutePoliciesInput
            value={values?.route_policy_id}
            onChange={useCallback(
              (v) => onChangeValue("route_policy_id", v),
              [values.route_policy_id]
            )}
            refDate={values?.date}
          />
          <Box pl={6}>
            <Typography
              mb={1}
              mt={-2}
              fontSize={".85rem"}
              fontWeight={"500"}
              color={"text.secondary"}
            >
              Taxa paga por km:{" "}
              <Typography
                variant="inherit"
                color={"primary.main"}
                fontWeight={"600"}
                component={"span"}
                fontSize={".9rem"}
              >
                {currency} {formatAmount(rate)}
              </Typography>
            </Typography>
            <Typography variant="h5" fontWeight={amount ? "600" : "400"}>
              <Typography
                variant="inherit"
                fontSize={"1.2rem"}
                component={"span"}
                fontWeight={"500"}
              >
                Total =
              </Typography>{" "}
              {currency} {formatAmount(amount)}
            </Typography>
            <Typography mt={1} mb={2} variant="body2" color={"text.secondary"}>
              {values.refundable ? "Reeembolsável" : "Não reeembolsável"}
            </Typography>
          </Box>
          <DateInput
            value={values.date}
            error={dateInvalid}
            onChange={useCallback(
              (v) => onChangeValue("date", v),
              [values.date]
            )}
          />
          <ProjectInput
            value={values.project_id}
            date={values.date}
            onChange={useCallback(
              (v) => onChangeValue("project_id", v),
              [values.project_id]
            )}
          />
          <ObsInput
            size="small"
            value={values.notes}
            onChange={useCallback(
              (v) => onChangeValue("notes", v),
              [values.notes]
            )}
          />
          <RequestInput
            value={values.to_request}
            onChange={useCallback(
              (v) => onChangeValue("to_request", v),
              [values.to_request]
            )}
          />
        </Box>
      </Box>
      <Box
        position="relative"
        width="28.3em"
        height={"100%"}
        display="flex"
        flexDirection={"column"}
      >
        <ModalReceipts
          files={receipts}
          requiredReceipt={false}
          handleOnDrop={onDropReceipts}
          handleCropReceipt={onCropReceipt}
          removeFile={onRemoveReceipt}
        />
      </Box>
    </DialogContent>
  );
};

export default ManualModeContent;

import { AssignmentOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HttpClient } from "../../api/httpClient";
import { selectAccountCurrency } from "../../store/features/accountSlice";
import {
  removeOneApprovalRequest,
  selectApprovalRequestById,
} from "../../store/features/approvalRequestsSlice";
import { setError } from "../../store/features/base/errorBaseSlice";
import { openSnackbar } from "../../store/features/base/snackbarBaseSlice";
import {
  closeElement,
  selectPortalIsOpened,
  selectPortalUtil,
} from "../../store/features/portalSlice";
import formatAmount from "../../utils/formatAmount";
import RejectionReasonInput from "./components/RejectionReasonInput";

const RejectModal = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");

  const currency = useSelector((state) => selectAccountCurrency(state, true));

  const open = useSelector((state) =>
    selectPortalIsOpened(state, "rejectModal")
  );

  const requestId = useSelector((state) =>
    selectPortalUtil(state, "rejectModal", "requestId")
  );

  const rejectedExpenses = useSelector((state) =>
    selectPortalUtil(state, "rejectModal", "rejectedExpenses")
  );

  const onClose = useCallback(
    () => dispatch(closeElement("rejectModal")),
    [dispatch]
  );

  const request = useSelector((state) =>
    selectApprovalRequestById(state, requestId)
  );

  const isAdvance = useMemo(() => {
    return Boolean(request?.advance?.status === "pending");
  }, [request?.advance]);

  useEffect(() => {
    if (!open) {
      setComment("");
    }
  }, [open]);

  const handleReject = async () => {
    setLoading(true);
    try {
      await HttpClient.post(`/approvers/request/${requestId}/reject`, {
        comment,
        rejected_expenses: rejectedExpenses || [],
      });
      dispatch(
        openSnackbar({
          message: `${isAdvance ? "Adiantamento" : "Relatório"} rejeitado`,
        })
      );
      dispatch(removeOneApprovalRequest(requestId));
      navigate(`/approver?tab=${isAdvance ? "1" : "0"}`);
      onClose();
    } catch (error) {
      dispatch(setError({ title: "Erro ao rejeitar relatório", error }));
    } finally {
      setLoading(false);
    }
  };

  return (
    open && (
      <Dialog open={open} fullWidth maxWidth="xs">
        <DialogTitle fontWeight={"400"} fontSize={"1.1rem"}>
          <Box>
            <Typography mb={0.5} variant="body1" color={"text.secondary"}>
              Rejeitar {isAdvance ? "adiantamento" : "relatório"}
            </Typography>
            <Typography
              variant="inherit"
              fontWeight={"600"}
              fontSize={"1.3rem"}
            >
              {isAdvance
                ? `${currency} ${formatAmount(request?.advance?.amount)}`
                : request?.title}
            </Typography>
            {isAdvance && (
              <Typography
                mt={0.5}
                fontWeight={"500"}
                component={"div"}
                display={"flex"}
                alignItems={"center"}
              >
                <AssignmentOutlined
                  color="action"
                  sx={{ fontSize: "1rem", mr: 1 }}
                />
                {request?.title}
              </Typography>
            )}
          </Box>
        </DialogTitle>
        <DialogContent>
          <RejectionReasonInput value={comment} onChange={setComment} />
          {Boolean(rejectedExpenses) && !isAdvance && (
            <Typography
              my={1}
              fontWeight={"500"}
              variant="body2"
              color={"text.secondary"}
            >
              Você marcou {rejectedExpenses.length} despesa(s) como rejeitada(s)
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
          <Button
            disabled={loading}
            variant="contained"
            disableElevation
            color="error"
            onClick={handleReject}
          >
            {loading ? "Rejeitando..." : "Rejeitar"}
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

export default memo(RejectModal);

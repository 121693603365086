import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { HttpClient } from "../../../api/httpClient";
import { selectAccountCurrency } from "../../../store/features/accountSlice";
import { openSnackbar } from "../../../store/features/base/snackbarBaseSlice";
import { selectPaymentTypeById } from "../../../store/features/configs/paymentTypesSlice";
import { selectProjectById } from "../../../store/features/configs/projectsSlice";
import {
  expensesOfflineActions,
  selectExpenseById,
} from "../../../store/features/expensesSlice";
import {
  closeElement,
  selectPortalIsOpened,
  selectPortalUtil,
} from "../../../store/features/portalSlice";
import { getReceiptUrl } from "../../../utils/functions/receipts";
import Layout from "./Layout";
import EditMode from "./contents/edit/EditMode";
import ReceiptsContent from "./contents/receipts/ReceiptsContent";
import ViewMode from "./contents/view/ViewMode";

function reducer(state, action) {
  switch (action.type) {
    case "setEditMode":
      return { ...state, editMode: action.payload };
    case "setExpenseCopy":
      return { ...state, expenseCopy: action.payload };
    case "setExpenseCopyProp":
      return {
        ...state,
        expenseCopy: {
          ...state.expenseCopy,
          [action.payload.prop]: action.payload.value,
        },
      };
    case "setConversionProp":
      return {
        ...state,
        conversion: {
          ...state.conversion,
          [action.payload.prop]: action.payload.value,
        },
      };
    case "setChanged":
      return { ...state, changed: action.payload };
    case "setConversion":
      return { ...state, conversion: action.payload };
    case "setLoadingConversion":
      return { ...state, loadingConversion: action.payload };
    case "reset":
      return { ...state, expenseCopy: {}, changed: false, editMode: false };
    case "setLoading":
      return { ...state, loading: action.payload };
    default:
      throw new Error();
  }
}

function ModalExpenseView({ settings }) {
  const dispatch = useDispatch();
  const accountCurrency = useSelector(selectAccountCurrency);

  //portal data
  const open = useSelector((state) =>
    selectPortalIsOpened(state, "expenseView")
  );
  const expenseId = useSelector((state) =>
    selectPortalUtil(state, "expenseView", "expenseId")
  );
  const expenseObj = useSelector((state) =>
    selectPortalUtil(state, "expenseView", "expenseObj")
  );

  //original expense
  const expense = useSelector(
    (state) => expenseObj || selectExpenseById(state, expenseId)
  );

  //refs
  const copiedValuesRef = useRef(expense);
  const expensePolicyRef = useRef();

  //functions utils
  const onClose = useCallback(() => dispatch(closeElement("expenseView")), []);
  const showSnackbar = useCallback(
    (message) => dispatch(openSnackbar({ message })),
    []
  );
  const setExpense = useCallback(
    (data) => dispatch(expensesOfflineActions.setExpense(data)),
    []
  );

  //principal state
  const [state, dispatchState] = useReducer(reducer, {
    editMode: false,
    expenseCopy: expense || {},
    conversion: {},
    loadingConversion: false,
  });
  const { editMode, expenseCopy, conversion, loadingConversion } = state;

  //entities by data
  const paymentType = useSelector((s) =>
    selectPaymentTypeById(s, expenseCopy.payment_type)
  );
  const project = useSelector((s) =>
    selectProjectById(s, expenseCopy.project_id)
  );

  const expTypeInputRef = useRef(null);

  //modifying functions
  const setEditMode = useCallback((value) => {
    dispatchState({ type: "setEditMode", payload: value });
  }, []);
  const setExpenseCopy = useCallback((value) => {
    dispatchState({ type: "setExpenseCopy", payload: value });
  }, []);
  const setConversion = useCallback((value) => {
    dispatchState({ type: "setConversion", payload: value });
  }, []);
  const setConversionProp = useCallback((value) => {
    dispatchState({ type: "setConversionProp", payload: value });
  }, []);
  const setLoadingConversion = useCallback((value) => {
    dispatchState({ type: "setLoadingConversion", payload: value });
  }, []);
  const handleChangeExpenseCopy = useCallback((prop, v) => {
    dispatchState({
      type: "setExpenseCopyProp",
      payload: { prop, value: v },
    });
  }, []);
  const handleEdit = useCallback(() => {
    setEditMode(true);
  }, []);
  const handleCancelEdit = useCallback(() => {
    setEditMode(false);
  }, []);

  //expense copy state control effect
  useEffect(() => {
    if (!open) {
      setEditMode(false);
    }
    if (expense) {
      setExpenseCopy({ ...expense });
    }
  }, [open, expense, editMode]);

  //conversions
  const converted = useMemo(
    () => Boolean(open && expenseCopy.currency !== accountCurrency),
    [expenseCopy.currency, accountCurrency, open]
  );
  const getConversion = async () => {
    try {
      setLoadingConversion(true);

      const { data } = await HttpClient.get(`/currency/conversor`, {
        params: {
          from: expenseCopy.currency,
          to: accountCurrency,
          date: expenseCopy.date,
          amount: expenseCopy.amount,
        },
      });

      setConversion({
        calculated: data.calculated,
        factor: data.factor,
        from: data.from,
        to: accountCurrency,
        referenceDate: data.reference_date,
      });
      setLoadingConversion(false);
    } catch (error) {
      setConversion({});
    }
  };
  useEffect(() => {
    if (!open || !editMode) return;
    if (converted) {
      if (conversion.factor) {
        const { amount } = expenseCopy;
        const { factor } = conversion;
        setConversionProp({
          prop: "calculated",
          value: factor * parseFloat(amount),
        });
      } else {
        getConversion();
      }
    }
  }, [converted, expenseCopy.amount]);
  useEffect(() => {
    if (!open || !editMode) return;
    if (converted) {
      getConversion();
    }
  }, [expenseCopy.date, expenseCopy.currency, editMode]);

  //generate receipts
  const receipts = useMemo(() => {
    if (open && expense) {
      return expense.receipts.map((receipt) => ({
        ...receipt,
        uri: receipt.uri || getReceiptUrl(receipt.url),
      }));
    } else {
      return [];
    }
  }, [open, expense?.receipts]);

  //refundable control
  useEffect(() => {
    if (expenseCopy.payment_type && paymentType && open) {
      handleChangeExpenseCopy("refundable", Boolean(paymentType.is_refundable));
    }
  }, [expenseCopy.payment_type, paymentType]);

  const refundableReadOnly = useMemo(() => {
    if (!paymentType || !open) return false;
    if (paymentType.can_edit) {
      return false;
    } else {
      return true;
    }
  }, [paymentType]);

  //refs control
  useEffect(() => {
    copiedValuesRef.current = {
      ...expenseCopy,
      payment_type_name: paymentType?.name || "",
      project_name: project?.name || expenseCopy?.project_name || "",
    };
  }, [expenseCopy, paymentType, project]);

  //SAVE
  const handleSave = useCallback(
    async (event, isChecked) => {
      const data = { ...(copiedValuesRef?.current || {}) };

      if (!data?.type_id) {
        expTypeInputRef?.current?.focus();
        expTypeInputRef.current.placeholder = "Selecione uma categoria";
        return;
      }

      let ok = false;
      const { isOk, warnings, required_receipt } =
        expensePolicyRef.current?.check(
          {
            ...data,
            route_mode: undefined,
            distance: undefined,
            currency_t: accountCurrency,
          },
          !isChecked
        );
      data.warnings = warnings || [];
      data.required_receipt = Boolean(required_receipt);
      if (isChecked) {
        ok = true;
      } else {
        ok = isOk;
      }
      if (!ok) return;
      setExpense({
        id: expenseId,
        ...data,
      });
      handleCancelEdit();
      showSnackbar("Despesa salva");
      onClose();
    },
    [expenseId]
  );

  return (
    open &&
    expenseId && (
      <Layout
        expensePolicyRef={expensePolicyRef}
        showRequiredReceipt={!Boolean(receipts.length)}
        open={open}
        settings={settings}
        warnings={expense?.warnings || []}
        expenseId={expenseId}
        onClose={onClose}
        editMode={editMode}
        changed={true}
        onEdit={handleEdit}
        onCancelEdit={handleCancelEdit}
        onSave={handleSave}
        loading={false}
        receiptsContent={
          <ReceiptsContent
            settings={settings}
            expenseId={expenseId}
            receipts={receipts}
            requiredReceipt={expense?.required_receipt}
            duplicatedReceipts={expense?.duplicated_receipts}
          />
        }
        viewContent={<ViewMode expense={expense} />}
        editContent={
          <EditMode
            expense={expenseCopy}
            refundableReadOnly={refundableReadOnly}
            onChangeValue={handleChangeExpenseCopy}
            conversion={conversion}
            converted={converted}
            loadingConversion={loadingConversion}
            expTypeInputRef={expTypeInputRef}
            predefinedValues={{
              project_name: expense?.project_name,
            }}
          />
        }
      />
    )
  );
}

export default memo(ModalExpenseView);

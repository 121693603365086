import { Chip, Typography } from "@mui/material";
import React, { memo } from "react";

const CustomChip = memo(
  ({
    sx,
    label,
    severity,
    color = `white`,
    bgcolor = (t) => `${t.palette[severity].main}15`,
    fz = ".9rem",
  }) => {
    return (
      <Chip
        color={severity}
        variant="filled"
        sx={{
          px: 0,
          bgcolor,
          borderRadius: 1.5,
          // bgcolor,
          ...sx,
        }}
        size="small"
        label={
          <Typography fontWeight={"600"} color={color} fontSize={fz}>
            {label}
          </Typography>
        }
      />
    );
  }
);

function RenderChipStatus({ status, sx, fz = ".9rem" }) {
  if (status) {
    return status.toUpperCase() === "P" ? (
      <CustomChip
        sx={sx}
        fz={fz}
        label={"Aguardando aprovação"}
        color="warning.main"
        severity="warning"
      />
    ) : status.toUpperCase() === "AD" ? (
      <CustomChip
        sx={sx}
        fz={fz}
        label={"Adiantamento solicitado"}
        // color="warning.main"
        color="text.primary"
        // bgcolor="action.hover"
        severity="warning"
      />
    ) : status.toUpperCase() === "A" ? (
      <CustomChip
        sx={sx}
        fz={fz}
        label={"Aprovado"}
        color="success.main"
        severity="success"
      />
    ) : status.toUpperCase() === "R" ? (
      <CustomChip
        sx={sx}
        fz={fz}
        label={"Rejeitado"}
        color="error.main"
        severity="error"
      />
    ) : status.toUpperCase() === "O" ? (
      <CustomChip
        sx={sx}
        fz={fz}
        bgcolor="action.hover"
        label={"Em aberto"}
        severity="default"
        color="text.primary"
      />
    ) : status.toUpperCase() === "F" ? (
      <CustomChip
        sx={sx}
        fz={fz}
        label={"Compensado"}
        severity="primary"
        color="primary"
      />
    ) : null;
  }
}

export default memo(RenderChipStatus);

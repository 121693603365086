import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { Box, Chip, Typography } from "@mui/material";
import React from "react";

const FilterChip = ({
  onClick,
  color,
  label,
  isDropdown,
  filtered,
  filteredLabel,
  opened,
  Icon,
  sx,
  fontSx,
}) => {
  return (
    <Chip
      data-disableselect={true}
      color={!filtered ? color : "default"}
      clickable
      icon={
        Icon ? <Icon color={"primary"} fontSize='small' /> : null
      }
      sx={{
        pl: Icon && 1,
        pr: !isDropdown && Icon && 1,
        height: 36,
        borderRadius: 100,
        fontWeight: "600",
        transition: "none",
        fontSize: ".885rem",
        border: 1,
        bgcolor: (t) =>
          filtered ? `${t.palette.primary.main}20` : "transparent",
        borderColor: (t) =>
          t.palette.mode === "light"
            ? "rgba(0,0,0,.25)"
            : "rgba(255,255,255,.25)",
        ":hover": {
          boxShadow: 2,
          bgcolor: (t) =>
            filtered
              ? `${t.palette.primary.main}30`
              : `${t.palette.primary.main}15`,
        },
        ...sx,
      }}
      variant={"filled"}
      label={
        <Box display={"flex"} alignItems="center">
          <Typography
            color={filtered ? "primary" : "text.primary"}
            ml={Icon && 0.5}
            noWrap
            maxWidth={200}
            variant="inherit"
            sx={fontSx}
          >
            {filtered ? filteredLabel || label : label}
          </Typography>
          {isDropdown &&
            (opened ? (
              <ArrowDropUp
                color={filtered ? "primary" : "action"}
                sx={{ ml: .5, fontSize: '1.2rem' }}
              />
            ) : (
              <ArrowDropDown
                color={filtered ? "primary" : "action"}
                sx={{ ml: .5, fontSize: '1.2rem' }}
              />
            ))}
        </Box>
      }
      onClick={onClick}
    />
  );
};

export default FilterChip;

import { LinearProgress } from "@mui/material";
import { DirectionsRenderer } from "@react-google-maps/api";
import React, { memo } from "react";
import Map from "../../../../components/google-maps/Map";

function MapContent({ directionsResponse, loadingMap }) {
  return (
    <>
      <LinearProgress sx={{ visibility: loadingMap ? "visible" : "hidden" }} />
      <Map mapContainerStyle={{ flex: 1 }}>
        {directionsResponse && (
          <DirectionsRenderer directions={directionsResponse} />
        )}
      </Map>
    </>
  );
}

export default memo(MapContent);

import {
  Circle,
  ExpandLess,
  ExpandMore,
  RunningWithErrors
} from "@mui/icons-material";
import { Box, Collapse, IconButton, Stack, Typography } from "@mui/material";
import React, { memo, useState } from "react";
import PaperSection from "./PaperSection";

const LimitsAlertsSection = ({ alerts = [] }) => {
  const [expanded, setExpanded] = useState(true);

  if (Boolean(alerts?.length)) {
    return (
      <PaperSection
        title={
          <Typography component={"span"} variant="inherit" fontSize={"1.1rem"}>
            Limites ultrapassados ({alerts?.length})
          </Typography>
        }
        sx={{ bgcolor: (t) => `${t.palette.warning.main}10` }}
        Icon={(props) => <RunningWithErrors {...props} color="warning" />}
        rightContent={
          <>
            <IconButton sx={{ my: -1 }} onClick={() => setExpanded(!expanded)}>
              {!expanded ? (
                <ExpandMore sx={{ fontSize: "32px" }} />
              ) : (
                <ExpandLess sx={{ fontSize: "32px" }} />
              )}
            </IconButton>
          </>
        }
      >
        <Collapse in={expanded}>
          <Stack gap={2} p={1}>
            {alerts?.map((item, idx) => (
              <Box
                key={idx.toString()}
                display={"flex"}
                alignItems={"flex-start"}
                gap={1}
              >
                <Circle sx={{ fontSize: ".5rem", mt: 1 }} color="action" />

                <Typography
                  component={"div"}
                  fontWeight={"500"}
                  fontSize={".92rem"}
                  dangerouslySetInnerHTML={{
                    __html: item || "",
                  }}
                />
              </Box>
            ))}
          </Stack>
        </Collapse>
      </PaperSection>
    );
  } else return;
};

export default memo(LimitsAlertsSection);

import { DeleteOutlineOutlined } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalDelete from "../../../../../../modals/ModalDelete";
import { openSnackbar } from "../../../../../../store/features/base/snackbarBaseSlice";
import {
  expensesOfflineActions,
  selectExpensesSelected,
} from "../../../../../../store/features/expensesSlice";

function Remove({ clearSelection = () => {} }) {
  const dispatch = useDispatch();
  const selected = useSelector(selectExpensesSelected);
  const [open, setOpen] = useState(false);

  const handleDelete = async () => {
    dispatch(expensesOfflineActions.removeExpenses(selected));
    dispatch(
      openSnackbar({ message: `${selected.length} despesa(s) excluída(s)` })
    );
    clearSelection();
  };

  return (
    <>
      <Tooltip title="Excluir">
        <IconButton
          sx={{ bgcolor: open && "action.focus" }}
          onClick={() => setOpen(true)}
        >
          <DeleteOutlineOutlined />
        </IconButton>
      </Tooltip>
      <ModalDelete
        open={open}
        title={`Deseja excluir ${selected.length} despesa(s)?`}
        subtitle={"Esta ação não poderá ser desfeita"}
        onClose={() => setOpen(false)}
        onDelete={handleDelete}
      />
    </>
  );
}

export default Remove;

import { Box, CardActionArea, LinearProgress, Typography } from "@mui/material";
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import { selectExpenseById } from "../../../store/features/expensesSlice";
import { curr } from "../../../utils/more/currency_country";
import Base from "./base";
import AmountArea from "./components/AmountArea";
import BottomActions from "./components/BottomActions";
import CheckboxArea from "./components/CheckboxArea";
import ExpTypeArea from "./components/ExpTypeArea";
import LeftContent from "./components/left-content/LeftContent";
import ReceiptsCarousel from "./components/left-content/ReceiptsCarousel";
import RouteContent from "./components/left-content/RouteContent";

const mapId = "fceaa9766c12a837";

const ExpenseCardItem = forwardRef(
  (
    {
      expenseObj,
      expenseId,
      selected,
      enableSelectScrollIntoView,
      customActionComponent,
      onChangeSelected = () => {},
      onClick = () => {},
      rejected,
      disableActions,
      settings = {
        select: true,
        remove: true,
        addToRequest: true,
        removeFromRequest: true,
      },
    },
    ref
  ) => {
    const expenseFromStore = useSelector((state) =>
      selectExpenseById(state, expenseId)
    );
    const expense = expenseObj || expenseFromStore || {};

    const {
      date,
      refundable,
      notes,
      is_route,
      type_id,
      currency,
      account_currency,
      amount,
      amount_converted,
      receipts,
      route_mode,
      distance,
      waypoints,
      request_id,
      required_receipt,
      payment_type_name,
      from,
      warnings,
      to,

      //sync
      processingStatus,
      processingError,
    } = expense;

    const scanning = useMemo(
      () => processingStatus === "scanning",
      [processingStatus]
    );
    const sync = useMemo(() => processingStatus === "sync", [processingStatus]);

    const handleClickSelect = useCallback(() => {
      onChangeSelected(expenseId);
    }, [expenseId]);

    const handleClick = useCallback(
      (e) => {
        e.stopPropagation();
        onClick(expenseId, is_route, route_mode, expenseObj);
      },
      [expenseId, expenseObj, is_route, route_mode]
    );

    const renderReceiptsCarousel = useCallback(
      (hovered) => {
        return (
          <ReceiptsCarousel
            receipts={receipts || []}
            requiredReceipt={required_receipt}
            hovered={hovered}
            isRoute={is_route}
            routeMode={route_mode}
          />
        );
      },
      [required_receipt, receipts, is_route, route_mode]
    );

    const staticMapURL = useMemo(() => {
      if (from && to) {
        let markers = `${from}`;
        if (waypoints && waypoints.length) {
          markers = `${markers}|${waypoints.join("|")}`;
        }
        markers = `${markers}|${to}`;
        return `https://maps.googleapis.com/maps/api/staticmap?&markers=size:mid|color:red|${markers}&map_id=${mapId}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&size=160x220`;
      } else {
        return null;
      }
    }, [from, to, waypoints]);

    useEffect(() => {
      if (selected && enableSelectScrollIntoView) {
        const expenseElement = document.getElementById(`e-${expenseId}`);
        if (expenseElement) {
          expenseElement?.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    }, [selected, expenseId]);

    return (
      <Base
        innerRef={ref}
        rejected={rejected}
        expenseId={expenseId}
        disabled={scanning}
        onClick={handleClick}
        checkbox={
          settings.select &&
          !scanning && (
            <CheckboxArea
              selected={selected}
              onChangeSelected={handleClickSelect}
            />
          )
        }
        selected={selected}
        sync={sync}
        requestId={request_id}
        settings={{
          deleteExpense: settings.remove,
          removeFromRequest: settings.removeFromRequest,
          addToRequest: settings.addToRequest,
        }}
      >
        {!scanning && (
          <BottomActions
            error={processingError}
            expenseId={expenseId}
            addToRequest={settings.addToRequest}
            deleteExpense={settings.remove}
            removeFromRequest={settings.removeFromRequest}
            requestId={request_id}
            warnings={warnings}
            customActionComponent={customActionComponent}
            disableActions={disableActions}
          />
        )}
        <Box
          overflow={"hidden"}
          borderRadius={2.5}
          onClick={handleClick}
          component={CardActionArea}
          display={"flex"}
          zIndex={1}
          disabled={scanning}
          height={"100%"}
        >
          <LeftContent
            isRoute={is_route && route_mode === "map"}
            receipts={renderReceiptsCarousel}
            route={<RouteContent staticMapURL={staticMapURL} />}
          />
          <Box
            borderLeft={1}
            borderColor={"divider"}
            height={"100%"}
            px={2}
            pt={1}
            flex={1}
            flexBasis={1}
            display={"flex"}
            flexDirection={"column"}
            gap={0.5}
            overflow={"auto"}
            position={"relative"}
          >
            {!is_route && (
              <Box
                left={0}
                top={0}
                p={2}
                pt={1}
                position={"absolute"}
                width={"100%"}
                height={"100%"}
                bgcolor={"background.default"}
                zIndex={!scanning ? 0 : 20}
                sx={{ opacity: scanning ? 1 : 0, transition: ".2s ease" }}
              >
                <Typography mt={2} fontSize={"1.15rem"} fontWeight={"500"}>
                  Analisando comprovante
                </Typography>

                <LinearProgress
                  sx={{ mt: 1, borderRadius: 100, height: 2.5, width: "80%" }}
                />
                <Typography fontSize={".8rem"} color={"text.secondary"}>
                  Preparando sua despesa...
                </Typography>
              </Box>
            )}
            <AmountArea
              date={date}
              refundable={refundable}
              amount={parseFloat(amount || 0)}
              paymentType={payment_type_name}
              currency={curr(currency)}
              accountCurrency={curr(account_currency)}
              amountConverted={is_route ? null : parseFloat(amount_converted)}
            />
            <ExpTypeArea
              distance={distance || 0}
              routeMode={route_mode}
              isRoute={is_route}
              expTypeId={type_id}
              route={{ to, from, waypoints: waypoints || [] }}
              notes={notes}
              hasWarnings={Boolean(warnings?.length)}
            />
          </Box>
        </Box>
      </Base>
    );
  }
);

export default memo(
  ExpenseCardItem,
  (prev, next) =>
    prev.selected === next.selected &&
    prev.rejected === next.rejected &&
    prev.settings?.select === next.settings?.select
);

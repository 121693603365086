import { ArrowBack, CheckOutlined, CloseOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { selectAccountCurrency } from "../../../../store/features/accountSlice";
import formatAmount from "../../../../utils/formatAmount";

const Header = ({
  title,
  onReject,
  onApprove,
  isAdvance,
  advanceAmount = 0,
  selectedExpenseView = false,
}) => {
  const { state } = useLocation();

  const navigate = useNavigate();
  const currency = useSelector((state) => selectAccountCurrency(state, true));

  const scrolled = useScrollTrigger({
    target: undefined,
    disableHysteresis: true,
    threshold: 0,
  });

  return (
    <Box
      p={1}
      pl={2}
      pt={0.5}
      gap={1}
      pr={selectedExpenseView ? { xs: "28em", xl: "38em" } : 3}
      boxShadow={scrolled && 2}
      display={"flex"}
      alignItems={"center"}
      sx={{ transition: ".15s ease" }}
      bgcolor={"background.default"}
      borderBottom={1}
      position={"sticky"}
      top={63}
      borderColor={"divider"}
      zIndex={100}
    >
      <IconButton
        onClick={() =>
          navigate(state?.origin, {
            state: {
              lastVisitedRequestId: state?.lastVisitedRequestId,
            },
          })
        }
      >
        <ArrowBack sx={{ fontSize: "26px" }} />
      </IconButton>
      <Box ml={1}>
        {isAdvance && (
          <Typography
            noWrap
            fontSize={".9rem"}
            fontWeight={"500"}
            color={"text.secondary"}
          >
            Adiantamento
          </Typography>
        )}
        <Typography
          noWrap
          fontSize={"1.4rem"}
          fontWeight={"500"}
          color={"text.primary"}
        >
          <strong>
            {isAdvance ? `${currency} ${formatAmount(advanceAmount)} - ` : ""}{" "}
          </strong>
          {title}
        </Typography>
      </Box>
      <Box flex={1} />
      <Button
        startIcon={<CloseOutlined />}
        onClick={onReject}
        variant="outlined"
        disableElevation
        color="error"
        sx={{
          fontWeight: "600",
          borderRadius: 100,
          height: 34,
          bgcolor: (t) => `${t.palette.error.main}10`,
          borderColor: "divider",
        }}
      >
        Rejeitar
      </Button>
      <Button
        startIcon={<CheckOutlined />}
        onClick={onApprove}
        variant="outlined"
        disableElevation
        color="success"
        sx={{
          fontWeight: "600",
          borderRadius: 100,
          height: 34,
          bgcolor: (t) => `${t.palette.success.main}10`,
          borderColor: "divider",
        }}
      >
        Aprovar
      </Button>
    </Box>
  );
};

export default memo(Header);

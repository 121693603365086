import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAccountCurrency } from "../../../store/features/accountSlice";
import { openSnackbar } from "../../../store/features/base/snackbarBaseSlice";
import { selectProjectById } from "../../../store/features/configs/projectsSlice";
import { selectRoutePoliciesEntities } from "../../../store/features/configs/routePoliciesSlice";
import {
  expensesOfflineActions,
  selectExpenseById,
} from "../../../store/features/expensesSlice";
import {
  closeElement,
  selectPortalIsOpened,
  selectPortalUtil,
} from "../../../store/features/portalSlice";
import formatAmountStringToNumberCopy from "../../../utils/formatAmountStringToNumber copy";
import getRateByDate from "../../../utils/getRateByDate";
import { newRouteInitialState } from "../../../utils/initialStates";
import { calculateRoute } from "../../expense-creation/new-route/functions";
import Layout from "./Layout";
import MapContent from "./contents/MapContent";
import EditContent from "./contents/edit/EditContent";
import ViewContent from "./contents/view/ViewContent";

function ModalMapRoute({ settings }) {
  const dispatch = useDispatch();

  const accountCurrency = useSelector(selectAccountCurrency);

  //portal data
  const open = useSelector((state) => selectPortalIsOpened(state, "routeView"));
  const expenseId = useSelector((state) =>
    selectPortalUtil(state, "routeView", "expenseId")
  );
  const expenseObj = useSelector((state) =>
    selectPortalUtil(state, "routeView", "expenseObj")
  );

  //original expense data
  const expense = useSelector(
    (state) => expenseObj || selectExpenseById(state, expenseId)
  );

  //functions utils
  const onClose = useCallback(() => dispatch(closeElement("routeView")), []);
  const showSnackbar = useCallback(
    (message) => dispatch(openSnackbar({ message })),
    []
  );
  const setExpense = useCallback(
    (data) => dispatch(expensesOfflineActions.setExpense(data)),
    []
  );

  //states
  const [calculating, setCalculating] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [expenseCopy, setExpenseCopy] = useState(expense || {});
  const [changed, setChanged] = useState(false);
  const [directionsResponse, setDirectionsResponse] = useState(null);

  //entities
  const routePoliciesEntities = useSelector(selectRoutePoliciesEntities);

  //refs
  const copiedValuesRef = useRef(expense);
  const expensePolicyRef = useRef();

  //entities by data
  const project = useSelector((s) =>
    selectProjectById(s, expenseCopy.project_id)
  );

  //copy data control
  useEffect(() => {
    if (!open) {
      setEditMode(false);
    }
    if (expense) {
      setExpenseCopy({ ...expense });
    }
  }, [open, editMode, expense]);

  //state functions
  const handleOnEdit = useCallback(() => {
    setEditMode(true);
  }, []);
  const handleCancelEdit = useCallback(() => {
    setEditMode(false);
    setChanged(false);
  }, []);
  const handleChangeEditValue = useCallback((prop, v) => {
    setExpenseCopy((prev) => ({
      ...prev,
      [prop]: v,
    }));
    setChanged(true);
  }, []);

  //route effects
  const handleCalculatedRoute = useCallback(async () => {
    setCalculating(true);
    const res = await calculateRoute([
      expenseCopy.from,
      ...(expenseCopy.waypoints || []),
      expenseCopy.to,
    ]);
    if (!res) {
      setCalculating(false);
      return;
    }
    setDirectionsResponse(res.results);
    if (expense?.distance?.toString() !== res.distance?.toString()) {
      handleChangeEditValue("distance", res.distance);
    }
    setCalculating(false);
  }, [editMode, expenseCopy.from, expenseCopy.waypoints, expenseCopy.to]);
  useEffect(() => {
    if (open) {
      handleCalculatedRoute();
    }
  }, [
    open,
    expenseCopy.to,
    expenseCopy.from,
    expenseCopy.distance,
    expenseCopy.waypoints,
  ]);
  const handleChangeRoutes = useCallback((newRoutes = []) => {
    const waypoints = newRoutes.slice(1, -1);
    setExpenseCopy((prev) => ({
      ...prev,
      from: newRoutes[0],
      to: newRoutes[newRoutes.length - 1],
      waypoints,
    }));
    if (!changed) {
      setChanged(true);
    }
  }, []);

  const reoderRoutes = useCallback(
    (startIndex, endIndex) => {
      const result = Array.from([
        expenseCopy.from,
        ...(expenseCopy.waypoints || []),
        expenseCopy.to,
      ]);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      handleChangeRoutes(result);
    },
    [expenseCopy]
  );

  // rate / policies / amount /refundable

  const routePolicyIsDetached = useMemo(
    () =>
      Boolean(
        expenseCopy?.route_policy_id === expense?.route_policy_id &&
          !routePoliciesEntities[expenseCopy.route_policy_id]
      ),
    [
      expenseCopy?.route_policy_id,
      routePoliciesEntities,
      expense?.route_policy_id,
    ]
  );

  const currentRate = useMemo(() => {
    if (!open) return 0;
    if (routePolicyIsDetached) {
      return expense?.rate;
    } else {
      return getRateByDate(
        routePoliciesEntities[expenseCopy.route_policy_id]?.history || [],
        new Date(expenseCopy.date)
      );
    }
  }, [
    expense?.rate,
    routePolicyIsDetached,
    expenseCopy.route_policy_id,
    routePoliciesEntities,
    expenseCopy.date,
    open,
  ]);
  const amount = useMemo(() => {
    if (!directionsResponse || !open) return 0;
    const distanceValue = formatAmountStringToNumberCopy(expenseCopy.distance);
    return distanceValue * parseFloat(currentRate);
  }, [expenseCopy.distance, currentRate, directionsResponse, open]);

  const refundable = useMemo(() => {
    if (!open) return newRouteInitialState.refundable;
    return Boolean(
      routePoliciesEntities[expenseCopy.route_policy_id]?.is_refundable
    );
  }, [expenseCopy.route_policy_id, routePoliciesEntities, open]);

  //refs control
  useEffect(() => {
    copiedValuesRef.current = {
      ...expenseCopy,
      amount,
      project_name: project?.name || expenseCopy?.project_name || "",
      rate: currentRate,
      route_policy_name:
        routePoliciesEntities[expenseCopy.route_policy_id]?.name ||
        expenseCopy?.route_policy_name ||
        "",
    };
  }, [expenseCopy, project, routePoliciesEntities, currentRate, amount]);

  //SAVE
  const handleSave = useCallback(
    async (event, isChecked) => {
      const data = { ...(copiedValuesRef?.current || {}) };
      let ok = false;
      const { isOk, warnings, required_receipt } =
        expensePolicyRef.current?.check(
          {
            ...data,
            route_mode: "map",
            currency_t: accountCurrency,
          },
          !isChecked
        );
      data.warnings = warnings || [];
      data.required_receipt = false;
      if (isChecked) {
        ok = true;
      } else {
        ok = isOk;
      }
      if (!ok) return;
      setExpense({ id: expenseId, ...data });
      handleCancelEdit();
      handleCalculatedRoute();
      showSnackbar("Despesa salva");
      onClose();
    },
    [expenseId]
  );

  return (
    open &&
    expenseId && (
      <Layout
        open={open}
        settings={settings}
        warnings={expense?.warnings || []}
        expensePolicyRef={expensePolicyRef}
        expenseId={expenseId}
        onClose={onClose}
        changed={!calculating}
        loading={false}
        mapContent={
          <MapContent
            directionsResponse={directionsResponse}
            loadingMap={calculating}
          />
        }
        editMode={editMode}
        onEdit={handleOnEdit}
        onCancelEdit={handleCancelEdit}
        editContent={
          <EditContent
            amount={amount}
            canEditAmount={false}
            expense={{ ...expenseCopy, refundable }}
            currentRate={currentRate}
            onCalculateRoutes={handleChangeRoutes}
            reoderRoutes={reoderRoutes}
            onChangeValue={handleChangeEditValue}
            dateInvalid={false}
            routePolicyIsDetached={routePolicyIsDetached}
            predefinedValues={{
              route_policy_name: expense?.route_policy_name,
              project_name: expense?.project_name,
            }}
          />
        }
        viewContent={<ViewContent rate={currentRate} expense={expense} />}
        onSave={handleSave}
      />
    )
  );
}

export default memo(ModalMapRoute);

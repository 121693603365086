import { Menu } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import Drawer from "../../drawer/Drawer";

const DrawerButton = () => {
  const [opened, setOpened] = useState(false);
  return (
    <>
      <IconButton sx={{mr: 1, ml: -2}} onClick={() => setOpened(true)}>
        <Menu />
      </IconButton>
      <Drawer open={opened} onClose={() => setOpened(false)} />
    </>
  );
};

export default DrawerButton;

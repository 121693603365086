import { CategoryOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import {
  PieChart,
  cheerfulFiestaPalette,
  pieArcLabelClasses,
} from "@mui/x-charts";
import React, { memo, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import LoadingSection from "../../../../components/loading/LoadingSection";
import { selectAccountCurrency } from "../../../../store/features/accountSlice";
import { selectLast3MonthsTotalsByCategory } from "../../../../store/features/totalsSummarySlice";
import formatAmount from "../../../../utils/formatAmount";

const TopCategoriesPieChart = () => {
  const currency = useSelector((state) => selectAccountCurrency(state, true));

  const totals = useSelector(selectLast3MonthsTotalsByCategory);

  const { top5, working } = totals || { top5: [], working: true };

  const total = useMemo(
    () => top5?.reduce((ac, item) => ac + parseFloat(item?.total || 0), 0),
    [top5]
  );

  const formattedTop5 = useMemo(
    () =>
      top5?.map((t, index) => ({
        id: `${t?.category}-${index}`,
        value: t?.total,
        label: t?.category,
      })),
    [top5]
  );

  const arcLabel = useCallback(
    (v) => `${((v?.value / total) * 100)?.toFixed(0)} %`,
    [total]
  );

  if (working) {
    return <LoadingSection h={250} />;
  } else if (!top5?.length) {
    return (
      <Box
        height={150}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        p={2}
        pb={3}
      >
        <CategoryOutlined
          color="disabled"
          fontSize="large"
          sx={{ mb: 1, fontSize: "3.3rem" }}
        />
        <Typography textAlign={"center"} color={"text.secondary"}>
          Você ainda não criou despesas
        </Typography>
      </Box>
    );
  } else {
    return (
      <PieChart
        series={[
          {
            arcLabel,
            data: formattedTop5,
            innerRadius: 20,
            paddingAngle: 2,
            outerRadius: 100,
            cx: 120,
            highlightScope: { faded: "global", highlighted: "item" },
            valueFormatter: (v) => `${currency} ${formatAmount(v?.value)}`,
          },
        ]}
        height={250}
        width={450}
        colors={cheerfulFiestaPalette}
        sx={{
          [`& .${pieArcLabelClasses.root}`]: {
            fill: "white",
            fontWeight: "bold",
          },
        }}
      />
    );
  }
};

export default memo(TopCategoriesPieChart);

import { Box } from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import RequestNotFound from "../../../components/RequestNotFound";
import ErrorBoundary from "../../../errors/Boundary";
import { setBreadcrumbs } from "../../../store/features/base/breadcrumbsSlice";
import {
  selectExpensesByRequest,
  selectExpensesNumByRequest,
  selectExpensesTotalAmountByRequest,
  selectExpensesTotalRefundableByRequest,
  selectHasExpensesWithRequiredReceipt,
  syncExpenses,
} from "../../../store/features/expensesSlice";
import {
  fetchRequestDetails,
  selectRequestById,
  selectRequestLimitsAlerts,
} from "../../../store/features/requestsSlice";
import RequestHeader from "./components/RequestHeader";
import ExpensesContent from "./contents/expenses/ExpensesContent";
import RejectionBar from "./contents/expenses/RejectionBar";
import Overview from "./contents/overview/Overview";
import LimitsAlertsSection from "./contents/overview/sections/LimitsAlertsSection";

function RequestView() {
  const navigate = useNavigate();
  const { requestId } = useParams();

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const tab = useMemo(
    () => (searchParams?.get("tab") || 0).toString(),
    [searchParams]
  );

  //Capturar a entidade deste relatório
  const requestData = useSelector((state) =>
    selectRequestById(state, requestId)
  );

  //Capturar as despesas deste relatório
  const expenses = useSelector(
    (state) => selectExpensesByRequest(state, requestId) || []
  );

  //Valores totais olhando para as despesas editáveis do relatório
  const expensesNumByState = useSelector((state) =>
    selectExpensesNumByRequest(state, requestId)
  );
  const expensesTotalByState = useSelector((state) =>
    selectExpensesTotalAmountByRequest(state, requestId)
  );
  const expensesTotalRefundableByState = useSelector((state) =>
    selectExpensesTotalRefundableByRequest(state, requestId)
  );
  const limitsAlerts = useSelector((state) =>
    selectRequestLimitsAlerts(state, requestId, expenses)
  );

  //Extrair constantes úteis
  const fetching = useMemo(() => {
    return Boolean(requestData?.fetching);
  }, [requestData?.fetching]);

  const status = useMemo(() => {
    return requestData?.status || "";
  }, [requestData?.status]);

  const isEditable = useMemo(() => {
    return Boolean(status === "O");
  }, [status]);

  const hasExpensesRequiredReceipts = useSelector((state) =>
    selectHasExpensesWithRequiredReceipt(state, requestId)
  );

  //Verificar status principal e chamar a configuração inicial "fetchRequestDetails"
  const fetcher = useCallback(() => {
    dispatch(fetchRequestDetails(requestId));
  }, [requestId]);

  useEffect(() => {
    fetcher();
  }, [requestId]);

  useEffect(() => {
    if (["P", "A", "R", "F"].includes(status) && !expenses?.length) {
      fetcher();
    }
  }, [status, requestId]);

  //Função de atualizar relatório manualmente
  const handleRefresh = useCallback(() => {
    fetcher();
    if (isEditable) {
      dispatch(syncExpenses());
    }
  }, [isEditable, fetcher]);

  //Formatar um resumo de acordo com as despesas
  const resume = useMemo(() => {
    let obj = {
      types: {},
      route: {
        total: 0,
        distance: 0,
        amount: 0,
      },
    };
    if (expenses && expenses.length) {
      for (let index = 0; index < expenses.length; index++) {
        const expense = expenses[index];
        if (expense.is_route) {
          obj.route.amount =
            parseFloat(obj.route.amount || 0) +
            parseFloat(expense.amount_converted || expense.amount || 0);
          obj.route.total += 1;
          obj.route.distance += parseFloat(expense.distance || 0);
        } else {
          if (expense.type_id) {
            if (obj.types[expense.type_id]) {
              obj.types[expense.type_id].amount =
                parseFloat(obj.types[expense.type_id].amount || 0) +
                parseFloat(expense.amount_converted || expense.amount || 0);
              obj.types[expense.type_id].total += 1;
            } else {
              obj.types[expense.type_id] = {
                amount: parseFloat(
                  expense.amount_converted || expense.amount || 0
                ),
                total: 1,
              };
            }
          }
        }
      }
    }
    return obj;
  }, [expenses, requestId]);

  //Formatar o objeto do relatório para saber quais valores mostrar, de acordo com o status.
  const requestTotals = useMemo(() => {
    if (isEditable || status === "AD") {
      return {
        total_amount: expensesTotalByState,
        total_refundable: expensesTotalRefundableByState,
        total_expenses: expensesNumByState,
      };
    } else {
      return {
        total_amount: requestData?.total_amount,
        total_refundable: requestData?.total_refundable,
        total_expenses: requestData?.total_expenses,
      };
    }
  }, [
    status,
    isEditable,
    expensesNumByState,
    expensesTotalRefundableByState,
    expensesTotalByState,
    requestData?.total_expenses,
    requestData?.total_refundable,
    requestData?.total_amount,
    requestId,
  ]);

  const stepInfo = useMemo(() => {
    if (requestData && requestData.step_info) {
      return {
        ...requestData.step_info,
        current_step: requestData.current_step,
      };
    } else {
      return null;
    }
  }, [requestData?.step_info, requestData?.current_step, requestId]);

  useEffect(() => {
    window.scroll({ top: 0, left: 0 });
  }, [requestId]);

  useEffect(() => {
    if (requestData?.title) {
      dispatch(setBreadcrumbs(["Relatórios de despesas", requestData?.title]));
    }
  }, [requestData?.title, requestId]);

  const handleChangeTab = useCallback(
    (newTab) => {
      searchParams.set("tab", newTab);
      setSearchParams(searchParams);
    },
    [searchParams, requestId]
  );

  if (!requestId || !requestData) {
    return (
      <RequestNotFound
        buttonAction={() => navigate("/approval-requests", { replace: true })}
      />
    );
  } else {
    return (
      <ErrorBoundary>
        <Box flex={1} display={"flex"} flexDirection={"column"}>
          <RequestHeader
            advanced={
              requestData?.advance?.status === "paid" ||
              requestData?.advance?.status === "approved"
            }
            title={requestData?.title}
            obs={requestData?.obs || ""}
            status={status}
            requestId={requestId}
            tab={tab}
            onChangeTab={handleChangeTab}
            hasExpensesRequiredReceipts={hasExpensesRequiredReceipts}
            hasExpensesInRequest={Boolean(expenses.length)}
            onRefresh={handleRefresh}
            loading={fetching && !isEditable}
            totalExpenses={requestTotals?.total_expenses || 0}
            hasLimitsAlerts={Boolean(limitsAlerts?.length)}
            limitBlockSendToApproval={
              Boolean(limitsAlerts?.length) &&
              !Boolean(requestData?.limits?.canSendToApproval)
            }
          />
          {tab === "0" && (
            <Overview
              requestId={requestId}
              loading={fetching && !isEditable}
              status={status}
              events={requestData?.events || []}
              stepInfo={stepInfo}
              advance={requestData?.advance || null}
              resume={resume}
              obs={requestData?.obs}
              title={requestData?.title}
              rejectedNum={(requestData?.rejected_expenses || [])?.length}
              totalAmount={requestTotals?.total_amount}
              totalRefundable={requestTotals?.total_refundable}
              totalExpenses={requestTotals?.total_expenses}
              customFields={requestData?.custom_fields}
              limits={requestData?.limits}
              alertLimitsComponent={
                <LimitsAlertsSection alerts={limitsAlerts} />
              }
            />
          )}
          {tab === "1" && (
            <>
              {status === "R" && (
                <RejectionBar events={requestData?.events || []} />
              )}
              <ExpensesContent
                expenses={expenses}
                isEditable={isEditable}
                rejectedExpenses={requestData?.rejected_expenses || []}
                status={status}
                loading={fetching && !isEditable}
                totalAmount={requestTotals?.total_amount}
                totalRefundable={requestTotals?.total_refundable}
              />
            </>
          )}
        </Box>
      </ErrorBoundary>
    );
  }
}

export default RequestView;

import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
} from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import React, { memo, useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { setError } from "../../../../../store/features/base/errorBaseSlice";
import { openSnackbar } from "../../../../../store/features/base/snackbarBaseSlice";
import {
  expensesOfflineActions,
  selectExpenseById,
} from "../../../../../store/features/expensesSlice";
import { getBlob } from "../../../../../utils/more/images_transform";
import ModalReceipts from "../../../../expense-creation/new-expense/components/ModalReceipts";

function UploadModal({ open, onClose, expenseId, onAdd }) {
  const dispatch = useDispatch();
  const [receipts, setReceipts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!open) {
      setReceipts([]);
    }
  }, [open]);

  const handleOnDropReceipts = useCallback(async (acceptedFiles) => {
    let prepared = [];
    for (let index = 0; index < acceptedFiles.length; index++) {
      const file = acceptedFiles[index];
      const blob = await getBlob(file);
      const receiptId = nanoid(5);
      try {
        const data = {
          id: receiptId,
          filename: file.path,
          uri: URL.createObjectURL(file),
          type: file.type,
          blob: new Blob([blob], { type: file.type }),
        };
        prepared.push(data);
      } catch (error) {
        dispatch(setError({ title: `Falha ao carregar arquivos` }));
      }
    }
    if (prepared && prepared.length) {
      setReceipts((prev) => {
        return [...prepared, ...prev];
      });
    }
  }, []);

  const handleCropReceipt = useCallback(
    (fileId, blob, previewUrl, origin, revert) => {
      const selectedFile = receipts.find((item) => item.id === fileId);
      if (!selectedFile) return;
      selectedFile.blob = blob;
      selectedFile.uri = previewUrl;
      if (revert) {
        delete selectedFile.origin;
      } else if (origin) {
        selectedFile.origin = origin;
      }
      setReceipts((prev) => {
        prev.splice(
          prev.findIndex((item) => item.id === fileId),
          1,
          selectedFile
        );
        return [...prev];
      });
    },
    [receipts]
  );

  const handleRemoveReceipt = useCallback((id) => {
    setReceipts((prev) => {
      const index = prev.findIndex((receipt) => receipt.id === id);
      if (index !== -1) {
        prev.splice(index, 1);
      }
      return [...prev];
    });
  }, []);

  const handleAdd = async () => {
    dispatch(expensesOfflineActions.uploadReceipts(expenseId, receipts, false));
    dispatch(openSnackbar({ message: "Comprovante(s) adicionado(s)" }));
    onAdd(0);
    onClose();
  };

  return (
    <Dialog
      PaperProps={{ sx: { height: "100%" } }}
      maxWidth="sm"
      fullWidth
      open={open}
    >
      {loading && <LinearProgress />}
      <DialogTitle component={"div"} height={50}>
        Adicionar comprovantes
        <Box flex={1} />
        <IconButton onClick={onClose} sx={{ m: -1 }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 0 }} dividers>
        <ModalReceipts
          files={receipts}
          handleOnDrop={handleOnDropReceipts}
          handleCropReceipt={handleCropReceipt}
          removeFile={handleRemoveReceipt}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          disabled={loading || !receipts.length}
          onClick={handleAdd}
          variant="contained"
          disableElevation
        >
          Adicionar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { receipts } = selectExpenseById(state, ownProps.expenseId);

  return {
    defaultReceipts: [...receipts],
    ...ownProps,
  };
};

export default connect(mapStateToProps)(memo(UploadModal));

import { ReceiptLongOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import NoDataContent from "../../components/form/NoDataContent";
import ErrorBoundary from "../../errors/Boundary";
import "../../main.css";
import ModalExpenseView from "../../modals/expense-view/expense/ModalExpenseView";
import ModalManualRouteView from "../../modals/expense-view/manual-route/ModalManualRouteView";
import ModalMapRouteView from "../../modals/expense-view/map-route/ModalMapRouteView";
import { setBreadcrumbs } from "../../store/features/base/breadcrumbsSlice";
import {
  clearExpensesFilters,
  onChangeExpensesSelection,
  removeExpenseFilter,
  selectExpensesIdsByFilters,
  selectExpensesScanningTotal,
  selectExpensesSelected,
  selectSingleExpensesIds,
  setExpensesFilter,
} from "../../store/features/expensesSlice";
import { openElement } from "../../store/features/portalSlice";
import { isSelected } from "../../utils/functions/onSelect";
import NoExpensesContent from "./NoExpensesContent";
import ExpensesRenderer from "./components/ExpensesRenderer";
import ExpensesDragSelection from "./components/v2/ExpensesDragSelection";
import Toolbar from "./components/v2/toolbar/Toolbar";

const expensesViewSettings = {
  edit: true,
  remove: true,
  addToRequest: true,
  removeFromRequest: false,
};

const expensesCardItemSettings = {
  select: true,
  remove: true,
  addToRequest: true,
  removeFromRequest: false,
};

function OpenedExpenses({
  openExpenseView,
  setFilter,
  removeFilter,
  clearFilters,
  onChangeExpensesSelection,
}) {
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);

  const selected = useSelector(selectExpensesSelected);

  const expensesIds = useSelector(selectSingleExpensesIds);

  const filteredExpensesIds = useSelector(selectExpensesIdsByFilters);

  const scanningTotal = useSelector(selectExpensesScanningTotal);

  const expensesCount = useMemo(
    () => filteredExpensesIds.length - scanningTotal,
    [scanningTotal, filteredExpensesIds?.length]
  );

  const handleSelect = useCallback((expenseId) => {
    onChangeExpensesSelection("toggle", expenseId);
  }, []);

  const handleSelectAllClick = useCallback(
    (event) => {
      if (event.target.checked) {
        onChangeExpensesSelection("select-all", [...filteredExpensesIds]);
        return;
      }
      onChangeExpensesSelection("clear");
    },
    [filteredExpensesIds]
  );

  const handleChangeFilter = useCallback(
    (prop, value) => {
      setFilter(prop, value);
    },
    [setFilter]
  );

  const handleRemoveFilter = useCallback(
    (filter) => {
      removeFilter(filter);
    },
    [removeFilter]
  );

  const handleClearAllFilters = useCallback(() => {
    clearFilters();
  }, [clearFilters]);

  const handleClearSelection = useCallback(() => {
    onChangeExpensesSelection("clear");
  }, []);

  useEffect(() => {
    dispatch(setBreadcrumbs(["Despesas avulsas"]));
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  useEffect(() => {
    if (mounted && selected.length) {
      handleClearSelection();
    }
  }, [filteredExpensesIds.length, expensesIds.length]);

  const handleChangeDragExpensesSelection = useCallback((data) => {
    onChangeExpensesSelection(data.type, data.payload);
  }, []);

  const expenseIsSelected = useCallback(
    (expenseId) => isSelected(expenseId, selected),
    [selected]
  );

  return (
    <ErrorBoundary>
      {!Boolean(expensesIds.length) ? (
        <NoExpensesContent />
      ) : (
        <>
          <Box
            id="expenses"
            data-disableselect={false}
            position={"relative"}
            onClick={(e) => window.getSelection().removeAllRanges()}
            pb={15}
            pt={"65px"}
            flex={1}
          >
            <Toolbar
              selectionMode={Boolean(selected.length)}
              selectedNum={selected.length}
              expensesCount={expensesCount}
              onClearAllFilters={handleClearAllFilters}
              onChangeFilter={handleChangeFilter}
              onRemoveFilter={handleRemoveFilter}
              onClearSelection={handleClearSelection}
              onSelectAllClick={handleSelectAllClick}
            />
            {!Boolean(filteredExpensesIds.length) ? (
              <NoDataContent
                Icon={ReceiptLongOutlined}
                iconSize="7rem"
                title={"Nenhuma despesa avulsa encontrada"}
              />
            ) : (
              <ExpensesRenderer
                byDataSection
                style={{ paddingTop: "10px" }}
                onExpenseClick={openExpenseView}
                onExpenseSelect={handleSelect}
                isSelected={expenseIsSelected}
                expenseSettings={expensesCardItemSettings}
                expensesIds={filteredExpensesIds}
              />
            )}
            <ExpensesDragSelection
              onChangeSelection={handleChangeDragExpensesSelection}
              expensesIds={filteredExpensesIds}
            />
          </Box>

          {/* view */}
          <ModalExpenseView settings={expensesViewSettings} />
          <ModalMapRouteView settings={expensesViewSettings} />
          <ModalManualRouteView settings={expensesViewSettings} />
        </>
      )}
    </ErrorBoundary>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeExpensesSelection: (type, payload) =>
      dispatch(onChangeExpensesSelection({ type, payload })),
    setFilter: (filter, value) =>
      dispatch(setExpensesFilter({ filter, value })),
    removeFilter: (filter) => dispatch(removeExpenseFilter(filter)),
    clearFilters: () => dispatch(clearExpensesFilters()),
    openExpenseView: (expenseId, isRoute, routeMode) =>
      dispatch(
        openElement({
          name: isRoute
            ? routeMode === "manual"
              ? "manualRouteView"
              : "routeView"
            : "expenseView",
          util: { expenseId },
        })
      ),
  };
};

export default connect(null, mapDispatchToProps)(OpenedExpenses);

import {
  BusinessOutlined,
  Circle,
  DocumentScannerOutlined,
  EmailOutlined,
} from "@mui/icons-material";
import { Avatar, Box, Typography } from "@mui/material";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsAdmin,
  selectIsApprover,
  selectIsMaster,
} from "../../../../store/features/accountSlice";
import { openElement } from "../../../../store/features/portalSlice";
import { getUsernameInitials } from "../../../../utils/formaters";
import getAccountLogoUrl from "../../../../utils/functions/getAccountLogoUrl";
import { getProfilePicture } from "../../../../utils/functions/profile";
import ActionButton from "./components/ActionButton";
import CreateAction from "./components/CreateAction";

export default memo((props) => {
  const dispatch = useDispatch();

  const accountId = useSelector((state) => state.account.accountId);
  const user = useSelector((state) => state.account.baseInfo);

  const hasLogo = useSelector((state) => state.account.accountHasLogo);
  const logoURL = getAccountLogoUrl(accountId);

  const isMaster = useSelector(selectIsMaster);
  const isApprover = useSelector(selectIsApprover);
  const isAdm = useSelector(selectIsAdmin);

  return (
    <Box
      boxShadow={"inset 0px 0px 5px rgba(0,0,0,.15)"}
      bgcolor={"elevation1.main"}
      position={"relative"}
      height={{ xs: 80, md: 125 }}
      mt={1}
      borderRadius={2}
    >
      <Box
        display={"flex"}
        alignItems={"flex-end"}
        position={"absolute"}
        bottom={-15}
        left={0}
        px={3}
        width={"100%"}
      >
        <Box flex={1} display={"flex"} alignItems={"center"}>
          <Avatar
            src={getProfilePicture(user?.userId, user?.photoVersion)}
            sx={{
              width: { xs: 60, md: 100 },
              height: { xs: 60, md: 100 },
              boxShadow: 1,
              fontSize: "2.5rem",
              fontWeight: "600",
            }}
          >
            {getUsernameInitials(user?.name, user?.lastname)}
          </Avatar>
          <Box ml={2} pb={{ xs: 3, md: 4 }}>
            <Typography
              noWrap
              fontSize={{ xs: "1.3rem", md: "1.9rem" }}
              fontWeight={"500"}
              variant="h4"
            >
              {user?.name} {user?.lastname}
            </Typography>
            <Typography
              mt={0.8}
              noWrap
              component={"div"}
              display={{ xs: "none", md: "flex" }}
              alignItems={"center"}
              flexWrap={"wrap"}
              gap={1}
              fontSize={".95rem"}
              sx={{ opacity: 0.8 }}
            >
              <EmailOutlined sx={{ fontSize: "1rem" }} color="action" />
              {user?.email}
              <Circle sx={{ fontSize: ".3rem" }} color="disabled" />
              {hasLogo ? (
                <Box
                  borderRadius={1}
                  overflow={"hidden"}
                  border={1}
                  borderColor={"divider"}
                  width={52}
                  height={26}
                >
                  <img src={logoURL} width={"100%"} height={"100%"} />
                </Box>
              ) : (
                <BusinessOutlined sx={{ fontSize: "1rem" }} color="action" />
              )}
              {user?.companyName}
            </Typography>
            <Typography
              mt={0.8}
              component={"div"}
              display={{ md: "none", xs: "flex" }}
              alignItems={"center"}
              flexWrap={"wrap"}
              gap={1}
              fontSize={".95rem"}
              sx={{ opacity: 0.8 }}
            >
              <EmailOutlined sx={{ fontSize: "1rem" }} color="action" />
              {user?.email}
            </Typography>
          </Box>
          <Box flex={1} />
          <Box
            display={{ xs: "none", md: "block" }}
            position={"absolute"}
            right={20}
            bottom={-5}
          >
            <Box gap={1} display={"flex"} alignItems={"center"}>
              <ActionButton
                onClick={() => dispatch(openElement({ name: "scanExpenses" }))}
                startIcon={<DocumentScannerOutlined />}
              >
                Escanear comprovantes
              </ActionButton>
              <CreateAction />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

import { Add } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { openElement } from "../../../store/features/portalSlice";

const RequestsActions = () => {
  const dispatch = useDispatch();
  return (
    <>
      <Button
        onClick={() => dispatch(openElement({ name: "newRequest" }))}
        variant="outlined"
        size="small"
        sx={{ borderRadius: 100, fontSize: ".9rem", px: 1.5 }}
        startIcon={<Add />}
      >
        <Typography variant="inherit" noWrap>
          Criar relatório
        </Typography>
      </Button>
    </>
  );
};

export default memo(RequestsActions);

import { AssignmentOutlined } from "@mui/icons-material";
import { Box, MenuItem, Typography } from "@mui/material";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { selectRequestById } from "../../store/features/requestsSlice";

function RequestMenuItem({ renderModalConfirm, requestId, selected, onClick }) {
  const { title, obs } = useSelector((state) =>
    selectRequestById(state, requestId)
  );

  return (
    <>
      <MenuItem
        data-disableselect={true}
        selected={selected}
        onClick={onClick}
        sx={{
          display: "flex",
          alignItems: "center",
          py: 1.5,
        }}
      >
        <AssignmentOutlined fontSize="small" color="action" sx={{ mr: 2 }} />
        <Box flex={1} pr={1} overflow={"hidden"}>
          <Typography noWrap fontSize={"1.03rem"} fontWeight={"600"}>
            {title}
          </Typography>
          <Typography
            noWrap
            sx={{ opacity: !obs ? 0.8 : 1 }}
            fontSize={".8rem"}
            color={"text.secondary"}
          >
            {obs || "Sem descrição"}
          </Typography>
        </Box>
      </MenuItem>
      {renderModalConfirm(title)}
    </>
  );
}

export default memo(RequestMenuItem);

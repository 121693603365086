import { PersonPinCircleOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  CircularProgress,
  MenuItem,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import React, { memo, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectAccountCurrency } from "../../../../store/features/accountSlice";
import {
  selectRoutePoliciesEntities,
  selectRoutePoliciesIds,
} from "../../../../store/features/configs/routePoliciesSlice";
import formatAmount from "../../../../utils/formatAmount";
import getRateByDate from "../../../../utils/getRateByDate";
import { curr } from "../../../../utils/more/currency_country";
import SelectorBox from "../SelectorBox";

const RoutePolicyItem = memo(({ data, refDate, ...props }) => {
  const accountCurrency = useSelector(selectAccountCurrency);
  const rate = useMemo(() => {
    return getRateByDate(data?.history || [], refDate);
  }, [refDate, data?.history]);
  return (
    <MenuItem {...props}>
      <Box flex={1}>
        <Typography variant="inherit">{data?.name}</Typography>
        <Typography variant="body2" color={"text.secondary"}>
          {data?.is_refundable ? "Reembolsável" : "Não reembolsável"}
        </Typography>
      </Box>
      <Typography fontWeight={"500"} variant="inherit">
        {curr(accountCurrency)} {formatAmount(rate)}
      </Typography>
    </MenuItem>
  );
});

function RoutePoliciesInput({
  value = "",
  size,
  onChange,
  readOnly,
  refDate,
  predefinedName,
}) {
  const ids = useSelector(selectRoutePoliciesIds);
  const entities = useSelector(selectRoutePoliciesEntities);

  const loading = useSelector((state) => state.routePolicies.working);

  useEffect(() => {
    if (!entities[value] && !predefinedName && value) {
      onChange("");
    }
  }, [value, entities, predefinedName]);

  const renderOption = useCallback(
    (props, option, state) => {
      return (
        <RoutePolicyItem
          refDate={refDate}
          data={entities[option] || {}}
          {...props}
        />
      );
    },
    [refDate, entities]
  );

  const getOptionLabel = useCallback(
    (option) => {
      const name = entities[option]?.name;
      return name || predefinedName || "";
    },
    [entities, predefinedName]
  );

  return (
    <SelectorBox Icon={PersonPinCircleOutlined}>
      <Autocomplete
        size={size || "small"}
        disablePortal
        PopperComponent={(props) => (
          <Popper {...props}>
            <Box px={1} pt={1} bgcolor={"background.default"}>
              <Typography variant="caption">
                Taxas referentes à data:{" "}
                <strong>{new Date(refDate).toLocaleDateString()}</strong>
              </Typography>
            </Box>
            {props.children}
          </Popper>
        )}
        fullWidth
        disableClearable
        readOnly={readOnly}
        options={ids}
        loadingText="Carregando..."
        value={value || null}
        onChange={(e, v) => {
          if (v) {
            onChange(v);
          }
        }}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            fullWidth
            variant="outlined"
            label={"Política de percurso"}
          />
        )}
      />
    </SelectorBox>
  );
}

export default memo(RoutePoliciesInput);

import { refreshToken } from "../../api/auth";

export default async function (error, action, retries, store) {
  const state = store?.getState();

  if (state.account.checkingAccess) return false;

  if (!error || !error.status || error.status === 403 || retries >= 5) {
    return true;
  }

  if (error.status === 401) {
    const { isAuth } = await refreshToken();
    return !isAuth;
  }
  return error && 400 <= error.status && error.status < 500;
}

import {
  Circle,
  ExpandLess,
  ExpandMore,
  RunningWithErrors,
} from "@mui/icons-material";
import { Box, Collapse, IconButton, Stack, Typography } from "@mui/material";
import React, { memo, useState } from "react";

const LimitsAlerts = ({ alerts = [] }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Box
      p={2}
      py={1}
      sx={{
        mt: 2,
        borderRadius: 3,
        maxWidth: 600,
        bgcolor: (t) => `${t.palette.warning.main}10`,
      }}
      border={1}
      borderColor={"divider"}
    >
      <Box display={"flex"} alignItems={"center"} gap={2}>
        <RunningWithErrors color="warning" />
        <Typography fontSize={"1.1rem"} fontWeight={"600"}>
          Limites ultrapassados ({alerts?.length})
        </Typography>
        <Box flex={1} />
        <IconButton onClick={() => setExpanded(!expanded)} size="small">
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>
      <Collapse in={expanded}>
        <Box pl={4} my={1} display={"flex"} flexDirection={"column"} gap={2}>
          {alerts?.map((item, idx) => (
            <Stack
              key={idx.toString}
              direction={"row"}
              alignItems={"flex-start"}
              gap={1}
            >
              <Circle color="action" sx={{ fontSize: ".4rem", mt: 1 }} />
              <Typography
                component={"div"}
                fontWeight={"500"}
                variant="body2"
                dangerouslySetInnerHTML={{
                  __html: item || "",
                }}
              />
            </Stack>
          ))}
        </Box>
      </Collapse>
    </Box>
  );
};

export default memo(LimitsAlerts);

import { AssignmentReturnOutlined } from "@mui/icons-material";
import { MenuItem } from "@mui/material";
import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ModalDelete from "../../../../../modals/ModalDelete";
import { openSnackbar } from "../../../../../store/features/base/snackbarBaseSlice";
import { expensesOfflineActions } from "../../../../../store/features/expensesSlice";

const RemoveFromRequest = ({ expenseId, requestId }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRemove = async () => {
    setOpen(false);
    dispatch(expensesOfflineActions.removeFromRequest(requestId, [expenseId]));
    dispatch(
      openSnackbar({
        message: `Despesa removida do relatório`,
        action: () => navigate(`/expenses`),
        actionText: "Ver avulsas",
      })
    );
  };

  return (
    <>
      <MenuItem
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
        sx={{ height: 40 }}
      >
        <AssignmentReturnOutlined
          fontSize="small"
          sx={{ mr: 1.5 }}
          color="action"
        />
        Voltar para avulsas
      </MenuItem>
      <ModalDelete
        buttonText={"Confirmar"}
        subtitle={"A despesa será removida do relatório"}
        title="Voltar despesa para avulsas?"
        open={open}
        onClose={() => setOpen(false)}
        onDelete={handleRemove}
      />
    </>
  );
};

export default memo(RemoveFromRequest);

import WebSocket from "./index";

class WSMessenger {
  static _running = false;
  static _verification = null;
  static _isOnline = true;

  //RECUPERAR EVENTS SALVOS EM ASYNC STORAGE
  static async reydrateMessage() {
    const result = window.localStorage.getItem("wsMessengerRunning");
    if (result) {
      this.startIntervalVerification();
    }
  }

  static toggleRunning(bool = false) {
    this._running = bool;
    if (bool) {
      window.localStorage.setItem("wsMessengerRunning", bool.toString());
    } else {
      window.localStorage.removeItem("wsMessengerRunning");
    }
  }

  //INICIAR INTERVAL DE VERIFICAÇÃO EM TEMPOS
  static startIntervalVerification() {
    if (!this._isOnline) return;
    this.stopIntervalVerification();
    this.toggleRunning(true);
    this._verification = setInterval(() => {
      this.sendMessage();
    }, 10000);
  }

  //PAUSAR INTERVAL DE VERIFICAÇÃO EM TEMPOS
  static stopIntervalVerification() {
    clearInterval(this._verification);
    this._verification = null;
    this.toggleRunning(false);
  }

  //REINICIAR INTERVAL DE VERIFICAÇÃO EM TEMPOS
  static applyMessage() {
    this.startIntervalVerification();
  }

  //ESTADO DA INTERNET
  static setIsOnline(isOnline) {
    this._isOnline = isOnline;
    if (!isOnline && this._verification) {
      this.stopIntervalVerification();
    } else if (isOnline && !this._verification) {
      this.startIntervalVerification();
    }
  }

  static sendMessage() {
    if (!this._isOnline) return;
    WebSocket.sendMessage("sync");
    //MESAGEM ENVIADA, PAUSAR VERIFICAÇÃO EM TEMPOS
    this.stopIntervalVerification();
  }
}

export default WSMessenger;

import { Close } from "@mui/icons-material";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import AvatarType from "../../../../components/avatar/AvatarType";
import CircleDivisor from "../../../../components/division/CircleDivisor";
import { selectExpenseTypesEntities } from "../../../../store/features/configs/expenseTypesSlice";
import { CurrencyFormat } from "../../../../utils/more/mask_functions";

const ResumeModal = ({
  open,
  onClose,
  resume = {},
  curr,
  totalRefundable,
  totalAmount,
}) => {
  const expTypes = useSelector(selectExpenseTypesEntities);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: 3 } }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        <Typography fontWeight={"500"} variant="body1" fontSize={"1.2rem"}>
          Resumo de totais
        </Typography>
        <Box flex={1} />
        <IconButton onClick={onClose} sx={{ m: -1 }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography
          variant="overline"
          fontWeight={"500"}
          fontSize={".7rem"}
          color={"text.secondary"}
        >
          Totais por categoria
        </Typography>
        {Object.keys(resume.types).length ? (
          Object.keys(resume.types).map((typeId) => (
            <Box
              key={typeId}
              py={1}
              display={"flex"}
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Typography
                component={"div"}
                display={"flex"}
                alignItems={"center"}
                variant="body2"
              >
                <AvatarType
                  icon={expTypes[typeId]?.icon}
                  size={30}
                  fz="1.2rem"
                  sx={{ mr: 1 }}
                />
                {expTypes[typeId]?.name}
              </Typography>
              <Typography ml={1} variant="body2">
                ({resume.types[typeId].total})
              </Typography>
              <Box flex={1} />
              <Typography variant="body1">
                {curr} <CurrencyFormat value={resume.types[typeId].amount} />
              </Typography>
            </Box>
          ))
        ) : (
          <Typography variant="body2" fontStyle={"italic"}>
            Nenhuma despesa por categoria
          </Typography>
        )}
        <Divider sx={{ mx: -3, my: 1.5 }} />
        <Typography
          variant="overline"
          fontWeight={"500"}
          fontSize={".7rem"}
          color={"text.secondary"}
        >
          Totais de percurso
        </Typography>
        {resume.route.total > 0 ? (
          <Box display={"flex"} alignItems={"center"} gap={1}>
            <Typography variant="body1">
              {resume.route.total} percurso(s)
            </Typography>
            <CircleDivisor />
            <Typography variant="body1">
              {parseFloat(resume.route.distance).toFixed(2)} km
            </Typography>
            <CircleDivisor />
            <Typography variant="body1">
              {curr} <CurrencyFormat value={resume.route.amount} />
            </Typography>
          </Box>
        ) : (
          <Typography variant="body2" fontStyle={"italic"}>
            Nenhuma despesa de percurso
          </Typography>
        )}
      </DialogContent>
      <Box
        mt={4}
        p={2}
        py={1}
        bgcolor={(t) =>
          t.palette.mode === "light" ? "#f1f3f4" : "action.hover"
        }
        display={"flex"}
        alignItems="center"
      >
        <Box
          flex={1}
          display={"flex"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography
            color={"text.secondary"}
            variant="body2"
            fontWeight={"500"}
          >
            Valor reembolsável:
          </Typography>
          <Tooltip
            placement="top"
            title={
              <Typography noWrap variant="body1" fontWeight={"500"}>
                {curr} <CurrencyFormat value={totalRefundable} />
              </Typography>
            }
          >
            <Typography noWrap variant="body1" fontWeight={"500"}>
              {curr} <CurrencyFormat value={totalRefundable} />
            </Typography>
          </Tooltip>
        </Box>
        <Divider orientation="vertical" sx={{ height: 40, mx: 3 }} />
        <Box
          flex={1}
          display={"flex"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography
            color={"text.secondary"}
            variant="body2"
            fontWeight={"500"}
          >
            Valor total:
          </Typography>
          <Tooltip
            placement="top"
            title={
              <Typography noWrap variant="body1" fontWeight={"500"}>
                {curr} <CurrencyFormat value={totalAmount} />
              </Typography>
            }
          >
            <Typography noWrap variant="body1" fontWeight={"500"}>
              {curr} <CurrencyFormat value={totalAmount} />
            </Typography>
          </Tooltip>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ResumeModal;

import { DeleteOutlineOutlined } from "@mui/icons-material";
import { MenuItem } from "@mui/material";
import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import ModalDelete from "../../../../../modals/ModalDelete";
import { openSnackbar } from "../../../../../store/features/base/snackbarBaseSlice";
import { expensesOfflineActions } from "../../../../../store/features/expensesSlice";

const DeleteExpense = ({ expenseId }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleDelete = async () => {
    setOpen(false);
    dispatch(expensesOfflineActions.removeExpenses([expenseId]));
    dispatch(openSnackbar({ message: "Despesa excluída" }));
  };

  return (
    <>
      <MenuItem
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
        sx={{ height: 40 }}
      >
        <DeleteOutlineOutlined
          fontSize="small"
          sx={{ mr: 1.5 }}
          color="action"
        />
        Excluir despesa
      </MenuItem>
      <ModalDelete
        title="Excluir despesa"
        open={open}
        onClose={() => setOpen(false)}
        onDelete={handleDelete}
      />
    </>
  );
};

export default memo(DeleteExpense);

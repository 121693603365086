import {
  CurrencyExchangeOutlined,
  NotesOutlined,
  PersonPinCircleOutlined,
  PostAddOutlined,
  TodayOutlined,
} from "@mui/icons-material";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { DirectionsRenderer } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import Map from "../../../../../../components/google-maps/Map";
import { calculateRoute } from "../../../../../../modals/expense-creation/new-route/functions";
import Baseline from "../../../../../../modals/expense-view/expense/contents/view/components/Baseline";
import RouteLine from "../../../../../../modals/expense-view/map-route/contents/view/components/RouteLine";
import { curr } from "../../../../../../utils/more/currency_country";
import { formatDate } from "../../../../../../utils/more/date_functions";
import { CurrencyFormat } from "../../../../../../utils/more/mask_functions";

export default ({ data }) => {
  const {
    currency,
    amount,
    project_name,
    distance,
    refundable,
    created_at,
    route_policy_name,
    waypoints,
    from,
    to,
    notes,
    rate,
    date,
  } = data;

  const {
    palette: { divider },
  } = useTheme();

  const [directionsResponse, setDirectionsResponse] = useState(null);

  useEffect(() => {
    (async () => {
      const { results } = await calculateRoute([
        from,
        ...(waypoints || []),
        to,
      ]);
      setDirectionsResponse(results);
    })();
    return () => {
      setDirectionsResponse(null);
    };
  }, []);

  return (
    <>
      <Typography
        variant="overline"
        fontWeight={"500"}
        color={"text.secondary"}
      >
        PERCURSO POR MAPA
      </Typography>
      <Box display={"flex"} flexDirection={"column"} gap={3}>
        <Box display={"flex"} alignItems={"flex-start"}>
          <Typography fontWeight={"500"} fontSize={"1.8rem"} variant="h4">
            {curr(currency)} <CurrencyFormat value={amount || 0} />
          </Typography>
          <Box flex={1} />
          <Box mt={1} ml={1} display={"flex"} alignItems={"center"}>
            <CurrencyExchangeOutlined
              fontSize="small"
              color={refundable ? "primary" : "disabled"}
              sx={{ mr: 1 }}
            />
            <Typography variant="body2">
              {refundable ? "Reembolsável" : "Não reembolsável"}
            </Typography>
          </Box>
        </Box>
        <Box
          position={"relative"}
          display={"flex"}
          flexDirection={"column"}
          gap={1.4}
        >
          <Divider
            orientation="vertical"
            sx={{
              position: "absolute",
              bgcolor: grey[400],
              left: 9.5,
            }}
          />
          <RouteLine value={from} />
          {(waypoints || []).map((wp, idx) => (
            <RouteLine key={wp + idx.toString()} value={wp} />
          ))}
          <RouteLine isDestination value={to} />
        </Box>
        <Typography color={"text.secondary"} fontSize={"1.2rem"} variant="h4">
          {parseFloat(distance || 0).toFixed(2)} km
        </Typography>
        <Divider sx={{ mx: -2 }} />
        <Box display={"flex"} flexDirection={"column"} gap={3} mx={-1}>
          <Baseline
            Icon={TodayOutlined}
            label={"Data do percurso"}
            value={formatDate(date)}
          />
          <Baseline
            Icon={PostAddOutlined}
            value={project_name}
            label={"Projeto"}
            emptyLabel="Sem projeto"
          />
          <Baseline
            Icon={PersonPinCircleOutlined}
            value={
              <Typography variant="inherit" component={"span"}>
                {route_policy_name} - {currency} <CurrencyFormat value={rate} />{" "}
                /km
              </Typography>
            }
            label={"Política de percurso"}
            emptyLabel="Sem política aplicada"
          />
          <Baseline
            Icon={NotesOutlined}
            value={notes}
            label={"Descrição"}
            emptyLabel="Sem descrição"
          />
        </Box>
        <Typography variant="caption" color={"text.secondary"}>
          Despesa criada em {formatDate(created_at || new Date())}
        </Typography>
        <Divider sx={{ mx: -2 }} />
        <Typography fontSize={"1.2rem"} variant="h5">
          Veja no mapa
        </Typography>
        <Map
          mapContainerStyle={{
            width: "100%",
            alignSelf: "center",
            height: 500,
            border: `2px solid ${divider}`,
            borderRadius: "10px",
          }}
          disableMyLocation
        >
          {directionsResponse && (
            <DirectionsRenderer directions={directionsResponse} />
          )}
        </Map>
      </Box>
    </>
  );
};

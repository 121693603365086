import { LocationOnOutlined } from "@mui/icons-material";
import React, { memo } from "react";
import FilterChip from "../../../../../../components/chip/FilterChip";

function isRouteFilter({ filter, onClick, onClear }) {
  const handleClick = () => {
    if (filter === undefined || filter === null) {
      onClick();
    } else {
      onClear();
    }
  };

  return (
    <FilterChip
      onClick={handleClick}
      Icon={LocationOnOutlined}
      label={"Percurso"}
      filtered={Boolean(filter)}
    />
  );
}

export default memo(isRouteFilter);

import { LinearProgress, useTheme } from "@mui/material";
import { GoogleMap, Marker } from "@react-google-maps/api";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPosition } from "../../store/features/base/settingsSlice";

const DEFAULT_CENTER = {
  lat: -8.598805678154301,
  lng: -53.0033534300447,
};

function Map({
  children,
  mapContainerStyle = {},
  enableCenteredLocation = true,
  options,
  disableMyLocation,
}) {
  const dispatch = useDispatch();

  const { mode } = useTheme().palette;
  const [loading, setLoading] = useState(false);

  const [mounted, setMounted] = useState(true);

  const currentPosition = useSelector(
    (state) => state.settings.currentPosition
  );

  useEffect(() => {
    setMounted(false);
    setTimeout(() => {
      setMounted(true);
    }, 150);
  }, [mode]);

  useEffect(() => {
    if (enableCenteredLocation && !currentPosition) {
      setLoading(true);
      window.navigator.geolocation.getCurrentPosition(
        (s) => {
          dispatch(
            setCurrentPosition({
              lat: s.coords.latitude,
              lng: s.coords.longitude,
            })
          );
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          dispatch(setCurrentPosition(DEFAULT_CENTER));
        },
        {
          enableHighAccuracy: true,
        }
      );
    }
  }, []);

  return (
    mounted && (
      <GoogleMap
        options={{
          mapId: mode === "dark" ? "efdbf3ce47824c04" : "fceaa9766c12a837",
          mapTypeControl: false,
          ...options,
        }}
        mapContainerStyle={mapContainerStyle}
        center={enableCenteredLocation ? currentPosition : DEFAULT_CENTER}
        zoom={10}
      >
        {loading && <LinearProgress />}
        {!disableMyLocation && currentPosition && (
          <Marker position={currentPosition} />
        )}
        {children}
      </GoogleMap>
    )
  );
}

export default memo(Map);

import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { APIActions } from "../../../queues/offline-queue/effect";
import normalize from "../../../utils/normalize";

const baseName = "paymentTypes";

const adapter = createEntityAdapter({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) =>
    a.name?.toUpperCase().localeCompare(b.name?.toUpperCase()),
});

const initialState = adapter.getInitialState({
  working: false,
});

export const syncPaymentTypes = createAsyncThunk(
  `${baseName}/syncPaymentTypes`,
  async () => {
    const res = await APIActions.paymentTypes.sync();
    return res;
  }
);

export const paymentTypesSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    setupPaymentTypes(state, { payload }) {
      const { entities } = normalize(payload);
      adapter.setAll(state, entities);
    },
    upsertPaymentType(state, { payload }) {
      adapter.upsertOne(state, payload);
    },
    removePaymentType(state, { payload }) {
      adapter.removeOne(state, payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(syncPaymentTypes.pending, (state, action) => {
        state.working = true;
      })
      .addCase(syncPaymentTypes.rejected, (state, action) => {
        state.working = false;
      })
      .addCase(syncPaymentTypes.fulfilled, (state, action) => {
        state.working = false;
        const data = action.payload;
        paymentTypesSlice.caseReducers.setupPaymentTypes(state, {
          payload: data,
        });
      });
  },
});

export const { setupPaymentTypes, upsertPaymentType, removePaymentType } =
  paymentTypesSlice.actions;

export const {
  selectAll: selectAllPaymentTypes,
  selectById: selectPaymentTypeById,
  selectIds,
  selectEntities: selectPaymentTypesEntities,
} = adapter.getSelectors((state) => state[baseName]);

export default paymentTypesSlice.reducer;

export const selectPaymentTypesIds = createSelector(
  [selectAllPaymentTypes, (state, showInactive) => showInactive],
  (data, showInactive) => {
    return data
      .filter((item) => (showInactive ? true : Boolean(item.active)))
      .map((item) => item.id);
  }
);

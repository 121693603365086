import { PostAddOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  selectProjectsEntities,
  selectProjectsIds,
} from "../../../../store/features/configs/projectsSlice";
import SelectorBox from "../SelectorBox";

function ProjectInput({
  value = "",
  predefinedValue,
  onChange,
  readOnly,
  loading,
  size,
}) {
  const ids = useSelector(selectProjectsIds);
  const entities = useSelector(selectProjectsEntities);

  useEffect(() => {
    if (!entities[value] && value && !predefinedValue) {
      onChange("");
    }
  }, [value, entities, predefinedValue]);

  return (
    <SelectorBox Icon={PostAddOutlined}>
      <Autocomplete
        disablePortal
        fullWidth
        value={value || null}
        readOnly={readOnly}
        autoHighlight
        options={ids}
        size={size || "small"}
        loadingText="Carregando..."
        onChange={(e, v) => {
          onChange(v || "");
        }}
        getOptionLabel={(option) =>
          entities[option]?.name || predefinedValue || ""
        }
        renderOption={(props, option, state) => {
          return (
            <MenuItem {...props} selected={state.selected}>
              <Box>
                <Typography variant="inherit">
                  {entities[option]?.name}{" "}
                </Typography>
                <Typography color={"text.secondary"} fontSize=".7rem">
                  {entities[option]?.description}
                </Typography>
              </Box>
            </MenuItem>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            fullWidth
            variant="outlined"
            label={"Projeto"}
          />
        )}
      />
    </SelectorBox>
  );
}

export default memo(ProjectInput);

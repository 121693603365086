import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import React from "react";
import { getProfilePicture } from "../../utils/functions/profile";

const UserListItem = ({ name, lastname, email, id }) => {
  return (
    <ListItem disableGutters>
      <ListItemAvatar>
        <Avatar
          sx={{ border: 1, borderColor: "divider" }}
          src={getProfilePicture(id)}
        ></Avatar>
      </ListItemAvatar>
      <ListItemText
        primaryTypographyProps={{ fontWeight: "500", fontSize: ".93rem" }}
        primary={`${name} ${lastname || ""}`}
        secondary={email}
      />
    </ListItem>
  );
};

export default UserListItem;

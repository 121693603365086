import { updateExpense } from "../../store/features/expensesSlice";
import { store } from "../../store/store";
import findExpensePoliciesByData from "../../utils/findExpensePoliciesByData";
import getCategoryByTag from "../../utils/getCategoryByTag";
import { newExpenseInitialState } from "../../utils/initialStates";

export default (expensePayload = "") => {
  const payload = JSON.parse(expensePayload);

  const expenseType = getCategoryByTag(payload?.category);
  const currency = store.getState()?.account?.currency;

  const applied = findExpensePoliciesByData({
    ...newExpenseInitialState,
    ...payload,
    type_id: expenseType?.id,
    is_route: false,
    route_mode: "",
    currency: payload?.currency || currency,
    currency_t: payload?.currency || currency,
    refundable: true,
  });

  const warnings = applied.map((rule) => rule.actions?.warning);
  const required_receipt = applied.some(
    (rule) => rule?.actions?.required_receipt
  );

  store.dispatch(
    updateExpense({
      id: payload?.expense_id,
      changes: {
        ...payload,
        type_id: expenseType?.id,
        warnings: warnings || [],
        required_receipt,
        processingStatus: null,
      },
    })
  );
};

import { ExpandLess, ExpandMore, InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeError } from "../../store/features/base/errorBaseSlice";

function ModalErrorBase(props) {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.errorBase.open);

  const title = useSelector((state) => state.errorBase.title);
  const subtitle = useSelector((state) => state.errorBase.subtitle);
  const content = useSelector((state) => state.errorBase.content);
  const error = useSelector((state) => state.errorBase.error);

  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (!open) {
      setShowMore(false);
    }
  }, [open]);

  return (
    open && (
      <Dialog data-disableselect={true} fullWidth maxWidth="xs" open={open}>
        {open && (
          <>
            <DialogTitle display={"flex"} alignItems="center">
              <InfoOutlined fontSize="large" sx={{ mr: 2 }} color="error" />
              <span>{title}</span>
            </DialogTitle>
            <DialogContent>
              {content}
              <DialogContentText>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      subtitle ||
                      error?.response?.data?.message?.toString() ||
                      error?.response?.data?.toString() ||
                      "",
                  }}
                />
                <br />
                <Typography component={"span"} fontSize={"0.8rem"} mt={1}>
                  Código do erro:{" "}
                  <Typography
                    component={"span"}
                    variant="inherit"
                    fontWeight={600}
                  >
                    {error?.response?.data?.code || error?.code || "NO_CODE"}
                  </Typography>
                </Typography>
              </DialogContentText>
              {error?.response?.data?.details && (
                <Box>
                  <Button
                    onClick={() => setShowMore(!showMore)}
                    size="small"
                    sx={{ px: 1, ml: -1, mt: 0.5 }}
                    endIcon={
                      showMore ? (
                        <ExpandLess fontSize="small" />
                      ) : (
                        <ExpandMore fontSize="small" />
                      )
                    }
                  >
                    Mais detalhes
                  </Button>
                  <Collapse in={showMore}>
                    <Typography mt={1} variant="body2">
                      - {error?.response?.data?.details}
                    </Typography>
                  </Collapse>
                </Box>
              )}
            </DialogContent>
            <DialogActions
              sx={{ py: 2 }}
              onClick={() => dispatch(closeError())}
            >
              <Button>Ok</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    )
  );
}

export default ModalErrorBase;

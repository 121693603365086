import { InfoOutlined, NotesOutlined } from "@mui/icons-material";
import { Box, Divider, Tooltip, Typography } from "@mui/material";
import React, { memo, useMemo } from "react";
import UserItem from "../../../../../components/chip/ChipUser";

const RejectionBar = ({ events = [] }) => {
  const rejectedEvent = useMemo(() => {
    if (events) {
      const revertedEvents = [...(events || [])]?.reverse();
      const ev = (revertedEvents || [])?.find((ev) => ev.type === "R");
      return ev;
    }
  }, [events]);

  const rejectedByUser = useMemo(
    () =>
      rejectedEvent?.approvers?.find((ap) => ap.status === "R") || {
        name: "",
        lastname: "",
        _id: "",
      },
    [rejectedEvent]
  );

  return (
    <Box
      bgcolor={"background.default"}
      boxShadow={2}
      px={3}
      height={50}
      display={"flex"}
      alignItems={"center"}
      gap={1}
    >
      <InfoOutlined color="error" />
      <Typography noWrap variant="body2" color={"text.secondary"}>
        Rejeitado por
      </Typography>
      <UserItem
        id={rejectedByUser?._id}
        name={`${rejectedByUser?.name} ${rejectedByUser?.lastname || ""}`}
      />
      <Divider sx={{ height: 15 }} orientation="vertical" />
      <NotesOutlined color="disabled" />
      <Tooltip
        arrow
        disableHoverListener={!rejectedByUser?.comment}
        disableFocusListener={!rejectedByUser?.comment}
        title={rejectedByUser?.comment}
      >
        <Typography
          noWrap
          variant="body2"
          fontWeight={"500"}
          color={rejectedByUser?.comment ? "text.primary" : "text.secondary"}
        >
          {rejectedByUser?.comment || "Nenhum motivo informado"}
        </Typography>
      </Tooltip>
    </Box>
  );
};

export default memo(RejectionBar);

import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { memo, useCallback, useMemo } from "react";
import FlipMove from "react-flip-move";
import { useSelector } from "react-redux";
import ExpenseCardItem from "../../../components/expenses/card/ExpenseCardItem";
import { selectLayoutViewMode } from "../../../store/features/base/settingsSlice";
import ExpensesTable from "./ExpensesTable";

const EXPENSES_GRID_STYLE = {
  padding: "25px",
  paddingBottom: "80px",
  display: "grid",
  gridGap: "15px",
  gridTemplateColumns: "repeat(auto-fill, minmax(340px,1fr))",
  gridAutoRows: "auto",
};

const ExpensesRenderer = ({
  expensesIds = [],
  allExpenses = null,
  style = {},
  isSelected,
  isRejected,
  onExpenseClick,
  enableSelectScrollIntoView,
  onExpenseSelect,
  renderCustomAction,
  disableActions,
  enableOtherSection = false,
  sectionExpensesIds = [],
  sectionTitle = "",
  sectionDescription = "",
  moreSectionTitle = "Demais",
  expensesTableRef,
  expenseSettings = {
    compact: true,
    select: true,
    remove: true,
    addToRequest: true,
    removeFromRequest: false,
    showMore: true,
  },
}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  const viewMode = useSelector(selectLayoutViewMode);

  const expenses = useMemo(
    () => allExpenses || expensesIds,
    [allExpenses, expensesIds]
  );

  const renderExpenseCardItem = useCallback(
    (expense) => {
      const isExpenseObj = Boolean(typeof expense !== "string");
      const expenseId = isExpenseObj ? expense?.id : expense;
      const expenseObj = isExpenseObj ? expense : null;

      return (
        <ExpenseCardItem
          key={expense}
          expenseObj={expenseObj}
          disableActions={disableActions}
          expenseId={expenseId}
          selected={isSelected ? isSelected(expenseId) : false}
          rejected={isRejected ? isRejected(expenseId) : false}
          onChangeSelected={onExpenseSelect}
          onClick={onExpenseClick}
          settings={expenseSettings}
          customActionComponent={renderCustomAction}
          enableSelectScrollIntoView={enableSelectScrollIntoView}
        />
      );
    },
    [
      isSelected,
      isRejected,
      renderCustomAction,
      expenseSettings,
      disableActions,
      enableSelectScrollIntoView,
    ]
  );

  if (viewMode === "grid") {
    return (
      <>
        {enableOtherSection && Boolean(sectionExpensesIds.length) && (
          <>
            <Box pt={3} px={3}>
              <Typography fontSize={"1.1rem"} fontWeight={"500"}>
                {sectionTitle}
              </Typography>
              {sectionDescription && (
                <Typography fontSize={".95rem"} color={"text.secondary"}>
                  {sectionDescription}
                </Typography>
              )}
            </Box>
            <FlipMove
              duration={240}
              style={{
                ...EXPENSES_GRID_STYLE,
                ...style,
                paddingBottom: "20px",
                paddingTop: "15px",
              }}
            >
              {sectionExpensesIds.map(renderExpenseCardItem)}
            </FlipMove>
          </>
        )}
        {enableOtherSection && moreSectionTitle && (
          <Box pt={1} mb={-1} px={3}>
            <Typography fontSize={"1.1rem"} fontWeight={"500"}>
              {moreSectionTitle}
            </Typography>
          </Box>
        )}
        <FlipMove
          duration={240}
          style={{
            ...EXPENSES_GRID_STYLE,
            padding: isSmall ? "15px" : EXPENSES_GRID_STYLE.padding,
            ...style,
          }}
        >
          {expenses.map(renderExpenseCardItem)}
        </FlipMove>
      </>
    );
  } else {
    return (
      <ExpensesTable
        enableOtherSection={enableOtherSection}
        expensesIds={expensesIds}
        isRejected={isRejected}
        isSelected={isSelected}
        onExpenseClick={onExpenseClick}
        onExpenseSelect={onExpenseSelect}
        renderCustomAction={renderCustomAction}
        expenseSettings={expenseSettings}
        sectionExpensesIds={sectionExpensesIds}
        allExpenses={allExpenses}
        disableActions={disableActions}
        sectionTitle={sectionTitle}
        moreSectionTitle={moreSectionTitle}
        ref={expensesTableRef}
        enableSelectScrollIntoView={enableSelectScrollIntoView}
      />
    );
  }
};

export default memo(ExpensesRenderer);

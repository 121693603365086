import { PaymentOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { memo } from "react";
import { connect } from "react-redux";
import {
  selectPaymentTypesEntities,
  selectPaymentTypesIds,
} from "../../../../store/features/configs/paymentTypesSlice";
import SelectorBox from "../SelectorBox";

function PaymentTypeInput({
  paymentTypes,
  paymentTypesEntities,
  value,
  onChange = () => {},
  readOnly,
  loading,
  hideInactive = true,
  size
}) {
  return (
    <SelectorBox Icon={PaymentOutlined}>
      <Autocomplete
        disablePortal
        size={size || "small"}
        fullWidth
        autoHighlight
        readOnly={readOnly}
        options={paymentTypes.filter((id) =>
          hideInactive ? paymentTypesEntities[id]?.active : true
        )}
        loadingText="Carregando..."
        value={value || null}
        onChange={(e, v) => {
          onChange(v || "");
        }}
        getOptionLabel={(option) => paymentTypesEntities[option]?.name}
        renderOption={(props, option, state) => {
          return (
            <MenuItem {...props} selected={state.selected}>
              {paymentTypesEntities[option]?.name}
              <Box flex={1} />
              <Typography variant="caption">
                {!paymentTypesEntities[option]?.can_edit
                  ? paymentTypesEntities[option]?.is_refundable
                    ? "Reembolsável"
                    : "Não reembolsável"
                  : ""}
              </Typography>
            </MenuItem>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            fullWidth
            variant="outlined"
            helperText={
              value &&
              !paymentTypesEntities[value]?.active && (
                <Typography
                  variant="inherit"
                  color="warning.main"
                  fontWeight={300}
                >
                  Forma de pagamento inativa
                </Typography>
              )
            }
            label={"Forma de pagamento"}
          />
        )}
      />
    </SelectorBox>
  );
}

function mapStateToProps(state, props) {
  return {
    paymentTypesEntities: selectPaymentTypesEntities(state),
    paymentTypes: selectPaymentTypesIds(state),
    ...props,
  };
}

export default connect(mapStateToProps)(memo(PaymentTypeInput));

import { PaidOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { BarChart, cheerfulFiestaPalette } from "@mui/x-charts";
import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import LoadingSection from "../../../../components/loading/LoadingSection";
import { selectAccountCurrency } from "../../../../store/features/accountSlice";
import { selectLast3MonthsTotals } from "../../../../store/features/totalsSummarySlice";
import formatAmount from "../../../../utils/formatAmount";

const Last3MonthsTotalsChart = () => {
  const currency = useSelector((state) => selectAccountCurrency(state, true));
  const totals = useSelector(selectLast3MonthsTotals);

  const { data, working } = totals || {
    data: [],
    working: true,
  };

  const xAxisData = useMemo(() => data?.map((t, ind) => ind), [data]);
  const seriesData = useMemo(() => data?.map((t) => t?.total), [data]);

  if (working) {
    return <LoadingSection h={250} />;
  } else if (!data?.length) {
    return (
      <Box
        height={150}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        p={2}
        pb={3}
      >
        <PaidOutlined
          color="disabled"
          fontSize="large"
          sx={{ mb: 1, fontSize: "3.3rem" }}
        />
        <Typography textAlign={"center"} color={"text.secondary"}>
          Nenhum gasto recente
        </Typography>
      </Box>
    );
  } else {
    return (
      <BarChart
        xAxis={[
          {
            scaleType: "band",
            data: xAxisData,
            valueFormatter: (v) => data[v]?.label,
          },
        ]}
        series={[
          {
            data: seriesData,
            label: `Total (${currency})`,
            highlightScope: { faded: "global", highlighted: "item" },
            valueFormatter: (v) => `${currency} ${formatAmount(v)}`,
          },
        ]}
        colors={cheerfulFiestaPalette}
        height={250}
      />
    );
  }
};

export default memo(Last3MonthsTotalsChart);

export const root_migrations = {
  0: (state) => {
    return {
      ...state,
      account: {
        ...state.account,
        receiptsAccessToken: {
          token: null,
          expiresAt: null,
        },
      },
    };
  },
};

export const account_migrations = {
  0: (state) => {
    return {
      ...state,
      receiptsAccessToken: {
        token: null,
        expiresAt: null,
      },
    };
  },
};

import { FilterAltOffOutlined } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import DateFilter from "./filters/DateFilter";
import ExpTypeFilter from "./filters/ExpTypeFilter";
import PaymentTypeFilter from "./filters/PaymentTypeFilter";
import ProjectsFilter from "./filters/ProjectsFilter";
import RefundableFilter from "./filters/RefundableFilter";
import IsRouteFilter from "./filters/isRouteFilter";

function FiltersOptions({
  onChangeFilter,
  filters,
  onRemoveFilter,
  onClearAllFilters,
}) {
  const filterCallbacks = useMemo(
    () => ({
      expTypes: {
        onChange: (v) => onChangeFilter("expTypes", v),
        onClear: () => onRemoveFilter("expTypes"),
      },
      project: {
        onChange: (v) => onChangeFilter("project", v),
        onClear: () => onRemoveFilter("project"),
      },
      receipts: {
        onChange: (v) => onChangeFilter("receipts", v),
        onClear: () => onRemoveFilter("receipts"),
      },
      refundable: {
        onClick: () => onChangeFilter("refundable", true),
        onClear: () => onRemoveFilter("refundable"),
      },
      payment: {
        onChange: (v) => onChangeFilter("payment", v),
        onClear: () => onRemoveFilter("payment"),
      },
      isRoute: {
        onClick: () => onChangeFilter("isRoute", true),
        onClear: () => onRemoveFilter("isRoute"),
      },
      date: {
        onChange: (v) => onChangeFilter("date", v),
        onClear: () => onRemoveFilter("date"),
      },
    }),
    [onChangeFilter, onRemoveFilter]
  );

  return (
    <>
      {Object.keys(filters).length > 0 && (
        <>
          <Tooltip title="Limpar todos os filtros">
            <IconButton sx={{ ml: -1 }} onClick={onClearAllFilters}>
              <FilterAltOffOutlined />
            </IconButton>
          </Tooltip>
        </>
      )}
      <DateFilter
        filter={filters.date || ''}
        onChange={useCallback(filterCallbacks.date.onChange, [])}
        onClear={useCallback(filterCallbacks.date.onClear, [])}
      />
      <ExpTypeFilter
        filter={filters.expTypes || []}
        onChange={useCallback(filterCallbacks.expTypes.onChange, [])}
        onClear={useCallback(filterCallbacks.expTypes.onClear, [])}
      />
      <ProjectsFilter
        filter={filters.project || null}
        onChange={useCallback(filterCallbacks.project.onChange, [])}
        onClear={useCallback(filterCallbacks.project.onClear, [])}
      />
      {/* <ReceiptsFilter
        filter={filters.receipts || null}
        onChange={useCallback(filterCallbacks.receipts.onChange, [])}
        onClear={useCallback(filterCallbacks.receipts.onClear, [])}
      /> */}
      <RefundableFilter
        filter={filters.refundable}
        onClick={useCallback(filterCallbacks.refundable.onClick, [])}
        onClear={useCallback(filterCallbacks.refundable.onClear, [])}
      />
      <PaymentTypeFilter
        filter={filters.payment}
        onChange={useCallback(filterCallbacks.payment.onChange, [])}
        onClear={useCallback(filterCallbacks.payment.onClear, [])}
      />
      <IsRouteFilter
        filter={filters.isRoute}
        onClick={useCallback(filterCallbacks.isRoute.onClick, [])}
        onClear={useCallback(filterCallbacks.isRoute.onClear, [])}
      />
    </>
  );
}

export default FiltersOptions;

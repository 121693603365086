import { Badge, Box, ListItemIcon, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { memo, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -1,
    top: 5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 3px",
    fontWeight: "700",
    fontSize: ".6rem",
  },
}));

const ButtonBox = memo(
  ({
    activated,
    Icon,
    ActiveIcon,
    title,
    badge,
    palette,
    tour_id,
    onClick = () => {},
  }) => {
    const [hovered, setHovered] = useState(false);

    const handleClick = React.useCallback(() => {
      onClick();
      setHovered(false);
    }, [onClick]);

    const handleMouseEnter = React.useCallback(() => setHovered(true), []);

    const handleMouseLeave = React.useCallback(() => setHovered(false), []);

    return (
      <Box
        component={"div"}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        tour_id={activated ? "" : tour_id}
        sx={{
          width: "100%",
          py: 1.7,
          px: 1,
          display: "flex",
          position: "relative",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: 0,
          transition: "none",
          cursor: "pointer",
        }}
      >
        <ListItemIcon
          sx={{
            display: "flex",
            alignItems: "center",
            color:
              activated || hovered
                ? palette.text.primary
                : palette.text.secondary,
            justifyContent: "center",
            bgcolor:
              hovered && activated
                ? `${palette.primary.main}40`
                : activated
                ? `${palette.primary.main}30`
                : hovered
                ? "action.hover"
                : "transparent",
            borderRadius: 100,
            height: 34,
          }}
        >
          <StyledBadge color="primary" badgeContent={activated ? 0 : badge}>
            {activated
              ? ActiveIcon && (
                  <ActiveIcon
                    sx={{
                      fontSize: "26px",
                      transform: hovered ? "scale(1.05)" : "scale(1)",
                      transition: ".1s ease",
                    }}
                  />
                )
              : Icon && (
                  <Icon
                    sx={{
                      fontSize: "26px",
                      transform: hovered ? "scale(1.05)" : "scale(1)",
                      transition: ".1s ease",
                    }}
                  />
                )}
          </StyledBadge>
        </ListItemIcon>
        <Typography
          mt={0.3}
          fontWeight={"500"}
          fontSize={".775rem"}
          lineHeight={"normal"}
          textAlign={"center"}
          sx={{
            opacity: activated || hovered ? 1 : 0.75,
          }}
          color={"text.primary"}
        >
          {title}
        </Typography>
      </Box>
    );
  }
);

function SidebarButtonItem({
  disableLink,
  path,
  title,
  Icon,
  badge,
  tour_id,
  onClick = () => {},
  ActiveIcon,
}) {
  const { palette } = useTheme();

  const { pathname } = useLocation();

  const isActive = (link) => {
    return new RegExp(`${link}`).test(pathname);
  };

  const activated = useMemo(() => isActive(path), [pathname]);

  return disableLink ? (
    <ButtonBox
      tour_id={tour_id}
      Icon={Icon}
      activated={activated}
      badge={badge}
      palette={palette}
      title={title}
      onClick={onClick}
      ActiveIcon={ActiveIcon}
    />
  ) : (
    <Link
      draggable={false}
      style={{
        textDecoration: "none",
        color: activated ? palette.primary.main : palette.text.secondary,
      }}
      to={path}
    >
      <ButtonBox
        tour_id={tour_id}
        Icon={Icon}
        activated={activated}
        badge={badge}
        palette={palette}
        title={title}
        ActiveIcon={ActiveIcon}
      />
    </Link>
  );
}

export default memo(SidebarButtonItem);

import {
  Circle,
  EditRoadOutlined,
  LocationOnOutlined,
  NotesOutlined,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { selectExpenseTypeById } from "../../../../store/features/configs/expenseTypesSlice";
import AvatarType from "../../../avatar/AvatarType";
import { DetailSmallItem } from "./MoreDetails";

function ExpTypeArea({
  isRoute,
  route,
  expTypeId,
  notes,
  routeMode,
  distance,
  hasWarnings,
}) {
  const expType = useSelector((s) => selectExpenseTypeById(s, expTypeId));
  return isRoute ? (
    <Box mb={0.5}>
      {routeMode === "map" ? (
        <>
          <Box mt={-0.3} mb={0.3} display={"flex"} alignItems={"center"}>
            <Box display={"flex"} width={18}>
              <Circle
                fontSize="small"
                color="disabled"
                sx={{ fontSize: ".5rem", ml: 0.1 }}
              />
            </Box>
            <Typography
              flex={1}
              noWrap
              fontWeight={"500"}
              variant="body2"
              fontSize={".8rem"}
            >
              {route?.from}
            </Typography>
          </Box>
          <Box mb={0.3} display={"flex"} alignItems={"center"}>
            <Box display={"flex"} width={18}>
              <LocationOnOutlined
                fontSize="small"
                color="error"
                sx={{ fontSize: ".7rem" }}
              />
            </Box>
            <Typography
              flex={1}
              noWrap
              fontWeight={"500"}
              variant="body2"
              fontSize={".8rem"}
            >
              {route?.to}
            </Typography>
          </Box>
          <Typography mt={1} fontWeight={"600"} noWrap fontSize={".875rem"}>
            {parseFloat(distance || 0).toFixed(2)} km
          </Typography>
        </>
      ) : (
        <>
          <Box mb={1} mt={0.2} display={"flex"} alignItems={"center"}>
            <EditRoadOutlined
              fontSize="small"
              color="primary"
              sx={{ fontSize: "1.2rem", mr: 1.7, opacity: 0.9 }}
            />
            <Typography fontWeight={"500"} noWrap fontSize={".875rem"}>
              {parseFloat(distance || 0).toFixed(2)} km
              {"  "}
              <Typography
                ml={0.2}
                variant="caption"
                component={"span"}
                color={"text.secondary"}
                fontWeight={"400"}
              >
                (Percurso manual)
              </Typography>
            </Typography>
          </Box>
          <DetailSmallItem Icon={NotesOutlined} emptyText="Sem descrição">
            {notes}
          </DetailSmallItem>
        </>
      )}
    </Box>
  ) : (
    <Box>
      <Box ml={-0.5} mb={1.2} display={"flex"} alignItems={"center"} gap={1}>
        <AvatarType showBgcolor icon={expType?.icon} size={30} fz="1.2rem" />
        <Typography
          noWrap
          ml={0.3}
          fontSize={".875rem"}
          color={expType ? "text.primary" : "text.secondary"}
          fontWeight={expType ? "500" : "400"}
        >
          {expType?.name || "Sem categoria"}
        </Typography>
      </Box>
      <DetailSmallItem
        maxWidth={hasWarnings ? "78%" : "85%"}
        Icon={null}
        emptyText="Sem descrição"
      >
        {notes}
      </DetailSmallItem>
    </Box>
  );
}

export default memo(ExpTypeArea);

import { AttachFileOutlined } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  CircularProgress,
  Collapse,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Zoom from "react-medium-image-zoom";
import { useDispatch } from "react-redux";
import PdfIcon from "../../../../../assets/icons/pdf_icon.svg";
import "../../../../../main.css";
import { setError } from "../../../../../store/features/base/errorBaseSlice";
import { expensesOfflineActions } from "../../../../../store/features/expensesSlice";
import { getReceiptUrl } from "../../../../../utils/functions/receipts";
import DuplicatedAlert from "./DuplicatedAlert";
import NavigateCarousel from "./NavigateCarousel";
import ReceiptHeader from "./ReceiptHeader";
import TagsIndicator from "./TagsIndicator";
import UploadButton from "./UploadButton";

function ReceiptsContent({
  receipts = [],
  expenseId,
  requiredReceipt,
  duplicatedReceipts,
  settings = { edit: true },
}) {
  const dispatch = useDispatch();

  const visibleReceipts = useMemo(() => {
    return receipts?.filter((receipt) => !receipt.hidden) || [];
  }, [receipts]);

  const [carouselIndex, setCarouselIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const currentReceipt = useMemo(() => {
    return visibleReceipts[carouselIndex] || null;
  }, [visibleReceipts, carouselIndex]);

  const isDuplicatedReceipt = useMemo(() => {
    return Boolean(
      duplicatedReceipts?.find((receipt) => receipt?.id === currentReceipt?.id)
    );
  }, [duplicatedReceipts, currentReceipt]);

  const duplicatedReceiptsByCurrent = useMemo(() => {
    return duplicatedReceipts?.filter(
      (receipt) => receipt?.id === currentReceipt?.id
    );
  }, [duplicatedReceipts, currentReceipt]);

  const handleNext = useCallback(() => {
    setLoading(true);
    setCarouselIndex((prev) => {
      if (prev === visibleReceipts?.length - 1) return 0;
      return prev + 1;
    });
  }, [visibleReceipts]);

  const handlePrev = useCallback(() => {
    setLoading(true);
    setCarouselIndex((prev) => {
      if (prev === 0) return visibleReceipts?.length - 1;
      return prev - 1;
    });
  }, [visibleReceipts]);

  const handleAddCapture = useCallback((idx) => {
    if (idx < 0) return;
    setCarouselIndex(idx);
  }, []);

  const handleRemoveReceipt = useCallback(async () => {
    const receiptId = currentReceipt?.id;
    if (!receiptId) {
      dispatch(setError({ title: "Erro ao remover comprovante" }));
      return;
    }
    dispatch(expensesOfflineActions.removeReceipt(expenseId, receiptId));
    handleNext();
  }, [currentReceipt, expenseId]);

  useEffect(() => {
    if (!currentReceipt) {
      setCarouselIndex(0);
    }
    if (currentReceipt?.type === "application/pdf") {
      setLoading(false);
    }
  }, [currentReceipt, carouselIndex]);

  useEffect(() => {
    setLoading(true);
    return () => {
      setLoading(false);
    };
  }, []);

  const openInNewWindow = () => {
    window.open(getReceiptUrl(currentReceipt?.url) || currentReceipt?.uri);
  };

  return (
    <>
      <Box
        sx={{ overflowY: "scroll", overflowX: "hidden" }}
        height={"100%"}
        py={2}
        display="flex"
        className="hover-scrollbar"
        flexDirection={"column"}
        position={"relative"}
        flex={1.2}
      >
        {visibleReceipts.length ? (
          <>
            <NavigateCarousel
              currentIndex={carouselIndex}
              onNext={handleNext}
              onPrev={handlePrev}
              total={visibleReceipts?.length}
            />
            {Boolean(isDuplicatedReceipt) && (
              <DuplicatedAlert
                duplicatedReceipts={duplicatedReceiptsByCurrent}
                receiptUrl={currentReceipt?.url}
              />
            )}
            <Box
              position={"relative"}
              borderRadius={2}
              minHeight={loading ? 590 : undefined}
            >
              <ReceiptHeader
                filename={currentReceipt?.filename}
                openInNewWindow={openInNewWindow}
                settings={settings}
                onRemove={handleRemoveReceipt}
              />
              {Boolean(currentReceipt?.tags?.length) && (
                <TagsIndicator tags={currentReceipt?.tags || []} />
              )}
              <Box
                position={"absolute"}
                top={0}
                left={0}
                zIndex={loading ? 100 : -1}
                width={"100%"}
                height={"100%"}
                display={"flex"}
                minHeight={590}
                alignItems={"flex-start"}
                pt={"80px"}
                justifyContent={"center"}
                bgcolor={"rgba(0,0,0,.3)"}
                sx={{ opacity: loading ? 1 : 0, transition: ".15s ease" }}
              >
                <CircularProgress
                  size={30}
                  sx={{ color: "white", position: "sticky", top: "80px" }}
                />
              </Box>
              {currentReceipt?.type === "application/pdf" ? (
                <img
                  onClick={openInNewWindow}
                  src={PdfIcon}
                  style={{ padding: "30px", cursor: "pointer" }}
                  height={"100%"}
                  width={"100%"}
                  onLoad={() => setLoading(false)}
                />
              ) : (
                <Zoom classDialog="custom-zoom">
                  <img
                    src={
                      getReceiptUrl(currentReceipt?.url) || currentReceipt?.uri
                    }
                    height={"100%"}
                    width={"100%"}
                    onLoad={() => setLoading(false)}
                    style={{ borderRadius: "5px" }}
                  />
                </Zoom>
              )}
            </Box>
          </>
        ) : (
          <>
            <Typography
              mt={1}
              textAlign={"center"}
              color="text.primary"
              fontSize={"1.2rem"}
              alignSelf={"center"}
              fontWeight="500"
              borderRadius={100}
              px={2}
            >
              Comprovantes
            </Typography>
            <Typography
              mb={2}
              mx={3}
              textAlign={"center"}
              fontSize={".85rem"}
              color="text.secondary"
              variant="body2"
            >
              Anexe arquivos dos tipos PNG, JPEG, WEBP ou PDF.
            </Typography>
            <Collapse in={requiredReceipt}>
              <Alert sx={{ mb: 2, mx: 2 }} severity="warning">
                Esta despesa requer pelo menos um comprovante
              </Alert>
            </Collapse>
            <Box
              pt={4}
              display={"flex"}
              flexDirection="column"
              alignItems={"center"}
            >
              <Avatar
                sx={{
                  width: 110,
                  height: 110,
                  bgcolor: (t) => `${t.palette.primary.main}20`,
                  color: "primary.main",
                }}
              >
                <AttachFileOutlined
                  sx={{ fontSize: "4rem", transform: "rotate(45deg)" }}
                />
              </Avatar>
              <Typography
                mb={2}
                color={"text.secondary"}
                mt={2}
                fontSize="1rem"
              >
                Nenhum comprovante adicionado
              </Typography>
              {settings.edit && (
                <UploadButton expenseId={expenseId} onAdd={handleAddCapture} />
              )}
            </Box>
          </>
        )}
      </Box>
      {settings.edit && Boolean(receipts?.length) && (
        <UploadButton expenseId={expenseId} onAdd={handleAddCapture} />
      )}
    </>
  );
}

export default ReceiptsContent;

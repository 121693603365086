import { Box, Typography } from "@mui/material";
import React, { memo } from "react";

const TitleSection = ({ title, obs }) => {
  return (
    <Box px={2} overflow={"hidden"}>
      <Typography noWrap fontSize={".975rem"} fontWeight={"600"}>
        {title}
      </Typography>
      <Typography
        noWrap
        textOverflow={"ellipsis"}
        fontSize={".85rem"}
        sx={{ opacity: obs ? 0.8 : 0.9 }}
        color={obs ? "text.primary" : "text.secondary"}
      >
        {obs || "Sem descrição"}
      </Typography>
    </Box>
  );
};

export default memo(TitleSection);

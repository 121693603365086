import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./features";

//offline
import { offline } from "@redux-offline/redux-offline";
import offlineConfig from "@redux-offline/redux-offline/lib/defaults";
import defaultQueue from "@redux-offline/redux-offline/lib/defaults/queue";

//persist
import { createMigrate, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import discard from "../queues/offline-queue/discard";
import { effect } from "../queues/offline-queue/effect";
import peek from "../queues/offline-queue/peek";
import PersistTransformer from "../store-transformers/PersistTransformer";
import { root_migrations } from "./migrations";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "errorBase",
    "snackbarBase",
    "approvalRequests",
    "portal",
    "account",
    "notifications",
    "totalsSummary",
    "settings",
  ],
  transforms: [PersistTransformer],
  version: 0,
  migrate: createMigrate(root_migrations, {
    debug: false,
  }),
};

const appReducer = (state, action) => {
  if (action.type === "RESET_STORE") {
    // for all keys defined in your persistConfig(s)
    storage.removeItem("persist:root");
    // storage.removeItem('persist:otherKey')
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, appReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  enhancers: (defaultEnhancers) => [
    ...defaultEnhancers,
    offline({
      ...offlineConfig,
      persistAutoRehydrate: false,
      discard: (error, action, retries) =>
        discard(error, action, retries, store),
      effect,
      queue: {
        ...defaultQueue,
        peek: (queue) => peek(queue, store),
      },
    }),
  ],
});

const persistor = persistStore(store);

export { persistor, store };

import {
  AttachFileOutlined,
  Close,
  CopyAllOutlined,
  FeedbackOutlined,
  LiquorRounded,
  WarningOutlined
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import findExpensePoliciesByData from "../utils/findExpensePoliciesByData";

export const RuleItem = memo(({ message, type }) => {
  return (
    <ListItem dense disableGutters>
      <ListItemIcon>
        {type ? (
          type === "required_receipt" ? (
            <AttachFileOutlined color="action" />
          ) : type === "alcohol" ? (
            <LiquorRounded color="action" />
          ) : type === "dupReceipt" ? (
            <CopyAllOutlined color="action" />
          ) : (
            <FeedbackOutlined color="action" />
          )
        ) : (
          <FeedbackOutlined color="action" />
        )}
      </ListItemIcon>
      <ListItemText
        sx={{ ml: -2 }}
        primaryTypographyProps={{ fontWeight: "500" }}
        primary={message}
      />
    </ListItem>
  );
});

const ExpensePolicyLayer = forwardRef(
  (
    { onCreate = () => {}, isSave, isRoute, showRequiredReceipt = true },
    ref
  ) => {
    const [open, setOpen] = useState(false);
    const [appliedPolicies, setAppliedPolicies] = useState([]);
    const [readOnly, setReadOnly] = useState(false);

    const present = useCallback(() => {
      setOpen(true);
    }, []);
    const dismiss = useCallback(() => {
      setOpen(false);
    }, []);

    const requiredReceipt = useMemo(() => {
      return Boolean(
        appliedPolicies.some((rule) => rule?.actions?.required_receipt)
      );
    }, [appliedPolicies]);

    useImperativeHandle(ref, () => ({
      check: (expenseData, presentOnCheck = true) => {
        const applied = findExpensePoliciesByData(expenseData);
        const warnings = applied.map((rule) => rule.actions?.warning);
        const required_receipt = applied.some(
          (rule) => rule?.actions?.required_receipt
        );

        if (Boolean(applied && applied.length)) {
          setAppliedPolicies(applied);
          if (presentOnCheck) {
            setReadOnly(false);
            present();
          }
          return { isOk: false, warnings, required_receipt };
        }
        return { isOk: true, warnings, required_receipt };
      },
      presentReadOnly: (warnings) => {
        setReadOnly(true);
        setAppliedPolicies(
          warnings.map((warn, idx) => ({
            id: (warn?.message || "") + idx.toString(),
            actions: {
              required_receipt: false,
              warning: {
                message: warn?.message,
                type: warn?.type || null,
              },
            },
          }))
        );
        present();
      },
    }));

    const handleCreate = (event) => {
      dismiss();
      onCreate(event, true);
    };

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));

    const isExpensePolicy = useMemo(
      () => appliedPolicies?.every((pol) => !pol?.actions?.warning?.type),
      [appliedPolicies]
    );

    return (
      <Dialog
        fullScreen={isSmall}
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={dismiss}
        PaperProps={{ sx: { position: "relative" } }}
      >
        <IconButton
          sx={{ position: "absolute", top: 6, right: 10 }}
          onClick={dismiss}
        >
          <Close />
        </IconButton>
        <Box
          p={2}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <WarningOutlined color="warning" sx={{ mb: 2, fontSize: "2.5rem" }} />
          <Typography
            mb={0.5}
            variant="h5"
            fontWeight={"500"}
            textAlign={"center"}
          >
            {isExpensePolicy
              ? "Políticas de despesa aplicadas"
              : "Despesa com alertas"}
          </Typography>
          <Typography
            variant="body2"
            color={"text.secondary"}
            textAlign={"center"}
          >
            As configurações da despesa podem ir contra as regras configuradas
            pela sua empresa, verifique as mensagens de avisos.
          </Typography>
        </Box>
        <Divider />
        <DialogContent>
          <List disablePadding>
            {requiredReceipt && showRequiredReceipt && (
              <RuleItem
                message={
                  "É necessário inserir pelo menos um comprovante nesta despesa para enviá-la para aprovação"
                }
                type={"required_receipt"}
              />
            )}
            {appliedPolicies.map((police) => (
              <RuleItem
                key={police.id}
                type={police?.actions?.warning?.type}
                message={
                  police?.actions?.warning?.message ||
                  "Mensagem de alerta não definida"
                }
              />
            ))}
          </List>
        </DialogContent>
        {!readOnly && (
          <>
            <Divider />
            <Typography mt={2} fontWeight={"500"} textAlign={"center"} mx={2}>
              {isSave
                ? `Deseja salvar ${
                    isRoute ? "o percurso" : "a despesa"
                  } mesmo assim?`
                : `Deseja criar ${
                    isRoute ? "o percurso" : "a despesa"
                  } mesmo assim?`}
            </Typography>
            <Typography variant="body2" textAlign={"center"} m={2} mt={0}>
              Você poderá editá-la ou inserir comprovantes posteriormente
            </Typography>
            <DialogActions>
              <Button onClick={dismiss}>Cancelar</Button>
              <Button
                onClick={handleCreate}
                variant="contained"
                disableElevation
              >
                {isSave
                  ? isRoute
                    ? "Salvar percurso"
                    : "Salvar despesa"
                  : isRoute
                  ? "Criar percurso"
                  : "Criar despesa"}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }
);
export default memo(ExpensePolicyLayer);

import { ChevronLeft, ChevronRight, Close } from "@mui/icons-material";
import { Box, Divider, IconButton } from "@mui/material";
import React, { memo } from "react";
import ToggleRejection from "./ToggleRejection";

const ViewerHeader = ({
  scrolled,
  activeIndex = 0,
  total = 0,
  onClose,
  rejected,
  toggleRejectExpense,
  onPrev,
  onNext,
}) => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      zIndex={200}
      sx={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
      boxShadow={scrolled && 3}
      p={1.1}
      py={0.5}
      px={2}
    >
      <ToggleRejection
        color="error"
        selected={rejected}
        onChange={toggleRejectExpense}
      />
      <Box flex={1} />
      <IconButton onClick={onPrev}>
        <ChevronLeft />
      </IconButton>
      <IconButton onClick={onNext}>
        <ChevronRight />
      </IconButton>
      <Divider sx={{ height: 20, mx: 2 }} orientation="vertical" />
      <IconButton onClick={onClose}>
        <Close />
      </IconButton>
    </Box>
  );
};

export default memo(ViewerHeader);

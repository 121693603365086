import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { isWithinInterval, subDays } from "date-fns";
import { HttpClient } from "../../api/httpClient";
import { BASE_URL } from "../../api/urls";
import WSMessenger from "../../api/websockets/messenger";
import DialogAlert from "../../classes/DialogAlert";
import normalize from "../../utils/normalize";
import { getRequestLimitsAlerts } from "../../utils/requestLimits";
import { store } from "../store";
import { setError } from "./base/errorBaseSlice";
import { openSnackbar } from "./base/snackbarBaseSlice";

const basename = "requests";

const adapter = createEntityAdapter({
  selectId: (request) => request.id,
  sortComparer: (a, b) => b.created_at?.localeCompare(a.created_at),
});

const initialState = adapter.getInitialState({
  status: "idle",
  working: false,
  visitedStatus: "all",
});

export const fetchRequests = createAsyncThunk(
  `${basename}/fetchRequests`,
  async ({ skip = 0, status, limit, resync = false }) => {
    const res = await HttpClient.get(
      "/requests",
      {
        skip,
        limit: limit || 50,
        status,
      },
      true
    );
    return res.data;
  }
);
export const fetchRequestDetails = createAsyncThunk(
  `${basename}/fetchRequestDetails`,
  async (requestId, thunkAPI) => {
    const res = await HttpClient.get(
      `/requests/${requestId}`,
      {},
      true,
      false,
      {
        signal: thunkAPI.signal,
      }
    );
    return res.data;
  }
);

{
  /* MATHEUS */
}
export const exportRequestToExcel = async (requestId, isApprover, userId) => {
  const state = store.getState();

  const filename =
    state[isApprover ? "approvalRequests" : "requests"]?.entities[requestId]
      ?.title || "report" + ".xls";

  const user_id = userId || state?.account?.baseInfo?.userId;

  try {
    const {
      data: { file_url },
    } = await HttpClient.post(`/requests/${requestId}/export/xls`, {
      filename,
      user_id,
      is_approver: Boolean(isApprover),
      download: true,
    });
    const URL = BASE_URL + file_url;
    const link = document.createElement("a");
    link.download = filename;
    link.href = URL;
    link.target = "_blank";
    link.click();
    store.dispatch(
      openSnackbar({
        message: "Transferido para downloads",
      })
    );
  } catch (error) {
    store.dispatch(
      setError({
        title: "Erro ao exportar Excel",
        message: error?.response?.data?.message,
      })
    );
  }
};

//Offline actions
export const ADD_REQUEST = `${basename}/addRequest`;
export const ADD_REQUEST_ROLLBACK = `${basename}/addRequestRollback`;
export const ADD_REQUEST_COMMIT = `${basename}/addRequestCommit`;

export const UPDATE_REQUEST = `${basename}/updateRequest`;
export const UPDATE_REQUEST_ROLLBACK = `${basename}/updateRequestRollback`;
export const UPDATE_REQUEST_COMMIT = `${basename}/updateRequestCommit`;

export const REMOVE_REQUEST = `${basename}/removeRequest`;
export const REMOVE_REQUEST_ROLLBACK = `${basename}/removeRequestRollback`;
export const REMOVE_REQUEST_COMMIT = `${basename}/removeRequestCommit`;

function removeNullKeys(obj) {
  for (let key in obj) {
    if (obj[key] === null) {
      delete obj[key];
    }
  }
  return obj;
}

const customFieldsRequestFormatter = (fields = {}, prevCustomFields = {}) => {
  console.log(fields, prevCustomFields);

  if (!fields) return null;
  const allEntities = store.getState()?.customFields?.entities;
  if (!allEntities) return null;
  let result = {};
  Object.keys(allEntities)?.forEach((fieldId) => {
    const storeField = allEntities[fieldId];

    if (fields[fieldId]) {
      result[fieldId] = {
        value: fields[fieldId],
        label: storeField?.label,
        text:
          storeField?.inputType?.category === "text"
            ? fields[fieldId]
            : storeField?.inputType?.options?.find(
                (op) => op?.id === fields[fieldId]
              )?.text,
      };
    } else {
      result[fieldId] = null;
    }
  });

  return removeNullKeys({ ...prevCustomFields, ...result });
};

const addRequest = createAction(ADD_REQUEST, (requestData) => {
  return {
    payload: {
      ...requestData,
      custom_fields: customFieldsRequestFormatter(requestData?.custom_fields),
    },
    meta: {
      offline: {
        effect: {
          scope: "requests",
          action: "add",
          json: requestData,
        },
        rollback: {
          type: ADD_REQUEST_ROLLBACK,
          meta: { id: requestData.id, title: requestData?.title },
        },
        commit: {
          type: ADD_REQUEST_COMMIT,
          meta: requestData,
        },
      },
    },
  };
});

const updateRequest = createAction(UPDATE_REQUEST, (requestId, changes) => {
  const { title, obs, custom_fields } =
    store.getState()?.requests.entities[requestId];

  return {
    payload: {
      requestId,
      changes: {
        ...changes,
        custom_fields: customFieldsRequestFormatter(
          changes?.custom_fields,
          custom_fields
        ),
      },
    },
    meta: {
      offline: {
        effect: {
          scope: "requests",
          action: "update",
          json: { requestId, changes },
        },
        rollback: {
          type: UPDATE_REQUEST_ROLLBACK,
          meta: { requestId, changes, prev: { title, obs, custom_fields } },
        },
        commit: {
          type: UPDATE_REQUEST_COMMIT,
          meta: {
            requestId,
            changes,
          },
        },
      },
    },
  };
});

const removeRequest = createAction(REMOVE_REQUEST, (requestId) => {
  return {
    payload: { requestId },
    meta: {
      offline: {
        effect: {
          scope: "requests",
          action: "remove",
          json: requestId,
        },
        rollback: {
          type: REMOVE_REQUEST_ROLLBACK,
          meta: { requestId },
        },
        commit: {
          type: REMOVE_REQUEST_COMMIT,
          meta: { requestId },
        },
      },
    },
  };
});

export const requestsSlice = createSlice({
  name: basename,
  initialState,
  reducers: {
    setupRequests(state, { payload }) {
      const { entities } = normalize(payload);
      adapter.setAll(state, entities);
    },
    loadRequests(state, { payload }) {
      const { ids, entities } = payload;
      const requests = ids.map((id) => entities[id]);
      adapter.setMany(state, requests);
    },

    //visited filter
    setRequestsVistedStatus(state, { payload }) {
      state.visitedStatus = payload;
    },

    //OFFLINE ADD REQUESTS
    addRequest(state, { payload }) {
      adapter.addOne(state, {
        ...payload,
        processingStatus: "sync",
        events: [
          {
            timestamp: new Date().toISOString(),
            type: "C",
          },
        ],
      });
    },
    addRequestCommit(state, { meta, payload }) {
      delete payload.created_at;
      adapter.updateOne(state, {
        id: meta.id,
        changes: {
          ...payload,
          processingStatus: null,
        },
      });
      WSMessenger.applyMessage();
    },
    addRequestRollback(state, { meta, payload }) {
      adapter.removeOne(state, meta.id);
      DialogAlert.show({
        title: "Erro ao criar relatório",
        message: payload?.message,
        isError: true,
      });
    },

    //OFFLINE UPDATE REQUESTS
    updateRequest(state, { payload }) {
      const { requestId, changes } = payload;
      adapter.updateOne(state, {
        id: requestId,
        changes,
      });
    },
    updateRequestCommit(state, { payload, meta }) {
      adapter.updateOne(state, {
        id: meta?.requestId,
        changes: payload,
      });
      WSMessenger.applyMessage();
    },
    updateRequestRollback(state, { meta, payload }) {
      DialogAlert.show({
        title: "Erro ao salvar relatório",
        message: payload?.message,
        isError: true,
      });
      const { requestId, prev } = meta;
      adapter.updateOne(state, {
        id: requestId,
        changes: prev || {},
      });
    },

    //OFFLINE REMOVE REQUESTS
    removeRequest(state, { payload }) {
      adapter.updateOne(state, {
        id: payload.requestId,
        changes: {
          hidden: true,
        },
      });
    },
    removeRequestCommit(state, { meta: { requestId } }) {
      adapter.removeOne(state, requestId);
      WSMessenger.sendMessage();
    },
    removeRequestRollback(state, { meta: { requestId }, payload }) {
      DialogAlert.show({
        title: "Erro ao excluir relatório",
        message: payload?.message,
        isError: true,
      });
      adapter.updateOne(state, {
        id: requestId,
        changes: {
          hidden: false,
        },
      });
    },

    //SEND TO APPROVAL
    sendToApproval(state, { payload }) {
      const { requestId, responseData } = payload;
      adapter.updateOne(state, {
        id: requestId,
        changes: {
          ...responseData,
          status: "P",
          rejected_expenses: [],
        },
      });
      WSMessenger.sendMessage();
    },

    //REOPEN
    reopen(state, { payload }) {
      const { requestId, responseData } = payload;
      adapter.updateOne(state, {
        id: requestId,
        changes: {
          ...responseData,
          status: "O",
          rejected_expenses: [],
        },
      });
      WSMessenger.sendMessage();
    },

    //ADVANCE REQUEST
    advanceRequest(state, { payload }) {
      const { requestId, responseData } = payload;
      adapter.updateOne(state, {
        id: requestId,
        changes: {
          ...responseData,
          status: "AD",
        },
      });
      WSMessenger.sendMessage();
    },

    //UTILS
    updateRequestStore: (state, { payload }) => {
      const { requestId, changes } = payload;
      adapter.updateOne(state, {
        id: requestId,
        changes,
      });
    },
  },
  extraReducers: (builder) => {
    builder
      //FETCH REQUESTS
      .addCase(fetchRequests.pending, (state, action) => {
        state.working = true;
      })
      .addCase(fetchRequests.fulfilled, (state, { payload, meta: { arg } }) => {
        const { resync } = arg;
        const requestsApi = payload || [];
        const { ids: idsAPI, entities: entitiesAPI } = normalize(requestsApi);

        if (resync) {
          const allIds = [...new Set([...state.ids, ...idsAPI])];
          let entities = { ...state.entities, ...entitiesAPI };
          for (const requestId of allIds) {
            if (
              !idsAPI.includes(requestId) &&
              !entities[requestId]?.processingStatus
            ) {
              delete entities[requestId];
            } else {
              if (entities[requestId]?.processingStatus) {
                delete entitiesAPI[requestId].limits;
                entities[requestId] = {
                  ...entitiesAPI[requestId],
                  ...entities[requestId],
                };
              } else {
                entities[requestId] = {
                  ...entities[requestId],
                  ...entitiesAPI[requestId],
                };
              }
              //Manter limits no list sync
              if (state.entities[requestId]?.limits) {
                entities[requestId].limits = state.entities[requestId]?.limits;
              }
            }
          }
          adapter.setAll(state, entities);
        } else {
          adapter.setMany(state, entitiesAPI);
        }
        state.working = false;
        state.status = "succeeded";
      })
      .addCase(fetchRequests.rejected, (state, action) => {
        state.working = false;
      })

      //detail single
      .addCase(fetchRequestDetails.pending, (state, { meta: { arg } }) => {
        state.entities[arg].fetching = true;
      })
      .addCase(fetchRequestDetails.rejected, (state, { meta: { arg } }) => {
        state.entities[arg].fetching = false;
      })
      .addCase(
        fetchRequestDetails.fulfilled,
        (state, { meta: { arg }, payload }) => {
          state.entities[arg] = {
            ...state.entities[arg],
            fetching: false,
            ...payload,
            limits: payload?.limits,
          };
        }
      );
  },
});

export const {
  reopen,
  setupRequests,
  sendToApproval,
  setRequestsVistedStatus,
  updateRequestStore,
  advanceRequest,
} = requestsSlice.actions;

export const {
  selectAll,
  selectById: selectRequestById,
  selectIds,
  selectEntities: selectRequestsEntities,
} = adapter.getSelectors((state) => state.requests);

export const requestsOfflineActions = {
  addRequest,
  updateRequest,
  removeRequest,
};

export default requestsSlice.reducer;

export const selectAllRequests = createSelector([selectAll], (requests) => {
  return requests.filter((req) => !req.hidden);
});

export const selectRequestsIds = createSelector(
  [selectAllRequests],
  (requests) => {
    return requests.map((req) => req.id);
  }
);

export const selectRequestsIdsByStatus = createSelector(
  [selectAllRequests, (state, status) => status],
  (requests, status) => {
    return requests
      .filter((request) =>
        status === "all" ? true : request.status === status
      )
      .map((request) => request.id);
  }
);

export const selectOpenedRequestsIds = createSelector(
  [selectRequestsIds, selectRequestsEntities],
  (ids, entities) => ids.filter((id) => entities[id].status === "O")
);

export const selectRequestLimitsAlerts = createSelector(
  [
    selectRequestById,
    (state, requestId, expenses) => expenses,
    (state, requestId) => state?.account?.currency,
  ],
  (request = {}, expenses = [], currency) => {
    if (["P", "O"].includes(request?.status)) {
      return getRequestLimitsAlerts({
        currency,
        expenses,
        limits: request?.limits,
      });
    } else return [];
  }
);

export const selectRecentRequestsIds = createSelector(
  [selectRequestsIds, selectRequestsEntities],
  (ids, entities) => {
    const limitDate = subDays(new Date(), 5);
    return ids
      ?.filter((id) =>
        isWithinInterval(new Date(entities[id]?.created_at), {
          start: limitDate,
          end: new Date(),
        })
      )
      .splice(0, 6);
  }
);

export const selectRequestsVisitedStatus = (state) =>
  state.requests.visitedStatus;

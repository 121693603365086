import { AssignmentOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  LinearProgress,
  MenuItem,
  Popper,
  TextField,
  createFilterOptions,
} from "@mui/material";
import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openElement } from "../../../../store/features/portalSlice";
import {
  fetchRequests,
  selectOpenedRequestsIds,
  selectRequestById,
  selectRequestsEntities,
} from "../../../../store/features/requestsSlice";
import { includesOnSearch } from "../../../../utils/more/search";
import SelectorBox from "../SelectorBox";

const filter = createFilterOptions();

const RequestItem = memo(({ option, selected, ...props }) => {
  const data = useSelector((state) => selectRequestById(state, option));
  return (
    <MenuItem
      {...props}
      sx={{ height: 50, fontWeight: "600" }}
      selected={selected}
    >
      {option?.newAction ? (
        <>
          <AssignmentOutlined fontSize="small" color="primary" sx={{ mr: 2 }} />
          {option?.title}
        </>
      ) : (
        <>
          <AssignmentOutlined fontSize="small" color="action" sx={{ mr: 2 }} />
          {data?.title}
        </>
      )}
    </MenuItem>
  );
});

function RequestInput({ value, onChange, readOnly, size }) {
  const dispatch = useDispatch();

  const working = useSelector((state) => state.requests.working);
  const ids = useSelector(selectOpenedRequestsIds);
  const entities = useSelector(selectRequestsEntities);

  const renderOption = useCallback((props, option, state) => {
    return <RequestItem option={option} selected={state.selected} {...props} />;
  }, []);

  const handleChange = (event, v) => {
    if (typeof v === "string") {
      onChange(v || "");
    } else if (v && v.newAction) {
      // Create a new value from the user input
      dispatch(
        openElement({
          name: "newRequest",
          util: {
            title: v.inputValue,
            onCreate: (id) => onChange(id || ""),
            disableAdvance: true,
          },
        })
      );
    } else {
      onChange(v || "");
    }
  };

  const filterOptions = useCallback(
    (options, params) => {
      const filtered = filter(options, params);

      const { inputValue } = params;
      // Suggest the creation of a new value
      const isExisting = options.some((option) =>
        includesOnSearch(inputValue, [entities[option]?.title])
      );
      if (inputValue !== "" && !isExisting) {
        filtered.push({
          newAction: true,
          inputValue,
          title: `Criar "${inputValue}"`,
        });
      }
      return filtered;
    },
    [entities]
  );

  const getOptionLabel = useCallback(
    (option) => {
      // Value selected with enter, right from the input
      if (typeof option === "string") {
        return entities[option]?.title;
      }
      // Add "xxx" option created dynamically
      if (option.newAction) {
        return option.inputValue;
      }
      // Regular option
      return entities[option]?.title;
    },
    [entities]
  );

  const loadMoreRequests = () => {
    if (working) return;
    dispatch(
      fetchRequests({
        status: "O",
        skip: ids.length,
        limit: 50,
      })
    );
  };

  const handleListboxScroll = useCallback((event) => {
    const listboxNode = event.currentTarget;
    if (
      listboxNode.scrollTop + listboxNode.clientHeight ===
      listboxNode.scrollHeight
    ) {
      loadMoreRequests();
    }
  }, []);

  const renderPopper = useCallback(
    (params) => {
      return (
        <Popper {...params} placement="top" sx={{ boxShadow: 6 }}>
          {params.children}
          <LinearProgress
            sx={{ transition: ".2s ease", height: working ? 4 : 0 }}
          />
        </Popper>
      );
    },
    [working]
  );

  return (
    <SelectorBox Icon={AssignmentOutlined}>
      <Autocomplete
        ListboxProps={{
          onScroll: handleListboxScroll,
        }}
        PopperComponent={renderPopper}
        fullWidth
        autoHighlight
        readOnly={readOnly}
        options={ids}
        size={size || "small"}
        value={value || null}
        onChange={handleChange}
        filterOptions={filterOptions}
        selectOnFocus
        clearOnBlur
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        renderInput={(params) => (
          <TextField
            {...params}
            label={"Adicionar ao relatório"}
            fullWidth
            variant="outlined"
            name="to_request"
          />
        )}
      />
    </SelectorBox>
  );
}

export default memo(RequestInput);

import { FeedbackOutlined } from "@mui/icons-material";
import { Box, Button, Link, Typography } from "@mui/material";
import { memo } from "react";
import AppleStoreIcon from "../../../../assets/icons/apple-store-icon.png";
import GoogleStoreIcon from "../../../../assets/icons/google-play-icon.png";

export default memo(() => {
  return (
    <Box
      borderRadius={2}
      mt={"100px"}
      boxShadow={"inset 0px 0px 5px rgba(0,0,0,.15)"}
      bgcolor={"elevation1.main"}
      position={"relative"}
      display={"flex"}
      p={4}
      mb={5}
      alignItems={"flex-start"}
      justifyContent={"space-around"}
      flexWrap={"wrap"}
    >
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Typography noWrap textAlign={"center"} fontWeight={"500"} variant="h5">
          Acesse nosso aplicativo nas lojas
        </Typography>
        <Box gap={2} mt={-4} display={"flex"} alignItems={"center"}>
          <img width={"130px"} src={GoogleStoreIcon} />
          <img width={"130px"} src={AppleStoreIcon} />
        </Box>
      </Box>
      <Box
        mb={3}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Typography noWrap textAlign={"center"} fontWeight={"500"} variant="h5">
          Nos ajude a melhorar!
        </Typography>
        <Button
          LinkComponent={Link}
          href="https://docs.google.com/forms/d/e/1FAIpQLSfOIaKZFp0kI5uBTaJ2vvOIdnjO_nItTxNtxUV9wIoe63dF9w/viewform?usp=sf_link"
          target="_blank"
          variant="outlined"
          startIcon={<FeedbackOutlined />}
          sx={{ borderRadius: 100, mt: 2 }}
        >
          Enviar feedback
        </Button>
      </Box>
      <Typography variant="caption" sx={{ position: "absolute", bottom: 20 }}>
        © Notepay - {new Date().getFullYear()}
      </Typography>
    </Box>
  );
});

import { RunningWithErrors } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectAccountCurrency } from "../../../../../../store/features/accountSlice";
import { selectExpenseTypesEntities } from "../../../../../../store/features/configs/expenseTypesSlice";
import formatAmount from "../../../../../../utils/formatAmount";
import { curr } from "../../../../../../utils/more/currency_country";
import PaperSection from "./PaperSection";

const periodText = {
  daily: "dia",
  monthly: "mês",
};

const LimitsSection = ({ limits = {} }) => {
  const currency = useSelector(selectAccountCurrency);
  const categoriesEntities = useSelector(selectExpenseTypesEntities);

  const expenseTypesIds = useMemo(
    () => Object.keys(limits?.expensesTypes || {}),
    [limits]
  );

  return (
    <PaperSection
      title={`Limites (${expenseTypesIds?.length})`}
      Icon={RunningWithErrors}
    >
      {expenseTypesIds?.map((typeId) => {
        const category = categoriesEntities[typeId];
        const periods = Object.keys(limits?.expensesTypes[typeId]);
        return (
          <Stack
            key={typeId}
            direction={"row"}
            alignItems={periods?.length == 1 ? "flex-start" : "center"}
            gap={1}
            py={1}
            borderTop={1}
            borderColor="divider"
          >
            <Box display={"flex"} alignItems={"center"} gap={1.5}>
              <Typography fontWeight={"500"}>{category?.name}</Typography>
            </Box>
            <Box flex={1} />
            <Box>
              {periods?.map((period) => (
                <Typography
                  justifyContent={"flex-end"}
                  fontSize={".9rem"}
                  key={period}
                  fontWeight={"400"}
                  component={"div"}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <Typography
                    component={"span"}
                    variant="inherit"
                    fontWeight={"600"}
                  >
                    {curr(currency)}{" "}
                    {formatAmount(limits?.expensesTypes[typeId][period] || 0)}
                  </Typography>{" "}
                  <Typography
                    textAlign={"right"}
                    variant="inherit"
                    width={55}
                    color={"text.secondary"}
                  >
                    / {periodText[period]}
                  </Typography>
                </Typography>
              ))}
            </Box>
          </Stack>
        );
      })}
    </PaperSection>
  );
};

export default memo(LimitsSection);

import { Box, LinearProgress } from "@mui/material";
import React from "react";

const Fallback = () => {
  return (
    <Box
      bgcolor={(t) => `${t.palette.background.paper}30`}
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: (t) => t.zIndex.appBar + 31,
        height: "100vh",
        width: "100vw",
      }}
    >
      <LinearProgress
        sx={{
          width: "100%",
          height: 3,
        }}
      />
    </Box>
  );
};

export default Fallback;

import { Circle } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectAccountCurrency } from "../../../../store/features/accountSlice";
import formatAmount from "../../../../utils/formatAmount";

const AmountSection = ({ totalAmount, totalExpenses, requestId }) => {
  const navigate = useNavigate();
  const accountCurrency = useSelector((s) => selectAccountCurrency(s, true));
  return (
    <Box display={"flex"} alignItems={"center"}>
      <Typography fontSize={".875rem"} noWrap fontWeight={"600"}>
        {accountCurrency} {formatAmount(totalAmount)}
      </Typography>
      <Circle color="disabled" sx={{ mx: 0.8, fontSize: ".3rem" }} />
      <Typography
        onClick={(e) => {
          e.stopPropagation();
          navigate(`/approval-requests/${requestId}?tab=1`);
        }}
        sx={{ ":hover": { textDecoration: "underline" } }}
        noWrap
        color={"text.secondary"}
        fontSize={".825rem"}
        fontWeight={"500"}
      >
        {totalExpenses} despesa(s)
      </Typography>
    </Box>
  );
};

export default memo(AmountSection);

import { OpenInNewOutlined } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import React, { memo } from "react";
import RemoveReceipt from "./RemoveReceipt";

const ReceiptHeader = ({ settings, filename, onRemove, openInNewWindow }) => {
  return (
    <Box
      display={"flex"}
      alignItems="center"
      bgcolor={"rgb(0,0,0,.7)"}
      position={"absolute"}
      width="100%"
      zIndex={3}
      top={0}
      py={0.5}
      px={2}
    >
      <Tooltip title={filename}>
        <Typography
          component={"div"}
          onClick={openInNewWindow}
          sx={{ cursor: "pointer", ":hover": { textDecoration: "underline" } }}
          noWrap
          fontWeight={"500"}
          variant="body2"
          color={"white"}
        >
          {filename}
        </Typography>
      </Tooltip>
      <Box flex={1} />
      <Tooltip title="Abrir em outra aba">
        <IconButton
          onClick={openInNewWindow}
          size="small"
          sx={{ color: "white" }}
        >
          <OpenInNewOutlined fontSize="small" />
        </IconButton>
      </Tooltip>
      {settings.edit && (
        <RemoveReceipt onRemove={onRemove} filename={filename} />
      )}
    </Box>
  );
};

export default memo(ReceiptHeader);

import {
  AssignmentOutlined,
  CurrencyExchangeOutlined,
  DirectionsOutlined,
  NotesOutlined,
  PersonPinCircleOutlined,
  PostAddOutlined,
  TodayOutlined,
} from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@mui/material";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectAccountCurrency } from "../../../../../store/features/accountSlice";
import { selectRoutePolicieById } from "../../../../../store/features/configs/routePoliciesSlice";
import { selectSnippetsEntities } from "../../../../../store/features/configs/snippetsSlice";
import { curr } from "../../../../../utils/more/currency_country";
import {
  formatDate,
  formatMoment,
} from "../../../../../utils/more/date_functions";
import { CurrencyFormat } from "../../../../../utils/more/mask_functions";
import Baseline from "../../../expense/contents/view/components/Baseline";

function ViewMode({ expense, callback, showRequest }) {
  const {
    amount,
    currency,
    account_currency,
    notes,
    date,
    rate,
    route_policy_id,
    route_policy_name,
    refundable,
    project_name,
    request_id,
    distance,
    snippet_id,
    request_title,
    sync,
    sync_error,
    created_at,
  } = expense || {};

  const navigate = useNavigate();

  const accountCurrency = useSelector((state) =>
    selectAccountCurrency(state, true)
  );
  const snippetsEntities = useSelector(selectSnippetsEntities);

  const routePolicy = useSelector((state) =>
    selectRoutePolicieById(state, route_policy_id)
  );

  return (
    <Box display={"flex"} flexDirection={"column"} gap={3}>
      <Box>
        <Box display={"flex"} alignItems={"flex-start"}>
          <Box>
            <Typography fontWeight={"500"} fontSize={"2rem"} variant="h4">
              {curr(currency)} <CurrencyFormat value={amount || 0} />
            </Typography>
            <Typography
              mt={1}
              color={"text.secondary"}
              fontSize={"1.2rem"}
              variant="h4"
            >
              {parseFloat(distance || 0).toFixed(2)} km
            </Typography>
          </Box>
          <Box flex={1} />
          <Box mt={1} ml={1} display={"flex"} alignItems={"center"}>
            <CurrencyExchangeOutlined
              fontSize="small"
              color={refundable ? "primary" : "disabled"}
              sx={{ mr: 1 }}
            />
            <Typography variant="body2">
              {refundable ? "Reembolsável" : "Não reembolsável"}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ mx: -2, my: -0.5 }} />
      <Baseline
        Icon={DirectionsOutlined}
        label={"Trecho pré-cadastrado"}
        emptyLabel={"Nenhum trecho selecionado"}
        value={snippetsEntities[snippet_id]?.description || ""}
      />
      <Baseline
        Icon={PersonPinCircleOutlined}
        value={
          <Typography component={"span"}>
            {route_policy_name || routePolicy?.name || ""} - {accountCurrency}{" "}
            <CurrencyFormat value={rate} /> /km
          </Typography>
        }
        label={"Política de percurso"}
        emptyLabel="Sem política aplicada"
      />
      <Baseline
        Icon={TodayOutlined}
        label={"Data do percurso"}
        value={formatDate(date)}
      />
      <Baseline
        Icon={PostAddOutlined}
        value={project_name}
        label={"Projeto"}
        emptyLabel="Sem projeto"
      />
      <Baseline
        Icon={NotesOutlined}
        value={notes || ""}
        label={"Descrição"}
        emptyLabel="Sem descrição"
      />
      {showRequest && (
        <Box width={"100%"} display={"flex"} alignItems={"center"}>
          <Baseline
            Icon={AssignmentOutlined}
            value={request_title}
            label={"Relatório"}
            emptyLabel="Sem relatório"
          />
          {Boolean(request_title) && (
            <Button
              onClick={() => {
                navigate(`/approval-requests/${request_id}`);
                if (callback && typeof callback === "function") {
                  callback();
                }
              }}
              variant="outlined"
              sx={{ ml: "auto" }}
            >
              Ver relatório
            </Button>
          )}
        </Box>
      )}
      <Divider sx={{ mx: -2 }} />
      <Typography variant="body2" color={"text.secondary"}>
        Criado em{" "}
        <Typography
          fontWeight={"500"}
          color={"text.primary"}
          component={"span"}
          variant="body2"
        >
          {formatMoment(created_at || new Date())}
        </Typography>
      </Typography>
    </Box>
  );
}

export default memo(ViewMode);

import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openSnackbar } from "../../store/features/base/snackbarBaseSlice";
import {
  selectCustomFieldsEntities,
  selectCustomFieldsIds,
} from "../../store/features/configs/customFieldsSlice";
import { expensesOfflineActions } from "../../store/features/expensesSlice";
import {
  closeElement,
  selectPortalIsOpened,
} from "../../store/features/portalSlice";
import {
  requestsOfflineActions,
  setRequestsVistedStatus,
} from "../../store/features/requestsSlice";
import customNanoid from "../../utils/customNanoid";
import AdvanceForm from "./components/AdvanceForm";
import CustomFieldsForm from "./components/CustomFieldsForm";
import RequestForm from "./components/RequestForm";

function ModalNewRequest(props) {
  const dispatch = useDispatch();

  const open = useSelector((state) =>
    selectPortalIsOpened(state, "newRequest")
  );

  const [content, setContent] = useState(undefined);

  const scrolled = useScrollTrigger({
    target: content,
    disableHysteresis: true,
    threshold: 0,
  });

  const onClose = useCallback(() => dispatch(closeElement("newRequest")), []);

  const utils = useSelector((state) => state.portal.newRequest.util);

  const disableAdvance = useMemo(() => {
    return Boolean(utils?.disableAdvance);
  }, [utils]);

  const selectedOnOpen = useMemo(() => {
    return utils && utils?.selectedExpenses ? utils?.selectedExpenses : null;
  }, [utils]);

  const initialTitle = useMemo(() => {
    return utils && utils.title ? utils?.title : "";
  }, [utils]);

  const onCreateCallback = utils?.onCreate;

  const navigate = useNavigate();

  const customFieldsIds = useSelector(selectCustomFieldsIds);
  const customFieldsEntities = useSelector(selectCustomFieldsEntities);

  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(initialTitle);
  const [obs, setObs] = useState("");
  const [advance, setAdvance] = useState({
    active: false,
    amount: "",
    reason: "",
  });

  const initialCustomFieldsValues = useMemo(() => {
    let values = {};

    customFieldsIds?.forEach((fieldId) => {
      values[fieldId] = "";
    });

    return values;
  }, [customFieldsIds]);

  const [customFieldsValues, setCustomFieldsValues] = useState(
    customFieldsIds?.length ? initialCustomFieldsValues : null
  );

  useEffect(() => {
    if (!open) {
      setTitle("");
      setObs("");
      setAdvance({
        active: false,
        amount: "",
        reason: "",
      });
      setCustomFieldsValues(initialCustomFieldsValues);
    } else {
      setTitle(initialTitle);
    }
  }, [open]);

  const requiredCustomFieldsIsOk = useMemo(() => {
    if (!customFieldsValues) return true;
    return customFieldsIds
      ?.filter((fieldId) => customFieldsEntities[fieldId]?.isRequired)
      ?.every((fieldId) => Boolean(customFieldsValues[fieldId]));
  }, [customFieldsIds, customFieldsEntities, customFieldsValues]);

  const isOk = useMemo(
    () =>
      requiredCustomFieldsIsOk &&
      Boolean(title.trim()) &&
      (advance.active ? Boolean(advance.amount) : true),
    [title, advance, requiredCustomFieldsIsOk]
  );

  const handleChangeAdvance = useCallback((prop, v) => {
    setAdvance((prev) => ({ ...prev, [prop]: v }));
  }, []);

  const handleChangeCustomFieldValue = useCallback((fieldId, value) => {
    setCustomFieldsValues((prev) => ({ ...prev, [fieldId]: value }));
  }, []);

  const handleAdd = async () => {
    if (!isOk) return;
    dispatch(setRequestsVistedStatus("all"));
    const requestId = customNanoid(5);

    const requestEntity = {
      id: requestId,
      obs,
      title,
      created_at: new Date().toISOString(),
      status: advance.active ? "AD" : "O",
      total_amount: 0,
      total_refundable: 0,
      advance: advance.active
        ? {
            reason: advance.reason,
            amount: advance.amount,
          }
        : null,

      custom_fields: customFieldsValues || undefined,
    };
    dispatch(requestsOfflineActions.addRequest(requestEntity));
    if (selectedOnOpen) {
      dispatch(expensesOfflineActions.addToRequest(selectedOnOpen, requestId));
    }
    if (onCreateCallback && typeof onCreateCallback === "function") {
      onCreateCallback(requestId);
    }
    dispatch(
      openSnackbar({
        message: "Relatório criado",
        action: () => navigate(`/approval-requests/${requestId}`),
        actionText: initialTitle ? null : "Ver relatório",
      })
    );
    onClose();
  };

  return (
    open && (
      <Dialog
        data-disableselect={true}
        open={open}
        fullWidth
        maxWidth="xs"
        PaperProps={{
          sx: { maxWidth: 550, height: { xs: "100%", sm: "auto" } },
        }}
      >
        {loading && <LinearProgress />}
        <DialogTitle
          boxShadow={scrolled ? 3 : 0}
          component={"div"}
          display="flex"
          alignItems={"center"}
        >
          <Typography fontWeight={"500"} fontSize={"1.2rem"} variant="inherit">
            Criar relatório de despesas
          </Typography>
          <Box flex={1} />
          <IconButton onClick={onClose} sx={{ m: -1 }}>
            <Close sx={{ fontSize: "25px" }} />
          </IconButton>
        </DialogTitle>
        <DialogContentText
          color={"primary"}
          fontWeight={"500"}
          fontSize={".9rem"}
          mx={3}
        >
          {Boolean(selectedOnOpen) &&
            `${selectedOnOpen.length} despesa(s) selecionada(s)`}
        </DialogContentText>
        <DialogContent
          ref={(node) => node && setContent(node)}
          sx={{ pt: 0, pb: 7 }}
        >
          <RequestForm
            title={title}
            obs={obs}
            setObs={setObs}
            setTitle={setTitle}
          />
          {customFieldsValues && (
            <CustomFieldsForm
              values={customFieldsValues}
              onChangeValue={handleChangeCustomFieldValue}
            />
          )}
          {!Boolean(selectedOnOpen) && !disableAdvance && (
            <AdvanceForm advance={advance} onChange={handleChangeAdvance} />
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
          <Button
            disabled={!isOk || loading}
            variant="contained"
            onClick={handleAdd}
            disableElevation
            autoFocus
          >
            {loading ? "Criando" : "Criar"}
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
}

export default memo(ModalNewRequest);

import { TableCell, Typography } from "@mui/material";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { selectProjectById } from "../../../../store/features/configs/projectsSlice";

const ProjectCell = ({ projectId }) => {
  const project = useSelector((state) => selectProjectById(state, projectId));
  return (
    <TableCell>
      <Typography variant="inherit" noWrap>
        {project?.title || "--"}
      </Typography>
    </TableCell>
  );
};

export default memo(ProjectCell);

import { Box } from "@mui/material";
import React from "react";

function SelectorBox({ Icon = () => {}, children, sx, iconSx, disabled }) {
  return (
    <Box sx={sx} display={"flex"} alignItems="center" position={"relative"}>
      {!disabled && (
        <Icon
          color="action"
          sx={{ fontSize: "1.4rem", mr: ".8em", ml: 1, ...iconSx }}
          fontSize="small"
        />
      )}
      {children}
    </Box>
  );
}

export default SelectorBox;

import { AvTimerOutlined } from "@mui/icons-material";
import { TextField, Typography } from "@mui/material";
import React, { memo } from "react";
import formatAmount from "../../../../utils/formatAmount";
import { CurrencyInputMask } from "../../../../utils/more/mask_functions";
import SelectorBox from "../SelectorBox";

function DistanceInput({
  value = "",
  readOnly,
  disabled,
  onChange,
  error,
  variant,
  size
}) {
  return (
    <SelectorBox iconSx={{ mt: readOnly ? 2 : 0 }} Icon={AvTimerOutlined}>
      {readOnly ? (
        <Typography mt={2} fontSize={"1.2rem"} fontWeight={"500"}>
          {formatAmount(value)} km
        </Typography>
      ) : (
        <TextField
          size={size || "small"}
          label={"Quilometragem (KM)"}
          fullWidth
          variant="outlined"
          name="distance"
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          InputProps={{
            readOnly: disabled,
            inputComponent: CurrencyInputMask,
          }}
          error={Boolean(error)}
          helperText={error || ""}
        />
      )}
    </SelectorBox>
  );
}

export default memo(DistanceInput);

import {
  ExpandLess,
  ExpandMore,
  FilePresentOutlined,
  MoreVertOutlined,
  NotesOutlined,
  PictureAsPdfOutlined,
  WarningOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  Menu,
  Paper,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import DetailLabel from "../../../../components/DetailLabel";
import DefaultMenuItem from "../../../../components/menus/DefaultMenuItem";
import { openElement } from "../../../../store/features/portalSlice";
import { exportRequestToExcel } from "../../../../store/features/requestsSlice";
import formatAmount from "../../../../utils/formatAmount";
import { getProfilePicture } from "../../../../utils/functions/profile";
import {
  formatDate,
  formatMoment,
} from "../../../../utils/more/date_functions";
import AmountSection from "../../../requests/view/contents/overview/sections/AmountSection";
import CustomFieldsSection from "../../../requests/view/contents/overview/sections/CustomFieldsSection";
import LimitsAlerts from "./LimitsAlerts";

const MoreButton = ({ requestId, approverId }) => {
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(null);
  return (
    <>
      <IconButton onClick={(e) => setMenu(e.target)}>
        <MoreVertOutlined />
      </IconButton>
      <Menu
        elevation={1}
        slotProps={{
          paper: {
            sx: {
              boxShadow: 3,
              borderRadius: 1,
            },
          },
        }}
        anchorEl={menu}
        open={Boolean(menu)}
        onClose={() => setMenu(null)}
        anchorOrigin={{
          horizontal: "center",
          vertical: "center",
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
      >
        <DefaultMenuItem
          Icon={PictureAsPdfOutlined}
          onClick={() => {
            dispatch(
              openElement({
                name: "requestExportPDF",
                util: { requestId, isApprover: true, userId: approverId },
              })
            );
          }}
          title="Exportar PDF"
        />
        <DefaultMenuItem
          Icon={FilePresentOutlined}
          onClick={() => {
            exportRequestToExcel(requestId, true, approverId);
          }}
          title="Exportar para Excel"
        />
      </Menu>
    </>
  );
};

const DetailBox = ({ title, children, sx }) => {
  const [expanded, setExpanded] = useState(true);
  return (
    <Paper
      onClick={() => setExpanded(true)}
      variant="outlined"
      sx={{
        p: 2,
        maxWidth: 600,
        position: "relative",
        cursor: expanded ? "default" : "pointer",
        ...sx,
      }}
    >
      <Typography
        color={"text.secondary"}
        variant="h5"
        fontWeight={"500"}
        fontSize={"1.2rem"}
        mb={expanded && 2}
        sx={{ transition: ".2s ease" }}
      >
        {title}
      </Typography>
      <IconButton
        sx={{ position: "absolute", right: 10, top: 10 }}
        onClick={(e) => {
          e.stopPropagation();
          setExpanded(!expanded);
        }}
      >
        {expanded ? <ExpandLess /> : <ExpandMore />}
      </IconButton>
      <Collapse in={expanded}>{children}</Collapse>
    </Paper>
  );
};

const BaseInfo = ({
  sendAt,
  user,
  title,
  obs,
  currency,
  totalAmount,
  totalRefundable,
  customFields,
  isAdvance,
  advance,
  requiredReceipt,
  hasWarning,
  createdAt,
  openHistoryDrawer,
  requestId,
  alerts,
  currentStep,
}) => {
  return (
    <>
      <Box mb={3} display={"flex"} alignItems={"center"} gap={2}>
        <Box flex={1} display={"flex"} alignItems={"center"}>
          <Avatar
            sx={{ border: 1, borderColor: "divider" }}
            src={getProfilePicture(user._id)}
          ></Avatar>
          <Box ml={1}>
            <Typography color={"text.primary"} fontWeight={"500"}>
              <Typography
                component={"span"}
                variant="inherit"
                color={"text.secondary"}
              >
                de:
              </Typography>{" "}
              {user.name} {user.lastname || ""}
            </Typography>
            <Typography variant="body2" color={"text.secondary"}>
              {user.email}
            </Typography>
          </Box>
          <Divider orientation="vertical" sx={{ mx: 2.5, height: 25 }} />
          <Box>
            <Typography color={"text.secondary"} variant="body2">
              Para aprovar na
            </Typography>
            <Box display={"flex"} alignItems={"center"}>
              <Typography fontWeight={"600"}>Etapa {currentStep}</Typography>
            </Box>
          </Box>
          <Divider orientation="vertical" sx={{ mx: 2.5, height: 25 }} />
          <Button
            variant="outlined"
            sx={{ borderRadius: 100 }}
            onClick={openHistoryDrawer}
          >
            Ver histórico do relatório
          </Button>
        </Box>
        <Typography
          variant="body2"
          fontSize={".8rem"}
          fontWeight={"500"}
          color={"text.secondary"}
        >
          Enviado {formatMoment(sendAt)}
        </Typography>
        <MoreButton requestId={requestId} approverId={user?._id} />
      </Box>
      {isAdvance ? (
        <>
          <Typography mb={0.3} variant="body1" color={"text.secondary"}>
            Valor solicitado:
          </Typography>
          <Typography variant="h5" fontWeight={"500"} color={"text.primary"}>
            {currency} {formatAmount(advance?.amount)}
          </Typography>
          <Typography mt={2} mb={0.3} variant="body1" color={"text.secondary"}>
            Motivo:
          </Typography>
          <Typography
            maxWidth={700}
            fontStyle={advance?.reason ? "normal" : "italic"}
            color={advance?.reason ? "text.primary" : "text.secondary"}
            variant="body1"
            fontWeight={"500"}
          >
            {advance?.reason || "Nenhum motivo informado"}
          </Typography>
          <DetailBox title={"Detalhes do relatório"} sx={{ mt: 3 }}>
            <Box display={"flex"} flexDirection={"column"} gap={1}>
              <DetailLabel label="Título">{title}</DetailLabel>
              <DetailLabel label="Descrição">{obs}</DetailLabel>
              <DetailLabel label="Criado em">
                {formatDate(createdAt)}
              </DetailLabel>
              <Button
                onClick={openHistoryDrawer}
                sx={{ alignSelf: "flex-end" }}
              >
                Ver histórico
              </Button>
            </Box>
          </DetailBox>
        </>
      ) : (
        <>
          <Box
            borderRadius={3}
            maxWidth={600}
            border={1}
            borderColor={"divider"}
            p={2}
          >
            <Box display={"flex"} alignItems={"flex-start"}>
              <NotesOutlined color="action" sx={{ mr: 2 }} />
              <Typography
                variant="body1"
                color={obs ? "text.primary" : "text.secondary"}
              >
                {obs || "Sem descrição"}
              </Typography>
            </Box>
            {!customFields && (
              <CustomFieldsSection
                sx={{ mt: 1 }}
                dividers={false}
                customFields={customFields}
              />
            )}
            <Box maxWidth={600}>
              <AmountSection
                totalAdvance={
                  advance?.status === "approved" || advance?.status === "paid"
                    ? advance?.amount
                    : 0
                }
                totalAmount={totalAmount}
                totalRefundable={totalRefundable}
                isApprover={true}
              />
            </Box>
          </Box>
          {Boolean(alerts?.length) && <LimitsAlerts alerts={alerts} />}
          {(requiredReceipt || hasWarning) && (
            <Box
              p={2}
              py={1}
              sx={{
                mt: 2,
                borderRadius: 3,
                maxWidth: 600,
                bgcolor: (t) => `${t.palette.warning.main}10`,
              }}
              border={1}
              borderColor={"divider"}
            >
              <Box
                pt={0.5}
                mb={1}
                display={"flex"}
                alignItems={"center"}
                gap={2}
              >
                <WarningOutlined color="warning" />
                <Typography fontSize={"1.1rem"} fontWeight={"600"}>
                  Despesas com alertas
                </Typography>
              </Box>
              <Box pl={4.5}>
                {requiredReceipt && (
                  <Typography mb={1} variant="body2" fontWeight={"500"}>
                    - Este relatório possui despesa(s) que precisa(m) de ao
                    menos um comprovante
                  </Typography>
                )}
                {hasWarning && (
                  <Typography mb={1} variant="body2" fontWeight={"500"}>
                    - Este relatório possui despesas com alertas.
                    <Typography
                      mt={0.3}
                      display={"flex"}
                      alignItems={"center"}
                      component={"span"}
                      variant="body2"
                    >
                      Verifique-as:{" "}
                      <WarningOutlined
                        color="warning"
                        sx={{ fontSize: ".9rem", ml: 1 }}
                      />
                    </Typography>
                  </Typography>
                )}
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default memo(BaseInfo);

import {
  TableCell,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { selectAccountCurrency } from "../../../store/features/accountSlice";
import formatAmount from "../../../utils/formatAmount";

const ExpensesResumeTotal = ({
  totalAmount = 0,
  totalRefundable = 0,
  dividerColSpan = 12,
  cellColSpan = 4,
}) => {
  const currency = useSelector((state) => selectAccountCurrency(state, true));

  return (
    <>
      <TableRow
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderTop: 1.5,
            borderBottom: 0,
            borderColor: "divider",
            p: 0,
            pt: 1,
          },
        }}
      >
        <TableCell colSpan={dividerColSpan}></TableCell>
      </TableRow>
      <TableRow
        sx={{
          [`& .${tableCellClasses.root}`]: {
            pb: 0,
            border: "none",
          },
        }}
      >
        <TableCell align="right" colSpan={cellColSpan}>
          <Typography
            noWrap
            variant="inherit"
            fontSize={".93rem"}
            color={"text.secondary"}
            fontWeight={"500"}
          >
            Total
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography
            noWrap
            variant="inherit"
            fontSize={".93rem"}
            color={"text.primary"}
            fontWeight={"600"}
          >
            {currency} {formatAmount(totalAmount)}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow
        sx={{
          [`& .${tableCellClasses.root}`]: {
            border: "none",
          },
        }}
      >
        <TableCell align="right" colSpan={cellColSpan}>
          <Typography
            noWrap
            variant="inherit"
            fontSize={".93rem"}
            color={"text.secondary"}
            fontWeight={"500"}
          >
            Total reembolsável
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography
            variant="inherit"
            fontSize={".93rem"}
            color={"text.primary"}
            fontWeight={"600"}
            noWrap
          >
            {currency} {formatAmount(totalRefundable)}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

export default memo(ExpensesResumeTotal);

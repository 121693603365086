import { AssignmentOutlined } from "@mui/icons-material";
import { Avatar, useTheme } from "@mui/material";
import React, { memo, useMemo } from "react";

const RequestAvatar = ({ status }) => {
  const { palette } = useTheme();

  const color = useMemo(() => {
    switch (status) {
      case "R":
        return palette.error.main;
      case "A":
        return palette.success.main;
      case "P":
        return palette.warning.main;
      case "AD":
        return palette.warning.main;
      case "F":
        return palette.primary.main;
      case "O":
        return palette.action.active;
      default:
        break;
    }
  }, [status]);

  const bgcolor = useMemo(() => {
    return `${color}15`;
  }, [color]);
  return (
    <Avatar sx={{ bgcolor }}>
      <AssignmentOutlined sx={{ color }} />
    </Avatar>
  );
};

export default memo(RequestAvatar);

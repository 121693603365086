import { Circle, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HttpClient } from "../../api/httpClient";
import { selectAccountCurrency } from "../../store/features/accountSlice";
import {
  removeOneApprovalRequest,
  selectApprovalRequestsEntities,
} from "../../store/features/approvalRequestsSlice";
import { openSnackbar } from "../../store/features/base/snackbarBaseSlice";
import formatAmount from "../../utils/formatAmount";
import RejectionReasonInput from "./components/RejectionReasonInput";

function RejectMultiple({
  open,
  onClose,
  ids = [],
  onAction,
  isAdvance = false,
}) {
  const dispatch = useDispatch();
  const entities = useSelector(selectApprovalRequestsEntities);

  const currency = useSelector((state) => selectAccountCurrency(state, true));

  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");

  const handleReject = async () => {
    const freezeIds = [...ids];
    setLoading(true);
    for (let index = 0; index < freezeIds.length; index++) {
      const requestId = freezeIds[index];
      try {
        await HttpClient.post(`/approvers/request/${requestId}/reject`, {
          comment,
          rejected_expenses: [],
        });
        dispatch(removeOneApprovalRequest(requestId));
      } catch (error) {
        continue;
      }
    }
    dispatch(
      openSnackbar({
        message: `${freezeIds.length} ${
          isAdvance ? "adiantamento(s)" : "relatório(s)"
        } rejeitado(s)`,
      })
    );
    setLoading(false);
    onClose();
    onAction();
  };

  useEffect(() => {
    if (!open) {
      setComment("");
    }
  }, [open]);

  return (
    open && (
      <Dialog open={open} fullWidth maxWidth="xs">
        <LinearProgress
          sx={{ height: loading ? 3 : 0, transition: ".15s ease" }}
          color="error"
        />
        <Collapse in={!loading}>
          <DialogTitle fontWeight={"500"} fontSize={"1.1rem"}>
            <Typography variant="inherit">
              Rejeitar {isAdvance ? "adiantamentos" : "relatórios"}
            </Typography>
            <Box flex={1} />
            <IconButton sx={{ m: -1 }} onClick={onClose}>
              <Close />
            </IconButton>
          </DialogTitle>
        </Collapse>
        <DialogContent>
          {ids
            .filter((requestId) => Boolean(entities[requestId]))
            .map((requestId) => (
              <Box
                key={requestId}
                mb={0.8}
                display={"flex"}
                alignItems={"center"}
              >
                <Circle color="disabled" sx={{ fontSize: ".5rem", mr: 1 }} />
                <Typography noWrap variant="body2" fontWeight={"500"}>
                  {`${currency} ${formatAmount(
                    isAdvance
                      ? entities[requestId]?.advance?.amount || 0
                      : entities[requestId]?.total_amount || 0
                  )} - `}
                  {entities[requestId]?.title || ""}
                </Typography>
              </Box>
            ))}
          <Collapse in={!loading}>
            <RejectionReasonInput
              sx={{ mt: 1 }}
              value={comment}
              onChange={setComment}
            />
          </Collapse>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
          <Button
            disabled={loading}
            variant="contained"
            disableElevation
            color="error"
            onClick={handleReject}
          >
            {loading ? "Rejeitando..." : "Rejeitar"}
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
}

export default RejectMultiple;

import { CategoryOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import AvatarType from "../../../../../../components/avatar/AvatarType";
import CircleDivisor from "../../../../../../components/division/CircleDivisor";
import { selectAccountCurrency } from "../../../../../../store/features/accountSlice";
import { selectExpenseTypesEntities } from "../../../../../../store/features/configs/expenseTypesSlice";
import { CurrencyFormat } from "../../../../../../utils/more/mask_functions";
import PaperSection from "./PaperSection";

function TotalsResume({
  resume = { types: {}, route: { total: 0, distance: 0, amount: 0 } },
}) {
  const curr = useSelector((state) => selectAccountCurrency(state, true));
  const expTypesEntities = useSelector(selectExpenseTypesEntities);
  return (
    <PaperSection title="Resumo por categoria" Icon={CategoryOutlined}>
      {Object.keys(resume.types).length ? (
        Object.keys(resume.types).map((typeId) => (
          <Box
            key={typeId}
            py={1}
            display={"flex"}
            mx={-2}
            px={2}
            alignItems="center"
            justifyContent={"space-between"}
            sx={{ ":hover": { bgcolor: "action.hover" } }}
          >
            <Typography
              component={"div"}
              display={"flex"}
              alignItems={"center"}
              variant="body1"
            >
              <AvatarType
                showBgcolor
                icon={expTypesEntities[typeId]?.icon}
                size={30}
                fz="1.2rem"
                sx={{ mr: 1, ml: -0.3 }}
              />
              {expTypesEntities[typeId]?.name}
            </Typography>
            <Typography ml={1} variant="body2">
              ({resume.types[typeId].total})
            </Typography>
            <Box flex={1} />
            <Typography fontWeight={"500"} variant="body1">
              {curr} <CurrencyFormat value={resume.types[typeId].amount} />
            </Typography>
          </Box>
        ))
      ) : (
        <Typography variant="body2" fontStyle={"italic"}>
          Nenhuma despesa por categoria
        </Typography>
      )}

      <Typography
        mt={2}
        fontSize={".8rem"}
        fontWeight={"500"}
        gutterBottom
        color={"text.secondary"}
      >
        Totais de percurso
      </Typography>
      {resume.route?.total > 0 ? (
        <Box display={"flex"} alignItems={"center"} gap={1}>
          <Typography fontWeight={"500"} variant="body2">
            {resume.route?.total} percurso(s)
          </Typography>
          <CircleDivisor />
          <Typography fontWeight={"500"} variant="body2">
            {parseFloat(resume.route?.distance || 0).toFixed(2)} km
          </Typography>
          <CircleDivisor />
          <Typography fontWeight={"500"} variant="body2">
            {curr} <CurrencyFormat value={resume.route?.amount} />
          </Typography>
        </Box>
      ) : (
        <Typography variant="body2" fontStyle={"italic"}>
          Nenhuma despesa de percurso
        </Typography>
      )}
    </PaperSection>
  );
}

export default memo(TotalsResume);

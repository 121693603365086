import { Add, AddOutlined, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openSnackbar } from "../../store/features/base/snackbarBaseSlice";
import { expensesOfflineActions } from "../../store/features/expensesSlice";
import { closeElement, openElement } from "../../store/features/portalSlice";
import {
  fetchRequests,
  selectOpenedRequestsIds,
  selectRequestsEntities,
} from "../../store/features/requestsSlice";
import { includesOnSearch } from "../../utils/more/search";
import RequestMenuItem from "./RequestMenuItem";
import SearchRequests from "./components/SearchRequests";

const ConfirmModal = ({
  isMove,
  open,
  onClose,
  expensesNum,
  requestTitle,
  onConfirm,
}) => {
  const handleConfirm = async () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog
      data-disableselect={true}
      open={open}
      onClose={onClose}
      fullWidth
      PaperProps={{
        sx: {
          maxWidth: 400,
        },
      }}
    >
      <DialogTitle fontSize={"1.2rem"} fontWeight={"500"}>
        <Box>
          <Typography
            variant="body1"
            fontWeight={"500"}
            color={"text.secondary"}
          >
            {isMove ? "Mover" : "Adicionar"} {expensesNum} despesa(s) ao
            relatório...
          </Typography>
          <Typography fontWeight={"500"} variant="inherit">
            {requestTitle}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button variant="contained" disableElevation onClick={handleConfirm}>
          {isMove ? "Mover" : "Adicionar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RequestItem = memo(({ requestId, expensesNum, onConfirm, isMove }) => {
  const [confirmModal, setConfirmModal] = useState(false);

  const handleConfirm = useCallback(() => onConfirm(requestId), [onConfirm]);

  const renderModalConfirm = useCallback(
    (title) => {
      return (
        <ConfirmModal
          open={confirmModal}
          expensesNum={expensesNum}
          onClose={() => setConfirmModal(false)}
          onConfirm={handleConfirm}
          requestTitle={title}
          isMove={isMove}
        />
      );
    },
    [confirmModal, isMove, expensesNum]
  );

  const handleClick = useCallback(() => {
    setConfirmModal(true);
  }, []);

  return (
    <RequestMenuItem
      selected={confirmModal}
      onClick={handleClick}
      requestId={requestId}
      renderModalConfirm={renderModalConfirm}
    />
  );
});

const CreationButton = memo(({ onClick }) => {
  return (
    <Tooltip placement="left-start" title="Novo relatório">
      <Fab
        onClick={onClick}
        color="primary"
        sx={{
          position: "absolute",
          boxShadow: 1,
          bottom: 20,
          right: 20,
          borderRadius: 4,
        }}
        variant="circular"
      >
        <AddOutlined />
      </Fab>
    </Tooltip>
  );
});

function MenuAddExpensesInRequest({
  open,
  fromRequestId = null,
  isMove = false,
  onClose,
  onAction = () => {},
  expensesIds = [],
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const working = useSelector((state) => state.requests.working);
  const requests = useSelector(selectOpenedRequestsIds);
  const entities = useSelector(selectRequestsEntities);

  const [content, setContent] = useState(undefined);

  const [noDataResponseCount, setNoDataResponseCount] = useState(0);

  const scrolled = useScrollTrigger({
    target: content,
    disableHysteresis: true,
    threshold: 0,
  });

  const [search, setSearch] = useState("");

  const requestsFormatedByInsertType = useMemo(() => {
    return requests.filter((rId) =>
      isMove && fromRequestId ? rId !== fromRequestId : true
    );
  }, [requests, fromRequestId, isMove]);

  const requestsFormatedBySearch = useMemo(() => {
    return requestsFormatedByInsertType.filter((requestId) =>
      includesOnSearch(search, [entities[requestId].title])
    );
  }, [requestsFormatedByInsertType, search, entities]);

  const handleAddToRequest = useCallback(
    async (requestId) => {
      if (isMove) {
        dispatch(
          expensesOfflineActions.moveToRequest(
            fromRequestId,
            requestId,
            expensesIds
          )
        );
        dispatch(
          openSnackbar({
            message: "Despesas movidas",
            action: () => navigate(`/approval-requests/${requestId}`),
            actionText: "Ver no relatório",
          })
        );
      } else {
        dispatch(expensesOfflineActions.addToRequest(expensesIds, requestId));
        dispatch(
          openSnackbar({
            message: "Despesas adicionadas ao relatório",
            action: () => navigate(`/approval-requests/${requestId}`),
            actionText: "Ver relatório",
          })
        );
      }

      onClose();
      dispatch(closeElement("expenseView"));
      dispatch(closeElement("routeView"));
      dispatch(closeElement("manualRouteView"));
      onAction();
    },
    [isMove, fromRequestId, expensesIds]
  );

  useEffect(() => {
    if (!open) {
      setSearch("");
      setNoDataResponseCount(0);
    }
  }, [open]);

  useEffect(() => {
    if (working) return;
    if (search && !requestsFormatedBySearch.length) {
      loadMoreRequests();
    }
  }, [search]);

  const handleClickAdd = useCallback(() => {
    dispatch(
      openElement({
        name: "newRequest",
        util: { selectedExpenses: expensesIds },
      })
    );
    onClose();
  }, [expensesIds]);

  const handleClickAddSearch = useCallback(() => {
    dispatch(
      openElement({
        name: "newRequest",
        util: { selectedExpenses: expensesIds, title: search },
      })
    );
    onClose();
  }, [expensesIds, search]);

  const loadMoreRequests = () => {
    if (noDataResponseCount > 2 || working) return;
    dispatch(
      fetchRequests({
        skip: requests?.length,
        limit: 50,
        status: "O",
      })
    )
      .unwrap()
      .then((result) => {
        if (result && result?.length === 0) {
          setNoDataResponseCount((prev) => prev + 1);
        }
      });
  };

  const handleContentScroll = useCallback((event) => {
    const boxNode = event.currentTarget;
    if (boxNode.scrollTop + boxNode.clientHeight === boxNode.scrollHeight) {
      loadMoreRequests();
    }
  }, []);

  return (
    open && (
      <Dialog
        transitionDuration={0}
        onClick={(e) => e.stopPropagation()}
        PaperProps={{
          elevation: 3,
          sx: {
            height: "90%",
            maxHeight: "50em",
          },
        }}
        fullWidth
        maxWidth="xs"
        data-disableselect={true}
        open={open}
        onClose={onClose}
      >
        <Box boxShadow={scrolled ? 3 : 0}>
          <Box
            boxShadow={0}
            display={"flex"}
            alignItems={"center"}
            p={2}
            pt={1.5}
            pb={1.5}
            fontSize={"1.1rem"}
            fontWeight={"500"}
          >
            {isMove ? "Mover para o relatório..." : "Adicionar ao relatório"}
            <Box flex={1} />
            <IconButton sx={{ m: -1 }} onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          <SearchRequests
            scrolled={scrolled}
            value={search}
            onChange={setSearch}
          />
        </Box>
        <DialogContent
          ref={(node) => node && setContent(node)}
          onScroll={handleContentScroll}
          sx={{ padding: 0, pt: 1, pb: 4, position: "relative" }}
        >
          {!Boolean(requestsFormatedByInsertType.length) ? (
            <>
              <Typography
                color={"text.secondary"}
                textAlign={"center"}
                m={2}
                mt={5}
              >
                Nenhum relatório encontrado
              </Typography>
              <Box display={"flex"} justifyContent={"center"}>
                <Button onClick={handleClickAdd} startIcon={<Add />}>
                  Criar novo
                </Button>
              </Box>
            </>
          ) : Boolean(search.trim().length) &&
            Boolean(!requestsFormatedBySearch.length) ? (
            <>
              <Typography
                color={"text.secondary"}
                textAlign={"center"}
                m={2}
                mt={5}
              >
                Nenhum relatório encontrado
              </Typography>
              <MenuItem
                onClick={handleClickAddSearch}
                sx={{ justifyContent: "center", px: 3 }}
              >
                <Typography noWrap fontWeight={"500"} textAlign={"center"}>
                  Criar{" "}
                  <Typography
                    color={"primary.main"}
                    component={"span"}
                    variant="inherit"
                    fontWeight={"600"}
                  >
                    "{search}"
                  </Typography>
                </Typography>
              </MenuItem>
            </>
          ) : (
            requestsFormatedBySearch.map((requestId) => (
              <RequestItem
                key={requestId}
                requestId={requestId}
                expensesNum={expensesIds.length}
                onConfirm={handleAddToRequest}
                isMove={isMove}
              />
            ))
          )}
          <Box
            height={100}
            visibility={working ? "visible" : "hidden"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress size={30} />
          </Box>
        </DialogContent>
        <CreationButton onClick={handleClickAdd} />
      </Dialog>
    )
  );
}

export default MenuAddExpensesInRequest;

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { APIActions } from "../../../queues/offline-queue/effect";
import normalize from "../../../utils/normalize";

const baseName = "rejectionReasons";

const adapter = createEntityAdapter();

const initialState = adapter.getInitialState({
  working: false,
  status: "idle",
});

export const syncRejectionReasons = createAsyncThunk(
  `${baseName}/syncRejectionReasons`,
  async () => {
    const res = await APIActions.rejectionReasons.sync();
    return res;
  }
);

export const rejectionReasonsSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    setupRejectionReasons(state, { payload }) {
      const { entities } = normalize(payload);
      adapter.setAll(state, entities);
    },
    upsertRejectionReason(state, { payload }) {
      adapter.upsertOne(state, payload);
    },
    removeRejectionReason(state, { payload }) {
      adapter.removeOne(state, payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(syncRejectionReasons.pending, (state, action) => {
        state.working = true;
      })
      .addCase(syncRejectionReasons.rejected, (state, action) => {
        state.working = false;
      })
      .addCase(syncRejectionReasons.fulfilled, (state, action) => {
        state.working = false;
        state.status = "succeeded";
        const data = action.payload;
        rejectionReasonsSlice.caseReducers.setupRejectionReasons(state, {
          payload: data,
        });
      });
  },
});

export const {
  removeRejectionReason,
  setupRejectionReasons,
  upsertRejectionReason,
} = rejectionReasonsSlice.actions;

export const {
  selectAll: selectAllRejectionReasons,
  selectById: selectRejectionReasonById,
  selectIds: selectRejectionReasonsIds,
  selectEntities: selectRejectionReasonsEntities,
} = adapter.getSelectors((state) => state[baseName]);

export default rejectionReasonsSlice.reducer;

import { Box, Button, Popover, Typography, lighten } from "@mui/material";
import React from "react";

import { useSelector } from "react-redux";
import { ADMIN_BASE_URL } from "../../../api/urls";
import { selectIsAdmin } from "../../../store/features/accountSlice";
import "./styles.css";

function MenuContainer({ anchorEl, onClose, children, name }) {
  const isAdmin = useSelector(selectIsAdmin);

  return (
    <Popover
      elevation={5}
      sx={{ mt: 1 }}
      disableScrollLock
      disablePortal
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      transitionDuration={0}
      transformOrigin={{ horizontal: "center", vertical: "top" }}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      PaperProps={{
        className: "account-menu",
        elevation: 0,
        sx: {
          boxShadow: 5,
          width: 410,
          borderRadius: 6,
          bgcolor: (t) =>
            t.palette.mode === "light"
              ? lighten(t.palette.primary.main, 0.95)
              : lighten(t.palette.background.default, 0.015),
        },
      }}
    >
      <Box p={2} pb={5}>
        <Box mb={1} display={"flex"} alignItems={"center"}>
          <Typography fontSize={"1.1rem"} fontWeight={"500"}>
            Olá, {name || ""}.
          </Typography>
          <Box flex={1} />
          {isAdmin && (
            <Button
              LinkComponent={"a"}
              sx={{
                px: 1,
                transition: "none",
                height: 30,
                ":hover": { boxShadow: 2 },
              }}
              href={ADMIN_BASE_URL}
              target="_blank"
            >
              Administrador
            </Button>
          )}
        </Box>
        {children}
      </Box>
    </Popover>
  );
}

export default MenuContainer;

import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openSnackbar } from "../../../../../store/features/base/snackbarBaseSlice";
import { requestsOfflineActions } from "../../../../../store/features/requestsSlice";

function DeleteRequest({ requestId, renderComponent, disableNavigateToBack }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [confirmModal, setConfirmModal] = useState(false);

  const handleDelete = async () => {
    dispatch(requestsOfflineActions.removeRequest(requestId));
    dispatch(openSnackbar({ message: "Relatório removido" }));
    setConfirmModal(false);
    if (!disableNavigateToBack) {
      navigate("/approval-requests");
    }
  };

  return (
    <Box>
      {renderComponent({
        onConfirm: () => setConfirmModal(true),
        disabled: false,
      })}
      <Dialog
        open={confirmModal}
        onClose={() => setConfirmModal(false)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle fontSize={"1.2rem"}>
          Excluir o relatório de despesas?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            fontWeight={"500"}
            color={"error.main"}
            component={"div"}
            display={"flex"}
            alignItems={"center"}
          >
            <InfoOutlined sx={{ mr: 1 }} color="error" />
            As despesas serão removidas
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmModal(false)}>Cancelar</Button>
          <Button variant="contained" disableElevation onClick={handleDelete}>
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default DeleteRequest;

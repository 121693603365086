import { Avatar, Box, TableCell, Typography } from "@mui/material";
import React, { memo } from "react";
import { getProfilePicture } from "../../../../utils/functions/profile";

const UserCell = ({ email, name, lastname, _id }) => {
  return (
    <TableCell>
      <Box display={"flex"} alignItems={"center"}>
        <Avatar
          sx={{ width: 30, height: 30 }}
          src={getProfilePicture(_id)}
        ></Avatar>
        <Box ml={1.5}>
          <Typography
            noWrap
            variant="h5"
            fontWeight={"600"}
            fontSize={".975rem"}
          >
            {name} {lastname || ""}
          </Typography>
          <Typography
            noWrap
            variant="h5"
            color={"text.secondary"}
            fontSize={".825rem"}
          >
            {email}
          </Typography>
        </Box>
      </Box>
    </TableCell>
  );
};

export default memo(UserCell);

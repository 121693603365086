import { Popover } from "@mui/material";
import React from "react";

function NotificationsMenu({ children, anchorEl, onClose, onExited }) {
  return (
    <Popover
      disablePortal
      transitionDuration={0}
      disableScrollLock={false}
      TransitionProps={{
        unmountOnExit: true,
        onExited,
      }}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            mt: 0.5,
            width: 510,
            height: 700,
            borderRadius: 3,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            boxShadow: 10,
          },
        },
      }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      transformOrigin={{ horizontal: "center", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {children}
    </Popover>
  );
}

export default NotificationsMenu;

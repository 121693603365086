import { MenuItem, Typography } from "@mui/material";
import React, { memo } from "react";
import SelectInput from "../../../components/inputs/Select";

const menuItemStyle = {
  height: 50,
  fontSize: "1rem",
};

const data = {
  amount: "Valor",
  date: "Data",
  category: "Categoria",
  payment: "Forma de pagamento",
};

const SelectOrderBy = ({ value, onChange = () => {}, disabled }) => {
  return (
    <SelectInput
      value={value}
      disabled={disabled}
      onChange={(v) => onChange("orderBy", v)}
      margin={"normal"}
      label={"Ordenar por"}
      renderValue={(v) => (
        <Typography variant="body1" fontWeight={"500"}>
          {data[v]}
        </Typography>
      )}
    >
      <MenuItem value="amount" style={menuItemStyle}>
        Valor
      </MenuItem>
      <MenuItem value="date" style={menuItemStyle}>
        Data
      </MenuItem>
      <MenuItem value="category" style={menuItemStyle}>
        Categoria
      </MenuItem>
      <MenuItem value="payment" style={menuItemStyle}>
        Forma de pagamento
      </MenuItem>
    </SelectInput>
  );
};

export default memo(SelectOrderBy);

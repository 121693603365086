import { Check, PostAddOutlined, SearchOutlined } from "@mui/icons-material";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { memo, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import FilterChip from "../../../../../../components/chip/FilterChip";
import Input from "../../../../../../components/inputs/text-input";
import {
  selectAllProjects,
  selectProjectsEntities,
} from "../../../../../../store/features/configs/projectsSlice";
import { includesOnSearch } from "../../../../../../utils/more/search";
import MenuBase from "./MenuBase";

const ProjectsFilterMenu = ({
  anchorEl,
  onClose,
  filter,
  onClickItem,
  onClear,
}) => {
  const projects = useSelector(selectAllProjects);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setSearch("");
  }, [onClose]);

  return (
    <MenuBase
      onClear={onClear}
      disabledClear={!Boolean(filter.length)}
      title={"Projetos"}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <List disablePadding>
        {!projects.length ? (
          <Typography
            textAlign={"center"}
            m={5}
            variant="body2"
            color={"text.secondary"}
          >
            Nenhum projeto encontrado
          </Typography>
        ) : (
          <>
            <Input
              sx={{ p: 2, pt: 0 }}
              size={"small"}
              value={search}
              onClick={(e) => e.stopPropagation()}
              onChange={setSearch}
              LeftIcon={SearchOutlined}
              variant={"standard"}
              autoFocus
              placeholder={"Buscar"}
            />
            {projects
              .filter((pj) => includesOnSearch(search, [pj.name]))
              .map((pj) => {
                const selected = filter === pj._id;
                return (
                  <ListItem disablePadding key={pj._id}>
                    <ListItemButton
                      sx={{ transition: "none" }}
                      onClick={() => onClickItem(pj._id)}
                      selected={selected}
                    >
                      <PostAddOutlined
                        color={selected ? "primary" : "action"}
                        sx={{ mr: 2 }}
                      />
                      <ListItemText
                        primaryTypographyProps={{
                          fontWeight: "500",
                          fontSize: ".95rem",
                          noWrap: true,
                        }}
                        primary={pj.name}
                      />
                      {selected && <Check color="primary" sx={{ ml: 1 }} />}
                    </ListItemButton>
                  </ListItem>
                );
              })}
          </>
        )}
      </List>
    </MenuBase>
  );
};

function ProjectsFilter({ filter, onChange, onClear }) {
  const filtered = useMemo(() => Boolean(filter), [filter]);
  const [menu, setMenu] = useState(null);

  const projectsEntities = useSelector(selectProjectsEntities);

  const handleClickProjectItem = (projectId) => {
    if (projectId === filter) {
      onClear();
      return;
    }
    onChange(projectId);
  };

  return (
    <>
      <FilterChip
        onClick={(e) => setMenu(e.target)}
        filtered={filtered}
        Icon={PostAddOutlined}
        label={"Projeto"}
        opened={Boolean(menu)}
        filteredLabel={filter && projectsEntities[filter]?.name}
        isDropdown
      />
      <ProjectsFilterMenu
        anchorEl={menu}
        filter={filter || []}
        onClose={() => setMenu(null)}
        onClickItem={handleClickProjectItem}
        onClear={onClear}
      />
    </>
  );
}

export default memo(ProjectsFilter);

import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  ToggleButton,
  Typography,
} from "@mui/material";
import React from "react";

const toggleButtonStyles = {
  height: 35,
  border: 1,
  borderColor: "divider",
  px: 2,
  borderRadius: 100,
  color: "text.primary",
  overflow: "hidden",
};

const HeaderToggleRouteMode = ({
  onClose,
  mode,
  onChangeMode,
  disabledCreation,
  onCreate,
}) => {
  return (
    <Box
      borderBottom={1}
      borderColor={"divider"}
      px={2}
      py={1}
      display="flex"
      gap={1}
      alignItems={"center"}
    >
      <IconButton color="inherit" onClick={onClose}>
        <Close />
      </IconButton>
      <ToggleButton
        value={"map"}
        color="primary"
        onClick={() => onChangeMode("map")}
        sx={toggleButtonStyles}
        selected={mode === "map"}
      >
        <Typography variant="inherit" noWrap>
          Por mapa
        </Typography>
      </ToggleButton>
      <ToggleButton
        value={"manual"}
        color="primary"
        onClick={() => onChangeMode("manual")}
        sx={toggleButtonStyles}
        selected={mode === "manual"}
      >
        <Typography variant="inherit" noWrap>
          Manual
        </Typography>
      </ToggleButton>
      <Box flex={1} />
      <Button sx={{ display: { md: "flex", xs: "none" } }} onClick={onClose}>
        Cancelar
      </Button>
      <Button
        disabled={disabledCreation}
        onClick={onCreate}
        variant="contained"
        disableElevation
      >
        <Typography variant="inherit" noWrap>
          Criar percurso
        </Typography>
      </Button>
    </Box>
  );
};

export default HeaderToggleRouteMode;

import { CurrencyExchangeOutlined } from "@mui/icons-material";
import React, { memo } from "react";
import FilterChip from "../../../../../../components/chip/FilterChip";

function RefundableFilter({ filter, onClick, onClear }) {
  const handleClick = () => {
    if (filter === undefined || filter === null) {
      onClick();
    } else {
      onClear();
    }
  };

  return (
    <FilterChip
      onClick={handleClick}
      Icon={CurrencyExchangeOutlined}
      label={"Reembolsável"}
      filtered={Boolean(filter)}
    />
  );
}

export default memo(RefundableFilter);

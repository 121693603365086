import { Close } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { memo } from "react";

function Header({ title, onClose, onCreate }) {
  return (
    <Box
      borderBottom={1}
      borderColor={"divider"}
      pr={2}
      pl={3}
      py={1}
      display="flex"
      alignItems={"center"}
    >
      <IconButton onClick={onClose} sx={{ ml: -1, mr: 1 }}>
        <Close />
      </IconButton>
      <Typography
        mr={2}
        color={"text.primary"}
        fontWeight="500"
        fontSize="1.1rem"
        sx={{ opacity: 0.9 }}
      >
        {title}
      </Typography>
      <Box flex={1} />
      <Button sx={{ display: { xs: "none", md: "flex" } }} onClick={onClose}>
        Cancelar
      </Button>
      <Button
        onClick={onCreate}
        sx={{ ml: 1, px: 4 }}
        variant="contained"
        disableElevation
      >
        Criar
      </Button>
    </Box>
  );
}

export default memo(Header);

import { HttpClient } from "../api/httpClient";

async function currencyConversor(currFrom, currTo, date, amount) {
  const { data } = await HttpClient.get(`/currency/conversor`, {
      from: currFrom,
      to: currTo,
      date,
      amount,
  });
  return data;
}

export default currencyConversor;

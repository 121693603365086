import { Button, useMediaQuery, useTheme } from "@mui/material";
import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import MenuAddExpensesInRequest from "../../../../../../components/menus/MenuAddExpensesInRequest";
import { selectExpensesSelected } from "../../../../../../store/features/expensesSlice";

function AddToRequest({ clearSelection = () => {} }) {
  const selected = useSelector(selectExpensesSelected);

  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Button
        data-disableselect={true}
        disableElevation
        size={isSmall ? "small" : "medium"}
        variant="contained"
        sx={{ mx: 1 }}
        onClick={() => {
          setOpen(true);
        }}
      >
        Adicionar a um relatório
      </Button>
      <MenuAddExpensesInRequest
        open={open}
        onClose={() => setOpen(false)}
        expensesIds={selected}
        onAdd={clearSelection}
      />
    </>
  );
}

export default memo(AddToRequest);

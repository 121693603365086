import {
  AssignmentOutlined,
  CheckCircleOutline,
  DarkModeOutlined,
  FeedbackOutlined,
  HomeOutlined,
  LightModeOutlined,
  ReceiptLongOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import { Drawer as DrawerMUI } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { ADMIN_BASE_URL } from "../../api/urls";
import { useThemeMode } from "../../contexts/useThemeMode";
import {
  selectIsAdmin,
  selectIsApprover,
} from "../../store/features/accountSlice";
import NotepayLogo from "../NotepayLogo";
import SectionComponent from "./SectionComponent";
import SidebarItem from "./SidebarItem";

function Drawer({ open, onClose }) {
  const isApprover = useSelector(selectIsApprover);
  const isAdm = useSelector(selectIsAdmin);
  const { mode, changeThemeMode } = useThemeMode();
  return (
    <DrawerMUI
      open={open}
      onClose={onClose}
      ModalProps={{
        onClick: onClose,
      }}
      transitionDuration={150}
      PaperProps={{
        elevation: 4,
        onClick: (e) => e.stopPropagation(),
        sx: {
          width: 300,
          py: 2,
        },
      }}
    >
      <NotepayLogo
        style={{
          width: '90px',
          marginBottom: "15px",
          marginLeft: "20px",
        }}
      />
      <SectionComponent divider onClick={onClose}>
        <SidebarItem Icon={HomeOutlined} title="Início" path={"home"} />
        <SidebarItem
          Icon={ReceiptLongOutlined}
          title="Despesas avulsas"
          path={"expenses"}
        />
        <SidebarItem
          Icon={AssignmentOutlined}
          title="Relatórios de despesas"
          path={"approval-requests"}
        />
        {isApprover && (
          <SidebarItem
            Icon={CheckCircleOutline}
            title="Para aprovar"
            path={"approver"}
          />
        )}
      </SectionComponent>
      <SectionComponent divider onClick={onClose}>
        <SidebarItem
          Icon={FeedbackOutlined}
          title="Enviar feedback"
          disableLink
          onClick={() =>
            window.open(
              "https://docs.google.com/forms/d/e/1FAIpQLSfOIaKZFp0kI5uBTaJ2vvOIdnjO_nItTxNtxUV9wIoe63dF9w/viewform?usp=sf_link"
            )
          }
        />
        {isAdm && (
          <SidebarItem
            Icon={SettingsOutlined}
            title="Painel do administrador"
            disableLink
            onClick={() => window.open(ADMIN_BASE_URL)}
          />
        )}
        <SidebarItem
          Icon={mode === "dark" ? LightModeOutlined : DarkModeOutlined}
          title="Alterar tema"
          disableLink
          onClick={() => {
            if (mode === "dark") {
              changeThemeMode("light");
            } else {
              changeThemeMode("dark");
            }
          }}
        />
      </SectionComponent>
    </DrawerMUI>
  );
}

export default Drawer;

import { Avatar, Chip, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { getProfilePicture } from "../../utils/functions/profile";

function UserItem({ onClick = () => {}, name, subtitle, id, onDelete, sx }) {
  const photo = useMemo(() => getProfilePicture(id), [id]);

  return (
    <Chip
      icon={
        <Avatar sx={{ width: 20, height: 20 }} src={photo} alt={name}></Avatar>
      }
      label={
        <>
          <Typography variant="inherit">{name}</Typography>
          <Typography fontSize={".7rem"} color="text.secondary">
            {subtitle}
          </Typography>
        </>
      }
      sx={{
        height: 25,
        ...sx,
      }}
      variant="outlined"
      size="small"
      onDelete={onDelete}
    />
  );
}

export default UserItem;

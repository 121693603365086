import {
  Box,
  Dialog,
  DialogContent,
  Fade,
  LinearProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { memo, useCallback } from "react";
import ExpensePolicyLayer from "../../../layers/ExpensePolicyLayer";
import "../../../main.css";
import Header from "../components/Header";

function Layout({
  open,
  onClose,
  expenseId,
  settings,
  loading,
  changed,
  onEdit,
  editMode,
  onCancelEdit,
  onSave,
  receiptsContent,
  viewContent,
  editContent,
  expensePolicyRef,
  warnings,
  showRequiredReceipt,
}) {
  const handleClickWarnings = useCallback((data) => {
    expensePolicyRef?.current?.presentReadOnly(data);
  }, []);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      fullScreen={isSmall}
      data-disableselect={true}
      open={open}
      TransitionComponent={Fade}
      fullWidth
      maxWidth="md"
      disableScrollLock={false}
      onClose={(event, reason) =>
        reason === "backdropClick" && !editMode && onClose()
      }
      PaperProps={{
        elevation: 0,
        sx: {
          borderRadius: isSmall ? 0 : 2,
          height: { md: "90%", xs: "100%" },
          maxHeight: { md: "45em", xs: "100%" },
          position: "relative",
        },
      }}
    >
      {loading && <LinearProgress />}
      <Header
        title="Despesa por categoria"
        settings={settings}
        expenseId={expenseId}
        changed={changed}
        onEdit={onEdit}
        editMode={editMode}
        onCancelEdit={onCancelEdit}
        onClose={onClose}
        onSave={onSave}
        warnings={warnings}
        onViewWarnings={handleClickWarnings}
      />
      <DialogContent sx={{ p: 0, display: "flex", flexWrap: "wrap-reverse" }}>
        <Box
          height={{ xs: undefined, md: "100%" }}
          position="relative"
          width={{ xs: "100%", md: "26em" }}
          borderRight={1}
          borderColor={{ md: "divider", xs: "transparent" }}
          mr={1}
        >
          {receiptsContent}
        </Box>
        <Box
          height={{ xs: undefined, md: "100%" }}
          flex={1}
          display="flex"
          flexDirection={"column"}
        >
          <Box
            p={2}
            pl={1}
            pr={3}
            pt={3}
            pb={{ xs: 1, md: 10 }}
            flex={1}
            display="flex"
            flexDirection={"column"}
            flexBasis={{ md: 1, xs: undefined }}
            className="hover-scrollbar"
            id="view_expense_fields_content"
            overflow={{ xs: undefined, md: "auto" }}
            gap={3.5}
          >
            {editMode ? editContent : viewContent}
          </Box>
        </Box>
      </DialogContent>
      <ExpensePolicyLayer
        ref={expensePolicyRef}
        onCreate={onSave}
        isSave
        showRequiredReceipt={showRequiredReceipt}
      />
    </Dialog>
  );
}

export default memo(Layout);

import { InfoOutlined } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import React, { memo } from "react";
import { CurrencyFormat } from "../../../../utils/more/mask_functions";

function ConversionAlert({ conversion }) {
  return (
    <Box mt={-2}  display={"flex"} alignItems={"center"}>
      <Typography variant="body2">
        O valor será convertido para:{" "}
        <strong>
          {conversion.to} <CurrencyFormat value={conversion.calculated || 0} />
        </strong>
      </Typography>
      <Tooltip
        title={
          <>
            <Typography
              fontSize={".8rem"}
              gutterBottom
              fontWeight={"500"}
              variant="inherit"
            >
              1 {conversion.from} = {conversion.to}{" "}
              <CurrencyFormat value={conversion.factor || 0} />
            </Typography>
            <Typography
              variant="inherit"
              component={"div"}
              display={"flex"}
              fontSize={".8rem"}
              alignItems={"center"}
            >
              <Typography mr={1} variant="inherit">
                Última data da cotação:
              </Typography>
              <Typography variant="inherit" fontWeight={"500"}>
                {new Date(conversion.referenceDate).toLocaleDateString()}
              </Typography>
            </Typography>
          </>
        }
      >
        <span>
          <InfoOutlined fontSize="small" color="info" sx={{ mt: 0.2, ml: 1 }} />
        </span>
      </Tooltip>
    </Box>
  );
}

export default memo(ConversionAlert);

import { DarkModeOutlined, LightModeOutlined } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { memo } from "react";
import { useThemeMode } from "../../../contexts/useThemeMode";

const ToggleThemeButton = () => {
  const { mode, changeThemeMode } = useThemeMode();
  return (
    <Tooltip title="Alterar tema" placement="right">
      <IconButton
        onClick={() => {
          if (mode === "dark") {
            changeThemeMode("light");
          } else {
            changeThemeMode("dark");
          }
        }}
        sx={{
          mb: 2.5,
          alignSelf: "center",
        }}
      >
        {mode === "dark" ? <LightModeOutlined /> : <DarkModeOutlined />}
      </IconButton>
    </Tooltip>
  );
};

export default memo(ToggleThemeButton);

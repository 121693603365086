import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import {
    FormHelperText,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
} from "@mui/material";
import React, { memo, useEffect } from "react";

const data = {
  amount: "Valor",
  date: "Data",
  category: "Categoria",
  payment: "Forma de pagamento",
};

const ToggleOrder = ({ value, onChange = () => {}, orderBy, disabled }) => {
  useEffect(() => {
    if (orderBy === "payment" || orderBy === "category") {
      onChange("order", "asc");
    }
  }, [orderBy]);

  return (
    <>
      <ToggleButtonGroup
        color="primary"
        size="small"
        value={value}
        sx={{ height: 30, mt: 1, borderRadius: 1 }}
        exclusive
        disabled={disabled}
        onChange={(e, v) => v && onChange("order", v)}
      >
        <Tooltip title="Menor para o maior">
          <ToggleButton
            value={"desc"}
            sx={{ height: 30, px: 2, borderRadius: 1 }}
          >
            {["payment", "category"].includes(orderBy) ? "Z-A" : "Crescente"}
            <ArrowUpward sx={{ fontSize: "1rem", ml: 1 }} />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Maior para o menor">
          <ToggleButton
            value={"asc"}
            sx={{ height: 30, px: 2, borderRadius: 1 }}
          >
            {["payment", "category"].includes(orderBy) ? "A-Z" : "Decrescente"}
            <ArrowDownward sx={{ fontSize: "1rem", ml: 1 }} />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
      <FormHelperText sx={{ mt: 1 }}>
        {orderBy === "amount"
          ? value === "asc"
            ? "Ordenado do MAIOR valor para o MENOR (1-0)"
            : "Ordenado do MENOR valor para o MAIOR (0-1)"
          : orderBy === "date"
          ? value === "asc"
            ? "Ordenado pela data mais RECENTE para mais ANTIGA"
            : "Ordenado pela data mais ANTIGA para mais RECENTE"
          : value === "asc"
          ? "Ordenado de A-Z"
          : "Ordenado da Z-A"}
      </FormHelperText>
    </>
  );
};

export default memo(ToggleOrder);

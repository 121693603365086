import {
  AttachmentOutlined,
  OpenInNewOutlined,
  PictureAsPdfOutlined,
} from "@mui/icons-material";
import { Box, ImageList, Paper, Typography } from "@mui/material";
import React, { memo, useCallback, useMemo } from "react";

import NoDataContent from "../../../../../../components/form/NoDataContent";
import "../../../../../../main.css";
import { getReceiptUrl } from "../../../../../../utils/functions/receipts";
import ReceiptItem from "./ReceiptItem";

const ReceiptsSection = ({
  receipts = [],
  duplicatedReceipts = [],
  userId,
  isApprover,
}) => {
  const { images, pdfs } = useMemo(() => {
    return {
      images: receipts?.filter(
        (receipt) => receipt?.type !== "application/pdf"
      ),
      pdfs: receipts?.filter((receipt) => receipt?.type === "application/pdf"),
    };
  }, [receipts]);

  const openInNew = (url) => {
    window.open(getReceiptUrl(url));
  };

  const getDuplicatedReceipts = useCallback(
    (receiptId) => duplicatedReceipts?.filter((item) => item.id === receiptId),
    [duplicatedReceipts]
  );

  const renderReceipts = useCallback(
    (receipt) => {
      return (
        <ReceiptItem
          key={receipt?.id}
          receipt={receipt || {}}
          userId={userId}
          duplicatedReceipts={getDuplicatedReceipts(receipt?.id)}
          isApprover={isApprover}
        />
      );
    },
    [getDuplicatedReceipts, userId, isApprover]
  );

  return (
    <>
      <Typography fontSize={"1.2rem"} variant="h5" fontWeight={"500"}>
        Comprovantes{" "}
        {receipts && receipts.length > 0 ? `(${receipts.length})` : ""}
      </Typography>
      {receipts && receipts.length ? (
        <>
          <ImageList variant="masonry" cols={2} gap={7} sx={{ pb: 1 }}>
            {images.map(renderReceipts)}
          </ImageList>
          {Boolean(pdfs.length) && (
            <>
              <Typography mb={-1} color={"text.secondary"} fontWeight={"500"}>
                Arquivos PDF ({pdfs.length})
              </Typography>
              {pdfs.map((receipt) => (
                <Paper
                  onClick={() => openInNew(receipt.url)}
                  elevation={0}
                  key={receipt.id}
                  sx={{
                    transition: "none",
                    p: 2,
                    borderRadius: 2,
                    border: 1.5,
                    display: "flex",
                    alignItems: "center",
                    borderColor: "divider",
                    ":hover": { boxShadow: 3, cursor: "pointer" },
                  }}
                >
                  <PictureAsPdfOutlined fontSize="large" color="error" />
                  <Box flex={1} mx={2}>
                    <Typography maxWidth={300} noWrap fontWeight={"500"}>
                      {receipt.filename}
                    </Typography>
                    <Typography
                      fontStyle={"italic"}
                      fontSize={".8rem"}
                      variant="body2"
                      color={"text.secondary"}
                    >
                      Arquivo PDF
                    </Typography>
                  </Box>
                  <OpenInNewOutlined fontSize="small" />
                </Paper>
              ))}
            </>
          )}
        </>
      ) : (
        <NoDataContent
          height={150}
          Icon={AttachmentOutlined}
          subtitle={"Nenhum comprovante adicionado"}
        />
      )}
    </>
  );
};

export default memo(ReceiptsSection);

import { TableRow, tableCellClasses } from "@mui/material";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { selectPortalUtil } from "../../../store/features/portalSlice";

export const EXPENSE_BASE_HEIGHT = 130;

function Base({
  sx,
  children,
  selected,
  innerRef,
  rejected,
  expenseId,
  onClick,
  disabled,
}) {
  const expenseViewId = useSelector((state) =>
    selectPortalUtil(state, "expenseView", "expenseId")
  );

  return (
    <TableRow
      hover={!disabled}
      selected={selected || expenseViewId === expenseId}
      ref={innerRef}
      data-disableselect={true}
      id={`e-${expenseId}`}
      onClick={(e) => !disabled && onClick(e)}
      sx={{
        cursor: disabled ? "default" : "pointer",
        color: "text.primary",
        transition: "none",
        [`& .${tableCellClasses.root}`]: {
          bgcolor: (t) => (rejected ? `${t.palette.error.main}10` : undefined),
        },
        ...sx,
      }}
    >
      {children}
    </TableRow>
  );
}

export default memo(Base);

import React, { memo } from "react";
import { useSelector } from "react-redux";
import CustomField from "../../../components/inputs/CustomField";
import { selectRequestsCustomFieldsIds } from "../../../store/features/configs/customFieldsSlice";

const CustomFieldsForm = ({
  values = {},
  onChangeValue = () => {},
  variant,
}) => {
  const customFields = useSelector(selectRequestsCustomFieldsIds);
  return (
    <>
      {customFields?.map((customFieldId) => (
        <CustomField
          variant={variant}
          key={customFieldId}
          customFieldId={customFieldId}
          margin="normal"
          value={values[customFieldId] || ""}
          onChange={onChangeValue}
        />
      ))}
    </>
  );
};

export default memo(CustomFieldsForm);

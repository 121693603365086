import { Box, Typography } from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { HttpClient } from "../../../api/httpClient";
import RequestNotFound from "../../../components/RequestNotFound";
import LoadingSection from "../../../components/loading/LoadingSection";
import { selectAccountCurrency } from "../../../store/features/accountSlice";
import { selectApprovalRequestById } from "../../../store/features/approvalRequestsSlice";
import { setBreadcrumbs } from "../../../store/features/base/breadcrumbsSlice";
import { selectLayoutViewMode } from "../../../store/features/base/settingsSlice";
import { openElement } from "../../../store/features/portalSlice";
import formatAmount from "../../../utils/formatAmount";
import { getRequestLimitsAlerts } from "../../../utils/requestLimits";
import ExpensesRenderer from "../../expenses/components/ExpensesRenderer";
import BaseInfo from "./components/BaseInfo";
import Header from "./components/Header";
import HistoryDrawer from "./components/HistoryDrawer";
import ResumeModal from "./components/ResumeModal";
import ExpenseViewer from "./components/expense-viewer";
import ToggleRejection from "./components/expense-viewer/ToggleRejection";

const ApprovalRequestView = () => {
  const { requestId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currency = useSelector((state) => selectAccountCurrency(state, true));

  const requestData = useSelector((state) =>
    selectApprovalRequestById(state, requestId)
  );

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState(requestData || {});
  const [selectedExpenseView, setSelectedExpenseView] = useState(null);
  const [rejectedExpenses, setRejectedExpenses] = useState([]);
  const [resumeView, setResumeView] = useState(false);
  const [historyDrawer, setHistoryDrawer] = useState(false);

  const layoutViewMode = useSelector(selectLayoutViewMode);

  const rejectedExpensesRef = useRef();

  const {
    title,
    obs,
    sent_at,
    created_at,
    user,
    total_amount,
    total_refundable,
    total_expenses,
    expenses,
    advance,
    events,
    custom_fields,
    limits,
    current_step,
  } = details || {
    fetching: false,
    title: "",
    obs: "",
    sent_at: new Date().toISOString(),
    created_at: new Date().toISOString(),
    user: {},
    total_amount: 0,
    total_refundable: 0,
    total_expenses: 0,
    expenses: [],
    events: [],
    advance: null,
    custom_fields: null,
    limits: null,
    current_step: 1,
  };

  const expensesTableRef = useRef(null);
  const expensesRef = useRef(expenses || []);

  const isAdvance = useMemo(() => {
    return Boolean(advance?.status === "pending");
  }, [advance]);

  const hasWarning = useMemo(() => {
    return expenses?.some((exp) => Boolean(exp?.warnings?.length));
  }, [expenses]);

  const requiredReceipt = useMemo(() => {
    return expenses?.some((exp) =>
      Boolean(exp?.required_receipt && !exp?.receipts?.length)
    );
  }, [expenses]);

  useEffect(() => {
    rejectedExpensesRef.current = rejectedExpenses;
  }, [rejectedExpenses]);

  useEffect(() => {
    expensesRef.current = expenses;
  }, [expenses]);

  const getDetails = async () => {
    setLoading(true);
    try {
      const { data } = await HttpClient.get(
        `/approvers/pending/requests/${requestId}`
      );
      setDetails({
        ...(requestData || {}),
        ...details,
        ...data,
      });
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDetails();
    dispatch(
      setBreadcrumbs([
        "Para aprovar",
        isAdvance ? `${currency} ${formatAmount(advance?.amount || 0)}` : title,
      ])
    );
  }, []);

  const resume = useMemo(() => {
    let obj = {
      types: {},
      route: {
        total: 0,
        distance: 0,
        amount: 0,
      },
    };
    if (expenses && expenses.length) {
      for (let index = 0; index < expenses.length; index++) {
        const expense = expenses[index];
        if (expense.is_route) {
          obj.route.amount += expense.amount_converted || expense.amount;
          obj.route.total += 1;
          obj.route.distance += expense.distance;
          continue;
        }
        if (obj.types[expense.type_id]) {
          if (expense.type_id) {
            obj.types[expense.type_id].amount +=
              expense.amount_converted || expense.amount;
            obj.types[expense.type_id].total += 1;
          }
        } else {
          if (expense.type_id) {
            obj.types[expense.type_id] = {
              amount: expense.amount_converted || expense.amount,
              total: 1,
            };
          }
        }
      }
    }
    return obj;
  }, [expenses]);

  const activeIndexViewer = useMemo(() => {
    if (selectedExpenseView) {
      return expenses?.findIndex((exp) => exp.id === selectedExpenseView.id);
    } else {
      return 0;
    }
  }, [expenses, selectedExpenseView]);

  const handleExpenseClick = useCallback(
    (expenseId, isRoute, routeMode, expenseObj) => {
      setSelectedExpenseView(expenseObj);
    },
    []
  );

  const toggleRejectExpense = useCallback((expenseId) => {
    setRejectedExpenses((prev) => {
      const updatedExpenses = prev.slice(); // Crie uma cópia do array
      const expenseIndex = updatedExpenses.findIndex(
        (item) => item === expenseId
      );

      if (expenseIndex !== -1) {
        updatedExpenses.splice(expenseIndex, 1);
      } else {
        updatedExpenses.push(expenseId);
      }

      return updatedExpenses;
    });
  }, []);

  const handleNextExpense = useCallback(() => {
    const expenses =
      layoutViewMode === "grid"
        ? expensesRef?.current
        : expensesTableRef?.current?.getOrdenedExpenses();

    const currentIndex = expenses?.findIndex(
      (exp) => exp.id === selectedExpenseView?.id
    );
    const nextExpense = expenses[currentIndex + 1];
    if (!nextExpense) return;
    setSelectedExpenseView(nextExpense);
  }, [selectedExpenseView, layoutViewMode]);

  const handlePrevExpense = useCallback(() => {
    const expenses =
      layoutViewMode === "grid"
        ? expensesRef?.current
        : expensesTableRef?.current?.getOrdenedExpenses();

    const currentIndex = expenses?.findIndex(
      (exp) => exp.id === selectedExpenseView?.id
    );
    const nextExpense = expenses[currentIndex - 1];
    if (!nextExpense) return;
    setSelectedExpenseView(nextExpense);
  }, [selectedExpenseView, layoutViewMode]);

  const limitsAlerts = useMemo(
    () =>
      getRequestLimitsAlerts({
        currency,
        expenses,
        limits,
      }),
    [currency, expenses, limits]
  );

  const handleApprove = useCallback(() => {
    dispatch(
      openElement({
        name: "approvalModal",
        util: {
          requestId,
        },
      })
    );
  }, [requestId]);

  const handleReject = useCallback(() => {
    dispatch(
      openElement({
        name: "rejectModal",
        util: {
          requestId,
          rejectedExpenses: rejectedExpensesRef.current || [],
        },
      })
    );
  }, [requestId]);

  const isSelected = useCallback(
    (expenseId) =>
      Boolean(selectedExpenseView && selectedExpenseView.id === expenseId),
    [selectedExpenseView]
  );

  const isRejected = useCallback(
    (expenseId) => rejectedExpenses.includes(expenseId),
    [rejectedExpenses]
  );

  const openResume = useCallback(() => {
    setResumeView(true);
  }, []);
  const openHistoryDrawer = useCallback(() => {
    setHistoryDrawer(true);
  }, []);
  const closeHistoryDrawer = useCallback(() => {
    setHistoryDrawer(false);
  }, []);

  const renderCustomAction = useCallback(
    (expenseId) => {
      return (
        <ToggleRejection
          color={"error"}
          selected={isRejected(expenseId)}
          onChange={() => toggleRejectExpense(expenseId)}
        />
      );
    },
    [isRejected, toggleRejectExpense]
  );

  if (loading) {
    return <LoadingSection />;
  } else if (!requestId || Boolean(error)) {
    return (
      <RequestNotFound
        subtitle="Pode ser que o relatório ja tenha sido aprovado ou rejeitado"
        buttonText="Ver relatórios para aprovar"
        buttonAction={() => navigate("/approver", { replace: true })}
      />
    );
  }

  return (
    <Box color={"text.primary"}>
      <Box flex={1} display={"flex"} flexDirection={"column"}>
        <Header
          title={title}
          onApprove={handleApprove}
          onReject={handleReject}
          isAdvance={isAdvance}
          advanceAmount={advance?.amount || 0}
          requestId={requestId}
          selectedExpenseView={Boolean(selectedExpenseView)}
        />
        <Box
          onClick={() => setSelectedExpenseView(null)}
          p={3}
          flex={1}
          pb={15}
        >
          <BaseInfo
            currency={currency}
            obs={obs}
            createdAt={created_at}
            title={title}
            sendAt={sent_at}
            totalAmount={total_amount || 0}
            totalExpenses={total_expenses || 0}
            totalRefundable={total_refundable || 0}
            user={user}
            isAdvance={isAdvance}
            advance={advance}
            requiredReceipt={requiredReceipt}
            hasWarning={hasWarning}
            onClickResume={openResume}
            openHistoryDrawer={openHistoryDrawer}
            requestId={requestId}
            customFields={custom_fields}
            alerts={limitsAlerts}
            currentStep={current_step || 1}
          />
          {!isAdvance && (
            <>
              <Typography
                mt={3}
                ml={1}
                mb={2}
                fontSize={"1.25rem"}
                fontWeight={"600"}
              >
                Despesas ({total_expenses})
              </Typography>
              <Box
                m={-3}
                mx={-2}
                mt={layoutViewMode === "grid" ? -2 : -1}
                overflow={"auto"}
                marginRight={
                  selectedExpenseView ? { xs: "25rem", xl: "34.3em" } : 0
                }
              >
                <ExpensesRenderer
                  allExpenses={expenses}
                  isSelected={isSelected}
                  isRejected={isRejected}
                  expensesTableRef={expensesTableRef}
                  onExpenseClick={handleExpenseClick}
                  disableActions
                  renderCustomAction={renderCustomAction}
                  enableSelectScrollIntoView={true}
                  expenseSettings={{
                    compact: true,
                    addToRequest: false,
                    remove: false,
                    removeFromRequest: false,
                    select: false,
                  }}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
      <HistoryDrawer
        open={historyDrawer}
        onClose={closeHistoryDrawer}
        events={events}
        title={title}
      />
      <ResumeModal
        curr={currency}
        open={resumeView}
        onClose={() => setResumeView(false)}
        totalAmount={total_amount || 0}
        totalRefundable={total_refundable || 0}
        resume={resume}
      />
      <ExpenseViewer
        open={Boolean(selectedExpenseView)}
        expense={selectedExpenseView || {}}
        isRejected={rejectedExpenses.includes(selectedExpenseView?.id)}
        onClose={() => setSelectedExpenseView(null)}
        toggleRejectExpense={toggleRejectExpense}
        activeIndex={activeIndexViewer}
        totalExpenses={expenses?.length}
        onNextExpense={handleNextExpense}
        onPrevExpense={handlePrevExpense}
        userId={user?._id}
      />
    </Box>
  );
};

export default ApprovalRequestView;

import React from "react";
import { NumericFormat } from "react-number-format";

export const CnpjFormat = React.forwardRef(function CnpjFormat(props, ref) {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="##.###.###/####-##"
    />
  );
});

export const CnpjPlaceholder = "00.000.000/0000-00";

export const CurrencyInputMask = React.forwardRef(function CurrencyInputMask(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      displayType="input"
      allowNegative={false}
      valueIsNumericString
      fixedDecimalScale={true}
      decimalScale={2}
      decimalSeparator=","
      thousandSeparator="."
    />
  );
});

export const CurrencyFormat = React.forwardRef(function CurrencyFormat(
  props,
  ref
) {
  const { ...other } = props;
  return (
    <NumericFormat
      {...other}
      displayType="text"
      allowNegative={false}
      fixedDecimalScale={true}
      valueIsNumericString
      decimalScale={2}
      decimalSeparator=","
      thousandSeparator="."
    />
  );
});

import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
} from "@mui/material";
import React, { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HttpClient } from "../../../../../api/httpClient";
import Input from "../../../../../components/inputs/text-input";
import { selectAccountCurrency } from "../../../../../store/features/accountSlice";
import { setError } from "../../../../../store/features/base/errorBaseSlice";
import { openSnackbar } from "../../../../../store/features/base/snackbarBaseSlice";
import {
  advanceRequest
} from "../../../../../store/features/requestsSlice";
import { CurrencyInputMask } from "../../../../../utils/more/mask_functions";

const AdvanceRequestModal = ({
  open,
  onClose,
  initialAdvance = {},
  requestId,
}) => {

  const currency = useSelector(state => selectAccountCurrency(state, true))

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [amount, setAmount] = useState("");
  const [reason, setReason] = useState("");

  const isOk = useMemo(() => Boolean(amount), [amount]);

  useEffect(() => {
    setAmount(initialAdvance?.amount || "");
    setReason(initialAdvance?.reason || "");
  }, [open]);

  const handleConfirm = async () => {
    setLoading(true);
    try {
      const { data } = await HttpClient.post(`requests/${requestId}/advance`, {
        amount,
        reason,
      });
      dispatch(
        advanceRequest({
          requestId,
          responseData: data,
        })
      );
      onClose();
      dispatch(
        openSnackbar({
          message: "Adiantamento solicitado",
        })
      );
    } catch (error) {
      dispatch(
        setError({
          title: "Erro ao solicitar adiantamento",
          error,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      {loading && <LinearProgress />}
      <DialogTitle>
        Solicitar adiantamento <Box flex={1} />
        <IconButton onClick={onClose} sx={{ m: -1 }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Input
          autoFocus
          margin={"dense"}
          variant={"filled"}
          label={"Valor desejado"}
          LeftIcon={() => currency}
          inputComponent={CurrencyInputMask}
          placeholder={"0,00"}
          value={amount}
          onChange={setAmount}
          required
        />
        <Input
          margin={"dense"}
          variant={"filled"}
          label={"Motivo"}
          value={reason}
          onChange={setReason}
          multiline
          rows={7}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          disabled={!isOk || loading}
          variant="contained"
          disableElevation
          onClick={handleConfirm}
        >
          {loading ? "Solicitando" : "Solicitar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(AdvanceRequestModal);

import { API_BASE_URL } from "../../api/urls";
import { store } from "../../store/store";

export const getProfilePicture = (userId, version = 0) => {
  const hasPhoto = store.getState().account?.hasPhoto;
  if (hasPhoto) {
    return `${API_BASE_URL}/photo/user/${userId}?version=${version}`;
  } else {
    return undefined;
  }
};

import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { APIActions } from "../../../queues/offline-queue/effect";
import normalize from "../../../utils/normalize";

const baseName = "expenseTypes";

const adapter = createEntityAdapter({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) =>
    a.name?.toUpperCase().localeCompare(b.name?.toUpperCase()),
});

const initialState = adapter.getInitialState({
  working: false,
});

export const syncExpenseTypes = createAsyncThunk(
  `${baseName}/syncExpenseTypes`,
  async () => {
    const res = await APIActions.expenseTypes.sync();
    return res;
  }
);

export const expenseTypesSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    setupExpenseTypes(state, { payload }) {
      const { entities } = normalize(payload);
      adapter.setAll(state, entities);
    },
    upsertExpenseType(state, { payload }) {
      adapter.upsertOne(state, payload);
    },
    removeExpenseType(state, { payload }) {
      adapter.removeOne(state, payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(syncExpenseTypes.pending, (state, action) => {
        state.working = true;
      })
      .addCase(syncExpenseTypes.rejected, (state, action) => {
        state.working = false;
      })
      .addCase(syncExpenseTypes.fulfilled, (state, action) => {
        state.working = false;
        const data = action.payload;
        expenseTypesSlice.caseReducers.setupExpenseTypes(state, {
          payload: data,
        });
      });
  },
});

export const { setupExpenseTypes, upsertExpenseType, removeExpenseType } =
  expenseTypesSlice.actions;

export const {
  selectAll: selectAllExpenseTypes,
  selectById: selectExpenseTypeById,
  selectIds,
  selectEntities: selectExpenseTypesEntities,
} = adapter.getSelectors((state) => state[baseName]);

export default expenseTypesSlice.reducer;

export const selectExpenseTypesIds = createSelector(
  [selectAllExpenseTypes, (state, showInactive) => showInactive],
  (data, showInactive) => {
    return data
      .filter((item) => (showInactive ? true : Boolean(item.active)))
      .map((item) => item.id);
  }
);

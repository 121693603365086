import { Box, Paper } from "@mui/material";
import React, { memo, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectPortalUtil } from "../../../store/features/portalSlice";
import ContextMenuWrapper from "../ContextMenuWrapper";

export const EXPENSE_BASE_HEIGHT = 120;

function Base({
  sx,
  children,
  selected,
  innerRef,
  onClick = () => {},
  rejected,
  checkbox,
  expenseId,
  requestId,
  disabled,
  settings,

  sync,
  syncError,
}) {
  const [hovered, setHovered] = useState(false);

  const expenseViewId = useSelector((state) =>
    selectPortalUtil(state, "expenseView", "expenseId")
  );

  const isSelected = useMemo(
    () => selected || expenseViewId === expenseId,
    [expenseViewId, expenseId, selected]
  );

  const renderComponent = ({ setMenu }) => {
    return (
      <Box
        draggable={false}
        ref={innerRef}
        onContextMenu={(e) => {
          e.preventDefault();
          setMenu(e.target);
        }}
        data-disableselect={true}
        id={`e-${expenseId}`}
        minWidth={340}
        height={EXPENSE_BASE_HEIGHT}
        boxShadow={0}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={(e) => {
          e.stopPropagation();
          if (disabled) return;
          onClick();
        }}
        component={Paper}
        borderRadius={2.5}
        bgcolor={(t) =>
          isSelected
            ? rejected
              ? `${t.palette.error.main}15`
              : `${t.palette.primary.main}15`
            : rejected && `${t.palette.error.main}10`
        }
        position="relative"
        elevation={0}
        zIndex={1}
        border={"1.12pt solid"}
        sx={{
          transition: "none",
          cursor: disabled ? "default" : "pointer",
          outline: (t) => isSelected && `1.5pt solid ${t.palette.primary.main}`,
          ":hover": {
            boxShadow: 4,
          },
          borderColor: (t) =>
            isSelected
              ? "transparent"
              : (syncError && !sync) || rejected
              ? `${t.palette.error.main}`
              : t.palette.mode === "light"
              ? "rgba(0,0,0,.23)"
              : "rgba(255,255,255,.23)",
          ...sx,
        }}
        display={"flex"}
        flexDirection={"column"}
      >
        {children}
        {(hovered || selected) && checkbox}
      </Box>
    );
  };

  return (
    <ContextMenuWrapper
      expenseId={expenseId}
      requestId={requestId}
      settings={settings}
      renderComponent={renderComponent}
    />
  );
}

export default memo(Base);

import { AssignmentOutlined, PaidOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React, { memo, useState } from "react";

const TabItem = memo(({ title, selected, Icon, onClick }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <Box
      component={"div"}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => {
        setHovered(false);
        onClick();
      }}
      sx={{
        cursor: "pointer",
        bgcolor: (t) =>
          selected ? `${t.palette.primary.main}30` : "transparent",
        ":hover": {
          bgcolor: (t) =>
            selected ? `${t.palette.primary.main}35` : "action.hover",
        },
      }}
      px={3}
      py={1.3}
      borderRadius={100}
      display={"flex"}
      alignItems={"center"}
      gap={1.5}
    >
      {Icon && (
        <Icon
          fontSize="small"
          sx={{
            color: selected || hovered ? "text.primary" : "text.secondary",
          }}
        />
      )}
      <Typography
        color={selected || hovered ? "text.primary" : "text.secondary"}
        fontWeight={"600"}
        fontSize={".95rem"}
      >
        {title}
      </Typography>
    </Box>
  );
});

const TabNavigator = ({
  tab,
  onChange,
  advancesTotal = 0,
  requestsTotal = 0,
}) => {
  return (
    <Box
      m={2}
      mt={1}
      alignSelf={"flex-start"}
      display={"flex"}
      alignItems={"center"}
      borderRadius={100}
      bgcolor={"elevation1.main"}
    >
      <TabItem
        selected={tab === 0}
        onClick={() => onChange(0)}
        title={`Relatórios ${requestsTotal ? `(${requestsTotal})` : ""}`}
        Icon={AssignmentOutlined}
      />
      <TabItem
        selected={tab === 1}
        onClick={() => onChange(1)}
        title={`Adiantamentos ${advancesTotal ? `(${advancesTotal})` : ""}`}
        Icon={PaidOutlined}
      />
    </Box>
  );
};

export default memo(TabNavigator);

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  LinearProgress,
  MenuItem,
} from "@mui/material";
import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { syncAll } from "../../../../../api/synchronizer";
import { openSnackbar } from "../../../../../store/features/base/snackbarBaseSlice";

const SyncAllItem = ({ closeMenu }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSync = async () => {
    setLoading(true);
    syncAll({
      onError: (error) => {
        dispatch(
          openSnackbar({
            message: "Falha ao sincronizar dados",
            error: true,
          })
        );
      },
      onSuccess: () => {
        dispatch(openSnackbar({ message: "Conta sincronizada com sucesso" }));
      },
      onFinally: () => {
        setOpen(false);
        setLoading(false);
        closeMenu();
      },
    });
  };

  return (
    <>
      <MenuItem
        onClick={() => {
          setOpen(true);
        }}
      >
        Sincronizar configurações da conta
      </MenuItem>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={() => setOpen(false)}
      >
        {loading && <LinearProgress />}
        <DialogTitle fontSize={"1.1rem"}>
          Deseja sincronizar todas as configurações da conta ?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancelar</Button>
          <Button
            variant="contained"
            disableElevation
            disabled={loading}
            onClick={handleSync}
          >
            {loading ? "Sincronizando..." : "Sincronizar"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default memo(SyncAllItem);

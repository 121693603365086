import { DeleteOutlineOutlined } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import ModalDelete from "../../../../ModalDelete";

const RemoveReceipt = ({ onRemove, filename }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Tooltip title="Remover">
        <IconButton
          onClick={(event) => setOpen(true)}
          size="small"
          sx={{ color: "white" }}
        >
          <DeleteOutlineOutlined fontSize="small" />
        </IconButton>
      </Tooltip>
      <ModalDelete
        open={open}
        onClose={() => setOpen(false)}
        title="Remover comprovante"
        onDelete={onRemove}
        subtitle={`Tem certeza que deseja remover o comprovante "${
          filename && filename
        }"`}
      />
    </>
  );
};

export default RemoveReceipt;

import { openSnackbar } from "../../../store/features/base/snackbarBaseSlice";
import { store } from "../../../store/store";

export const calculateRoute = async (array) => {
  if (array.every(Boolean)) {
    try {
      const origin = array[0];
      const destination = array[array.length - 1];
      const waypoints = array.slice(1, -1);
      const directionsService = new window.google.maps.DirectionsService();
      const results = await directionsService.route({
        origin,
        destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
        waypoints:
          waypoints &&
          waypoints.map((loc) => ({
            location: loc,
          })),
        optimizeWaypoints: true,
      });
      let total = 0;
      const legs = results.routes[0].legs;
      for (let index = 0; index < legs.length; index++) {
        const leg = legs[index];
        total = total + leg.distance.value;
      }
      total = total / 1000;
      const dist = total.toFixed(2);
      return {
        results,
        distance: dist,
      };
    } catch (error) {
      store.dispatch(
        openSnackbar({ error: true, message: "Não foi possível buscar a rota" })
      );
      return null;
    }
  } else {
    return;
  }
};

import { InfoOutlined } from "@mui/icons-material";
import { Box, Divider, Tooltip, Typography } from "@mui/material";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { selectAccountCurrency } from "../../../../store/features/accountSlice";
import formatAmount from "../../../../utils/formatAmount";

const AdvanceInfo = ({ amount = 0, reason }) => {
  const currency = useSelector(selectAccountCurrency);
  return (
    <Tooltip
      placement="left"
      onClick={(e) => e.stopPropagation()}
      slotProps={{
        tooltip: {
          sx: {
            bgcolor: "background.default",
            boxShadow: 5,
          },
        },
      }}
      title={
        <>
          <Box onClick={(e) => e.stopPropagation()}>
            <Typography
              variant="body2"
              color={"text.secondary"}
              fontWeight={"500"}
            >
              Valor solicitado
            </Typography>
            <Typography color={"text.primary"} fontWeight={"600"}>
              {currency} {formatAmount(amount)}
            </Typography>
            <Divider sx={{ my: 0.5, bgcolor: "#FFF" }} />
            <Typography
              variant="body2"
              fontWeight={"500"}
              color={"text.secondary"}
            >
              Motivo
            </Typography>
            <Typography
              color={reason ? "text.primary" : "text.secondary"}
              variant="body2"
              fontWeight={reason ? "500" : "400"}
            >
              {reason || "Nenhum motivo informado"}
            </Typography>
          </Box>
        </>
      }
    >
      <Box
        onClick={(e) => e.stopPropagation()}
        borderRadius={100}
        height={25}
        width={25}
        display={"flex"}
        mr={-0.5}
        ml={0.5}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          cursor: "default",
          ":hover": {
            bgcolor: "action.hover",
          },
        }}
      >
        <InfoOutlined color="action" sx={{ fontSize: "1.1rem" }} />
      </Box>
    </Tooltip>
  );
};

export default memo(AdvanceInfo);

import { PostAddOutlined } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import React, { memo } from "react";

export const DetailSmallItem = memo(
  ({ Icon, children, emptyText = "", maxWidth = "90%" }) => {
    return (
      <Box maxWidth={maxWidth} display={"flex"} alignItems={"center"}>
        {Icon && (
          <Box width={33}>
            <Icon
              color="action"
              sx={{ opacity: 0.7, mt: 0.4, fontSize: "1rem" }}
            />
          </Box>
        )}
        <Typography
          flex={1}
          noWrap
          sx={{ wordBreak: "break-all" }}
          lineHeight={"normal"}
          color={"text.secondary"}
          fontWeight={children ? "500" : "400"}
          fontStyle={children ? "normal" : "italic"}
          fontSize={"13px"}
        >
          {children || emptyText}
        </Typography>
      </Box>
    );
  }
);

export const CustomIcon = memo(({ Icon, color = "action", tooltip, sx }) => {
  return (
    <Tooltip
      arrow
      title={
        <Typography fontSize={".8rem"} fontWeight={"500"}>
          {tooltip || ""}
        </Typography>
      }
    >
      <Box
        p={0.7}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        borderRadius={100}
        zIndex={10}
        sx={{
          cursor: "default",
          ":hover": { bgcolor: "action.hover", ...sx },
        }}
      >
        <Icon color={color} sx={{ opacity: 1, fontSize: ".8rem" }} />
      </Box>
    </Tooltip>
  );
});

function MoreDetails(props) {
  return (
    <Box display={"flex"} alignItems={"center"} p={0.5}>
      <CustomIcon Icon={PostAddOutlined} />
    </Box>
  );
}

export default MoreDetails;

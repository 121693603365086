import { Box, LinearProgress, List, useScrollTrigger } from "@mui/material";
import React, { useState } from "react";
import LoadingSection from "../../../loading/LoadingSection";
import NotificationsHeader from "./NotificationsHeader";

function Layout({ num, children, loading, onClose, filter, onChangeFilter }) {
  const [content, setContent] = useState(undefined);

  const scrolled = useScrollTrigger({
    disableHysteresis: true,
    target: content,
    threshold: 0,
  });

  return (
    <>
      <NotificationsHeader
        loading={loading}
        scrolled={scrolled}
        onClose={onClose}
      />
      {/* <FiltersSection filter={filter} onChangeFilter={onChangeFilter} /> */}
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          position: "relative",
        }}
      >
        <LinearProgress
          sx={{
            position: "absolute",
            top: 0,
            height: loading && num > 0 ? 2 : 0,
            transition: ".2s ease",
            width: "100%",
          }}
        />
        {loading && num === 0 ? (
          <LoadingSection />
        ) : (
          <Box width={"100%"} flex={1} flexBasis={0}>
            {children}
          </Box>
        )}
      </List>
    </>
  );
}

export default Layout;

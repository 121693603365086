import { OpenInNewOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";
import React, { memo, useEffect, useMemo, useState } from "react";
import Zoom from "react-medium-image-zoom";
import { getReceiptUrl } from "../../../utils/functions/receipts";
import ExpenseViewMode from "../../expense-view/expense/contents/view/ViewMode";
import ManualRouteViewMode from "../../expense-view/manual-route/contents/view/ViewMode";

const RightContent = ({ expense = {}, currentDuplicated = {}, onClose }) => {
  const [loadingReceipt, setLoadingReceipt] = useState(true);

  const { shadows } = useTheme();

  const toReceiptURL = useMemo(
    () =>
      expense?.receipts?.find(
        (receipt) => receipt.id === currentDuplicated?.to_receipt_id
      )?.url,
    [currentDuplicated, expense]
  );

  useEffect(() => {
    setLoadingReceipt(false);
  }, [toReceiptURL]);

  return (
    <>
      <Box mb={2} display={"flex"} alignItems={"center"}>
        <Typography
          color={"text.secondary"}
          fontWeight={"500"}
          fontSize={"1.1rem"}
        >
          Comprovante
        </Typography>
        <Button
          onClick={() => window.open(getReceiptUrl(toReceiptURL))}
          sx={{ ml: "auto", px: 0 }}
          size="small"
          startIcon={<OpenInNewOutlined />}
        >
          Ver comprovante
        </Button>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        pt={0}
        pb={2}
        position={"relative"}
        sx={{ transition: ".2s ease" }}
        minHeight={loadingReceipt ? "300px" : undefined}
      >
        <Zoom>
          <img
            style={{
              borderRadius: "10px",
              opacity: loadingReceipt ? 0 : 1,
              boxShadow: shadows[15],
            }}
            width={"200px"}
            src={getReceiptUrl(toReceiptURL)}
            onLoad={() => setLoadingReceipt(false)}
          />
        </Zoom>
        {loadingReceipt && (
          <Skeleton
            variant="rectangular"
            sx={{ position: "absolute", minHeight: "280px" }}
            width={"200px"}
            height={"90%"}
          />
        )}
      </Box>
      <Divider
        sx={{ mx: -3, mt: loadingReceipt ? 0 : 4, transition: ".2s ease" }}
      />
      <Box flex={1} py={2}>
        <Typography
          color={"text.secondary"}
          mb={2}
          fontWeight={"500"}
          fontSize={"1.1rem"}
        >
          Informações da despesa
        </Typography>
        {expense?.route_mode === "manual" ? (
          <ManualRouteViewMode
            expense={expense}
            showRequest
            callback={onClose}
          />
        ) : (
          <ExpenseViewMode expense={expense} showRequest callback={onClose} />
        )}
      </Box>
    </>
  );
};

export default memo(RightContent);

import { TableCell, Typography } from "@mui/material";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { selectPaymentTypeById } from "../../../store/features/configs/paymentTypesSlice";

const PaymentCell = ({ paymentId }) => {
  const payment = useSelector((state) =>
    selectPaymentTypeById(state, paymentId)
  );
  return (
    <TableCell>
      <Typography variant="inherit" noWrap>
        {payment?.name || "--"}
      </Typography>
    </TableCell>
  );
};

export default memo(PaymentCell);

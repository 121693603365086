import { Box, Divider, Stack, Typography } from "@mui/material";
import React, { memo } from "react";

const CustomFieldsSection = ({ customFields = {}, dividers = true, sx }) => {
  return (
    <Box sx={sx}>
      {dividers && <Divider sx={{ mt: 1 }} />}
      {Object?.keys(customFields)?.map((fieldId) => (
        <Stack
          key={fieldId}
          direction={"column"}
          borderBottom={dividers ? 1 : 0}
          borderColor={"divider"}
          py={0.7}
        >
          <Typography
            variant="caption"
            fontWeight={"500"}
            color={"text.secondary"}
          >
            {customFields[fieldId]?.label}
          </Typography>
          <Typography variant="body2" fontWeight={"500"}>
            {customFields[fieldId]?.text}
          </Typography>
        </Stack>
      ))}
    </Box>
  );
};

export default memo(CustomFieldsSection);

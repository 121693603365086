import { Box, Typography } from "@mui/material";

const DetailLabel = ({ children, width = 170, label = "", sx }) => {
  return (
    <Box display={"flex"} alignItems={"flex-start"}>
      <Typography
        width={width}
        variant="body2"
        fontWeight={"500"}
        color={"text.secondary"}
        sx={sx}
      >
        {label}
      </Typography>
      <Box flex={1}>
        <Typography sx={{cursor: 'text'}} component={"div"} fontWeight={"500"} variant="body2">
          {children || "--"}
        </Typography>
      </Box>
    </Box>
  );
};

export default DetailLabel;

import { TodayOutlined } from "@mui/icons-material";
import { List, ListItemButton, ListItemText } from "@mui/material";
import React, { memo, useMemo, useState } from "react";
import FilterChip from "../../../../../../components/chip/FilterChip";
import MenuBase from "./MenuBase";

const dateOptions = {
  today: "Hoje",
  this_month: "Este mês",
  last_month: "Mês passado",
  last_90_days: "Últimos 90 dias",
};

const DateFilterMenu = ({
  anchorEl,
  onClose,
  filter,
  onClickItem,
  onClear,
}) => {
  return (
    <MenuBase
      onClear={onClear}
      disabledClear={!Boolean(filter.length)}
      title={"Escolha um período"}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <List disablePadding>
        {Object.keys(dateOptions).map((option) => {
          const selected = filter === option;
          return (
            <ListItemButton
              key={option}
              sx={{ transition: "none" }}
              onClick={() => onClickItem(option)}
              selected={selected}
            >
              <ListItemText
                primaryTypographyProps={{
                  fontWeight: "600",
                  fontSize: ".95rem",
                  noWrap: true,
                }}
                primary={dateOptions[option]}
              />
            </ListItemButton>
          );
        })}
      </List>
    </MenuBase>
  );
};

function DateFilter({ filter = "", onChange, onClear }) {
  const filtered = useMemo(() => Boolean(filter), [filter]);
  const [menu, setMenu] = useState(null);

  const handleClickItem = (option) => {
    if (filter === option) {
      onClear();
    } else {
      onChange(option);
    }
    setMenu(null);
  };

  return (
    <>
      <FilterChip
        onClick={(e) => setMenu(e.target)}
        filtered={filtered}
        Icon={TodayOutlined}
        label={"Data"}
        opened={Boolean(menu)}
        filteredLabel={filter && dateOptions[filter]}
        isDropdown
      />
      <DateFilterMenu
        anchorEl={menu}
        filter={filter || ""}
        onClose={() => setMenu(null)}
        onClickItem={handleClickItem}
        onClear={() => {
          onClear();
          setMenu(null);
        }}
      />
    </>
  );
}

export default memo(DateFilter);

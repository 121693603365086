import {
  ChevronRight,
  Close,
  NotesOutlined,
  RefreshOutlined,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import React, { memo, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoDataContent from "../../../components/form/NoDataContent";
import {
  selectAllRejectionReasons,
  syncRejectionReasons,
} from "../../../store/features/configs/rejectionReasonsSlice";

const RejectionModalItem = memo(({ reason, onClick = () => {}, selected }) => {
  return (
    <ListItemButton
      divider
      sx={{ px: 3, transition: "none" }}
      selected={selected}
      onClick={() => {
        onClick(reason);
      }}
    >
      <ListItemText
        primaryTypographyProps={{ fontSize: ".925rem" }}
        primary={reason}
      />
      <ChevronRight color="action" />
    </ListItemButton>
  );
});

const ModalReasons = memo(
  ({ open, onClose, reasons = [], onSelect = () => {}, selected }) => {
    const dispatch = useDispatch();

    const handleSelect = useCallback((v) => {
      onSelect(v);
      onClose();
    }, []);

    return (
      <Dialog
        maxWidth="xs"
        TransitionProps={{
          unmountOnExit: true,
        }}
        hideBackdrop
        PaperProps={{ sx: { height: "90%", maxHeight: "45em" } }}
        fullWidth
        open={open}
        onClose={onClose}
      >
        <DialogTitle height={60} fontSize={"1.2rem"}>
          Motivos de rejeição <Box flex={1} />
          <IconButton
            onClick={() => dispatch(syncRejectionReasons())}
            sx={{ mr: 3 }}
          >
            <RefreshOutlined />
          </IconButton>
          <IconButton onClick={onClose} sx={{ m: -1 }}>
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ p: 0 }}>
          {reasons.length ? (
            <List disablePadding>
              {reasons.map((reason, idx) => (
                <RejectionModalItem
                  reason={reason}
                  key={idx.toString()}
                  onClick={handleSelect}
                  selected={selected === reason}
                />
              ))}
            </List>
          ) : (
            <NoDataContent
              Icon={NotesOutlined}
              subtitle={"Nenhum motivo encontrado"}
            />
          )}
        </DialogContent>
      </Dialog>
    );
  }
);

const RejectionReasonInput = ({ value = "", onChange, sx }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const status = useSelector((state) => state.rejectionReasons.status);
  const rejectionReasons = useSelector(selectAllRejectionReasons);

  const data = useMemo(() => {
    return rejectionReasons?.map((reason) => reason.text);
  }, [rejectionReasons]);

  const onOpen = () => {
    if (status !== "succeeded") {
      dispatch(syncRejectionReasons());
    }
  };

  const renderPopper = useCallback((params) => {
    return (
      <Popper
        {...params}
        placement="auto"
        sx={{ boxShadow: !data?.length ? 0 : 10 }}
      />
    );
  }, [data]);

  return (
    <>
      <Autocomplete
        value={value}
        onInputChange={(e, v) => onChange(v)}
        freeSolo
        PopperComponent={renderPopper}
        options={data}
        sx={{ position: "relative", ...sx }}
        renderOption={(props, option, state) => (
          <ListItemButton
            {...props}
            selected={state.selected}
            sx={{ transition: "none" }}
            divider
          >
            <Typography fontSize={".92rem"} fontWeight={"500"}>
              {option}
            </Typography>
          </ListItemButton>
        )}
        disableClearable={!data?.length}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <Button
                    onClick={() => setOpen(true)}
                    sx={{ position: "absolute", bottom: 10, left: 10, px: 1 }}
                  >
                    Ver motivos
                  </Button>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
            margin="normal"
            placeholder="Motivo da rejeição, clique para ver opções..."
            multiline
            rows={7}
          />
        )}
        onOpen={onOpen}
      />
      <ModalReasons
        open={open}
        reasons={data}
        onClose={useCallback(() => setOpen(false), [])}
        onSelect={onChange}
        selected={value}
      />
    </>
  );
};

export default memo(RejectionReasonInput);

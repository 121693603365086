import { Box, LinearProgress } from "@mui/material";
import { DirectionsRenderer } from "@react-google-maps/api";
import React, { memo } from "react";
import Map from "../../../../components/google-maps/Map";

const MapComponent = memo(({ directionsResponse }) => {
  return (
    <Map mapContainerStyle={{ flex: 1, width: "100%" }}>
      {directionsResponse && (
        <DirectionsRenderer directions={directionsResponse} />
      )}
    </Map>
  );
});

function RightContent({
  directionsResponse,
  onClose,
  onCreate,
  loadingMap,
  disabledCreate,
}) {
  return (
    <Box
      display="flex"
      flexDirection={"column"}
      width={"100%"}
      flex={1}
      height={"100%"}
    >
      <LinearProgress sx={{ visibility: loadingMap ? "visible" : "hidden" }} />
      <Box flex={1} display="flex" flexDirection={"column"}>
        <MapComponent directionsResponse={directionsResponse} />
      </Box>
    </Box>
  );
}

export default memo(RightContent);

import {
  Checkbox,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { memo, useCallback } from "react";
import { useSelector } from "react-redux";
import { selectAccountCurrency } from "../../../../../../store/features/accountSlice";
import { selectApprovalRequestById } from "../../../../../../store/features/approvalRequestsSlice";
import { selectPortalUtil } from "../../../../../../store/features/portalSlice";
import formatAmount from "../../../../../../utils/formatAmount";
import SentAtCell from "../../../../components/cells/SentAtCell";
import UserCell from "../../../../components/cells/UserCell";

const TableItem = ({ requestId, onClick, selected, onChangeSelected }) => {
  const currency = useSelector((state) => selectAccountCurrency(state, true));

  const { title, user, sent_at, advance, current_step } = useSelector((state) =>
    selectApprovalRequestById(state, requestId)
  );

  const handleClick = useCallback(() => {
    onClick(requestId);
  }, [requestId]);

  const isSelected = useSelector((state) => {
    return Boolean(
      (selectPortalUtil(state, "rejectModal", "requestId") ||
        selectPortalUtil(state, "approvalModal", "requestId")) === requestId
    );
  });

  return (
    <TableRow
      id={`r-${requestId}`}
      selected={selected || isSelected}
      onClick={handleClick}
      hover
      sx={{
        cursor: "pointer",
      }}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={selected}
          onClick={(e) => {
            e.stopPropagation();
            onChangeSelected(requestId);
          }}
          color="primary"
        />
      </TableCell>
      <UserCell {...user} />
      <TableCell>
        <Typography minWidth={100} noWrap variant="inherit" fontWeight={"500"}>
          {currency} {formatAmount(advance?.amount)}
        </Typography>
      </TableCell>
      <TableCell>
        <Tooltip title={title}>
          <Typography
            maxWidth={280}
            noWrap
            variant="inherit"
            fontWeight={"500"}
          >
            {title}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Typography
          noWrap
          variant="inherit"
          color={"text.secondary"}
          fontWeight={"500"}
        >
          Etapa {current_step}
        </Typography>
      </TableCell>
      <SentAtCell requestId={requestId} sentAt={sent_at} />
    </TableRow>
  );
};

export default memo(TableItem);

import { Box, FormHelperText, Typography } from "@mui/material";
import React, { memo } from "react";

function Baseline({ Icon, value, label, emptyLabel, error = "" }) {
  return (
    <Box display={"flex"} alignItems={"center"}>
      <Box width={40} mr={1.4} display={"flex"} justifyContent={"center"}>
        {Icon && <Icon color={"action"} sx={{ fontSize: "1.4rem" }} />}
      </Box>
      <Box>
        <Typography
          gutterBottom
          variant="body2"
          fontSize={".875rem"}
          color={"text.secondary"}
          fontWeight={"500"}
        >
          {label}
        </Typography>
        <Typography
          maxWidth={400}
          variant="body2"
          sx={{ wordBreak: "break-all" }}
          fontSize={".93rem"}
          color={
            value ? (error ? "warning.main" : "text.primary") : "text.secondary"
          }
          fontWeight={value ? "500" : "400"}
        >
          {value || emptyLabel}
        </Typography>
        {error && value && (
          <FormHelperText sx={{ color: "warning.main" }}>
            {error}
          </FormHelperText>
        )}
      </Box>
    </Box>
  );
}

export default memo(Baseline);

import { LocationOnOutlined } from "@mui/icons-material";
import { Box, Paper, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";

function RouteLine({ isDestination, value }) {
  return (
    <Box
      component={Paper}
      elevation={1}
      boxShadow={0}
      zIndex={2}
      display={"flex"}
      alignItems={"center"}
    >
      <Box
        width={20}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        {isDestination ? (
          <LocationOnOutlined color="error" fontSize="small" />
        ) : (
          <Box
            borderRadius={100}
            width={12}
            height={12}
            border={2}
            borderColor={grey[500]}
          />
        )}
      </Box>
      <Tooltip title={value}>
        <Typography
          maxWidth={350}
          noWrap
          fontSize={"1rem"}
          ml={2}
          fontWeight={"500"}
        >
          {value}
        </Typography>
      </Tooltip>
    </Box>
  );
}

export default RouteLine;

import {
  AssignmentOutlined,
  DeleteOutlineOutlined,
  FilePresentOutlined,
  NoteAltOutlined,
  PictureAsPdfOutlined,
  ReceiptLongOutlined,
  SendOutlined,
} from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DeleteRequest from "../../../../pages/requests/view/components/functions-layers/DeleteRequest";
import ReopenRequest from "../../../../pages/requests/view/components/functions-layers/ReopenRequest";
import SendToApproval from "../../../../pages/requests/view/components/functions-layers/SendToApproval";
import { openElement } from "../../../../store/features/portalSlice";
import { exportRequestToExcel } from "../../../../store/features/requestsSlice";

const DEFAULT_SETTINGS = {
  send: false,
  delete: false,
  edit: false,
  reopen: false,
  hidden: false,
  export: false,
};

const CustomMenuItem = ({ Icon, label, disabled, iconColor = "action", onClick }) => {
  return (
    <MenuItem onClick={onClick} disabled={disabled} sx={{ fontSize: ".95rem", height: 45 }}>
      {Icon && <Icon sx={{ mr: 2 }} color={iconColor} />} {label}
    </MenuItem>
  );
};

const ContextMenuWrapper = ({
  requestId,
  renderComponent = () => {},
  settings = DEFAULT_SETTINGS,
}) => {
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    setMenu(null);
  }, [settings]);

  return (
    <>
      {renderComponent({ menu, opened: Boolean(menu), setMenu })}
      <Menu
        onClick={(e) => e.stopPropagation()}
        onClose={() => setMenu(null)}
        anchorEl={menu}
        open={Boolean(menu) && !settings.hidden}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        elevation={3}
        slotProps={{
          paper: {
            sx: {
              boxShadow: 5,
              minWidth: 200,
            },
          },
        }}
      >
        {settings.send && (
          <SendToApproval
            requestId={requestId}
            renderComponent={({ disabled, onConfirm }) => (
              <CustomMenuItem
                disabled={disabled}
                onClick={onConfirm}
                iconColor="primary"
                Icon={SendOutlined}
                label="Enviar para aprovação"
              />
            )}
          />
        )}
        <CustomMenuItem
          Icon={AssignmentOutlined}
          onClick={() => navigate(`/approval-requests/${requestId}`)}
          label="Visão geral"
        />
        <CustomMenuItem
          onClick={() => navigate(`/approval-requests/${requestId}?tab=1`)}
          Icon={ReceiptLongOutlined}
          label="Ver despesas"
        />
        {settings.reopen && (
          <ReopenRequest
            requestId={requestId}
            renderComponent={({ disabled, onConfirm }) => (
              <CustomMenuItem
                disabled={disabled}
                onClick={onConfirm}
                Icon={NoteAltOutlined}
                label="Reabrir"
              />
            )}
          />
        )}
        {settings?.export && (
          <CustomMenuItem
            onClick={() => {
              setMenu(null);
              dispatch(
                openElement({
                  name: "requestExportPDF",
                  util: { requestId },
                })
              );
            }}
            Icon={PictureAsPdfOutlined}
            label="Exportar PDF"
          />
        )}
        {/* MATHEUS */}
        {settings?.export && (
          <CustomMenuItem
            onClick={() => {
              setMenu(null);
              dispatch(exportRequestToExcel(requestId));
            }}
            Icon={FilePresentOutlined}
            label="Exportar para Excel"
          />
        )}
        {settings.delete && (
          <DeleteRequest
            requestId={requestId}
            disableNavigateToBack
            renderComponent={({ disabled, onConfirm }) => (
              <CustomMenuItem
                disabled={disabled}
                onClick={onConfirm}
                Icon={DeleteOutlineOutlined}
                label="Excluir"
              />
            )}
          />
        )}
      </Menu>
    </>
  );
};

export default memo(ContextMenuWrapper);

import { Box, Typography } from "@mui/material";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { selectAccountCurrency } from "../../../../store/features/accountSlice";
import formatAmount from "../../../../utils/formatAmount";
import { curr } from "../../../../utils/more/currency_country";

function AmountSection({ totalAmount }) {
  const currency = useSelector(selectAccountCurrency);
  return (
    <Box mt={1} display={"flex"} alignItems={"center"} gap={1}>
      <Typography variant="h5" fontWeight={"500"} fontSize={"1rem"}>
        {curr(currency)} {formatAmount(totalAmount)}
      </Typography>
    </Box>
  );
}

export default memo(AmountSection);

import {
  BusinessOutlined,
  InfoOutlined,
  TimerOutlined,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { ADMIN_BASE_URL } from "../../../../api/urls";
import CompanyLogoSkeleton from "../../../../assets/bgs/company-logo-skeleton.png";
import {
  selectAccountStatus,
  selectIsAdmin,
  selectIsMaster,
} from "../../../../store/features/accountSlice";
import { getAccountStatusData } from "../../../../utils/accountStatus";
import SectionActionButton from "./components/SectionActionButton";
import SectionActionPaper from "./components/SectionActionPaper";
import SectionPaper from "./components/SectionPaper";

function CompanyInfo({ companyName, logo, closeMenu, hasLogo }) {
  const isAdmin = useSelector(selectIsAdmin);
  const isMaster = useSelector(selectIsMaster);
  const accountStatus = useSelector(selectAccountStatus);

  const { description, severity, title } = useMemo(
    () => getAccountStatusData(accountStatus),
    [accountStatus]
  );

  return (
    <>
      <SectionPaper
        sx={{
          borderBottomLeftRadius: isAdmin || isMaster ? 0 : 20,
          borderBottomRightRadius: isAdmin || isMaster ? 0 : 20,
        }}
      >
        <Typography
          gutterBottom
          fontWeight={"500"}
          fontSize={".75rem"}
          variant="body2"
          color={"text.secondary"}
        >
          Empresa:
        </Typography>
        <Box
          borderRadius={2}
          overflow={"hidden"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <img src={hasLogo ? logo : CompanyLogoSkeleton} width={"60%"} />
        </Box>
        <Typography
          textAlign={"center"}
          mt={2}
          mb={1}
          variant="h5"
          fontSize={"1.3rem"}
          fontWeight={"500"}
          sx={{
            textShadow: "0px 0px 1px rgb(0,0,0,.4)",
          }}
        >
          {companyName}
        </Typography>
      </SectionPaper>
      {isMaster ? (
        <>
          {accountStatus !== "active" && (
            <>
              <SectionPaper
                sx={{
                  bgcolor: (t) => `${t.palette[severity].main}20`,
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  mt: 0.5,
                }}
              >
                <Box display={"flex"} gap={1} alignItems={"center"}>
                  {["unpaid", "canceled", "paused"].includes(accountStatus) ? (
                    <InfoOutlined color={severity} />
                  ) : (
                    <TimerOutlined color="info" />
                  )}
                  <Typography ml={2} fontWeight={"500"} variant="body2">
                    {title}
                  </Typography>
                </Box>
              </SectionPaper>
            </>
          )}
          <SectionPaper
            sx={{
              p: 0,
              mt: 0.5,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomLeftRadius: 20,
              borderBottomRightRadius: 20,
            }}
          >
            <SectionActionButton
              LinkComponent={"a"}
              href={ADMIN_BASE_URL}
              target="_blank"
              Icon={BusinessOutlined}
            >
              Painel de administrador
            </SectionActionButton>
          </SectionPaper>
        </>
      ) : (
        isAdmin && (
          <SectionActionPaper>
            <SectionActionButton
              LinkComponent={"a"}
              href={ADMIN_BASE_URL}
              target="_blank"
              Icon={BusinessOutlined}
            >
              Painel de administrador
            </SectionActionButton>
          </SectionActionPaper>
        )
      )}
    </>
  );
}

export default CompanyInfo;

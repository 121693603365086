import { AssignmentReturnOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { openSnackbar } from "../../../../../../store/features/base/snackbarBaseSlice";
import { expensesOfflineActions } from "../../../../../../store/features/expensesSlice";

const ModalConfirm = memo(({ open, onClose, onConfirm }) => {
  const [loading, setLoading] = useState(false);

  const handleConfirm = useCallback(async () => {
    setLoading(true);
    await onConfirm();
    setLoading(false);
  }, [onConfirm, setLoading]);

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      {loading && <LinearProgress />}
      <DialogTitle fontSize={"1.1rem"}>
        Tem certeza que deseja voltar a(s) despesa(s) em avulsas?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          As despesas selecionadas serão removidas deste relatório
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          variant="contained"
          disabled={loading}
          disableElevation
          onClick={handleConfirm}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
});

const RemoveFromRequest = ({ selected = [], clearSelection }) => {
  const { requestId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [confirm, setConfirm] = useState(false);

  const handleConfirm = async () => {
    dispatch(expensesOfflineActions.removeFromRequest(requestId, selected));
    dispatch(
      openSnackbar({
        message: `${selected.length} despesa(s) removida(s) do relatório`,
        action: () => navigate(`/expenses`),
        actionText: "Ver avulsas",
      })
    );
    setConfirm(false);
    clearSelection();
  };

  return (
    <>
      <Button
        onClick={() => setConfirm(true)}
        variant="outlined"
        startIcon={<AssignmentReturnOutlined />}
      >
        <Typography
          sx={{ display: { md: "flex", xs: "none" } }}
          variant="inherit"
        >
          Voltar para avulsas
        </Typography>
      </Button>
      <ModalConfirm
        open={confirm}
        onClose={() => setConfirm(false)}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default memo(RemoveFromRequest);

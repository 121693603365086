import { PaidOutlined } from "@mui/icons-material";
import { TableCell } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useReducer, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NoDataContent from "../../../../components/form/NoDataContent";
import { selectionReducer } from "../../../../reducers/selectionReducer";
import CustomTableContainer from "../../components/CustomTableContainer";
import TableItem from "./components/table-item/TableItem";

const Advances = ({ advancesIds = [] }) => {
  const navigate = useNavigate();

  const { state, pathname, search } = useLocation();

  const handleClickItem = useCallback(
    (requestId) => {
      navigate(requestId, {
        state: {
          origin: pathname + search,
          lastVisitedRequestId: requestId,
        },
      });
    },
    [pathname, search]
  );

  const [selected, dispatchSelection] = useReducer(selectionReducer, []);

  const selectedRef = useRef([]);

  useEffect(() => {
    selectedRef.current = selected;
  }, [selected]);

  const selectionMode = useMemo(() => Boolean(selected.length), [Boolean(selected.length)]);

  const handleChangeSelected = useCallback((requestId) => {
    dispatchSelection({ type: "toggle", payload: requestId });
  }, []);

  const handleClearSelection = useCallback(() => {
    dispatchSelection({ type: "clear" });
  }, []);

  const handleSelectAllClick = useCallback(
    (event) => {
      if (event.target.checked) {
        dispatchSelection({ type: "select-all", payload: [...advancesIds] });
        return;
      }
      dispatchSelection({ type: "clear" });
    },
    [advancesIds]
  );

  useEffect(() => {
    if (state && state?.lastVisitedRequestId) {
      const element = document.getElementById(`r-${state?.lastVisitedRequestId}`);
      if (element) {
        element?.scrollIntoView({
          behavior: "instant",
          block: "end",
        });
      }
    }
  }, [state?.lastVisitedRequestId]);

  const renderItem = useCallback(
    (requestId) => {
      return (
        <TableItem
          key={requestId}
          requestId={requestId}
          onClick={handleClickItem}
          onChangeSelected={handleChangeSelected}
          selected={selected.includes(requestId)}
        />
      );
    },
    [selected]
  );

  return (
    <CustomTableContainer
      data={advancesIds}
      isAdvance={true}
      selectionMode={selectionMode}
      selected={selected}
      onSelectAll={handleSelectAllClick}
      onClearSelection={handleClearSelection}
      renderItem={renderItem}
      emptyComponent={
        <NoDataContent
          height={400}
          iconSize="5rem"
          Icon={PaidOutlined}
          title={"Nenhum adiantamento para aprovar"}
          subtitle={"Aqui você encontrará solicitações de adiantamento pendentes de aprovação"}
        />
      }
      columns={
        <>
          <TableCell>Colaborador</TableCell>
          <TableCell>Valor solicitado</TableCell>
          <TableCell>Relatório</TableCell>
          <TableCell>Etapa</TableCell>
          <TableCell align="right"></TableCell>
        </>
      }
    />
  );
};

export default Advances;

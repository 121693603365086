import { store } from "../store/store";

export default (tag = "") => {
  if (!tag) return;
  const state = store.getState();
  const expenseTypesEntities = state.expenseTypes.entities;
  const expenseTypesIds = state.expenseTypes.ids;
  const expenseTypeFinded =
    expenseTypesEntities[
      expenseTypesIds?.find(
        (typeId) => expenseTypesEntities[typeId]?.tag === tag
      )
    ];
  return (
    expenseTypeFinded ||
    expenseTypesEntities[
      expenseTypesIds?.find(
        (typeId) => expenseTypesEntities[typeId]?.is_default
      )
    ] ||
    null
  );
};

import { BusinessOutlined } from "@mui/icons-material";
import { Avatar, Box, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import getAccountLogoUrl from "../../utils/functions/getAccountLogoUrl";
import { getProfilePicture } from "../../utils/functions/profile";
import ButtonContainer from "./ButtonContainer";
import AccountMenu from "./menu/AccountMenu";

function AccountIndicator(props) {
  const [menu, setMenu] = useState(null);
  const accountId = useSelector((state) => state.account.accountId);
  const user = useSelector((state) => state.account.baseInfo);

  const hasLogo = useSelector((state) => state.account.accountHasLogo);
  const hasPhoto = useSelector((state) => state.account.hasPhoto);

  const {
    name: accountName,
    userId,
    lastname: accountLastname,
    email,
    companyName,
    occupation,
    org,
    photoVersion,
  } = user || {
    userId: undefined,
    name: "",
    lastname: "",
    email: "",
    companyName,
    occupation: "",
    org: "",
  };

  const { name, lastname } = {
    name: accountName || "",
    lastname: accountLastname || "",
  };

  const logoURL = getAccountLogoUrl(accountId);

  const profilePhotoSrc = useMemo(
    () => (hasPhoto ? getProfilePicture(userId, photoVersion) : undefined),
    [photoVersion, userId, hasPhoto]
  );

  return (
    <>
      <ButtonContainer
        companyName={companyName}
        email={email}
        lastname={lastname}
        name={name}
        onClick={(e) => setMenu(e.target)}
        selected={Boolean(menu)}
      >
        <Box
          textOverflow={"ellipsis"}
          overflow={"hidden"}
          flex={1}
          pl={0.3}
          mr={1}
          display={{ xs: "none", md: "flex" }}
          alignItems={"center"}
        >
          {hasLogo ? (
            <img style={{ width: "97%", height: 45 }} src={logoURL} />
          ) : (
            <>
              <BusinessOutlined
                sx={{ fontSize: "1rem", ml: 1, opacity: 0.6 }}
              />
              <Typography noWrap ml={1} fontSize={".8rem"} fontWeight={"600"}>
                {companyName}
              </Typography>
            </>
          )}
        </Box>
        <Avatar
          src={profilePhotoSrc}
          sx={{
            ml: {
              xs: 0.3,
              md: 1,
            },
            fontWeight: "600",
          }}
        >
          {name[0] && name[0].toUpperCase()}
        </Avatar>
      </ButtonContainer>
      {Boolean(menu) && (
        <AccountMenu
          anchorEl={menu}
          onClose={() => setMenu(null)}
          companyName={companyName}
          email={email}
          lastname={lastname}
          name={name}
          hasLogo={hasLogo}
          occupation={occupation}
          org={org}
          logo={logoURL}
          userId={userId}
          profilePhotoSrc={profilePhotoSrc}
        />
      )}
    </>
  );
}

export default AccountIndicator;

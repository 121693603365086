import { Box, Typography } from "@mui/material";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { selectAccountCurrency } from "../../../../../store/features/accountSlice";
import {
  selectExpensesSelected,
  selectExpensesTotalAmountByIds,
  selectExpensesTotalRefundableByIds,
} from "../../../../../store/features/expensesSlice";
import formatAmount from "../../../../../utils/formatAmount";

const TotalSelectedIndicator = ({ prevSelected }) => {
  const selectedInRedux = useSelector(selectExpensesSelected);

  const selected = prevSelected || selectedInRedux;

  const currency = useSelector((state) => selectAccountCurrency(state, true));

  const totalAmount = useSelector((state) =>
    selectExpensesTotalAmountByIds(state, selected)
  );
  const totalRefundable = useSelector((state) =>
    selectExpensesTotalRefundableByIds(state, selected)
  );

  return (
    <Box
      color={"text.primary"}
      display={{ md: "flex", xs: "none" }}
      alignItems={"center"}
      flexWrap={"wrap"}
      gap={{ lg: 2, xs: 0.5 }}
      ml={1}
    >
      <Typography
        fontSize={{
          xs: ".9rem",
          md: "1rem",
        }}
        fontWeight={"400"}
      >
        Total:{" "}
        <strong>
          {currency} {formatAmount(totalAmount)}
        </strong>
      </Typography>
      <Typography
        fontSize={{
          xs: ".9rem",
          md: "1rem",
        }}
        fontWeight={"400"}
      >
        Reembolsável:{" "}
        <strong>
          {currency} {formatAmount(totalRefundable)}
        </strong>
      </Typography>
    </Box>
  );
};

export default memo(TotalSelectedIndicator);

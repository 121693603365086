import {
  CheckOutlined,
  GridViewOutlined,
  ViewAgendaOutlined,
} from "@mui/icons-material";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLayoutViewMode } from "../../../store/features/base/settingsSlice";

const ToggleButtonItem = memo(({ selected, Icon, value }) => {
  return (
    <Tooltip
      enterDelay={1000}
      title={`Alternar para modo de visualização em ${
        value !== "grid" ? "lista" : "grade"
      }`}
    >
      <ToggleButton
        value={value}
        selected={selected}
        sx={{
          borderRadius: 100,
          pl: selected ? 4 : 2,
          position: "relative",
          transition: ".15s ease",
        }}
      >
        <Icon sx={{ fontSize: "22px" }} />
        <CheckOutlined
          sx={{
            position: "absolute",
            left: 10,
            fontSize: "18px",
            transform: selected ? "translateX(0)" : "translateX(-50)",
            transition: ".15s ease",
            opacity: selected ? 1 : 0,
          }}
        />
      </ToggleButton>
    </Tooltip>
  );
});

const ToggleLayoutViewMode = () => {
  const dispatch = useDispatch();
  const mode = useSelector((state) => state.settings.layoutViewMode);
  return (
    <ToggleButtonGroup
      exclusive
      onChange={(e, v) => dispatch(setLayoutViewMode(v))}
      color="primary"
      sx={{ borderRadius: 100, height: 34 }}
    >
      <ToggleButtonItem
        value={"grid"}
        selected={mode === "grid"}
        Icon={GridViewOutlined}
      />
      <ToggleButtonItem
        value={"line"}
        selected={mode === "line"}
        Icon={ViewAgendaOutlined}
      />
    </ToggleButtonGroup>
  );
};

export default memo(ToggleLayoutViewMode);

import { ChevronRightOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React, { memo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectSingleExpensesNum } from "../../../store/features/expensesSlice";
import { isStaging } from "../../../theme";

const CustomTypography = memo(({ children, disabled, onClick = () => {} }) => {
  return (
    <Typography
      noWrap
      fontSize={{
        xs: "1.18rem",
        md: isStaging ? "1.2rem" : "1.3rem",
      }}
      fontWeight={"400"}
      onClick={onClick}
      color={"text.primary"}
      sx={{
        cursor: disabled ? "default" : "pointer",
        letterSpacing: -0.3,
        opacity: 0.9,
        ":hover": {
          textDecoration: !disabled && "underline",
          color: !disabled ? "primary.main" : "text.primary",
          opacity: !disabled ? 1 : 0.8,
        },
      }}
    >
      {children}
    </Typography>
  );
});

const PartitionItem = memo(({ route, onClick, isBase }) => {
  return (
    <>
      <CustomTypography
        disabled={!isBase}
        onClick={() => isBase && onClick(route)}
      >
        {route}
      </CustomTypography>
      {isBase && <ChevronRightOutlined sx={{ mx: 0.1 }} color="disabled" />}
    </>
  );
});

const covertTitleToPathname = (title) => {
  switch (title) {
    case "Início":
      return "home";
    case "Para aprovar":
      return `approver`;
    case "Relatórios de despesas":
      return "approval-requests";
    case "Despesas avulsas":
      return "expenses";

    default:
      break;
  }
};

function Title(props) {
  const navigate = useNavigate();
  const breadcrumbs = useSelector((state) => state.breadcrumbs.data);

  const singleExpensesTotal = useSelector(selectSingleExpensesNum);

  const handleClickItem = useCallback((breadcrumb) => {
    navigate(covertTitleToPathname(breadcrumb));
  }, []);

  return (
    <>
      {breadcrumbs.length === 1 ? (
        <CustomTypography disabled>
          {breadcrumbs[0]}{" "}
          {breadcrumbs[0] === "Despesas avulsas" && singleExpensesTotal
            ? `(${singleExpensesTotal})`
            : ""}
        </CustomTypography>
      ) : (
        breadcrumbs.map((route, idx) => {
          return (
            <PartitionItem
              key={route + idx?.toString()}
              route={route}
              onClick={handleClickItem}
              isBase={idx !== breadcrumbs.length - 1}
            />
          );
        })
      )}
    </>
  );
}

export default memo(Title);

import { Dialog, DialogContentText, Fade } from "@mui/material";
import { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HttpClient } from "../../api/httpClient";
import LoadingSection from "../../components/loading/LoadingSection";
import { closeElement } from "../../store/features/portalSlice";
import { getReceiptUrl } from "../../utils/functions/receipts";
import Layout from "./components/Layout";
import RightContent from "./components/RightContent";

export default memo(() => {
  const dispatch = useDispatch();

  const open = useSelector((state) => state?.portal?.duplicatedChecker?.open);

  const data =
    useSelector((state) => state?.portal?.duplicatedChecker?.util) || {};

  const onClose = () => dispatch(closeElement("duplicatedChecker"));

  const [loading, setLoading] = useState(true);
  const [expense, setExpense] = useState({});
  const [error, setError] = useState(null);

  const duplicatedReceipts = useMemo(
    () => data?.duplicatedReceipts || [],
    [data?.duplicatedReceipts]
  );

  const [currentDuplicated, setCurrentDuplicated] = useState(null);

  const duplicatedIndex = useMemo(
    () =>
      duplicatedReceipts?.findIndex(
        (item) =>
          JSON.stringify(item) === JSON.stringify(currentDuplicated || {})
      ),
    [currentDuplicated, duplicatedReceipts]
  );

  const getExpense = async () => {
    try {
      let res;
      if (data?.isApprover) {
        res = await HttpClient.get(
          `/approvers/expenses/${currentDuplicated?.to_expense_id}`,
          {
            user_id: data?.user_id,
          }
        );
      } else {
        res = await HttpClient.get(
          `/expenses/${currentDuplicated?.to_expense_id}`
        );
      }

      setExpense(res?.data);
    } catch (error) {
      setError("Erro ao carregar despesa");
    }
  };

  useEffect(() => {
    if (open) {
      setCurrentDuplicated(duplicatedReceipts[0]);
    } else {
      setCurrentDuplicated(null);
      setLoading(false);
    }
  }, [open]);

  useEffect(() => {
    if (currentDuplicated) {
      setLoading(true);
      getExpense().then(() => {
        setLoading(false);
      });
    }
  }, [currentDuplicated]);

  const handleNext = () => {
    const next = duplicatedReceipts[duplicatedIndex + 1];
    if (next) {
      setCurrentDuplicated(next);
    }
  };

  const handlePrev = () => {
    const prev = duplicatedReceipts[duplicatedIndex - 1];
    if (prev) {
      setCurrentDuplicated(prev);
    }
  };

  return (
    <Dialog
      PaperProps={{
        sx: { height: "100%", display: "flex", flexDirection: "column" },
      }}
      hideBackdrop={data?.hideBackdrop}
      open={open}
      onClose={(event, reason) => reason === "backdropClick" && onClose()}
      maxWidth="lg"
      TransitionComponent={Fade}
      fullWidth
    >
      <Layout
        duplicatedIndex={duplicatedIndex}
        duplicatedNum={duplicatedReceipts?.length}
        onNext={handleNext}
        onPrev={handlePrev}
        onClose={onClose}
        leftContent={
          <>
            <DialogContentText color={"text.primary"} fontWeight={"500"} my={3}>
              É possível que este comprovante seja o mesmo da despesa ao lado
            </DialogContentText>
            <img
              style={{ borderRadius: "10px" }}
              width={"100%"}
              src={getReceiptUrl(data?.receipt_url)}
            />
          </>
        }
        rightContent={
          loading ? (
            <LoadingSection />
          ) : (
            <RightContent
              expense={expense}
              currentDuplicated={currentDuplicated}
              onClose={onClose}
            />
          )
        }
      />
    </Dialog>
  );
});

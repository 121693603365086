import { Typography } from "@mui/material";
import React, { memo } from "react";

function ObsSection({ obs = "" }) {
  return (
    <Typography
      fontSize={".875rem"}
      color={!obs ? "text.secondary" : "text.primary"}
      noWrap
      sx={{
        opacity: 0.8,
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {obs || "Sem descrição"}
    </Typography>
  );
}

export default memo(ObsSection);

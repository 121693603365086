import { MoveUpOutlined } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import React, { memo, useState } from "react";
import { useParams } from "react-router-dom";
import MenuAddExpensesInRequest from "../../../../../../components/menus/MenuAddExpensesInRequest";

const MoveRequest = ({ selected, clearSelection }) => {
  const { requestId } = useParams();
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        onClick={(e) => setOpen(true)}
        startIcon={<MoveUpOutlined />}
        variant="outlined"
      >
        <Typography
          sx={{ display: { md: "flex", xs: "none" } }}
          variant="inherit"
        >
          Mover de relatório
        </Typography>
      </Button>
      <MenuAddExpensesInRequest
        fromRequestId={requestId}
        isMove
        open={open}
        onClose={() => setOpen(false)}
        expensesIds={selected}
        onAction={clearSelection}
      />
    </>
  );
};

export default memo(MoveRequest);

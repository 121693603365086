import {
  Box,
  Dialog,
  DialogContent,
  Fade,
  LinearProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { memo, useCallback } from "react";
import ExpensePolicyLayer from "../../../layers/ExpensePolicyLayer";
import "../../../main.css";
import Header from "../components/Header";

function Layout({
  open,
  onClose,
  expenseId,
  settings,

  loading,
  expensePolicyRef,
  warnings,

  changed,
  onEdit,
  editMode,
  onCancelEdit,
  onSave,

  mapContent,
  viewContent,
  editContent,
}) {
  const handleClickWarnings = useCallback((data) => {
    expensePolicyRef?.current?.presentReadOnly(data);
  }, []);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      fullScreen={isSmall}
      data-disableselect={true}
      open={open}
      TransitionComponent={Fade}
      disableScrollLock={false}
      onClose={(event, reason) =>
        reason === "backdropClick" && !editMode && onClose()
      }
      fullWidth
      maxWidth="md"
      PaperProps={{
        elevation: 1,
        sx: {
          borderRadius: isSmall ? 0 : 2,
          height: { md: "90%", xs: "100%" },
          maxHeight: { md: "45em", xs: "100%" },
          position: "relative",
        },
      }}
    >
      {loading && <LinearProgress />}
      <Header
        title="Percurso por mapa"
        settings={settings}
        expenseId={expenseId}
        changed={changed}
        onEdit={onEdit}
        editMode={editMode}
        onCancelEdit={onCancelEdit}
        onClose={onClose}
        onSave={onSave}
        warnings={warnings}
        onViewWarnings={handleClickWarnings}
      />
      <DialogContent sx={{ p: 0, display: "flex", flexWrap: "wrap-reverse" }}>
        <Box
          height={"100%"}
          display={"flex"}
          flexDirection="column"
          overflow={"auto"}
          width={{ xs: "100%", md: "28em" }}
          className="hover-scrollbar"
          position="relative"
          zIndex={3}
          borderRight={1}
          p={2}
          pl={1}
          pr={2.5}
          pt={3}
          pb={{ xs: 1, md: 10 }}
          borderColor="divider"
          sx={{
            overflowX: "hidden",
          }}
        >
          {editMode ? editContent : viewContent}
        </Box>
        <Box
          height={"100%"}
          flex={1}
          display={{ xs: "none", md: "flex" }}
          flexDirection={"column"}
        >
          {mapContent}
        </Box>
      </DialogContent>
      <ExpensePolicyLayer
        ref={expensePolicyRef}
        onCreate={onSave}
        isSave
        isRoute
      />
    </Dialog>
  );
}

export default memo(Layout);

import { Box, Typography } from "@mui/material";
import React, { memo } from "react";
import RenderChipStatus from "../../../chip/RenderChipStatus";
import AdvanceInfo from "../../card/components/AdvanceInfo";

const StatusSection = ({ sync, advancing, status, advance = {} }) => {
  return (
    <Box display={"flex"} alignItems={"center"}>
      {sync || advancing ? (
        <Typography
          variant="body2"
          fontStyle={"italic"}
          color={"text.secondary"}
        >
          {sync ? "Criando..." : "Solicitando adiantamento..."}
        </Typography>
      ) : (
        <>
          <RenderChipStatus fz=".8rem" sx={{ height: 25 }} status={status} />
          {status === "AD" && advance && (
            <AdvanceInfo reason={advance?.reason} amount={advance?.amount} />
          )}
        </>
      )}
    </Box>
  );
};

export default memo(StatusSection);

import { RefreshOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  LinearProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  tableCellClasses,
} from "@mui/material";
import React, { memo, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchApprovalRequests } from "../../../store/features/approvalRequestsSlice";
import TableHeader from "./TableHeader";

const CustomTableContainer = ({
  data = [],
  selectionMode,
  selected,
  onSelectAll,
  onClearSelection,
  emptyComponent,
  columns,
  renderItem = () => {},
  isAdvance,
}) => {
  const dispatch = useDispatch();

  const total = useMemo(() => data?.length, [data]);

  const working = useSelector((state) => state.approvalRequests.working);

  return (
    <TableContainer
      sx={{
        flex: 1,
        flexBasis: 0,
        width: "100%",
        borderRadius: 3,
        border: 1,
        pb: 10,
        position: "relative",
        borderColor: "divider",
        backgroundColor: (t) =>
          t.palette.mode === "dark" ? "elevation1.main" : "#FFF",
      }}
    >
      <LinearProgress
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: working ? 2 : 0,
          transition: ".2s ease",
          zIndex: 100,
        }}
      />
      <Table stickyHeader>
        <TableHead>
          {total > 0 && (
            <TableHeader
              selectionMode={selectionMode}
              selected={selected}
              count={total}
              onSelectAll={onSelectAll}
              clearSelection={onClearSelection}
              columns={columns}
              isAdvance={isAdvance}
            />
          )}
        </TableHead>

        {total === 0 ? (
          <Box component={"tbody"}>
            <Box component={"tr"}>
              <Box
                position={"relative"}
                display={"flex"}
                flexDirection={"column"}
                component={"td"}
              >
                {emptyComponent}
                <Button
                  startIcon={<RefreshOutlined />}
                  variant="outlined"
                  sx={{
                    position: "absolute",
                    alignSelf: "center",
                    borderRadius: 100,
                    bottom: 30,
                  }}
                  disabled={working}
                  onClick={() => dispatch(fetchApprovalRequests())}
                >
                  Atualizar
                </Button>
              </Box>
            </Box>
          </Box>
        ) : (
          <TableBody
            sx={{
              [`& .${tableCellClasses.root}`]: {
                py: 1.2,
                border: "none",
                fontSize: ".92rem",
                fontWeight: "500",
              },
            }}
          >
            {data?.map(renderItem)}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default memo(CustomTableContainer);

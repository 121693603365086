import {
  AssignmentOutlined,
  DocumentScanner,
  LocationOnOutlined,
  ReceiptLongOutlined,
} from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { openElement } from "../../../store/features/portalSlice";

export const CustomMenuItem = ({ label, Icon, onClick }) => {
  return (
    <MenuItem
      onClick={onClick}
      sx={{ fontWeight: "500", fontSize: ".94rem", height: 43, pr: 3 }}
    >
      {Icon && <Icon sx={{ mr: 2.5 }} fontSize="small" color="action" />}
      {label}
    </MenuItem>
  );
};

function MenuCreation({
  anchorEl,
  onClose,
  hiddenNewRequest,
  hiddenScanner,
  transformOrigin = { horizontal: "right", vertical: "top" },
  anchorOrigin = { vertical: "top", horizontal: "right" },
}) {
  const dispatch = useDispatch();
  return (
    <Menu
      disablePortal
      disableScrollLock
      transitionDuration={150}
      defaultChecked
      elevation={0}
      onClose={onClose}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      slotProps={{
        paper: {
          elevation: 3,
          sx: { borderRadius: 3, pb: 1, boxShadow: 4 },
        },
      }}
      transformOrigin={transformOrigin}
      anchorOrigin={anchorOrigin}
    >
      {!hiddenScanner && (
        <CustomMenuItem
          Icon={DocumentScanner}
          label="Escanear comprovantes"
          onClick={() => {
            dispatch(openElement({ name: "scanExpenses" }));
            onClose();
          }}
        />
      )}
      <CustomMenuItem
        Icon={ReceiptLongOutlined}
        label="Criar despesa"
        onClick={() => {
          dispatch(openElement({ name: "newExpense" }));
          onClose();
        }}
      />
      <CustomMenuItem
        Icon={LocationOnOutlined}
        label="Criar percurso"
        onClick={() => {
          dispatch(openElement({ name: "newRoute" }));
          onClose();
        }}
      />
      {!hiddenNewRequest && (
        <CustomMenuItem
          Icon={AssignmentOutlined}
          label="Criar relatório de despesas"
          onClick={() => {
            dispatch(openElement({ name: "newRequest" }));
            onClose();
          }}
        />
      )}
    </Menu>
  );
}

export default MenuCreation;

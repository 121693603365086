import {
  BadgeOutlined,
  BusinessCenterOutlined,
  SettingsOutlined,
  WorkspacePremiumOutlined,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthClient } from "../../../../api/httpClient";
import ModalChangePassword from "../../../../modals/change-password/ModalChangePassword";
import ModalChangePersonalData from "../../../../modals/change-personal-data/ModalChangePersonalData";
import {
  selectIsMaster,
  setHasPhoto,
  updatePhotoVersion,
} from "../../../../store/features/accountSlice";
import { setError } from "../../../../store/features/base/errorBaseSlice";
import { openSnackbar } from "../../../../store/features/base/snackbarBaseSlice";
import { blobToBase64 } from "../../../../utils/more/images_transform";
import AvatarPicker from "./components/AvatarPicker";
import SectionPaper from "./components/SectionPaper";
import SyncAllItem from "./components/SyncAllItem";

const SettingsButton = memo(() => {
  const [menu, setMenu] = useState(null);
  const [changePassModal, setChangePassModal] = useState(false);
  const [changeDataModal, setChangeDataModal] = useState(false);
  return (
    <>
      <IconButton onClick={(e) => setMenu(e.target)}>
        <SettingsOutlined />
      </IconButton>
      <Menu
        PaperProps={{ elevation: 5 }}
        anchorEl={menu}
        onClose={() => setMenu(null)}
        open={Boolean(menu)}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <SyncAllItem closeMenu={useCallback(() => setMenu(null), [])} />
        <MenuItem
          onClick={() => {
            setChangeDataModal(true);
            setMenu(null);
          }}
        >
          Editar dados pessoais
        </MenuItem>
        <MenuItem
          onClick={() => {
            setChangePassModal(true);
            setMenu(null);
          }}
        >
          Alterar senha de acesso
        </MenuItem>
      </Menu>
      <ModalChangePassword
        open={changePassModal}
        onClose={() => setChangePassModal(false)}
      />
      <ModalChangePersonalData
        open={changeDataModal}
        onClose={() => setChangeDataModal(false)}
      />
    </>
  );
});

function BaseInfo({
  name,
  lastname,
  userId,
  email,
  initialOccupation,
  initialOrg,
  profilePhotoSrc,
  closeMenu,
}) {
  const dispatch = useDispatch();
  const isMaster = useSelector(selectIsMaster);

  const { org, occupation } = {
    org: initialOrg || "",
    occupation: initialOccupation || "",
  };

  const handleChangeUserPhoto = async (photo) => {
    const base64 = await blobToBase64(photo);
    try {
      const { data } = await AuthClient.client().put(`/profile`, {
        profilePicBase64: base64,
      });
      fetch(profilePhotoSrc, {
        headers: {
          "force-revalidate": 1,
        },
      }).then(() => {
        dispatch(updatePhotoVersion(data?.profile_photo_version));
      });
      dispatch(setHasPhoto(true));
      dispatch(openSnackbar({ message: "Foto salva" }));
    } catch (error) {
      dispatch(setError({ title: "Erro ao salvar foto de perfil", error }));
    }
  };
  const handleRemoveUserPhoto = async (photo) => {
    try {
      const { data } = await AuthClient.client().put(`/profile`, {
        deleteProfilePic: true,
      });
      fetch(profilePhotoSrc, {
        headers: {
          "force-revalidate": 1,
        },
      }).then(() => {
        dispatch(updatePhotoVersion(data?.profile_photo_version));
      });
      dispatch(setHasPhoto(false));
      dispatch(openSnackbar({ message: "Foto removida" }));
    } catch (error) {
      dispatch(setError({ title: "Erro ao remover foto de perfil", error }));
    }
  };

  return (
    <>
      <SectionPaper
        sx={{
          display: "flex",
          alignItems: "flex-start",
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20,
        }}
      >
        <AvatarPicker
          name={name}
          userId={userId}
          photo={profilePhotoSrc || null}
          onChange={handleChangeUserPhoto}
          onRemove={handleRemoveUserPhoto}
          showAlertRemove
          closeOnChange={false}
        />
        <Box ml={2} width={"100%"}>
          <Box display={"flex"} alignItems={"center"}>
            <Box flex={1}>
              <Typography fontWeight={"600"} fontSize={"1.2rem"}>
                {name} {lastname || ""}
              </Typography>
              <Typography
                sx={{ wordBreak: "break-all" }}
                fontSize={".85rem"}
                color={"text.secondary"}
              >
                {email}
              </Typography>
            </Box>
            <SettingsButton />
          </Box>
          <Divider sx={{ my: 1 }} />
          {isMaster && (
            <Typography
              display={"flex"}
              alignItems={"center"}
              fontSize={".8rem"}
              color={"text.primary"}
              fontWeight={"500"}
              mb={1}
            >
              <WorkspacePremiumOutlined
                color="primary"
                sx={{ fontSize: ".9rem", mr: 1 }}
              />
              Proprietário
            </Typography>
          )}
          <Typography
            display={"flex"}
            alignItems={"center"}
            fontSize={".8rem"}
            color={"text.secondary"}
            mb={0.3}
            fontStyle={org ? "normal" : "italic"}
          >
            <BusinessCenterOutlined
              color="action"
              sx={{ fontSize: ".9rem", mr: 1 }}
            />
            {org || "Sem organização"}
          </Typography>
          <Typography
            display={"flex"}
            alignItems={"center"}
            fontSize={".8rem"}
            color={"text.secondary"}
            fontStyle={occupation ? "normal" : "italic"}
          >
            <BadgeOutlined color="action" sx={{ fontSize: ".9rem", mr: 1 }} />
            {occupation || "Sem cargo"}
          </Typography>
        </Box>
      </SectionPaper>
    </>
  );
}

export default memo(BaseInfo);

import { Alert, AlertTitle, Box, Link } from "@mui/material";
import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { openElement } from "../../../../../store/features/portalSlice";

const DuplicatedAlert = ({ duplicatedReceipts = [], receiptUrl }) => {
  const dispatch = useDispatch();

  return (
    <>
      <Alert severity="warning">
        <AlertTitle sx={{ display: "flex", alignItems: "center" }}>
          Risco de duplicação
          <Box flex={1} />
          <Link
            component={"div"}
            onClick={() => {
              dispatch(
                openElement({
                  name: "duplicatedChecker",
                  util: {
                    hideBackdrop: true,
                    receipt_url: receiptUrl,
                    duplicatedReceipts,
                  },
                })
              );
            }}
            variant="body2"
            fontWeight={"600"}
          >
            Comparar
          </Link>
        </AlertTitle>
        É possível que este comprovante tenha sido utilizado anteriormente
      </Alert>
    </>
  );
};

export default memo(DuplicatedAlert);

import { Box, DialogContent, Typography } from "@mui/material";
import React from "react";
import SelectRouteImg from "../../../../assets/bgs/select_route.svg";
import Form from "../components/Form";
import RightContent from "../components/RightContent";
import RouteSection from "../components/route-section/RouteSection";

const MapModeContent = ({
  updateRoute,
  revertRoutes,
  calculateFunction,
  addRoute,
  removeRoute,
  onDragEnd,
  directionsResponse,
  routes,
  onClose,
  onCreate,
  rate,
  currency,
  dateInvalid,
  onChangeValue,
  disabledCreate,
  loadingMap,
  values,
}) => {
  return (
    <DialogContent
      sx={{
        p: 0,
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      <Box
        height={"100%"}
        display={"flex"}
        flexDirection="column"
        overflow={"scroll"}
        width={{ md: "28em", xs: "100%" }}
        className="hover-scrollbar"
        zIndex={3}
        borderRight={1}
        px={3}
        pr={2}
        borderColor="divider"
      >
        {/* inputs */}
        <RouteSection
          routes={routes}
          updateRoute={updateRoute}
          revertRoutes={revertRoutes}
          calculateFunction={calculateFunction}
          addRoute={addRoute}
          removeRoute={removeRoute}
          onDragEnd={onDragEnd}
        />
        {!directionsResponse && (
          <Box
            display={"flex"}
            alignItems={"center"}
            flexDirection="column"
            pt={5}
          >
            <img width={215} src={SelectRouteImg} />
            <Typography mt={2} textAlign={"center"} fontSize={"1.1rem"}>
              Insira o trajeto para ver as opções
            </Typography>
          </Box>
        )}
        {directionsResponse && (
          <Form
            values={values}
            currency={currency}
            dateInvalid={dateInvalid}
            onChangeValue={onChangeValue}
            rate={rate}
          />
        )}
      </Box>
      <RightContent
        directionsResponse={directionsResponse}
        onClose={onClose}
        onCreate={onCreate}
        disabledCreate={disabledCreate}
        loadingMap={loadingMap}
      />
    </DialogContent>
  );
};

export default MapModeContent;

import { Box, Button, Popover, useScrollTrigger } from "@mui/material";
import React, { useState } from "react";
import MenuHeader from "./MenuHeader";

function MenuBase({
  children,
  onClose,
  anchorEl,
  title,
  disabledClear,
  onClear,
  disableFooterShadown,
}) {
  const [content, setContent] = useState(undefined);
  const scrolled = useScrollTrigger({
    disableHysteresis: true,
    target: content,
    threshold: 0,
  });
  return (
    <Popover
      disableScrollLock
      data-disableselect={true}
      transitionDuration={0}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      transformOrigin={{ horizontal: "center", vertical: "top" }}
      slotProps={{
        paper: {
          elevation: 2,
          sx: {
            width: 380,
            borderRadius: 3,
            boxShadow: 3,
            bgcolor: "elevation1.main",
          },
        },
      }}
    >
      <MenuHeader onClose={onClose} scrolled={scrolled} title={title} />
      <Box
        pb={5}
        ref={(node) => node && setContent(node)}
        data-disableselect={true}
        maxHeight={400}
        overflow={"auto"}
      >
        {children}
      </Box>
      <Box
        boxShadow={!disableFooterShadown && "0px -1px 2px 0px rgb(0 0 0 / 14%)"}
        p={1}
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"center"}
      >
        <Button
          sx={{ borderRadius: 100 }}
          onClick={onClear}
          disabled={disabledClear}
          color="primary"
        >
          Limpar
        </Button>
      </Box>
    </Popover>
  );
}

export default MenuBase;

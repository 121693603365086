import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthClient } from "../../api/httpClient";
import AvatarPicker from "../../components/account-indicator/menu/sections/components/AvatarPicker";
import Input from "../../components/inputs/text-input";
import {
  saveAccountData,
  setHasPhoto,
  updatePhotoVersion,
} from "../../store/features/accountSlice";
import { setError } from "../../store/features/base/errorBaseSlice";
import { openSnackbar } from "../../store/features/base/snackbarBaseSlice";
import { getProfilePicture } from "../../utils/functions/profile";
import { blobToBase64 } from "../../utils/more/images_transform";

const ModalChangePersonalData = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.account.baseInfo);

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(user?.name || "");
  const [lastname, setLastname] = useState(user?.lastname || "");

  const initialURL = useMemo(
    () => getProfilePicture(user?.userId || null, user?.photoVersion || 0),
    [user?.userId, user?.photoVersion]
  );

  const [photo, setPhoto] = useState(initialURL);

  useEffect(() => {
    if (!open) {
      setName(user?.name || "");
      setLastname(user?.lastname || "");
      setPhoto(initialURL);
    }
  }, [open]);

  const handleSave = async () => {
    setLoading(true);
    const data = {
      name,
      lastname,
    };
    if (getProfilePicture(user?.userId) && !photo) {
      data.deleteProfilePic = true;
    }
    if (photo && getProfilePicture(user?.userId) !== photo) {
      const base64 = await blobToBase64(photo);
      data.profilePicBase64 = base64;
      data.deleteProfilePic = false;
    }
    try {
      const { data: uploadData } = await AuthClient.client().put(
        `/profile`,
        data
      );
      fetch(getProfilePicture(initialURL), {
        headers: {
          "force-revalidate": 1,
        },
      }).then(() => {
        dispatch(updatePhotoVersion(uploadData?.profile_photo_version));
      });
      dispatch(setHasPhoto(!data.deleteProfilePic));
      dispatch(
        saveAccountData({
          name,
          lastname,
        })
      );
      dispatch(openSnackbar({ message: "Dados salvos" }));
      onClose();
    } catch (error) {
      dispatch(setError({ title: "Erro ao editar dados", error }));
    } finally {
      setLoading(false);
    }
  };

  return (
    open && (
      <Dialog
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: "rgba(255, 255, 255, .9)",
            },
          },
        }}
        PaperProps={{
          sx: {
            position: "relative",
          },
        }}
        open={open}
        onClose={() => !loading && onClose()}
        fullWidth
        maxWidth="xs"
      >
        {loading && <LinearProgress />}
        <DialogTitle fontSize={"1.1rem"}>
          Editar dados pessoais
          <Box flex={1} />
          <IconButton onClick={onClose} sx={{ m: -1 }}>
            <Close />
          </IconButton>
        </DialogTitle>
        <Box
          p={3}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
          width={"100%"}
        >
          <AvatarPicker
            name={user?.name}
            photo={photo}
            onChange={setPhoto}
            onRemove={() => setPhoto(null)}
            size={100}
          />
          <Box width={"100%"} mt={3} display={"flex"}>
            <Input
              autoFocus
              value={name}
              onChange={setName}
              label={"Nome"}
              required
              variant={"standard"}
            />
            <Input
              value={lastname}
              onChange={setLastname}
              label={"Último nome"}
              variant={"standard"}
            />
          </Box>
        </Box>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
          <Button
            disabled={loading}
            onClick={handleSave}
            variant="contained"
            disableElevation
          >
            {loading ? "Salvando" : "Salvar"}
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

export default ModalChangePersonalData;

import { Box } from "@mui/material";
import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "../src/App.css";
import Appbar from "./components/appbar/Appbar";

import { AnimatePresence } from "framer-motion";
import { useSelector } from "react-redux";
import DialogAlert from "./classes/DialogAlert";
import CustomTour from "./components/CustomTour";
import Fallback from "./components/Fallback";
import CheckApproverAccessWrapper from "./components/access/CheckApproverAccessWrapper";
import Sidebar from "./components/sidebar/Sidebar";
import ErrorBoundary from "./errors/Boundary";
import ApprovalModal from "./modals/approval/ApprovalModal";
import RejectModal from "./modals/approval/RejectModal";
import DuplicatedChecker from "./modals/duplicated-checker";
import ModalNewExpense from "./modals/expense-creation/new-expense/ModalNewExpense";
import ModalNewRoute from "./modals/expense-creation/new-route/ModalNewRoute";
import ModalRequestExportPDF from "./modals/export/ModalRequestExportPDF";
import ModalNewRequest from "./modals/new-request/ModalNewRequest";
import ScanExpensesModal from "./modals/scanner-expenses/ScanExpensesModal";
import Approver from "./pages/approver";
import ApprovalRequestView from "./pages/approver/request-view/ApprovalRequestView";
import ExpensesMain from "./pages/expenses/ExpensesMain";
import Home from "./pages/home/Home";
import RequestsMain from "./pages/requests/RequestsMain";
import RequestView from "./pages/requests/view/RequestView";
import { selectIsApprover } from "./store/features/accountSlice";

export default (props) => {
  const isApprover = useSelector(selectIsApprover);
  return (
    <>
      <Appbar />
      <Sidebar />
      <Box
        ml={{ xs: 0, md: "6.7em" }}
        px={2}
        pr={0}
        pl={0}
        pb={0}
        mt={{ xs: "50px", md: "60px" }}
        display={"flex"}
        flexDirection={"column"}
        minHeight={"calc(100% - 60px)"}
        component={"div"}
        sx={{ transition: ".15s ease" }}
      >
        <ErrorBoundary>
          <AnimatePresence mode="wait">
            <Suspense fallback={<Fallback />}>
              <Routes>
                <Route index element={<Navigate to={"home"} />} />
                <Route path="home" element={<Home />} />
                <Route path="expenses" element={<ExpensesMain />} />
                <Route path="approval-requests">
                  <Route index element={<RequestsMain />} />
                  <Route path=":requestId" element={<RequestView />} />
                </Route>
                <Route path="approver">
                  <Route
                    index
                    element={
                      <CheckApproverAccessWrapper showNoAccessComponent>
                        <Approver />
                      </CheckApproverAccessWrapper>
                    }
                  />
                  <Route
                    path=":requestId"
                    element={
                      <CheckApproverAccessWrapper showNoAccessComponent>
                        <ApprovalRequestView />
                      </CheckApproverAccessWrapper>
                    }
                  />
                </Route>
              </Routes>
            </Suspense>
          </AnimatePresence>

          <ScanExpensesModal />

          <ModalNewRequest />

          <ModalNewExpense />
          <ModalNewRoute />
          <DialogAlert />

          <ModalRequestExportPDF />
          <DuplicatedChecker />

          {isApprover && (
            <>
              <ApprovalModal />
              <RejectModal />
            </>
          )}

          {/* TOUR COMPONENT */}
          <CustomTour />
        </ErrorBoundary>
      </Box>
    </>
  );
};

import { Box, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { useLocation } from "react-router-dom";

const BaseItem = ({
  disableHiddenLabel,
  width,
  selected,
  title,
  content,
  Icon,
  onClick,
  subtitle,
  disabled,
}) => {
  return (
    <ListItemButton
      disabled={disabled}
      onClick={onClick}
      sx={{
        color: (t) => `${t.palette.primary.main}50`,
        pl: !disableHiddenLabel && 3.2,
        width,
        height: 50,
        bgcolor: (t) => selected && `${t.palette.primary.main}30`,
        transition: "none",
        overflow: "hidden",
        position: "relative",
        borderRadius: 100,
        ":hover": {
          bgcolor: (t) =>
            selected ? `${t.palette.primary.main}40` : `action.hover`,
        },
      }}
    >
      {Icon && (
        <ListItemIcon
          sx={{ mx: -1.5, color: selected ? "text.primary" : "text.secondary" }}
        >
          <Icon sx={{ fontSize: "20px" }} />
        </ListItemIcon>
      )}
      <ListItemText
        primaryTypographyProps={{
          variant: "body1",
          fontSize: ".9rem",
          fontWeight: "500",
          sx: {
            textShadow: (t) => `0.13pt 0.13pt 0.13pt ${grey[500]}`,
          },
          noWrap: true,
          color: selected ? "text.primary" : "text.secondary",
          ml: -1.2,
        }}
        primary={title}
        secondary={subtitle}
      />
      {content}
    </ListItemButton>
  );
};

const isSelected = (link, pathname) => {
  return new RegExp(`${link}`).test(pathname);
};

function SidebarItem({
  path,
  title,
  subtitle,
  Icon,
  width = "100%",
  disableHiddenLabel,
  selectedProp,
  onClick,
  content,
  disabled,
}) {
  const { pathname } = useLocation();

  const selected =
    selectedProp === undefined ? isSelected(path, pathname) : selectedProp;

  return (
    <Box>
      <BaseItem
        disabled={disabled}
        Icon={Icon}
        content={content}
        disableHiddenLabel={disableHiddenLabel}
        selected={selected}
        title={title}
        width={width}
        subtitle={subtitle}
        onClick={onClick}
      />
    </Box>
  );
}

export default SidebarItem;

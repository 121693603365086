import { Box } from "@mui/material";
import React from "react";

function CircleDivisor(props) {
  return (
    <Box
      width={4.5}
      height={4.5}
      borderRadius={100}
      bgcolor={(t) => t.palette.grey[400]}
      {...props}
    />
  );
}

export default CircleDivisor;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HttpClient } from "../../api/httpClient";

const initialState = {
  last3MonthsTotals: {
    working: true,
    data: [],
  },
  last3MonthsTotalsByCategory: {
    working: true,
    top5: [],
  },
  resume: {
    working: true,
    totals: {
      approved: 0,
      opened: 0,
      pending: 0,
      to_approve: 0,
    },
  },
};

// Obter gastos totais dos últimos 3 meses
export const fetchLast3MonthsTotals = createAsyncThunk(
  `totalsSummary/fetchLast3MonthsTotals`,
  async () => {
    const { data } = await HttpClient.get("/summary/totals/daily");
    return data;
  }
);

// Obter gastos totais dos últimos 3 meses por categoria de despesa
export const fetchLast3MonthsTotalsByCategory = createAsyncThunk(
  `totalsSummary/fetchLast3MonthsTotalsByCategory`,
  async () => {
    const { data } = await HttpClient.get("/summary/totals/by/expense-type");

    /** Obter somente as 5 categorias mais utilizadas */
    const sorted = data.sort((a, b) => b.total - a.total);
    return [...sorted.slice(0, 4)];
  }
);

// Valores de resumo geral
export const fetchTotalsResume = createAsyncThunk(
  `totalsSummary/fetchTotalsResume`,
  async () => {
    const { data } = await HttpClient.get("/summary/totals");
    return data?.totals;
  }
);

const totalsSummarySlice = createSlice({
  name: "totalsSummary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLast3MonthsTotals.fulfilled, (state, action) => {
        state.last3MonthsTotals.working = false;
        state.last3MonthsTotals.data = action.payload || [];
      })
      .addCase(fetchLast3MonthsTotalsByCategory.fulfilled, (state, action) => {
        state.last3MonthsTotalsByCategory.working = false;
        state.last3MonthsTotalsByCategory.top5 = action.payload || [];
      })
      .addCase(fetchTotalsResume.fulfilled, (state, action) => {
        state.resume.working = false;
        state.resume.totals = {
          ...initialState.resume.totals,
          ...(action?.payload || {}),
        };
      });
  },
});

// export const {} = totalsSummarySlice.actions;

export const selectLast3MonthsTotals = (state) =>
  state.totalsSummary.last3MonthsTotals;

export const selectLast3MonthsTotalsByCategory = (state) =>
  state.totalsSummary.last3MonthsTotalsByCategory;

export const selectTotalsResume = (state) => state.totalsSummary.resume;

export default totalsSummarySlice.reducer;

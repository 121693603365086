import { Box, Checkbox, TableCell, Typography } from "@mui/material";
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import { selectExpenseById } from "../../../store/features/expensesSlice";
import { formatDate } from "../../../utils/more/date_functions";
import PaymentCell from "./PaymentCell";
import Base from "./base";
import ActionsArea from "./components/ActionsArea";
import Amount from "./components/Amount";
import Content from "./components/Content";
import ProjectCell from "./components/ProjectCell";
import ReceiptIndicator from "./components/ReceiptIndicator";
import RefundableCell from "./components/RefundableCell";
import ScanningLayer from "./components/ScanningLayer";

const mapId = "fceaa9766c12a837";

const ExpenseLineItem = forwardRef(
  (
    {
      expenseObj,
      expenseId,
      selected,
      enableSelectScrollIntoView = false,
      customActionComponent,
      onChangeSelected = () => {},
      onClick = () => {},
      rejected,
      disableActions,
      settings = {
        compact: true,
        select: true,
        remove: true,
        addToRequest: true,
        removeFromRequest: true,
      },
    },
    ref
  ) => {
    const expenseFromStore = useSelector((state) =>
      selectExpenseById(state, expenseId)
    );
    const expense = expenseFromStore || expenseObj || {};

    const {
      date,
      refundable,
      notes,
      is_route,
      type_id,
      currency,
      amount,
      receipts,
      route_mode,
      distance,
      waypoints,
      request_id,
      required_receipt,
      from,
      project_id,
      payment_type,
      payment_type_name,
      warnings,
      to,

      //sync
      processingStatus,
      processingError,
    } = expense;

    const scanning = useMemo(
      () => processingStatus === "scanning",
      [processingStatus]
    );
    const sync = useMemo(() => processingStatus === "sync", [processingStatus]);

    const handleClickSelect = useCallback(
      (e) => {
        e.stopPropagation();
        onChangeSelected(expenseId);
      },
      [expenseId]
    );

    const handleClick = useCallback(
      (e) => {
        e.stopPropagation();
        onClick(expenseId, is_route, route_mode, expenseObj);
      },
      [expenseId, expenseObj, is_route, route_mode]
    );

    const staticMapURL = useMemo(() => {
      if (from && to) {
        let markers = `${from}`;
        if (waypoints && waypoints.length) {
          markers = `${markers}|${waypoints.join("|")}`;
        }
        markers = `${markers}|${to}`;
        return `https://maps.googleapis.com/maps/api/staticmap?&markers=size:mid|color:red|${markers}&map_id=${mapId}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&size=160x220`;
      } else {
        return null;
      }
    }, [from, to, waypoints]);

    useEffect(() => {
      if (selected && enableSelectScrollIntoView) {
        const expenseElement = document.getElementById(`e-${expenseId}`);
        if (expenseElement) {
          expenseElement?.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    }, [selected, expenseId]);

    return (
      <Base
        innerRef={ref}
        rejected={rejected}
        expenseId={expenseId}
        disabled={scanning}
        selected={selected}
        sync={sync}
        onClick={handleClick}
      >
        {(settings.select || customActionComponent) && (
          <TableCell padding="checkbox">
            <Box display={"flex"} justifyContent={"center"} ml={-0.4}>
              {(customActionComponent && customActionComponent(expenseId)) || (
                <Checkbox
                  disabled={scanning}
                  checked={selected}
                  onClick={handleClickSelect}
                />
              )}
            </Box>
          </TableCell>
        )}
        <TableCell>
          <Box display={"flex"} justifyContent={"center"} position={"relative"}>
            <ReceiptIndicator
              staticMapURL={staticMapURL}
              isRoute={is_route}
              routeMode={route_mode}
              receipts={receipts}
              requiredReceipt={required_receipt}
            />
          </Box>
        </TableCell>

        {scanning ? (
          <TableCell colSpan={settings.select || customActionComponent ? 4 : 3}>
            <ScanningLayer />
          </TableCell>
        ) : (
          <>
            <TableCell>
              <Typography
                width={150}
                noWrap
                color={"text.secondary"}
                fontWeight={"500"}
                fontSize={".85rem"}
              >
                {formatDate(date)}
              </Typography>
            </TableCell>
            <TableCell>
              <Content
                route={{ from, to, waypoints }}
                isRoute={is_route}
                notes={notes}
                typeId={type_id}
                routeMode={route_mode}
                distance={distance}
                compact={settings.compact}
              />
            </TableCell>
            <PaymentCell paymentId={payment_type} />
            <ProjectCell projectId={project_id} />
            <RefundableCell refundable={refundable} />
            <TableCell align="right">
              <Amount amount={amount} currency={currency} />
            </TableCell>
            <TableCell>
              <ActionsArea
                error={disableActions ? null : processingError}
                expenseId={expenseId}
                addToRequest={settings.addToRequest}
                deleteExpense={settings.remove}
                removeFromRequest={settings.removeFromRequest}
                requestId={request_id}
                warnings={warnings}
                customActionComponent={customActionComponent}
                disableMoreActions={disableActions}
              />
            </TableCell>
          </>
        )}
      </Base>
    );
  }
);

export default memo(
  ExpenseLineItem,
  (prev, next) =>
    prev.selected === next.selected &&
    prev.rejected === next.rejected &&
    prev.settings?.select === next.settings?.select
);

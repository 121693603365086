import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  isAfter,
  isSameMonth,
  isThisMonth,
  isToday,
  subDays,
  subMonths,
} from "date-fns";
import { HttpClient } from "../../api/httpClient";
import WSMessenger from "../../api/websockets/messenger";
import DialogAlert from "../../classes/DialogAlert";
import normalize from "../../utils/normalize";
import {
  ADD_REQUEST_ROLLBACK,
  REMOVE_REQUEST_COMMIT,
  fetchRequestDetails,
} from "./requestsSlice";

const baseName = "expenses";

const adapter = createEntityAdapter({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) => b.created_at?.localeCompare(a.created_at),
});

const initialState = adapter.getInitialState({
  working: false,
  filters: {},
  selected: [],
});

//FETCH EXPENSES
export const syncExpenses = createAsyncThunk(
  `${baseName}/syncExpenses`,
  async () => {
    const res = await HttpClient.get("/expenses");
    //Formatar despesas da API para status persistente = O
    const formatedExpenses = res.data?.map((exp) => ({ ...exp, status: "O" }));
    return formatedExpenses;
  }
);

const SET_EXPENSE = `${baseName}/setExpense`;
const SET_EXPENSE_ROLLBACK = `${baseName}/setExpenseRollback`;
const SET_EXPENSE_COMMIT = `${baseName}/setExpenseCommit`;

const REMOVE_EXPENSES = `${baseName}/removeExpenses`;
const REMOVE_EXPENSES_ROLLBACK = `${baseName}/removeExpensesRollback`;
const REMOVE_EXPENSES_COMMIT = `${baseName}/removeExpensesCommit`;

const UPLOAD_RECEIPTS = `${baseName}/uploadReceipts`;
const UPLOAD_RECEIPTS_ROLLBACK = `${baseName}/uploadReceiptsRollback`;
const UPLOAD_RECEIPTS_COMMIT = `${baseName}/uploadReceiptsCommit`;

const REMOVE_RECEIPT = `${baseName}/removeReceipt`;
const REMOVE_RECEIPT_ROLLBACK = `${baseName}/removeReceiptRollback`;
const REMOVE_RECEIPT_COMMIT = `${baseName}/removeReceiptCommit`;

const ADD_TO_REQUEST = `${baseName}/addToRequest`;
const ADD_TO_REQUEST_ROLLBACK = `${baseName}/addToRequestRollback`;
const ADD_TO_REQUEST_COMMIT = `${baseName}/addToRequestCommit`;

const MOVE_TO_REQUEST = `${baseName}/moveToRequest`;
const MOVE_TO_REQUEST_ROLLBACK = `${baseName}/moveToRequestRollback`;
const MOVE_TO_REQUEST_COMMIT = `${baseName}/moveToRequestCommit`;

const REMOVE_FROM_REQUEST = `${baseName}/removeFromRequest`;
const REMOVE_FROM_REQUEST_ROLLBACK = `${baseName}/removeFromRequestRollback`;
const REMOVE_FROM_REQUEST_COMMIT = `${baseName}/removeFromRequestCommit`;

//offline actions
const setExpense = createAction(SET_EXPENSE, (expenseData) => {
  return {
    payload: expenseData,
    meta: {
      offline: {
        effect: {
          scope: "expenses",
          action: "set",
          json: expenseData,
        },
        rollback: {
          type: SET_EXPENSE_ROLLBACK,
          meta: { id: expenseData.id },
        },
        commit: {
          type: SET_EXPENSE_COMMIT,
          meta: expenseData,
        },
      },
    },
  };
});

const removeExpenses = createAction(REMOVE_EXPENSES, (ids) => {
  return {
    payload: ids,
    meta: {
      offline: {
        effect: {
          scope: "expenses",
          action: "remove",
          json: ids,
        },
        rollback: {
          type: REMOVE_EXPENSES_ROLLBACK,
          meta: { ids },
        },
        commit: {
          type: REMOVE_EXPENSES_COMMIT,
          meta: {
            ids,
          },
        },
      },
    },
  };
});

const uploadReceipts = createAction(
  UPLOAD_RECEIPTS,
  (expenseId, receipts, syncOnly) => {
    return {
      payload: { receipts, expenseId, syncOnly },
      meta: {
        offline: {
          effect: {
            scope: "receipts",
            action: "upload",
            json: {
              expenseId,
              receipts,
            },
          },
          rollback: {
            type: UPLOAD_RECEIPTS_ROLLBACK,
            meta: { receipts, expenseId },
          },
          commit: {
            type: UPLOAD_RECEIPTS_COMMIT,
            meta: { receipts, expenseId },
          },
        },
      },
    };
  }
);

const removeReceipt = createAction(REMOVE_RECEIPT, (expenseId, receiptId) => {
  return {
    payload: { receiptId, expenseId },
    meta: {
      offline: {
        effect: {
          scope: "receipts",
          action: "remove",
          json: { receiptId, expenseId },
        },
        rollback: {
          type: REMOVE_RECEIPT_ROLLBACK,
          meta: { receiptId, expenseId },
        },
        commit: {
          type: REMOVE_RECEIPT_COMMIT,
          meta: { receiptId, expenseId },
        },
      },
    },
  };
});

const addToRequest = createAction(ADD_TO_REQUEST, (expensesIds, requestId) => {
  return {
    payload: { expensesIds, requestId },
    meta: {
      offline: {
        effect: {
          scope: "expenses",
          action: "addToRequest",
          json: { expensesIds, requestId },
        },
        rollback: {
          type: ADD_TO_REQUEST_ROLLBACK,
          meta: { expensesIds, requestId },
        },
        commit: {
          type: ADD_TO_REQUEST_COMMIT,
          meta: { expensesIds, requestId },
        },
      },
    },
  };
});

const moveToRequest = createAction(
  MOVE_TO_REQUEST,
  (fromRequestId, toRequestId, expenses) => {
    return {
      payload: { fromRequestId, toRequestId, expenses },
      meta: {
        offline: {
          effect: {
            scope: "expenses",
            action: "moveToRequest",
            json: { expenses, fromRequestId, toRequestId },
          },
          rollback: {
            type: MOVE_TO_REQUEST_ROLLBACK,
            meta: { expenses, fromRequestId, toRequestId },
          },
          commit: {
            type: MOVE_TO_REQUEST_COMMIT,
            meta: { expenses, fromRequestId, toRequestId },
          },
        },
      },
    };
  }
);

const removeFromRequest = createAction(
  REMOVE_FROM_REQUEST,
  (requestId, expensesIds) => {
    return {
      payload: { requestId, expensesIds },
      meta: {
        offline: {
          effect: {
            scope: "expenses",
            action: "removeFromRequest",
            json: { expensesIds, requestId },
          },
          rollback: {
            type: REMOVE_FROM_REQUEST_ROLLBACK,
            meta: { expensesIds, requestId },
          },
          commit: {
            type: REMOVE_FROM_REQUEST_COMMIT,
            meta: { expensesIds, requestId },
          },
        },
      },
    };
  }
);

export const expensesSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    //SETUP
    setupExpenses(state, { payload }) {
      const { entities } = normalize(payload);
      adapter.setAll(state, entities);
    },

    onChangeExpensesSelection(state, action) {
      const { type, payload } = action.payload;

      const initialSelected = state.selected;

      const disabled = state.entities[payload]?.processingStatus === "scanning";

      switch (type) {
        case "select":
          if (!initialSelected.includes(payload) && !disabled) {
            state.selected = [...initialSelected, payload];
          }
          break;
        case "select-all":
          state.selected = payload
            ? payload.filter(
                (id) => state.entities[id]?.processingStatus !== "scanning"
              )
            : initialSelected;
          break;
        case "deselect":
          if (initialSelected.includes(payload)) {
            state.selected = initialSelected.filter((item) => item !== payload);
          }
          break;
        case "toggle":
          if (initialSelected.includes(payload)) {
            state.selected = initialSelected.filter((item) => item !== payload);
          } else if (!disabled) {
            state.selected = [...initialSelected, payload];
          }
          break;
        case "clear":
          state.selected = [];
          break;
        default:
          state.selected = initialSelected;
          break;
      }
    },
    setExpensesFilter(state, action) {
      const { filter, value } = action.payload;
      state.filters[filter] = value;
    },
    removeExpenseFilter(state, action) {
      delete state.filters[action.payload];
    },
    clearExpensesFilters(state, action) {
      state.filters = initialState.filters;
    },

    //ATUALIZAR/ADICIONAR DESPESA OFFLINE EFFECT
    setExpense(state, { payload }) {
      adapter.upsertOne(state, {
        ...payload,
        processingStatus: "sync",
        processingError: null,
      });
    },
    setExpenseCommit(state, { meta, payload }) {
      delete payload.receipts;
      const expenseId = meta.id;
      adapter.updateOne(state, {
        id: expenseId,
        changes: {
          ...payload,
          processingStatus: null,
          processingError: null,
        },
      });
      WSMessenger.applyMessage();
    },
    setExpenseRollback(state, { payload, meta }) {
      const { id } = meta;
      const errorMessage = payload?.message;
      adapter.updateOne(state, {
        id,
        changes: {
          processingStatus: null,
          processingError:
            errorMessage || "Erro não indentificado ao sincronizar despesa",
        },
      });
    },

    //REMOVER DESPESA OFFLINE EFFECT
    removeExpenses(state, action) {
      const expensesIds = action.payload;
      adapter.updateMany(
        state,
        expensesIds.map((id) => ({
          id,
          changes: {
            hidden: true,
          },
        }))
      );
    },
    removeExpensesCommit(state, action) {
      const expensesIds = action.meta.ids;
      adapter.removeMany(state, expensesIds);
      WSMessenger.sendMessage();
    },
    removeExpensesRollback(state, { meta, payload }) {
      DialogAlert.show({
        title: "Erro ao excluir despesa(s)",
        message: payload?.message,
        isError: true,
      });
      const expensesIds = meta.ids;
      adapter.updateMany(
        state,
        expensesIds.map((expenseId) => ({
          id: expenseId,
          changes: {
            hidden: false,
          },
        }))
      );
    },

    //UPLOAD/REMOVE RECEIPT OFFLINE EFFECT
    uploadReceipts(state, { payload }) {
      const { expenseId, receipts, syncOnly } = payload;
      if (!syncOnly) {
        state.entities[expenseId].receipts?.unshift(...receipts);
      }
    },
    uploadReceiptsCommit(state, { meta, payload }) {
      WSMessenger.applyMessage();
    },
    uploadReceiptsRollback(state, { meta, payload }) {
      DialogAlert.show({
        title: "Erro ao fazer upload de comprovantes",
        message: payload?.message,
        isError: true,
      });
      const { receipts, expenseId } = meta;
      state.entities[expenseId].receipts = [
        ...(state.entities[expenseId]?.receipts || []).filter((receipt) =>
          Boolean(receipts.find((item) => item.id !== receipt.id))
        ),
      ];
    },

    removeReceipt(state, { payload }) {
      const { expenseId, receiptId } = payload;
      const idx = state.entities[expenseId]?.receipts?.findIndex(
        (rc) => rc.id === receiptId
      );
      if (idx !== -1) {
        state.entities[expenseId].receipts[idx].hidden = true;
      }
    },
    removeReceiptCommit(state, action) {
      const { expenseId, receiptId } = action.meta;
      const idx = state.entities[expenseId]?.receipts?.findIndex(
        (rc) => rc.id === receiptId
      );
      if (idx !== -1) {
        state.entities[expenseId]?.receipts?.splice(idx, 1);
      }
      WSMessenger.sendMessage();
    },
    removeReceiptRollback(state, { meta, payload }) {
      DialogAlert.show({
        title: "Erro ao remover comprovante",
        message: payload?.message,
        isError: true,
      });
      const { expenseId, receiptId } = meta;
      const idx = state.entities[expenseId]?.receipts?.findIndex(
        (rc) => rc.id === receiptId
      );
      if (idx !== -1) {
        state.entities[expenseId].receipts[idx].hidden = false;
      }
    },

    //ADD TO REQUEST OFFLINE EFFECT
    addToRequest(state, { payload }) {
      const { requestId, expensesIds } = payload;
      adapter.updateMany(
        state,
        expensesIds.map((expId) => ({
          id: expId,
          changes: {
            request_id: requestId,
          },
        }))
      );
    },
    addToRequestCommit(state, { meta }) {
      WSMessenger.sendMessage();
    },
    addToRequestRollback(state, { meta, payload }) {
      DialogAlert.show({
        title: "Erro ao adicionar despesa(s) ao relatório",
        message: payload?.message,
        isError: true,
      });
      const { expensesIds, requestId } = meta;
      adapter.updateMany(
        state,
        expensesIds.map((expId) => ({
          id: expId,
          changes: {
            request_id: null,
          },
        }))
      );
    },

    //MOVE TO REQUEST OFFLINE EFFECT
    moveToRequest(state, { payload }) {
      const { fromRequestId, toRequestId, expenses } = payload;
      adapter.updateMany(
        state,
        expenses.map((expId) => ({
          id: expId,
          changes: {
            request_id: toRequestId,
          },
        }))
      );
    },
    moveToRequestCommit(state, { meta }) {
      WSMessenger.sendMessage();
    },
    moveToRequestRollback(state, { meta, payload }) {
      DialogAlert.show({
        title: "Não foi possível mover as despesas",
        message: payload?.message,
        isError: true,
      });
      const { expenses, fromRequestId } = meta;
      adapter.updateMany(
        state,
        expenses.map((expId) => ({
          id: expId,
          changes: {
            request_id: fromRequestId,
          },
        }))
      );
    },

    //REMOVE FROM REQUEST OFFLINE EFFECT
    removeFromRequest(state, { payload }) {
      const { requestId, expensesIds } = payload;
      adapter.updateMany(
        state,
        expensesIds.map((expId) => ({
          id: expId,
          changes: {
            request_id: null,
          },
        }))
      );
    },
    removeFromRequestCommit(state, { meta }) {
      WSMessenger.sendMessage();
    },
    removeFromRequestRollback(state, { meta, payload }) {
      DialogAlert.show({
        title: "Erro ao remover as despesas do relatório",
        message: payload?.message,
        isError: true,
      });
      const { expensesIds, requestId } = meta;
      adapter.updateMany(
        state,
        expensesIds.map((expId) => ({
          id: expId,
          changes: {
            request_id: requestId,
          },
        }))
      );
    },
    //UTILS
    upsertExpense(state, action) {
      adapter.upsertOne(state, action.payload);
    },
    upsertManyExpenses(state, action) {
      adapter.upsertMany(state, action.payload);
    },
    addManyExpenses(state, { payload }) {
      adapter.addMany(state, payload);
    },
    updateExpense(state, action) {
      const { id, changes } = action.payload;
      adapter.updateOne(state, { id, changes });
    },
    updateManyExpenses(state, action) {
      const { ids, changes } = action.payload;
      adapter.updateMany(
        state,
        ids.map((id) => ({
          id,
          changes,
        }))
      );
    },
    removeManyExpenses(state, { payload }) {
      adapter.removeMany(state, payload);
    },
  },
  extraReducers: (builder) => {
    builder

      //Alterar status das despesas do relatório ao ser enviado para aprovação = P
      .addCase("requests/sendToApproval", (state, action) => {
        const { requestId } = action.payload;
        const expensesRequest = state.ids.filter(
          (id) => state.entities[id]?.request_id === requestId
        );
        expensesSlice.caseReducers.updateManyExpenses(state, {
          payload: {
            ids: expensesRequest,
            changes: {
              status: "P",
            },
          },
        });
      })

      //Alterar status das despesas do relatório ao ser reaberto = O
      .addCase("requests/reopen", (state, action) => {
        const { requestId } = action.payload;
        const expensesRequest = state.ids.filter(
          (id) => state.entities[id]?.request_id === requestId
        );
        expensesSlice.caseReducers.updateManyExpenses(state, {
          payload: {
            ids: expensesRequest,
            changes: {
              status: "O",
            },
          },
        });
      })

      //Remover despesas do relatório caso der erro ao adicioná-lo
      .addCase(ADD_REQUEST_ROLLBACK, (state, action) => {
        const { id: requestId } = action.meta;
        const expensesRequest = state.ids.filter(
          (id) => state.entities[id]?.request_id === requestId
        );

        expensesSlice.caseReducers.updateManyExpenses(state, {
          payload: {
            ids: expensesRequest,
            changes: {
              request_id: null,
            },
          },
        });
      })

      //Excluir despesas ao excluir relatório
      .addCase(REMOVE_REQUEST_COMMIT, (state, action) => {
        const { requestId } = action.meta;
        const expensesRequest = state.ids.filter(
          (id) => state.entities[id]?.request_id === requestId
        );
        expensesSlice.caseReducers.removeManyExpenses(state, {
          payload: expensesRequest,
        });
      })

      //Lógica de sincronização de despesas
      .addCase(syncExpenses.pending, (state, action) => {
        state.working = true;
      })
      .addCase(syncExpenses.rejected, (state, action) => {
        state.working = false;
      })
      .addCase(syncExpenses.fulfilled, (state, action) => {
        //Pegar despesas da API
        const expensesAPI = action.payload || [];

        //Normalizar dados da API
        const { entities: entitiesAPI, ids: idsAPI } = normalize(expensesAPI);

        //Juntar todos os IDS store/api em um único array
        const allIds = [...new Set([...state.ids, ...idsAPI])];

        //Juntar todas as entidades store/api em um único objeto
        let entities = { ...state.entities, ...entitiesAPI };

        //Verificar cada expenseId, de allIds
        for (const expenseId of allIds) {
          //Se o expenseId, estiver nos dados da API, add/update
          if (idsAPI.includes(expenseId)) {
            const { entities: prevReceiptsEntities } = normalize(
              state.entities[expenseId]?.receipts
            );
            const { entities: newReceiptsEntities } = normalize(
              entitiesAPI[expenseId]?.receipts
            );
            const formatedReceipts = {};
            for (const receiptId in newReceiptsEntities) {
              formatedReceipts[receiptId] = {
                ...prevReceiptsEntities[receiptId],
                ...newReceiptsEntities[receiptId],
              };
            }

            //Atualização de dados de acordo com o status de processamento
            const expenseProcessing =
              Boolean(entities[expenseId]?.processingStatus) ||
              Boolean(entities[expenseId]?.processingError);

            const updatedEntities = expenseProcessing
              ? {
                  ...entitiesAPI[expenseId],
                  ...state.entities[expenseId],
                }
              : {
                  ...state.entities[expenseId],
                  ...entitiesAPI[expenseId],
                };

            //Atualizar comprovantes formatados
            entities[expenseId] = {
              ...updatedEntities,
              processingStatus: null,
              receipts: Object.values(formatedReceipts),
            };
          } else {
            //Se a despesa não esiver em status de processamento, remover
            if (
              !Boolean(entities[expenseId]?.processingStatus) &&
              !Boolean(entities[expenseId]?.processingError) &&
              entities[expenseId]?.status === "O"
            ) {
              delete entities[expenseId];
            }
          }
        }
        //Atualizar estados
        adapter.setAll(state, entities);
        state.working = false;
      })

      //Conferir despesas de um relatório ao ser detalhado
      .addCase(
        fetchRequestDetails.fulfilled,
        (state, { meta: { arg: requestId }, payload }) => {
          if (!["AD", "O"].includes(payload?.status)) {
            expensesSlice.caseReducers.upsertManyExpenses(state, {
              payload: payload.expenses?.map((exp) => ({
                ...exp,
                status: payload?.status,
                request_id: requestId,
              })),
            });
          }
        }
      );
  },
});

export const expensesOfflineActions = {
  setExpense,
  removeExpenses,
  uploadReceipts,
  removeReceipt,
  addToRequest,
  moveToRequest,
  removeFromRequest,
};

export const {
  setupExpenses,
  updateExpense,
  upsertManyExpenses,
  upsertExpense,
  onChangeExpensesSelection,
  updateManyExpenses,
  updateExpensesLastSync,
  removeManyExpenses,
  removeReceipts,
  addReceipts,
  setExpensesFilter,
  removeExpenseFilter,
  clearExpensesFilters,
} = expensesSlice.actions;

export const {
  selectAll: selectAllExpenses,
  selectIds: selectExpensesIds,
  selectById: selectExpenseById,
  selectEntities: selectExpensesEntities,
  selectTotal: selectExpensesTotal,
} = adapter.getSelectors((state) => state[baseName]);

export const selectSingleExpensesIds = createSelector(
  [selectExpensesIds, selectExpensesEntities],
  (ids, entities) => {
    return ids.filter(
      (id) => !entities[id].request_id && !Boolean(entities[id].hidden)
    );
  }
);

const selectAllSingleExpenses = createSelector(
  [selectAllExpenses],
  (expenses) => {
    return expenses.filter(
      (expense) => !expense.request_id && !Boolean(expense.hidden)
    );
  }
);

export const selectExpensesByRequest = createSelector(
  [selectAllExpenses, (state, requestId) => requestId],
  (expenses, requestId) => {
    return expenses.filter(
      (expense) => !expense.hidden && expense.request_id === requestId
    );
  }
);

export const selectExpensesIdsByRequest = createSelector(
  [selectAllExpenses, (state, requestId) => requestId],
  (expenses, requestId) => {
    return expenses
      .filter(
        (expense) =>
          !expense.hidden &&
          expense.request_id &&
          expense.request_id === requestId
      )
      .map((exp) => exp.id);
  }
);

export const selectExpensesTotalAmountByIds = createSelector(
  [selectExpensesEntities, (state, ids) => ids],
  (entities, ids) => {
    return ids.reduce(
      (amount, expenseId) =>
        amount +
        (parseFloat(
          entities[expenseId]?.amount_converted ||
            entities[expenseId]?.amount ||
            0
        ) || 0),
      0
    );
  }
);
export const selectExpensesTotalRefundableByIds = createSelector(
  [selectExpensesEntities, (state, ids) => ids],
  (entities, ids) => {
    return ids
      ?.filter((expenseId) => Boolean(entities[expenseId]?.refundable))
      ?.reduce(
        (amount, expenseId) =>
          amount +
          (parseFloat(
            entities[expenseId]?.amount_converted ||
              entities[expenseId]?.amount ||
              0
          ) || 0),
        0
      );
  }
);

export const selectExpensesTotalAmountByRequest = createSelector(
  [selectExpensesByRequest],
  (expenses) => {
    return expenses.reduce(
      (amount, cId) => amount + parseFloat(cId.amount_converted || cId.amount),
      0
    );
  }
);

export const selectExpensesTotalRefundableByRequest = createSelector(
  [selectExpensesByRequest],
  (expenses) => {
    return expenses
      .filter((exp) => Boolean(exp.refundable))
      .reduce(
        (amount, cId) =>
          amount + parseFloat(cId.amount_converted || cId.amount),
        0
      );
  }
);

export const selectExpensesNumByRequest = createSelector(
  [selectExpensesByRequest],
  (expenses) => {
    return expenses?.length || 0;
  }
);

export const selectHasExpensesWithRequiredReceipt = createSelector(
  [selectExpensesByRequest],
  (expenses) =>
    expenses.some((exp) => exp.required_receipt && !exp.receipts.length)
);

export const selectExpensesSelected = (state) => state.expenses.selected;
export const selectExpensesFilters = (state) => state.expenses.filters;

//apply filters
const filtersBase = (expenses) => {
  return {
    getByDate: (dateOption) => {
      if (dateOption === "today") {
        return expenses.filter((exp) => isToday(new Date(exp.date)));
      }
      if (dateOption === "this_month") {
        return expenses.filter((exp) => isThisMonth(new Date(exp.date)));
      }
      if (dateOption === "last_month") {
        return expenses.filter((exp) =>
          isSameMonth(subMonths(new Date(), 1), new Date(exp.date))
        );
      }
      if (dateOption === "last_90_days") {
        return expenses.filter((exp) =>
          isAfter(new Date(exp.date), subDays(new Date(), 90))
        );
      }
    },
    getByType: (expTypes) =>
      expenses.filter((exp) => exp.type_id && expTypes.includes(exp.type_id)),
    getByRefundable: (bool) =>
      expenses.filter((exp) => Boolean(exp.refundable)),
    getByPayment: (payment) =>
      expenses.filter((exp) => exp.payment_type === payment),
    getByProject: (project) =>
      expenses.filter((exp) => exp.project_id === project),
    getByIsRoute: (bool) =>
      expenses.filter((exp) => Boolean(exp.isRoute || exp.is_route)),
    getByReceipts: (type) =>
      expenses.filter((exp) =>
        type === "no-receipts"
          ? !Boolean(exp.receipts?.length)
          : type === "with-receipts" && exp.receipts?.length >= 1
      ),
  };
};
export const selectExpensesIdsByFilters = createSelector(
  [selectAllSingleExpenses, selectExpensesFilters],
  (expenses, filters) => {
    if (!expenses.length) return [];
    let filtered = [...expenses];
    if (
      filters &&
      "expTypes" in filters &&
      filters.expTypes &&
      filters.expTypes.length
    ) {
      filtered = filtersBase(filtered).getByType(filters.expTypes);
    }
    console.log(filters);
    if (filters && "date" in filters && filters.date) {
      filtered = filtersBase(filtered).getByDate(filters.date);
    }
    if (filters && "refundable" in filters) {
      filtered = filtersBase(filtered).getByRefundable(filters.refundable);
    }
    if (filters && "payment" in filters) {
      filtered = filtersBase(filtered).getByPayment(filters.payment);
    }
    if (filters && "project" in filters) {
      filtered = filtersBase(filtered).getByProject(filters.project);
    }
    if (filters && "isRoute" in filters) {
      filtered = filtersBase(filtered).getByIsRoute(filters.isRoute);
    }
    if (filters && "receipts" in filters) {
      filtered = filtersBase(filtered).getByReceipts(filters.receipts);
    }
    return [...filtered]?.map((item) => item.id);
  }
);

export const selectSingleExpensesNum = createSelector(
  [selectSingleExpensesIds],
  (ids) => {
    return ids.length;
  }
);

export const selectExpensesObjectArrayByIds = createSelector(
  [selectAllExpenses, (state, ids) => ids],
  (expenses = [], ids = []) => {
    return expenses?.filter((exp) => ids?.includes(exp.id));
  }
);

export const selectExpensesScanningTotal = createSelector(
  [selectSingleExpensesIds, selectExpensesEntities],
  (expensesIds, entities) => {
    return expensesIds.filter(
      (expenseId) => entities[expenseId]?.processingStatus === "scanning"
    )?.length;
  }
);

export default expensesSlice.reducer;

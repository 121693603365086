import axios from "axios";
import { refreshToken } from "./auth";
import { API_BASE_URL, AUTH_CLIENT_URL } from "./urls";

class HttpClient {
  static _client;
  static _isConnected = true;
  static _accessToken = null;

  static _offlineCallback = () => {
    window.alert("Falha de conexão");
  };

  static setIsConnected(c) {
    this._isConnected = !!c;
  }

  static setup() {
    this._client = axios.create({
      baseURL: API_BASE_URL,
      withCredentials: true,
    });
  }

  static setOfflineEventListener(callback) {
    this._offlineCallback = callback;
  }

  static setAccessToken(token) {
    if (this._client) {
      // console.log('Setou access token Httpclient', Boolean(token));
      this._client.defaults.headers["api-access-token"] = token;
      this._accessToken = token;
    }
  }

  static setAuthFailedEventListener() {
    this._client.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const status = error?.response?.status;
        if (status === 403) {
          refreshToken();
        }
        return Promise.reject(error);
      }
    );
  }

  static async post(url, body, params = {}) {
    if (!this._isConnected) {
      this._offlineCallback();
      return;
    }
    try {
      const data = await this._client.post(url, body, {
        params,
      });
      return data;
    } catch (error) {
      throw error;
    }
  }
  static async get(url, params = {}) {
    if (!this._isConnected) {
      this._offlineCallback();
      return;
    }
    try {
      const data = await this._client.get(url, {
        params,
      });
      return data;
    } catch (error) {
      throw error;
    }
  }
  static async put(url, body, params = {}) {
    if (!this._isConnected) {
      this._offlineCallback();
      return;
    }
    try {
      const data = await this._client.put(url, body, {
        params,
      });
      return data;
    } catch (error) {
      throw error;
    }
  }
  static async delete(url, body, params = {}) {
    if (!this._isConnected) {
      this._offlineCallback();
      return;
    }
    try {
      const data = await this._client.delete(url, body, {
        params,
      });
      return data;
    } catch (error) {
      throw error;
    }
  }
}

class AuthClient {
  static _client = null;
  static client() {
    if (!this._client) {
      this._client = axios.create({
        baseURL: AUTH_CLIENT_URL,
        withCredentials: true,
      });
    }
    return this._client;
  }
  static setAccessToken(token) {
    this._client.defaults.headers["api-access-token"] = token;
  }
}

class AuthAPIGateway {
  static async refreshToken(account = undefined) {
    const r = await AuthClient.client().get("/refresh_token", {
      account,
    });
    const d = r.data;
    if (d.accessToken) {
      AuthClient.setAccessToken(d.accessToken);
    }
    return r;
  }
  static getMe() {
    return HttpClient.get("/users/me");
  }
  static async logout() {
    await AuthClient.client().get("/logout");
  }
}

export { AuthAPIGateway, AuthClient, HttpClient };


import { MoreVert } from "@mui/icons-material";
import { Box, IconButton, TableCell, TableRow } from "@mui/material";
import React, { memo } from "react";
import ContextMenuWrapper from "../card/components/ContextMenuWrapper";

function Base({
  sx,
  disableRowActions,
  onClick,
  children,
  requestId,
  innerRef,
  settings,
}) {
  const renderComponent = ({ setMenu, opened }) => {
    return (
      <Box
        component={TableRow}
        onContextMenu={(e) => {
          e.preventDefault();
          setMenu(e.target);
        }}
        hover
        onClick={onClick}
        id={`r-${requestId}`}
        ref={innerRef}
        selected={opened}
        width="100%"
        position="relative"
        sx={{ cursor: "pointer" }}
      >
        {children}
        {!disableRowActions && (
          <TableCell align="right">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setMenu(e.target);
              }}
            >
              <MoreVert />
            </IconButton>
          </TableCell>
        )}
      </Box>
    );
  };

  return (
    <ContextMenuWrapper
      requestId={requestId}
      settings={settings}
      renderComponent={renderComponent}
    />
  );
}

export default memo(Base);

import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAccountCurrency } from "../../../store/features/accountSlice";
import { openSnackbar } from "../../../store/features/base/snackbarBaseSlice";
import { selectProjectById } from "../../../store/features/configs/projectsSlice";
import { selectRoutePoliciesEntities } from "../../../store/features/configs/routePoliciesSlice";
import { selectSnippetsEntities } from "../../../store/features/configs/snippetsSlice";
import {
  expensesOfflineActions,
  selectExpenseById,
} from "../../../store/features/expensesSlice";
import {
  closeElement,
  selectPortalIsOpened,
  selectPortalUtil,
} from "../../../store/features/portalSlice";
import formatAmountStringToNumberCopy from "../../../utils/formatAmountStringToNumber copy";
import { getReceiptUrl } from "../../../utils/functions/receipts";
import getRateByDate from "../../../utils/getRateByDate";
import { newRouteInitialState } from "../../../utils/initialStates";
import { curr } from "../../../utils/more/currency_country";
import ReceiptsContent from "../expense/contents/receipts/ReceiptsContent";
import Layout from "./Layout";
import EditMode from "./contents/edit/EditMode";
import ViewMode from "./contents/view/ViewMode";

function reducer(state, action) {
  switch (action.type) {
    case "setEditMode":
      return { ...state, editMode: action.payload };
    case "setExpenseCopy":
      return { ...state, expenseCopy: action.payload };
    case "setExpenseCopyProp":
      return {
        ...state,
        expenseCopy: {
          ...state.expenseCopy,
          [action.payload.prop]: action.payload.value,
        },
      };
    case "setConversionProp":
      return {
        ...state,
        conversion: {
          ...state.conversion,
          [action.payload.prop]: action.payload.value,
        },
      };
    case "setChanged":
      return { ...state, changed: action.payload };
    case "reset":
      return { ...state, expenseCopy: {}, changed: false, editMode: false };
    default:
      throw new Error();
  }
}

function ModalExpenseView({ settings }) {
  const dispatch = useDispatch();
  const accountCurrency = useSelector(selectAccountCurrency);

  //portal utils
  const open = useSelector((state) =>
    selectPortalIsOpened(state, "manualRouteView")
  );
  const expenseId = useSelector((state) =>
    selectPortalUtil(state, "manualRouteView", "expenseId")
  );
  const expenseObj = useSelector((state) =>
    selectPortalUtil(state, "manualRouteView", "expenseObj")
  );

  //original expense data
  const expense = useSelector(
    (state) => expenseObj || selectExpenseById(state, expenseId)
  );

  //functions utils
  const onClose = useCallback(
    () => dispatch(closeElement("manualRouteView")),
    []
  );
  const showSnackbar = useCallback(
    (message) => dispatch(openSnackbar({ message })),
    []
  );
  const setExpense = useCallback(
    (data) => dispatch(expensesOfflineActions.setExpense(data)),
    []
  );

  //entities
  const routePoliciesEntities = useSelector(selectRoutePoliciesEntities);
  const snippetsEntities = useSelector(selectSnippetsEntities);

  //refs
  const copiedValuesRef = useRef(expense);
  const expensePolicyRef = useRef();

  //principal state
  const [state, dispatchState] = useReducer(reducer, {
    loading: false,
    editMode: false,
    expenseCopy: expense || {},
  });
  const { loading, editMode, expenseCopy } = state;

  //entities by data
  const project = useSelector((s) =>
    selectProjectById(s, expenseCopy.project_id)
  );

  //functions edit states
  const setEditMode = useCallback((value) => {
    dispatchState({ type: "setEditMode", payload: value });
  }, []);
  const setExpenseCopy = useCallback((value) => {
    dispatchState({ type: "setExpenseCopy", payload: value });
  }, []);
  const handleChangeExpenseCopy = useCallback(
    (prop, v) => {
      dispatchState({
        type: "setExpenseCopyProp",
        payload: { prop, value: v },
      });
    },
    [dispatchState]
  );
  const handleEdit = useCallback(() => {
    setEditMode(true);
  }, []);
  const handleCancelEdit = useCallback(() => {
    setEditMode(false);
  }, []);

  //copy data control
  useEffect(() => {
    if (!open) {
      setEditMode(false);
    }
    if (expense) {
      setExpenseCopy({ ...expense });
    }
  }, [open, editMode, expense]);

  //receipts
  const receipts = useMemo(() => {
    if (open) {
      return expense?.receipts.map((receipt) => ({
        ...receipt,
        uri: receipt.uri || getReceiptUrl(receipt.url),
      }));
    } else {
      return [];
    }
  }, [open, expense?.receipts]);

  // rate / policies / amount /refundable / canEditDistance
  const routePolicyIsDetached = useMemo(
    () =>
      Boolean(
        expenseCopy?.route_policy_id === expense?.route_policy_id &&
          !routePoliciesEntities[expenseCopy.route_policy_id]
      ),
    [
      expenseCopy?.route_policy_id,
      routePoliciesEntities,
      expense?.route_policy_id,
    ]
  );

  const currentRate = useMemo(() => {
    if (!open) return 0;
    if (routePolicyIsDetached) {
      return expense?.rate;
    } else {
      return getRateByDate(
        routePoliciesEntities[expenseCopy.route_policy_id]?.history || [],
        new Date(expenseCopy.date)
      );
    }
  }, [
    expense?.rate,
    routePolicyIsDetached,
    expenseCopy.route_policy_id,
    routePoliciesEntities,
    expenseCopy.date,
    open,
  ]);

  const amount = useMemo(() => {
    if (!open) return 0;
    const distanceValue = formatAmountStringToNumberCopy(expenseCopy.distance);
    return distanceValue * parseFloat(currentRate);
  }, [expenseCopy.distance, currentRate, open]);

  const refundable = useMemo(() => {
    if (!open) return newRouteInitialState.refundable;
    return Boolean(
      routePoliciesEntities[expenseCopy.route_policy_id]?.is_refundable
    );
  }, [expenseCopy.route_policy_id, routePoliciesEntities, open]);

  //snippets distance effect
  const canEditDistance = useMemo(() => {
    if (!expenseCopy.snippet_id) return true;
    return Boolean(
      expenseCopy.snippet_id &&
        snippetsEntities[expenseCopy.snippet_id]?.can_edit_distance
    );
  }, [expenseCopy.snippet_id, snippetsEntities]);
  useEffect(() => {
    if (expenseCopy.snippet_id) {
      handleChangeExpenseCopy(
        "distance",
        parseFloat(
          snippetsEntities[expenseCopy.snippet_id]?.distance || 0
        ).toFixed(2)
      );
    }
  }, [expenseCopy.snippet_id, snippetsEntities]);

  //refs control
  useEffect(() => {
    copiedValuesRef.current = {
      ...expenseCopy,
      amount,
      project_name: project?.name || expenseCopy?.project_name || "",
      rate: currentRate,
      snippet_name:
        snippetsEntities[expenseCopy?.snippet_id]?.name ||
        expenseCopy?.snippet_name ||
        "",
      can_edit_distance: canEditDistance,
      route_policy_name:
        routePoliciesEntities[expenseCopy.route_policy_id]?.name ||
        expenseCopy?.route_policy_name ||
        "",
    };
  }, [
    expenseCopy,
    project,
    routePoliciesEntities,
    currentRate,
    snippetsEntities,
    canEditDistance,
    amount,
  ]);

  //SAVE
  const handleSave = useCallback(
    async (event, isChecked) => {
      const data = { ...(copiedValuesRef?.current || {}) };
      let ok = false;
      const { isOk, warnings, required_receipt } =
        expensePolicyRef.current?.check(
          {
            ...data,
            route_mode: "manual",
            currency_t: accountCurrency,
          },
          !isChecked
        );
      data.warnings = warnings || [];
      data.required_receipt = Boolean(required_receipt);
      if (isChecked) {
        ok = true;
      } else {
        ok = isOk;
      }
      if (!ok) return;
      setExpense({ id: expenseId, ...data });
      handleCancelEdit();
      showSnackbar("Despesa salva");
      onClose();
    },
    [expenseId]
  );

  return (
    open &&
    expenseId && (
      <Layout
        open={open}
        settings={settings}
        warnings={expense?.warnings || []}
        expensePolicyRef={expensePolicyRef}
        showRequiredReceipt={!Boolean(receipts.length)}
        expenseId={expenseId}
        onClose={onClose}
        editMode={editMode}
        changed={true}
        onEdit={handleEdit}
        onCancelEdit={handleCancelEdit}
        onSave={handleSave}
        loading={loading}
        receiptsContent={
          <ReceiptsContent
            settings={settings}
            expenseId={expenseId}
            receipts={receipts}
            requiredReceipt={expense?.required_receipt}
            duplicatedReceipts={expense?.duplicated_receipts}
          />
        }
        viewContent={<ViewMode expense={expense} />}
        editContent={
          <EditMode
            rate={currentRate}
            canEditDistance={canEditDistance}
            currency={curr(accountCurrency)}
            amount={amount}
            expense={{ ...expenseCopy, refundable }}
            onChangeValue={handleChangeExpenseCopy}
            routePolicyIsDetached={routePolicyIsDetached}
            predefinedValues={{
              route_policy_name: expense?.route_policy_name,
              project_name: expense?.project_name,
            }}
          />
        }
      />
    )
  );
}

export default memo(ModalExpenseView);

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { memo, useCallback, useEffect } from "react";
import FlipMove from "react-flip-move";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import NoRequestsSVG from "../../../assets/bgs/NoRequestsSVG";
import RequestsActions from "../../../components/appbar/components/RequestsActions";
import EmptyContent from "../../../components/form/EmptyContent";
import RequestCardItem from "../../../components/requests/card/RequestCardItem";
import RequestLineItem from "../../../components/requests/line/RequestLineItem";
import { selectLayoutViewMode } from "../../../store/features/base/settingsSlice";

const REQUESTS_GRID_STYLE = {
  padding: "25px",
  paddingBottom: "50px",
  paddingTop: "10px",
  display: "grid",
  gridGap: "15px",
  gridTemplateColumns: "repeat(auto-fill, minmax(340px,1fr))",
  gridAutoRows: "auto",
};

function RequestsMapper({
  requests = [],
  sx,
  disableRowActions,
  emptyText = "",
}) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();
  const { state } = useLocation();

  const viewMode = useSelector(selectLayoutViewMode);

  const handleClick = useCallback((requestId) => {
    navigate(`/approval-requests/${requestId}`);
  }, []);

  useEffect(() => {
    if (state?.visitedRequest) {
      const element = document.getElementById(`r-${state?.visitedRequest}`);
      if (element) {
        element.scrollIntoView({ behavior: "instant", block: "center" });
      }
    }
  }, [state?.visitedRequest]);

  const renderLineRequests = useCallback(
    (requestId) => {
      return (
        <RequestLineItem
          onClick={handleClick}
          key={requestId}
          disableRowActions={disableRowActions}
          requestId={requestId}
        />
      );
    },
    [handleClick, disableRowActions]
  );
  const renderCardRequests = useCallback(
    (requestId) => {
      return (
        <RequestCardItem
          onClick={handleClick}
          key={requestId}
          requestId={requestId}
        />
      );
    },
    [handleClick]
  );

  return (
    <>
      {!Boolean(requests.length) ? (
        <EmptyContent
          artComponent={<NoRequestsSVG />}
          title={"Nada por aqui"}
          subtitle={
            "Organize suas despesas em um relatório e envie-o para aprovação"
          }
          actionComponent={<RequestsActions />}
        />
      ) : viewMode === "line" ? (
        <TableContainer sx={{ pb: "50px" }}>
          <Table sx={{ tableLayout: "fixed" }}>
            <TableHead
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  py: 1,
                  color: "text.secondary",
                },
              }}
            >
              <TableRow>
                <TableCell width={300}>Relatório</TableCell>
                <TableCell width={200}>Status</TableCell>
                <TableCell width={150}>Criado em</TableCell>
                <TableCell width={250}>Total</TableCell>
                {!disableRowActions && (
                  <TableCell
                    padding="none"
                    width={60}
                    align="right"
                  ></TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  py: 1.5,
                  border: "none",
                },
              }}
            >
              {requests.map(renderLineRequests)}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <FlipMove
          duration={200}
          style={{
            ...REQUESTS_GRID_STYLE,
            padding: isSmall ? "15px" : REQUESTS_GRID_STYLE.padding,
            ...sx,
          }}
        >
          {requests.map(renderCardRequests)}
        </FlipMove>
      )}
    </>
  );
}

export default memo(RequestsMapper);

import { SupportAgentOutlined } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { StyledBadge } from "../../appbar/components/notifications/NotificationButton";

const HelpButton = () => {
  const [unreadCount, setUnreadCount] = useState(0);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [message, setMessage] = useState("Suporte Notepay");

  useEffect(() => {
    if (!tooltipOpen) {
      setMessage("Suporte Notepay");
    }
  }, [tooltipOpen]);

  useEffect(() => {
    if (window.zE) {
      window.zE("messenger:on", "unreadMessages", function (count) {
        setUnreadCount(count);
        if (!count) {
          setTooltipOpen(false);
        }
        if (count > unreadCount) {
          setTooltipOpen(true);
          setMessage("Nova mensagem!");
          setTimeout(() => {
            setTooltipOpen(false);
          }, 8000);
        }
      });
    }
  }, []);

  const handleClick = (e) => {
    if (window.zE) {
      window.zE("messenger:set", "zIndex", 999999999);
      window.zE("messenger", "show");
      window.zE("messenger", "open");
    }
  };
  return (
    <Tooltip
      placement="right"
      open={tooltipOpen}
      arrow={unreadCount > 0}
      onOpen={() => setTooltipOpen(true)}
      onClose={() => {
        setTooltipOpen(false);
      }}
      title={message}
      TransitionProps={{
        timeout: 0,
      }}
    >
      <IconButton
        sx={{ alignSelf: "center", mb: 1 }}
        id="notepay-support-button"
        onClick={handleClick}
      >
        <StyledBadge color="error" badgeContent={unreadCount}>
          <SupportAgentOutlined />
        </StyledBadge>
      </IconButton>
    </Tooltip>
  );
};

export default memo(HelpButton);

import { Box } from "@mui/material";
import React, { memo } from "react";
import { MoreButton } from "../../card/components/BottomActions";
import ErrorButton from "../../card/components/ErrorButton";
import WarningsButton from "../../card/components/WarningsButton";

const ActionsArea = ({
  error,
  removeFromRequest,
  addToRequest,
  deleteExpense,
  expenseId,
  requestId,
  warnings,
  disableMoreActions,
}) => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"flex-end"}
    >
      {!error && (
        <>
          {Boolean(warnings && warnings.length) && (
            <WarningsButton warnings={warnings} expenseId={expenseId} />
          )}
          {!disableMoreActions && (
            <MoreButton
              removeFromRequest={removeFromRequest}
              addToRequest={addToRequest}
              deleteExpense={deleteExpense}
              expenseId={expenseId}
              requestId={requestId}
            />
          )}
        </>
      )}
      {error && <ErrorButton error={error} expenseId={expenseId} />}
    </Box>
  );
};

export default memo(ActionsArea);

export const selectionReducer = (state, action) => {
  switch (action.type) {
    case "select":
      return [...state, action.payload];
    case "select-all":
      return [...action.payload];
    case "deselect":
      return state.filter((item) => item !== action.payload);
    case "toggle":
      if (state.includes(action.payload)) {
        return state.filter((item) => item !== action.payload);
      } else {
        return [...state, action.payload];
      }
    case "clear":
      return [];
    default:
      return state;
  }
};

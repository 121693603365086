import {
  AddOutlined,
  AttachFileOutlined,
  ComputerOutlined,
} from "@mui/icons-material";
import { Avatar, Box, Button, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import "../../../main.css";
import DropzoneBase from "../base";
import ReceiptItem from "./components/ReceiptItem";
import SingleReceiptView from "./components/SingleReceiptView";

function DropzoneReceipt({
  files = [],
  enableCropFiles = [],
  disableCrop,
  sx,
  containerStyle,
  onDrop = () => {},
  onClickRemove = () => {},
  onClickCrop = () => {},
  renderItem,
  renderContentStyle,
}) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/webp": [],
      "application/pdf": [],
    },
    onDrop: onDrop,
  });

  const enabledCrop = useCallback(
    (id, type) => {
      if (disableCrop) return false;
      return Boolean(
        enableCropFiles &&
          type &&
          !type.includes("application") &&
          enableCropFiles.find((item) => item.id === id)
      );
    },
    [enableCropFiles, files]
  );

  return (
    <Box
      overflow={"initial"}
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        pb: 4,
        ...containerStyle,
      }}
    >
      {files && files.length === 1 ? (
        <SingleReceiptView
          onClickRemove={(event) => onClickRemove(event, files[0].id)}
          file={files[0]}
          enableCrop={enabledCrop(files[0].id, files[0].type)}
          onClickCrop={() => onClickCrop(files[0].id)}
        />
      ) : (
        <Box sx={renderContentStyle}>
          {files &&
            files.map((file, idx) => {
              const id = file.id;
              if (renderItem) {
                return renderItem(id, file);
              }
              return (
                <ReceiptItem
                  key={id + idx?.toString()}
                  file={file}
                  onClickRemove={(event) => onClickRemove(event, id)}
                  enableCrop={enabledCrop(id, file.type)}
                  onClickCrop={() => onClickCrop(id)}
                />
              );
            })}
        </Box>
      )}
      <DropzoneBase
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "sticky",
          bottom: 0,
          alignItems: "center",
          borderRadius: 3,
          flex: 1,
          borderColor: "divider",
        }}
        getInputProps={getInputProps}
        getRootProps={getRootProps}
        isDragAccept={isDragAccept}
        isDragActive={isDragActive}
        isDragReject={isDragReject}
      >
        {Boolean(!files.length) && (
          <Box
            pt={4}
            display={"flex"}
            flexDirection="column"
            alignItems={"center"}
          >
            <input {...getInputProps()} />
            <Avatar
              sx={{
                width: 110,
                height: 110,
                bgcolor: (t) => `${t.palette.primary.main}20`,
                color: "primary.main",
              }}
            >
              <AttachFileOutlined
                sx={{ fontSize: "4rem", transform: "rotate(45deg)" }}
              />
            </Avatar>
            <Typography color={"text.secondary"} mt={2} fontSize="1rem">
              {isDragAccept
                ? "Solte para adicionar"
                : "Arraste os arquivos aqui"}
            </Typography>
            <Typography fontSize={".7rem"} color={"text.secondary"} my={1}>
              Ou
            </Typography>
          </Box>
        )}
        <Button
          sx={{
            mb: 1,
            position: "sticky",
            bottom: 0,
            mt: files.length && 3,
            boxShadow: 2,
            color: "primary.main",
            bgcolor: "background.default",
            ":hover": { boxShadow: 10, bgcolor: "background.default" },
          }}
          disableElevation
          color="inherit"
          variant="contained"
        >
          {!files.length ? (
            <ComputerOutlined sx={{ mr: 1 }} />
          ) : (
            <AddOutlined sx={{ mr: 1 }} />
          )}
          Fazer upload do computador
        </Button>
      </DropzoneBase>
    </Box>
  );
}

export default DropzoneReceipt;

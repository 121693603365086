import {
  AssignmentLateOutlined,
  AssignmentReturnOutlined,
  AssignmentTurnedInOutlined,
  ContentPasteOutlined,
  CurrencyExchangeOutlined,
  PendingActionsOutlined,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import React, { memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SidebarItem from "../../../components/sidebar/components/SidebarItem";
import "../../../main.css";
import { setRequestsVistedStatus } from "../../../store/features/requestsSlice";

const filters = [
  {
    icon: null,
    status: "all",
    label: "Todos os relatórios",
    color: "primary",
  },
  {
    icon: ContentPasteOutlined,
    status: "O",
    label: "Em aberto",
    color: "action",
  },
  {
    icon: AssignmentReturnOutlined,
    status: "AD",
    label: "Adiantamento solicitado",
    color: "action",
  },
  {
    icon: PendingActionsOutlined,
    status: "P",
    label: "Em aprovação",
    color: "warning",
  },
  {
    icon: AssignmentLateOutlined,
    status: "R",
    label: "Rejeitados",
    color: "error",
  },
  {
    icon: AssignmentTurnedInOutlined,
    status: "A",
    label: "Aprovados",
    color: "success",
  },
  {
    icon: CurrencyExchangeOutlined,
    status: "F",
    label: "Compensados",
    color: "primary",
  },
];

function StatusNavigation({ selectedStatus }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSelectItem = useCallback((status) => {
    navigate(".", { replace: true });
    dispatch(setRequestsVistedStatus(status));
  }, []);

  return (
    <Box
      px={1}
      position={"fixed"}
      left={"5em"}
      zIndex={(t) => t.zIndex.appBar + 10}
      height={"calc(100% - 60px)"}
      top={"63px"}
      overflow={"auto"}
      width={"17em"}
      bgcolor={(t) => `${t.palette.elevation1.main}`}
      display={{ xs: "none", md: "flex" }}
      flexDirection={"column"}
      gap={0.6}
      boxShadow={0}
      py={3}
      pt={2}
      pl={"2.4em"}
      sx={{ borderTopRightRadius: 20, borderBottomRightRadius: 20 }}
    >
      {filters.map((filter) => (
        <SidebarItem
          key={filter.status}
          Icon={filter.icon}
          selectedProp={selectedStatus === filter.status}
          title={filter.label}
          onClick={() => handleSelectItem(filter.status)}
        />
      ))}
    </Box>
  );
}

export default memo(StatusNavigation);

import {
  AccountTreeOutlined,
  AttachMoneyOutlined,
  CheckOutlined,
  CloseOutlined,
  CurrencyExchange,
  FileOpenOutlined,
  InfoOutlined,
  NoteAddOutlined,
  SendOutlined,
} from "@mui/icons-material";
import {
  TimelineConnector,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import {
  Box,
  Collapse,
  IconButton,
  List,
  Paper,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import UserListItem from "../../../../../components/list-items/UserListItem";
import { selectAccountCurrency } from "../../../../../store/features/accountSlice";
import formatAmount from "../../../../../utils/formatAmount";
import EventTitle from "./components/EventTitle";

export const BoxContent = ({ sx, children, onClick }) => {
  return (
    <Box
      onClick={onClick}
      borderRadius={2}
      sx={sx}
      mt={1}
      p={1.5}
      border={1}
      borderColor={(t) =>
        t.palette.mode === "light" ? "rgba(0,0,0,.2)" : "rgba(255,255,255,.2)"
      }
      position={"relative"}
      variant={"elevation"}
      elevation={0}
      boxShadow={0}
      bgcolor={"background.default"}
      component={Paper}
    >
      {children}
    </Box>
  );
};

const Content = memo(
  ({
    timestamp,
    step,
    approvers,
    comment,
    title = "",
    rejectedBy,
    advanceAmount,
    advanceReason,
  }) => {
    const [expanded, setExpanded] = useState(false);

    const currency = useSelector((state) => selectAccountCurrency(state, true));

    return (
      <BoxContent
        sx={{
          boxShadow: expanded ? 3 : 0,
          ":hover": {
            boxShadow: 3,
          },
          transition: "none",
          cursor: "pointer",
        }}
        onClick={() => setExpanded(!expanded)}
      >
        <EventTitle
          timestamp={timestamp}
          color={rejectedBy ? "error.main" : "success.main"}
        >
          {title} na <strong>etapa {step}</strong> {rejectedBy && ", por:"}
        </EventTitle>
        <IconButton
          size="large"
          sx={{
            position: "absolute",
            bgcolor: expanded && "action.focus",
            ":hover": {
              bgcolor: expanded && "action.focus",
            },
            right: 10,
            top: 10,
          }}
        >
          <InfoOutlined />
        </IconButton>
        <Collapse unmountOnExit in={expanded}>
          <Typography color={"text.secondary"} mt={1} fontSize={".8rem"}>
            Aprovadores
          </Typography>
          {rejectedBy ? (
            <List disablePadding>
              <UserListItem
                id={rejectedBy._id}
                name={rejectedBy.name}
                lastname={rejectedBy.lastname}
                email={rejectedBy.email}
              />
            </List>
          ) : (
            <List disablePadding>
              {approvers
                .filter((ap) => ap.status === "A")
                .map((ap) => (
                  <UserListItem
                    key={ap._id}
                    id={ap._id}
                    name={ap.name}
                    lastname={ap.lastname}
                    email={ap.email}
                  />
                ))}
            </List>
          )}
          {Boolean(advanceAmount) && (
            <>
              <Typography
                mt={1}
                fontSize={".8rem"}
                fontWeight={"500"}
                color={"text.secondary"}
              >
                Valor desejado
              </Typography>
              <Typography
                mt={0.5}
                variant="body2"
                color={comment ? "text.primary" : "text.secondary"}
              >
                {currency} {formatAmount(advanceAmount || 0)}
              </Typography>
            </>
          )}
          {Boolean(advanceReason) && (
            <>
              <Typography
                mt={1}
                fontSize={".8rem"}
                fontWeight={"500"}
                color={"text.secondary"}
              >
                Motivo do adiantamento
              </Typography>
              <Typography
                mt={0.5}
                variant="body2"
                color={advanceReason ? "text.primary" : "text.secondary"}
              >
                {advanceReason}
              </Typography>
            </>
          )}
          {comment && (
            <>
              <Typography
                mt={1}
                fontSize={".8rem"}
                fontWeight={"500"}
                color={"text.secondary"}
              >
                Motivo da rejeição
              </Typography>
              <Typography
                mt={0.5}
                variant="body2"
                color={comment ? "text.primary" : "text.secondary"}
              >
                {comment || "Nenhuma motivo informado"}
              </Typography>
            </>
          )}
        </Collapse>
      </BoxContent>
    );
  }
);

function EventItem({ event, type, thirdPerson }) {
  let dot = <></>;
  let content = <></>;
  let color = "grey";

  const currency = useSelector((state) => selectAccountCurrency(state, true));

  switch (type) {
    case "C":
      dot = <NoteAddOutlined color="action" fontSize="small" />;
      color = "grey";
      content = (
        <BoxContent>
          <EventTitle timestamp={event.timestamp} color={"text.primary"}>
            Relatório criado
          </EventTitle>
        </BoxContent>
      );
      break;
    case "S":
      color = "primary";
      dot = <SendOutlined color="primary" fontSize="small" />;
      content = (
        <BoxContent>
          <EventTitle timestamp={event.timestamp} color={"primary.main"}>
            Enviado para aprovação
          </EventTitle>
        </BoxContent>
      );
      break;
    case "AD-S":
      color = "warning";
      dot = <AttachMoneyOutlined color="warning" fontSize="small" />;
      content = (
        <BoxContent>
          <EventTitle timestamp={event.timestamp} color={"warning.main"}>
            Adiantamento solicitado - {currency}{" "}
            {formatAmount(event?.amount || 0)}
          </EventTitle>
          <Typography
            mt={0.5}
            variant="body2"
            fontWeight={"500"}
            color={"text.primary"}
          >
            <Typography
              fontWeight={"500"}
              color={"text.secondary"}
              component={"span"}
              variant="inherit"
            >
              Valor:
            </Typography>{" "}
            {currency} {formatAmount(event?.amount || 0)}
          </Typography>
          <Typography
            mt={0.5}
            variant="body2"
            fontWeight={"500"}
            color={event?.reason ? "text.primary" : "text.secondary"}
          >
            <Typography
              fontWeight={"500"}
              color={"text.secondary"}
              component={"span"}
              variant="inherit"
            >
              Motivo:
            </Typography>{" "}
            {event?.reason || "Nenhum motivo"}
          </Typography>
        </BoxContent>
      );
      break;
    case "AD-A":
      color = "success";
      dot = <AttachMoneyOutlined color="success" fontSize="small" />;
      content = (
        <Content
          title="Adiantamento aprovado"
          timestamp={event.timestamp}
          step={event.step}
          approvers={event?.approvers || []}
        />
      );
      break;
    case "AD-R":
      const rejectedAdvanceApprover = event.approvers.find(
        (ap) => ap.status === "R"
      );
      color = "error";
      dot = <AttachMoneyOutlined color="error" fontSize="small" />;
      content = (
        <Content
          title="Adiantamento rejeitado"
          timestamp={event.timestamp}
          step={event.step}
          rejectedBy={rejectedAdvanceApprover}
          comment={rejectedAdvanceApprover.comment}
          advanceAmount={event.advance_amount || 0}
          advanceReason={event.advance_reason || ""}
        />
      );
      break;
    case "AD-PAID":
      color = "primary";
      dot = <AttachMoneyOutlined color="primary" fontSize="small" />;
      content = (
        <BoxContent>
          <EventTitle timestamp={event.timestamp} color={"primary.main"}>
            Adiantamento pago
          </EventTitle>
        </BoxContent>
      );
      break;
    case "A":
      color = "success";
      dot = <CheckOutlined color="success" fontSize="small" />;
      content = (
        <Content
          title="Aprovado"
          timestamp={event.timestamp}
          step={event.step}
          approvers={event?.approvers || []}
        />
      );
      break;
    case "R":
      const rejectedApprover = event.approvers.find((ap) => ap.status === "R");
      color = "error";
      dot = <CloseOutlined color="error" fontSize="small" />;
      content = (
        <Content
          title="Rejeitado"
          timestamp={event.timestamp}
          step={event.step}
          rejectedBy={rejectedApprover}
          comment={rejectedApprover.comment}
        />
      );
      break;
    case "WF001":
      color = "grey";
      dot = <AccountTreeOutlined color="action" fontSize="small" />;
      content = (
        <BoxContent>
          <EventTitle timestamp={event.timestamp} color={"text.primary"}>
            As {thirdPerson ? "" : "suas"} configurações de aprovação foram
            alteradas
          </EventTitle>
          <Typography fontSize={".8rem"} color={"text.secondary"}>
            Pode ser que {thirdPerson ? "o colaborador" : "você"} tenha sido
            adicionado a outro fluxo de aprovação, ou o fluxo que{" "}
            {thirdPerson ? "ele(a)" : "você"} já estava foi alterado ou
            removido.
          </Typography>
        </BoxContent>
      );
      break;
    case "PAID":
      color = "primary";
      dot = <CurrencyExchange color="primary" fontSize="small" />;
      content = (
        <BoxContent>
          <EventTitle timestamp={event.timestamp} color={"primary.main"}>
            Compensado
          </EventTitle>
          <Typography fontSize={".8rem"} color={"text.secondary"}>
            Este relatório foi marcado como compensado pela administração
          </Typography>
        </BoxContent>
      );
      break;
    case "RE-OPEN":
      color = "grey";
      dot = <FileOpenOutlined color="action" fontSize="small" />;
      content = (
        <BoxContent>
          <EventTitle timestamp={event.timestamp} color={"text.primary"}>
            Reaberto
          </EventTitle>
          <Typography fontSize={".8rem"} color={"text.secondary"}>
            {thirdPerson
              ? "Este relatório foi reaberto"
              : "Você reabriu este relatório"}
          </Typography>
        </BoxContent>
      );
      break;

    default:
      break;
  }

  return (
    <TimelineItem>
      <TimelineOppositeContent>{content}</TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector sx={{ bgcolor: "action.focus" }} />
        <TimelineDot variant="outlined" color={color}>
          {dot}
        </TimelineDot>
      </TimelineSeparator>
    </TimelineItem>
  );
}

export default memo(EventItem);

import {
  Box,
  Typography
} from "@mui/material";
import React, { memo, useCallback } from "react";
import formatAmount from "../../../../../utils/formatAmount";
import DateInput from "../../../../expense-creation/components/inputs/DateInput";
import DistanceInput from "../../../../expense-creation/components/inputs/DistanceInput";
import ObsInput from "../../../../expense-creation/components/inputs/ObsInput";
import PreRegisteredSnippet from "../../../../expense-creation/components/inputs/PreRegisteredSnippet";
import ProjectInput from "../../../../expense-creation/components/inputs/ProjectInput";
import RoutePoliciesInput from "../../../../expense-creation/components/inputs/RoutePoliciesInput";

function EditMode({
  rate,
  expense,
  currency,
  amount,
  onChangeValue,
  canEditDistance,
  routePolicyIsDetached,
  predefinedValues = {},
}) {
  return (
    <>
      <PreRegisteredSnippet
        value={expense.snippet_id}
        onChange={useCallback(
          (v) => onChangeValue("snippet_id", v),
          [expense.snippet_id]
        )}
      />
      <DistanceInput
        disabled={!canEditDistance}
        value={expense.distance}
        onChange={useCallback(
          (v) => onChangeValue("distance", v),
          [expense.distance]
        )}
      />
      <RoutePoliciesInput
        value={expense?.route_policy_id}
        onChange={useCallback(
          (v) => onChangeValue("route_policy_id", v),
          [expense.route_policy_id]
        )}
        refDate={new Date(expense?.date)}
        predefinedName={predefinedValues?.route_policy_name || ""}
      />
      <Box pl={6}>
        <Typography
          mb={1}
          mt={-2}
          fontSize={".85rem"}
          fontWeight={"500"}
          color={"text.secondary"}
        >
          Taxa paga por km:{" "}
          <Typography
            variant="inherit"
            color={"primary.main"}
            fontWeight={"600"}
            component={"span"}
            fontSize={".9rem"}
          >
            {currency} {formatAmount(rate)}
          </Typography>
        </Typography>
        <Typography variant="h5" fontWeight={amount ? "600" : "400"}>
          <Typography
            variant="inherit"
            fontSize={"1.2rem"}
            component={"span"}
            fontWeight={"500"}
          >
            Total =
          </Typography>{" "}
          {currency} {formatAmount(amount)}
        </Typography>
        <Typography mt={1} mb={2} variant="body2" color={"text.secondary"}>
          {expense.refundable ? "Reeembolsável" : "Não reeembolsável"}
        </Typography>
      </Box>
      <DateInput
        value={new Date(expense.date)}
        onChange={useCallback((v) => onChangeValue("date", v), [expense.date])}
        disabledByRoutePolicy={routePolicyIsDetached}
      />
      <ProjectInput
        value={expense.project_id}
        onChange={useCallback(
          (v) => onChangeValue("project_id", v),
          [expense.project_id]
        )}
        predefinedValue={predefinedValues?.project_name}
      />
      <ObsInput
        value={expense.notes}
        onChange={useCallback(
          (v) => onChangeValue("notes", v),
          [expense.notes]
        )}
      />
    </>
  );
}

export default memo(EditMode);

import { NotesOutlined } from "@mui/icons-material";
import { TextField } from "@mui/material";
import React, { memo } from "react";
import SelectorBox from "../SelectorBox";

function ObsInput({ value = "", size, readOnly, onChange, error, variant }) {
  return (
    <SelectorBox
      iconSx={{ mt: 1 }}
      sx={{ alignItems: "flex-start" }}
      Icon={NotesOutlined}
    >
      <TextField
        label={"Descrição"}
        fullWidth
        readOnly={readOnly}
        variant="outlined"
        name="notes"
        size={size}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        multiline
        maxRows={7}
        error={Boolean(error)}
        helperText={error || ""}
      />
    </SelectorBox>
  );
}

export default memo(ObsInput);

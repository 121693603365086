import { EditOutlined } from "@mui/icons-material";
import { Box, Button, Chip, Typography } from "@mui/material";
import React, { memo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import RenderChipStatus from "../../../../../../components/chip/RenderChipStatus";
import CircleDivisor from "../../../../../../components/division/CircleDivisor";
import EditModal from "../../../components/EditModal";
import AmountSection from "./AmountSection";
import CustomFieldsSection from "./CustomFieldsSection";
import PaperSection from "./PaperSection";

const EditButton = memo(({ title, obs, initialCustomFields }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<EditOutlined />}
        onClick={() => setOpen(true)}
      >
        Editar
      </Button>
      <EditModal
        onClose={() => setOpen(false)}
        open={open}
        initialTitle={title}
        initialObs={obs}
        initialCustomFields={initialCustomFields || {}}
      />
    </>
  );
});

const GeneralSection = ({
  totalAmount = 0,
  totalAdvance = 0,
  totalRefundable = 0,
  totalExpenses = 0,
  obs,
  title,
  status,
  customFields,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <PaperSection
      sx={{ boxShadow: 3 }}
      rightContent={
        status === "O" && (
          <EditButton
            title={title}
            obs={obs}
            initialCustomFields={customFields}
          />
        )
      }
      title={
        <Typography
          my={1}
          mb={0.5}
          ml={0.3}
          component={"span"}
          variant="inherit"
          fontWeight={"600"}
          fontSize={"1.5rem"}
        >
          {title}
        </Typography>
      }
    >
      <Typography
        ml={0.3}
        color={obs ? "text.primary" : "text.secondary"}
        fontSize={".95rem"}
      >
        {obs || "Sem descrição"}
      </Typography>

      {customFields && <CustomFieldsSection customFields={customFields} />}

      <Box ml={-0.5} mt={2} gap={1} display={"flex"} alignItems={"center"}>
        <RenderChipStatus status={status} fz=".9rem" />
        <CircleDivisor />
        <Chip
          size="small"
          variant="outlined"
          sx={{ fontWeight: "600", fontSize: ".9rem" }}
          onClick={() => {
            searchParams.set("tab", "1");
            setSearchParams(searchParams);
          }}
          label={<>{totalExpenses} despesa(s)</>}
        />
      </Box>
      <AmountSection
        totalAdvance={totalAdvance}
        totalAmount={totalAmount}
        totalRefundable={totalRefundable}
        status={status}
      />
    </PaperSection>
  );
};

export default memo(GeneralSection);

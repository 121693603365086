import { Button, useTheme } from "@mui/material";
import React, { memo } from "react";
import NoExpensesInRequestSVG from "../../../../../assets/bgs/NoExpensesInRequestSVG";
import EmptyContent from "../../../../../components/form/EmptyContent";
import AddExpenseButton from "./header-components/AddExpenseButton";

const NoExpenses = ({ status }) => {
  const {
    palette: { mode },
  } = useTheme();

  return (
    <EmptyContent
      sx={{ mt: 4 }}
      artComponent={<NoExpensesInRequestSVG />}
      title={"Relatório sem despesas"}
      subtitle={
        "Adicione pelo menos uma despesa neste relatório para enviá-lo para aprovação"
      }
      actionComponent={
        <AddExpenseButton
          renderComponent={({ setMenu }) =>
            status === "O" && (
              <Button
                onClick={(e) => setMenu(e.target)}
                sx={{ borderRadius: 100 }}
                variant="outlined"
              >
                Adicionar
              </Button>
            )
          }
        />
      }
    />
  );
};

export default memo(NoExpenses);

import { Box, Typography } from "@mui/material";
import React from "react";
import NoPermissionImage from "../../assets/bgs/no-permission.png";
import NotepayLogo from "../NotepayLogo";

const NoAccessContent = ({
  title = "Você não tem permissão para acessar este recurso",
  children,
}) => {
  return (
    <Box
      height={"70vh"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={"column"}
      px={3}
    >
      <NotepayLogo style={{ position: "fixed", top: 30, left: 30 }} />
      <img src={NoPermissionImage} width={"200px"} />
      <Typography
        maxWidth={500}
        mt={3}
        gutterBottom
        fontWeight={"500"}
        textAlign={"center"}
        variant="h5"
      >
        {title}
      </Typography>
      <Typography
        maxWidth={600}
        color={"text.secondary"}
        textAlign={"center"}
        variant="body1"
      >
        Entre em contado com a sua empresa para resolver este problema
      </Typography>
      <Box mt={3}/>
      {children}
    </Box>
  );
};

export default NoAccessContent;

import { CheckCircleOutline } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { memo } from "react";
import HelpIconButton from "../../../../../../components/buttons/HelpIconButton";
import UserListItem from "../../../../../../components/list-items/UserListItem";
import { getProfilePicture } from "../../../../../../utils/functions/profile";
import { formatMoment } from "../../../../../../utils/more/date_functions";
import CircleStatusIndicator from "../../../components/CircleStatusIndicator";
import PaperSection from "./PaperSection";
import "./styles.css";

const StepApproverItem = memo(({ approver }) => {
  const { name, lastname, email, status, is_read, _id } = approver;

  return (
    <ListItem disableGutters>
      <ListItemAvatar>
        <Avatar
          sx={{ border: 1, borderColor: "divider" }}
          src={getProfilePicture(_id)}
        ></Avatar>
      </ListItemAvatar>
      <ListItemText
        primaryTypographyProps={{ fontWeight: "500" }}
        primary={`${name} ${lastname || ""}`}
        secondary={email}
      />
      {status === "A" ? (
        <Chip
          icon={<CheckCircleOutline />}
          variant="outlined"
          color="success"
          size="small"
          label="Aprovou"
          sx={{ fontWeight: "600" }}
        />
      ) : (
        status === "P" && (
          <div className="typing">
            <span></span>
            <span></span>
            <span></span>
          </div>
        )
      )}
    </ListItem>
  );
});

const ApproversList = memo(
  ({ approvers = [], title = "Aprovadores da etapa" }) => {
    return (
      <>
        <Box mt={2} component={"div"} display={"flex"} alignItems={"center"}>
          <Typography
            fontSize={".8rem"}
            fontWeight={"500"}
            color={"text.secondary"}
          >
            {title}
          </Typography>
          <HelpIconButton size="small">
            Aqui estão os aprovadores responsáveis pela etapa atual. Pode ser
            necessário obter uma ou mais aprovações
          </HelpIconButton>
        </Box>
        <List dense>
          {approvers.map((approver) => (
            <StepApproverItem key={approver._id} approver={approver} />
          ))}
        </List>
      </>
    );
  }
);

function StatusSection({
  loading,
  status = "",
  stepInfo,
  rejectedEvent = {},
  rejectedNum = 0,
  approvedEvent = {},
}) {
  let title = "";
  let subtitle = "";
  let content = <></>;

  switch (status) {
    case "O":
      title = "O relatório ainda está em aberto";
      subtitle = "Você pode adicionar, remover ou editar as despesas";
      break;
    case "R":
      const rejectedByUser = rejectedEvent?.approvers?.find(
        (ap) => ap.status === "R"
      ) || {
        name: "",
        lastname: "",
        _id: "",
      };
      title = (
        <Typography variant="inherit">
          Rejeitado por {rejectedByUser?.name} {rejectedByUser?.lastname || ""}{" "}
          na <strong>etapa {rejectedEvent?.step}</strong>
        </Typography>
      );
      subtitle = `Rejeitado em ${formatMoment(rejectedEvent?.timestamp)}`;
      content = (
        <>
          <Typography
            mt={1.5}
            fontSize={".82rem"}
            fontWeight={"500"}
            color={"text.secondary"}
          >
            Por
          </Typography>
          <UserListItem
            id={rejectedByUser?._id}
            name={rejectedByUser?.name}
            lastname={rejectedByUser?.lastname}
            email={rejectedByUser?.email}
          />
          <Typography
            mt={1}
            fontSize={".82rem"}
            fontWeight={"500"}
            color={"text.secondary"}
          >
            Motivo da rejeição
          </Typography>
          <Typography
            mt={0.5}
            variant="body2"
            color={rejectedByUser?.comment ? "text.primary" : "text.secondary"}
          >
            {rejectedByUser?.comment || "Nenhum motivo informado"}
          </Typography>
          <Typography
            mt={2}
            variant="body2"
            fontSize={".9rem"}
            fontWeight={"500"}
          >
            <Typography
              mr={0.5}
              component={"span"}
              color={"error.main"}
              variant="inherit"
              fontWeight={"600"}
            >
              {rejectedNum}
            </Typography>{" "}
            despesa(s) rejeitada(s)
          </Typography>
        </>
      );
      break;
    case "P":
      title = (
        <Typography variant="inherit">
          Aguardando aprovação na{" "}
          <strong>etapa {stepInfo?.current_step}</strong>
        </Typography>
      );
      subtitle = `Enviado em ${formatMoment(stepInfo?.timestamp)}`;
      content = <ApproversList approvers={stepInfo?.approvers || []} />;
      break;
    case "AD":
      title = (
        <Typography variant="inherit">
          Aguardando aprovação do adiantamento na{" "}
          <strong>etapa {stepInfo?.current_step}</strong>
        </Typography>
      );
      subtitle = `Enviado em ${formatMoment(stepInfo?.timestamp)}.`;
      content = <ApproversList approvers={stepInfo?.approvers || []} />;
      break;
    case "A":
      title = (
        <Typography variant="inherit">
          Aprovado, aguardando compensação
        </Typography>
      );
      subtitle = `Aprovado em ${formatMoment(approvedEvent?.timestamp)}.`;
      content = null;
      break;
    case "F":
      title = <Typography variant="inherit">Compensado</Typography>;
      subtitle = `O relatório foi marcado como compensado e não poderá mais ser reaberto`;
      content = <></>;
      break;

    default:
      break;
  }

  return (
    <PaperSection title="Status">
      <Typography
        mt={0.5}
        fontSize={"1rem"}
        variant="body1"
        component={"div"}
        display={"flex"}
        fontWeight={"600"}
        alignItems={"center"}
        gutterBottom
      >
        <CircleStatusIndicator width={9} height={9} status={status} mr={1} />
        {title}
      </Typography>
      {loading && ["AD", "P", "A", "R"].includes(status) ? (
        <>
          <Skeleton height={20} />
          <Skeleton width={200} height={20} />
        </>
      ) : (
        <>
          <Typography
            color={"text.secondary"}
            variant="body2"
            fontSize={".9rem"}
          >
            {subtitle}
          </Typography>
          {content}
        </>
      )}
    </PaperSection>
  );
}

export default memo(StatusSection);

import { APIActions } from "../queues/offline-queue/effect";
import { updateExpense } from "../store/features/expensesSlice";
import { store } from "../store/store";

export const startScanExpenses = async (expensesParam) => {
  const { ids, entities } = store.getState().expenses;
  const expensesToScan =
    expensesParam ||
    ids.filter(
      (expenseId) => entities[expenseId]?.processingStatus === "scanning"
    );

  if (!expensesToScan?.length) return;

  const scanPromises = expensesToScan.map((expense) => {
    const { id: expenseId, receipts } = expensesParam
      ? expense
      : entities[expense];

    if (!receipts?.length || receipts?.length !== 1) return null;

    const { id: receiptId, filename, blob } = receipts[0];

    return APIActions.receipts
      .scan({
        expenseId,
        created_at: entities[expenseId]?.created_at,
        receipt: { filename, id: receiptId, blob },
      })
      .catch((error) => {
        if (error?.response) {
          store.dispatch(
            updateExpense({
              id: expenseId,
              changes: { processingStatus: null },
            })
          );
        }
      });
  });
  Promise.all(scanPromises);
};

import { Box, TableCell, Typography } from "@mui/material";
import React, { forwardRef, memo, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  selectExpensesNumByRequest,
  selectExpensesTotalAmountByRequest,
} from "../../../store/features/expensesSlice";
import { selectRequestById } from "../../../store/features/requestsSlice";
import { requestItemData } from "../../../utils/initialStates";
import { formatDate } from "../../../utils/more/date_functions";
import Base from "./Base";
import AmountSection from "./components/AmountSection";
import RequestAvatar from "./components/RequestAvatar";
import StatusSection from "./components/StatusSection";
import TitleSection from "./components/TitleSection";

const RequestLineItem = forwardRef(
  ({ onClick, requestId, disableRowActions }, ref) => {
    const request = useSelector((state) => selectRequestById(state, requestId));

    const {
      title,
      obs,
      total_amount,
      total_expenses,
      status,
      id,
      created_at,
      advance,
      processingStatus,
      hidden,
    } = request || requestItemData;

    const handleClick = useCallback(() => onClick(id), [id]);

    const sync = useMemo(() => processingStatus === "sync", [processingStatus]);
    const advancing = useMemo(
      () => processingStatus === "advancing",
      [processingStatus]
    );

    const totalAmount = useSelector(
      (state) =>
        parseFloat(total_amount || 0) ||
        selectExpensesTotalAmountByRequest(state, id) ||
        0
    );

    const totalExpenses = useSelector(
      (state) => total_expenses || selectExpensesNumByRequest(state, id) || 0
    );

    return (
      <Base
        disableRowActions={disableRowActions}
        innerRef={ref}
        requestId={id}
        onClick={handleClick}
        settings={{
          edit: status === "O" && !processingStatus,
          delete:
            ["O", "R"].includes(status) &&
            !processingStatus &&
            !["paid", "approved"].includes(advance?.status),
          send: status === "O" && Boolean(totalExpenses) && !processingStatus,
          reopen: status === "R" && !processingStatus,
          export: totalExpenses > 0,
          hidden,
        }}
      >
        <TableCell>
          <Box display={"flex"} alignItems={"center"}>
            <RequestAvatar status={status} />
            <TitleSection title={title} obs={obs} />
          </Box>
        </TableCell>
        <TableCell>
          <StatusSection
            advancing={advancing}
            advance={advance}
            status={status}
            sync={sync}
          />
        </TableCell>
        <TableCell>
          <Typography
            fontWeight={"500"}
            noWrap
            fontSize={".85rem"}
            color={"text.secondary"}
          >
            {formatDate(created_at)}
          </Typography>
        </TableCell>
        <TableCell>
          <AmountSection
            requestId={requestId}
            totalAmount={totalAmount}
            totalExpenses={totalExpenses}
          />
        </TableCell>
      </Base>
    );
  }
);

export default memo(RequestLineItem);

import { NotificationsOutlined } from "@mui/icons-material";
import { Badge, IconButton, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

export const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 1,
    top: 2,
    border: `2px solid ${theme.palette.background.default}`,
    padding: "0 4px",
    fontWeight: "bold",
    fontSize: ".7rem",
  },
}));

function NotificationButton({ opened, unreadNum, onClick }) {
  return (
    <Tooltip title={"Notificações"}>
      <IconButton
        onClick={onClick}
        sx={{
          bgcolor: opened && "action.focus",
          mr: { xs: -2, md: 0 },
        }}
      >
        <StyledBadge badgeContent={unreadNum || 0} color="error">
          <NotificationsOutlined />
        </StyledBadge>
      </IconButton>
    </Tooltip>
  );
}

export default NotificationButton;

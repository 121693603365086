import {
  ExpandLess,
  ExpandMore,
  FullscreenOutlined,
  HistoryOutlined,
} from "@mui/icons-material";
import { Box, Collapse, Container, IconButton, Tooltip } from "@mui/material";
import React, { memo, useCallback, useMemo, useState } from "react";
import AdvanceRequestModal from "../../components/modals/AdvanceRequestModal";
import Timeline from "../timeline/Timeline";
import HistoryDrawer from "./HistoryDrawer";
import AdvanceSection from "./sections/AdvanceSection";
import GeneralSection from "./sections/GeneralSection";
import LimitsSection from "./sections/LimitsSection";
import PaperSection from "./sections/PaperSection";
import StatusSection from "./sections/StatusSection";
import TotalsResume from "./sections/TotalsResume";

const HistorySection = memo(({ events = [], requestTitle = "" }) => {
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(true);
  return (
    <>
      <PaperSection
        title={`Histórico do relatório (${events?.length})`}
        Icon={HistoryOutlined}
        rightContent={
          <>
            <Tooltip title="Expandir histórico">
              <IconButton sx={{ my: -1, mr: 1 }} onClick={() => setOpen(true)}>
                <FullscreenOutlined sx={{ fontSize: "32px" }} />
              </IconButton>
            </Tooltip>
            <IconButton sx={{ my: -1 }} onClick={() => setExpanded(!expanded)}>
              {!expanded ? (
                <ExpandMore sx={{ fontSize: "32px" }} />
              ) : (
                <ExpandLess sx={{ fontSize: "32px" }} />
              )}
            </IconButton>
          </>
        }
      >
        <Collapse unmountOnExit in={expanded}>
          <Timeline events={events} />
        </Collapse>
      </PaperSection>
      <HistoryDrawer
        open={open}
        onClose={() => setOpen(false)}
        events={events}
        title={requestTitle}
      />
    </>
  );
});

const Overview = ({
  requestId,
  loading,
  status,
  events = [],
  totalAmount = 0,
  totalRefundable = 0,
  totalExpenses = 0,
  stepInfo = {},
  advance,
  rejectedNum = 0,
  resume,
  obs,
  title,
  customFields,
  limits,
  alertLimitsComponent,
}) => {
  const advanceRejectedEvent = useMemo(() => {
    if (events && !loading) {
      const revertedEvents = [...(events || [])].reverse();
      const ev = (revertedEvents || []).find((ev) => ev.type === "AD-R");
      return ev;
    }
  }, [events, status, loading]);

  const rejectedEvent = useMemo(() => {
    if (status === "R" && events && !loading) {
      const revertedEvents = [...(events || [])].reverse();
      const ev = (revertedEvents || []).find((ev) => ev.type === "R");
      return ev;
    }
  }, [events, status, loading]);

  const approvedEvent = useMemo(() => {
    if (status === "A" && events && !loading) {
      const revertedEvents = [...(events || [])].reverse();
      const ev = (revertedEvents || []).find((ev) => ev.type === "A");
      return ev;
    }
  }, [events, status, loading]);

  const totalAdvance = useMemo(() => {
    return advance?.status === "approved" || advance?.status === "paid"
      ? advance?.amount
      : 0;
  }, [advance]);

  const [advanceModal, setAdvanceModal] = useState(false);

  const openAdvanceModal = useCallback(() => {
    setAdvanceModal(true);
  }, []);
  const closeAdvanceModal = useCallback(() => {
    setAdvanceModal(false);
  }, []);

  return (
    <Container
      maxWidth="lg"
      height={"100%"}
      sx={{ display: "flex", flexWrap: "wrap", gap: 3, py: 4, pb: 10 }}
    >
      <Box flex={1} sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        <GeneralSection
          totalAmount={totalAmount}
          totalRefundable={totalRefundable}
          totalAdvance={totalAdvance}
          totalExpenses={totalExpenses}
          title={title}
          obs={obs}
          customFields={customFields}
          status={status}
        />

        <AdvanceSection
          requestStatus={status}
          advance={advance}
          rejectedEvent={advanceRejectedEvent}
          openAdvanceModal={openAdvanceModal}
        />
        <TotalsResume
          resume={resume}
          totalAmount={totalAmount}
          totalRefundable={totalRefundable}
        />
        {limits && limits?.expensesTypes && status === "O" && (
          <LimitsSection limits={limits} />
        )}
      </Box>
      <Box
        height={"100%"}
        flex={1}
        display={"flex"}
        flexDirection={"column"}
        gap={3}
      >
        {["O", "P"].includes(status) &&
          limits?.expensesTypes &&
          alertLimitsComponent}
        <StatusSection
          status={status}
          stepInfo={stepInfo}
          loading={loading}
          approvedEvent={approvedEvent}
          rejectedEvent={rejectedEvent}
          rejectedNum={rejectedNum}
        />
        <HistorySection events={events} requestTitle={title} />
      </Box>
      {status === "O" && (
        <AdvanceRequestModal
          open={advanceModal}
          onClose={closeAdvanceModal}
          requestId={requestId}
          initialAdvance={advance}
        />
      )}
    </Container>
  );
};

export default Overview;

import { DeleteOutlineOutlined } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { memo, useImperativeHandle, useState } from "react";
import { useDispatch } from "react-redux";
import ModalDelete from "../../../../../../modals/ModalDelete";
import { openSnackbar } from "../../../../../../store/features/base/snackbarBaseSlice";
import { expensesOfflineActions } from "../../../../../../store/features/expensesSlice";

const DeleteExpenses = React.forwardRef(
  ({ selected = [], clearSelection }, ref) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const handleDelete = async () => {
      dispatch(expensesOfflineActions.removeExpenses(selected));
      dispatch(
        openSnackbar({ message: `${selected.length} despesa(s) excluída(s)` })
      );
      clearSelection();
    };

    useImperativeHandle(ref, () => {
      return {
        openDelete: () => setOpen(true),
      };
    });

    return (
      <>
        <Tooltip title="Excluir">
          <IconButton
            sx={{ bgcolor: open && "action.focus" }}
            onClick={() => setOpen(true)}
          >
            <DeleteOutlineOutlined />
          </IconButton>
        </Tooltip>
        <ModalDelete
          open={open}
          title={`Deseja excluir ${selected.length} despesa(s)?`}
          subtitle={"Esta ação não poderá ser desfeita"}
          onClose={() => setOpen(false)}
          onDelete={handleDelete}
        />
      </>
    );
  }
);

export default memo(DeleteExpenses);

import { Menu, MenuItem } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import AddToRequest from "./card/components/more-actions/AddToRequest";
import CopyModel from "./card/components/more-actions/CopyModel";
import DeleteExpense from "./card/components/more-actions/DeleteExpense";
import RemoveFromRequest from "./card/components/more-actions/RemoveFromRequest";

const CustomMenuItem = ({
  Icon,
  label,
  disabled,
  iconColor = "action",
  onClick,
}) => {
  return (
    <MenuItem
      onClick={onClick}
      disabled={disabled}
      sx={{ fontSize: ".95rem", height: 45 }}
    >
      {Icon && <Icon sx={{ mr: 2 }} color={iconColor} />} {label}
    </MenuItem>
  );
};

const ContextMenuWrapper = ({
  expenseId,
  requestId,
  renderComponent = () => {},
  settings = {
    removeFromRequest: false,
    addToRequest: false,
    deleteExpense: false,
  },
}) => {
  const [menu, setMenu] = useState(null);

  useEffect(() => {
    setMenu(null);
  }, [settings]);

  return (
    <>
      {renderComponent({ menu, opened: Boolean(menu), setMenu })}
      <Menu
        onClick={(e) => e.stopPropagation()}
        onClose={() => setMenu(null)}
        anchorEl={menu}
        open={Boolean(menu) && !settings.hidden}
        anchorOrigin={{ vertical: "center", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        elevation={3}
        slotProps={{
          paper: {
            sx: {
              boxShadow: 4,
              minWidth: 200,
              bgcolor: "elevation1.main",
            },
          },
        }}
      >
        {settings.deleteExpense && <DeleteExpense expenseId={expenseId} />}
        {settings.removeFromRequest && requestId && (
          <RemoveFromRequest expenseId={expenseId} requestId={requestId} />
        )}
        {settings.addToRequest && <AddToRequest expenseId={expenseId} />}
        <CopyModel requestId={requestId} expenseId={expenseId} />
      </Menu>
    </>
  );
};

export default memo(ContextMenuWrapper);

import { AddOutlined } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React, { memo, useState } from "react";
import UploadModal from "./UploadModal";

function UploadButton({ expenseId, onAdd }) {
  const [open, setOpen] = useState(false);

  return (
    <Box
      width={"100%"}
      sx={{
        p: 1,
        position: "absolute",
        bottom: 20,
        zIndex: 1000,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Button
        onClick={() => setOpen(true)}
        sx={{
          bgcolor: "background.default",
          transition: "none",
          boxShadow: 4,
          ":hover": {
            boxShadow: 10,
            bgcolor: "background.default",
          },
        }}
        disableElevation
        color="primary"
        variant="text"
      >
        <AddOutlined sx={{ mr: 1 }} />
        Adicionar comprovantes
      </Button>
      <UploadModal
        open={open}
        onClose={() => setOpen(false)}
        expenseId={expenseId}
        onAdd={onAdd}
      />
    </Box>
  );
}

export default memo(UploadButton);

import { Close, WarningOutlined } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  List,
  Popover,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import { RuleItem } from "../../../../layers/ExpensePolicyLayer";

function WarningsButton({ warnings = [] }) {
  const [menu, setMenu] = useState(null);
  return (
    <>
      <IconButton
        color="warning"
        onClick={(e) => {
          e.stopPropagation();
          setMenu(e.target);
        }}
        sx={{
          bgcolor: Boolean(menu) && "action.focus",
        }}
      >
        <WarningOutlined fontSize="small" color="warning" />
      </IconButton>
      <Popover
        onClick={(e) => e.stopPropagation()}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => {
          setMenu(null);
        }}
        data-disableselect={true}
        open={Boolean(menu)}
        anchorEl={menu}
        slotProps={{
          paper: {
            sx: { width: 400, px: 2, pt: 2, pb: 0 },
          },
        }}
      >
        <Box mb={1} display={"flex"} alignItems={"center"}>
          <WarningOutlined fontSize="small" color="warning" sx={{ mr: 2.5 }} />
          <Typography fontWeight={"600"}>Despesa com alertas</Typography>
          <Box flex={1} />
          <IconButton sx={{ m: -1 }} onClick={() => setMenu(null)}>
            <Close />
          </IconButton>
        </Box>
        <Divider sx={{ ml: 5 }} />
        <List sx={{ pb: 2 }}>
          {warnings.map((warning, idx) => (
            <RuleItem
              key={warning?.message + idx.toString()}
              message={warning?.message || "Mensagem de alerta não definida"}
              type={warning?.type}
            />
          ))}
        </List>
      </Popover>
    </>
  );
}

export default memo(WarningsButton);

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import React, {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import ExpenseLineItem from "../../../components/expenses/line/ExpenseLineItem";
import { selectExpensesObjectArrayByIds } from "../../../store/features/expensesSlice";
import ExpensesTableHeader from "./ExpensesTableHeader";
import ExpensesTableResumeTotal from "./ExpensesTableResumeTotal";

const ExpensesTable = forwardRef(
  (
    {
      expenseSettings = {},
      renderCustomAction,
      enableOtherSection,
      sectionExpensesIds = [],
      expensesIds = [],
      isSelected,
      isRejected,
      onExpenseSelect,
      onExpenseClick,
      disableActions,
      allExpenses,
      sectionTitle = "",
      moreSectionTitle = "",
      enableSelectScrollIntoView,
    },
    ref
  ) => {
    const expensesObjectArray = useSelector((state) =>
      selectExpensesObjectArrayByIds(state, expensesIds)
    );

    const [order, setOrder] = React.useState("");
    const [orderBy, setOrderBy] = React.useState("");

    const expenses = useMemo(
      () => allExpenses || expensesObjectArray,
      [allExpenses, expensesObjectArray]
    );

    useImperativeHandle(
      ref,
      () => {
        return {
          getOrdenedExpenses: () => {
            return stableSort(expenses, getComparator(order, orderBy));
          },
        };
      },
      [expenses, order, orderBy]
    );

    const renderExpenseLineItem = useCallback(
      (expense) => {
        const isExpenseObj = Boolean(typeof expense !== "string");
        const expenseId = isExpenseObj ? expense?.id : expense;
        const expenseObj = isExpenseObj ? expense : null;

        return (
          <ExpenseLineItem
            key={expenseId}
            expenseObj={expenseObj}
            expenseId={expenseId}
            selected={isSelected ? isSelected(expenseId) : false}
            rejected={isRejected ? isRejected(expenseId) : false}
            onChangeSelected={onExpenseSelect}
            onClick={onExpenseClick}
            settings={expenseSettings}
            disableActions={disableActions}
            customActionComponent={renderCustomAction}
            enableSelectScrollIntoView={enableSelectScrollIntoView}
          />
        );
      },
      [
        isSelected,
        isRejected,
        renderCustomAction,
        expenseSettings,
        disableActions,
        orderBy,
        order,
        enableSelectScrollIntoView,
      ]
    );

    function descendingComparator(a, b, orderBy) {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }

    function getComparator(order, orderBy) {
      return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
          return order;
        }
        return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
    }

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };

    const totalAmount = useMemo(
      () =>
        expenses?.reduce((acc, item) => parseFloat(item?.amount || 0) + acc, 0),
      [expenses]
    );
    const totalRefundable = useMemo(
      () =>
        expenses
          ?.filter((exp) => exp.refundable)
          ?.reduce((acc, item) => parseFloat(item?.amount || 0) + acc, 0),
      [expenses]
    );

    return (
      <TableContainer onClick={(e) => e.stopPropagation()} sx={{ pb: 4 }}>
        <Table size="small">
          <ExpensesTableHeader
            expenseSettings={expenseSettings}
            renderCustomAction={renderCustomAction}
            onRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
          />
          <TableBody
            sx={{
              [`& .${tableCellClasses.root}`]: {
                border: "none",
                py: 1.05,
                fontWeight: "500",
              },
            }}
          >
            {enableOtherSection && Boolean(sectionExpensesIds.length) && (
              <>
                <TableRow>
                  <TableCell align="left" colSpan={2}>
                    <Typography fontSize={"1rem"} fontWeight={"500"}>
                      {sectionTitle}
                    </Typography>
                  </TableCell>
                </TableRow>
                {sectionExpensesIds.map(renderExpenseLineItem)}
              </>
            )}
            {enableOtherSection && moreSectionTitle && (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography fontSize={"1rem"} fontWeight={"500"}>
                    {moreSectionTitle}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {stableSort(expenses, getComparator(order, orderBy)).map(
              renderExpenseLineItem
            )}
            <ExpensesTableResumeTotal
              totalAmount={totalAmount}
              totalRefundable={totalRefundable}
              dividerColSpan={
                expenseSettings.select || Boolean(renderCustomAction) ? 9 : 8
              }
              cellColSpan={
                expenseSettings.select || Boolean(renderCustomAction) ? 7 : 6
              }
            />
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
);

export default memo(ExpensesTable);

import { grey } from "@mui/material/colors";
import React from "react";
import CircleDivisor from "../../../../components/division/CircleDivisor";

const CircleStatusIndicator = ({ status, ...rest }) => {
  return (
    <CircleDivisor
      height={7}
      width={7}
      bgcolor={
        status === "O"
          ? grey[500]
          : status === "P" || status === "AD"
          ? "warning.main"
          : status === "A"
          ? "success.main"
          : status === "R"
          ? "error.main"
          : "primary.main"
      }
      {...rest}
    />
  );
};

export default CircleStatusIndicator;

import { Close } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React, { memo } from "react";

function MenuHeader({ title, scrolled, onClose }) {
  return (
    <Box
      boxShadow={scrolled && "0px 1px 5px 0px rgb(0 0 0 / 20%)"}
      p={2}
      display={"flex"}
      alignItems={"center"}
    >
      <Typography fontSize={"1.2rem"} fontWeight={"500"}>
        {title}
      </Typography>
      <Box flex={1} />
      <IconButton sx={{ m: -1, p: 1 }} onClick={onClose}>
        <Close />
      </IconButton>
    </Box>
  );
}

export default memo(MenuHeader);

import {
  boxesIntersect,
  useSelectionContainer,
} from "@air/react-drag-to-select";
import { memo, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { selectExpensesEntities } from "../../../../store/features/expensesSlice";

function ExpensesDragSelection({ expensesIds, onChangeSelection }) {
  const selectableItemsRef = useRef([]);

  const entities = useSelector(selectExpensesEntities);

  const getSelectableItems = useCallback(() => {
    return expensesIds.map((itemId) => {
      const el = document.getElementById(`e-${itemId}`);
      return el ? el : null;
    });
  }, [expensesIds, entities]);

  const getSelectableBoxes = useCallback(() => {
    return selectableItemsRef.current.map((item) => {
      const { left, top, width, height } = item.getBoundingClientRect();
      return { left, top, width, height };
    });
  }, []);

  const checkIntersection = useCallback((box, boxItem) => {
    return boxesIntersect(box, boxItem);
  }, []);

  const handleSelectionChange = useCallback(
    (box) => {
      const selectableItems = getSelectableItems();
      selectableItemsRef.current = selectableItems.filter(Boolean);
      const selectableBoxes = getSelectableBoxes();

      selectableBoxes.forEach((boxItem, index) => {
        const itemId = expensesIds[index];
        const payload = { type: "deselect", payload: itemId };
        if (!entities[itemId] || entities[itemId]?.scanning) {
          return null;
        }
        if (checkIntersection(box, boxItem)) {
          payload.type = "select";
        }
        onChangeSelection(payload);
      });
    },
    [expensesIds, getSelectableItems, getSelectableBoxes, checkIntersection]
  );

  const shouldStartSelecting = useCallback((target) => {
    if (target instanceof HTMLElement) {
      let el = target;
      while (el.parentElement && !el.dataset.disableselect) {
        el = el.parentElement;
      }
      return el.dataset.disableselect !== "true";
    }
    return false;
  }, []);

  const { DragSelection } = useSelectionContainer({
    onSelectionChange: handleSelectionChange,
    shouldStartSelecting,
    selectionProps: {
      style: {
        backgroundColor: "#047BC415",
        borderColor: "#047BC4",
        zIndex: 20000,
      },
    },
    isEnabled: true,
  });

  return <DragSelection />;
}

export default memo(ExpensesDragSelection);

import { InfoOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Component } from "react";

export default class DialogAlert extends Component {
  static componentInstance;

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      title: "",
      message: "",
      isError: false,
    };

    DialogAlert.componentInstance = this;

    // Bind the methods to the class instance
    this._show = this._show.bind(this);
    this._close = this._close.bind(this);
  }

  static show({ title, message, isError }) {
    DialogAlert.componentInstance._show({ title, message, isError });
  }

  _show({ title, message, isError }) {
    this.setState({
      visible: true,
      title,
      message,
      isError,
    });
  }
  _close() {
    this.setState({ visible: false, title: "", message: "", isError: false });
  }

  render() {
    const { message, title, visible, isError } = this.state;
    return (
      visible && (
        <Dialog
          sx={{
            zIndex: (t) => t.zIndex.snackbar + 100,
          }}
          data-disableselect={true}
          PaperProps={{
            sx: {
              maxWidth: 450,
            },
          }}
          TransitionProps={{
            onExited: () => {
              this.setState({ title: "", message: "", isError: false });
            },
          }}
          onClose={this._close}
          open={visible}
          fullWidth
        >
          <DialogTitle fontWeight={"500"} fontSize={"1.2rem"}>
            {Boolean(isError) && (
              <InfoOutlined sx={{ mr: 1.5 }} color={"error"} />
            )}
            <span>{title}</span>
          </DialogTitle>
          <DialogContent>
            {message && (
              <Typography
                component={"div"}
                fontWeight={"500"}
                fontSize={".936rem"}
                color={"text.secondary"}
                sx={{ wordBreak: "break-word" }}
                dangerouslySetInnerHTML={{
                  __html: message || "",
                }}
              />
            )}
          </DialogContent>
          <DialogActions sx={{ py: 2 }}>
            <Button onClick={this._close}>Ok</Button>
          </DialogActions>
        </Dialog>
      )
    );
  }
}

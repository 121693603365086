import { addDays } from "date-fns";
import { setReceiptsAccessToken } from "../store/features/accountSlice";
import { store } from "../store/store";
import { refreshToken } from "./auth";
import { HttpClient } from "./httpClient";
import { RECEIPTS_URL } from "./urls";

const updateReceiptsAccessToken = async (accessToken) => {
  if (!accessToken) return;
  try {
    const {
      data: { token: receiptsAccessToken },
    } = await HttpClient.get(
      `${RECEIPTS_URL}/token?access_token=${accessToken}`
    );
    store.dispatch(
      setReceiptsAccessToken({
        token: receiptsAccessToken,
        expiresAt: addDays(new Date(), 20).toISOString(),
      })
    );
  } catch (error) {
    const status = error?.response?.status;
    if (status === 401) {
      const { isAuth, accessToken: newAccessToken } = await refreshToken();
      if (isAuth) {
        updateReceiptsAccessToken(newAccessToken);
      }
    }
  }
};
export default updateReceiptsAccessToken;

import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { APIActions } from "../../../queues/offline-queue/effect";
import normalize from "../../../utils/normalize";

const baseName = "snippets";

const adapter = createEntityAdapter();

const initialState = adapter.getInitialState({
  working: false,
});

export const syncSnippets = createAsyncThunk(
  `${baseName}/syncSnippets`,
  async () => {
    const res = await APIActions.snippets.sync();
    return res;
  }
);

export const snippetsSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    setupSnippets(state, { payload }) {
      const { entities } = normalize(payload);
      adapter.setAll(state, entities);
    },
    upsertSnippet(state, { payload }) {
      adapter.upsertOne(state, payload);
    },
    removeSnippet(state, { payload }) {
      adapter.removeOne(state, payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(syncSnippets.pending, (state, action) => {
        state.working = true;
      })
      .addCase(syncSnippets.rejected, (state, action) => {
        state.working = false;
      })
      .addCase(syncSnippets.fulfilled, (state, action) => {
        state.working = false;
        const data = action.payload;
        snippetsSlice.caseReducers.setupSnippets(state, {
          payload: data,
        });
      });
  },
});

export const { setupSnippets, upsertSnippet, removeSnippet } =
  snippetsSlice.actions;

export const {
  selectAll: selectAllSnippets,
  selectById: selectSnippetById,
  selectIds,
  selectEntities: selectSnippetsEntities,
} = adapter.getSelectors((state) => state[baseName]);

export default snippetsSlice.reducer;

export const selectSnippetsIds = createSelector(
  [selectAllSnippets, (state, showInactive) => showInactive],
  (data, showInactive) => {
    return data
      .filter((item) => (showInactive ? true : Boolean(item.active)))
      .map((item) => item.id);
  }
);

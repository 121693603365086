import { AssignmentOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { memo, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HttpClient } from "../../api/httpClient";
import { selectAccountCurrency } from "../../store/features/accountSlice";
import {
  removeOneApprovalRequest,
  selectApprovalRequestById,
} from "../../store/features/approvalRequestsSlice";
import { setError } from "../../store/features/base/errorBaseSlice";
import { openSnackbar } from "../../store/features/base/snackbarBaseSlice";
import {
  closeElement,
  selectPortalIsOpened,
  selectPortalUtil,
} from "../../store/features/portalSlice";
import formatAmount from "../../utils/formatAmount";

const ApprovalModal = (props) => {
  const [loading, setLoading] = useState(false);

  const currency = useSelector((state) => selectAccountCurrency(state, true));

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const open = useSelector((state) =>
    selectPortalIsOpened(state, "approvalModal")
  );
  const requestId = useSelector((state) =>
    selectPortalUtil(state, "approvalModal", "requestId")
  );
  const onClose = useCallback(
    () => dispatch(closeElement("approvalModal")),
    [dispatch]
  );

  const request = useSelector((state) =>
    selectApprovalRequestById(state, requestId)
  );

  const isAdvance = useMemo(() => {
    return Boolean(request?.advance?.status === "pending");
  }, [request?.advance]);

  const handleApprove = async () => {
    setLoading(true);
    try {
      await HttpClient.post(`/approvers/request/${requestId}/approve`);
      dispatch(
        openSnackbar({
          message: `${isAdvance ? "Adiantamento" : "Relatório"} aprovado`,
        })
      );
      dispatch(removeOneApprovalRequest(requestId));
      navigate(`/approver?tab=${isAdvance ? "1" : "0"}`);
      onClose();
    } catch (error) {
      dispatch(setError({ title: "Erro ao aprovar relatório", error }));
    } finally {
      setLoading(false);
    }
  };

  return (
    open && (
      <Dialog open={open} fullWidth maxWidth="xs">
        {loading && <LinearProgress color="success" />}
        <DialogTitle fontSize={"1.1rem"} fontWeight={"400"}>
          <Box>
            <Typography mb={0.5} variant="body1" color={"text.secondary"}>
              Aprovar {isAdvance ? "adiantamento" : "relatório"}
            </Typography>
            <Typography fontWeight={"600"} fontSize={"1.3rem"}>
              {isAdvance
                ? `${currency} ${formatAmount(request?.advance?.amount)}`
                : request?.title}
            </Typography>
            {isAdvance && (
              <Typography
                mt={0.5}
                fontWeight={"500"}
                component={"div"}
                display={"flex"}
                alignItems={"center"}
              >
                <AssignmentOutlined
                  color="action"
                  sx={{ fontSize: "1rem", mr: 1 }}
                />
                {request?.title}
              </Typography>
            )}
          </Box>
        </DialogTitle>
        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
          <Button
            disabled={loading}
            variant="contained"
            disableElevation
            color="success"
            onClick={handleApprove}
          >
            {loading ? "Aprovando..." : "Aprovar"}
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

export default memo(ApprovalModal);

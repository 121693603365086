import { Box, List, ListSubheader, Typography } from "@mui/material";
import React from "react";

function SectionComponent({ title, onClick, children, sx, action, divider }) {
  return (
    <List
      onClick={onClick}
      data-disableselect={true}
      disablePadding
      sx={{
        width: "100%",
        bgcolor: "transparent",
        borderBottom: divider && 1,
        borderColor: "divider",
        pr: 1.5,
        pb: 1,
        mb: 1,
      }}
      component="nav"
      subheader={
        <ListSubheader
          disableSticky
          sx={{
            ...sx,
            bgcolor: "transparent",
            display: "flex",
            alignItems: "center",
          }}
          component="div"
        >
          <Typography
            fontSize={".9rem"}
            fontWeight="500"
            color={"text.secondary"}
            variant="inherit"
            noWrap
            mt={-1}
          >
            {title}
          </Typography>
          <Box flex={1} />
          {action}
        </ListSubheader>
      }
    >
      {children}
    </List>
  );
}

export default SectionComponent;

import { Circle } from "@mui/icons-material";
import { Box, LinearProgress, Typography } from "@mui/material";
import React, { memo } from "react";

const ScanningLayer = (props) => {
  return (
    <Box display={"flex"} alignItems={"center"}>
      <Box flex={1}>
        <Box display={"flex"} alignItems={"center"}>
          <Typography fontSize={".9rem"} fontWeight={"500"}>
            Analisando comprovante
          </Typography>
          <Circle sx={{ fontSize: ".3rem", mx: 1 }} color="disabled" />
          <Typography fontSize={".85rem"} color={"text.secondary"}>
            Preparando sua despesa...
          </Typography>
          <Circle sx={{ fontSize: ".3rem", mx: 1 }} color="disabled" />
          <LinearProgress sx={{ borderRadius: 100, height: 2.5, width: 70 }} />
        </Box>
      </Box>
    </Box>
  );
};

export default memo(ScanningLayer);

import {
  saveAccountData,
  setAccessExpiresAt,
  setAccountError,
  setReceiptsAccessToken,
} from "../store/features/accountSlice";
import { store } from "../store/store";
import { AuthAPIGateway, HttpClient } from "./httpClient";
import updateReceiptsAccessToken from "./updateReceiptsAccessToken";
import { AUTH_BASE_URL } from "./urls";
import WebSockets from "./websockets";

const checkReceiptsAccessToken = async (accessToken) => {
  //Dados de acesso para comprovantes
  const receiptsAccess = store.getState().account?.receiptsAccessToken || null;
  if (!receiptsAccess?.token || !receiptsAccess?.expiresAt) {
    await updateReceiptsAccessToken(accessToken);
    return;
  }
  const now = new Date().getTime();
  const expiresAt = new Date(receiptsAccess?.expiresAt).getTime();
  if (now > expiresAt) {
    await updateReceiptsAccessToken(accessToken);
  }
};

const handleSetupTokensAccess = async (accessToken, expiresAt) => {
  HttpClient.setAccessToken(accessToken);
  WebSockets.setAccessToken(accessToken);
  store.dispatch(setAccessExpiresAt(expiresAt));
  await checkReceiptsAccessToken(accessToken);
};

const updateUserInfo = (data = {}) => {
  if (!data) return;
  if (data.email) {
    window.localStorage.setItem("email", data.email);
  }

  const prevIsApprover = store.getState().account?.isApprover;

  if (prevIsApprover !== Boolean(data?.roles?.approver)) {
    store.dispatch(setReceiptsAccessToken({ token: null, expiresAt: null }));
  }

  store.dispatch(
    saveAccountData({
      userId: data?._id,
      accountId: data?.account?._id,
      currency: data?.account?.currency,
      companyName: data?.account?.name,
      org: data?.account?.org,
      accountStatus: data?.account?.status,
      name: data?.name,
      lastname: data?.lastname,
      email: data?.email,
      occupation: data?.occupation?.name,
      org: data?.org?.name,
      accountStatus: data?.account?.status,
      accountHasLogo: data?.account?.has_logo,
      isApprover: Boolean(data?.roles?.approver),
      isAdmin: Boolean(data?.roles?.admin),
      isMaster: Boolean(data?.roles?.master),
      hasPhoto: Boolean(data?.has_profile_photo),
      photoVersion: data?.profile_photo_version,
    })
  );
};

const handleDebugAuthentication = async () => {
  try {
    const d = await fetch(`${AUTH_BASE_URL}/auth/v1/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: process.env.REACT_APP_USER,
        password: process.env.REACT_APP_PASSWORD,
        debug: true,
      }),
    });

    const data = await d.json();
    const accessToken = data.accessToken;
    await handleSetupTokensAccess(accessToken, data?.expiresAt);
    updateUserInfo(data.user);

    // Autenticar usuário p/ suporte do Zendesk
    if (window.zE) {
      window.zE("messenger", "loginUser", function (callback) {
        callback(data.zendeskToken);
      });
    }

    return {
      isAuth: true,
      userId: data.user._id,
      accessToken: accessToken,
      persistLoading: false,
      expiresAt: new Date(data.expiresAt),
    };
  } catch (error) {
    window.alert("Ocorreu uma falha na autenticação");
    updateUserInfo(error.response?.data?.user);
    return {
      isAuth: false,
      accessToken: null,
      expiresAt: null,
      persistLoading: false,
      userId: error.response?.data?.user?._id,
    };
  }
};

export const refreshToken = async () => {
  console.log("refreshToken!");
  // MODO DEBUG
  if (process.env.REACT_APP_DEBUG === "true") {
    return handleDebugAuthentication();
  }
  try {
    const { data } = await AuthAPIGateway.refreshToken();
    await handleSetupTokensAccess(data.accessToken, data?.expiresAt);
    updateUserInfo(data.user);
    // Autenticar usuário p/ suporte do Zendesk
    if (window.zE) {
      window.zE("messenger", "loginUser", function (callback) {
        callback(data.zendeskToken);
      });
    }
    return {
      isAuth: true,
      userId: data.user._id,
      accessToken: data.accessToken,
      persistLoading: false,
      expiresAt: new Date(data.expiresAt),
    };
  } catch (error) {
    return handleAuthenticationError(error);
  }
};

const handleAuthenticationError = (error) => {
  if (error.response?.status === 401) {
    const email = window.localStorage.getItem("email");
    const redirectUrl = window.location;
    const urlParams = `${
      email ? `email=${email}&` : ""
    }redirect=${redirectUrl}`;
    const authUrl = `${AUTH_BASE_URL}?step=${email ? 1 : 0}&${urlParams}`;
    window.location.replace(authUrl);
  } else {
    updateUserInfo(error.response?.data?.user);
    if (error.response?.status === 403) {
      store.dispatch(setAccountError(error.response?.data));
    }
  }
  return {
    isAuth: false,
    accessToken: null,
    expiresAt: null,
    persistLoading: error.response?.status === 401,
    userId: error.response?.data?.user?._id,
  };
};

export const getAccount = async () => {
  const { data } = await HttpClient.get("/users/me");
  return data;
};

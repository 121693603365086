import { Box } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import ErrorBoundary from "../../errors/Boundary";
import {
  fetchApprovalRequests,
  selectApprovalAdvancesIds,
  selectApprovalRequestsIds,
} from "../../store/features/approvalRequestsSlice";
import { setBreadcrumbs } from "../../store/features/base/breadcrumbsSlice";
import TabNavigator from "./components/TabNavigator";
import Advances from "./contents/advances/Advances";
import Requests from "./contents/requests/Requests";

export default (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const advancesIds = useSelector(selectApprovalAdvancesIds);
  const requestsIds = useSelector(selectApprovalRequestsIds);

  const tab = useMemo(
    () => parseInt(searchParams.get("tab") || "0"),
    [searchParams]
  );

  useEffect(() => {
    dispatch(fetchApprovalRequests());
    dispatch(setBreadcrumbs(["Para aprovar"]));
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflowY = "scroll";
      document.body.style.overflowX = "hidden";
    };
  }, []);

  const handleChangeTab = useCallback(
    (v) => {
      searchParams.set("tab", v);
      setSearchParams(searchParams);
    },
    [searchParams]
  );

  return (
    <ErrorBoundary>
      <TabNavigator
        advancesTotal={advancesIds?.length}
        requestsTotal={requestsIds?.length}
        tab={tab}
        onChange={handleChangeTab}
      />
      <Box flex={1} display={"flex"} flexDirection={"column"} px={2} pb={4}>
        {tab === 1 && <Advances advancesIds={advancesIds} />}
        {tab === 0 && <Requests requestsIds={requestsIds} />}
      </Box>
    </ErrorBoundary>
  );
};

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { APIActions } from "../../../../../queues/offline-queue/effect";
import { setError } from "../../../../../store/features/base/errorBaseSlice";
import { openSnackbar } from "../../../../../store/features/base/snackbarBaseSlice";
import { reopen } from "../../../../../store/features/requestsSlice";

function ReopenRequest({ requestId, renderComponent }) {
  const dispatch = useDispatch();
  const [confirmModal, setConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleReopen = async () => {
    setLoading(true);
    try {
      const data = await APIActions.requests.reopen(requestId);
      dispatch(reopen({ requestId, responseData: data }));
      dispatch(
        openSnackbar({
          message: "Relatório reaberto",
        })
      );
    } catch (error) {
      dispatch(
        setError({
          title: "Erro ao reabrir relatório",
          error,
        })
      );
    } finally {
      setConfirmModal(false);
      setLoading(false);
    }
  };

  return (
    <Box>
      {renderComponent({
        onConfirm: () => setConfirmModal(true),
        disabled: false,
      })}
      <Dialog
        open={confirmModal}
        onClose={() => !loading && setConfirmModal(false)}
        fullWidth
        maxWidth="xs"
      >
        {loading && <LinearProgress />}
        <DialogTitle fontSize={"1.1rem"}>
          Deseja reabrir este relatório?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ao reabrir você poderá reorganizar suas despesas
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={() => setConfirmModal(false)}>
            Cancelar
          </Button>
          <Button
            disabled={loading}
            variant="contained"
            disableElevation
            onClick={handleReopen}
          >
            {loading ? "Reabrindo" : "Reabrir"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ReopenRequest;

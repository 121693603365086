import React, { memo, useCallback } from "react";
import AmounInput from "../../../../expense-creation/components/inputs/AmounInput";
import DateInput from "../../../../expense-creation/components/inputs/DateInput";
import ExpTypeInput from "../../../../expense-creation/components/inputs/ExpTypeInput";
import ObsInput from "../../../../expense-creation/components/inputs/ObsInput";
import PaymentTypeInput from "../../../../expense-creation/components/inputs/PaymentTypeInput";
import ProjectInput from "../../../../expense-creation/components/inputs/ProjectInput";

function EditMode({
  expense,
  onChangeValue,
  conversion,
  converted,
  refundableReadOnly,
  predefinedValues = {},
  expTypeInputRef,
}) {
  return (
    <>
      <AmounInput
        refundableReadOnly={refundableReadOnly}
        value={expense.amount}
        refundable={expense.refundable}
        currency={expense.currency}
        onChange={useCallback(
          (v) => onChangeValue("amount", v),
          [expense.amount]
        )}
        onChangeRefundable={useCallback(
          () => onChangeValue("refundable", !expense.refundable),
          [expense.refundable]
        )}
        onChangeCurrency={useCallback(
          (v) => onChangeValue("currency", v),
          [expense.currency]
        )}
      />
      <ExpTypeInput
        size="medium"
        value={expense.type_id}
        onChange={useCallback(
          (v) => onChangeValue("type_id", v),
          [expense.type_id]
        )}
        inputRef={expTypeInputRef}
      />
      <DateInput
        size="medium"
        value={new Date(expense.date)}
        onChange={useCallback((v) => onChangeValue("date", v), [expense.date])}
      />
      <PaymentTypeInput
        size="medium"
        value={expense.payment_type}
        onChange={useCallback(
          (v) => onChangeValue("payment_type", v),
          [expense.payment_type]
        )}
      />
      <ProjectInput
        size="medium"
        value={expense.project_id}
        onChange={useCallback(
          (v) => onChangeValue("project_id", v),
          [expense.project_id]
        )}
        predefinedValue={predefinedValues?.project_name}
      />
      <ObsInput
        value={expense.notes}
        onChange={useCallback(
          (v) => onChangeValue("notes", v),
          [expense.notes]
        )}
      />
    </>
  );
}

export default memo(EditMode);

import { Circle } from "@mui/icons-material";
import {
  Box,
  Collapse,
  FormControlLabel,
  List,
  ListItem,
  Switch,
  Typography,
} from "@mui/material";
import React, { memo, useCallback } from "react";
import { useSelector } from "react-redux";
import HelpIconButton from "../../../components/buttons/HelpIconButton";
import Input from "../../../components/inputs/text-input";
import { selectAccountCurrency } from "../../../store/features/accountSlice";
import { CurrencyInputMask } from "../../../utils/more/mask_functions";

const ToggleActive = memo(({ active, onChange }) => {
  return (
    <FormControlLabel
      sx={{ alignItems: "flex-start" }}
      control={<Switch checked={active} onClick={onChange} />}
      label={
        <Box ml={1} display={"flex"} alignItems={"flex-start"}>
          <Box flex={1}>
            <Typography
              mb={0.4}
              variant="body1"
              fontSize={"1.1rem"}
              fontWeight={"500"}
            >
              Solicitar adiantamento
            </Typography>
            <Typography
              variant="body2"
              fontSize={".9rem"}
              color={"text.secondary"}
            >
              O relatório não poderá ser editado até que sua solicitação seja
              aprovada ou rejeitada
            </Typography>
          </Box>
          <HelpIconButton sx={{ mr: -2 }} title={"Adiantamento"}>
            O adiantamento é uma forma de receber dinheiro da empresa antes de
            realizar as despesas.
            <List>
              <ListItem disableGutters>
                <Circle color="disabled" sx={{ fontSize: ".3rem", mr: 2 }} />
                <Typography variant="inherit" component={"div"}>
                  Se o total reembolsável do relatório for maior que o
                  adiantamento, o resultado será um{" "}
                  <strong>reembolso parcial</strong>
                </Typography>
              </ListItem>
              <ListItem disableGutters>
                <Circle color="disabled" sx={{ fontSize: ".3rem", mr: 2 }} />
                <Typography variant="inherit" component={"div"}>
                  Se o total reembolsável do relatório for menor que o
                  adiantamento, o resultado será uma{" "}
                  <strong>devolução parcial</strong>
                </Typography>
              </ListItem>
            </List>
          </HelpIconButton>
        </Box>
      }
    />
  );
});

const CollapseForm = memo(({ reason = "", amount = "", onChange }) => {
  const accountCurrency = useSelector((state) =>
    selectAccountCurrency(state, true)
  );

  return (
    <Box mt={1} pl={{ xs: 0, sm: 6 }}>
      <Input
        autoFocus
        margin={"normal"}
        inputComponent={CurrencyInputMask}
        label={"Valor desejado"}
        required
        size={'small'}
        value={amount}
        onChange={(v) => onChange("amount", v)}
        placeholder={"0,00"}
        LeftIcon={() => <Typography>{accountCurrency}</Typography>}
      />
      <Input
        value={reason}
        margin={"normal"}
        label={"Motivo"}
        placeholder={"Descreva o porque do adiantamento..."}
        multiline
        onChange={(v) => onChange("reason", v)}
      />
    </Box>
  );
});

const AdvanceForm = ({ advance = {}, onChange }) => {
  const handleChangeActive = useCallback(() => {
    onChange("active", !advance.active);
  }, [advance?.active]);

  return (
    <Box mt={3} border={1} p={2} borderRadius={3} borderColor={"divider"}>
      <ToggleActive active={advance.active} onChange={handleChangeActive} />
      <Collapse unmountOnExit in={advance.active}>
        <CollapseForm
          amount={advance.amount}
          reason={advance.reason}
          onChange={onChange}
        />
      </Collapse>
    </Box>
  );
};

export default memo(AdvanceForm);

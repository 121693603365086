import { CategoryOutlined, SearchOutlined } from "@mui/icons-material";
import {
  Checkbox,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { memo, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import AvatarType from "../../../../../../components/avatar/AvatarType";
import FilterChip from "../../../../../../components/chip/FilterChip";
import Input from "../../../../../../components/inputs/text-input";
import {
  selectAllExpenseTypes,
  selectExpenseTypesEntities,
} from "../../../../../../store/features/configs/expenseTypesSlice";
import { includesOnSearch } from "../../../../../../utils/more/search";
import MenuBase from "./MenuBase";

const ExpTypeFilterMenu = ({
  anchorEl,
  onClose,
  filter,
  onClickItem,
  onClear,
}) => {
  const expTypes = useSelector(selectAllExpenseTypes);

  const [search, setSearch] = useState("");

  useEffect(() => {
    setSearch("");
  }, [onClose]);

  return (
    <MenuBase
      onClear={onClear}
      disabledClear={!Boolean(filter.length)}
      title={"Categorias"}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <List disablePadding>
        {!expTypes.length ? (
          <Typography textAlign={"center"} m={5} color={"text.secondary"}>
            Nenhuma categoria encontrada
          </Typography>
        ) : (
          <>
            <Input
              sx={{ p: 2, pt: 0 }}
              size={"small"}
              value={search}
              onClick={(e) => e.stopPropagation()}
              onChange={setSearch}
              LeftIcon={SearchOutlined}
              variant={"standard"}
              autoFocus
              placeholder={"Buscar"}
            />
            {expTypes
              .filter((type) => includesOnSearch(search, [type?.name]))
              .map((type) => {
                const selected = filter.includes(type?._id);
                return (
                  <ListItemButton
                    key={type?._id}
                    sx={{ transition: "none" }}
                    onClick={() => onClickItem(type?._id)}
                    selected={selected}
                  >
                    <Checkbox size="small" checked={selected} />
                    <AvatarType icon={type?.icon} sx={{ mr: 1 }} />
                    <ListItemText
                      primaryTypographyProps={{
                        fontWeight: "500",
                        fontSize: ".95rem",
                        noWrap: true,
                      }}
                      primary={type?.name}
                    />
                  </ListItemButton>
                );
              })}
          </>
        )}
      </List>
    </MenuBase>
  );
};

function ExpTypeFilter({ filter = [], onChange, onClear }) {
  const filtered = useMemo(() => Boolean(filter && filter.length), [filter]);
  const [menu, setMenu] = useState(null);

  const expTypesEntities = useSelector(selectExpenseTypesEntities);

  const handleClickExpTypeItem = (typeId) => {
    let value = filter ? [...filter] : [];
    if (filter.includes(typeId)) {
      if (filter.length === 1) {
        onClear();
        return;
      }
      value.splice(
        value.findIndex((v) => v === typeId),
        1
      );
    } else {
      value.push(typeId);
    }
    onChange(value);
    setMenu(null);
  };

  return (
    <>
      <FilterChip
        onClick={(e) => setMenu(e.target)}
        filtered={filtered}
        Icon={CategoryOutlined}
        label={"Categoria"}
        opened={Boolean(menu)}
        filteredLabel={
          filter &&
          expTypesEntities[filter[filter.length - 1]]?.name +
            `${filter.length > 1 ? ` +${filter.length - 1}` : ""}`
        }
        isDropdown
      />
      <ExpTypeFilterMenu
        anchorEl={menu}
        filter={filter || []}
        onClose={() => setMenu(null)}
        onClickItem={handleClickExpTypeItem}
        onClear={() => {
          onClear();
          setMenu(null);
        }}
      />
    </>
  );
}

export default memo(ExpTypeFilter);

import {
  AttachFileOutlined,
  Close,
  CropOutlined,
  ImageOutlined,
  OpenInNewOutlined,
  PictureAsPdfOutlined,
} from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import Zoom from "react-medium-image-zoom";
import PdfIcon from "../../../../assets/icons/pdf_icon.svg";
import { getReceiptUrl } from "../../../../utils/functions/receipts";
import { formatFileType } from "../../../../utils/more/images_transform";
import "./styles.css";

function ReceiptItem({ file, onClickRemove, enableCrop, onClickCrop }) {
  const [hovered, setHovered] = useState(false);

  return (
    <Box
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      my={1}
      display={"flex"}
      alignItems="center"
    >
      <Box
        display="flex"
        justifyContent={"center"}
        sx={{ ":hover": { boxShadow: 3 } }}
        height={"auto"}
        position={"relative"}
        width={100}
        borderRadius={"10px"}
        onClick={() =>
          file.type.includes("application") &&
          window.open(file.uri || getReceiptUrl(file.url))
        }
      >
        {file.type.includes("application") ? (
          file.type === "application/pdf" ? (
            <img
              height="100%"
              width={"100%"}
              style={{ cursor: "pointer", borderRadius: "10px" }}
              src={PdfIcon}
            />
          ) : (
            <AttachFileOutlined fontSize="large" />
          )
        ) : (
          <Zoom classDialog="custom-zoom">
            <img
              src={file.uri}
              alt={file.filename}
              height="100%"
              width={"100%"}
              style={{ borderRadius: "10px" }}
            />
          </Zoom>
        )}
        {hovered && (
          <Box
            gap={1}
            py={1}
            position="absolute"
            right={-10}
            top={-5}
            display={"flex"}
            flexDirection="column"
            borderRadius={1.5}
            boxShadow={3}
            bgcolor={"background.paper"}
          >
            {enableCrop && (
              <Tooltip placement="right-start" title="Cortar imagem">
                <IconButton onClick={onClickCrop} size="small">
                  <CropOutlined />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip placement="right-start" title="Abrir em outra aba">
              <IconButton
                onClick={() => window.open(file.uri || getReceiptUrl(file.url))}
                size="small"
              >
                <OpenInNewOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      <Box ml={2}>
        <Tooltip title={file.filename}>
          <Typography
            gutterBottom
            fontSize={".92rem"}
            fontWeight={"500"}
            width={200}
            noWrap
          >
            {file.filename}
          </Typography>
        </Tooltip>
        <Typography
          fontSize={".75rem"}
          textTransform={"uppercase"}
          display={"flex"}
          alignItems="center"
        >
          {file.type === "application/pdf" ? (
            <PictureAsPdfOutlined
              fontSize="small"
              color="error"
              sx={{ mr: 0.5 }}
            />
          ) : (
            <ImageOutlined fontSize="small" color="capition" sx={{ mr: 0.5 }} />
          )}
          {formatFileType(file.type)}
        </Typography>
      </Box>
      <Box flex={1} />
      <Box display={"flex"} alignItems="center" gap={0.5}>
        <Tooltip title="Remover">
          <IconButton onClick={onClickRemove} size="small">
            <Close />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
}

export default ReceiptItem;

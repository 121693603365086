import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  LinearProgress
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { APIActions } from "../../../../../queues/offline-queue/effect";
import { setError } from "../../../../../store/features/base/errorBaseSlice";
import { openSnackbar } from "../../../../../store/features/base/snackbarBaseSlice";
import { sendToApproval } from "../../../../../store/features/requestsSlice";

function SendToApproval({ requestId, renderComponent }) {
  const dispatch = useDispatch();
  const [confirmModal, setConfirmModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleSendToApproval = async () => {
    setLoading(true);
    try {
      const data = await APIActions.requests.sendToApproval(requestId);
      dispatch(sendToApproval({ requestId, responseData: data }));
      dispatch(
        openSnackbar({
          message: "Relatório enviado",
        })
      );
    } catch (error) {
      dispatch(
        setError({
          title: "Erro ao enviar relatório",
          error,
        })
      );
    } finally {
      setConfirmModal(false);
      setLoading(false);
    }
  };

  return (
    <Box>
      {renderComponent({
        onConfirm: () => setConfirmModal(true),
        disabled: false,
      })}
      <Dialog
        open={confirmModal}
        onClose={() => !loading && setConfirmModal(false)}
        fullWidth
        maxWidth="xs"
      >
        {loading && <LinearProgress />}
        <DialogTitle fontSize={"1.4rem"}>
          Enviar o relatório para aprovação?
        </DialogTitle>
        <DialogActions>
          <Button disabled={loading} onClick={() => setConfirmModal(false)}>
            Cancelar
          </Button>
          <Button
            disabled={loading}
            variant="contained"
            disableElevation
            onClick={handleSendToApproval}
          >
            {loading ? "Enviando" : "Enviar"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default SendToApproval;

import { Box, Divider, Tooltip, Typography } from "@mui/material";
import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import HelpIconButton from "../../../../../../components/buttons/HelpIconButton";
import { selectAccountCurrency } from "../../../../../../store/features/accountSlice";
import formatAmount from "../../../../../../utils/formatAmount";

const AmountSection = ({
  totalAdvance,
  status,
  totalRefundable,
  totalAmount,
  isApprover,
}) => {
  const currency = useSelector((state) => selectAccountCurrency(state, true));

  const result = useMemo(() => {
    return totalRefundable - totalAdvance;
  }, [totalRefundable, totalAdvance]);

  return (
    <>
      <Box
        p={2}
        py={1.2}
        borderRadius={2}
        bgcolor={(t) => `${t.palette.primary.main}10`}
        my={2}
        display={"flex"}
        alignItems={"center"}
      >
        <Box flex={1}>
          <Typography
            color={"text.secondary"}
            fontWeight={"500"}
            variant="body1"
            mb={0.1}
          >
            {result < 0 ? "Devolução" : "Reembolso"}
          </Typography>
          <Typography
            noWrap
            variant="body1"
            fontSize={"1.3rem"}
            fontWeight={"600"}
          >
            {currency} {formatAmount(result)}
          </Typography>
        </Box>
        {status !== "F" && (
          <HelpIconButton
            size="medium"
            title={result < 0 ? "Devolução" : "Reembolso"}
          >
            <Typography variant="inherit" component={"div"}>
              {result < 0 ? (
                <>
                  Sua empresa deve receber o valor de{" "}
                  <strong>
                    {currency} {formatAmount(result)}
                  </strong>{" "}
                  com base nos totais do relatório
                </>
              ) : (
                <>
                  Com base nos totais do relatório,{" "}
                  {isApprover ? "o colaborador" : "você"} receberá o valor de{" "}
                  <strong>
                    {currency} {formatAmount(result)}
                  </strong>
                </>
              )}
            </Typography>
          </HelpIconButton>
        )}
      </Box>
      <Box mx={-1} px={2} display={"flex"} alignItems="center">
        <Box flex={1}>
          <Typography color={"text.secondary"} variant="body2" mb={0.3}>
            Valor total
          </Typography>
          <Tooltip title={`${currency} ${formatAmount(totalAmount)}`}>
            <Typography
              noWrap
              variant="body1"
              fontSize={"1rem"}
              fontWeight={"500"}
            >
              {currency} {formatAmount(totalAmount)}
            </Typography>
          </Tooltip>
        </Box>
        <Divider orientation="vertical" sx={{ height: 40, mx: 3 }} />
        <Box flex={1}>
          <Typography color={"text.secondary"} variant="body2" mb={0.3}>
            Valor reembolsável
          </Typography>
          <Tooltip title={`${currency} ${formatAmount(totalRefundable)}`}>
            <Typography
              noWrap
              variant="body1"
              fontSize={"1rem"}
              fontWeight={"500"}
            >
              {currency} {formatAmount(totalRefundable)}
            </Typography>
          </Tooltip>
        </Box>
        <Divider orientation="vertical" sx={{ height: 40, mx: 3 }} />
        <Box flex={1}>
          <Typography color={"text.secondary"} variant="body2" mb={0.3}>
            Valor adiantado
          </Typography>
          <Tooltip title={`${currency} ${formatAmount(totalAdvance)}`}>
            <Typography
              noWrap
              variant="body1"
              fontSize={"1rem"}
              fontWeight={"500"}
            >
              {currency} {formatAmount(totalAdvance)}
            </Typography>
          </Tooltip>
        </Box>
      </Box>
    </>
  );
};

export default memo(AmountSection);

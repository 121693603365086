import {
  Box,
  Button,
  Checkbox,
  TableCell,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import React, { memo, useMemo, useState } from "react";
import ApproveMultiple from "../../../modals/approval/ApproveMultiple";
import RejectMultiple from "../../../modals/approval/RejectMultiple";

const RejectButton = memo(({ selected, clearSelection, isAdvance }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        size="small"
        sx={{
          fontSize: ".9rem",
          bgcolor: (t) => `${t.palette.error.main}10`,
          borderColor: "transparent",
        }}
        color="error"
        variant="outlined"
      >
        Rejeitar
      </Button>
      <RejectMultiple
        isAdvance={isAdvance}
        open={open}
        ids={selected}
        onClose={() => setOpen(false)}
        onAction={clearSelection}
      />
    </>
  );
});
const ApproveButton = memo(({ selected, clearSelection, isAdvance }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        size="small"
        sx={{
          fontSize: ".9rem",
          bgcolor: (t) => `${t.palette.success.main}10`,
          borderColor: "transparent",
        }}
        color="success"
        variant="outlined"
      >
        Aprovar
      </Button>
      <ApproveMultiple
        open={open}
        isAdvance={isAdvance}
        ids={selected}
        onClose={() => setOpen(false)}
        onAction={clearSelection}
      />
    </>
  );
});

const TableHeader = ({
  count,
  selectionMode,
  selected = [],
  onSelectAll,
  clearSelection,
  columns,
  isAdvance,
}) => {
  const selectedNum = useMemo(() => selected.length, [selected]);
  return (
    <TableRow
      sx={{
        [`& .${tableCellClasses.root}`]: {
          color: "text.secondary",
          fontSize: ".9rem",
          fontWeight: "500",
          py: 0.8,
          backgroundColor: (t) =>
            t.palette.mode === "dark" ? "elevation1.main" : "#FFF",
        },
      }}
    >
      <TableCell padding="checkbox">
        <Checkbox
          indeterminate={selectedNum > 0 && selectedNum < count}
          checked={count > 0 && selectedNum === count}
          onChange={onSelectAll}
          color="primary"
        />
      </TableCell>
      {selectionMode ? (
        <TableCell colSpan={5}>
          <Box gap={1} display={"flex"} alignItems={"center"}>
            <Typography color={"text.primary"} fontSize={"1rem"}>
              {selectedNum} selecionado(s)
            </Typography>
            <Box flex={1} />
            <RejectButton
              selected={selected}
              clearSelection={clearSelection}
              isAdvance={isAdvance}
            />
            <ApproveButton
              selected={selected}
              clearSelection={clearSelection}
              isAdvance={isAdvance}
            />
          </Box>
        </TableCell>
      ) : (
        <>{columns}</>
      )}
    </TableRow>
  );
};

export default memo(TableHeader);

import { ExpandLess, ExpandMore, Warning } from "@mui/icons-material";
import { Box, Collapse, Divider, IconButton, List } from "@mui/material";
import React, { memo, useState } from "react";
import { RuleItem } from "../../../../../../layers/ExpensePolicyLayer";

const WarningsList = ({ warnings = [], requiredReceipt }) => {
  const [expanded, setExpanded] = useState(true);

  return (
    <Box mb={2} mx={-2} bgcolor={(t) => `${t.palette.warning.main}10`}>
      <Box
        fontWeight={"500"}
        p={2}
        py={1}
        display={"flex"}
        alignItems={"center"}
      >
        <Warning color="warning" sx={{ mr: 2 }} />
        Alertas da despesa{" "}
        {!expanded
          ? requiredReceipt
            ? `(${warnings?.length + 1})`
            : `(${warnings?.length})`
          : ""}{" "}
        <Box flex={1} />
        <IconButton
          onClick={() => setExpanded(!expanded)}
          sx={{ m: -1 }}
          size="small"
        >
          {!expanded ? <ExpandMore /> : <ExpandLess />}
        </IconButton>
      </Box>
      <Collapse unmountOnExit in={expanded}>
        <>
          <Divider />
          <List disablePadding sx={{ px: 2, pb: 1 }}>
            {requiredReceipt && (
              <RuleItem
                type={"required_receipt"}
                message={"Esta despesa precisa de ao menos um comprovante"}
              />
            )}
            {warnings?.map((warning, idx) => (
              <RuleItem
                key={idx.toString()}
                type={warning?.type}
                message={warning?.message}
              />
            ))}
          </List>
        </>
      </Collapse>
    </Box>
  );
};

export default memo(WarningsList);

import { Add } from "@mui/icons-material";
import { Box, Fab, lighten } from "@mui/material";
import React, { memo, useState } from "react";
import { isStaging } from "../../../theme";
import MenuCreation from "./MenuCreation";

function CreationButton(props) {
  const [menu, setMenu] = useState(null);

  return (
    <>
      <Box
        mt={0.5}
        sx={{
          mb: 2,
          justifyContent: "center",
          alignSelf: "center",
          position: {
            xs: "fixed",
            md: "relative",
          },
        }}
        bottom={{
          md: 0,
          xs: 20,
        }}
        right={{
          md: 0,
          xs: 20,
        }}
        zIndex={(t) => t.zIndex.fab}
      >
        <Fab
          tour_id="creation_button"
          color="primary"
          onClick={(e) => setMenu(e.target)}
          variant="circular"
          size="medium"
          sx={{
            borderRadius: 3.5,
            width: 55,
            height: 55,
            bgcolor: (t) =>
              t.palette.mode === "dark"
                ? `#4B5A6F`
                : isStaging
                ? "#9E9E9E"
                : `#B0CEEA`,
            boxShadow: {
              xs: 4,
              md: 0,
            },
            ":hover": {
              boxShadow: 10,
              bgcolor: (t) =>
                t.palette.mode === "dark"
                  ? lighten(`#4B5A6F`, 0.1)
                  : lighten(isStaging ? "#9E9E9E" : `#B0CEEA`, 0.1),
            },
          }}
        >
          <Add sx={{ fontSize: "1.6rem", color: "text.primary" }} />
        </Fab>
      </Box>
      <MenuCreation anchorEl={menu} onClose={() => setMenu(null)} />
    </>
  );
}

export default memo(CreationButton);

import { Check, PaymentOutlined } from "@mui/icons-material";
import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import React, { memo, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import FilterChip from "../../../../../../components/chip/FilterChip";
import {
  selectAllPaymentTypes,
  selectPaymentTypesEntities,
} from "../../../../../../store/features/configs/paymentTypesSlice";
import MenuBase from "./MenuBase";

const PaymentFilterMenu = ({
  anchorEl,
  onClose,
  filter,
  onClickItem,
  onClear,
}) => {
  const paymentTypes = useSelector(selectAllPaymentTypes);

  return (
    <MenuBase
      onClear={onClear}
      disabledClear={!Boolean(filter)}
      title={"Formas de pagamento"}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      {paymentTypes.map((payment) => {
        const selected = filter === payment._id;
        return (
          <ListItem disablePadding key={payment._id}>
            <ListItemButton
              sx={{ transition: "none" }}
              onClick={() => onClickItem(payment._id)}
              selected={selected}
            >
              {selected && <Check color="primary" sx={{ mr: 1 }} />}
              <ListItemText
                primaryTypographyProps={{
                  fontWeight: "500",
                  fontSize: ".95rem",
                  noWrap: true,
                }}
                primary={payment.name}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </MenuBase>
  );
};

function PaymentFilter({ filter, onChange, onClear }) {
  const filtered = useMemo(() => Boolean(filter), [filter]);
  const [menu, setMenu] = useState(null);

  const paymentTypesEntities = useSelector(selectPaymentTypesEntities);

  const handleClickPaymentItem = (payment) => {
    if (payment === filter) {
      onClear();
      return;
    }
    onChange(payment);
  };

  return (
    <>
      <FilterChip
        onClick={(e) => setMenu(e.target)}
        filtered={filtered}
        Icon={PaymentOutlined}
        label={"Forma de pagamento"}
        opened={Boolean(menu)}
        filteredLabel={filter && paymentTypesEntities[filter]?.name}
        isDropdown
      />
      <PaymentFilterMenu
        anchorEl={menu}
        filter={filter || ""}
        onClose={() => setMenu(null)}
        onClickItem={handleClickPaymentItem}
        onClear={onClear}
      />
    </>
  );
}

export default memo(PaymentFilter);

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  preferences: {
    open: false,
    util: null,
  },
  scanExpenses: {
    open: false,
    util: null,
  },
  newRequest: {
    open: false,
    util: null,
  },
  newExpense: {
    open: false,
    util: null,
  },
  newRoute: {
    open: false,
    util: null,
  },
  expenseView: {
    open: false,
    util: null,
  },
  routeView: {
    open: false,
    util: null,
  },
  manualRouteView: {
    open: false,
    util: null,
  },
  requestView: {
    open: false,
    util: null,
  },
  approvalModal: {
    open: false,
    util: null,
  },
  rejectModal: {
    open: false,
    util: null,
  },
  requestExportPDF: {
    open: false,
    util: null,
  },
  duplicatedChecker: {
    open: false,
    util: null,
  },
};

const portalSlice = createSlice({
  name: "portal",
  initialState,
  reducers: {
    openElement: (state, action) => {
      const { name, util } = action.payload;
      state[name].open = true;
      state[name].util = util || null;
    },
    closeElement: (state, action) => {
      state[action.payload].open = false;
      state[action.payload].util = null;
    },
  },
});

export const { openElement, closeElement } = portalSlice.actions;

export default portalSlice.reducer;

export const selectPortalIsOpened = (state, portalName) =>
  Boolean(state.portal[portalName]?.open);

export const selectPortalUtil = (state, portalName, utilName) =>
  state.portal[portalName]?.util && state.portal[portalName]?.util[utilName];

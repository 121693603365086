import { SyncOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React, { memo } from "react";
import { formatDate } from "../../../../utils/more/date_functions";

function TitleSection({ title, createdAt, sync }) {
  return (
    <Box width={"100%"} mb={0.5} display={"flex"} alignItems="flex-start">
      <Typography
        mr={1}
        flex={1}
        noWrap
        variant="h5"
        fontWeight={"600"}
        fontSize={"1.1rem"}
      >
        {title}
      </Typography>
      <Typography
        fontSize={".75rem"}
        fontWeight={"500"}
        color={"text.secondary"}
      >
        {formatDate(createdAt)}
      </Typography>
      <SyncOutlined
        color="action"
        fontSize="small"
        sx={{
          ml: 1,
          fontSize: ".9rem",
          mr: sync ? -0.5 : -3,
          opacity: sync ? 1 : 0,
          transition: ".15s ease",
        }}
      />
    </Box>
  );
}

export default memo(TitleSection);

import { Box, Typography } from "@mui/material";
import { memo } from "react";
import { useSelector } from "react-redux";
import { selectAccountCurrency, selectIsApprover } from "../../../../store/features/accountSlice";
import { selectTotalsResume } from "../../../../store/features/totalsSummarySlice";
import formatAmount from "../../../../utils/formatAmount";
import ResumeItem from "./components/ResumeItem";

export default memo(() => {
  const isAppover = useSelector(selectIsApprover);

  const currency = useSelector((state) => selectAccountCurrency(state, true));

  const { working, totals } = useSelector(selectTotalsResume);

  return (
    <Box
      mt={0}
      p={2}
      pt={4}
      px={3}
      sx={{
        borderRadius: 0,
        borderBottom: 1,
        borderColor: "divider",
        boxShadow: 0,
      }}
    >
      <Typography fontSize={"1.2rem"} variant="h5" color={"text.secondary"} fontWeight={"500"}>
        Resumo financeiro
      </Typography>
      <Box mt={2} display={"flex"} alignItems={"center"} gap={3} flexWrap={"wrap"}>
        <ResumeItem
          loading={working}
          title={"Em aberto"}
          helperText={
            "Soma dos valores de todas as despesas avulsas e relatórios em aberto"
          }
        >
          {currency} {totals?.opened ? formatAmount(totals.opened || 0) : "--"}
        </ResumeItem>
        <ResumeItem
          loading={working}
          title={"Em aprovação"}
          helperText={
            "Soma dos valores de todos os relatórios e adiantamentos que estão aguardando aprovação"
          }
        >
          {currency} {totals?.pending ? formatAmount(totals.pending || 0) : "--"}
        </ResumeItem>
        <ResumeItem
          loading={working}
          title={"Aguardando compensação"}
          helperText={
            "Soma dos valores de todos os seus relatórios e adiantamentos aprovados que estão aguardando a compensação"
          }
        >
          {currency} {totals?.approved ? formatAmount(totals.approved || 0) : "--"}
        </ResumeItem>
        {isAppover && (
          <ResumeItem
            loading={working}
            title={"Para aprovar"}
            helperText={
              "Soma dos valores de todos os seus relatórios e adiantamentos que estão aguardando aprovação e você pode aprovar"
            }
          >
            {currency} {totals?.to_approve ? formatAmount(totals.to_approve || 0) : "--"}
          </ResumeItem>
        )}
      </Box>
    </Box>
  );
});

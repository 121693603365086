import { Close } from "@mui/icons-material";
import {
  Box,
  DialogContent,
  Divider,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";
import React, { memo } from "react";
import Timeline from "../../../requests/view/contents/timeline/Timeline";

const HistoryDrawer = ({ open, onClose, title = "", events = [] }) => {
  return (
    <Drawer
      PaperProps={{
        elevation: 0,
        sx: {
          boxShadow: 15,
          width: "100%",
          maxWidth: 700,
        },
      }}
      open={open}
      anchor="right"
      onClose={onClose}
    >
      <Box p={2} display={"flex"} alignItems={"flex-start"}>
        <Box flex={1} mr={4}>
          <Typography
            mb={0.4}
            fontSize={"1.1rem"}
            textOverflow={"ellipsis"}
            color={"text.secondary"}
          >
            Histórico de
          </Typography>
          <Typography
            component={"span"}
            color={"text.primary"}
            variant="h5"
            fontSize={"1.1rem"}
            fontWeight={"500"}
            textOverflow={"ellipsis"}
          >
            {title}
          </Typography>
        </Box>
        <IconButton onClick={onClose} sx={{ m: -0.5 }}>
          <Close />
        </IconButton>
      </Box>
      <Divider />
      <DialogContent sx={{ pb: 10 }}>
        <Timeline thirdPerson={true} events={events} />
      </DialogContent>
    </Drawer>
  );
};

export default memo(HistoryDrawer);

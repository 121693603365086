import { Close, InfoOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";
import { AuthClient } from "../../api/httpClient";
import PasswordInput from "../../components/inputs/PasswordInput";
import { setError } from "../../store/features/base/errorBaseSlice";
import { openSnackbar } from "../../store/features/base/snackbarBaseSlice";
import { getProfilePicture } from "../../utils/functions/profile";

const ModalChangePassword = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.account.baseInfo);

  const [loading, setLoading] = useState(false);

  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  const [visiblePass, setVisiblePass] = useState(false);

  const [errors, setErrors] = useState({
    currentPass: null,
    newPass: null,
    confirmPass: null,
  });

  useEffect(() => {
    if (!open) {
      setCurrentPass("");
      setNewPass("");
      setConfirmPass("");
      setVisiblePass(false);
      setErrors({
        currentPass: null,
        newPass: null,
        confirmPass: null,
      });
    }
  }, [open]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;

    let errs = {
      currentPass: null,
      newPass: null,
      confirmPass: null,
    };

    if (!currentPass?.trim()) {
      errs.currentPass = "Insira a sua senha atual";
    }
    if (!newPass?.trim()) {
      errs.newPass = "Insira uma nova senha";
    }
    if (!confirmPass?.trim()) {
      errs.confirmPass = "Confirme a senha";
    }
    if (newPass === currentPass) {
      errs.newPass = "A nova senha deve ser diferente da atual";
    }
    if (
      !validator.isStrongPassword(newPass, {
        minLength: 8,
        minUppercase: 1,
        minSymbols: 1,
        minNumbers: 0,
      })
    ) {
      errs.newPass =
        "A senha deve conter no mínimo 8 caracteres, 1 letra maiúscula e 1 símbolo";
    }
    if (confirmPass !== newPass) {
      errs.confirmPass = "As senhas não correspondem";
    }

    if (Object.keys(errs)?.some((err) => Boolean(errs[err]))) {
      setErrors(errs);
      return;
    } else {
      setErrors({
        currentPass: null,
        newPass: null,
        confirmPass: null,
      });
    }

    setLoading(true);
    try {
      await AuthClient.client().put(`/profile`, {
        currentPassword: currentPass,
        newPassword: newPass,
      });
      dispatch(
        openSnackbar({ message: "A sua senha foi alterada com sucesso" })
      );
      onClose();
    } catch (error) {
      dispatch(
        setError({
          title: "Erro ao alterar senha",
          error,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: "rgba(255, 255, 255, .9)",
          },
        },
      }}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      {loading && <LinearProgress />}
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Alterar minha senha Notepay
          <Box flex={1} />
          <IconButton onClick={onClose} sx={{ m: -1 }}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Chip
            sx={{ height: 50, mb: 2 }}
            variant="outlined"
            icon={
              <Avatar
                sx={{ width: 30, height: 30 }}
                src={getProfilePicture(user?.userId)}
              ></Avatar>
            }
            label={
              <Box>
                <Typography variant="body2" fontWeight={"500"}>
                  {user?.name} {user?.lastname}
                </Typography>
                <Typography variant="caption" color={"text.secondary"}>
                  {user?.email}
                </Typography>
              </Box>
            }
          />
          <DialogContentText
            sx={{
              mb: 1,
              fontWeight: "500",
              display: "flex",
              fontSize: ".9rem",
              alignItems: "flex-start",
            }}
          >
            <InfoOutlined
              color="action"
              sx={{ fontSize: "1.1rem", mt: 0.5, mr: 1 }}
            />
            A nova senha deve conter no mínimo 8 caracteres, 1 letra maiúscula e
            1 símbolo
          </DialogContentText>
          <PasswordInput
            value={currentPass}
            onChange={setCurrentPass}
            autoFocus
            disableVisibilityButton
            controlledType={visiblePass ? "text" : "password"}
            label={"Senha atual"}
            margin={"normal"}
            error={errors?.currentPass}
            clearError={() =>
              setErrors((prev) => ({ ...prev, currentPass: null }))
            }
          />
          <PasswordInput
            value={newPass}
            onChange={setNewPass}
            disableVisibilityButton
            label={"Nova senha"}
            controlledType={visiblePass ? "text" : "password"}
            margin={"normal"}
            error={errors?.newPass}
            clearError={() => setErrors((prev) => ({ ...prev, newPass: null }))}
          />
          <PasswordInput
            value={confirmPass}
            onChange={setConfirmPass}
            disableVisibilityButton
            controlledType={visiblePass ? "text" : "password"}
            label={"Confirmar nova senha"}
            margin={"normal"}
            error={errors?.confirmPass}
            clearError={() =>
              setErrors((prev) => ({ ...prev, confirmPass: null }))
            }
          />
          <FormControlLabel
            sx={{ mt: 2 }}
            control={
              <Checkbox
                checked={visiblePass}
                onClick={() => setVisiblePass(!visiblePass)}
              />
            }
            label="Mostrar senhas"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
          <Button
            disabled={loading}
            type="submit"
            variant="contained"
            disableElevation
          >
            {loading ? "Alterando" : "Alterar"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalChangePassword;

import React, { memo } from "react";
import Input from "../../../components/inputs/text-input";

const RequestForm = ({ title = "", setTitle, setObs, obs = "" }) => {
  return (
    <>
      <Input
        value={title}
        onChange={setTitle}
        autoFocus
        required
        label={"Título"}
        margin={"normal"}
        variant={"outlined"}
      />
      <Input
        value={obs}
        onChange={setObs}
        label={"Descrição"}
        multiline
        margin={"normal"}
        variant={"outlined"}
      />
    </>
  );
};

export default memo(RequestForm, (prev, next) => {
  return prev.title === next.title && prev.obs === next.obs;
});

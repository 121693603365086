import { MoreVert } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { openElement } from "../../../store/features/portalSlice";
import { store } from "../../../store/store";

const sxIcon = { fontSize: "24px" };
const sxIconButton = { p: 1.5, color: "inherit" };

const MoreOptions = ({
  edit,
  remove,
  addToRequest,
  removeFromRequest,
  onEdit,
  openModal,
  requestId,
  expenseId,
  onClose,
}) => {
  const dispatch = useDispatch();

  const [menu, setMenu] = useState(null);

  const handleCopy = () => {
    const state = store.getState();

    const expenseCopy = state.expenses.entities[expenseId];

    const initialValues = {
      date: new Date(expenseCopy.date),
      from: expenseCopy.from,
      to: expenseCopy.to,
      waypoints: expenseCopy.waypoints,
      route_policy_id: expenseCopy.route_policy_id,
      distance: expenseCopy.distance,
      currency: expenseCopy.currency,
      project_id: expenseCopy.project_id,
      notes: expenseCopy.notes,
      to_request: requestId,
    };

    if (!edit) {
      initialValues.to_request = null;
    }

    let screenName;

    if (expenseCopy.is_route || expenseCopy.isRoute) {
      screenName = "newRoute";
      initialValues.payment_type = expenseCopy.payment_type;
      initialValues.snippet_id = expenseCopy.snippet_id;
      initialValues.route_mode = expenseCopy.route_mode;
    } else {
      screenName = "newExpense";
      initialValues.amount = expenseCopy.amount;
      initialValues.payment_type = expenseCopy.payment_type;
      initialValues.type_id = expenseCopy.type_id;
      initialValues.refundable = Boolean(expenseCopy.refundable);
    }
    dispatch(
      openElement({
        name: screenName,
        util: initialValues,
      })
    );
    onClose();
  };

  return (
    <>
      <Tooltip title="Opções">
        <IconButton sx={sxIconButton} onClick={(e) => setMenu(e.target)}>
          <MoreVert sx={sxIcon} />
        </IconButton>
      </Tooltip>
      <Menu
        slotProps={{
          paper: {
            sx: {
              boxShadow: 4,
              bgcolor: "elevation1.main",
            },
          },
        }}
        open={Boolean(menu)}
        anchorEl={menu}
        onClose={() => setMenu(null)}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "center",
        }}
      >
        {edit && <MenuItem onClick={onEdit}>Editar</MenuItem>}
        {addToRequest && (
          <MenuItem onClick={() => openModal("addToRequest")}>
            Adicionar a um relatório
          </MenuItem>
        )}
        {removeFromRequest && (
          <MenuItem onClick={() => openModal("removeFromRequest")}>
            Voltar para avulsas
          </MenuItem>
        )}
        <MenuItem onClick={handleCopy}>Criar como modelo</MenuItem>
        {remove && (
          <MenuItem onClick={() => openModal("delete")}>Excluir</MenuItem>
        )}
      </Menu>
    </>
  );
};

export default memo(MoreOptions);

import { combineReducers } from "@reduxjs/toolkit";
import accountSlice from "./accountSlice";
import expensesSlice from "./expensesSlice";

//bases
import errorBaseSlice from "./base/errorBaseSlice";
import snackbarBaseSlice from "./base/snackbarBaseSlice";

//persist
import { createMigrate, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { account_migrations } from "../migrations";
import approvalRequestsSlice from "./approvalRequestsSlice";
import breadcrumbsSlice from "./base/breadcrumbsSlice";
import settingsSlice from "./base/settingsSlice";
import customFieldsSlice from "./configs/customFieldsSlice";
import expensePoliciesSlice from "./configs/expensePoliciesSlice";
import expenseTypesSlice from "./configs/expenseTypesSlice";
import paymentTypesSlice from "./configs/paymentTypesSlice";
import projectsSlice from "./configs/projectsSlice";
import rejectionReasonsSlice from "./configs/rejectionReasonsSlice";
import routePoliciesSlice from "./configs/routePoliciesSlice";
import snippetsSlice from "./configs/snippetsSlice";
import notificationsSlice from "./notificationsSlice";
import portalSlice from "./portalSlice";
import requestsSlice from "./requestsSlice";
import totalsSummarySlice from "./totalsSummarySlice";

const persistConfig = {
  account: {
    key: "account",
    storage,
    blacklist: ["checkingAccess", "accountError", "syncing"],
    version: 0,
    migrate: createMigrate(account_migrations, {
      debug: false,
    }),
  },
  settings: {
    key: "settings",
    storage,
    blacklist: ["currentPosition"],
  },
};

export const rootReducer = combineReducers({
  account: persistReducer(persistConfig.account, accountSlice),
  portal: portalSlice,
  totalsSummary: totalsSummarySlice,

  //principals
  expenses: expensesSlice,
  requests: requestsSlice,
  approvalRequests: approvalRequestsSlice,

  //layout
  errorBase: errorBaseSlice,
  snackbarBase: snackbarBaseSlice,
  breadcrumbs: breadcrumbsSlice,
  settings: persistReducer(persistConfig.settings, settingsSlice),

  //configs
  expenseTypes: expenseTypesSlice,
  projects: projectsSlice,
  paymentTypes: paymentTypesSlice,
  expensePolicies: expensePoliciesSlice,
  routePolicies: routePoliciesSlice,
  snippets: snippetsSlice,
  rejectionReasons: rejectionReasonsSlice,
  customFields: customFieldsSlice,
  notifications: notificationsSlice,
});

import { InfoOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { syncAll } from "../api/synchronizer";
import LogoPrimary from "../assets/logo/logo-primary.svg";
import LogoWhite from "../assets/logo/logo-white.svg";
import { useThemeMode } from "../contexts/useThemeMode";
import { toggleCheckingAccess } from "../store/features/accountSlice";
import { getProfilePicture } from "../utils/functions/profile";

const CustomAvatar = ({ userId, userName, hasPhoto }) => {
  return (
    <Box
      width={100}
      height={100}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      position={"relative"}
      borderRadius={100}
    >
      <Avatar
        src={hasPhoto ? getProfilePicture(userId, nanoid()) : undefined}
        sx={{
          width: 100,
          height: 100,
          boxShadow: 2,
          fontSize: "3rem",
          fontWeight: "600",
          zIndex: 100,
          border: 1,
          borderColor: "divider",
          color: "text.secondary",
          backgroundColor: (t) => t.palette.background.default,
        }}
      >
        {userName[0]?.toUpperCase()}
      </Avatar>
      <CircularProgress
        size={105}
        sx={{ position: "absolute", transition: ".2s ease" }}
      />
    </Box>
  );
};

const SynchronizingLoading = () => {
  const { mode } = useThemeMode();

  const dispatch = useDispatch();

  const userData = useSelector((state) => state.account.baseInfo);
  const hasPhoto = useSelector((state) => state.account.hasPhoto);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleSyncAll = async () => {
    setLoading(true);
    await syncAll({
      onError: (err) => {
        setError("Erro ao sincronizar configuraçães da conta");
      },
      onSuccess: () => {
        dispatch(toggleCheckingAccess(false));
      },
    });
    setLoading(false);
  };

  useEffect(() => {
    handleSyncAll();
  }, []);

  return (
    <Box
      height={"80%"}
      width="100%"
      display={"flex"}
      flexDirection={"column"}
      alignItems="center"
      color={"text.primary"}
      justifyContent={"center"}
      p={2}
    >
      <img
        style={{
          width: "170px",
          alignSelf: "center",
          top: 70,
          marginBottom: "100px",
        }}
        src={mode === "light" ? LogoPrimary : LogoWhite}
      />
      {loading ? (
        <>
          <CustomAvatar
            hasPhoto={hasPhoto}
            userId={userData?.userId}
            userName={userData?.name}
          />
          <Typography
            textAlign={"center"}
            fontWeight={"500"}
            mt={4}
            color={"text.secondary"}
            variant="h5"
          >
            Olá, {userData?.name}.
          </Typography>
          <Typography
            textAlign={"center"}
            fontWeight={"600"}
            mt={1}
            variant="h4"
          >
            Estamos preparando tudo
          </Typography>
          <Typography
            textAlign={"center"}
            color={"text.secondary"}
            mt={0.5}
            fontWeight={"400"}
            variant="h6"
            maxWidth={650}
          >
            Vamos melhorar a gestão das suas despesas corporativas.
          </Typography>
        </>
      ) : (
        Boolean(error) && (
          <>
            <InfoOutlined color="error" sx={{ fontSize: "3rem" }} />
            <Typography variant="h5" my={2}>
              {error}
            </Typography>
            <Typography
              textAlign={"center"}
              color={"text.secondary"}
              mt={1}
              width={500}
              variant="body1"
            >
              Aconteceu um erro ao sincronizar os dados principais da sua conta.
              Tente atualizar a página, caso o erro persista, por favor, entre
              em contato conosco: <strong>suporte@notepay.com</strong>
            </Typography>
            <Button
              variant="contained"
              disableElevation
              sx={{ mt: 2 }}
              onClick={handleSyncAll}
            >
              Tentar novamente
            </Button>
          </>
        )
      )}
    </Box>
  );
};

export default SynchronizingLoading;

import {
  Add,
  LibraryAddCheckOutlined,
  LocationOnOutlined,
  ReceiptLongOutlined,
} from "@mui/icons-material";
import { Button, Menu, Typography } from "@mui/material";
import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { CustomMenuItem } from "../../../../../../components/sidebar/components/MenuCreation";
import { openElement } from "../../../../../../store/features/portalSlice";
import SelectFromSingleExpenses from "./SelectFromSingleExpenses";

const MenuCreation = memo(({ anchorEl, onClose, requestId }) => {
  const dispatch = useDispatch();
  const [selectSingleDrawer, setSelectSingleDrawer] = useState(false);
  return (
    <>
      <Menu
        transitionDuration={150}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        elevation={1}
        disableScrollLock
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: 3,
              width: 300,
              boxShadow: 3,
              bgcolor: "elevation1.main",
              pb: 1,
            },
          },
        }}
      >
        <Typography m={2} my={1} fontSize={"1.2rem"} fontWeight={"500"}>
          Adicionar despesas
        </Typography>
        <CustomMenuItem
          Icon={ReceiptLongOutlined}
          label="Criar despesa"
          onClick={() => {
            dispatch(
              openElement({
                name: "newExpense",
                util: { to_request: requestId },
              })
            );
            onClose();
          }}
        />
        <CustomMenuItem
          Icon={LocationOnOutlined}
          label="Criar percurso"
          onClick={() => {
            dispatch(
              openElement({ name: "newRoute", util: { to_request: requestId } })
            );
            onClose();
          }}
        />
        <CustomMenuItem
          Icon={LibraryAddCheckOutlined}
          label="Selecionar de avulsas"
          onClick={() => {
            setSelectSingleDrawer(true);
            onClose();
          }}
        />
      </Menu>
      <SelectFromSingleExpenses
        open={selectSingleDrawer}
        onClose={() => setSelectSingleDrawer(false)}
      />
    </>
  );
});

const AddExpenseButton = ({
  renderComponent = ({ setMenu }) => (
    <>
      <Button
        variant="outlined"
        startIcon={<Add />}
        onClick={(e) => setMenu(e.target)}
        sx={{ ml: 3, display: { xs: "none", md: "flex" } }}
      >
        Adicionar despesas
      </Button>
    </>
  ),
}) => {
  const { requestId } = useParams();
  const [menu, setMenu] = useState(null);
  return (
    <>
      {renderComponent({ setMenu })}
      <MenuCreation
        anchorEl={menu}
        onClose={() => setMenu(null)}
        requestId={requestId}
      />
    </>
  );
};

export default memo(AddExpenseButton);

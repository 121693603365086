import { MoreVert } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import MoreMenu from "./MoreMenu";

const MoreButton = ({
  requestId,
  status,
  advanced,
  onBackToOpen,
  onRefresh,
  exportPDF
}) => {
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    setMenu(null);
  }, [status]);

  return (
    <>
      <IconButton
        sx={{ bgcolor: menu && "action.focus" }}
        onClick={(e) => setMenu(e.target)}
      >
        <MoreVert />
      </IconButton>
      <MoreMenu
        anchorEl={menu}
        onClose={() => setMenu(null)}
        onBackToOpen={onBackToOpen}
        onRefresh={onRefresh}
        requestId={requestId}
        status={status}
        advanced={advanced}
        exportPDF={exportPDF}
      />
    </>
  );
};

export default memo(MoreButton);

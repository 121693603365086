import { createTransform } from "redux-persist";

const PersistTransformer = createTransform(
  (inboundState, key) => {
    if (key === "requests") {
      const persistentIds = [...inboundState.ids.slice(0, 50)];
      let persistentEntities = {};
      persistentIds.forEach(
        (id) => (persistentEntities[id] = inboundState.entities[id])
      );
      return {
        working: false,
        visitedStatus: inboundState.visitedStatus || "all",
        ids: persistentIds,
        entities: persistentEntities,
      };
    } else if (key === "expenses") {
      const persistentIds = inboundState.ids.filter((expenseId) => {
        const status = inboundState.entities[expenseId]?.status;
        const processingStatus = Boolean(
          inboundState.entities[expenseId]?.processingStatus
        );
        const inRequest = Boolean(inboundState.entities[expenseId]?.request_id);
        return (
          ["O", "AD", "R", "P"].includes(status) ||
          processingStatus ||
          !inRequest
        );
      });
      let persistentEntities = {};
      for (const expenseId of persistentIds) {
        persistentEntities[expenseId] = {
          ...inboundState.entities[expenseId],
        };
      }
      return {
        working: false,
        filters: {},
        selected: [],
        ids: persistentIds,
        entities: persistentEntities,
      };
    }
  },
  (outboundState, key) => {
    return outboundState;
  },
  { whitelist: ["expenses", "requests"] }
);

export default PersistTransformer;

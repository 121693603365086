import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { store } from "../store/store";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super();
    this.state = { error: null, errorInfo: null };
  }
  componentDidCatch(error, info) {
    this.setState({
      error,
      info,
    });
  }
  render() {
    if (this.state.error) {
      return (
        <Box
          height={"70%"}
          display={"flex"}
          justifyContent="center"
          flexDirection={"column"}
          alignItems="center"
        >
          <Typography
            maxWidth={600}
            fontWeight={"500"}
            variant="h4"
            gutterBottom
            textAlign={"center"}
          >
            Ops, algo deu errado.
          </Typography>
          <Typography maxWidth={600} mb={2} textAlign={"center"}>
            Vamos tentar novamente?
          </Typography>
          <Button
            disableElevation
            size="large"
            variant="contained"
            onClick={() => {
              const theme = window.localStorage.getItem("theme");
              const visited = window.localStorage.getItem("visited");
              store.dispatch({
                type: "RESET_STORE",
              });
              window.localStorage.clear();
              if (visited === "true") {
                window.localStorage.setItem("visited", "true");
              }
              window.localStorage.setItem("theme", theme || "light");
              window.location.reload();
            }}
          >
            Atualizar página
          </Button>
        </Box>
      );
    }
    return this.props.children;
  }
}

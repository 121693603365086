import { CheckOutlined, CloseOutlined } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Skeleton,
  TableCell,
  Tooltip,
  Typography,
} from "@mui/material";
import { formatDistanceToNow, isToday } from "date-fns";
import { ptBR } from "date-fns/locale";
import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { openElement } from "../../../../store/features/portalSlice";
import { formatDate } from "../../../../utils/more/date_functions";

const SentAtCell = ({ sentAt, requestId }) => {
  const dispatch = useDispatch();

  const [formattedDate, setFormattedDate] = useState(null);

  useEffect(() => {
    const date = new Date(sentAt);

    if (isToday(date)) {
      setFormattedDate(
        formatDistanceToNow(date, { locale: ptBR, addSuffix: true })
      );

      // Atualiza a cada minuto
      const interval = setInterval(() => {
        setFormattedDate(
          formatDistanceToNow(date, { locale: ptBR, addSuffix: true })
        );
      }, 60000);

      // Limpa o intervalo quando o componente for desmontado
      return () => clearInterval(interval);
    } else {
      setFormattedDate(formatDate(date));
    }
  }, [sentAt]);

  return (
    <TableCell padding="normal" align="right">
      <Box
        display={"flex"}
        gap={1}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        {formattedDate ? (
          <Typography
            width={150}
            color={"text.secondary"}
            noWrap
            variant="body2"
            fontSize={".825rem"}
          >
            {formattedDate}
          </Typography>
        ) : (
          <Skeleton width={150} height={20} />
        )}
        <Tooltip title="Rejeitar">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                openElement({ name: "rejectModal", util: { requestId } })
              );
            }}
            size="small"
            color="error"
            sx={{
              ml: 2,
              bgcolor: (t) => `${t.palette.error.main}14`,
              ":hover": { bgcolor: (t) => `${t.palette.error.main}35` },
            }}
          >
            <CloseOutlined color="error" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Aprovar">
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                openElement({ name: "approvalModal", util: { requestId } })
              );
            }}
            color="success"
            sx={{
              bgcolor: (t) => `${t.palette.success.main}14`,
              ":hover": { bgcolor: (t) => `${t.palette.success.main}35` },
            }}
          >
            <CheckOutlined color="success" />
          </IconButton>
        </Tooltip>
      </Box>
    </TableCell>
  );
};

export default memo(SentAtCell);

import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { memo, useEffect } from "react";
import { useDispatch } from "react-redux";

import { setBreadcrumbs } from "../../store/features/base/breadcrumbsSlice";
import {
  fetchLast3MonthsTotals,
  fetchLast3MonthsTotalsByCategory,
  fetchTotalsResume,
} from "../../store/features/totalsSummarySlice";
import Last3MonthsTotalsChart from "./components/charts/Last3MonthsTotalsChart";
import TopCategoriesPieChart from "./components/charts/TopCategoriesPieChart";
import Footer from "./components/footer";
import Header from "./components/header";
import RecentRequests from "./components/recent-requests";
import Resume from "./components/resume";

const Home = () => {
  const dispatch = useDispatch();

  const fetchHomeData = () => {
    dispatch(fetchLast3MonthsTotals());
    dispatch(fetchLast3MonthsTotalsByCategory());
    dispatch(fetchTotalsResume());
  };

  useEffect(() => {
    dispatch(setBreadcrumbs(["Gestão de despesas corporativas"]));
    fetchHomeData();

    const refreshInterval = setInterval(fetchHomeData, 60000);
    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  const scrolled = useScrollTrigger({
    disableHysteresis: true,
    target: undefined,
    threshold: 0,
  });

  useEffect(() => {
    const appbar = document.getElementById("appbar_main");
    if (!appbar) return;
    if (scrolled) {
      appbar.style.boxShadow = "0 4px 3px -2px rgba(0,0,0,.3)";
    } else {
      appbar.style.boxShadow = "none";
    }
  }, [scrolled]);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container disableGutters={isSmall} sx={{ flex: 1, color: "text.primary" }}>
      <Header />
      <Resume />
      <RecentRequests />
      <Grid spacing={3} sx={{ p: 3, pt: 2 }} container>
        <Grid xs={12} md={6}>
          <Box borderRadius={2} border={1} borderColor={"divider"} p={2}>
            <Typography
              mb={2}
              variant="h5"
              fontSize={"1.2rem"}
              fontWeight={"500"}
            >
              Gastos em despesas nos últimos 3 meses
            </Typography>
            <Last3MonthsTotalsChart />
          </Box>
        </Grid>
        <Grid xs={12} md={6}>
          <Box borderRadius={2} border={1} borderColor={"divider"} p={2}>
            <Typography
              mb={2}
              variant="h5"
              fontSize={"1.2rem"}
              fontWeight={"500"}
            >
              Categorias mais usadas
            </Typography>
            <TopCategoriesPieChart />
          </Box>
        </Grid>
      </Grid>
      <Footer />
    </Container>
  );
};

export default memo(Home);

import { MenuItem } from "@mui/material";

const DefaultMenuItem = ({ Icon, title = "", onClick, disabled }) => {
  return (
    <MenuItem
      sx={{ fontWeight: "600", fontSize: ".95rem", height: 45 }}
      disabled={disabled}
      onClick={onClick}
    >
      {Icon && <Icon color="action" fontSize="small" sx={{ mr: 2 }} />}
      {title}
    </MenuItem>
  );
};

export default DefaultMenuItem;

import { Chip, TableCell } from "@mui/material";
import React, { memo } from "react";

const RefundableCell = ({ refundable }) => {
  return (
    <TableCell align="left">
      <Chip
        variant="outlined"
        color={refundable ? "primary" : "default"}
        sx={{
          fontWeight: "600",
          border: "none",
          bgcolor: (t) =>
            refundable ? `${t.palette.primary.main}15` : "action.hover",
        }}
        size="small"
        label={refundable ? "Sim" : "Não"}
      />
    </TableCell>
  );
};

export default memo(RefundableCell);

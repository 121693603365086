import { Alert, Box, Collapse, Typography } from "@mui/material";
import React, { memo, useCallback, useState } from "react";
import DropzoneReceipt from "../../../../components/dropzone/dropzone-receipt/DropzoneReceipt";
import "../../../../main.css";
import Cropper from "../../../cropper/Cropper";

function ModalReceipts({
  files = [],
  disableCrop,
  handleOnDrop = () => {},
  handleCropReceipt = () => {},
  removeFile = () => {},
  requiredReceipt,
}) {
  const [cropper, setCropper] = useState(null);

  return (
    <Box
      sx={{ overflowY: "auto", overflowX: "hidden" }}
      height={"100%"}
      py={2}
      px={3}
      display="flex"
      className="hover-scrollbar"
      flexDirection={"column"}
      flex={1.2}
    >
      <Typography
        mt={1}
        textAlign={"center"}
        color="text.primary"
        fontSize={"1.2rem"}
        alignSelf={"center"}
        fontWeight="500"
        borderRadius={100}
        px={2}
      >
        Comprovantes
      </Typography>
      <Typography
        mb={2}
        mx={3}
        textAlign={"center"}
        fontSize={".85rem"}
        color="text.secondary"
        variant="body2"
      >
        Anexe arquivos dos tipos PNG, JPEG, WEBP ou PDF
      </Typography>
      <Collapse in={Boolean(requiredReceipt && !files.length)}>
        <Alert sx={{ mb: 2 }} severity="warning">
          Esta despesa requer pelo menos um comprovante
        </Alert>
      </Collapse>
      <DropzoneReceipt
        files={files}
        disableCrop={disableCrop}
        onDrop={handleOnDrop}
        onClickRemove={(event, id) => {
          removeFile(id);
        }}
        onClickCrop={useCallback(
          (fileId) => {
            const file = files.find((item) => item.id === fileId);
            if (!file) return;
            setCropper(file);
          },
          [files, cropper]
        )}
        enableCropFiles={
          files && files.filter((file) => !Boolean(file.cropped))
        }
      />
      {!disableCrop && (
        <Cropper
          file={cropper || {}}
          open={Boolean(cropper)}
          onClose={() => setCropper(null)}
          onCrop={handleCropReceipt}
        />
      )}
    </Box>
  );
}

export default memo(ModalReceipts);

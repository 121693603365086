import { Typography } from "@mui/material";
import React from "react";
import { formatMoment } from "../../../../../../utils/more/date_functions";

const EventTitle = ({ children, color, timestamp }) => {
  return (
    <>
      <Typography
        ml={"auto"}
        variant="body2"
        fontWeight={"500"}
        fontSize={".8rem"}
        color={"text.secondary"}
        mb={0.3}
      >
        {formatMoment(timestamp)}
      </Typography>
      <Typography
        mr={0.6}
        fontSize={".9rem"}
        variant="inherit"
        fontWeight={"500"}
      >
        {children}
      </Typography>
    </>
  );
};

export default EventTitle;

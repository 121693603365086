import { Box, Typography } from "@mui/material";
import React, { forwardRef, memo, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  selectExpensesNumByRequest,
  selectExpensesTotalAmountByRequest,
} from "../../../store/features/expensesSlice";
import { selectRequestById } from "../../../store/features/requestsSlice";
import { requestItemData } from "../../../utils/initialStates";
import RenderChipStatus from "../../chip/RenderChipStatus";
import Base from "./Base";
import AdvanceInfo from "./components/AdvanceInfo";
import AmountSection from "./components/AmountSection";
import ObsSection from "./components/ObsSection";
import TitleSection from "./components/TitleSection";

const RequestCardItem = forwardRef(({ onClick, requestId }, ref) => {
  const request = useSelector((state) => selectRequestById(state, requestId));

  const {
    title,
    obs,
    total_amount,
    total_expenses,
    status,
    id,
    created_at,
    advance,
    processingStatus,
    hidden,
  } = request || requestItemData;

  const navigate = useNavigate();

  const handleClick = useCallback(() => onClick(id), [id]);

  const sync = useMemo(
    () => processingStatus === "sync",
    [processingStatus, requestId]
  );

  const advancing = useMemo(
    () => processingStatus === "advancing",
    [processingStatus]
  );

  const totalAmount = useSelector(
    (state) =>
      parseFloat(total_amount || 0) ||
      selectExpensesTotalAmountByRequest(state, id) ||
      0
  );

  const totalExpenses = useSelector(
    (state) => total_expenses || selectExpensesNumByRequest(state, id) || 0
  );

  return (
    <Base
      innerRef={ref}
      requestId={id}
      onClick={handleClick}
      settings={{
        edit: status === "O" && !processingStatus,
        delete:
          ["O", "R"].includes(status) &&
          !processingStatus &&
          !["paid", "approved"].includes(advance?.status),
        send: status === "O" && Boolean(totalExpenses) && !processingStatus,
        reopen: status === "R" && !processingStatus,
        export: totalExpenses > 0,
        hidden,
      }}
    >
      <TitleSection title={title} createdAt={created_at} sync={sync} />
      <Box width={"100%"} flex={1}>
        <ObsSection obs={obs} />
        <AmountSection
          totalAmount={totalAmount}
          totalExpenses={totalExpenses}
        />
      </Box>
      <Box
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        <Typography
          color={"text.secondary"}
          fontSize={".8rem"}
          fontWeight={"500"}
          noWrap
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/approval-requests/${id}?tab=1`);
          }}
          sx={{ ":hover": { textDecoration: "underline" } }}
        >
          {totalExpenses === 0
            ? "Sem despesa"
            : totalExpenses === 1
            ? "1 despesa"
            : `${totalExpenses} despesas`}
        </Typography>
        <Box flex={1} />
        {advancing ? (
          <Typography
            variant="body2"
            fontStyle={"italic"}
            color={"text.secondary"}
          >
            Solicitando adiantamento...
          </Typography>
        ) : (
          <>
            <RenderChipStatus
              fz=".795rem"
              sx={{ height: 25 }}
              status={status}
            />
            {status === "AD" && advance && (
              <AdvanceInfo reason={advance?.reason} amount={advance?.amount} />
            )}
            <Box mr={3.2} />
          </>
        )}
      </Box>
    </Base>
  );
});

export default memo(RequestCardItem);

import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import SingleExpensesSVG from "../../assets/bgs/SingleExpensesSVG";
import MenuCreation from "../../components/sidebar/components/MenuCreation";

function NoExpensesContent({ title, subtitle, disableCreation }) {
  const [menu, setMenu] = useState(null);

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      height={"85%"}
      p={2}
      pb={"100px"}
    >
      <SingleExpensesSVG />
      <Typography
        color={"text.primary"}
        mt={2}
        gutterBottom
        fontWeight={"500"}
        fontSize={"1.3rem"}
        textAlign={"center"}
      >
        {title || "Aqui você organiza suas despesas avulsas"}
      </Typography>
      <Typography width={400} color={"text.secondary"} textAlign={"center"}>
        {subtitle ||
          "Quando estiver tudo pronto, coloque suas despesas em um relatório para enviá-las para aprovação"}
      </Typography>
      {!disableCreation && (
        <>
          <Button
            onClick={(e) => setMenu(e.target)}
            sx={{ mt: 2, borderRadius: 100 }}
            disableElevation
            variant="contained"
          >
            Adicionar despesas
          </Button>
          <MenuCreation
            hiddenNewRequest
            anchorEl={menu}
            onClose={() => setMenu(null)}
            transformOrigin={{ horizontal: "center", vertical: "bottom" }}
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          />
        </>
      )}
    </Box>
  );
}

export default NoExpensesContent;

import {
  AttachmentOutlined,
  ChevronLeft,
  ChevronRight,
  EditRoadOutlined,
  LiquorRounded,
  ReceiptLongOutlined,
} from "@mui/icons-material";
import {
  Box,
  CardMedia,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { memo, useCallback, useMemo, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import PdfIcon from "../../../../../assets/icons/pdf_icon.svg";
import { getReceiptUrl } from "../../../../../utils/functions/receipts";
import { EXPENSE_BASE_HEIGHT } from "../../base";

const AlcoholIndicator = memo(({ visible }) => {
  return (
    <Tooltip
      disableFocusListener={!visible}
      disableHoverListener={!visible}
      arrow
      title={
        "É possível que este comprovante inclua a compra de bebidas alcoólicas"
      }
    >
      <Box
        width={visible ? 27 : 0}
        height={visible ? 27 : 0}
        overflow={"hidden"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        boxShadow={0}
        position={"absolute"}
        bgcolor={"background.default"}
        top={0}
        right={0}
        sx={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 10,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          opacity: visible ? 1 : 0,
          transition: ".3s ease",
        }}
      >
        <LiquorRounded
          fontSize="small"
          color="action"
          sx={{ fontSize: "1.1rem" }}
        />
      </Box>
    </Tooltip>
  );
});

const NaviagtionButton = ({ children, onClick = () => {} }) => {
  return (
    <IconButton
      sx={{
        bgcolor: "rgb(255,255,255, .6)",
        ":hover": {
          bgcolor: "rgb(255,255,255, 1)",
        },
      }}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      size="small"
    >
      {children}
    </IconButton>
  );
};

const CarouselItem = memo(({ receipt = {} }) => {
  const [version, setVersion] = useState(0);

  const [loaded, setLoaded] = useState(false);

  const receiptURL = useMemo(
    () =>
      (getReceiptUrl(receipt.url, 250, 200) || receipt.uri) +
      `?version=${version}`,
    [receipt, version]
  );

  const handleError = () => {
    if (version > 5) {
      return;
    }
    setLoaded(false);
    setTimeout(() => {
      setVersion(version + 1);
    }, 1500);
  };

  return (
    <>
      <AlcoholIndicator visible={receipt?.tags?.includes("alcohol")} />
      {!loaded && (
        <Skeleton
          variant="rectangular"
          position={"absolute"}
          height={"100%"}
          width={"100%"}
          animation="wave"
        />
      )}
      <CardMedia
        width={"100%"}
        height={EXPENSE_BASE_HEIGHT}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        component="img"
        onError={handleError}
        onLoad={() => setLoaded(true)}
        image={
          receipt.type === "application/pdf"
            ? PdfIcon
            : receipt.url
            ? receiptURL
            : receipt.uri
        }
        alt={receipt.pathname}
      />
    </>
  );
});

function ReceiptsCarousel({
  hovered,
  receipts,
  isRoute,
  routeMode,
  requiredReceipt,
}) {
  const visibleReceipts = useMemo(() => {
    return receipts?.filter((receipt) => !receipt.hidden);
  }, [receipts]);

  const [carouselIndex, setCarouselIndex] = useState(0);

  const renderReceipts = useCallback((receipt, idx) => {
    return <CarouselItem key={idx.toString()} receipt={receipt} />;
  }, []);

  return (
    <Box component={"div"}>
      {visibleReceipts.length ? (
        <>
          {hovered && visibleReceipts.length > 1 && (
            <Box
              display={"flex"}
              top={0}
              bottom={0}
              alignItems={"center"}
              justifyContent={"space-between"}
              position={"absolute"}
              zIndex={10}
              width={"100%"}
              px={0.2}
            >
              <NaviagtionButton
                onClick={() => setCarouselIndex((prev) => prev - 1)}
              >
                <ChevronLeft
                  fontSize="small"
                  sx={{ color: "rgb(0,0,0,.8)", fontSize: "1rem" }}
                />
              </NaviagtionButton>
              <NaviagtionButton
                onClick={() => setCarouselIndex((prev) => prev + 1)}
              >
                <ChevronRight
                  fontSize="small"
                  sx={{ color: "rgb(0,0,0,.8)", fontSize: "1rem" }}
                />
              </NaviagtionButton>
            </Box>
          )}
          <Carousel
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            interval={5000}
            stopOnHover
            autoPlay={!hovered}
            infiniteLoop
            transitionTime={300}
            selectedItem={carouselIndex}
            onChange={(index) => setCarouselIndex(index)}
          >
            {visibleReceipts.map(renderReceipts)}
          </Carousel>
        </>
      ) : (
        <>
          <Box
            position={"relative"}
            height={EXPENSE_BASE_HEIGHT}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {routeMode === "manual" && isRoute ? (
              <EditRoadOutlined
                color={"disabled"}
                sx={{ opacity: 0.7, fontSize: "4rem" }}
              />
            ) : (
              <ReceiptLongOutlined
                color={"disabled"}
                sx={{ opacity: 0.7, fontSize: "4rem" }}
              />
            )}
            {requiredReceipt && (
              <Tooltip
                arrow
                title={
                  <Typography fontSize={".85rem"} fontWeight={"500"}>
                    É necessário inserir ao menos um comprovante para enviar
                    para aprovação
                  </Typography>
                }
              >
                <Box
                  bgcolor={"background.default"}
                  sx={{
                    cursor: "default",
                    position: "absolute",
                    bottom: 28,
                    right: 10,
                    p: 0.3,
                    width: 30,
                    height: 30,
                    borderRadius: 100,
                  }}
                  component={"span"}
                >
                  <AttachmentOutlined color="warning" />
                </Box>
              </Tooltip>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}

export default memo(ReceiptsCarousel);

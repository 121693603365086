import {
  AppBar,
  Box,
  Divider,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { isStaging } from "../../theme";
import NotepayLogo from "../NotepayLogo";
import AccountIndicator from "../account-indicator/AccountIndicator";
import DrawerButton from "./components/DrawerButton";
import ExpensesActions from "./components/ExpensesActions";
import RequestsActions from "./components/RequestsActions";
import ServerSyncButton from "./components/ServerSyncButton";
import Title from "./components/Title";
import ToggleLayoutViewMode from "./components/ToggleViewModeLayout";
import NotificationsMain from "./components/notifications/NotificationsMain";

function Appbar(props) {
  const breadcrumbs = useSelector((state) => state.breadcrumbs.data);

  const isRequestsScreen = useMemo(() => {
    return (
      breadcrumbs?.includes("Relatórios de despesas") &&
      breadcrumbs?.length === 1
    );
  }, [breadcrumbs]);

  const isExpensesScreen = useMemo(() => {
    return (
      breadcrumbs?.includes("Despesas avulsas") && breadcrumbs?.length === 1
    );
  }, [breadcrumbs]);

  const rightActionsExpanded = useMemo(() => {
    return isExpensesScreen || isRequestsScreen || false;
  }, [isExpensesScreen, isRequestsScreen]);

  const theme = useTheme();

  const isLarge = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <AppBar
      id="appbar_main"
      variant="none"
      position="fixed"
      sx={{
        width: "100%",
        transition: "none",
        zIndex: (t) => t.zIndex.appBar + 30,
        bgcolor: "background.default",
        pl: isLarge && "6.7em",
      }}
    >
      <Toolbar disableGutters sx={{ px: 2, pl: 3 }}>
        {!isLarge && <DrawerButton />}
        {isLarge && (
          <NotepayLogo
            style={{
              marginRight: ".8em",
            }}
          />
        )}
        {isStaging && (
          <>
            <Typography
              fontSize={"1.2rem"}
              color={"text.secondary"}
              fontWeight={"600"}
            >
              Homologação
            </Typography>
            <Divider orientation="vertical" sx={{ height: 20, mx: 1 }} />
          </>
        )}
        <Title />
        <Box flex={1} />

        {rightActionsExpanded && isLarge && (
          <>
            {isRequestsScreen && <RequestsActions />}
            {isExpensesScreen && <ExpensesActions />}
            <Divider
              orientation="vertical"
              sx={{ height: 20, ml: 2, mr: 1.3 }}
            />
          </>
        )}
        <Box display={"flex"} alignItems={"center"} gap={1} pl={0.5}>
          <ToggleLayoutViewMode />
          <ServerSyncButton />
          <NotificationsMain />
          <AccountIndicator />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default memo(Appbar);

import { Close } from "@mui/icons-material";
import {
  AppBar,
  Backdrop,
  Box,
  Button,
  IconButton,
  LinearProgress,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Cropper } from "react-cropper";

function ModalCropper({ open, onClose, file, onCrop }) {
  const [cropper, setCropper] = useState(null);
  const [copyFile, setCopyFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const appbarEl = document.getElementById("cropper_appbar");

  useEffect(() => {
    if (open && file) {
      setCopyFile(JSON.parse(JSON.stringify(file)));
    } else {
      setLoading(false);
    }
  }, [open]);

  const handleSave = () => {
    if (cropper) {
      const canvas = cropper.getCroppedCanvas();
      canvas.toBlob(async (blob) => {
        setLoading(true);
        const uri = URL.createObjectURL(blob);
        const origin = {
          uri,
          blob: copyFile.blob,
        };
        onCrop(file.id, blob, uri, origin, false);
        setLoading(false);
        onClose();
      });
    }
  };

  return (
    <Backdrop
      sx={{
        zIndex: (t) => t.zIndex.modal + 100,
        bgcolor: "rgb(0,0,0,.9)",
      }}
      open={open}
    >
      <Box
        display={"flex"}
        flexDirection="column"
        // bgcolor={"#E478B410"}
        width={"100%"}
        height="100%"
      >
        {loading && <LinearProgress />}
        <AppBar
          id="cropper_appbar"
          sx={{ color: "white" }}
          color="transparent"
          position="relative"
        >
          <Toolbar variant="dense" sx={{ color: "white", py: 1 }}>
            <IconButton color="inherit" onClick={onClose}>
              <Close />
            </IconButton>
            <Typography
              ml={2}
              color={"inherit"}
              variant="h5"
              fontSize={"1.2rem"}
            >
              Cortar comprovante
            </Typography>
            <Box flex={1} />
            {copyFile && copyFile.origin && (
              <Button
                onClick={() => {
                  setCopyFile((prev) => {
                    prev.blob = file.origin.blob;
                    prev.uri = file.origin.uri;
                    delete prev.origin;
                    return { ...prev };
                  });
                }}
                sx={{ mr: 1 }}
                color="inherit"
              >
                Reverter alterações
              </Button>
            )}
            <Button disabled={loading} onClick={handleSave} variant="contained">
              Salvar
            </Button>
          </Toolbar>
        </AppBar>
        {open && (
          <Cropper
            style={{
              height: `calc(96vh - ${appbarEl ? appbarEl.offsetHeight : 0}px)`,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            zoomTo={0.5}
            src={copyFile && copyFile.uri}
            viewMode={1}
            minCropBoxHeight={400}
            minCropBoxWidth={300}
            background={false}
            responsive={true}
            rotatable
            wheelZoomRatio={0.15}
            autoCropArea={1}
            checkOrientation={true} // https://github.com/fengyuanchen/cropperjs/issues/671
            onInitialized={(instance) => {
              setCropper(instance);
            }}
            guides={true}
          />
        )}
      </Box>
    </Backdrop>
  );
}

export default ModalCropper;

import {
  Box,
  Dialog,
  DialogContent,
  Fade,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ExpensePolicyLayer from "../../../layers/ExpensePolicyLayer";
import "../../../main.css";
import { selectAccountCurrency } from "../../../store/features/accountSlice";
import { setError } from "../../../store/features/base/errorBaseSlice";
import { openSnackbar } from "../../../store/features/base/snackbarBaseSlice";
import { selectPaymentTypeById } from "../../../store/features/configs/paymentTypesSlice";
import { selectProjectById } from "../../../store/features/configs/projectsSlice";
import { expensesOfflineActions } from "../../../store/features/expensesSlice";
import {
  closeElement,
  selectPortalIsOpened,
} from "../../../store/features/portalSlice";
import currencyConversor from "../../../utils/currencyConversor";
import { newExpenseInitialState } from "../../../utils/initialStates";
import { getBlob } from "../../../utils/more/images_transform";
import Header from "../components/Header";
import AmounInput from "../components/inputs/AmounInput";
import DateInput from "../components/inputs/DateInput";
import ExpTypeInput from "../components/inputs/ExpTypeInput";
import ObsInput from "../components/inputs/ObsInput";
import PaymentTypeInput from "../components/inputs/PaymentTypeInput";
import ProjectInput from "../components/inputs/ProjectInput";
import RequestInput from "../components/inputs/RequestInput";
import ConversionAlert from "./components/ConversionAlert";
import ModalReceipts from "./components/ModalReceipts";

function ModalNewExpense(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const accountCurrency = useSelector(selectAccountCurrency);

  //states
  const [values, setValues] = useState(newExpenseInitialState);
  const [receipts, setReceipts] = useState([]);
  const [conversion, setConversion] = useState({});
  const [loadingConversion, setLoadingConversion] = useState(false);

  //refs
  const expensePolicyRef = useRef();

  //portal data selector
  const open = useSelector((state) =>
    selectPortalIsOpened(state, "newExpense")
  );
  const initialPropData = useSelector((state) => state.portal.newExpense.util);

  //functions
  const onClose = useCallback(() => dispatch(closeElement("newExpense")), []);
  const showSnackbar = useCallback(
    (message) => dispatch(openSnackbar({ message })),
    []
  );
  const setExpense = useCallback(
    (data) => dispatch(expensesOfflineActions.setExpense(data)),
    []
  );
  const uploadReceipts = useCallback(
    (expenseId, receipts, syncOnly) =>
      dispatch(
        expensesOfflineActions.uploadReceipts(expenseId, receipts, syncOnly)
      ),
    []
  );
  const openError = useCallback((data) => dispatch(setError(data)), [dispatch]);

  //select entities by values
  const project = useSelector((s) => selectProjectById(s, values.project_id));
  const paymentType = useSelector((s) =>
    selectPaymentTypeById(s, values.payment_type)
  );

  const expTypeInputRef = useRef(null);

  //initial state control
  useEffect(() => {
    if (open) {
      setValues({
        ...newExpenseInitialState,
        ...(initialPropData || {}),
        currency: initialPropData?.currency || accountCurrency,
      });
      setReceipts([]);
      setConversion({});
    } else {
      setValues(newExpenseInitialState);
    }
  }, [open]);
  const handleValuesChange = (prop, v) => {
    setValues((prev) => ({ ...prev, [prop]: v }));
  };

  //CONVERSION
  const converted = useMemo(
    () => Boolean(open && values.currency !== accountCurrency),
    [values.currency, accountCurrency, open]
  );
  const setConversionProp = useCallback((payload) => {
    setConversion((prev) => ({ ...prev, [payload.prop]: payload.value }));
  }, []);
  const getConversion = async () => {
    try {
      setLoadingConversion(true);
      const result = await currencyConversor(
        values.currency,
        accountCurrency,
        values.date,
        values.amount || 0
      );
      setConversion({
        calculated: result.calculated,
        factor: result.factor,
        from: result.from,
        to: accountCurrency,
        referenceDate: result.reference_date,
      });
      setLoadingConversion(false);
    } catch (error) {
      setConversion({});
    }
  };
  useEffect(() => {
    if (!open) return;
    if (converted) {
      if (conversion.factor) {
        const { amount } = values;
        const { factor } = conversion;
        setConversionProp({
          prop: "calculated",
          value: factor * parseFloat(amount),
        });
      } else {
        getConversion();
      }
    }
  }, [converted, values.amount]);
  useEffect(() => {
    if (!open) return;
    if (converted) {
      getConversion();
    }
  }, [values.date, values.currency]);

  //payment effect
  useEffect(() => {
    if (values.payment_type && paymentType && !paymentType.can_edit) {
      handleValuesChange("refundable", Boolean(paymentType.is_refundable));
    }
  }, [values.payment_type, paymentType]);

  const refundableReadOnly = useMemo(() => {
    if (!paymentType) return false;
    if (paymentType.can_edit) {
      return false;
    } else {
      return true;
    }
  }, [paymentType]);

  //receipts func
  const handleOnDropReceipts = useCallback(async (acceptedFiles) => {
    let prepared = [];
    for (let index = 0; index < acceptedFiles.length; index++) {
      const file = acceptedFiles[index];
      const blob = await getBlob(file);
      const receiptId = nanoid(5);
      try {
        const data = {
          id: receiptId,
          filename: file.path,
          uri: URL.createObjectURL(file),
          type: file.type,
          blob: new Blob([blob], { type: file.type }),
        };
        prepared.push(data);
      } catch (error) {
        openError(`Falha ao carregar arquivos ${error.message}`);
      }
    }
    if (prepared && prepared.length) {
      setReceipts((prev) => {
        return [...prepared, ...prev];
      });
    }
  }, []);

  const handleCropReceipt = useCallback(
    (fileId, blob, previewUrl, origin, revert) => {
      const selectedFile = receipts.find((item) => item.id === fileId);
      if (!selectedFile) return;
      selectedFile.blob = blob;
      selectedFile.uri = previewUrl;
      if (revert) {
        delete selectedFile.origin;
      } else if (origin) {
        selectedFile.origin = origin;
      }
      setReceipts((prev) => {
        prev.splice(
          prev.findIndex((item) => item.id === fileId),
          1,
          selectedFile
        );
        return [...prev];
      });
    },
    [receipts]
  );
  const handleRemoveReceipt = useCallback((id) => {
    setReceipts((prev) => {
      const index = prev.findIndex((receipt) => receipt.id === id);
      if (index !== -1) {
        prev.splice(index, 1);
      }
      return [...prev];
    });
  }, []);

  //CREATION
  const handleCreateExpense = async (event, isChecked) => {
    if (!values?.type_id) {
      expTypeInputRef?.current?.focus();
      expTypeInputRef.current.placeholder = "Selecione uma categoria";
      return;
    }
    const expenseId = nanoid(8);
    const expenseObj = {
      id: expenseId,
      type_id: values.type_id,
      project_name: project?.name || "",
      project_id: values.project_id,
      payment_type: values.payment_type,
      payment_type_name: paymentType?.name,
      account_currency: accountCurrency,
      currency: values.currency,
      amount: parseFloat(values.amount),
      amount_converted: converted ? parseFloat(conversion.calculated) : null,
      date: values.date,
      notes: values.notes,
      isRoute: false,
      is_route: false,
      request_id: values.to_request,
      refundable: values.refundable,
      receipts,
      status: "O",
      created_at: new Date().toISOString(),
    };
    let ok = false;

    const { isOk, warnings, required_receipt } =
      expensePolicyRef.current?.check(
        {
          ...expenseObj,
          route_mode: undefined,
          distance: undefined,
          currency_t: expenseObj.currency,
        },
        !isChecked
      );
    expenseObj.warnings = warnings || [];
    expenseObj.required_receipt = Boolean(required_receipt);
    if (isChecked) {
      ok = true;
    } else {
      ok = isOk;
    }
    if (!ok) return;
    setExpense(expenseObj);
    if (values.to_request) {
      navigate(`/approval-requests/${values.to_request}?tab=1`, {
        replace: true,
      });
    } else {
      navigate("/expenses", { replace: true });
    }
    showSnackbar("Despesa criada");
    onClose();

    if (Boolean(receipts.length)) {
      uploadReceipts(expenseId, receipts, true);
    }
  };

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      fullScreen={isSmall}
      data-disableselect={true}
      open={open}
      TransitionComponent={Fade}
      fullWidth
      maxWidth="md"
      PaperProps={{
        elevation: 1,
        sx: {
          boxShadow: 24,
          borderRadius: isSmall ? 0 : 2,
          height: { md: "90%", xs: "100%" },
          maxHeight: { md: "45em", xs: "100%" },
          transition: ".25s ease",
          position: "relative",
        },
      }}
    >
      <Header
        onClose={onClose}
        title={"Criar despesa"}
        onCreate={handleCreateExpense}
      />
      <DialogContent sx={{ p: 0, display: "flex", flexWrap: "wrap-reverse" }}>
        <Box
          height={"100%"}
          position="relative"
          width={{ md: "26em", xs: "100%" }}
          borderRight={1}
          borderColor={{ md: "divider", xs: "transparent" }}
        >
          <ModalReceipts
            files={receipts}
            requiredReceipt={false}
            handleOnDrop={handleOnDropReceipts}
            handleCropReceipt={handleCropReceipt}
            removeFile={handleRemoveReceipt}
          />
        </Box>
        <Box height={"100%"} flex={1} display="flex" flexDirection={"column"}>
          <Box
            p={2}
            pr={2.5}
            pt={3}
            pb={{ xs: 2, md: 6 }}
            flex={1}
            display="flex"
            flexDirection={"column"}
            flexBasis={0}
            className="hover-scrollbar"
            id="new_expense_fields_content"
            overflow="auto"
            gap={3}
          >
            <AmounInput
              sx={{ mb: 1 }}
              value={values.amount}
              refundableReadOnly={refundableReadOnly}
              refundable={values.refundable}
              currency={values.currency}
              onChange={useCallback(
                (v) => handleValuesChange("amount", v),
                [values.amount]
              )}
              onChangeRefundable={useCallback(
                () => handleValuesChange("refundable", !values.refundable),
                [values.refundable]
              )}
              onChangeCurrency={useCallback(
                (v) => handleValuesChange("currency", v),
                [values.currency]
              )}
            />
            {values.currency !== accountCurrency && (
              <>
                <ConversionAlert conversion={conversion} />
              </>
            )}
            <ExpTypeInput
              size="medium"
              value={values.type_id}
              onChange={useCallback(
                (v) => handleValuesChange("type_id", v),
                [values.type_id]
              )}
              inputRef={expTypeInputRef}
            />
            <DateInput
              size="medium"
              value={values.date}
              onChange={useCallback(
                (v) => handleValuesChange("date", v),
                [values.date]
              )}
            />
            <PaymentTypeInput
              size="medium"
              value={values.payment_type}
              onChange={useCallback(
                (v) => handleValuesChange("payment_type", v),
                [values.payment_type]
              )}
            />
            <ProjectInput
              size="medium"
              value={values.project_id}
              onChange={useCallback(
                (v) => handleValuesChange("project_id", v),
                [values.project_id]
              )}
            />
            <ObsInput
              value={values.notes}
              onChange={useCallback(
                (v) => handleValuesChange("notes", v),
                [values.notes]
              )}
            />
            <RequestInput
              size="medium"
              value={values.to_request}
              onChange={useCallback(
                (v) => handleValuesChange("to_request", v),
                [values.to_request]
              )}
            />
          </Box>
        </Box>
      </DialogContent>
      {/* Camada de validação de pilíticas de despesa */}
      <ExpensePolicyLayer
        ref={expensePolicyRef}
        onCreate={handleCreateExpense}
        showRequiredReceipt={!Boolean(receipts.length)}
      />
    </Dialog>
  );
}

export default memo(ModalNewExpense);

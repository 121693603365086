import { Close } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  IconButton,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import React, { memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../../../main.css";
import {
  onChangeExpensesSelection,
  selectExpensesFilters,
} from "../../../../../store/features/expensesSlice";
import FiltersOptions from "./FiltersOptions";
import TotalSelectedIndicator from "./TotalSelectedIndicator";
import AddToRequest from "./actions/AddToRequest";
import Remove from "./actions/Remove";

function Toolbar({
  selectionMode,
  expensesCount,
  selectedNum,
  onChangeFilter,
  onRemoveFilter,
  onClearAllFilters,
  onClearSelection,
  onSelectAllClick,
  sx,
}) {
  const dispatch = useDispatch();

  const filters = useSelector(selectExpensesFilters);

  const handleKeydown = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        onClearSelection();
      }
    },
    [onClearSelection]
  );

  useEffect(() => {
    if (selectionMode) {
      window.addEventListener("keydown", handleKeydown);
    }
    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  }, [selectionMode]);

  const scrolled = useScrollTrigger({
    disableHysteresis: true,
    target: undefined,
    threshold: 0,
  });

  const clearSelection = useCallback(() => {
    dispatch(onChangeExpensesSelection({ type: "clear" }));
  }, [onChangeExpensesSelection]);

  return (
    <Box
      px={{ xs: 0, md: 1 }}
      py={1}
      pl={{ xs: 1, md: "2.5em" }}
      position={"fixed"}
      left={{ xs: 0, md: "5em" }}
      width={{
        xs: "100%",
        md: "calc(100% - 5em)",
      }}
      boxShadow={scrolled && 2}
      zIndex={(z) => z.zIndex.appBar + 11}
      top={{
        xs: 53,
        md: 63,
      }}
      bgcolor={"background.default"}
      gap={1}
      display={"flex"}
      alignItems={"center"}
      overflow={"scroll"}
      sx={sx}
      className="toolbar-expenses"
      data-disableselect={selectionMode}
    >
      <Checkbox
        data-disableselect={true}
        color="primary"
        indeterminate={selectedNum > 0 && selectedNum < expensesCount}
        checked={expensesCount > 0 && selectedNum === expensesCount}
        onChange={onSelectAllClick}
        sx={{ mr: 1 }}
      />
      <Box
        position={"absolute"}
        ml={5}
        display={"flex"}
        alignItems={"center"}
        bgcolor={"background.default"}
        sx={{
          opacity: selectionMode ? 1 : 0,
          transform: `scale(${selectionMode ? "1" : ".89"})`,
          transition: ".1s ease",
        }}
      >
        <IconButton
          sx={{ mr: 1 }}
          data-disableselect={true}
          onClick={onClearSelection}
        >
          <Close />
        </IconButton>
        <Typography
          mr={1}
          color={"text.primary"}
          fontWeight={"500"}
          variant="body1"
          fontSize={{
            xs: ".9rem",
            md: "1.1rem",
          }}
          sx={{
            opacity: selectionMode ? 1 : 0.4,
          }}
        >
          {selectedNum} selecionada(s)
        </Typography>
        <TotalSelectedIndicator />
      </Box>
      {!selectionMode ? (
        <FiltersOptions
          onChangeFilter={onChangeFilter}
          filters={filters}
          onClearAllFilters={onClearAllFilters}
          onRemoveFilter={onRemoveFilter}
        />
      ) : (
        <>
          <Box flex={1} />
          <AddToRequest clearSelection={clearSelection} />
          <Remove clearSelection={clearSelection} />
        </>
      )}
    </Box>
  );
}

export default memo(Toolbar);

import React from "react";
import { useSelector } from "react-redux";
import { selectIsApprover } from "../../store/features/accountSlice";
import NoAccessContent from "./NoAccessContent";

const CheckApproverAccessWrapper = ({
  children,
  showNoAccessComponent = false,
}) => {
  const isApprover = useSelector(selectIsApprover);

  return isApprover ? (
    children
  ) : showNoAccessComponent ? (
    <NoAccessContent title="Você não tem o acesso ao menu do aprovador" />
  ) : (
    <></>
  );
};

export default CheckApproverAccessWrapper;

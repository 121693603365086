import { AttachmentOutlined, ReceiptLongOutlined } from "@mui/icons-material";
import { Avatar, AvatarGroup, Box, Skeleton, Tooltip } from "@mui/material";
import React, { memo, useState } from "react";
import PdfIcon from "../../../../assets/icons/pdf_icon.svg";
import { getReceiptUrl } from "../../../../utils/functions/receipts";

const ReceiptIndicator = ({
  requiredReceipt,
  receipts = [],
  staticMapURL,
  isRoute,
  routeMode,
}) => {
  const [version, setVersion] = useState(0);

  const handleError = () => {
    if (version > 5) {
      return;
    }
    setTimeout(() => {
      setVersion(version + 1);
    }, 1500);
  };

  if (isRoute && routeMode === "map") {
    return <Avatar sx={{ boxShadow: 2 }} src={staticMapURL} />;
  } else if (receipts.length) {
    return (
      <AvatarGroup max={2} spacing={"small"}>
        {receipts.map((receipt) => {
          return (
            <Avatar
              key={`${receipt.id}-${version}`}
              src={
                receipt.type === "application/pdf"
                  ? PdfIcon
                  : receipt.url
                  ? getReceiptUrl(receipt.url, 85, 70) + `?version=${version}`
                  : receipt.uri
              }
              sx={{ bgcolor: "action.hover" }}
              onError={handleError}
            >
              <Skeleton
                variant="circular"
                height={40}
                width={40}
                animation="wave"
              />
            </Avatar>
          );
        })}
      </AvatarGroup>
    );
  } else {
    return (
      <Avatar
        sx={{
          bgcolor: (t) => `background.paper`,
          border: 1,
          borderColor: "divider",
          position: "relative",
        }}
      >
        <ReceiptLongOutlined color="action" sx={{ opacity: 0.5 }} />
        {requiredReceipt && !receipts.length && (
          <Tooltip
            arrow
            title={
              "É necessário inserir ao menos um comprovante para enviar para aprovação"
            }
          >
            <Box
              bgcolor={"background.default"}
              sx={{
                cursor: "default",
                position: "absolute",
                bottom: 0,
                right: 0,
                p: 0.3,
                width: 22,
                height: 22,
                borderRadius: 100,
                zIndex: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              component={"span"}
            >
              <AttachmentOutlined
                sx={{ fontSize: "1rem" }}
                fontSize="small"
                color="warning"
              />
            </Box>
          </Tooltip>
        )}
      </Avatar>
    );
  }
};

export default memo(ReceiptIndicator);

import { MoreVert } from "@mui/icons-material";
import { Box, IconButton, Menu } from "@mui/material";
import React, { memo, useCallback, useState } from "react";
import ErrorButton from "./ErrorButton";
import WarningsButton from "./WarningsButton";
import AddToRequest from "./more-actions/AddToRequest";
import CopyModel from "./more-actions/CopyModel";
import DeleteExpense from "./more-actions/DeleteExpense";
import RemoveFromRequest from "./more-actions/RemoveFromRequest";

export const MoreButton = memo(
  ({
    addToRequest,
    removeFromRequest,
    deleteExpense,
    expenseId,
    requestId,
  }) => {
    const [menu, setMenu] = useState(null);

    const closeMenu = useCallback(() => setMenu(null), []);

    return (
      <>
        <IconButton
          color="inherit"
          onClick={(e) => {
            e.stopPropagation();
            setMenu(e.target);
          }}
          sx={{
            bgcolor: menu && "action.focus",
          }}
        >
          <MoreVert fontSize="small" />
        </IconButton>
        <Menu
          transitionDuration={150}
          elevation={3}
          slotProps={{
            paper: {
              sx: {
                boxShadow: 4,
                minWidth: 200,
                bgcolor: 'elevation1.main'
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "center", vertical: "center" }}
          open={Boolean(menu)}
          anchorEl={menu}
          onClose={(e) => {
            e.stopPropagation();
            closeMenu();
          }}
        >
          {deleteExpense && <DeleteExpense expenseId={expenseId} />}
          {removeFromRequest && requestId && (
            <RemoveFromRequest expenseId={expenseId} requestId={requestId} />
          )}
          {addToRequest && <AddToRequest expenseId={expenseId} />}
          <CopyModel
            requestId={requestId}
            expenseId={expenseId}
            onClose={closeMenu}
          />
        </Menu>
      </>
    );
  }
);

function BottomActions({
  error,
  removeFromRequest,
  addToRequest,
  deleteExpense,
  expenseId,
  requestId,
  warnings,
  customActionComponent,
  disableActions,
}) {
  return (
    <Box
      sx={{
        position: "absolute",
        zIndex: 100,
        bottom: 0,
        right: 0,
        p: 1,
        display: "flex",
        alignItems: "center",
      }}
    >
      {!error && (
        <>
          {Boolean(warnings && warnings.length) && (
            <WarningsButton warnings={warnings} expenseId={expenseId} />
          )}
          {!disableActions && (
            <MoreButton
              removeFromRequest={removeFromRequest}
              addToRequest={addToRequest}
              deleteExpense={deleteExpense}
              expenseId={expenseId}
              requestId={requestId}
            />
          )}
          {customActionComponent && customActionComponent(expenseId)}
        </>
      )}
      {error && <ErrorButton error={error} expenseId={expenseId} />}
    </Box>
  );
}

export default memo(BottomActions);

import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { APIActions } from "../../../queues/offline-queue/effect";
import normalize from "../../../utils/normalize";

const baseName = "customFields";

const adapter = createEntityAdapter({
  selectId: (entity) => entity.id,
});

const initialState = adapter.getInitialState({
  working: false,
});

export const syncCustomFields = createAsyncThunk(
  `${baseName}/syncCustomFields`,
  async () => {
    const res = await APIActions.customFields.sync();
    return res;
  }
);

export const customFieldsSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    setupCustomFields(state, { payload }) {
      const { entities } = normalize(payload);
      adapter.setAll(state, entities);
    },
    upsertCustomField(state, { payload }) {
      adapter.upsertOne(state, payload);
    },
    removeCustomField(state, { payload }) {
      adapter.removeOne(state, payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(syncCustomFields.pending, (state, action) => {
        state.working = true;
      })
      .addCase(syncCustomFields.rejected, (state, action) => {
        state.working = false;
      })
      .addCase(syncCustomFields.fulfilled, (state, action) => {
        state.working = false;
        const data = action.payload;
        customFieldsSlice.caseReducers.setupCustomFields(state, {
          payload: data,
        });
      });
  },
});

export const { setupCustomFields, upsertCustomField, removeCustomField } =
  customFieldsSlice.actions;

export const {
  selectAll: selectAllCustomFields,
  selectById: selectCustomFieldById,
  selectIds: selectCustomFieldsIds,
  selectEntities: selectCustomFieldsEntities,
} = adapter.getSelectors((state) => state[baseName]);

export default customFieldsSlice.reducer;

export const selectRequestsCustomFieldsIds = createSelector(
  [selectCustomFieldsIds, selectCustomFieldsEntities],
  (ids, entities) => {
    return ids?.filter((fieldId) => entities[fieldId]?.applyTo === "requests");
  }
);

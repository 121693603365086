import { RepeatRounded } from "@mui/icons-material";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import React, { memo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import RouteInputBox from "./RouteInputBox";

function RouteSection({
  routes,
  updateRoute = () => {},
  revertRoutes = () => {},
  calculateFunction = () => {},
  addRoute = () => {},
  removeRoute = () => {},
  onDragEnd = () => {},
}) {
  const [loadingCurrentPos, setLoadingCurrentPos] = useState(false);

  const handleClickFromCurrentPosition = (idx) => {
    const geocoder = new window.google.maps.Geocoder();
    setLoadingCurrentPos(true);
    window.navigator.geolocation.getCurrentPosition(
      (s) => {
        const latlng = {
          lat: s.coords.latitude,
          lng: s.coords.longitude,
        };
        geocoder.geocode(
          {
            location: latlng,
          },
          function (results, status) {
            if (status === "OK") {
              if (results[0]) {
                //This is yout formatted address
                updateRoute(idx, results[0].formatted_address);
                calculateFunction();
              } else {
                window.alert("No results found");
              }
            } else {
              window.alert("Geocoder failed due to: " + status);
            }
            setLoadingCurrentPos(false);
          }
        );
      },
      (err) => {
        setLoadingCurrentPos(false);
        return;
      },
      {
        enableHighAccuracy: true,
      }
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box borderBottom={1} mx={-1.6} mt={1} borderColor="divider">
        <Box display={"flex"} alignItems="center" position="relative">
          <Box
            sx={{
              height: routes.length * 55 - 72,
              position: "absolute",
              width: 1.4,
              bgcolor: (t) => t.palette.grey[400],
              zIndex: 1,
              mt: 1,
              left: routes.length > 2 ? 30 : 28.5,
            }}
          />
          <Droppable droppableId="routes">
            {(provided) => (
              <Box
                flex={1}
                display={"flex"}
                py={3}
                pl={1}
                pr={1}
                pt={2}
                alignItems="flex-start"
                flexDirection={"column"}
                gap={1.7}
                height={routes.length * 55}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {routes.map((route, idx) => (
                  <Draggable
                    key={idx.toString()}
                    draggableId={"_" + idx.toString()}
                    index={idx}
                  >
                    {(provided, snapshot) => (
                      <RouteInputBox
                        innerRef={provided.innerRef}
                        value={route}
                        provided={provided}
                        isOrigin={idx === 0}
                        isDestination={idx === routes.length - 1}
                        enableRemove={routes.length > 2}
                        onRemove={() => removeRoute(idx)}
                        onPlaceChanged={(value) => {
                          updateRoute(idx, value);
                        }}
                        loadingCurrentPos={loadingCurrentPos}
                        calculate={calculateFunction}
                        onClickFromCurrentPosition={() =>
                          handleClickFromCurrentPosition(idx)
                        }
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
          {routes.length === 2 && (
            <Tooltip title="Reverter rota">
              <IconButton
                onClick={revertRoutes}
                color="inherit"
                sx={{
                  mr: 1,
                  mt: 2,
                }}
              >
                <RepeatRounded />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Box
          pl={6.3}
          pr={2}
          py={1}
          pt={0.5}
          display="flex"
          alignItems={"center"}
        >
          <Button
            sx={{ opacity: 0.85, px: 0.5 }}
            onClick={addRoute}
            size="small"
            color="inherit"
          >
            Adicionar destino
          </Button>
          <Box flex={1} />
        </Box>
      </Box>
    </DragDropContext>
  );
}

export default memo(RouteSection);

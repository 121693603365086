import { ArrowForward, Circle } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { selectExpenseTypeById } from "../../../../store/features/configs/expenseTypesSlice";
import AvatarType from "../../../avatar/AvatarType";

const SLICE_NUM = 30;

const Content = ({
  notes,
  typeId,
  routeMode,
  isRoute,
  distance,
  route = {},
  compact,
}) => {
  const expType = useSelector((s) => selectExpenseTypeById(s, typeId));

  return (
    <Box
      width={"100%"}
      display={"flex"}
      alignItems={"center"}
      maxWidth={compact ? 250 : 350}
    >
      <AvatarType
        showBgcolor
        icon={isRoute ? "place" : expType?.icon}
        fz="1.3rem"
        size={35}
      />
      <Box mx={2} flex={1} overflow={"hidden"}>
        <Box flex={1} display={"flex"} alignItems={"center"}>
          <Typography
            noWrap
            color={
              !expType?.name && !isRoute ? "text.secondary" : "text.primary"
            }
            fontWeight={!expType?.name && !isRoute ? "500" : "600"}
            fontSize={".9rem"}
          >
            {isRoute
              ? routeMode === "manual"
                ? "Percurso manual"
                : "Percurso"
              : expType?.name || "Sem categoria"}
          </Typography>
          {isRoute && (
            <>
              <Circle color="disabled" sx={{ fontSize: ".3rem", mx: 0.8 }} />
              <Typography noWrap fontWeight={"500"} fontSize={".9rem"}>
                {distance} km
              </Typography>
            </>
          )}
        </Box>
        {(notes || (isRoute && routeMode === "map")) && (
          <Typography
            display={"flex"}
            alignItems={"center"}
            noWrap
            component={"div"}
            color={"text.secondary"}
            fontSize={".82rem"}
          >
            {isRoute && routeMode === "map" ? (
              <>
                {route?.from?.slice(0, SLICE_NUM) + "..."}{" "}
                <ArrowForward sx={{ fontSize: "1rem", mx: 0.5 }} />{" "}
                {route?.to?.slice(0, SLICE_NUM) + "..."}
              </>
            ) : (
              <Typography variant="inherit" noWrap>
                {notes || "Sem descrição"}
              </Typography>
            )}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default memo(Content);

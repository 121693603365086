import React, { memo } from "react";
import { EXPENSE_BASE_HEIGHT } from "../../base";

function RouteContent({ staticMapURL }) {
  return (
    <img
      src={staticMapURL}
      style={{
        height: EXPENSE_BASE_HEIGHT,
        borderTopLeftRadius: 9,
        borderBottomLeftRadius: 9,
      }}
    />
  );
}

export default memo(RouteContent);

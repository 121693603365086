import { PaidOutlined } from "@mui/icons-material";
import { Box, Button, Chip, Divider, Typography } from "@mui/material";
import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import UserListItem from "../../../../../../components/list-items/UserListItem";
import { selectAccountCurrency } from "../../../../../../store/features/accountSlice";
import formatAmount from "../../../../../../utils/formatAmount";
import { formatMoment } from "../../../../../../utils/more/date_functions";
import PaperSection from "./PaperSection";

const AdvanceSection = ({
  advance = null,
  rejectedEvent = {},
  openAdvanceModal,
  requestStatus,
}) => {
  const currency = useSelector((state) => selectAccountCurrency(state, true));

  const rejectedByUser = useMemo(() => {
    return (
      rejectedEvent?.approvers?.find((ap) => ap.status === "R") || {
        name: "",
        lastname: "",
        _id: "",
      }
    );
  }, [rejectedEvent]);

  return (
    <PaperSection
      title={"Adiantamento"}
      Icon={PaidOutlined}
      leftContent={
        advance && (
          <Chip
            variant="outlined"
            sx={{ fontWeight: "600", mt: 0.3 }}
            color={
              advance.status === "pending"
                ? "warning"
                : advance.status === "rejected"
                ? "error"
                : advance.status === "approved"
                ? "success"
                : "primary"
            }
            size="small"
            label={
              advance.status === "pending"
                ? "Aguardando aprovação"
                : advance.status === "rejected"
                ? "Rejeitado"
                : advance.status === "approved"
                ? "Aprovado"
                : advance.status === "paid" && "Pago"
            }
          />
        )
      }
      rightContent={
        <>
          {advance && requestStatus === "O" && (
            <>
              {advance.status === "rejected" && (
                <Button onClick={openAdvanceModal} sx={{ px: 1, ml: 1 }}>
                  Solicitar novamente
                </Button>
              )}
            </>
          )}
        </>
      }
    >
      {!advance ? (
        <Box display={"flex"} alignItems={"flex-start"}>
          <Typography variant="body1" color={"text.secondary"}>
            Nenhum valor solicitado
          </Typography>
          <Box flex={1}/>
          {requestStatus === "O" && (
            <Button variant="outlined" onClick={openAdvanceModal}>
              Solicitar
            </Button>
          )}
        </Box>
      ) : (
        <Box mt={1}>
          <Typography
            variant="body2"
            color={"text.secondary"}
            fontWeight={"500"}
            mb={0.3}
          >
            Valor desejado
          </Typography>
          <Typography variant="body1" fontWeight={"500"}>
            {currency} {formatAmount(advance?.amount)}
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Typography
            variant="body2"
            color={"text.secondary"}
            fontWeight={"500"}
            mb={0.3}
          >
            Motivo do adiantamento
          </Typography>
          <Typography
            fontStyle={advance?.reason ? "normal" : "italic"}
            variant="body2"
            fontWeight={advance?.reason ? "500" : "400"}
          >
            {advance?.reason || "Nenhum motivo informado"}
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Typography
            variant="body2"
            color={"text.secondary"}
            fontWeight={"500"}
            mb={0.3}
          >
            {advance?.status === "pending"
              ? "Soliciado"
              : advance?.status === "rejected"
              ? "Rejeitado"
              : advance?.status === "paid"
              ? "Pago"
              : advance?.status === "approved"
              ? "Aprovado"
              : ""}
            :{" "}
            {formatMoment(
              advance?.status === "pending"
                ? advance?.created_at
                : advance?.status === "rejected"
                ? advance?.rejected_at
                : advance?.status === "paid"
                ? advance?.paid_at
                : advance?.status === "approved"
                ? advance?.approved_at
                : ""
            )}
          </Typography>
          {advance?.status === "rejected" && rejectedByUser && (
            <>
              <UserListItem
                id={rejectedByUser?._id}
                name={rejectedByUser?.name}
                lastname={rejectedByUser?.lastname}
                email={rejectedByUser?.email}
              />
              <Divider sx={{ my: 1 }} />
              <Typography
                variant="body2"
                color={"text.secondary"}
                fontWeight={"500"}
                mb={0.3}
              >
                Motivo da rejeição
              </Typography>
              <Typography
                mt={0.5}
                variant="body2"
                color={
                  rejectedByUser?.comment ? "text.primary" : "text.secondary"
                }
              >
                {rejectedByUser?.comment || "Nenhum motivo informado"}
              </Typography>
            </>
          )}
        </Box>
      )}
    </PaperSection>
  );
};

export default memo(AdvanceSection);

import { Typography } from "@mui/material";
import React, { memo } from "react";
import formatAmount from "../../../../utils/formatAmount";
import { curr } from "../../../../utils/more/currency_country";

const Amount = ({ currency, amount}) => {
  return (
    <Typography noWrap fontSize={".9rem"} fontWeight={"600"}>
      {curr(currency)} {formatAmount(amount)}
    </Typography>
  );
};

export default memo(Amount);

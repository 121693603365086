import React, { memo } from "react";

const NoExpensesInRequestSVG = () => {
  return (
    <svg
      id="Grupo_857"
      data-name="Grupo 857"
      xmlns="http://www.w3.org/2000/svg"
      width="400.974"
      height="270.706"
      viewBox="0 0 1567.974 1200.706"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Retângulo_1583"
            data-name="Retângulo 1583"
            width="1567.974"
            height="1545.706"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-3">
          <rect
            id="Retângulo_1552"
            data-name="Retângulo 1552"
            width="412.192"
            height="55.118"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-4">
          <rect
            id="Retângulo_1558"
            data-name="Retângulo 1558"
            width="207.872"
            height="257.191"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-5">
          <rect
            id="Retângulo_1559"
            data-name="Retângulo 1559"
            width="140.669"
            height="186.627"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-6">
          <rect
            id="Retângulo_1562"
            data-name="Retângulo 1562"
            width="195.828"
            height="249.544"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-7">
          <rect
            id="Retângulo_1563"
            data-name="Retângulo 1563"
            width="134.898"
            height="182.017"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-8">
          <rect
            id="Retângulo_1566"
            data-name="Retângulo 1566"
            width="185.563"
            height="242.68"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-9">
          <rect
            id="Retângulo_1567"
            data-name="Retângulo 1567"
            width="129.91"
            height="177.846"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-10">
          <rect
            id="Retângulo_1570"
            data-name="Retângulo 1570"
            width="177.154"
            height="236.864"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-11">
          <rect
            id="Retângulo_1571"
            data-name="Retângulo 1571"
            width="125.788"
            height="174.284"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-12">
          <rect
            id="Retângulo_1572"
            data-name="Retângulo 1572"
            width="569.049"
            height="415.884"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-13">
          <rect
            id="Retângulo_1573"
            data-name="Retângulo 1573"
            width="40.912"
            height="34.312"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-14">
          <rect
            id="Retângulo_1574"
            data-name="Retângulo 1574"
            width="41.122"
            height="42.058"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-15">
          <rect
            id="Retângulo_1575"
            data-name="Retângulo 1575"
            width="10.974"
            height="10.903"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-16">
          <rect
            id="Retângulo_1576"
            data-name="Retângulo 1576"
            width="18.707"
            height="25.438"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-17">
          <rect
            id="Retângulo_1577"
            data-name="Retângulo 1577"
            width="33.175"
            height="167.224"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-18">
          <rect
            id="Retângulo_1578"
            data-name="Retângulo 1578"
            width="119.323"
            height="21.486"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-19">
          <rect
            id="Retângulo_1579"
            data-name="Retângulo 1579"
            width="170.862"
            height="228.635"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-20">
          <rect
            id="Retângulo_1580"
            data-name="Retângulo 1580"
            width="108.397"
            height="33.013"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-21">
          <rect
            id="Retângulo_1581"
            data-name="Retângulo 1581"
            width="83.398"
            height="30.715"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Grupo_856" data-name="Grupo 856" clipPath="url(#clip-path)">
        <g id="Grupo_855" data-name="Grupo 855">
          <g id="Grupo_854" data-name="Grupo 854" clipPath="url(#clip-path)">
            <path
              id="Caminho_1915"
              data-name="Caminho 1915"
              d="M1567.974,772.857C1550.373,1803.409,17.448,1803.247,0,772.824c17.6-1030.519,1550.521-1030.356,1567.974.033"
              transform="translate(0 -0.002)"
              fill="#fcfdfe"
            />
            <path
              id="Caminho_1916"
              data-name="Caminho 1916"
              d="M327.792,491.63c-6.045-17.357-53.722,6.245-73.067,35.01-12.886,19.155-20.756,39.606-24.432,67.07.443-31.34.939-65.015,1.43-96.794,3.914-7.923,12.848-26.191,19.274-40.664,21.438-48.3,72.424-66.283,90.038-96.779,23.078-39.959,22.945-99.892,3.3-121.521-17.071-18.8-73.61,108.292-79.979,122.494-21.905,48.835-28.27,85.08-32.379,120.191.467-29.847.915-57.249,1.282-78.4,1.2-68.963,2.693-91.144,3.38-97.971,2.36-3.332,5.144-7.218,6.765-9.306,13.682-17.615,44.039-38.581,59.214-64.19,22.148-37.384,18.573-82.134,3.032-98.8-16.247-17.419-44.964,23.755-60.215,64.553s-8.734,79.851-13.01,113.731c-.891,6.646-1.812,31.888-2.674,66.9-4.629-37.675-13.844-62.913-30.915-86.434-21-28.923-39.139-64.152-56.444-59.6s-7.99,51.162,5.7,83.884c12.195,29.142,72.342,58.208,81.2,81.634-1.044,47.462-1.978,107.586-2.641,163.568-4.739-28.918-1.478-31.344-20.914-75.613-11.551-26.32-58.908-58.775-64.557-40.693-5.544,17.748,2.937,52.258,20.814,76.2,14.426,19.307,46.428,19.283,53.831,31.268,1.726,2.794,7.442,13.51,10.7,19.67-.772,68.347-1.12,128.672-.734,149.242l3.1.21c-.148-5.311.224-40.078.834-86.086,7.771-24.48,60.086-38.772,76.247-64.229,16.909-26.644,29.061-57.731,21.81-78.554"
              transform="translate(500.93 481.729)"
              fill="#e6e6e6"
            />
            <path
              id="Caminho_1917"
              data-name="Caminho 1917"
              d="M257.432,293.712H157.025a11.541,11.541,0,0,1-11.308-9.239l-7.127-35.144H275.862l-7.127,35.144a11.535,11.535,0,0,1-11.3,9.239"
              transform="translate(522.098 939.275)"
              fill="#f0f0f0"
            />
            <path
              id="Caminho_1918"
              data-name="Caminho 1918"
              d="M288.637,283.357H145.62a6.729,6.729,0,0,1-6.688-5.978L136,251.4a6.734,6.734,0,0,1,6.693-7.485H291.564a6.733,6.733,0,0,1,6.688,7.485l-2.922,25.981a6.737,6.737,0,0,1-6.693,5.978"
              transform="translate(512.198 918.872)"
              fill="#f0f0f0"
            />
            <rect
              id="Retângulo_1536"
              data-name="Retângulo 1536"
              width="96.145"
              height="410.266"
              transform="translate(1238.831 810.145)"
              fill="#f0f0f0"
            />
            <rect
              id="Retângulo_1537"
              data-name="Retângulo 1537"
              width="498.126"
              height="12.576"
              transform="translate(830.377 1220.411)"
              fill="#f0f0f0"
            />
            <rect
              id="Retângulo_1538"
              data-name="Retângulo 1538"
              width="414.929"
              height="410.266"
              transform="translate(823.903 810.145)"
              fill="#f5f5f5"
            />
            <rect
              id="Retângulo_1539"
              data-name="Retângulo 1539"
              width="339.33"
              height="86.873"
              transform="translate(861.697 971.839)"
              fill="#f0f0f0"
            />
            <rect
              id="Retângulo_1540"
              data-name="Retângulo 1540"
              width="339.33"
              height="86.873"
              transform="translate(861.697 1083.654)"
              fill="#f0f0f0"
            />
            <path
              id="Caminho_1919"
              data-name="Caminho 1919"
              d="M215.765,221.824h76.19a9.377,9.377,0,0,0,0-18.754h-76.19a9.377,9.377,0,0,0,0,18.754"
              transform="translate(777.507 765.008)"
              fill="#f5f5f5"
            />
            <rect
              id="Retângulo_1541"
              data-name="Retângulo 1541"
              width="339.33"
              height="86.873"
              transform="translate(861.697 860.029)"
              fill="#f0f0f0"
            />
            <path
              id="Caminho_1920"
              data-name="Caminho 1920"
              d="M215.765,198.369h76.19a9.377,9.377,0,1,0,0-18.754h-76.19a9.377,9.377,0,0,0,0,18.754"
              transform="translate(777.507 676.648)"
              fill="#f5f5f5"
            />
            <path
              id="Caminho_1921"
              data-name="Caminho 1921"
              d="M215.765,245.278h76.19a9.377,9.377,0,1,0,0-18.754h-76.19a9.377,9.377,0,0,0,0,18.754"
              transform="translate(777.507 853.364)"
              fill="#f5f5f5"
            />
            <rect
              id="Retângulo_1542"
              data-name="Retângulo 1542"
              width="423.462"
              height="11.704"
              transform="translate(815.37 798.441)"
              fill="#f0f0f0"
            />
            <rect
              id="Retângulo_1543"
              data-name="Retângulo 1543"
              width="105.303"
              height="11.704"
              transform="translate(1238.832 798.441)"
              fill="#e6e6e6"
            />
            <rect
              id="Retângulo_1544"
              data-name="Retângulo 1544"
              width="94.987"
              height="614.379"
              transform="translate(474.071 618.608)"
              fill="#e0e0e0"
            />
            <path
              id="Caminho_1922"
              data-name="Caminho 1922"
              d="M48.655,856.224H366.733l33.413-756.213H82.069Z"
              transform="translate(183.295 376.763)"
              fill="#e6e6e6"
            />
            <path
              id="Caminho_1923"
              data-name="Caminho 1923"
              d="M38.922,856.224H390.761l33.413-756.213H72.335Z"
              transform="translate(146.626 376.763)"
              fill="#f0f0f0"
            />
            <path
              id="Caminho_1924"
              data-name="Caminho 1924"
              d="M324.946,789.856l28.146-682.069H75.4L47.251,789.856Z"
              transform="translate(178.003 406.058)"
              fill="#fff"
            />
            <path
              id="Caminho_1925"
              data-name="Caminho 1925"
              d="M103.875,789.856l94.391-682.069H168.652L74.261,789.856Z"
              transform="translate(279.756 406.058)"
              fill="#fafafa"
            />
            <path
              id="Caminho_1926"
              data-name="Caminho 1926"
              d="M111.53,789.856l94.391-682.069h-50.5L61.036,789.856Z"
              transform="translate(229.935 406.058)"
              fill="#fafafa"
            />
            <rect
              id="Retângulo_1545"
              data-name="Retângulo 1545"
              width="384.976"
              height="360.115"
              transform="translate(914.933 322.626)"
              fill="#e6e6e6"
            />
            <rect
              id="Retângulo_1546"
              data-name="Retângulo 1546"
              width="391.34"
              height="360.115"
              transform="translate(902.981 322.626)"
              fill="#f5f5f5"
            />
            <rect
              id="Retângulo_1547"
              data-name="Retângulo 1547"
              width="384.976"
              height="14.74"
              transform="translate(914.933 682.741)"
              fill="#e6e6e6"
            />
            <rect
              id="Retângulo_1548"
              data-name="Retângulo 1548"
              width="365.197"
              height="14.74"
              transform="translate(892.26 682.741)"
              fill="#f5f5f5"
            />
            <rect
              id="Retângulo_1549"
              data-name="Retângulo 1549"
              width="357.889"
              height="315.485"
              transform="translate(919.71 344.941)"
              fill="#fafafa"
            />
            <rect
              id="Retângulo_1550"
              data-name="Retângulo 1550"
              width="2.384"
              height="315.485"
              transform="translate(919.709 344.941)"
              fill="#e6e6e6"
            />
            <path
              id="Caminho_1927"
              data-name="Caminho 1927"
              d="M368.548,387.842,306.665,72.357H225.551l61.878,315.484Z"
              transform="translate(849.699 272.585)"
              fill="#fff"
            />
            <path
              id="Caminho_1928"
              data-name="Caminho 1928"
              d="M313.627,387.842,251.744,72.357H215.112l61.878,315.484Z"
              transform="translate(810.374 272.585)"
              fill="#fff"
            />
            <path
              id="Caminho_1929"
              data-name="Caminho 1929"
              d="M268.512,399.606V66.535a1.848,1.848,0,0,0-1.84-1.845h-.148a1.848,1.848,0,0,0-1.84,1.845V399.606a1.844,1.844,0,0,0,1.84,1.84h.148a1.844,1.844,0,0,0,1.84-1.84"
              transform="translate(997.121 243.701)"
              fill="#ebebeb"
            />
            <g
              id="Grupo_799"
              data-name="Grupo 799"
              transform="translate(894.21 308.391)"
              opacity="0.5"
            >
              <g id="Grupo_798" data-name="Grupo 798">
                <g
                  id="Grupo_797"
                  data-name="Grupo 797"
                  clipPath="url(#clip-path-3)"
                >
                  <rect
                    id="Retângulo_1551"
                    data-name="Retângulo 1551"
                    width="412.192"
                    height="55.118"
                    fill="#e6e6e6"
                  />
                </g>
              </g>
            </g>
            <rect
              id="Retângulo_1553"
              data-name="Retângulo 1553"
              width="412.192"
              height="6.851"
              transform="translate(894.21 308.391)"
              fill="#e0e0e0"
            />
            <path
              id="Caminho_1930"
              data-name="Caminho 1930"
              d="M189.793,70.068H600.626a2.635,2.635,0,0,0,2.627-2.627v-.753a2.635,2.635,0,0,0-2.627-2.627H189.793a2.635,2.635,0,0,0-2.627,2.627v.753a2.635,2.635,0,0,0,2.627,2.627"
              transform="translate(705.094 241.331)"
              fill="#ebebeb"
            />
            <path
              id="Caminho_1931"
              data-name="Caminho 1931"
              d="M188.6,78.821H601.818a1.435,1.435,0,0,0,0-2.87H188.6a1.435,1.435,0,1,0,0,2.87"
              transform="translate(705.094 286.123)"
              fill="#fff"
            />
            <path
              id="Caminho_1932"
              data-name="Caminho 1932"
              d="M1005.04,302.078c0,18.878-204.48,34.181-456.723,34.181S91.6,320.956,91.6,302.078,296.075,267.9,548.318,267.9s456.723,15.3,456.723,34.181"
              transform="translate(345.058 1009.225)"
              fill="#f5f5f5"
            />
            <rect
              id="Retângulo_1554"
              data-name="Retângulo 1554"
              width="3.067"
              height="46.792"
              transform="matrix(0.993, -0.116, 0.116, 0.993, 221.761, 900.328)"
              fill="#047bc4"
            />
            <rect
              id="Retângulo_1555"
              data-name="Retângulo 1555"
              width="3.067"
              height="23.598"
              transform="translate(229.216 964.077) rotate(-6.67)"
              fill="#047bc4"
            />
            <path
              id="Caminho_1933"
              data-name="Caminho 1933"
              d="M258.53,194.149,254.907,184a32.807,32.807,0,0,0-34.71-21.567L75.932,179.3A32.805,32.805,0,0,0,47.157,215.7L88.221,566.783a22.5,22.5,0,0,0,24.961,19.731l516.16-60.372a22.5,22.5,0,0,0,19.731-24.956l-35.5-303.5a22.49,22.49,0,0,0-24.956-19.731L288.888,213.008a28.691,28.691,0,0,1-30.358-18.859"
              transform="translate(176.802 611.061)"
              fill="#047bc4"
            />
            <rect
              id="Retângulo_1556"
              data-name="Retângulo 1556"
              width="166.298"
              height="229.107"
              transform="matrix(0.981, -0.195, 0.195, 0.981, 286.665, 685.034)"
              fill="#047bc4"
            />
            <g
              id="Grupo_802"
              data-name="Grupo 802"
              transform="translate(286.664 652.531)"
              opacity="0.7"
            >
              <g id="Grupo_801" data-name="Grupo 801">
                <g
                  id="Grupo_800"
                  data-name="Grupo 800"
                  clipPath="url(#clip-path-4)"
                >
                  <rect
                    id="Retângulo_1557"
                    data-name="Retângulo 1557"
                    width="166.298"
                    height="229.107"
                    transform="matrix(0.981, -0.195, 0.195, 0.981, 0, 32.502)"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
            <g
              id="Grupo_805"
              data-name="Grupo 805"
              transform="translate(316.757 688.786)"
              opacity="0.4"
            >
              <g id="Grupo_804" data-name="Grupo 804">
                <g
                  id="Grupo_803"
                  data-name="Grupo 803"
                  clipPath="url(#clip-path-5)"
                >
                  <path
                    id="Caminho_1934"
                    data-name="Caminho 1934"
                    d="M177.663,162.3,77.2,182.324a8.995,8.995,0,1,1-3.513-17.643l100.459-20.022a9,9,0,1,1,3.518,17.643"
                    transform="translate(-66.444 -144.482)"
                    fill="#047bc4"
                  />
                  <path
                    id="Caminho_1935"
                    data-name="Caminho 1935"
                    d="M179.216,170.1,78.752,190.117a8.995,8.995,0,1,1-3.513-17.643L175.7,152.452a9,9,0,1,1,3.518,17.643"
                    transform="translate(-60.593 -115.124)"
                    fill="#047bc4"
                  />
                  <path
                    id="Caminho_1936"
                    data-name="Caminho 1936"
                    d="M180.769,177.89,80.305,197.912a8.995,8.995,0,0,1-3.513-17.643l100.459-20.022a9,9,0,1,1,3.518,17.643"
                    transform="translate(-54.743 -85.764)"
                    fill="#047bc4"
                  />
                  <path
                    id="Caminho_1937"
                    data-name="Caminho 1937"
                    d="M182.322,185.682,81.858,205.7a8.995,8.995,0,0,1-3.513-17.643L178.8,168.039a9,9,0,1,1,3.518,17.643"
                    transform="translate(-48.892 -56.405)"
                    fill="#047bc4"
                  />
                  <path
                    id="Caminho_1938"
                    data-name="Caminho 1938"
                    d="M156.417,194.624l-73.005,14.55a9,9,0,0,1-3.518-17.643l73.005-14.55a9,9,0,1,1,3.518,17.643"
                    transform="translate(-43.038 -22.723)"
                    fill="#047bc4"
                  />
                </g>
              </g>
            </g>
            <rect
              id="Retângulo_1560"
              data-name="Retângulo 1560"
              width="166.295"
              height="229.107"
              transform="matrix(0.991, -0.136, 0.136, 0.991, 517.377, 631.747)"
              fill="#047bc4"
            />
            <g
              id="Grupo_808"
              data-name="Grupo 808"
              transform="translate(517.376 609.197)"
              opacity="0.7"
            >
              <g id="Grupo_807" data-name="Grupo 807">
                <g
                  id="Grupo_806"
                  data-name="Grupo 806"
                  clipPath="url(#clip-path-6)"
                >
                  <rect
                    id="Retângulo_1561"
                    data-name="Retângulo 1561"
                    width="166.295"
                    height="229.107"
                    transform="matrix(0.991, -0.136, 0.136, 0.991, 0.001, 22.55)"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
            <g
              id="Grupo_811"
              data-name="Grupo 811"
              transform="translate(545.407 643.945)"
              opacity="0.4"
            >
              <g id="Grupo_810" data-name="Grupo 810">
                <g
                  id="Grupo_809"
                  data-name="Grupo 809"
                  clipPath="url(#clip-path-7)"
                >
                  <path
                    id="Caminho_1939"
                    data-name="Caminho 1939"
                    d="M226.117,152.988,124.623,166.879a9,9,0,1,1-2.436-17.825l101.489-13.892a9,9,0,0,1,2.441,17.825"
                    transform="translate(-114.406 -135.077)"
                    fill="#047bc4"
                  />
                  <path
                    id="Caminho_1940"
                    data-name="Caminho 1940"
                    d="M227.194,160.86,125.7,174.752a9,9,0,1,1-2.436-17.825l101.489-13.892a9,9,0,0,1,2.441,17.825"
                    transform="translate(-110.349 -105.417)"
                    fill="#047bc4"
                  />
                  <path
                    id="Caminho_1941"
                    data-name="Caminho 1941"
                    d="M154.743,170.844l-27.964,3.828a9,9,0,0,1-2.441-17.825l27.964-3.828a9,9,0,1,1,2.441,17.825"
                    transform="translate(-106.289 -67.805)"
                    fill="#047bc4"
                  />
                  <path
                    id="Caminho_1942"
                    data-name="Caminho 1942"
                    d="M166.119,169.287l-27.964,3.828a9,9,0,0,1-2.441-17.825l27.964-3.828a9,9,0,1,1,2.441,17.825"
                    transform="translate(-63.433 -73.671)"
                    fill="#047bc4"
                  />
                  <path
                    id="Caminho_1943"
                    data-name="Caminho 1943"
                    d="M229.35,176.606,127.856,190.5a9,9,0,0,1-2.441-17.825l101.494-13.892a9,9,0,1,1,2.441,17.825"
                    transform="translate(-102.227 -46.099)"
                    fill="#047bc4"
                  />
                  <path
                    id="Caminho_1944"
                    data-name="Caminho 1944"
                    d="M202.688,185.276l-73.754,10.092a8.993,8.993,0,1,1-2.441-17.82l73.754-10.1a9,9,0,0,1,2.441,17.825"
                    transform="translate(-98.167 -13.437)"
                    fill="#047bc4"
                  />
                </g>
              </g>
            </g>
            <rect
              id="Retângulo_1564"
              data-name="Retângulo 1564"
              width="166.298"
              height="229.11"
              transform="translate(360.995 656.801) rotate(-4.981)"
              fill="#047bc4"
            />
            <g
              id="Grupo_814"
              data-name="Grupo 814"
              transform="translate(360.998 642.363)"
              opacity="0.8"
            >
              <g id="Grupo_813" data-name="Grupo 813">
                <g
                  id="Grupo_812"
                  data-name="Grupo 812"
                  clipPath="url(#clip-path-8)"
                >
                  <rect
                    id="Retângulo_1565"
                    data-name="Retângulo 1565"
                    width="166.298"
                    height="229.11"
                    transform="translate(-0.002 14.439) rotate(-4.981)"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
            <g
              id="Grupo_817"
              data-name="Grupo 817"
              transform="translate(387.265 675.771)"
              opacity="0.4"
            >
              <g id="Grupo_816" data-name="Grupo 816">
                <g
                  id="Grupo_815"
                  data-name="Grupo 815"
                  clipPath="url(#clip-path-9)"
                >
                  <path
                    id="Caminho_1945"
                    data-name="Caminho 1945"
                    d="M193.065,159.71l-102.052,8.9A9,9,0,1,1,89.45,150.68L191.5,141.79a8.994,8.994,0,1,1,1.564,17.92"
                    transform="translate(-81.237 -141.751)"
                    fill="#047bc4"
                  />
                  <path
                    id="Caminho_1946"
                    data-name="Caminho 1946"
                    d="M193.755,167.626,91.7,176.521A9,9,0,1,1,90.14,158.6l102.052-8.891a8.994,8.994,0,1,1,1.564,17.92"
                    transform="translate(-78.637 -111.93)"
                    fill="#047bc4"
                  />
                  <path
                    id="Caminho_1947"
                    data-name="Caminho 1947"
                    d="M194.445,175.543l-102.052,8.9a9,9,0,0,1-1.564-17.925l102.052-8.891a8.994,8.994,0,1,1,1.564,17.92"
                    transform="translate(-76.038 -82.105)"
                    fill="#047bc4"
                  />
                  <path
                    id="Caminho_1948"
                    data-name="Caminho 1948"
                    d="M195.135,183.459l-102.052,8.9a9,9,0,1,1-1.564-17.925l102.052-8.891a8.994,8.994,0,1,1,1.564,17.92"
                    transform="translate(-73.438 -52.284)"
                    fill="#047bc4"
                  />
                  <path
                    id="Caminho_1949"
                    data-name="Caminho 1949"
                    d="M167.932,191.886,93.773,198.35a9,9,0,1,1-1.564-17.925l74.159-6.46a8.994,8.994,0,1,1,1.564,17.92"
                    transform="translate(-70.839 -20.538)"
                    fill="#047bc4"
                  />
                </g>
              </g>
            </g>
            <rect
              id="Retângulo_1568"
              data-name="Retângulo 1568"
              width="166.297"
              height="229.107"
              transform="matrix(0.999, -0.048, 0.048, 0.999, 562.473, 639.248)"
              fill="#047bc4"
            />
            <g
              id="Grupo_820"
              data-name="Grupo 820"
              transform="translate(562.469 631.227)"
              opacity="0.8"
            >
              <g id="Grupo_819" data-name="Grupo 819">
                <g
                  id="Grupo_818"
                  data-name="Grupo 818"
                  clipPath="url(#clip-path-10)"
                >
                  <rect
                    id="Retângulo_1569"
                    data-name="Retângulo 1569"
                    width="166.297"
                    height="229.107"
                    transform="matrix(0.999, -0.048, 0.048, 0.999, 0.004, 8.021)"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
            <g
              id="Grupo_823"
              data-name="Grupo 823"
              transform="translate(587.287 663.51)"
              opacity="0.4"
            >
              <g id="Grupo_822" data-name="Grupo 822">
                <g
                  id="Grupo_821"
                  data-name="Grupo 821"
                  clipPath="url(#clip-path-11)"
                >
                  <path
                    id="Caminho_1950"
                    data-name="Caminho 1950"
                    d="M234.942,157.16,132.623,162.1a8.994,8.994,0,1,1-.868-17.968l102.319-4.939a8.994,8.994,0,1,1,.868,17.968"
                    transform="translate(-123.194 -139.183)"
                    fill="#047bc4"
                  />
                  <path
                    id="Caminho_1951"
                    data-name="Caminho 1951"
                    d="M235.325,165.1l-102.319,4.939a8.994,8.994,0,1,1-.868-17.968l102.319-4.939a8.994,8.994,0,1,1,.868,17.968"
                    transform="translate(-121.751 -109.282)"
                    fill="#047bc4"
                  />
                  <path
                    id="Caminho_1952"
                    data-name="Caminho 1952"
                    d="M161.583,173.786l-28.193,1.363a9,9,0,0,1-.868-17.972l28.193-1.359a8.994,8.994,0,1,1,.868,17.968"
                    transform="translate(-120.308 -76.549)"
                    fill="#047bc4"
                  />
                  <path
                    id="Caminho_1953"
                    data-name="Caminho 1953"
                    d="M173.051,173.232,144.857,174.6a9,9,0,1,1-.868-17.972l28.193-1.359a8.994,8.994,0,1,1,.868,17.968"
                    transform="translate(-77.106 -78.636)"
                    fill="#047bc4"
                  />
                  <path
                    id="Caminho_1954"
                    data-name="Caminho 1954"
                    d="M236.091,180.972l-102.319,4.939a8.994,8.994,0,1,1-.868-17.968L235.223,163a8.994,8.994,0,1,1,.868,17.968"
                    transform="translate(-118.865 -49.478)"
                    fill="#047bc4"
                  />
                  <path
                    id="Caminho_1955"
                    data-name="Caminho 1955"
                    d="M208.51,189.193l-74.354,3.59a8.994,8.994,0,1,1-.868-17.968l74.354-3.59a8.994,8.994,0,1,1,.868,17.968"
                    transform="translate(-117.419 -18.508)"
                    fill="#047bc4"
                  />
                </g>
              </g>
            </g>
            <path
              id="Caminho_1956"
              data-name="Caminho 1956"
              d="M267.79,194.918l-2.646-9.935c-3.747-14.082-17.162-22.806-32.336-21.028L88.538,180.826c-18,2.107-32.245,18.092-31.826,35.706L64.9,560.191c.286,12.08,10.526,20.7,22.868,19.259l516.16-60.367c12.342-1.444,22.11-12.409,21.824-24.489l-7.079-297.078c-.286-12.08-10.521-20.7-22.864-19.26L296.079,213.31c-13.272,1.554-25-6.073-28.289-18.392"
              transform="translate(213.613 616.79)"
              fill="#047bc4"
            />
            <g
              id="Grupo_826"
              data-name="Grupo 826"
              transform="translate(270.319 780.516)"
              opacity="0.3"
            >
              <g id="Grupo_825" data-name="Grupo 825">
                <g
                  id="Grupo_824"
                  data-name="Grupo 824"
                  clipPath="url(#clip-path-12)"
                >
                  <path
                    id="Caminho_1957"
                    data-name="Caminho 1957"
                    d="M267.79,194.918l-2.646-9.935c-3.747-14.082-17.162-22.806-32.336-21.028L88.538,180.826c-18,2.107-32.245,18.092-31.826,35.706L64.9,560.191c.286,12.08,10.526,20.7,22.868,19.259l516.16-60.367c12.342-1.444,22.11-12.409,21.824-24.489l-7.079-297.078c-.286-12.08-10.521-20.7-22.864-19.26L296.079,213.31c-13.272,1.554-25-6.073-28.289-18.392"
                    transform="translate(-56.706 -163.726)"
                  />
                </g>
              </g>
            </g>
            <path
              id="Caminho_1958"
              data-name="Caminho 1958"
              d="M245.705,133.255c-2.231,11.217-4.619,22.244-7.294,33.275-2.627,11.027-5.32,22.029-8.247,32.98l-4.457,16.409-.586,2.1c-.267.9-.567,1.812-.92,2.746a27.534,27.534,0,0,1-2.956,5.83,20.778,20.778,0,0,1-5.635,5.73,18.057,18.057,0,0,1-8.243,2.975,23.653,23.653,0,0,1-12.943-2.86,46.086,46.086,0,0,1-8.39-5.8,75.179,75.179,0,0,1-12.285-13.853l8.4-7.547a103.465,103.465,0,0,0,12.972,8.61,31.032,31.032,0,0,0,6.212,2.693c1.854.6,3.471.52,3.49.214.043-.114-.3-.114-.453-.1-.172,0-.138-.076,0-.505.133-.41.353-1.054.548-1.859l.691-3.318,3.523-16.495,7.094-32.965c2.3-10.984,4.763-22,7.156-32.87Z"
              transform="translate(654.549 484.633)"
              fill="#ffc3bd"
            />
            <path
              id="Caminho_1959"
              data-name="Caminho 1959"
              d="M206.263,125.583c-25.752,19.712-24.718,56.863-24.718,56.863l46.061,11.008s14.13-37.351,8.4-51.023c-5.983-14.273-15.25-27.941-29.743-16.847"
              transform="translate(683.899 457.814)"
              fill="#263238"
            />
            <path
              id="Caminho_1960"
              data-name="Caminho 1960"
              d="M196.605,158.216l-16.933-16.933-9.134,26.491s14.3,7.718,27.979.443Z"
              transform="translate(642.453 532.243)"
              fill="#ffc3bd"
            />
            <path
              id="Caminho_1961"
              data-name="Caminho 1961"
              d="M170,140.735l-3.113,20.933,17.415,8.171,9.134-26.491Z"
              transform="translate(628.692 530.178)"
              fill="#ffc3bd"
            />
            <path
              id="Caminho_1962"
              data-name="Caminho 1962"
              d="M259.8,318.115l-25.905,2.531-26.949-61.654,25.9-2.531Z"
              transform="translate(779.598 966.142)"
              fill="#ffc3bd"
            />
            <path
              id="Caminho_1963"
              data-name="Caminho 1963"
              d="M301.6,286.752,282.141,305.02l-42-46.161L259.6,240.591Z"
              transform="translate(904.663 906.359)"
              fill="#ffc3bd"
            />
            <path
              id="Caminho_1964"
              data-name="Caminho 1964"
              d="M250.364,273.972l14.621-26.339a2.121,2.121,0,0,1,2.641-.982l22.506,8.562a4.313,4.313,0,0,1,2.083,6.073c-5.258,9.1-8.252,13.224-14.683,24.809-3.957,7.127-11.656,23.469-17.119,33.313-5.344,9.625-15.484,4.963-14.092.376,6.25-20.571,5.072-32.016,3.308-41.093a7,7,0,0,1,.734-4.72"
              transform="translate(927.456 928.643)"
              fill="#263238"
            />
            <path
              id="Caminho_1965"
              data-name="Caminho 1965"
              d="M249.4,268.246H281.21a2.638,2.638,0,0,1,2.512,1.936l6.917,25.166a3.961,3.961,0,0,1-3.88,5.039c-11.213.1-16.523.047-30.515.047H213.487c-11.622,0-14.054-11.751-9.244-12.814,21.562-4.772,33.995-11.355,40.65-17.634a6.547,6.547,0,0,1,4.505-1.74"
              transform="translate(760.654 1010.539)"
              fill="#263238"
            />
            <g
              id="Grupo_829"
              data-name="Grupo 829"
              transform="translate(986.551 1222.62)"
              opacity="0.2"
            >
              <g id="Grupo_828" data-name="Grupo 828">
                <g
                  id="Grupo_827"
                  data-name="Grupo 827"
                  clipPath="url(#clip-path-13)"
                >
                  <path
                    id="Caminho_1966"
                    data-name="Caminho 1966"
                    d="M206.945,259l13.887,31.778h27.021l-14.993-34.31Z"
                    transform="translate(-206.944 -256.463)"
                  />
                </g>
              </g>
            </g>
            <g
              id="Grupo_832"
              data-name="Grupo 832"
              transform="translate(1144.813 1146.96)"
              opacity="0.2"
            >
              <g id="Grupo_831" data-name="Grupo 831">
                <g
                  id="Grupo_830"
                  data-name="Grupo 830"
                  clipPath="url(#clip-path-14)"
                >
                  <path
                    id="Caminho_1967"
                    data-name="Caminho 1967"
                    d="M259.607,240.593l-19.465,18.273L261.8,282.65l19.46-18.273Z"
                    transform="translate(-240.146 -240.593)"
                  />
                </g>
              </g>
            </g>
            <path
              id="Caminho_1968"
              data-name="Caminho 1968"
              d="M189.64,133.938S178,139.058,217.052,293.611c43.053-3.719,87.55-7.57,108.5-9.382-5.64-44-8.047-71.232,5.22-163.677a321.949,321.949,0,0,0-46.6-2.093c-18.335.706-33.342,1.416-49.269,4.262-20.995,3.761-45.265,11.217-45.265,11.217"
              transform="translate(708.297 445.64)"
              fill="#263238"
            />
            <path
              id="Caminho_1969"
              data-name="Caminho 1969"
              d="M238.814,118.171s6.154.11,13.424.11c4.367,6.746-9.835,36.216-33.323,55.524a59.31,59.31,0,0,0-18.859-27.34Z"
              transform="translate(753.657 445.175)"
              fill="#fff"
            />
            <path
              id="Caminho_1970"
              data-name="Caminho 1970"
              d="M210.04,119.142s-2.741.391-9.31,1.888c-4.367,6.746-11.765,35.5,1.116,50.175a57.88,57.88,0,0,1,18.692-28.4Z"
              transform="translate(733.176 448.833)"
              fill="#fff"
            />
            <path
              id="Caminho_1971"
              data-name="Caminho 1971"
              d="M238.9,106.781c-1.735,16.123-2.326,45.436,9.682,55.057,0,0-12.829,13.086-38.757,27.535-18.869-13.038-10.512-23.273-10.512-23.273,17.31-5.749,15.717-18.826,11.575-30.877Z"
              transform="translate(743.893 402.267)"
              fill="#ffc3bd"
            />
            <path
              id="Caminho_1972"
              data-name="Caminho 1972"
              d="M199.965,135.519s15.913-17.324,24.122-14,1.874,9.267,1.139,14.84,3.194,20.442-7.823,16.58-17.439-17.424-17.439-17.424"
              transform="translate(753.31 456.202)"
              fill="#047bc4"
            />
            <path
              id="Caminho_1973"
              data-name="Caminho 1973"
              d="M215.849,133.279s-7.5-13.291-15.622-11.518-3.289,6.445-2.569,11.437c.925,6.426-5.845,18.716,3.947,15.246,9.53-3.375,14.245-15.164,14.245-15.164"
              transform="translate(736.782 458.089)"
              fill="#047bc4"
            />
            <g
              id="Grupo_835"
              data-name="Grupo 835"
              transform="translate(948.499 586.038)"
              opacity="0.2"
            >
              <g id="Grupo_834" data-name="Grupo 834">
                <g
                  id="Grupo_833"
                  data-name="Grupo 833"
                  clipPath="url(#clip-path-15)"
                >
                  <path
                    id="Caminho_1974"
                    data-name="Caminho 1974"
                    d="M198.966,125.381c.081,3.714.62,6.274,1.516,7.8a34.873,34.873,0,0,0,2.6-4.915,36.012,36.012,0,0,0-3.833-5.339,6.664,6.664,0,0,0-.286,2.45"
                    transform="translate(-198.961 -122.931)"
                  />
                  <path
                    id="Caminho_1975"
                    data-name="Caminho 1975"
                    d="M205.745,122.948c-3.4,3-5.778,5.592-5.778,5.592a43.787,43.787,0,0,0,3.1,5.23c1.349-1.139,2.45-3.232,2.941-6.86a6.964,6.964,0,0,0-.267-3.962"
                    transform="translate(-195.181 -122.867)"
                  />
                </g>
              </g>
            </g>
            <path
              id="Caminho_1976"
              data-name="Caminho 1976"
              d="M202.929,134.578c-2.245.01-3.633-2.46-3.8-8.29s4.324-2.6,4.324-2.6,5.959-3.118,4.953,3.976c-.787,5.516-3.156,6.9-5.478,6.912"
              transform="translate(750.146 462.592)"
              fill="#047bc4"
            />
            <g
              id="Grupo_838"
              data-name="Grupo 838"
              transform="translate(954.791 520.651)"
              opacity="0.2"
            >
              <g id="Grupo_837" data-name="Grupo 837">
                <g
                  id="Grupo_836"
                  data-name="Grupo 836"
                  clipPath="url(#clip-path-16)"
                >
                  <path
                    id="Caminho_1977"
                    data-name="Caminho 1977"
                    d="M216.863,109.215l-16.58,16.823a50.668,50.668,0,0,1,2.274,8.614c6.669-1.564,16.323-8.49,16.428-15.627a28.551,28.551,0,0,0-2.121-9.811"
                    transform="translate(-200.283 -109.215)"
                  />
                </g>
              </g>
            </g>
            <path
              id="Caminho_1978"
              data-name="Caminho 1978"
              d="M226.722,106.124s-18.053,25.09-21.042,36.35c-12.318,7.17-14.807-28.174-8.271-49.975,20.237,12.614,29.314,13.625,29.314,13.625"
              transform="translate(730.444 348.463)"
              fill="#263238"
            />
            <path
              id="Caminho_1979"
              data-name="Caminho 1979"
              d="M272.268,137.77c-6.727,25.748-9.02,36.793-24.813,47.582-23.76,16.232-53.917,2.341-55.023-24.9-.992-24.518,10.173-62.508,37.723-67.866,27.144-5.277,48.84,19.436,42.114,45.184"
              transform="translate(724.704 346.088)"
              fill="#ffc3bd"
            />
            <path
              id="Caminho_1980"
              data-name="Caminho 1980"
              d="M232.295,110.675s-12.938,20.957-14.678,30.076c-9.387,6.388-13.358-21.562-9.387-39.258,4.314-15.613,24.065,9.182,24.065,9.182"
              transform="translate(778.678 362.909)"
              fill="#263238"
            />
            <path
              id="Caminho_1981"
              data-name="Caminho 1981"
              d="M239.334,150.406c-3.728,2.836-7.7-7.642-3.966-15.665-7.16.443-15.765-3.8-13.93-10.7-14.087,4.72-19.317-9.62-17.691-19.541,1.964-11.994,12.481-14.64,18.659-11.475.124-5.392,13.639-16.671,18.826-7.852,9.635-3.4,25.147-2.35,24.837,9.477,6.364.2,25.152,7.4,18.23,24.3,8.286.491,12.781,11.036,8.166,18.993,10.626,6.231,6.688,17.048,2.641,20.837,3.823,7.966-7.809,19.817-13.973,18.392-4.715,7.232-11.937,12.662-16.661,6.493-9.167,6.684-19.441-1.788-17.114-8.319-10.393-1.711-20.88-18.85-8.023-24.942"
              transform="translate(766.481 308.576)"
              fill="#263238"
            />
            <path
              id="Caminho_1982"
              data-name="Caminho 1982"
              d="M239.272,101.944c6.76,11.284,6.2,24.055-1.254,28.522S219.04,129.4,212.275,118.12s-6.2-24.055,1.254-28.522,18.983,1.063,25.743,12.347"
              transform="translate(781.889 331.7)"
              fill="#047bc4"
            />
            <path
              id="Caminho_1983"
              data-name="Caminho 1983"
              d="M238.457,126.028c-.41,3.242-9.315,2.4-11.885-3.3-3.666,3.861-12.333,5.859-15.293,1.664-5,9.4-15.045,6.936-17.672,2.417s-4.324-12.852.882-14.5c-3.032-2.612-2.117-14.831,5.8-13.3C203.614,92.5,212.714,85.1,219.35,90.86c3.6-3.146,18.039-9.3,23.97,2.226,4.824-3.985,13.353-1.282,15.4,4.829,9.406-2.455,13.472,4.667,13.434,8.519,6.679,1.826,7.122,13.358,2.922,15.822,1.578,5.821.744,12.071-5.392,11.551-1.177,7.833-11.68,9.048-14.163,4.777-6.679,4.476-20.609-3.127-17.067-12.557"
              transform="translate(719.919 325.403)"
              fill="#263238"
            />
            <path
              id="Caminho_1984"
              data-name="Caminho 1984"
              d="M197.7,97.561c7.647-10.578,35.487-14.964,59.6.882,21.738,14.283,20.327,47.429,3.051,45.665s-34.381-22.039-34.381-22.039Z"
              transform="translate(744.793 331.702)"
              fill="#263238"
            />
            <path
              id="Caminho_1985"
              data-name="Caminho 1985"
              d="M225.459,116.866a19.451,19.451,0,0,1-11.437,9.482c-6.526,1.964-9.925-3.842-8.228-10.078,1.54-5.611,6.779-13.4,13.386-12.628,6.512.748,9.172,7.618,6.278,13.224"
              transform="translate(773.639 390.242)"
              fill="#ffc3bd"
            />
            <path
              id="Caminho_1986"
              data-name="Caminho 1986"
              d="M210.415,160.5S173.7,322.9,198.211,437.387c16.414,76.681,75.608,231.582,75.608,231.582l46.275-1.187S275.287,505.224,266.024,441.3C250.178,331.941,289.87,153.628,289.87,153.628Z"
              transform="translate(714.934 578.749)"
              fill="#047bc4"
            />
            <g
              id="Grupo_841"
              data-name="Grupo 841"
              transform="translate(957.642 772.045)"
              opacity="0.2"
            >
              <g id="Grupo_840" data-name="Grupo 840">
                <g
                  id="Grupo_839"
                  data-name="Grupo 839"
                  clipPath="url(#clip-path-17)"
                >
                  <path
                    id="Caminho_1987"
                    data-name="Caminho 1987"
                    d="M210.917,161.949l23.14,36.5c-5.888,37.842-11.746,85.181-13.239,130.727-32.546-58.26-17.138-136.38-9.9-167.224"
                    transform="translate(-200.883 -161.949)"
                  />
                </g>
              </g>
            </g>
            <path
              id="Caminho_1988"
              data-name="Caminho 1988"
              d="M209.567,277.267l57.168-1.459-3.3-17.567L205.1,261.006Z"
              transform="translate(772.657 972.85)"
              fill="#263238"
            />
            <path
              id="Caminho_1989"
              data-name="Caminho 1989"
              d="M199.083,160.758s21.538,172.22,68.219,266.249c37.709,75.942,138.325,184.053,138.325,184.053l25.724-26.506S363.39,467.061,335.368,416.614c-68.715-123.7-12.557-218.1-51.505-263.512Z"
              transform="translate(749.988 576.768)"
              fill="#047bc4"
            />
            <path
              id="Caminho_1990"
              data-name="Caminho 1990"
              d="M252.154,287.325l33.2-32.36-11.012-14.988L239.13,275.116Z"
              transform="translate(900.854 904.046)"
              fill="#263238"
            />
            <path
              id="Caminho_1991"
              data-name="Caminho 1991"
              d="M306.759,152.27l5.735,9.286c.443.725-.381,1.568-1.64,1.678l-113.789,9.839c-.977.086-1.831-.324-1.945-.934l-1.816-9.625c-.129-.667.672-1.311,1.74-1.406l109.879-9.5a1.974,1.974,0,0,1,1.835.663"
              transform="translate(728.165 571.093)"
              fill="#047bc4"
            />
            <g
              id="Grupo_844"
              data-name="Grupo 844"
              transform="translate(921.454 722.69)"
              opacity="0.5"
            >
              <g id="Grupo_843" data-name="Grupo 843">
                <g
                  id="Grupo_842"
                  data-name="Grupo 842"
                  clipPath="url(#clip-path-18)"
                >
                  <path
                    id="Caminho_1992"
                    data-name="Caminho 1992"
                    d="M306.759,152.27l5.735,9.286c.443.725-.381,1.568-1.64,1.678l-113.789,9.839c-.977.086-1.831-.324-1.945-.934l-1.816-9.625c-.129-.667.672-1.311,1.74-1.406l109.879-9.5a1.974,1.974,0,0,1,1.835.663"
                    transform="translate(-193.289 -151.597)"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
            <path
              id="Caminho_1993"
              data-name="Caminho 1993"
              d="M217.124,165.716l2.941-.253c.586-.052,1.006-.4.934-.758l-2.469-12.5c-.072-.367-.61-.624-1.2-.572l-2.946.253c-.582.052-1.006.391-.93.758l2.469,12.5c.071.367.61.625,1.2.572"
              transform="translate(804.108 571.209)"
              fill="#047bc4"
            />
            <path
              id="Caminho_1994"
              data-name="Caminho 1994"
              d="M198.743,167.3l2.941-.253c.586-.052,1.006-.4.934-.758l-2.469-12.5c-.071-.367-.61-.625-1.2-.572l-2.946.253c-.586.052-1.006.391-.934.758l2.474,12.5c.072.367.61.625,1.2.572"
              transform="translate(734.849 577.196)"
              fill="#047bc4"
            />
            <path
              id="Caminho_1995"
              data-name="Caminho 1995"
              d="M207.932,166.511l2.941-.253c.586-.052,1.006-.4.934-.758L209.338,153c-.071-.367-.61-.624-1.2-.572l-2.946.253c-.586.052-1.006.391-.93.758l2.469,12.5c.072.362.61.625,1.2.572"
              transform="translate(769.48 574.204)"
              fill="#047bc4"
            />
            <path
              id="Caminho_1996"
              data-name="Caminho 1996"
              d="M204.048,104.765c-.42,2.121-1.878,3.623-3.266,3.347s-2.179-2.212-1.759-4.333,1.883-3.623,3.27-3.351,2.174,2.217,1.754,4.338"
              transform="translate(749.338 378.211)"
              fill="#263238"
            />
            <path
              id="Caminho_1997"
              data-name="Caminho 1997"
              d="M199.015,103.776c-.419,2.121-1.878,3.623-3.266,3.347s-2.179-2.212-1.759-4.333,1.883-3.623,3.27-3.351,2.174,2.217,1.754,4.338"
              transform="translate(730.381 374.485)"
              fill="#263238"
            />
            <path
              id="Caminho_1998"
              data-name="Caminho 1998"
              d="M198.512,101.414l-4.81-2.469s1.835,4.5,4.81,2.469"
              transform="translate(729.716 372.747)"
              fill="#263238"
            />
            <path
              id="Caminho_1999"
              data-name="Caminho 1999"
              d="M204.769,101.432s-5.4,8.433-10.23,12.061c2.288,3.161,7.289,2.908,7.289,2.908Z"
              transform="translate(732.87 382.116)"
              fill="#ed847e"
            />
            <path
              id="Caminho_2000"
              data-name="Caminho 2000"
              d="M201.6,111.039a17.575,17.575,0,0,0,12.09-4.6.528.528,0,1,0-.753-.739,17.29,17.29,0,0,1-15.1,3.909.527.527,0,1,0-.2,1.035,20.54,20.54,0,0,0,3.966.4"
              transform="translate(742.916 397.594)"
              fill="#263238"
            />
            <path
              id="Caminho_2001"
              data-name="Caminho 2001"
              d="M210.715,105.738a1.032,1.032,0,0,0,.543-.153,1.051,1.051,0,0,0,.358-1.449,12.355,12.355,0,0,0-9.563-6.183,1.054,1.054,0,0,0-.076,2.107,10.27,10.27,0,0,1,7.833,5.168,1.058,1.058,0,0,0,.906.51"
              transform="translate(757.077 369.01)"
              fill="#263238"
            />
            <path
              id="Caminho_2002"
              data-name="Caminho 2002"
              d="M194.4,100.722a1.078,1.078,0,0,0,.515-.133,10.326,10.326,0,0,1,9.358-.615,1.054,1.054,0,0,0,.977-1.869,12.356,12.356,0,0,0-11.365.639,1.056,1.056,0,0,0,.515,1.978"
              transform="translate(728.341 365.526)"
              fill="#263238"
            />
            <path
              id="Caminho_2003"
              data-name="Caminho 2003"
              d="M272.081,123.947c2.045,2,3.461,3.547,5.072,5.335q2.31,2.624,4.457,5.315,4.326,5.377,8.19,11.041a182.534,182.534,0,0,1,13.506,23.874,156.088,156.088,0,0,1,9.406,25.867c1.158,4.457,2.217,8.938,2.975,13.491,1.049,4.777,1.454,12.514-2.059,19.083a24.983,24.983,0,0,1-6.75,8.033,32.926,32.926,0,0,1-7.885,4.529,56.629,56.629,0,0,1-15,3.566,125.911,125.911,0,0,1-27.555-.358,281.479,281.479,0,0,1-51.619-10.969l2.76-10.945c8.214,1.349,16.671,2.627,25.018,3.485,8.357.968,16.719,1.659,24.966,1.84,8.219.114,16.418.014,23.86-1.306a37.79,37.79,0,0,0,9.839-2.937c2.708-1.33,4.119-2.856,4.448-3.938a6.1,6.1,0,0,0,.372-2.188,16.162,16.162,0,0,0-.114-1.621c-.129-.625-.2-1.173-.477-2.279-.844-3.885-1.959-7.713-3.151-11.513a165.653,165.653,0,0,0-21.5-42.738c-2.26-3.313-4.639-6.541-7.132-9.649-1.244-1.559-2.517-3.084-3.809-4.562-1.22-1.435-2.689-3.065-3.685-4.1Z"
              transform="translate(771.6 466.935)"
              fill="#ffc3bd"
            />
            <path
              id="Caminho_2004"
              data-name="Caminho 2004"
              d="M224.311,153.683,203.15,142.471l-.949,28.007s14.945.944,23.722-5.93Z"
              transform="translate(761.734 536.718)"
              fill="#ffc3bd"
            />
            <path
              id="Caminho_2005"
              data-name="Caminho 2005"
              d="M320.28,357.081l17.467-189.606-37.284-28.546L186.645,128.44l-19.76,214.51Z"
              transform="translate(628.692 483.862)"
              fill="#047bc4"
            />
            <g
              id="Grupo_847"
              data-name="Grupo 847"
              transform="translate(795.576 612.305)"
              opacity="0.8"
            >
              <g id="Grupo_846" data-name="Grupo 846">
                <g
                  id="Grupo_845"
                  data-name="Grupo 845"
                  clipPath="url(#clip-path-19)"
                >
                  <path
                    id="Caminho_2006"
                    data-name="Caminho 2006"
                    d="M320.28,357.081l17.467-189.606-37.284-28.546L186.645,128.44l-19.76,214.51Z"
                    transform="translate(-166.884 -128.444)"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
            <path
              id="Caminho_2007"
              data-name="Caminho 2007"
              d="M232.189,159.187,194.905,130.64l9.773,34.167Z"
              transform="translate(734.249 492.15)"
              fill="#047bc4"
            />
            <path
              id="Caminho_2008"
              data-name="Caminho 2008"
              d="M252.58,212.791a46.48,46.48,0,1,1-40.745-51.581,46.48,46.48,0,0,1,40.745,51.581"
              transform="translate(602.496 606.102)"
              fill="#047bc4"
            />
            <path
              id="Caminho_2009"
              data-name="Caminho 2009"
              d="M235.793,204.255a37.067,37.067,0,1,1-32.5-41.136,37.072,37.072,0,0,1,32.5,41.136"
              transform="translate(609.939 613.541)"
              fill="#fff"
            />
            <path
              id="Caminho_2010"
              data-name="Caminho 2010"
              d="M210.691,185.485l-16.356-1.921,1.921-16.352-11.727-1.378-1.916,16.356-16.356-1.921L164.878,192l16.356,1.916-1.921,16.356,11.727,1.378,1.916-16.356,16.356,1.916Z"
              transform="translate(621.13 624.734)"
              fill="#047bc4"
            />
            <g
              id="Grupo_850"
              data-name="Grupo 850"
              transform="translate(830.258 669.145)"
              opacity="0.4"
            >
              <g id="Grupo_849" data-name="Grupo 849">
                <g
                  id="Grupo_848"
                  data-name="Grupo 848"
                  clipPath="url(#clip-path-20)"
                >
                  <path
                    id="Caminho_2011"
                    data-name="Caminho 2011"
                    d="M282.5,161.851h0a12.691,12.691,0,0,1-13.8,11.475l-83.016-7.637a12.689,12.689,0,0,1,2.326-25.271l83.012,7.637a12.687,12.687,0,0,1,11.475,13.8"
                    transform="translate(-174.158 -140.366)"
                    fill="#047bc4"
                  />
                </g>
              </g>
            </g>
            <g
              id="Grupo_853"
              data-name="Grupo 853"
              transform="translate(826.978 705.705)"
              opacity="0.4"
            >
              <g id="Grupo_852" data-name="Grupo 852">
                <g
                  id="Grupo_851"
                  data-name="Grupo 851"
                  clipPath="url(#clip-path-21)"
                >
                  <path
                    id="Caminho_2012"
                    data-name="Caminho 2012"
                    d="M256.81,167.222h0a12.684,12.684,0,0,1-13.8,11.47L185,173.357a12.689,12.689,0,0,1,2.326-25.271l58.017,5.339a12.684,12.684,0,0,1,11.47,13.8"
                    transform="translate(-173.47 -148.034)"
                    fill="#047bc4"
                  />
                </g>
              </g>
            </g>
            <path
              id="Caminho_2013"
              data-name="Caminho 2013"
              d="M199.694,144.145l3.175,20.923,8.776,5.411.949-28.007Z"
              transform="translate(752.29 536.721)"
              fill="#ffc3bd"
            />
            <path
              id="Caminho_2014"
              data-name="Caminho 2014"
              d="M239.211,119.116c13.3,2.35,31.94,40.5,31.94,40.5l-33.532,30.329s-26.668-29.089-25.242-46.423c1.206-14.712,9.892-27.4,26.835-24.408"
              transform="translate(799.86 447.072)"
              fill="#263238"
            />
            <path
              id="Caminho_2015"
              data-name="Caminho 2015"
              d="M248.551,264.1a3.3,3.3,0,0,0,1.039-.172c3.18-1.073,5.268-6.96,6.226-10.374a.526.526,0,0,0-.753-.61c-.968.505-9.468,5.049-9.744,8.4a2.062,2.062,0,0,0,.906,1.878,3.867,3.867,0,0,0,2.326.872m5.888-9.611c-1.473,4.71-3.38,7.828-5.187,8.438a2.5,2.5,0,0,1-2.393-.543,1.013,1.013,0,0,1-.486-.949c.172-2.059,5.149-5.292,8.066-6.946"
              transform="translate(924.14 952.672)"
              fill="#047bc4"
            />
            <path
              id="Caminho_2016"
              data-name="Caminho 2016"
              d="M249.29,258.01a19.113,19.113,0,0,0,8.352-1.955.538.538,0,0,0,.253-.463.527.527,0,0,0-.276-.448c-.281-.157-7.013-3.861-10.641-2.57a3.219,3.219,0,0,0-1.983,1.874,2.117,2.117,0,0,0,.486,2.608,6.075,6.075,0,0,0,3.809.953M256.2,255.6c-2.879,1.225-8.462,1.983-10.04.653-.262-.224-.52-.586-.186-1.406a2.154,2.154,0,0,1,1.359-1.278c2.469-.882,6.955,1.087,8.867,2.031"
              transform="translate(922.085 950.478)"
              fill="#047bc4"
            />
            <path
              id="Caminho_2017"
              data-name="Caminho 2017"
              d="M215.013,274.471a42.55,42.55,0,0,0,8.686-1.1.538.538,0,0,0,.41-.458.532.532,0,0,0-.3-.539c-1.311-.615-12.848-5.945-15.756-3.981a1.727,1.727,0,0,0-.753,1.635A3.849,3.849,0,0,0,208.873,273c1.44,1.092,3.7,1.468,6.14,1.468m6.87-1.811c-3.428.643-9.763,1.473-12.376-.5a2.832,2.832,0,0,1-1.154-2.2.7.7,0,0,1,.291-.7c1.626-1.1,8.448,1.287,13.239,3.394"
              transform="translate(780.924 1009.463)"
              fill="#047bc4"
            />
            <path
              id="Caminho_2018"
              data-name="Caminho 2018"
              d="M219.067,278.436a.524.524,0,0,0,.515-.643c-.11-.486-2.727-11.866-8.991-11.141-1.568.186-2,.963-2.083,1.583-.391,2.9,6.889,8.743,10.364,10.164a.547.547,0,0,0,.2.038m-7.909-10.764c4,0,6.336,6.622,7.108,9.277-3.761-1.969-8.962-6.717-8.71-8.572.019-.133.071-.548,1.163-.677a3.447,3.447,0,0,1,.439-.029"
              transform="translate(785.437 1004.407)"
              fill="#047bc4"
            />
            <path
              id="Caminho_2019"
              data-name="Caminho 2019"
              d="M203.544,102.4l-4.81-2.469s1.835,4.5,4.81,2.469"
              transform="translate(748.673 376.473)"
              fill="#263238"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default memo(NoExpensesInRequestSVG);

import {
  ArrowBack,
  AssignmentOutlined,
  HistoryOutlined,
  ReceiptLongOutlined,
  SendOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import RenderChipStatus from "../../../../components/chip/RenderChipStatus";
import CircleDivisor from "../../../../components/division/CircleDivisor";
import AddExpenseButton from "../contents/expenses/header-components/AddExpenseButton";
import ReopenRequest from "./functions-layers/ReopenRequest";
import SendToApproval from "./functions-layers/SendToApproval";
import MoreButton from "./header-components/more/MoreButton";

const TabsComponent = memo(({ tab, onChange = () => {}, totalExpenses }) => {
  return (
    <Tabs
      elevation={0}
      value={tab}
      onChange={(e, v) => onChange(v)}
      TabIndicatorProps={{
        sx: {
          height: 2,
          mb: 0,
          borderRadius: 100,
        },
      }}
      sx={{
        boxShadow: 0,
        ml: -4,
        "&.MuiTabs-root": {
          minHeight: 40,
          position: "relative",
        },
        "& .MuiTab-root": {
          minHeight: 40,
          minWidth: 0,
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          fontSize: ".95rem",
          gap: "5px",
          padding: "12px 30px 12px 30px",
          "& .MuiTab-wrapper": {
            flexDirection: "row",
            gap: "10px",
          },
        },
      }}
    >
      <Tab
        value={"0"}
        label="Geral"
        icon={<AssignmentOutlined fontSize="small" />}
        iconPosition="start"
      />
      <Tab
        value={"1"}
        label={`Despesas (${totalExpenses})`}
        icon={<ReceiptLongOutlined fontSize="small" />}
        iconPosition="start"
      />
      <Tab
        value={1}
        sx={{ display: "none" }}
        label="Histórico do relatório"
        icon={<HistoryOutlined fontSize="small" />}
        iconPosition="start"
      />
    </Tabs>
  );
});

const SendButton = memo(
  ({
    requestId,
    onSend,
    hasExpensesRequiredReceipts,
    hasExpensesInRequest,
    limitBlockSendToApproval,
    hasLimitsAlerts,
  }) => {
    const isDisabled =
      !hasExpensesInRequest ||
      hasExpensesRequiredReceipts ||
      limitBlockSendToApproval;

    const tooltipMessage = limitBlockSendToApproval
      ? "Você ultrapassou o limite diário ou mensal para uma ou mais categorias de despesas. Por favor, verifique e ajuste conforme necessário."
      : hasLimitsAlerts
      ? "Você ultrapassou o limite diário ou mensal para uma ou mais categorias de despesas. Tem certeza que deseja enviar para aprovação mesmo assim?"
      : !hasExpensesInRequest
      ? "É preciso adicionar despesas ao relatório antes de enviá-lo para aprovação"
      : hasExpensesRequiredReceipts
      ? "Este relatório possui despesas que precisam de pelo menos um comprovante"
      : "";

    return (
      <SendToApproval
        onSend={onSend}
        requestId={requestId}
        renderComponent={({ disabled, onConfirm }) => (
          <Tooltip
            arrow
            disableFocusListener={!tooltipMessage}
            disableHoverListener={!tooltipMessage}
            title={
              <Typography variant="body1" fontWeight={"600"}>
                {tooltipMessage}
              </Typography>
            }
          >
            <span>
              <Button
                disabled={isDisabled || disabled}
                onClick={onConfirm}
                disableElevation
                sx={{
                  mr: 1,
                  display: { xs: "none", md: "flex" },
                }}
                variant="contained"
                color={hasLimitsAlerts ? "warning" : "primary"}
                endIcon={<SendOutlined fontSize="small" sx={{ ml: 1 }} />}
              >
                Enviar para aprovação
              </Button>
            </span>
          </Tooltip>
        )}
      />
    );
  }
);

const ReopenButton = memo(({ requestId, onReopen }) => {
  return (
    <ReopenRequest
      onReopen={onReopen}
      requestId={requestId}
      renderComponent={({ disabled, onConfirm }) => (
        <Button
          onClick={onConfirm}
          disabled={disabled}
          sx={{
            display: { md: "flex", xs: "none" },
            mr: 1,
            borderRadius: 100,
            transition: "none",
            ":hover": { boxShadow: 2 },
          }}
          color="primary"
          size="large"
          variant="outlined"
        >
          Reabrir relatório
        </Button>
      )}
    />
  );
});

const RequestHeader = ({
  requestId,
  title,
  status,
  loading,
  tab,
  advanced,
  onChangeTab,
  hasExpensesInRequest,
  hasExpensesRequiredReceipts,
  onRefresh,
  totalExpenses,
  limitBlockSendToApproval,
  hasLimitsAlerts,
}) => {
  const navigate = useNavigate();
  const scrolled = useScrollTrigger({
    disableHysteresis: true,
    target: undefined,
    threshold: 0,
  });
  return (
    <Box
      position={"sticky"}
      zIndex={(t) => t.zIndex.appBar - 1}
      top={{ md: 63, xs: 50 }}
      borderBottom={1}
      borderColor={"divider"}
      height={70}
      bgcolor={"background.default"}
      boxShadow={scrolled && 3}
      display={"flex"}
      px={2}
      ml={-3}
      pl={{ md: 5, xs: 4 }}
      pr={3}
      color={"text.primary"}
    >
      <Box>
        <IconButton
          onClick={() =>
            navigate("/approval-requests", {
              state: { visitedRequest: requestId },
            })
          }
          sx={{ p: 1, mt: 0.5, zIndex: 100 }}
        >
          <ArrowBack />
        </IconButton>
      </Box>
      <Box
        ml={2}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        height={70}
        flex={1}
      >
        <Box
          flex={1}
          display={"flex"}
          alignItems={"center"}
          gap={1}
          sx={{ fontWeight: "500" }}
        >
          <Typography
            maxWidth={300}
            noWrap
            fontSize={"1.18rem"}
            fontWeight={"500"}
          >
            {title}
          </Typography>
          <CircleDivisor mt={0.2} />
          <RenderChipStatus fz=".8rem" status={status} />
        </Box>
        <TabsComponent
          tab={tab}
          onChange={onChangeTab}
          totalExpenses={totalExpenses}
        />
      </Box>
      <Box display={"inherit"} alignItems={"center"} gap={1}>
        {status === "O" && (
          <>
            <AddExpenseButton requestId={requestId} />
            <SendButton
              hasExpensesInRequest={hasExpensesInRequest}
              hasExpensesRequiredReceipts={hasExpensesRequiredReceipts}
              requestId={requestId}
              limitBlockSendToApproval={limitBlockSendToApproval}
              hasLimitsAlerts={hasLimitsAlerts}
            />
          </>
        )}
        {status === "R" && <ReopenButton requestId={requestId} />}
        <MoreButton
          requestId={requestId}
          status={status}
          onRefresh={onRefresh}
          advanced={advanced}
          exportPDF={hasExpensesInRequest}
        />
      </Box>
    </Box>
  );
};

export default memo(RequestHeader);

import { ContentPasteSearchOutlined } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React, { memo } from "react";

const RequestNotFound = ({
  title = "Relatório não encontrado",
  subtitle = "Pode ser que este relatório tenha sido removido",
  buttonText = "Ver meus relatórios",
  buttonAction = () => {},
}) => {
  return (
    <Box
      display={"flex"}
      p={2}
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={"column"}
      minHeight={500}
      width={"100%"}
      color={"text.primary"}
    >
      <ContentPasteSearchOutlined
        color="disabled"
        sx={{ fontSize: "5rem", mb: 2 }}
      />
      <Typography
        gutterBottom
        maxWidth={800}
        textAlign={"center"}
        variant="h5"
        fontWeight={"600"}
      >
        {title}
      </Typography>
      <Typography maxWidth={800} textAlign={"center"} color={"text.secondary"}>
        {subtitle}
      </Typography>
      <Button
        onClick={buttonAction}
        size="large"
        variant="outlined"
        sx={{ borderRadius: 100, mt: 2 }}
      >
        {buttonText}
      </Button>
    </Box>
  );
};

export default memo(RequestNotFound);

import {
  AttachFileOutlined,
  Close,
  CropOutlined,
  OpenInNewOutlined,
} from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import React, { memo, useMemo } from "react";
import Zoom from "react-medium-image-zoom";
import PdfIcon from "../../../../assets/icons/pdf_icon.svg";
import "../../../../main.css";
import { getReceiptUrl } from "../../../../utils/functions/receipts";

function SingleReceiptView({ file, onClickRemove, enableCrop, onClickCrop }) {
  const isPDF = useMemo(
    () =>
      file &&
      file.type.includes("application") &&
      file.type === "application/pdf",
    [file]
  );

  const openInNew = () => {
    window.open(getReceiptUrl(file.url) || file.uri);
  };

  return (
    <Box
      borderRadius={1}
      overflow="hidden"
      display="flex"
      width={"100%"}
      alignItems={"center"}
      justifyContent="center"
      position={"relative"}
    >
      <Box
        display={"flex"}
        alignItems="center"
        bgcolor={"rgb(0,0,0,.7)"}
        position={"absolute"}
        width="100%"
        zIndex={3}
        top={0}
        py={1}
        px={2}
      >
        <Tooltip title={file.filename}>
          <Typography
            onClick={openInNew}
            sx={{
              cursor: "pointer",
              ":hover": {
                textDecoration: "underline",
              },
            }}
            noWrap
            fontWeight={"600"}
            variant="body2"
            color={"white"}
          >
            {file.filename}
          </Typography>
        </Tooltip>
        <Box flex={1} />
        {enableCrop && (
          <Tooltip title="Cortar imagem">
            <IconButton
              sx={{ color: "white" }}
              onClick={onClickCrop}
              size="small"
            >
              <CropOutlined fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Abrir em outra aba">
          <IconButton onClick={openInNew} size="small" sx={{ color: "white" }}>
            <OpenInNewOutlined fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Remover">
          <IconButton
            onClick={(event) => onClickRemove(event, file.id)}
            size="small"
            sx={{ color: "white" }}
          >
            <Close fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      {file.type.includes("application") ? (
        isPDF ? (
          <img height="100%" width={"100%"} src={PdfIcon} />
        ) : (
          <AttachFileOutlined fontSize="large" />
        )
      ) : (
        <Zoom classDialog="custom-zoom">
          <img src={file.uri} alt={file.filename} width={"100%"} />
        </Zoom>
      )}
    </Box>
  );
}

export default memo(SingleReceiptView);

export const newExpenseInitialState = {
  date: new Date(),
  amount: "",
  currency: "",
  payment_type: "",
  project_id: "",
  type: "",
  notes: "",
  refundable: true,
  request_id: "",
};
export const newRouteInitialState = {
  date: new Date(),
  snippet_id: "",
  route_policy_id: "",
  project_id: "",
  distance: "",
  notes: "",
  refundable: true,
  request_id: "",
};

export const expenseItemData = {
  id: "",
  date: new Date().toISOString(),
  created_at: new Date().toISOString(),
  refundable: true,
  notes: "",
  is_route: false,
  type_id: "",
  currency: "",
  account_currency: "",
  amount: "",
  amount_converted: "",
  receipts: [],
  total_receipts: 0,
  project_name: "",
  distance: 0,
  waypoints: [],
  request_id: "",
  payment_type: "",
  payment_type_name: "",
  required_receipt: false,
  status: "",
  from: "",
  to: "",
  hidden: false,
  processingStatus: null,

  //sync
  sync: false,
  sync_error: null,
};

export const requestItemData = {
  id: "",
  created_at: new Date(),
  title: "",
  obs: "",
  status: "O",
  total_amount: 0,
  total_refundable: 0,
  total_expenses: 0,
  events: [],
  step_info: null,
  //sync
  processingStatus: null,
};
export const approvalRequestItemData = {
  id: "",
  created_at: new Date(),
  title: "",
  obs: "",
  total_amount: 0,
  total_refundable: 0,
  total_expenses: 0,
  user: {},
  expenses: [],
};

export const requestsStatusArray = ["O", "R", "AD", "P", "A", "F"];

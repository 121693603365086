import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { APIActions } from "../../../queues/offline-queue/effect";
import normalize from "../../../utils/normalize";

const baseName = "routePolicies";

const adapter = createEntityAdapter({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) =>
    a.name?.toUpperCase().localeCompare(b.name?.toUpperCase()),
});

const initialState = {
  working: false,
};

export const syncRoutePolicies = createAsyncThunk(
  `${baseName}/syncRoutePolicies`,
  async () => {
    const res = await APIActions.routePolicies.sync();
    return res;
  }
);

export const routePoliciesSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    setupRoutePolicies(state, { payload }) {
      const { entities } = normalize(payload);
      adapter.setAll(state, entities);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(syncRoutePolicies.pending, (state, action) => {
        state.working = true;
      })
      .addCase(syncRoutePolicies.rejected, (state, action) => {
        state.working = false;
      })
      .addCase(syncRoutePolicies.fulfilled, (state, action) => {
        state.working = false;
        const data = action.payload;
        routePoliciesSlice.caseReducers.setupRoutePolicies(state, {
          payload: data,
        });
      });
  },
});

export const { setupRoutePolicies } = routePoliciesSlice.actions;

export default routePoliciesSlice.reducer;

export const {
  selectAll: selectAllRoutePolicies,
  selectById: selectRoutePolicieById,
  selectIds,
  selectEntities: selectRoutePoliciesEntities,
} = adapter.getSelectors((state) => state[baseName]);

export const selectRoutePoliciesIds = createSelector(
  [selectAllRoutePolicies, (state, showInactive) => showInactive],
  (data, showInactive) => {
    return data
      .filter((item) => (showInactive ? true : Boolean(item.active)))
      .map((item) => item.id);
  }
);

import { Dialog, Fade, useMediaQuery, useTheme } from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DialogAlert from "../../../classes/DialogAlert";
import ExpensePolicyLayer from "../../../layers/ExpensePolicyLayer";
import "../../../main.css";
import { selectAccountCurrency } from "../../../store/features/accountSlice";
import { openSnackbar } from "../../../store/features/base/snackbarBaseSlice";
import { selectProjectById } from "../../../store/features/configs/projectsSlice";
import {
  selectRoutePoliciesEntities,
  syncRoutePolicies,
} from "../../../store/features/configs/routePoliciesSlice";
import { selectSnippetsEntities } from "../../../store/features/configs/snippetsSlice";
import { expensesOfflineActions } from "../../../store/features/expensesSlice";
import {
  closeElement,
  selectPortalIsOpened,
} from "../../../store/features/portalSlice";
import formatAmountStringToNumberCopy from "../../../utils/formatAmountStringToNumber copy";
import getRateByDate from "../../../utils/getRateByDate";
import { newRouteInitialState } from "../../../utils/initialStates";
import { curr } from "../../../utils/more/currency_country";
import { getBlob } from "../../../utils/more/images_transform";
import HeaderToggleRouteMode from "./components/HeaderToggleRouteMode";
import ManualModeContent from "./contents/ManualModeContent";
import MapModeContent from "./contents/MapModeContent";
import { calculateRoute } from "./functions";

function ModalNewRoute(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [calculating, setCalculating] = useState(false);
  const [routeMode, setRouteMode] = useState("map");

  const [values, setValues] = useState(newRouteInitialState);
  const [receipts, setReceipts] = useState([]);

  const [routes, setRoutes] = useState(["", ""]);
  const [directionsResponse, setDirectionsResponse] = useState(null);

  const open = useSelector((state) => selectPortalIsOpened(state, "newRoute"));
  const initialPropData = useSelector((state) => state.portal.newRoute.util);

  //select entities
  const accountCurrency = useSelector(selectAccountCurrency);
  const snippetsEntities = useSelector(selectSnippetsEntities);
  const routePoliciesEntities = useSelector(selectRoutePoliciesEntities);

  const valuesRef = useRef();
  const routesRef = useRef();
  const expensePolicyRef = useRef();

  //functions utils
  const onClose = useCallback(() => dispatch(closeElement("newRoute")), []);
  const showSnackbar = useCallback(
    (message) => dispatch(openSnackbar({ message })),
    []
  );
  const setExpense = useCallback(
    (data) => dispatch(expensesOfflineActions.setExpense(data)),
    []
  );

  const uploadReceipts = useCallback(
    (expenseId, receipts, syncOnly) =>
      dispatch(
        expensesOfflineActions.uploadReceipts(expenseId, receipts, syncOnly)
      ),
    []
  );

  //entitie for value
  const initialRoutePolicyId = useMemo(
    () => Object.keys(routePoliciesEntities)[0] || "",
    [routePoliciesEntities]
  );
  const project = useSelector((s) => selectProjectById(s, values.project_id));

  //state control
  useEffect(() => {
    if (!open) {
      setRoutes(["", ""]);
      setValues(newRouteInitialState);
      setDirectionsResponse(null);
      setReceipts([]);
    } else {
      dispatch(syncRoutePolicies());
      setValues({
        ...newRouteInitialState,
        route_policy_id: initialRoutePolicyId,
        ...(initialPropData || {}),
      });
      if (initialPropData) {
        setRouteMode(initialPropData?.route_mode || "map");
        if (initialPropData?.route_mode === "map") {
          const newRoutes = [
            initialPropData.from,
            ...(initialPropData.waypoints || []),
            initialPropData.to,
          ];
          setRoutes(newRoutes);
          handleCalculateRoute(newRoutes);
        }
      }
    }
  }, [open]);

  const handleChangeValues = useCallback(
    (prop, v) => {
      setValues((prev) => ({ ...prev, [prop]: v }));
    },
    [setValues]
  );

  //policies/rates/amount
  const currentRate = useMemo(() => {
    if (!open) return 0;
    return getRateByDate(
      routePoliciesEntities[values.route_policy_id]?.history || [],
      new Date(values.date)
    );
  }, [values.route_policy_id, routePoliciesEntities, values.date, open]);

  const amount = useMemo(() => {
    if ((!directionsResponse && routeMode === "map") || !open) return 0;
    const distanceValue = formatAmountStringToNumberCopy(values.distance);
    return distanceValue * parseFloat(currentRate);
  }, [values.distance, currentRate, directionsResponse, routeMode, open]);

  const refundable = useMemo(() => {
    if (!open) return newRouteInitialState.refundable;
    return Boolean(
      routePoliciesEntities[values.route_policy_id]?.is_refundable
    );
  }, [values.route_policy_id, routePoliciesEntities, open]);

  const disabledCreate = useMemo(() => {
    if (!open) return true;
    if (routeMode === "manual") return false;
    return Boolean(!directionsResponse) || calculating;
  }, [directionsResponse, open, calculating, routeMode]);

  //route mode effect
  useEffect(() => {
    if (routeMode === "map") {
      handleCalculateRoute(routes);
    }
  }, [routeMode]);

  //routes control
  const handleCalculateRoute = async (array) => {
    if (routeMode === "manual") return;
    if (array.every(Boolean)) {
      setCalculating(true);
      const { results, distance } = await calculateRoute(array);
      if (!results) return;
      handleChangeValues("distance", distance);
      setDirectionsResponse(results);
      setCalculating(false);
    }
  };

  const reoderRoutes = (startIndex, endIndex) => {
    let result = Array.from(routes);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    setRoutes([...result]);
    handleCalculateRoute([...result]);
  };

  const handleUpdateRoute = useCallback((idx, value) => {
    setRoutes((prev) => {
      prev.splice(idx, 1, value);
      return [...prev];
    });
  }, []);

  const handleRevertRoutes = useCallback(() => {
    let reversed = Object.assign([], routes);
    reversed.reverse();
    setRoutes([...reversed]);
    handleCalculateRoute([...reversed]);
  }, [routes]);

  const handleCalculateFunction = useCallback(
    () => handleCalculateRoute(routes),
    [routes]
  );
  const handleAddRoute = useCallback(() => {
    setRoutes((prev) => {
      prev.push("");
      return [...prev];
    });
  }, []);
  const handleRemoveRoute = useCallback((idx) => {
    setRoutes((prev) => {
      prev.splice(idx, 1);
      handleCalculateRoute([...prev]);
      return [...prev];
    });
  }, []);
  const handleDragEnd = useCallback(
    (result) => reoderRoutes(result.source.index, result.destination.index),
    [reoderRoutes]
  );

  //receipts manual route
  const handleDropReceipts = useCallback(async (acceptedFiles) => {
    let prepared = [];
    for (let index = 0; index < acceptedFiles.length; index++) {
      const file = acceptedFiles[index];
      const blob = await getBlob(file);
      const receiptId = nanoid(5);
      try {
        const data = {
          id: receiptId,
          filename: file.path,
          uri: URL.createObjectURL(file),
          type: file.type,
          blob: new Blob([blob], { type: file.type }),
        };
        prepared.push(data);
      } catch (error) {}
    }
    if (prepared && prepared.length) {
      setReceipts((prev) => {
        return [...prepared, ...prev];
      });
    }
  }, []);

  const handleCropReceipt = useCallback(
    (fileId, blob, previewUrl, origin, revert) => {
      const selectedFile = receipts.find((item) => item.id === fileId);
      if (!selectedFile) return;
      selectedFile.blob = blob;
      selectedFile.uri = previewUrl;
      if (revert) {
        delete selectedFile.origin;
      } else if (origin) {
        selectedFile.origin = origin;
      }
      setReceipts((prev) => {
        prev.splice(
          prev.findIndex((item) => item.id === fileId),
          1,
          selectedFile
        );
        return [...prev];
      });
    },
    [receipts]
  );
  const handleRemoveReceipt = useCallback((id) => {
    setReceipts((prev) => {
      const index = prev.findIndex((receipt) => receipt.id === id);
      if (index !== -1) {
        prev.splice(index, 1);
      }
      return [...prev];
    });
  }, []);

  //snippets distance effect
  const canEditDistance = useMemo(() => {
    if (!values.snippet_id || !open) return true;
    return Boolean(
      values.snippet_id &&
        snippetsEntities[values.snippet_id]?.can_edit_distance
    );
  }, [values.snippet_id, snippetsEntities, open]);

  useEffect(() => {
    if (values.snippet_id) {
      handleChangeValues(
        "distance",
        parseFloat(snippetsEntities[values.snippet_id]?.distance || 0).toFixed(
          2
        )
      );
    }
  }, [values.snippet_id, snippetsEntities]);

  //refs control
  useEffect(() => {
    valuesRef.current = {
      ...values,
      amount,
      project_name: project?.name || "",
      route_policy_name:
        routePoliciesEntities[values.route_policy_id]?.name || "",
      refundable,
      rate: currentRate,
      can_edit_distance: canEditDistance,
    };
    routesRef.current = routes;
  }, [
    values,
    routes,
    amount,
    project,
    routePoliciesEntities,
    currentRate,
    refundable,
    canEditDistance,
  ]);

  //CREATION
  const handleCreate = (event, isChecked) => {
    const routesValues = routesRef.current || [];
    const data = valuesRef.current;
    if (!data.route_policy_id) {
      DialogAlert.show({
        title: "Defina uma taxa de percurso para prosseguir",
      });
      return;
    }
    const expenseId = nanoid(8);
    const routeObj = {
      id: expenseId,
      amount: parseFloat(data.amount),
      date: data.date,
      notes: data.notes,
      refundable: data.refundable,
      request_id: data.to_request,
      project_id: data.project_id,
      project_name: project?.name || "",
      route_policy_id: data.route_policy_id,
      currency: accountCurrency,
      account_currency: accountCurrency,
      rate: data.rate,
      isRoute: true,
      is_route: true,
      route_mode: routeMode,
      can_edit_distance: data.can_edit_distance,
      from: routeMode === "map" ? routesValues[0] || "" : "",
      to:
        routeMode === "map" ? routesValues[routesValues.length - 1] || "" : "",
      waypoints: routeMode === "map" ? routesValues.slice(1, -1) : [],
      receipts,
      distance: data.distance,
      snippet_id: routeMode === "manual" ? data.snippet_id : undefined,
      created_at: new Date().toISOString(),
    };
    let ok = false;
    const { isOk, warnings, required_receipt } =
      expensePolicyRef.current?.check(
        {
          ...routeObj,
          currency_t: accountCurrency,
        },
        !isChecked
      );
    routeObj.warnings = warnings || [];
    routeObj.required_receipt =
      routeMode === "manual" ? Boolean(required_receipt) : false;
    if (isChecked) {
      ok = true;
    } else {
      ok = isOk;
    }
    if (!ok) return;
    setExpense(routeObj);
    if (data.to_request) {
      navigate(`/approval-requests/${data.to_request}?tab=1`, {
        replace: true,
      });
    } else {
      navigate("expenses", { replace: true });
    }
    showSnackbar("Despesa criada");
    if (routeMode === "manual" && Boolean(receipts.length)) {
      uploadReceipts(expenseId, receipts, true);
    }
    onClose();
  };

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      fullScreen={isSmall}
      data-disableselect={true}
      open={open}
      fullWidth
      TransitionComponent={Fade}
      maxWidth={"md"}
      PaperProps={{
        elevation: 1,
        sx: {
          borderRadius: isSmall ? 0 : 2,
          height: { xs: "100%", md: "90%" },
          maxHeight: { md: "45em", xs: "100%" },
          position: "relative",
        },
      }}
    >
      <HeaderToggleRouteMode
        mode={routeMode}
        onChangeMode={setRouteMode}
        onClose={onClose}
        onCreate={handleCreate}
        disabledCreation={disabledCreate}
      />
      {routeMode === "map" ? (
        <MapModeContent
          currency={curr(accountCurrency)}
          values={{ ...values, refundable, amount }}
          routes={routes}
          rate={currentRate}
          updateRoute={handleUpdateRoute}
          revertRoutes={handleRevertRoutes}
          calculateFunction={handleCalculateFunction}
          addRoute={handleAddRoute}
          removeRoute={handleRemoveRoute}
          onDragEnd={handleDragEnd}
          directionsResponse={directionsResponse}
          dateInvalid={false}
          predefinedRoutePolicyData={null}
          loadingMap={calculating}
          onChangeValue={handleChangeValues}
          onClose={onClose}
        />
      ) : routeMode === "manual" ? (
        <ManualModeContent
          canEditDistance={canEditDistance}
          rate={currentRate}
          values={{ ...values, refundable }}
          receipts={receipts}
          amount={amount}
          predefinedRoutePolicyData={null}
          currency={curr(accountCurrency)}
          dateInvalid={false}
          onChangeValue={handleChangeValues}
          onDropReceipts={handleDropReceipts}
          onCropReceipt={handleCropReceipt}
          onRemoveReceipt={handleRemoveReceipt}
          onClose={onClose}
        />
      ) : (
        <></>
      )}
      {/* Camada de validação de pilíticas de despesa */}
      <ExpensePolicyLayer
        ref={expensePolicyRef}
        onCreate={handleCreate}
        showRequiredReceipt={
          !Boolean(receipts.length) && routeMode === "manual"
        }
      />
    </Dialog>
  );
}

export default memo(ModalNewRoute);

import {
  Autocomplete,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import { connect } from "react-redux";
import AvatarType from "../../../../components/avatar/AvatarType";
import {
  selectExpenseTypeById,
  selectExpenseTypesEntities,
  selectExpenseTypesIds,
} from "../../../../store/features/configs/expenseTypesSlice";
import SelectorBox from "../SelectorBox";

function ExpTypeInput({
  expenseTypes,
  expenseTypesEntities,
  expenseTypeValue,
  value,
  readOnly,
  initialError,
  loading,
  onChange = () => {},
  helperText,
  size,
  inputRef,
}) {
  const [error, setError] = useState(Boolean(initialError));
  return (
    <SelectorBox>
      <AvatarType icon={expenseTypeValue?.icon} sx={{ mr: 1.5, ml: 0.2 }} />
      <Autocomplete
        disablePortal
        size={size || "small"}
        autoHighlight
        fullWidth
        openOnFocus
        readOnly={readOnly}
        options={expenseTypes}
        loading={loading}
        loadingText="Carregando..."
        getOptionDisabled={(option) => !expenseTypesEntities[option]?.active}
        value={value || null}
        onChange={(e, v) => {
          onChange(v);
          setError(false);
        }}
        getOptionLabel={(option) => expenseTypesEntities[option]?.name}
        renderOption={(props, option, state) => {
          return (
            <MenuItem {...props} selected={state.selected}>
              <AvatarType icon={expenseTypesEntities[option]?.icon} />
              <Typography variant="inherit" ml={1}>
                {expenseTypesEntities[option]?.name}
              </Typography>
            </MenuItem>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name="type_id"
            inputRef={inputRef}
            required
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            fullWidth
            variant="outlined"
            label={"Categoria"}
            helperText={''}
          />
        )}
      />
    </SelectorBox>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.expenseTypes.sync,
    expenseTypes: selectExpenseTypesIds(state) || [],
    expenseTypesEntities: selectExpenseTypesEntities(state) || {},
    expenseTypeValue: selectExpenseTypeById(state, ownProps.value) || null,
    ...ownProps,
  };
};

export default connect(mapStateToProps)(memo(ExpTypeInput));

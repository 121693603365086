import { Close, InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  DialogActions,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";

import { useDispatch } from "react-redux";
import {
  expensesOfflineActions,
  removeManyExpenses,
} from "../../../../store/features/expensesSlice";
import { store } from "../../../../store/store";

function ErrorButton({ error, expenseId }) {
  const [menu, setMenu] = useState(null);

  const dispatch = useDispatch();

  const retryAgain = () => {
    setMenu(null);
    const expenseData = store.getState().expenses.entities[expenseId];
    if (!expenseData) return;
    dispatch(expensesOfflineActions.setExpense(expenseData));
  };

  return (
    <>
      <IconButton
        color="error"
        onClick={(e) => {
          e.stopPropagation();
          setMenu(e.target);
        }}
        sx={{
          bgcolor: Boolean(menu) && "action.focus",
        }}
      >
        <InfoOutlined color="error" />
      </IconButton>
      <Popover
        onContextMenu={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onClick={(e) => e.stopPropagation()}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => {
          setMenu(null);
        }}
        data-disableselect={true}
        open={Boolean(menu)}
        anchorEl={menu}
        slotProps={{
          paper: {
            sx: { width: 400, px: 2, pt: 2, pb: 0 },
          },
        }}
      >
        <Box mb={1} display={"flex"} alignItems={"center"}>
          <InfoOutlined fontSize="small" color="error" sx={{ mr: 1.5 }} />
          <Typography fontWeight={"600"}>Erro ao adicionar despesa</Typography>
          <Box flex={1} />
          <IconButton sx={{ m: -1 }} onClick={() => setMenu(null)}>
            <Close />
          </IconButton>
        </Box>
        <Typography variant="body2" color={"text.secondary"}>
          {error}
        </Typography>
        <DialogActions sx={{ p: 0 }}>
          <Button
            size="small"
            onClick={() => {
              setMenu(null);
              dispatch(removeManyExpenses([expenseId]));
            }}
          >
            Remover despesa
          </Button>
          <Button
            variant="contained"
            disableElevation
            size="small"
            onClick={retryAgain}
          >
            Tentar novamente
          </Button>
        </DialogActions>
      </Popover>
    </>
  );
}

export default memo(ErrorButton);

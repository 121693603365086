import { Box, Typography } from "@mui/material";
import React, { memo, useCallback } from "react";
import formatAmount from "../../../../utils/formatAmount";
import DateInput from "../../components/inputs/DateInput";
import DistanceInput from "../../components/inputs/DistanceInput";
import ObsInput from "../../components/inputs/ObsInput";
import ProjectInput from "../../components/inputs/ProjectInput";
import RequestInput from "../../components/inputs/RequestInput";
import RoutePoliciesInput from "../../components/inputs/RoutePoliciesInput";

function Form({
  values = {},
  onChangeValue,
  currency,
  rate,
  predefinedValues = {},
  routePolicyIsDetached,
  hiddenRequestInput,
}) {

  const handleChangeRequest = useCallback(
    (v) => onChangeValue("to_request", v),
    [values.to_request]
  );

  return (
    <Box ml={-0.5} pb={6} display="flex" flexDirection={"column"} gap={3}>
      <DistanceInput
        variant={"outlined"}
        value={values.distance}
        onChange={useCallback(() => {}, [])}
        readOnly
      />
      <RoutePoliciesInput
        value={values?.route_policy_id}
        onChange={useCallback(
          (v) => onChangeValue("route_policy_id", v),
          [values.route_policy_id]
        )}
        refDate={new Date(values?.date)}
        predefinedName={predefinedValues?.route_policy_name || ""}
      />
      <Box pl={6}>
        <Typography
          mb={1}
          mt={-2}
          fontSize={".85rem"}
          fontWeight={"500"}
          color={"text.secondary"}
        >
          Taxa paga por km:{" "}
          <Typography
            variant="inherit"
            color={"primary.main"}
            fontWeight={"600"}
            component={"span"}
            fontSize={".9rem"}
          >
            {currency} {formatAmount(rate)}
          </Typography>
        </Typography>
        <Typography variant="h5" fontWeight={values?.amount ? "600" : "400"}>
          <Typography
            variant="inherit"
            fontSize={"1.2rem"}
            component={"span"}
            fontWeight={"500"}
          >
            Total =
          </Typography>{" "}
          {currency} {formatAmount(values?.amount)}
        </Typography>
        <Typography mt={1} mb={2} variant="body2" color={"text.secondary"}>
          {values.refundable ? "Reeembolsável" : "Não reeembolsável"}
        </Typography>
      </Box>
      <DateInput
        value={values.date}
        onChange={useCallback((v) => onChangeValue("date", v), [values.date])}
        disabledByRoutePolicy={routePolicyIsDetached}
      />
      <ProjectInput
        value={values.project_id}
        date={values.date}
        onChange={useCallback(
          (v) => onChangeValue("project_id", v),
          [values.project_id]
        )}
        predefinedValue={predefinedValues?.project_name}
      />
      <ObsInput
        size="small"
        value={values.notes}
        onChange={useCallback((v) => onChangeValue("notes", v), [values.notes])}
      />
      {!hiddenRequestInput && (
        <RequestInput
          value={values.to_request}
          onChange={handleChangeRequest}
        />
      )}
    </Box>
  );
}

export default memo(Form);

export default (id, selected, callback) => {
  const selectedIndex = selected.indexOf(id);
  let newSelected = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, id);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1)
    );
  }
  callback(newSelected);
};

export const onSelectAll = (checked, allIds, callback) => {
  if (checked) {
    callback(allIds);
    return;
  }
  callback([]);
};

export const isSelected = (id = "", selected = []) =>
  selected.indexOf(id) !== -1;

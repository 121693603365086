export const getAccountStatusData = (status, metadata = {}) => {
  let data = {
    title: "",
    description: "Lorem inpsum var lorem text para testando descriptio",
    severity: "info",
  };

  switch (status) {
    case "active":
      data.title = "Sua conta Notepay está ativa.";
      data.description =
        "Sua conta Notepay está funcionando normalmente. Continue desfrutando dos nossos serviços.";
      data.severity = "success";
      break;
    case "trialing":
      data.title = "Sua conta Notepay está no período de avaliação gratuita.";
      data.description =
        "Você está atualmente em um período de avaliação gratuita da Notepay. Experimente nossos recursos premium sem custo.";
      data.severity = "info";
      break;
    case "paused":
      data.title =
        "Seu período de avaliação gratuita terminou. Escolha o seu plano!";
      data.description =
        "O período de avaliação gratuita da Notepay chegou ao fim. Para continuar usando nossos serviços, escolha um plano de assinatura adequado às suas necessidades.";
      data.severity = "warning";
      break;
    case "unpaid":
      data.title =
        "Sua conta foi inativada devido a problemas no processamento do pagamento da fatura.";
      data.description =
        "Sua conta foi temporariamente desativada devido a dificuldades no processamento do pagamento da fatura. Por favor, verifique suas informações de pagamento.";
      data.severity = "error";
      break;
    case "canceled":
      data.title =
        "Sua conta está inativa. Seu plano foi cancelado, verifique seu faturamento.";
      data.description =
        "Sua conta foi cancelada e está atualmente inativa. Verifique o status do seu faturamento para reativar sua conta, se necessário.";
      data.severity = "error";
      break;
    default:
      break;
  }
  return data;
};

export const accountStatusIsError = (status = "") =>
  ["paused", "canceled", "unpaid"].includes(status);

import { Add, DocumentScannerOutlined } from "@mui/icons-material";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { openElement } from "../../../store/features/portalSlice";
import MenuCreation from "../../sidebar/components/MenuCreation";

const ExpensesActions = () => {
  const [menu, setMenu] = useState(null);
  const dispatch = useDispatch();
  return (
    <>
      <Button
        onClick={(e) => setMenu(e.target)}
        variant="outlined"
        startIcon={<Add />}
        size="small"
        sx={{ borderRadius: 100, fontSize: ".9rem", px: 1.5 }}
      >
        <Typography variant="inherit" noWrap>
          Criar despesas
        </Typography>
      </Button>
      <Tooltip title="Escanear comprovantes">
        <IconButton
          onClick={() => dispatch(openElement({ name: "scanExpenses" }))}
          sx={{ ml: 1.5 }}
        >
          <DocumentScannerOutlined />
        </IconButton>
      </Tooltip>
      <MenuCreation
        hiddenNewRequest
        anchorEl={menu}
        onClose={() => setMenu(null)}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      />
    </>
  );
};

export default memo(ExpensesActions);

import {
  Box,
  lighten,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const BaseItem = ({
  disableHiddenLabel,
  width,
  selected,
  rounded,
  height = 55,
  title,
  content,
  Icon,
  onClick,
  subtitle,
  action,
  onDragOver,
  onDragLeave,
  acceptDrag,
  onDrop,
  sx,
}) => {
  const [hovered, setHovered] = useState(false);

  return (
    <ListItemButton
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      draggable={false}
      onDrop={onDrop}
      disableRipple
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{
        pl: !disableHiddenLabel && 3,
        height,
        width,
        borderTopRightRadius: 100,
        borderBottomRightRadius: 100,
        borderRadius: rounded && 100,
        bgcolor: (t) =>
          acceptDrag
            ? lighten(`${t.palette.success.main}30`, 0.1)
            : selected && `${t.palette.primary.main}20`,
        transition: "none",
        overflow: "hidden",
        position: "relative",
        ":hover": {
          bgcolor: (t) =>
            selected
              ? `${t.palette.primary.main}30`
              : t.palette.action.selected,
        },
        ...sx,
      }}
    >
      <ListItemIcon>
        <Icon
          sx={{
            fontSize: "24px",
            color: selected ? "primary.main" : "text.primary",
            opacity: !selected && 0.6,
          }}
        />
      </ListItemIcon>
      <ListItemText
        sx={{ maxWidth: "70%" }}
        primaryTypographyProps={{
          fontSize: ".95rem",
          fontWeight: "600",
          noWrap: true,
          color: selected ? "primary" : "text.primary",
          // sx: { opacity: !selected && 0.85 },
          ml: -1,
        }}
        secondaryTypographyProps={{ fontSize: ".7rem", ml: -1 }}
        primary={title}
        secondary={subtitle}
      />
      <Box flex={1} />
      {hovered && action}
      <Box width={8}>{content}</Box>
    </ListItemButton>
  );
};

function SidebarItem({
  path,
  title,
  subtitle,
  rounded,
  disableLink,
  acceptDrag,
  droppableProps,
  innerRef,
  Icon,
  width = "100%",
  disableHiddenLabel,
  selectedProp,
  onClick,
  content,
  height,
  action,
  draggable,
  onDrop,
  onDragLeave,
  onDragOver,
  sx,
}) {
  const { pathname } = useLocation();

  const isSelected = (link) => {
    return new RegExp(`${link}`).test(pathname);
  };

  const selected = selectedProp === undefined ? isSelected(path) : selectedProp;

  if (disableLink) {
    return (
      <BaseItem
        rounded={rounded}
        innerRef={innerRef}
        droppableProps={droppableProps}
        onClick={onClick}
        Icon={Icon}
        content={content}
        disableHiddenLabel={disableHiddenLabel}
        selected={selected}
        title={title}
        width={width}
        height={height}
        subtitle={subtitle}
        action={action}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        draggable={draggable}
        acceptDrag={acceptDrag}
        sx={sx}
      />
    );
  }

  return (
    <Link
      draggable={false}
      style={{
        textDecoration: "none",
        textDecorationColor: "transparent",
        outline: "none",
      }}
      to={path}
    >
      <BaseItem
        rounded={rounded}
        innerRef={innerRef}
        droppableProps={droppableProps}
        Icon={Icon}
        content={content}
        disableHiddenLabel={disableHiddenLabel}
        selected={selected}
        title={title}
        width={width}
        subtitle={subtitle}
        onClick={onClick}
        height={height}
        action={action}
        acceptDrag={acceptDrag}
        draggable={draggable}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        sx={sx}
      />
    </Link>
  );
}

export default SidebarItem;

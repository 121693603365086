import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [""],
};

export const breadcrumbsSlice = createSlice({
  name: "breadcrumbs",
  initialState,
  reducers: {
    setBreadcrumbs(state, { payload }) {
      state.data = payload;
      document.title =
        `Notepay | ${payload[payload.length - 1]}` || "Notepay - Controle de despesas";
    },
  },
});

export const { setBreadcrumbs } = breadcrumbsSlice.actions;

export default breadcrumbsSlice.reducer;

import { HttpClient } from "../../api/httpClient";
import { RECEIPTS_URL } from "../../api/urls";

export const APIActions = {
  expenses: {
    set: async (expenseObj) => {
      const res = await HttpClient.post("/expenses", expenseObj);
      return res.data;
    },
    remove: async (expensesIds) => {
      const res = await HttpClient.post("/expenses/delete", {
        ids: expensesIds,
      });
      return res.data;
    },
    addToRequest: async ({ requestId, expensesIds }) => {
      const res = await HttpClient.put(`/requests/${requestId}/expenses/add`, {
        expensesIds,
      });
      return res.data;
    },
    moveToRequest: async ({ fromRequestId, toRequestId, expenses = [] }) => {
      const res = await HttpClient.put(
        `/requests/${fromRequestId}/expenses/move`,
        {
          expenses,
          to_request_id: toRequestId,
        }
      );
      return res.data;
    },
    removeFromRequest: async ({ requestId, expensesIds = [] }) => {
      const res = await HttpClient.put(
        `/requests/${requestId}/expenses/remove`,
        {
          expensesIds,
        }
      );
      return res.data;
    },
  },
  receipts: {
    scan: async ({
      expenseId,
      created_at,
      receipt: { blob, filename, id },
    }) => {
      const formData = new FormData();
      formData.append("receipt", blob, filename);
      const res = await HttpClient.post(
        `/expenses/${expenseId}/receipts/upload/scan`,
        formData,
        {
          receipt_id: id,
          created_at,
        }
      );
      return res.data;
    },

    upload: async ({ expenseId, receipts = [] }) => {
      const formData = new FormData();

      let receiptsIds = [];

      for (const receipt of receipts) {
        receiptsIds.push(receipt?.id);
        formData.append("receipts", receipt.blob, receipt.filename);
      }

      const res = await HttpClient.post(`${RECEIPTS_URL}/upload`, formData, {
        expense_id: expenseId,
        receipts_ids: receiptsIds?.join(","),
        access_token: HttpClient._accessToken,
      });
      return res.data;
    },

    remove: async ({ expenseId, receiptId }) => {
      const res = await HttpClient.delete(
        `/expenses/${expenseId}/receipts/${receiptId}`
      );
      return res.data;
    },
  },
  requests: {
    add: async (requestData) => {
      const res = await HttpClient.post(`/requests`, requestData);
      return res.data;
    },
    advance: async ({ requestId, advance = {} }) => {
      const res = await HttpClient.post(
        `/requests/${requestId}/advance`,
        advance
      );
      return res.data;
    },
    update: async ({ requestId = "", changes = {} }) => {
      const res = await HttpClient.put(`/requests/${requestId}`, changes);
      return res.data;
    },
    remove: async (requestId = "") => {
      const res = await HttpClient.delete(`/requests/${requestId}`);
      return res.data;
    },
    sendToApproval: async (requestId = "") => {
      const res = await HttpClient.post(
        `/requests/${requestId}/sendToApproval`
      );
      return res.data;
    },
    reopen: async (requestId = "") => {
      const res = await HttpClient.post(`/requests/${requestId}/reopen`);
      return res.data;
    },
  },
  projects: {
    sync: async () => {
      const res = await HttpClient.get(`/settings/sync/projects`);
      return res.data;
    },
  },
  expensePolicies: {
    sync: async () => {
      const res = await HttpClient.get(`/settings/sync/expensePolicies`);
      return res.data;
    },
  },
  routePolicies: {
    sync: async () => {
      const res = await HttpClient.get(`/settings/sync/routePolicies`);
      return res.data;
    },
  },
  paymentTypes: {
    sync: async () => {
      const res = await HttpClient.get(`/settings/sync/paymentTypes`);
      return res.data;
    },
  },
  expenseTypes: {
    sync: async () => {
      const res = await HttpClient.get(`/settings/sync/expenseTypes`);
      return res.data;
    },
  },
  customFields: {
    sync: async () => {
      const res = await HttpClient.get(`/settings/sync/customFields`);
      return res.data;
    },
  },
  snippets: {
    sync: async () => {
      const res = await HttpClient.get(`/settings/sync/snippets`);
      return res.data;
    },
  },
  rejectionReasons: {
    sync: async () => {
      const res = await HttpClient.get(`/settings/sync/rejectionReasons`);
      return res.data;
    },
  },
  account: {
    sync: async () => {
      const res = await HttpClient.get(`/settings/sync/account`);
      return res.data;
    },
  },
};

export const effect = ({ action, scope, json }) => {
  return new Promise((resolve, reject) => {
    APIActions[scope][action](json)
      .then((r) => {
        resolve(r);
      })
      .catch((error) => {
        const formatedError = {
          headers: error?.response?.headers,
          code: error?.response?.data?.code,
          message: error?.response?.data?.message,
          status: error?.response?.status,
        };
        reject(formatedError);
      });
  });
};

import { createContext, useContext, useState } from "react";

const ThemeModeContext = createContext({});

const ThemeModeProvider = ({ children }) => {
  const localMode = window.localStorage.getItem("theme");
  const [mode, setMode] = useState(localMode || "light");

  const changeThemeMode = (newTheme) => {
    window.localStorage.setItem("theme", newTheme);
    setMode(newTheme);
  };
  return (
    <ThemeModeContext.Provider
      value={{
        mode,
        changeThemeMode,
      }}
    >
      {children}
    </ThemeModeContext.Provider>
  );
};

const useThemeMode = () => {
  const auth = useContext(ThemeModeContext);
  if (auth == null) {
    throw new Error("useThemeMode() called outside of a AuthProvider?");
  }
  return auth;
};

export { ThemeModeProvider, useThemeMode };

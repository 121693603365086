import {
  Close,
  DeleteOutlineOutlined,
  EditOutlined,
  WarningOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { memo, useCallback, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import MenuAddExpensesInRequest from "../../../components/menus/MenuAddExpensesInRequest";
import { openSnackbar } from "../../../store/features/base/snackbarBaseSlice";
import {
  expensesOfflineActions,
  selectExpenseById,
} from "../../../store/features/expensesSlice";
import ModalDelete from "../../ModalDelete";
import MoreOptions from "./MoreOptions";

const sxIcon = { fontSize: "24px" };
const sxIconButton = { p: 1.5, color: "inherit" };

const RemoveModal = memo(({ expenseId, open, onClose, closeExpense }) => {
  const dispatch = useDispatch();

  const handleDelete = async () => {
    closeExpense();
    dispatch(expensesOfflineActions.removeExpenses([expenseId]));
    dispatch(openSnackbar({ message: "Despesa removida" }));
  };

  return (
    <ModalDelete
      open={open}
      onClose={() => onClose("delete")}
      title="Excluir a despesa?"
      onDelete={handleDelete}
    />
  );
});

const AddToRequestModal = memo(({ expenseId, open, closeExpense, onClose }) => {
  return (
    <MenuAddExpensesInRequest
      open={open}
      onClose={() => onClose("addToRequest")}
      expensesIds={[expenseId]}
      onAdd={closeExpense}
    />
  );
});

const RemoveFromRequestModal = memo(
  ({ expenseId, closeExpense, open, onClose, requestId }) => {
    const dispatch = useDispatch();

    const handleRemove = useCallback(async () => {
      dispatch(
        expensesOfflineActions.removeFromRequest(requestId, [expenseId])
      );
      dispatch(
        openSnackbar({
          message: `Despesa removida do relatório`,
        })
      );
      closeExpense();
    }, [expenseId, requestId]);

    return (
      <>
        <ModalDelete
          open={open}
          onClose={() => onClose("removeFromRequest")}
          buttonText={"Confirmar"}
          title="Voltar despesa para avulsas?"
          subtitle={"A despesa será removida do relatório"}
          onDelete={handleRemove}
        />
      </>
    );
  }
);

const WarningButton = memo(({ warnings, onClick }) => {
  return (
    <>
      <Tooltip title="Ver alertas">
        <span>
          <IconButton
            color="warning"
            sx={sxIconButton}
            onClick={(e) => onClick(warnings)}
          >
            <WarningOutlined sx={{ ...sxIcon, color: "warning.main" }} />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
});

const TryAgainButton = memo(({ expenseId }) => {
  const dispatch = useDispatch();
  const expense = useSelector((s) => selectExpenseById(s, expenseId));
  return (
    <Button
      onClick={() => dispatch(expensesOfflineActions.setExpense(expense))}
      variant="contained"
      disableElevation
      sx={{ mr: 1 }}
    >
      <Typography variant="inherit" noWrap>
        Tentar novamente
      </Typography>
    </Button>
  );
});

function Header({
  onClose,
  onEdit,
  editMode,
  title = "",
  onSave,
  changed,
  onCancelEdit,
  expenseId,
  syncError,

  warnings = [],
  onViewWarnings = () => {},

  requestId,
  settings: {
    edit = true,
    remove = true,
    addToRequest = true,
    removeFromRequest = true,
  },
}) {
  const [modals, setModals] = useState({
    removeFromRequest: false,
    addToRequest: false,
    delete: false,
  });

  const openModal = useCallback((name) => {
    setModals((prev) => ({ ...prev, [name]: true }));
  }, []);
  const closeModal = useCallback((name) => {
    setModals((prev) => ({ ...prev, [name]: false }));
  }, []);

  return (
    <>
      <DialogTitle
        sx={{
          height: 55,
          px: 1.5,
          transition: ".15s ease",
        }}
        position={"relative"}
        component={"div"}
        display="flex"
        alignItems={"center"}
        bgcolor={(t) =>
          syncError && !editMode
            ? `${t.palette.error.main}09`
            : Boolean(warnings.length && !editMode)
            ? `${t.palette.warning.main}09`
            : "transparent"
        }
        borderBottom={1}
        borderColor={"divider"}
      >
        <IconButton
          color="inherit"
          sx={{ ml: -0.4, p: 1.5, mr: 0.5 }}
          onClick={onClose}
        >
          <Close sx={sxIcon} />
        </IconButton>

        {!editMode && (
          <>
            {syncError ? (
              <Typography
                variant="body2"
                fontWeight={"500"}
                color={"error.main"}
                noWrap
              >
                {syncError || ""}
              </Typography>
            ) : (
              <Typography
                variant="body2"
                fontSize={"1.1rem"}
                fontWeight={"500"}
                noWrap
              >
                {title || ""}
              </Typography>
            )}
          </>
        )}
        <Box flex={1} />
        {editMode ? (
          <>
            <Button onClick={onCancelEdit} sx={{ mr: 1 }}>
              Cancelar
            </Button>
            {syncError ? (
              <TryAgainButton expenseId={expenseId} />
            ) : (
              <Button
                disabled={!changed}
                onClick={onSave}
                variant="contained"
                disableElevation
                sx={{ mr: 1, px: 3 }}
              >
                Salvar
              </Button>
            )}
          </>
        ) : (
          <>
            {Boolean(warnings?.length) && (
              <WarningButton warnings={warnings} onClick={onViewWarnings} />
            )}
            {edit && (
              <Tooltip title="Editar">
                <IconButton sx={sxIconButton} onClick={onEdit}>
                  <EditOutlined sx={sxIcon} />
                </IconButton>
              </Tooltip>
            )}
            {remove && (
              <Tooltip title="Excluir">
                <span>
                  <IconButton
                    onClick={() => openModal("delete")}
                    sx={sxIconButton}
                  >
                    <DeleteOutlineOutlined sx={sxIcon} />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            {addToRequest && (
              <Button
                sx={{ mx: 1.5, display: { xs: "none", md: "flex" } }}
                variant="contained"
                disableElevation
                onClick={(e) => openModal("addToRequest")}
              >
                Adicionar a um relatório
              </Button>
            )}
            <MoreOptions
              remove={remove}
              addToRequest={addToRequest}
              edit={edit}
              removeFromRequest={removeFromRequest}
              requestId={requestId}
              expenseId={expenseId}
              onClose={onClose}
              onEdit={onEdit}
              openModal={openModal}
            />
          </>
        )}
      </DialogTitle>

      {remove && (
        <RemoveModal
          expenseId={expenseId}
          closeExpense={onClose}
          open={modals.delete}
          onClose={closeModal}
        />
      )}

      {addToRequest && (
        <AddToRequestModal
          open={modals.addToRequest}
          onClose={closeModal}
          expenseId={expenseId}
          closeExpense={onClose}
        />
      )}

      {removeFromRequest && requestId && (
        <RemoveFromRequestModal
          open={modals.removeFromRequest}
          onClose={closeModal}
          expenseId={expenseId}
          closeExpense={onClose}
          requestId={requestId}
        />
      )}
    </>
  );
}

function mapStateToProps(state, ownProps) {
  const { processingError, request_id } = selectExpenseById(
    state,
    ownProps.expenseId
  ) || {
    processingError: null,
    request_id: null,
  };
  return {
    syncError: processingError,
    requestId: request_id,
    ...ownProps,
  };
}

export default connect(mapStateToProps)(memo(Header));

import { Checkbox } from "@mui/material";
import React, { memo } from "react";

function CheckboxArea({ selected, onChangeSelected }) {
  return (
    <Checkbox
      onClick={(e) => {
        e.stopPropagation();
        onChangeSelected();
      }}
      sx={{
        p: .8,
        position: "absolute",
        left: -11,
        top: -11,
        zIndex: 100,
        bgcolor: "background.paper",
        ":hover": {
          bgcolor: "background.paper",
          color: "primary.main",
        },
      }}
      checked={Boolean(selected)}
    />
  );
}

export default memo(CheckboxArea);

import { CurrencyExchangeOutlined, PaymentOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React, { memo } from "react";
import { formatDate } from "../../../../utils/more/date_functions";
import { CurrencyFormat } from "../../../../utils/more/mask_functions";
import { CustomIcon } from "./MoreDetails";

function AmountArea({
  refundable,
  amount,
  amountConverted,
  currency,
  accountCurrency,
  date,
  paymentType,
}) {
  return (
    <Box zIndex={1} py={0.3} pt={0.5} display={"flex"} alignItems={"center"}>
      <Typography
        noWrap
        mr={0.5}
        fontWeight={amount ? "600" : "500"}
        fontSize={"1.1rem"}
        color={amount ? "text.primary" : "text.secondary"}
      >
        {currency} <CurrencyFormat value={amount} />
      </Typography>
      <CustomIcon
        tooltip={refundable ? "Reembolsável" : "Não reembolsável"}
        color={refundable ? "primary" : "action"}
        Icon={CurrencyExchangeOutlined}
      />
      {paymentType && (
        <CustomIcon
          tooltip={paymentType}
          color={"action"}
          Icon={PaymentOutlined}
        />
      )}
      <Box flex={1} />
      <Typography
        component={"div"}
        display={"flex"}
        fontWeight={"500"}
        alignItems={"center"}
        color={"text.secondary"}
        fontSize={".75rem"}
        mb={1}
      >
        {formatDate(date)}
      </Typography>
    </Box>
  );
}

export default memo(AmountArea);

import { Box, Typography } from "@mui/material";
import React, { memo } from "react";

const EmptyContent = ({
  artComponent,
  actionComponent,
  title,
  subtitle,
  sx,
}) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      width={"100%"}
      p={3}
      color={"text.primary"}
      pb={"50px"}
      sx={sx}
    >
      {artComponent}
      {title && (
        <Typography mt={3} variant="h5" fontWeight={"500"} textAlign={"center"}>
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography
          mt={0.5}
          maxWidth={590}
          variant="body1"
          fontWeight={"500"}
          textAlign={"center"}
          color={"text.secondary"}
        >
          {subtitle}
        </Typography>
      )}
      <Box mt={2} display={"flex"} justifyContent={"center"}>
        {actionComponent}
      </Box>
    </Box>
  );
};

export default memo(EmptyContent);

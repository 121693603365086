import { Close } from "@mui/icons-material";
import { IconButton, MenuItem } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { selectCustomFieldById } from "../../store/features/configs/customFieldsSlice";
import HelpIconButton from "../buttons/HelpIconButton";
import SelectInput from "./Select";
import Input from "./text-input";

const CustomField = ({
  value,
  variant,
  readOnly,
  onChange = () => {},
  customFieldId,
  margin,
  size,
}) => {
  const data = useSelector((state) =>
    selectCustomFieldById(state, customFieldId)
  );

  const {
    inputType: { category, options },
    helperText,
    label,
    isRequired,
  } = data || {};

  const handleChange = (v) => {
    onChange(customFieldId, v);
  };

  if (category === "select") {
    return (
      <SelectInput
        helperText={helperText}
        margin={margin}
        label={label}
        variant={variant}
        value={value}
        onChange={handleChange}
        required={isRequired}
        readOnly={readOnly}
        size={size}
        endIcon={
          <>
            {Boolean(value) && (
              <IconButton
                sx={{ mr: 2 }}
                onClick={() => handleChange("")}
                size={size}
              >
                <Close />
              </IconButton>
            )}
            {Boolean(helperText) && (
              <HelpIconButton sx={{ mr: 3 }}>{helperText}</HelpIconButton>
            )}
          </>
        }
      >
        {options
          ?.filter((option) => option?.active)
          ?.map((option) => (
            <MenuItem
              sx={{ height: 45, fontSize: ".95rem" }}
              key={option?.id}
              value={option?.id}
            >
              {option?.text}
            </MenuItem>
          ))}
      </SelectInput>
    );
  } else if (category === "text") {
    return (
      <Input
        value={value}
        onChange={handleChange}
        variant={variant}
        label={label}
        required={isRequired}
        readOnly={readOnly}
        RightIcon={() => {
          return (
            Boolean(helperText) && <HelpIconButton>{helperText}</HelpIconButton>
          );
        }}
        margin={margin}
        size={size}
      />
    );
  } else return <></>;
};

export default CustomField;

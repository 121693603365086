import { InfoRounded } from "@mui/icons-material";
import { InputAdornment, TextField, Tooltip, Typography } from "@mui/material";
import React from "react";

export default function Input({
  label,
  width,
  className,
  type,
  LeftIcon,
  maxLength,
  RightIcon,
  size,
  color,
  placeholder,
  variant,
  onMouseDown,
  value,
  helperText = "",
  error,
  onChange = () => {},
  clearError = () => {},
  onFocus,
  onBlur,
  required,
  inputComponent,
  inputSx,
  defaultValue,
  multiline,
  autoFocus,
  readOnly,
  sx,
  maxRows,
  onClick,
  rows,
  disabled,
  action,
  tooltipErrorIcon,
  accept,
  onKeyDown,
  fontSize,
  margin,
  hiddenLabel,
  id,
  inputRef,
}) {

  const handleChange = (e) => {
    onChange(e.target.value);
    if (error) {
      clearError();
    }
  };

  return (
    <TextField
      accept={accept}
      id={id}
      sx={{
        width: width || "100%",
        ...sx,
      }}
      margin={margin}
      inputRef={inputRef}
      onKeyDown={onKeyDown}
      disabled={disabled}
      onClick={onClick}
      inputProps={{ maxLength: maxLength, readOnly: readOnly }}
      placeholder={placeholder}
      className={className}
      defaultValue={defaultValue}
      autoFocus={autoFocus}
      type={type}
      required={required}
      label={label}
      size={size}
      onChange={handleChange}
      value={value}
      variant={variant}
      error={error}
      onMouseDown={onMouseDown}
      onFocus={onFocus}
      onBlur={onBlur}
      maxRows={maxRows}
      rows={rows}
      hiddenLabel={hiddenLabel}
      multiline={multiline}
      helperText={
        error ? (
          <Typography
            component={"span"}
            ml={-1}
            display={"flex"}
            alignItems={"flex-start"}
            variant="inherit"
            fontWeight={"500"}
            fontSize={".83rem"}
          >
            <InfoRounded
              color="error"
              sx={{ mr: 1, mt: 0.5, fontSize: "1rem" }}
            />
            {helperText}
          </Typography>
        ) : (
          helperText
        )
      }
      InputProps={{
        sx: { fontSize, color, ...inputSx },
        inputComponent: inputComponent,
        startAdornment: LeftIcon && (
          <InputAdornment position={"start"}>
            <LeftIcon fontSize={size} />
          </InputAdornment>
        ),
        endAdornment: action ? (
          <InputAdornment position={"end"}>{action}</InputAdornment>
        ) : (
          RightIcon && (
            <InputAdornment position={"end"}>
              {!action ? (
                <Tooltip
                  title={tooltipErrorIcon || ""}
                  disableFocusListener={!tooltipErrorIcon}
                  disableHoverListener={!tooltipErrorIcon}
                >
                  <span>
                    <RightIcon color={error ? "error" : ""} fontSize={size} />
                  </span>
                </Tooltip>
              ) : (
                action
              )}
            </InputAdornment>
          )
        ),
      }}
    ></TextField>
  );
}

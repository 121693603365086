import { startOfDay } from "date-fns";
import { store } from "../store/store";

const hoursMS = 3600000;

const compareByOparator = (op, v1, v2) => {
  switch (op) {
    case "GT":
      return Boolean(v1 > v2);
    case "LT":
      return Boolean(v1 < v2);
    case "LTE":
      return Boolean(v1 <= v2);
    case "GTE":
      return Boolean(v1 >= v2);
    case "EQ":
      return Boolean(v1 === v2);
    default:
      throw new Error(`"${op}" é um operador inválido.`);
  }
};

const compareDates = (num, op, dmy, from, equalTo, date) => {
  if (op === "EQ") {
    const aux = startOfDay(new Date(equalTo));

    return date.getTime() === aux.getTime();
  }

  const aux =
    from?.type === "current"
      ? startOfDay(new Date())
      : startOfDay(new Date(from?.meta));

  let t, d;
  let mult = 1;
  if (op === "A") mult = -1;

  switch (dmy) {
    case "D":
      d = (mult * (aux.getTime() - date.getTime())) / (24 * hoursMS);
      return d > num;
    case "M":
      d = (mult * (aux.getTime() - date.getTime())) / (30 * 24 * hoursMS);
      return d > num;
    case "Y":
      d = (mult * (aux.getTime() - date.getTime())) / (12 * 30 * 24 * hoursMS);
      return d > num;
    default:
      throw new Error("Invalid operator date");
  }
};

function matchRuleConditionExpense(_expense, condition) {
  let isMatch = [];
  // expense is route (get amount/calculate)
  const expense = JSON.parse(JSON.stringify(_expense));

  for (const key in condition) {
    if (Object.hasOwnProperty.call(condition, key)) {
      const props = condition[key];
      switch (key) {
        case "amount":
          isMatch.push(
            compareByOparator(props.op, expense.amount, props.amount)
          );
          break;
        case "date":
          isMatch.push(
            compareDates(
              props.num,
              props.op,
              props.dmy,
              props.from,
              props.equalTo,
              startOfDay(new Date(expense.date))
            )
          );
          break;
        case "exptypes":
          if (props.empty) {
            isMatch.push(!expense.type_id);
          } else {
            isMatch.push(
              expense.type_id &&
                props.meta &&
                props.meta.includes(expense.type_id.toString())
            );
          }
          break;
        case "projects":
          if (props.empty) {
            isMatch.push(!expense.project_id);
          } else {
            isMatch.push(
              expense.project_id &&
                props.meta &&
                props.meta.includes(expense.project_id.toString())
            );
          }
          break;
        case "paytypes":
          if (props.empty) {
            isMatch.push(!expense.payment_type);
          } else {
            isMatch.push(
              expense.payment_type &&
                props.meta &&
                props.meta.includes(expense.payment_type.toString())
            );
          }
          break;
        case "currency_t":
          isMatch.push(expense.currency_t && props.curr === expense.currency_t);
          break;
        case "route":
          if (!expense.is_route) {
            isMatch.push(false);
          } else {
            let passMode = false;
            switch (props.mode) {
              case "all":
                passMode = true;
                break;
              default:
                passMode = expense.route_mode === props.mode;
                break;
            }
            let passDistance = true;
            if (props.distance) {
              passDistance = compareByOparator(
                props.distance.op,
                expense.distance || 0,
                props.distance.amount
              );
            }
            isMatch.push(passMode && passDistance);
          }
          break;
        case "routePolicies":
          isMatch.push(
            expense.is_route &&
              expense.route_policy_id &&
              props.meta &&
              props.meta.includes(expense.route_policy_id.toString())
          );
          break;
        case "snippets":
          if (props.empty) {
            isMatch.push(
              expense.is_route &&
                expense.route_mode === "manual" &&
                !expense.snippet_id
            );
          } else {
            isMatch.push(
              expense.is_route &&
                expense.route_mode === "manual" &&
                expense.snippet_id &&
                props.meta &&
                props.meta.includes(expense.snippet_id.toString())
            );
          }
          break;
        case "is_refundable":
          isMatch.push(props.b ? expense.refundable : !expense.refundable);
          break;
        case "total_receipts":
          if (expense.is_route && expense.route_mode !== "manual") {
            isMatch.push(false);
          } else {
            isMatch.push(
              compareByOparator(
                props.op,
                (expense.receipts || []).length,
                props.amount
              )
            );
          }
          break;
        default:
          console.error(
            `"${key}" é um operador inválido. Defin. Políticas de despesas em myexpenses`
          );
          break;
      }
    }
  }
  return isMatch.every((b) => Boolean(b));
}

export default (expenseData = {}) => {
  const {
    date,
    amount,
    type_id,
    project_id,
    is_route,
    payment_type,
    refundable,
    route_mode,
    distance,
    currency_t,
    route_policy_id,
    snippet_id,
  } = expenseData;

  const rulesStore = store.getState().expensePolicies || {
    ids: [],
    entities: {},
  };

  const rulesIds = rulesStore.ids;
  const rulesEntities = rulesStore.entities;

  const rules = rulesIds
    ?.filter((ruleId) => Boolean(rulesEntities[ruleId]?.active))
    ?.map((ruleId) => rulesEntities[ruleId]);

  // Loop in rules searching matching conditions
  const matchingRules = [];
  for (let i = 0; i < rules.length; i++) {
    const rule = rules[i];
    if (
      matchRuleConditionExpense(
        {
          date,
          amount,
          refundable,
          is_route,
          type_id,
          project_id,
          payment_type,
          route_mode,
          distance,
          currency_t,
          route_policy_id,
          snippet_id,
        },
        rule.conditions
      )
    ) {
      matchingRules.push({
        id: rule.id,
        name: rule.name,
        actions: rule.actions,
      });
    }
  }
  return matchingRules;
};

import {
  AssignmentOutlined,
  CurrencyExchangeOutlined,
  NotesOutlined,
  PaymentOutlined,
  PostAddOutlined,
  TodayOutlined,
} from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@mui/material";
import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AvatarType from "../../../../../components/avatar/AvatarType";
import { selectExpenseTypesEntities } from "../../../../../store/features/configs/expenseTypesSlice";
import { selectPaymentTypesEntities } from "../../../../../store/features/configs/paymentTypesSlice";
import { curr } from "../../../../../utils/more/currency_country";
import {
  formatDate,
  formatMoment,
} from "../../../../../utils/more/date_functions";
import { CurrencyFormat } from "../../../../../utils/more/mask_functions";
import Baseline from "./components/Baseline";

function ViewMode({ expense, showRequest, callback }) {
  const {
    amount,
    currency,
    notes,
    date,
    refundable,
    project_name,
    type_id,
    payment_type,
    created_at,
    request_title,
    request_id,
  } = expense || {};

  const navigate = useNavigate();

  const expTypesEntities = useSelector(selectExpenseTypesEntities);
  const paymentTypesEntities = useSelector(selectPaymentTypesEntities);

  const expType = useMemo(
    () => type_id && expTypesEntities[type_id],
    [type_id, expTypesEntities]
  );

  const paymentType = useMemo(
    () => payment_type && paymentTypesEntities[payment_type],
    [payment_type, paymentTypesEntities]
  );

  return (
    <Box display={"flex"} flexDirection={"column"} gap={4} mt={0.4}>
      <Box>
        <Box display={"flex"} alignItems={"flex-start"}>
          <Box>
            <Typography fontWeight={"500"} fontSize={"2rem"} variant="h4">
              {curr(currency)} <span style={{ marginLeft: "10px" }}> </span>{" "}
              <CurrencyFormat value={amount || 0} />
            </Typography>
          </Box>
          <Box flex={1} />
          <Box mt={1} ml={1} display={"flex"} alignItems={"center"}>
            <CurrencyExchangeOutlined
              fontSize="small"
              color={refundable ? "primary" : "disabled"}
              sx={{ mr: 1 }}
            />
            <Typography variant="body2">
              {refundable ? "Reembolsável" : "Não reembolsável"}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Baseline
        Icon={() => <AvatarType icon={expType?.icon} size={35} fz="1.5rem" />}
        value={expType?.name}
        emptyLabel="Sem categoria"
        label={"Categoria"}
      />
      <Baseline
        Icon={TodayOutlined}
        label={"Data da despesa"}
        value={formatDate(date)}
      />
      <Baseline
        Icon={PaymentOutlined}
        value={paymentType?.name || ""}
        emptyLabel="Sem forma de pagamento"
        label={"Forma de pagamento"}
        error={!paymentType?.active && "Forma de pagamento inativa"}
      />
      <Baseline
        Icon={PostAddOutlined}
        value={project_name}
        label={"Projeto"}
        emptyLabel="Sem projeto"
      />
      <Baseline
        Icon={NotesOutlined}
        value={notes || ""}
        label={"Descrição"}
        emptyLabel="Sem descrição"
      />
      {showRequest && (
        <Box width={"100%"} display={"flex"} alignItems={"center"}>
          <Baseline
            Icon={AssignmentOutlined}
            value={request_title}
            label={"Relatório"}
            emptyLabel="Sem relatório"
          />
          {Boolean(request_title) && (
            <Button
              onClick={() => {
                navigate(`/approval-requests/${request_id}`);
                if (callback && typeof callback === "function") {
                  callback();
                }
              }}
              variant="outlined"
              sx={{ ml: "auto" }}
            >
              Ver relatório
            </Button>
          )}
        </Box>
      )}
      <Divider sx={{ mx: -3 }} />
      <Typography variant="body2" color={"text.secondary"}>
        Criada em{" "}
        <Typography
          fontWeight={"500"}
          color={"text.primary"}
          component={"span"}
          variant="body2"
        >
          {formatMoment(created_at || new Date())}
        </Typography>
      </Typography>
    </Box>
  );
}

export default memo(ViewMode);

import { Box } from "@mui/material";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectAccountCurrency } from "../../../../../store/features/accountSlice";
import Form from "../../../../expense-creation/new-route/components/Form";
import RouteSection from "../../../../expense-creation/new-route/components/route-section/RouteSection";

function EditContent({
  expense,
  amount,
  currentRate,
  onCalculateRoutes,
  onChangeValue,
  dateInvalid,
  reoderRoutes,
  predefinedValues = {},
  routePolicyIsDetached,
}) {
  const [routes, setRoutes] = useState([]);

  const currency = useSelector((state) => selectAccountCurrency(state, true));

  useEffect(() => {
    const prevRoutes = [
      expense?.from,
      ...(expense.waypoints || []),
      expense?.to,
    ];
    setRoutes(prevRoutes);
  }, []);

  const handleChangeRoutes = useCallback((newRoutes) => {
    setRoutes(newRoutes);
  }, []);

  const handleUpdateRoute = useCallback((idx, value) => {
    setRoutes((prev) => {
      prev.splice(idx, 1, value);
      return [...prev];
    });
  }, []);

  const handleRevertRoutes = useCallback(() => {
    let reversed = Object.assign([], routes);
    reversed.reverse();
    handleChangeRoutes(reversed);
    onCalculateRoutes(reversed);
  }, [routes]);

  const handleCalculateFunction = useCallback(
    () => onCalculateRoutes([...routes]),
    [routes]
  );

  const handleAddRoute = useCallback(() => {
    setRoutes((prev) => {
      prev.push("");
      return [...prev];
    });
  }, []);

  const handleRemoveRoute = useCallback(
    (idx) => {
      setRoutes((prev) => {
        prev.splice(idx, 1);
        return [...prev];
      });
      handleCalculateFunction();
    },
    [handleCalculateFunction]
  );

  const handleDragEnd = useCallback(
    (param) => {
      const startIndex = param.source.index;
      const endIndex = param.destination.index;

      const result = Array.from(routes);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      setRoutes(result);
      reoderRoutes(startIndex, endIndex);
    },
    [routes]
  );

  return (
    <Box mt={-3} ml={1.5}>
      <RouteSection
        routes={routes}
        updateRoute={handleUpdateRoute}
        revertRoutes={handleRevertRoutes}
        calculateFunction={handleCalculateFunction}
        addRoute={handleAddRoute}
        removeRoute={handleRemoveRoute}
        unit={"km"}
        onDragEnd={handleDragEnd}
      />
      <Form
        routePolicyIsDetached={routePolicyIsDetached}
        dateInvalid={dateInvalid}
        onChangeValue={onChangeValue}
        rate={currentRate}
        predefinedValues={predefinedValues}
        hiddenRequestInput
        currency={currency}
        values={{
          distance: expense.distance || 0,
          amount,
          unit: "km",
          notes: expense.notes,
          to_request: null,
          refundable: expense.refundable,
          project_id: expense.project_id,
          route_policy_id: expense.route_policy_id,
          date: new Date(expense.date),
        }}
      />
    </Box>
  );
}

export default memo(EditContent);

import {
  Close,
  DownloadOutlined,
  OpenInNewOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { HttpClient } from "../../api/httpClient";
import { BASE_URL } from "../../api/urls";
import { selectApprovalRequestById } from "../../store/features/approvalRequestsSlice";
import { setError } from "../../store/features/base/errorBaseSlice";
import {
  selectRequestPDFSettings,
  setRequestPDFSetting,
} from "../../store/features/base/settingsSlice";
import { openSnackbar } from "../../store/features/base/snackbarBaseSlice";
import { closeElement } from "../../store/features/portalSlice";
import { selectRequestById } from "../../store/features/requestsSlice";
import { requestItemData } from "../../utils/initialStates";
import SelectGroupBy from "./components/SelectGroupBy";
import SelectOrderBy from "./components/SelectOrderBy";
import ToggleOrder from "./components/ToggleOrder";

const ModalRequestExportPDF = ({
  open,
  onClose,
  request = {},
  settings = {
    groupBy: "category",
    order: "desc",
    orderBy: "date",
  },
  requestId,
  onChangeSetting = () => {},
  setError = () => {},
  openSnackbar = () => {},
  receiptsAccessToken,
  userId,
  isApprover,
}) => {
  const [loading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [hideReceipts, setHideReceipts] = useState(false);
  const [hideHistory, setHideHistory] = useState(false);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  const handleExport = async (download = false) => {
    setLoading(true);
    try {
      const {
        data: { file_url },
      } = await HttpClient.post(`/requests/${requestId}/export/pdf`, {
        token: receiptsAccessToken,
        user_id: userId,
        is_approver: Boolean(isApprover),
        filename: request?.title,
        groupBy: settings?.groupBy, //date,category,project,
        order: settings?.order, // desc,
        orderBy: settings?.orderBy, //category,amount,date,payment
        hideReceipts,
        hideHistory,
        download,
      });

      const URL = BASE_URL + file_url;

      if (download) {
        const link = document.createElement("a");
        link.download = request?.title;
        link.href = URL;
        link.click();
        openSnackbar({
          message: "Transferido para downloads",
        });
      } else {
        window.open(URL, "_blank");
      }
    } catch (error) {
      setError({
        title: "Erro carregar PDF",
        error,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChangeSetting = useCallback(onChangeSetting, [onChangeSetting]);

  useEffect(() => {
    if (!open) {
      setLoading(false);
    }
  }, [open]);

  return (
    open && (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="xs"
        fullWidth
        fullScreen={isSmall}
      >
        {loading && <LinearProgress />}
        <DialogTitle variant="h5" fontWeight={"500"}>
          <Box flex={1}>
            <Typography
              mb={0.4}
              fontWeight={"500"}
              variant="body2"
              color={"text.secondary"}
            >
              Exportar PDF
            </Typography>
            <Typography noWrap maxWidth={350} variant="inherit">
              {request?.title}
            </Typography>
          </Box>
          <IconButton onClick={onClose} sx={{ m: -1, mt: -3 }}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ pb: 5 }}>
          <SelectGroupBy
            disabled={loading}
            value={settings?.groupBy}
            onChange={handleChangeSetting}
          />
          <SelectOrderBy
            disabled={loading}
            value={settings?.orderBy}
            onChange={handleChangeSetting}
          />
          <ToggleOrder
            disabled={loading}
            value={settings?.order}
            orderBy={settings?.orderBy}
            onChange={handleChangeSetting}
          />
          <Divider sx={{ my: 2, mx: -3 }} />
          <Button
            onClick={() => setShowMore(!showMore)}
            sx={{ ml: -1, px: 1 }}
            size="small"
          >
            {showMore ? "Mostrar menos" : "Mais opções"}
          </Button>
          <Collapse in={showMore}>
            <FormControlLabel
              sx={{ mt: 1 }}
              label="Ocultar comprovantes"
              control={
                <Checkbox
                  checked={hideReceipts}
                  onClick={() => setHideReceipts(!hideReceipts)}
                />
              }
            />
            <FormControlLabel
              label="Ocultar histórico do relatório"
              control={
                <Checkbox
                  checked={hideHistory}
                  onClick={() => setHideHistory(!hideHistory)}
                />
              }
            />
          </Collapse>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Fechar
          </Button>
          <Box flex={1} />
          <Button
            disabled={loading}
            startIcon={<OpenInNewOutlined />}
            variant="outlined"
            onClick={() => handleExport(false)}
          >
            Visualizar
          </Button>
          <Button
            startIcon={<DownloadOutlined />}
            disableElevation
            variant="contained"
            disabled={loading}
            onClick={() => handleExport(true)}
          >
            Exportar
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

function mapStateToProps(state, ownProps) {
  const userId = state?.portal?.requestExportPDF?.util?.userId;
  const isApprover = state?.portal?.requestExportPDF?.util?.isApprover;
  const requestId = state?.portal?.requestExportPDF?.util?.requestId;
  return {
    open: state?.portal?.requestExportPDF?.open,
    request:
      (isApprover
        ? selectApprovalRequestById(state, requestId)
        : selectRequestById(state, requestId)) || requestItemData,
    settings: selectRequestPDFSettings(state),
    requestId,
    userId: userId || state.account.baseInfo.userId,
    receiptsAccessToken: state.account.receiptsAccessToken.token,
    isApprover,
    ...ownProps,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onClose: () => dispatch(closeElement("requestExportPDF")),
    onChangeSetting: (setting, value) =>
      dispatch(
        setRequestPDFSetting({
          setting,
          value,
        })
      ),
    setError: (data) => dispatch(setError(data)),
    openSnackbar: (data) => dispatch(openSnackbar(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalRequestExportPDF);

import {
  DeleteOutlineOutlined,
  FilePresentOutlined,
  PictureAsPdfOutlined,
  RefreshOutlined,
  SendOutlined,
} from "@mui/icons-material";
import { Box, Menu, MenuItem, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { openElement } from "../../../../../../store/features/portalSlice";
import { exportRequestToExcel } from "../../../../../../store/features/requestsSlice";
import DeleteRequest from "../../functions-layers/DeleteRequest";
import ReopenRequest from "../../functions-layers/ReopenRequest";
import SendToApproval from "../../functions-layers/SendToApproval";

const CustomMenuItem = ({ Icon, title = "", onClick, disabled }) => {
  return (
    <MenuItem
      sx={{ fontWeight: "600", fontSize: ".95rem", height: 45 }}
      disabled={disabled}
      onClick={onClick}
    >
      {Icon && <Icon color="action" fontSize="small" sx={{ mr: 2 }} />}
      {title}
    </MenuItem>
  );
};

function MoreMenu({
  requestId,
  status,
  anchorEl,
  onClose,
  onBackToOpen,
  onRefresh,
  advanced,
  exportPDF,
}) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Menu
      disableScrollLock
      slotProps={{
        paper: {
          sx: {
            boxShadow: 5,
            minWidth: 300,
          },
        },
      }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: "center", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Box>
        {status === "R" && (
          <ReopenRequest
            onReopen={onBackToOpen}
            requestId={requestId}
            renderComponent={({ disabled, onConfirm }) => (
              <CustomMenuItem
                disabled={disabled}
                onClick={onConfirm}
                title="Reabrir relatório"
              />
            )}
          />
        )}
        {status === "O" && isSmall && (
          <SendToApproval
            requestId={requestId}
            renderComponent={({ disabled, onConfirm }) => (
              <CustomMenuItem
                disabled={disabled}
                onClick={onConfirm}
                Icon={SendOutlined}
                title="Enviar para aprovação"
              />
            )}
          />
        )}
        <CustomMenuItem
          Icon={RefreshOutlined}
          onClick={() => {
            onRefresh();
            onClose();
          }}
          title="Atualizar"
        />

        {exportPDF && (
          <CustomMenuItem
            Icon={PictureAsPdfOutlined}
            onClick={() => {
              dispatch(
                openElement({
                  name: "requestExportPDF",
                  util: { requestId },
                })
              );
            }}
            title="Exportar PDF"
          />
        )}
        {exportPDF && (
          <CustomMenuItem
            Icon={FilePresentOutlined}
            onClick={() => {
              exportRequestToExcel(requestId, false);
            }}
            title="Exportar para Excel"
          />
        )}

        {["O", "R"].includes(status) && !advanced && (
          <DeleteRequest
            requestId={requestId}
            renderComponent={({ disabled, onConfirm }) => (
              <CustomMenuItem
                disabled={disabled}
                Icon={DeleteOutlineOutlined}
                onClick={onConfirm}
                title="Excluir"
              />
            )}
          />
        )}
      </Box>
    </Menu>
  );
}

export default MoreMenu;

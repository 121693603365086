import { Box, CircularProgress } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchRequests,
  selectRequestsIdsByStatus,
} from "../../../../store/features/requestsSlice";
import RequestsMapper from "../RequestsMapper";

function FatcherRenderer({ status }) {
  const dispatch = useDispatch();

  const requests = useSelector((state) =>
    selectRequestsIdsByStatus(state, status)
  );

  const [noDataResponseCount, setNoDataResponseCount] = useState(0);

  const working = useSelector((state) => state.requests.working);

  const handleFetchRequests = useCallback(async () => {
    if (noDataResponseCount > 2 || working) return;
    dispatch(
      fetchRequests({
        skip: requests?.length,
        limit: 50,
        status: status === "all" ? "A,R,F,O,P" : status,
      })
    )
      .unwrap()
      .then((result) => {
        if (result && result?.length === 0) {
          setNoDataResponseCount((prev) => prev + 1);
        }
      });
  }, [status, requests?.length, noDataResponseCount, working]);

  const handleOnEndReached = useCallback(() => {
    if (working) return;
    if (
      parseInt(
        parseInt(window.innerHeight) +
          parseInt(document.documentElement.scrollTop)
      ) === parseInt(document.scrollingElement.scrollHeight)
    ) {
      handleFetchRequests();
    }
  }, [working]);

  useEffect(() => {
    handleFetchRequests();
    setNoDataResponseCount(0);
  }, [status]);

  useEffect(() => {
    const handleScroll = () => {
      handleOnEndReached();
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleOnEndReached]);

  return (
    <>
      <RequestsMapper requests={requests} />
      <Box
        height={100}
        visibility={working ? "visible" : "hidden"}
        display={"flex"}
        justifyContent={"center"}
      >
        <CircularProgress size={30} />
      </Box>
    </>
  );
}

export default React.memo(FatcherRenderer);

import { Close, DocumentScannerOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { nanoid } from "nanoid";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DropzoneReceipt from "../../components/dropzone/dropzone-receipt/DropzoneReceipt";
import { startScanExpenses } from "../../scanner_expenses";
import { selectAccountCurrency } from "../../store/features/accountSlice";
import { setError } from "../../store/features/base/errorBaseSlice";
import { upsertManyExpenses } from "../../store/features/expensesSlice";
import {
  closeElement,
  selectPortalIsOpened,
} from "../../store/features/portalSlice";
import { expenseItemData } from "../../utils/initialStates";
import { getBlob } from "../../utils/more/images_transform";
import ModalCropper from "../cropper/Cropper";

const ScanExpensesModal = (props) => {
  const dispatch = useDispatch();

  const accountCurrency = useSelector(selectAccountCurrency);
  const open = useSelector((state) =>
    selectPortalIsOpened(state, "scanExpenses")
  );

  const onClose = useCallback(() => dispatch(closeElement("scanExpenses")), []);

  const [files, setFiles] = useState([]);
  const [cropper, setCropper] = useState(null);

  useEffect(() => {
    if (!open) {
      setFiles([]);
      setCropper(null);
    }
  }, [open]);

  const handleOnDrop = async (acceptedFiles) => {
    let prepared = [];
    for (let index = 0; index < acceptedFiles.length; index++) {
      const file = acceptedFiles[index];
      const blob = await getBlob(file);
      const receiptId = nanoid(5);
      try {
        const data = {
          id: receiptId,
          filename: file.path,
          uri: URL.createObjectURL(file),
          type: file.type,
          blob: new Blob([blob], { type: file.type }),
        };
        prepared.push(data);
      } catch (error) {
        dispatch(setError({ title: `Falha ao carregar arquivos` }));
      }
    }
    if (prepared && prepared.length) {
      setFiles((prev) => {
        return [...prepared, ...prev];
      });
    }
  };

  const handleCropReceipt = (fileId, blob, previewUrl, origin, revert) => {
    const selectedFile = files.find((item) => item.id === fileId);
    if (!selectedFile) return;
    selectedFile.blob = blob;
    selectedFile.uri = previewUrl;
    if (revert) {
      delete selectedFile.origin;
    } else if (origin) {
      selectedFile.origin = origin;
    }
    setFiles((prev) => {
      prev.splice(
        prev.findIndex((item) => item.id === fileId),
        1,
        selectedFile
      );
      return [...prev];
    });
  };

  const handleRemoveFile = (event, id) => {
    setFiles((prev) => {
      const index = prev.findIndex((file) => file.id === id);
      if (index !== -1) {
        prev.splice(index, 1);
      }
      return [...prev];
    });
  };

  const handleAddFiles = () => {
    onClose();
    const newExpenses = files.map((file) => {
      const { uri, filename, type, id: receiptId, blob } = file;
      const expenseId = nanoid(6);
      return {
        ...expenseItemData,
        created_at: new Date().toISOString(),
        id: expenseId,
        status: "O",
        request_id: null,
        account_currency: accountCurrency,
        currency: accountCurrency,
        receipts: [
          {
            id: receiptId,
            filename,
            type,
            uri,
            blob,
          },
        ],
        processingStatus: "scanning",
        hidden: false,
      };
    });
    dispatch(upsertManyExpenses(newExpenses));
    startScanExpenses(newExpenses);
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          height: "100%",
          maxWidth: 620,
        },
      }}
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <DocumentScannerOutlined color="primary" sx={{ mr: 2 }} />
        Escanear comprovantes {files?.length > 0 ? `(${files?.length})` : ""}
        <Box flex={1} />
        <IconButton onClick={onClose} sx={{ m: -1 }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        <DialogContentText>
          Insira seus comprovantes e aproveite para tomar um café ☕️. <br /> Nós criaremos
          automaticamente as despesas para você.
        </DialogContentText>
        <DropzoneReceipt
          files={files}
          disableCrop={false}
          onDrop={handleOnDrop}
          onClickRemove={handleRemoveFile}
          onClickCrop={useCallback(
            (fileId) => {
              const file = files.find((item) => item.id === fileId);
              if (!file) return;
              setCropper(file);
            },
            [files, cropper]
          )}
          enableCropFiles={
            files && files.filter((file) => !Boolean(file.cropped))
          }
          containerStyle={{ mt: 4 }}
        />
        <ModalCropper
          file={cropper || {}}
          open={Boolean(cropper)}
          onClose={() => setCropper(null)}
          onCrop={handleCropReceipt}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          onClick={handleAddFiles}
          disabled={!Boolean(files.length)}
          autoFocus
          variant="contained"
          disableElevation
        >
          Adicionar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(ScanExpensesModal);

import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { APIActions } from "../../../queues/offline-queue/effect";
import normalize from "../../../utils/normalize";

const baseName = "expensePolicies";

const adapter = createEntityAdapter({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) =>
    a.name?.toUpperCase().localeCompare(b.name?.toUpperCase()),
});

const initialState = adapter.getInitialState({
  working: false,
});

export const syncExpensePolicies = createAsyncThunk(
  `${baseName}/syncExpensePolicies`,
  async () => {
    const res = await APIActions.expensePolicies.sync();
    return res;
  }
);

export const expensePoliciesSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    setupExpensePolicies(state, { payload }) {
      const { entities } = normalize(payload);
      adapter.setAll(state, entities);
    },
    upsertExpensePolicie(state, { payload }) {
      adapter.upsertOne(state, payload);
    },
    removeExpensePolicie(state, { payload }) {
      adapter.removeOne(state, payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(syncExpensePolicies.pending, (state, action) => {
        state.working = true;
      })
      .addCase(syncExpensePolicies.rejected, (state, action) => {
        state.working = false;
      })
      .addCase(syncExpensePolicies.fulfilled, (state, action) => {
        state.working = false;
        const data = action.payload || [];
        expensePoliciesSlice.caseReducers.setupExpensePolicies(state, {
          payload: data,
        });
      });
  },
});

export const {
  setupExpensePolicies,
  upsertExpensePolicie,
  removeExpensePolicie,
} = expensePoliciesSlice.actions;

export const {
  selectAll: selectAllExpensePolicies,
  selectById: selectExpensePoliciesById,
  selectIds,
  selectEntities: selectExpensePoliciesEntities,
} = adapter.getSelectors((state) => state[baseName]);

export const selectExpensePoliciesIds = createSelector(
  [selectAllExpensePolicies, (state, showInactive) => showInactive],
  (data, showInactive) => {
    return data
      .filter((item) => (showInactive ? true : Boolean(item.active)))
      .map((item) => item.id);
  }
);

export default expensePoliciesSlice.reducer;

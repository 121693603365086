import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { HttpClient } from "../../api/httpClient";
import normalize from "../../utils/normalize";

const baseName = "approvalRequests";

export const adapter = createEntityAdapter({
  sortComparer: (a, b) => b.sent_at?.localeCompare(a.sent_at),
});

const initialState = adapter.getInitialState({
  working: false,
});

//FETCHS APPROVAL REQUESTS
export const fetchApprovalRequests = createAsyncThunk(
  `${baseName}/fetchApprovalRequests`,
  async () => {
    const res = await HttpClient.get("/approvers/pending/requests");
    return res.data;
  }
);

export const fetchApprovalRequestDetails = createAsyncThunk(
  `${baseName}/fetchApprovalRequestDetails`,
  async (requestId) => {
    const res = await HttpClient.get(
      `/approvers/pending/requests/${requestId}`
    );
    return res.data;
  }
);

export const approvalRequestsSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    loadApprovalRequests(state, { payload }) {
      const { ids, entities } = payload;
      state.ids = ids;
      state.entities = entities;
    },
    removeOneApprovalRequest(state, { payload }) {
      adapter.removeOne(state, payload);
    },
    removeManyApprovalRequests(state, { payload }) {
      adapter.removeMany(state, payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchApprovalRequests.pending, (state, action) => {
        state.working = true;
      })
      .addCase(fetchApprovalRequests.fulfilled, (state, action) => {
        state.working = false;
        const requestsApi = action.payload?.requests || [];
        const { ids, entities } = normalize(requestsApi);
        approvalRequestsSlice.caseReducers.loadApprovalRequests(state, {
          payload: {
            ids,
            entities,
          },
        });
      })
      .addCase(fetchApprovalRequests.rejected, (state, action) => {
        state.working = false;
      })

      //detail single
      .addCase(
        fetchApprovalRequestDetails.pending,
        (state, { meta: { arg } }) => {
          state.entities[arg].fetching = true;
        }
      )
      .addCase(
        fetchApprovalRequestDetails.rejected,
        (state, { meta: { arg } }) => {
          state.entities[arg].fetching = false;
        }
      )
      .addCase(
        fetchApprovalRequestDetails.fulfilled,
        (state, { meta: { arg }, payload }) => {
          state.entities[arg] = {
            ...state.entities[arg],
            fetching: false,
            ...payload,
          };
        }
      );
  },
});

export const {
  loadApprovalRequests,
  removeManyApprovalRequests,
  removeOneApprovalRequest,
} = approvalRequestsSlice.actions;

export const {
  selectAll: selectAllApprovalRequests,
  selectIds,
  selectById: selectApprovalRequestById,
  selectEntities: selectApprovalRequestsEntities,
  selectTotal: selectApprovalRequestsTotal,
} = adapter.getSelectors((state) => state[baseName]);

export default approvalRequestsSlice.reducer;

export const selectApprovalRequestsIds = createSelector(
  [selectIds, selectApprovalRequestsEntities],
  (ids, entities) => {
    return ids.filter((id) =>
      Boolean(entities[id]?.advance?.status !== "pending")
    );
  }
);
export const selectApprovalAdvancesIds = createSelector(
  [selectIds, selectApprovalRequestsEntities],
  (ids, entities) => {
    return ids.filter((id) =>
      Boolean(entities[id]?.advance?.status === "pending")
    );
  }
);

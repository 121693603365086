import { AssignmentOutlined } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingSection from "../../../../components/loading/LoadingSection";
import { openElement } from "../../../../store/features/portalSlice";
import { selectRecentRequestsIds } from "../../../../store/features/requestsSlice";
import RequestsMapper from "../../../requests/components/RequestsMapper";

export default memo(() => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const recents = useSelector(selectRecentRequestsIds);

  const status = useSelector((state) => state.requests.status);

  return (
    <Box m={1} mr={2} mt={3}>
      <Box m={2} mb={1} display={"flex"} alignItems={"center"}>
        <Box mr={2} flex={1}>
          <Typography variant="h5" fontWeight={"500"}>
            Trabalhados recentemente
          </Typography>
        </Box>
        <Button
          onClick={() => {
            navigate("/approval-requests");
          }}
          variant="outlined"
          sx={{ borderRadius: 100 }}
        >
          <Typography variant="inherit" noWrap>
            Ver todos os relatórios
          </Typography>
        </Button>
      </Box>
      {status === "idle" ? (
        <LoadingSection />
      ) : recents?.length ? (
        <RequestsMapper
          requests={recents}
          disableRowActions
          sx={{ padding: "16px" }}
        />
      ) : (
        <Box pl={1} display={"flex"} alignItems={"center"}>
          <AssignmentOutlined
            sx={{ fontSize: "4rem", opacity: 0.7 }}
            color="disabled"
          />
          <Box ml={2}>
            <Typography variant="body1" color={"text.secondary"}>
              Nenhum relatório de despesas adicionado recentemente
            </Typography>
            <Button
              onClick={() => {
                dispatch(openElement({ name: "newRequest" }));
              }}
              sx={{ ml: -1, px: 1 }}
            >
              Criar relatório
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
});

import { Close } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Checkbox,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { memo, useCallback, useEffect, useMemo, useRef } from "react";
import TotalSelectedIndicator from "../../../../expenses/components/v2/toolbar/TotalSelectedIndicator";
import DeleteExpenses from "./selection-actions/DeleteExpenses";
import MoveRequest from "./selection-actions/MoveRequest";
import RemoveFromRequest from "./selection-actions/RemoveFromRequest";

const SelectionBar = ({
  selected = [],
  count,
  onSelectAllClick,
  onClearSelection,
}) => {
  const selectedNum = useMemo(() => selected?.length, [selected?.length]);
  const selectionMode = useMemo(() => Boolean(selectedNum), [selectedNum]);

  const deleteButtonRef = useRef();

  const handleKeydown = useCallback((e) => {
    if (e.keyCode === 27) {
      onClearSelection();
      return;
    }
    if (e.keyCode === 8) {
      deleteButtonRef?.current?.openDelete();
      return;
    }
  }, []);

  useEffect(() => {
    if (selectionMode) {
      window.addEventListener("keydown", handleKeydown);
    }
    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  }, [selectionMode]);

  return (
    <AppBar
      elevation={0}
      position="fixed"
      sx={{
        top: { md: 63, xs: 50 },
        height: 70,
        borderBottom: 1,
        borderColor: "divider",
        right: 0,
        width: { md: "calc(100% - 5em)", xs: "calc(100% + .5em)" },
        bgcolor: "background.default",
        pl: { md: "1.2em", xs: 0 },
        color: "text.primary",
        transition: "transform .15s ease",
        transform: `translateY(${selected.length ? 0 : "-100px"})`,
      }}
    >
      <Toolbar sx={{ height: 80, gap: 1 }}>
        <IconButton onClick={onClearSelection}>
          <Close />
        </IconButton>
        <Checkbox
          color="primary"
          indeterminate={selectedNum > 0 && selectedNum < count}
          checked={count > 0 && selectedNum === count}
          onChange={onSelectAllClick}
        />
        <Typography noWrap variant="body1" fontSize={"1rem"} fontWeight={"500"}>
          {selectedNum} selecionadas
        </Typography>
        <TotalSelectedIndicator prevSelected={selected} />
        <Box flex={1} />
        <RemoveFromRequest
          selected={selected}
          clearSelection={onClearSelection}
        />
        <MoveRequest selected={selected} clearSelection={onClearSelection} />
        <DeleteExpenses
          ref={deleteButtonRef}
          selected={selected}
          clearSelection={onClearSelection}
        />
      </Toolbar>
    </AppBar>
  );
};

export default memo(SelectionBar);

import { AssignmentOutlined } from "@mui/icons-material";
import { MenuItem } from "@mui/material";
import React, { memo, useState } from "react";
import MenuAddExpensesInRequest from "../../../../menus/MenuAddExpensesInRequest";

const AddToRequest = ({ expenseId }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <MenuItem
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
        sx={{ height: 40 }}
      >
        <AssignmentOutlined fontSize="small" sx={{ mr: 1.5 }} color="action" />
        Adicionar a um relatório
      </MenuItem>
      <MenuAddExpensesInRequest
        open={open}
        onClose={() => setOpen(false)}
        expensesIds={[expenseId]}
      />
    </>
  );
};

export default memo(AddToRequest);

import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { store } from "../store/store";
import formatAmount from "./formatAmount";
import { curr } from "./more/currency_country";

/** Agrupar despesas por categoria, mes e dia */
function groupExpensesToCheckLimits(expenses = []) {
  const response = {};
  for (let i = 0; i < expenses.length; i++) {
    const { amount, date, is_route, type_id } = expenses[i];
    const _d = new Date(date);
    _d.setHours(3, 0, 0, 0);
    // construção das chaves
    const key1 = is_route ? "_route_" : type_id?.toString();
    if (!key1) continue;
    const _dateString = _d.toISOString();
    const year = _dateString.slice(0, 4);
    const month = _dateString.slice(5, 7);
    const day = _dateString.slice(8, 10);
    const key2 = `${month}-${year}`;
    const key3 = day;
    if (!response[key1]) response[key1] = {};
    if (!response[key1][key2]) {
      response[key1][key2] = {
        total: 0,
        days: {},
      };
    }
    if (!response[key1][key2].days[key3]) response[key1][key2].days[key3] = 0;
    // Sum total...
    response[key1][key2].total += amount;
    response[key1][key2].days[key3] += amount;
  }
  return response;
}

export const getRequestLimitsAlerts = ({
  currency,
  limits = {},
  expenses = [],
}) => {
  const categoriesEntities = store.getState().expenseTypes.entities;

  const alerts = [];
  if (limits && limits.expensesTypes) {
    // Limites por categoria
    const { expensesTypes } = limits;
    const groupedExpenses = groupExpensesToCheckLimits(expenses);
    // Análise de cada regra
    for (const typeId in expensesTypes) {
      const { daily, monthly } = expensesTypes[typeId];
      const totals = groupedExpenses[typeId];
      const typeName =
        typeId === "_route_" ? "Percurso" : categoriesEntities[typeId]?.name;
      if (totals) {
        if (monthly) {
          // Limite mensal
          for (const month in totals) {
            const { total, days } = totals[month];
            if (total > monthly) {
              // Erro: Limite mensal ultrapassado do mês "month"

              const [mes, ano] = month?.split("-");
              const fullDate = [ano, mes, "01"]?.join("-")?.replace(/-/g, "/");

              alerts.push(
                `O limite mensal de <strong>${curr(currency)} ${formatAmount(
                  monthly
                )}</strong> para despesas de ${typeName?.toLowerCase()} <br /> foi ultrapassado em <strong>${format(
                  new Date(fullDate),
                  "MMMM' de ' yyyy",
                  {
                    locale: ptBR,
                  }
                )}</strong>`
              );
            }
          }
        }
        if (daily) {
          // Limite diário
          for (const month in totals) {
            const { total, days } = totals[month];
            for (const day in days) {
              const totalAmount = days[day];
              if (totalAmount > daily) {
                // Erro: Limite diário ultrapassado no dia "day" do mês "month"

                const [mes, ano] = month?.split("-");

                const fullDate = [ano, mes, day]?.join("-")?.replace(/-/g, "/");

                alerts.push(
                  `O limite diário de <strong>${curr(currency)} ${formatAmount(
                    daily
                  )}</strong> para despesas de ${typeName?.toLowerCase()} <br /> foi ultrapassado em  <strong>${format(
                    new Date(fullDate),
                    "d 'de' MMMM',' '('iiii')'",
                    {
                      locale: ptBR,
                    }
                  )}</strong>`
                );
              }
            }
          }
        }
      }
    }
  }

  return alerts;
};

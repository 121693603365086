import { ListItemButton, ListItemText } from "@mui/material";
import React from "react";

function SectionActionButton({
  children,
  Icon,
  onClick,
  LinkComponent,
  target,
  href,
}) {
  return (
    <ListItemButton
      LinkComponent={LinkComponent}
      target={target}
      href={href}
      disableTouchRipple
      onClick={onClick}
      sx={{
        transition: "none",
        ":hover": {
          bgcolor: (t) => `${t.palette.primary.main}25`,
        },
      }}
    >
      {Icon && <Icon sx={{ mr: 3 }} color="action" />}
      <ListItemText
        primaryTypographyProps={{
          fontWeight: "500",
          fontSize: ".9rem",
        }}
        primary={children}
      />
    </ListItemButton>
  );
}

export default SectionActionButton;

import {
  CircleOutlined,
  DirectionsOutlined,
  LocationOnOutlined,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectSnippetsEntities,
  selectSnippetsIds,
} from "../../../../store/features/configs/snippetsSlice";
import SelectorBox from "../SelectorBox";

function PreRegisteredSnippet({
  value = "",
  helperText = "",
  initialError,
  variant,
  onChange,
  readOnly,
  loading,
  size,
}) {
  const snippetsIds = useSelector(selectSnippetsIds);
  const snippetsEntities = useSelector(selectSnippetsEntities);
  const [error, setError] = useState(Boolean(initialError));

  return (
    <SelectorBox Icon={DirectionsOutlined}>
      <Autocomplete
        disablePortal
        fullWidth
        readOnly={readOnly}
        size={size || "small"}
        options={snippetsIds}
        loadingText="Carregando..."
        value={value || null}
        onChange={(e, option) => {
          onChange(option || "");
        }}
        getOptionLabel={(option) => snippetsEntities[option]?.description}
        renderOption={(props, option, state) => {
          return (
            <MenuItem {...props} divider selected={state.selected}>
              <Box>
                <Typography mb={0.3} variant="inherit">
                  {snippetsEntities[option]?.description}{" "}
                </Typography>
                <Box display={"flex"} alignItems={"center"}>
                  <CircleOutlined sx={{ fontSize: ".65rem", mr: 0.8 }} />
                  <Typography color={"text.secondary"} fontSize=".7rem">
                    {snippetsEntities[option]?.from}
                  </Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"}>
                  <LocationOnOutlined
                    color="error"
                    sx={{ fontSize: ".65rem", mr: 0.8 }}
                  />
                  <Typography color={"text.secondary"} fontSize=".7rem">
                    {snippetsEntities[option]?.to}
                  </Typography>
                </Box>
                <Typography mt={0.5} fontWeight={"500"} fontSize=".7rem">
                  {parseFloat(snippetsEntities[option]?.distance).toFixed(2)} Km
                </Typography>
              </Box>
            </MenuItem>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            fullWidth
            variant={variant || "outlined"}
            label={"Trechos pré-cadastrados"}
            helperText={error && helperText}
            error={error}
          />
        )}
      />
    </SelectorBox>
  );
}

export default memo(PreRegisteredSnippet);

import { Close } from "@mui/icons-material";
import { Button, IconButton, Snackbar, Typography } from "@mui/material";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeSnackbar,
  selectSnackbarState,
} from "../../store/features/base/snackbarBaseSlice";

function SnackbarBase(props) {
  const dispatch = useDispatch();

  const { open, message, action, actionText, error, duration, hiddenClose } =
    useSelector(selectSnackbarState);

  const handleClose = () => {
    dispatch(closeSnackbar());
  };

  return (
    open && (
      <Snackbar
        open={open}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        autoHideDuration={duration || 4000}
        onClose={handleClose}
        data-disableselect={true}
        ContentProps={{
          sx: {
            bgcolor: error && "error.main",
          },
        }}
        message={
          <Typography variant="inherit" fontSize={".92rem"} fontWeight={"600"}>
            {message}
          </Typography>
        }
        action={
          <>
            {action && actionText && (
              <Button
                sx={{
                  color: "primaryDark.main",
                  ":hover": { textDecoration: "underline" },
                }}
                onClick={() => {
                  action();
                  handleClose();
                }}
              >
                {actionText || ""}
              </Button>
            )}
            {!hiddenClose && (
              <IconButton onClick={handleClose} color="inherit">
                <Close />
              </IconButton>
            )}
          </>
        }
      />
    )
  );
}

export default memo(SnackbarBase);

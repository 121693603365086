import { CopyAllOutlined } from "@mui/icons-material";
import { MenuItem } from "@mui/material";
import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { openElement } from "../../../../../store/features/portalSlice";
import { store } from "../../../../../store/store";

const CopyModel = ({ expenseId, requestId, onClose = () => {} }) => {
  const dispatch = useDispatch();

  const handleClick = (e) => {
    e.stopPropagation();

    const state = store.getState();

    const expenseCopy = state.expenses.entities[expenseId];
    const isEditable = state.requests.entities[requestId]?.status === "O";

    const initialValues = {
      date: new Date(expenseCopy.date),
      from: expenseCopy.from,
      to: expenseCopy.to,
      waypoints: expenseCopy.waypoints,
      route_policy_id: expenseCopy.route_policy_id,
      distance: expenseCopy.distance,
      currency: expenseCopy.currency,
      project_id: expenseCopy.project_id,
      notes: expenseCopy.notes,
      to_request: requestId,
    };

    if (!isEditable) {
      initialValues.to_request = null;
    }

    let screenName;

    if (expenseCopy.is_route || expenseCopy.isRoute) {
      screenName = "newRoute";
      initialValues.payment_type = expenseCopy.payment_type;
      initialValues.snippet_id = expenseCopy.snippet_id;
      initialValues.route_mode = expenseCopy.route_mode;
    } else {
      screenName = "newExpense";
      initialValues.amount = expenseCopy.amount;
      initialValues.type_id = expenseCopy.type_id;
      initialValues.payment_type = expenseCopy.payment_type;
      initialValues.refundable = Boolean(expenseCopy.refundable);
    }

    dispatch(
      openElement({
        name: screenName,
        util: initialValues,
      })
    );
    onClose();
  };

  return (
    <MenuItem sx={{ height: 40 }} onClick={handleClick}>
      <CopyAllOutlined fontSize="small" sx={{ mr: 1.5 }} color="action" />
      Criar como modelo
    </MenuItem>
  );
};

export default memo(CopyModel);
